'use strict';

import * as ActionTypes from "./ActionTypes";
import DealLostReasonRepository from "./DealLostReasonRepository";

export function loadDealLostReasons() {
    return (dispatch) => {
        DealLostReasonRepository.all()
            .then(response => dispatch(dealLostReasonsLoaded(response)))
            .catch(e => dispatch(dealLostReasonsLoadingFailed(e.message ? e.message : e)));
    }
}

function dealLostReasonsLoaded(dealLostReasons) {
    return {
        type: ActionTypes.DEALLOSTREASONS_LOADED,
        payload: {
            dealLostReasons: dealLostReasons
        }
    }
}

function dealLostReasonsLoadingFailed(error) {
    return {
        type: ActionTypes.DEALLOSTREASONS_LOADING_FAILED,
        payload: {
            error: error
        }
    }
}

export function newDealLostReason() {
    return {
        type: ActionTypes.DEALLOSTREASON_NEW
    }
}

export function updateDealLostReasonField(id, fieldName, value) {
    return {
        type: ActionTypes.DEALLOSTREASON_UPDATE_FIELD,
        payload: {
            id: id,
            fieldName: fieldName,
            value: value
        }
    }
}

export function saveDealLostReason(entity) {
    return (dispatch) => {
        dispatch(savingDealLostReason(entity));
        DealLostReasonRepository.save(entity).then((response) => {
            dispatch(dealLostReasonSaved(response));
        }).catch(error => {
            dispatch(savingDealLostReasonFailed(entity.id, error));
        });
    }
}

export function selectDealLostReason(id) {
    return {
        type: ActionTypes.DEALLOSTREASON_SELECT,
        payload: {
            id: id
        }
    }
}

export function loadDealLostReason(id) {
    return (dispatch) => {
        dispatch(loadingDealLostReason(id));
        DealLostReasonRepository.find(id).then((response) => {
            dispatch(dealLostReasonLoaded(response));
        }).catch(error => {
            dispatch(loadingDealLostReasonFailed(id, error));
        });
    }
}

export function invalidateDealLostReason(id) {
    return {
        type: ActionTypes.DEALLOSTREASON_INVALIDATED,
        payload: {
            id: id
        }
    }
}

export function deleteDealLostReason(id) {
    return (dispatch) => {
        dispatch(deletingDealLostReason(id));
        DealLostReasonRepository.delete(id).then(() => {
            dispatch(dealLostReasonDeleted(id));
        }).catch(error => {
            dispatch(savingDealLostReasonFailed(id, error));
        });
    }
}

function savingDealLostReason(entity) {
    return {
        type: ActionTypes.DEALLOSTREASON_SAVING,
        payload: {
            entity: entity
        }
    }
}

function dealLostReasonSaved(rawEntity) {
    return {
        type: ActionTypes.DEALLOSTREASON_SAVED,
        payload: {
            data: rawEntity
        }
    }
}

function savingDealLostReasonFailed(id, error) {
    return {
        type: ActionTypes.DEALLOSTREASON_SAVING_FAILED,
        payload: {
            id: id,
            error: error
        }
    }
}

function loadingDealLostReason(id) {
    return {
        type: ActionTypes.DEALLOSTREASON_LOADING,
        payload: {
            id: id
        }
    }
}

function dealLostReasonLoaded(rawEntity) {
    return {
        type: ActionTypes.DEALLOSTREASON_LOADED,
        payload: {
            data: rawEntity
        }
    }
}

function loadingDealLostReasonFailed(id, error) {
    return {
        type: ActionTypes.DEALLOSTREASON_LOADING_FAILED,
        payload: {
            id: id,
            error: error
        }
    }
}

function deletingDealLostReason(id) {
    return {
        type: ActionTypes.DEALLOSTREASON_DELETING,
        payload: {
            id: id
        }
    }
}

function dealLostReasonDeleted(id) {
    return {
        type: ActionTypes.DEALLOSTREASON_DELETED,
        payload: {
            id: id
        }
    }
}