'use strict';

import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import SavedSearch from '../../../../Entities/SavedSearch/SavedSearch';

class Open extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            isDefault: false,
            shared: false,
        };
    }

    updateSaveAs(event) {
        this.setState({
            name: event.target.value,
        });
    }

    save(event) {
        event.preventDefault();
        if (!this.state.name) return;

        let newSavedSearchEntity = Object.assign(SavedSearch.blank(), {
            name: this.state.name,
            isDefault: this.state.isDefault,
            shared: this.state.shared,
        });

        this.props.save(newSavedSearchEntity);
    }

    button() {
        const disabled = this.state.name === '';
        let buttonClasses = 'react-adv--save-button';
        return (
            <span className="react-adv--save-button-container">
                <Button
                    className={buttonClasses}
                    size="sm"
                    type="submit"
                    variant={disabled ? 'default' : 'tertiary'}
                    disabled={disabled}
                >
                    Save
                </Button>
            </span>
        );
    }

    render() {
        return (
            <form onSubmit={this.save.bind(this)}>
                <input
                    type="text"
                    className="form-control react-adv--save-input"
                    placeholder="Save as"
                    ref="saveAsInput"
                    onChange={this.updateSaveAs.bind(this)}
                    autoFocus={true}
                />
                {this.button()}
            </form>
        );
    }
}

Open.propTypes = {
    save: PropTypes.func.isRequired,
};

export default Open;
