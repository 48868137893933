'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import AddFieldControl from "./Pieces/AddFieldControl";
import LoadSavedSearchControl from "./Pieces/LoadSavedSearchControl";
import SaveCurrentSearchControl from "./Pieces/SaveCurrentSearchControl";

import './advanced-search.scss';
import PageHeader from "./Pieces/PageHeader";
import {clearSearch} from "../../Entities/SavedSearch/Actions";
import SavedSearch from "../../Entities/SavedSearch/SavedSearch";

export const ADD_FIELD_CONTROL = 'AddFieldControl';
export const LOAD_SAVED_SEARCH_CONTROL = 'LoadSavedSearchControl';
export const SAVE_CURRENT_SEARCH_CONTROL = 'SaveCurrentSearchControl';

class AdvancedSearchBar extends React.Component {

    constructor(props) {
        super(props);

        // Allow removeEventListener on bound functions
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    handleClick(e) {
        if (this.refs.componentContainer.contains(e.target)) return;
        this.props.toggleComponent('');
    }

    toggleComponent(componentName) {
        this.props.toggleComponent(componentName);
    }

    updateSelectedComponents(components) {
        this.props.updateSelectedComponents(components);
    }

    loadSavedSearch(id) {
        this.props.loadSavedSearch(id);
    }

    saveButton() {
        if (this.props.components.length === 0 || !this.props.showSaveButton) return null;
        return <SaveCurrentSearchControl
            open={this.props.openComponent === SAVE_CURRENT_SEARCH_CONTROL}
            toggle={() => this.toggleComponent(SAVE_CURRENT_SEARCH_CONTROL)}
            save={this.props.saveCurrentSearch}
            saving={this.props.savedSearchSaving}
            currentSavedSearch={this.props.currentSavedSearch}
        />;
    }

    error() {
        if (!this.props.error) return null;
        return <span className="alert alert-danger">{this.props.error}</span>
    }

    deleteCurrentlySelectedSearch() {
        this.props.clearSearch();
    }

    render() {
        return <div>
            <PageHeader
                pageTitle={this.props.pageTitle}
                currentSavedSearch={this.props.currentSavedSearch}
                clearSearch={this.props.clearSearch}
                showClearSearch={this.props.components.length > 0}
                viewControls={this.props.headerViewControls}
            />
            <div className="react-adv--advanced-search" ref="componentContainer">
                {this.props.components.map((c) => c.react)}
                <AddFieldControl
                    open={this.props.openComponent === ADD_FIELD_CONTROL}
                    toggle={() => this.toggleComponent(ADD_FIELD_CONTROL)}
                    components={this.props.components.map((c) => c.name)}
                    updateSelectedComponents={this.updateSelectedComponents.bind(this)}
                    availableComponents={this.props.availableComponents}
                />
                <LoadSavedSearchControl
                    selectedValue={this.props.currentSavedSearch ? this.props.currentSavedSearch.id : null}
                    component={LOAD_SAVED_SEARCH_CONTROL}
                    open={this.props.openComponent === LOAD_SAVED_SEARCH_CONTROL}
                    toggle={() => this.toggleComponent(LOAD_SAVED_SEARCH_CONTROL)}
                    updateSelectedOption={this.loadSavedSearch.bind(this)}
                    deleteCurrentlySelectedSearch={this.deleteCurrentlySelectedSearch.bind(this)}
                    entityName={this.props.entityName}
                />
                {this.saveButton()}
                {this.error()}
            </div>
        </div>;
    }

}

AdvancedSearchBar.propTypes = {
    components: PropTypes.array.isRequired,
    availableComponents: PropTypes.array.isRequired,
    updateSelectedComponents: PropTypes.func.isRequired,
    openComponent: PropTypes.string,
    toggleComponent: PropTypes.func.isRequired,
    loadSavedSearch: PropTypes.func.isRequired,
    saveCurrentSearch: PropTypes.func.isRequired,
    currentSavedSearch: PropTypes.instanceOf(SavedSearch),
    savedSearchSaving: PropTypes.bool,
    error: PropTypes.string,
    pageTitle: PropTypes.string,
    headerViewControls: PropTypes.element,
    showSaveButton: PropTypes.bool,
    clearSearch: PropTypes.func,
    entityName: PropTypes.string.isRequired
};

AdvancedSearchBar.defaultProps = {
    savedSearchSaving: false,
    openComponent: '',
    pageTitle: '',
    showSaveButton: true
};

export default AdvancedSearchBar;