import { UserPermissionsContext } from '@client/Context/UserPermissions';
import {
    AsyncDropDownPaginated,
    Checkbox,
    DropDown,
    isValidString,
    LogLevel,
    OptionTypeBase,
    OptionTypeBaseUserFormatter,
    SearchQuery,
    SearchQueryBuilder,
    showBanner,
    Sisp,
    SortOrder,
} from '@sprint/sprint-react-components';
import { format } from 'date-fns';
import _ from 'lodash';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Card, Form, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { ucwords } from '../../../../Helpers/StringHelper';
import { ContactsRequest } from '../../Api/ContactsRequest';
import { DealsRequest } from '../../Api/DealsRequest';
import { OrganisationsRequest } from '../../Api/OrganisationsRequest';
import { TasksRequest } from '../../Api/TasksRequest';
import { TaskTypeRequest } from '../../Api/TaskTypeRequest';
import { UserTypeRequest } from '../../Api/UserTypeRequest';
import { DictionaryContext, PermissionsContext, RepositoryFactoryContext } from '../../index';
import Contact from '../../Models/Contact';
import { Deal } from '../../Models/Deal';
import { TasksPriority } from '../../Models/Enums';
import Organisation from '../../Models/Organisation';
import { TasksTypeAddState } from '../../Models/TasksType';
import TaskType from '../../Models/TaskType';
import UserType from '../../Models/UserType';
import CustomPropertyForm, { AvailablePropertyTypes } from '../CustomProperties/CustomPropertyForm';
import './TasksSisps.scss';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
}

const TasksAddSisp: FunctionComponent<Props> = (props: Props) => {
    const tasksRepository = useContext(RepositoryFactoryContext).getApiRepository(new TasksRequest());
    const taskTypeRepository = useContext(RepositoryFactoryContext).getApiRepository(new TaskTypeRequest());
    const usersRepository = useContext(RepositoryFactoryContext).getApiRepository(new UserTypeRequest());
    const dealsRepository = useContext(RepositoryFactoryContext).getApiRepository(new DealsRequest());
    const contactsRepository = useContext(RepositoryFactoryContext).getApiRepository(new ContactsRequest());
    const organisationsRepository = useContext(RepositoryFactoryContext).getApiRepository(new OrganisationsRequest());

    const dictionary = useContext(DictionaryContext);
    const permissions = useContext(PermissionsContext);
    const userPermissions = useContext(UserPermissionsContext);

    const customProperties: any = JSON.parse(
        String((document.getElementById('custom-properties') as HTMLInputElement).value),
    );

    // State: General
    const loadingFinishedDefault = {
        taskTypes: false,
        users: false,
    };
    const [loadingFinished, setLoadingFinished] = useState(loadingFinishedDefault);

    const focusRef = useRef<HTMLInputElement>(null);

    const [newName, setNewName] = useState<string>('');
    const [newPriority, setNewPriority] = useState<OptionTypeBase | null>(null);
    const newRelatesToTypeDefault = {
        none: false,
        contact: false,
        organisation: false,
        deal: false,
    };
    const [newRelatesToType, setNewRelatesToType] = useState(newRelatesToTypeDefault);
    const [newRelatesTo, setNewRelatesTo] = useState<OptionTypeBase | null>(null);
    const [newDueByDate, setNewDueByDate] = useState<Date | null>(null);
    const [newType, setNewType] = useState<OptionTypeBase | null>(null);
    const [newAssignedTo, setNewAssignedTo] = useState<OptionTypeBase | null>(null);
    const [newPrivateTask, setNewPrivateTask] = useState<boolean>(false);
    const [newDescription, setNewDescription] = useState<string>('');
    const [newCustomProperties, setNewCustomProperties] = useState<{ [key: string]: string }>({});

    const [customPropertyValues, setCustomPropertyValues] = useState<any>();

    const validationStateDefault = {
        name: true,
        priority: true,
        type: true,
        assignedTo: true,
        relatesTo: true,
    };
    const [validationState, setValidationState] = useState(validationStateDefault);

    const [taskTypes, setTaskTypes] = useState<OptionTypeBase[]>();
    const [currentUserId, setCurrentUserId] = useState<number | null>(null);
    const [users, setUsers] = useState<OptionTypeBase[]>();

    const getTaskTypes = (): Promise<boolean> => {
        return taskTypeRepository
            .search(new SearchQueryBuilder().build())
            .then((results: any) => {
                setTaskTypes(
                    results.results.map((taskType: TaskType) => {
                        if (taskType.is_default) {
                            setNewType({ value: taskType.id, label: taskType.type });
                        }
                        return { value: taskType.id, label: taskType.type };
                    }),
                );
                setLoadingFinished((prevState) => {
                    return { ...prevState, taskTypes: true };
                });
                return true;
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get Task Types - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                    dismissable: false,
                });
                return false;
            });
    };

    const getUsers = (): Promise<boolean> => {
        return usersRepository
            .search(new SearchQueryBuilder().build())
            .then((results: any) => {
                setUsers(
                    results.results.map((user: UserType) => {
                        if (user.name === 'You') {
                            setCurrentUserId(user.id ?? null);
                        }
                        return OptionTypeBaseUserFormatter(user);
                    }),
                );
                setLoadingFinished((prevState) => {
                    return { ...prevState, users: true };
                });
                return true;
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get Users - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                    dismissable: false,
                });
                return false;
            });
    };

    const getContacts = async (filter: string, page?: number) => {
        // Build filterRequest
        const query: SearchQuery = new SearchQuery(page ?? 1, 5, 'co.firstname', SortOrder.ASC, filter);
        let res: OptionTypeBase[] = [];

        return contactsRepository
            .search(query)
            .then((result: any) => {
                res = _.map(result.results, (contact: Contact) => {
                    return {
                        value: contact.id,
                        label: `${contact.full_name} [${contact.organisation.name}]`,
                    };
                });
                res.push({
                    value: result.counts.currentPage < result.counts.totalPages,
                    label: 'has_more',
                });
                return res;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to get Contacts - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return res;
            });
    };

    const getOrganisations = async (filter: string, page?: number) => {
        // Build filterRequest
        const query: SearchQuery = new SearchQuery(page ?? 1, 5, 'o.organisation_name', SortOrder.ASC, filter);
        let res: OptionTypeBase[] = [];

        return organisationsRepository
            .search(query)
            .then((result: any) => {
                res = _.map(result.results, (organisation: Organisation) => {
                    return {
                        value: organisation.id,
                        label: organisation.name,
                    };
                });
                res.push({
                    value: result.counts.currentPage < result.counts.totalPages,
                    label: 'has_more',
                });
                return res;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to get Organisations - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return res;
            });
    };

    const getDeals = async (filter: string, page?: number) => {
        // Build filterRequest
        const query: SearchQuery = new SearchQuery(page ?? 1, 10, 'd.name', SortOrder.ASC, filter);
        let res: OptionTypeBase[] = [];

        return dealsRepository
            .search(query)
            .then((result: any) => {
                res = _.map(result.results, (deal: Deal) => {
                    return {
                        value: deal.id,
                        label: `${deal.name} [${deal.value.formatted}]`,
                    };
                });
                res.push({
                    value: result.counts.currentPage < result.counts.totalPages,
                    label: 'has_more',
                });
                return res;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to get Deals - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return res;
            });
    };

    useEffect(() => {
        if (props.shown) {
            setLoadingFinished(loadingFinishedDefault);
            getTaskTypes();
            getUsers();
            updateRelatesToType('none');
            setNewPriority({ value: TasksPriority.MEDIUM, label: TasksPriority.MEDIUM });
        }
    }, [props.shown]);

    useEffect(() => {
        if (_.every(loadingFinished)) {
            focusRef.current?.focus();
        }
    }, [loadingFinished]);

    const reset = () => {
        setNewName('');
        setNewPriority(null);
        setNewRelatesToType(newRelatesToTypeDefault);
        setNewRelatesTo(null);
        setNewDueByDate(null);
        setNewType(null);
        setNewAssignedTo(null);
        setNewPrivateTask(false);
        setNewDescription('');
        setNewCustomProperties({});
        setCurrentUserId(null);
        setCustomPropertyValues(undefined);
        setValidationState(validationStateDefault);
    };

    const updateRelatesToType = (relatesToType: string) => {
        setNewRelatesToType({ ...newRelatesToTypeDefault, [relatesToType]: true });
        setNewRelatesTo(null);
        setValidationState((prevState) => {
            return { ...prevState, relatesTo: true };
        });
    };

    const validate = async (): Promise<boolean> => {
        let relatesToValid = true;

        if (newRelatesToType.contact || newRelatesToType.organisation || newRelatesToType.deal) {
            relatesToValid = newRelatesTo !== null;
        }

        const newValidationState = {
            name: !!newName && isValidString(newName),
            priority: newPriority !== null,
            type: newType !== null,
            assignedTo: newAssignedTo !== null,
            relatesTo: relatesToValid,
        };
        setValidationState(newValidationState);

        return _.every(newValidationState);
    };

    const handleAddRow = async (): Promise<boolean> => {
        let newRelatesToStr = '';
        for (const [key, value] of Object.entries(newRelatesToType)) {
            if (value) {
                newRelatesToStr = key;
                break;
            }
        }

        const newTask: TasksTypeAddState = {
            name: newName,
            description: newDescription,
            task_type_id: newType!.value,
            assigned_to_id: newAssignedTo!.value,
            is_private: newPrivateTask,
            due_date: newDueByDate && format(newDueByDate!, 'yyyy-MM-dd HH:mm:ss'),
            priority: newPriority!.value,
            relates_to: newRelatesToStr,
            relates_to_id: newRelatesToStr !== 'none' ? newRelatesTo!.value : null,
        };

        return tasksRepository
            .create({ ...newTask, ...newCustomProperties })
            .then(props.onSuccess)
            .then(async (success) => {
                reset();
                return success;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to create task - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        if ((await validate()) && (await handleAddRow())) props.onClose();
    };

    const setCustomPropertyValue = (key: any, value: any) => {
        // This is needed to stop an infinite render loop
        if (!(key in newCustomProperties && newCustomProperties[key] == value)) {
            setNewCustomProperties((prevState) => ({
                ...prevState,
                [key]: value,
            }));
        }
    };

    return (
        <Sisp
            className="tasks-sisp-add"
            isOpen={props.shown}
            onSubmit={handleAddRow}
            onCancel={() => {
                reset();
                props.onClose();
            }}
            validate={validate}
        >
            <h4>Create Task</h4>
            {_.every(loadingFinished) ? (
                <Form onSubmit={onSubmitForm}>
                    <Form.Group>
                        <Form.Label>
                            Task Name <span className="required-field-marker">*</span>
                        </Form.Label>
                        <Form.Control
                            autoComplete="off"
                            ref={focusRef}
                            type="text"
                            isInvalid={!validationState.name}
                            value={newName || ''}
                            onChange={(event) => {
                                setNewName(event.target.value);
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            {!validationState.name && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Relates To</Form.Label>
                        <div className="radio-button-group align-horizontal">
                            <Form.Check
                                className="radio-button"
                                id="relates-to-none-add"
                                name="relates-to"
                                type="radio"
                                label="None"
                                custom
                                checked={newRelatesToType.none}
                                onChange={() => updateRelatesToType('none')}
                            />
                            <Form.Check
                                className="radio-button"
                                id="relates-to-contact-add"
                                name="relates-to"
                                type="radio"
                                label="Contact"
                                custom
                                checked={newRelatesToType.contact}
                                onChange={() => updateRelatesToType('contact')}
                            />
                            <Form.Check
                                className="radio-button"
                                id="relates-to-organisation-add"
                                name="relates-to"
                                type="radio"
                                label={ucwords(dictionary['organisation'])}
                                custom
                                checked={newRelatesToType.organisation}
                                onChange={() => updateRelatesToType('organisation')}
                            />
                            {permissions['deals_module'] && (
                                <Form.Check
                                    className="radio-button"
                                    id="relates-to-deal-add"
                                    name="relates-to"
                                    type="radio"
                                    label="Deal"
                                    custom
                                    checked={newRelatesToType.deal}
                                    onChange={() => updateRelatesToType('deal')}
                                />
                            )}
                        </div>
                    </Form.Group>
                    {newRelatesToType.contact && (
                        <Form.Group>
                            <Form.Label>
                                Contacts <span className="required-field-marker">*</span>
                            </Form.Label>
                            <AsyncDropDownPaginated
                                id={'contacts_dropdown'}
                                value={newRelatesTo}
                                isInvalid={!validationState.relatesTo}
                                menuPlacement="auto"
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                onChange={(selected: OptionTypeBase) => {
                                    setNewRelatesTo(selected);
                                }}
                                loadOptions={async (filter: string, _loadedOptions, { page }) => {
                                    let res = await getContacts(filter, page);
                                    // Get has_more entry from results
                                    const hasMore = res.find((obj) => obj.label === 'has_more');
                                    // Remove has_more entry from main results
                                    res = _.filter(res, (obj) => obj.label !== 'has_more');
                                    return {
                                        options: res,
                                        hasMore: hasMore?.value as boolean,
                                        additional: {
                                            page: page + 1,
                                        },
                                    };
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {!validationState.relatesTo && 'This field is required.'}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}
                    {newRelatesToType.organisation && (
                        <Form.Group>
                            <Form.Label>
                                {ucwords(dictionary['organisation'])}s <span className="required-field-marker">*</span>
                            </Form.Label>
                            <AsyncDropDownPaginated
                                id={'organisations_dropdown'}
                                value={newRelatesTo}
                                isInvalid={!validationState.relatesTo}
                                menuPlacement="auto"
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                onChange={(selected: OptionTypeBase) => {
                                    setNewRelatesTo(selected);
                                }}
                                loadOptions={async (filter: string, _loadedOptions, { page }) => {
                                    let res = await getOrganisations(filter, page);
                                    // Get has_more entry from results
                                    const hasMore = res.find((obj) => obj.label === 'has_more');
                                    // Remove has_more entry from main results
                                    res = _.filter(res, (obj) => obj.label !== 'has_more');
                                    return {
                                        options: res,
                                        hasMore: hasMore?.value as boolean,
                                        additional: {
                                            page: page + 1,
                                        },
                                    };
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {!validationState.relatesTo && 'This field is required.'}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}
                    {permissions['deals_module'] && newRelatesToType.deal && (
                        <Form.Group>
                            <Form.Label>
                                Deals <span className="required-field-marker">*</span>
                            </Form.Label>
                            <AsyncDropDownPaginated
                                id={'deals_dropdown'}
                                value={newRelatesTo}
                                isInvalid={!validationState.relatesTo}
                                menuPlacement="auto"
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                onChange={(selected: OptionTypeBase) => {
                                    setNewRelatesTo(selected);
                                }}
                                loadOptions={async (filter: string, _loadedOptions, { page }) => {
                                    let res = await getDeals(filter, page);
                                    // Get has_more entry from results
                                    const hasMore = res.find((obj) => obj.label === 'has_more');
                                    // Remove has_more entry from main results
                                    res = _.filter(res, (obj) => obj.label !== 'has_more');
                                    return {
                                        options: res,
                                        hasMore: hasMore?.value as boolean,
                                        additional: {
                                            page: page + 1,
                                        },
                                    };
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {!validationState.relatesTo && 'This field is required.'}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}
                    <Form.Group>
                        <Form.Label>
                            Priority <span className="required-field-marker">*</span>
                        </Form.Label>
                        <DropDown
                            value={newPriority}
                            isInvalid={!validationState.priority}
                            onChange={(selected: OptionTypeBase) => {
                                setNewPriority(selected);
                            }}
                            options={Object.values(TasksPriority).map((value: string) => {
                                return {
                                    value: value,
                                    label: value,
                                };
                            })}
                            menuPosition="fixed"
                        />
                        <Form.Control.Feedback type="invalid">
                            {!validationState.priority && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="tasks-date">
                        <Form.Label>Due By</Form.Label>
                        <DatePicker
                            className="tasks-date-picker"
                            selected={newDueByDate}
                            onChange={(date: Date) => setNewDueByDate(date)}
                            dateFormat="dd/MM/yyyy h:mm aaa"
                            showTimeInput
                            timeFormat="h:mm aa"
                            timeIntervals={1}
                            isClearable={true}
                            shouldCloseOnSelect={false}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Type <span className="required-field-marker">*</span>
                        </Form.Label>
                        <DropDown
                            value={newType}
                            isInvalid={!validationState.type}
                            onChange={(selected: OptionTypeBase) => {
                                setNewType(selected);
                            }}
                            options={taskTypes!}
                            menuPosition="fixed"
                        />
                        <Form.Control.Feedback type="invalid">
                            {!validationState.type && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Assigned To <span className="required-field-marker">*</span>
                            {currentUserId && (
                                <a
                                    style={{ position: 'absolute', right: '20px', textDecoration: 'none' }}
                                    href=""
                                    className="rdg-link"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        users!.every((option: OptionTypeBase) => {
                                            if (option.value === currentUserId) {
                                                setNewAssignedTo(option);
                                                return false;
                                            }
                                            return true;
                                        });
                                    }}
                                >
                                    Assign to me
                                </a>
                            )}
                        </Form.Label>
                        <DropDown
                            value={newAssignedTo}
                            isInvalid={!validationState.assignedTo}
                            onChange={(selected: OptionTypeBase) => {
                                setNewAssignedTo(selected);
                            }}
                            options={users!}
                            menuPosition="fixed"
                        />
                        <Form.Control.Feedback type="invalid">
                            {!validationState.assignedTo && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <OverlayTrigger
                            overlay={
                                <Tooltip id={'private-task-tooltip'}>
                                    If private, only assigned to users can see this task.
                                </Tooltip>
                            }
                            placement="right"
                        >
                            <div style={{ width: '25%' }}>
                                <Checkbox
                                    label="Private Task"
                                    isChecked={newPrivateTask}
                                    onChange={(event) => setNewPrivateTask(event.target.checked)}
                                />
                            </div>
                        </OverlayTrigger>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            style={{
                                minHeight: '10rem',
                            }}
                            value={newDescription}
                            onChange={(event) => setNewDescription((event.target as HTMLTextAreaElement).value)}
                        />
                    </Form.Group>
                    {userPermissions.customPropertiesTasks.isEnabled && (
                        <CustomPropertyForm
                            propertyType={AvailablePropertyTypes.tasks}
                            customProperties={customProperties}
                            customPropertyValues={customPropertyValues}
                            updateFormPropertyState={setCustomPropertyValues}
                            setPropertyValue={setCustomPropertyValue}
                        />
                    )}
                </Form>
            ) : (
                <div style={{ position: 'relative', alignItems: 'center' }}>
                    <Card
                        className="loading-spinner-container filter-loading-spinner"
                        style={{ background: '#f9f9f9' }}
                    >
                        <Spinner animation="border" role="status" />
                    </Card>
                </div>
            )}
        </Sisp>
    );
};

export default TasksAddSisp;
