'use strict';

import * as ActionTypes from "./ActionTypes";
import ClientRepository from "./ClientRepository";

export function loadClient() {
    return (dispatch) => {
        ClientRepository.all()
            .then(response => dispatch(clientLoaded(response)))
            .catch(e => dispatch(clientLoadingFailed(e.message ? e.message : e)));
    }
}

function clientLoaded(client) {
    return {
        type: ActionTypes.CLIENT_LOADED,
        payload: {
            client: client
        }
    }
}

function clientLoadingFailed(error) {
    return {
        type: ActionTypes.CLIENT_LOADING_FAILED,
        payload: {
            error: error
        }
    }
}