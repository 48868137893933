'use strict';

import { REHYDRATE } from 'redux-persist';
import * as DealActionTypes from '../../../Entities/Deal/ActionTypes';
import PersistenceKey from '../../../Helpers/PersistenceKey';
import * as ActionTypes from '../Actions/AdvancedSearchActionTypes';
import * as FieldComponent from '../Components/DealAdvancedSearch/FieldComponent';

const blankSearch = {
    pipelines: [],
    stageStates: [],
    types: [],
    ownedBy: [],
    createdBy: [],
    modifiedBy: [],
    relatesToContacts: [],
    relatesToOrganisations: [],
    createdDateBetween: {
        from: undefined,
        to: undefined,
        preset: undefined,
    },
    followUpDateBetween: {
        from: undefined,
        to: undefined,
        preset: undefined,
    },
    closeDateBetween: {
        from: undefined,
        to: undefined,
        preset: undefined,
    },
    lostReasons: [],
    valueBetween: {
        from: '',
        to: '',
    },
};

const blankComponents = [];

const initialState = {
    components: blankComponents,
    openComponent: '',
    currentSearch: blankSearch,
    savedSearchesLoaded: false,
    updateDataTable: false,
    updateLoadedEntities: false,
    dataTableRowCount: -1,
};

function resetSearchValuesWhereComponentIsNotPresent(search, components) {
    if (components.indexOf(FieldComponent.FIELD_DEAL_PIPELINE) === -1) search.pipelines = [];
    if (components.indexOf(FieldComponent.FIELD_DEAL_STAGE_STATE) === -1) search.stageStates = [];
    if (components.indexOf(FieldComponent.FIELD_DEAL_TYPE) === -1) search.types = [];
    if (components.indexOf(FieldComponent.FIELD_OWNED_BY) === -1) search.ownedBy = [];
    if (components.indexOf(FieldComponent.FIELD_CREATED_BY) === -1) search.createdBy = [];
    if (components.indexOf(FieldComponent.FIELD_MODIFIED_BY) === -1) search.modifiedBy = [];
    if (components.indexOf(FieldComponent.FIELD_RELATES_TO_CONTACT) === -1) search.relatesToContacts = [];
    if (components.indexOf(FieldComponent.FIELD_RELATES_TO_ORGANISATION) === -1) search.relatesToOrganisations = [];
    if (components.indexOf(FieldComponent.FIELD_CREATED_DATE_BETWEEN) === -1)
        search.createdDateBetween = { from: undefined, to: undefined, preset: undefined };
    if (components.indexOf(FieldComponent.FIELD_FOLLOW_UP_DATE_BETWEEN) === -1)
        search.followUpDateBetween = { from: undefined, to: undefined, preset: undefined };
    if (components.indexOf(FieldComponent.FIELD_CLOSE_DATE_BETWEEN) === -1)
        search.closeDateBetween = { from: undefined, to: undefined, preset: undefined };
    if (components.indexOf(FieldComponent.FIELD_DEAL_LOST_REASON) === -1) search.lostReasons = [];
    if (components.indexOf(FieldComponent.FIELD_VALUE_BETWEEN) === -1) search.valueBetween = { from: '', to: '' };

    return search;
}

function addComponentsWhereSearchValueIsPresent(search) {
    const components = [];
    if (search.pipelines.length > 0) components.push(FieldComponent.FIELD_DEAL_PIPELINE);
    if (search.stageStates.length > 0) components.push(FieldComponent.FIELD_DEAL_STAGE_STATE);
    if (search.types.length > 0) components.push(FieldComponent.FIELD_DEAL_TYPE);
    if (search.ownedBy.length > 0) components.push(FieldComponent.FIELD_OWNED_BY);
    if (search.createdBy.length > 0) components.push(FieldComponent.FIELD_CREATED_BY);
    if (search.modifiedBy.length > 0) components.push(FieldComponent.FIELD_MODIFIED_BY);
    if (search.relatesToContacts.length > 0) components.push(FieldComponent.FIELD_RELATES_TO_CONTACT);
    if (search.relatesToOrganisations.length > 0) components.push(FieldComponent.FIELD_RELATES_TO_ORGANISATION);
    if (
        search.createdDateBetween &&
        (search.createdDateBetween.from || search.createdDateBetween.to || search.createdDateBetween.preset)
    )
        components.push(FieldComponent.FIELD_CREATED_DATE_BETWEEN);
    if (
        search.followUpDateBetween &&
        (search.followUpDateBetween.from || search.followUpDateBetween.to || search.followUpDateBetween.preset)
    )
        components.push(FieldComponent.FIELD_FOLLOW_UP_DATE_BETWEEN);
    if (
        search.closeDateBetween &&
        (search.closeDateBetween.from || search.closeDateBetween.to || search.closeDateBetween.preset)
    )
        components.push(FieldComponent.FIELD_CLOSE_DATE_BETWEEN);
    if (search.lostReasons.length > 0) components.push(FieldComponent.FIELD_DEAL_LOST_REASON);
    if (search.valueBetween.from !== '' || search.valueBetween.to !== '')
        components.push(FieldComponent.FIELD_VALUE_BETWEEN);
    return components;
}

export default function (state, action) {
    if (state === undefined) {
        state = initialState;
    }

    switch (action.type) {
        case REHYDRATE:
            const reducerNameInRoot = 'advancedSearch';
            if (!PersistenceKey.checkActionForRoot(action, reducerNameInRoot)) return state;
            const serializedState = action.payload[reducerNameInRoot];
            return Object.assign({}, state, serializedState, {
                updateDataTable: true,
                updateLoadedEntities: true,
            });

        case ActionTypes.OPEN_COMPONENT:
            return Object.assign({}, state, {
                openComponent: action.payload.component,
            });
        case ActionTypes.UPDATE_SELECTED_COMPONENTS:
            const components = action.payload.selectedComponents;
            const newSearch = Object.assign({}, state.currentSearch);

            // find new components since last time
            let openComponent = '';
            const newComponents = components.filter((c) => state.components.indexOf(c) === -1);
            if (newComponents.length > 0) openComponent = newComponents[0];

            return Object.assign({}, state, {
                components: components,
                currentSearch: resetSearchValuesWhereComponentIsNotPresent(newSearch, components),
                openComponent: openComponent,
            });
        case ActionTypes.UPDATE_SEARCH:
            return Object.assign({}, state, {
                // Check if first property of newSearch object is undefined, if so set a blank search
                currentSearch:
                    action.payload.newSearch.pipelines === undefined ? blankSearch : action.payload.newSearch,
                updateDataTable: true,
                updateLoadedEntities: true,
            });
        case ActionTypes.DEAL_DATA_GRID_PIPELINE_FILTER:
            return Object.assign({}, state, {
                // Change the legacy filters to the selected Pipelines so that only the selected Pipelines are rendered
                currentSearch: { ...blankSearch, pipelines: action.payload.pipelineArray },
                updateDataTable: true,
                updateLoadedEntities: true,
            });
        case ActionTypes.REMOVE_COMPONENT:
            const componentsAfter = state.components.filter((c) => c !== action.payload.componentToRemove);
            const currentSearch = Object.assign({}, state.currentSearch);
            return Object.assign({}, state, {
                components: componentsAfter,
                currentSearch: resetSearchValuesWhereComponentIsNotPresent(currentSearch, componentsAfter),
                updateDataTable: true,
                updateLoadedEntities: true,
            });
        case ActionTypes.LOAD_SEARCH:
            return Object.assign({}, state, {
                components: addComponentsWhereSearchValueIsPresent(action.payload.searchToLoad),
                currentSearch: action.payload.searchToLoad,
                updateDataTable: true,
                updateLoadedEntities: true,
            });
        case ActionTypes.CLEAR_SEARCH:
            return Object.assign({}, state, {
                components: [],
                openComponent: '',
                currentSearch: blankSearch,
                updateDataTable: true,
                updateLoadedEntities: true,
            });
        case ActionTypes.DATA_TABLE_UPDATING:
            return Object.assign({}, state, {
                updateDataTable: false,
            });
        case ActionTypes.DATA_TABLE_UPDATED:
            return Object.assign({}, state, {
                updateDataTable: false,
                dataTableRowCount:
                    action.payload.dataTableRowCount >= 0 ? action.payload.dataTableRowCount : state.dataTableRowCount,
            });
        case ActionTypes.LOADED_ENTITIES_UPDATING:
            return Object.assign({}, state, {
                updateLoadedEntities: false,
            });

        case DealActionTypes.DEAL_SAVED:
            return Object.assign({}, state, {
                updateLoadedEntities: true,
            });

        default:
            return state;
    }
}
