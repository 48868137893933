import { faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, FunctionComponent, useRef } from 'react';

interface Props {
    currentValue: string;
    onChange: (value: string) => void;
    align?: 'left' | 'right';
}

const SearchBar: FunctionComponent<Props> = (props: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleClick = () => {
        if (props.currentValue.length) {
            // Clear the input
            props.onChange('');
        } else {
            // Focus the input
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    };

    return (
        <div style={{ display: 'inline-block', position: 'relative', textAlign: props.align ?? 'right' }}>
            <input
                ref={inputRef}
                type="text"
                className="form-control"
                placeholder="Search"
                value={props.currentValue}
                onChange={(e: ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value)}
                style={{
                    width: '220px',
                    display: 'inline-block',
                    fontSize: '12px',
                    paddingRight: '24px',
                }}
            />
            <button
                onClick={handleClick}
                style={{
                    position: 'absolute',
                    top: 7,
                    right: 5,
                    border: 0,
                    background: 'none',
                    color: '#999',
                }}
            >
                <FontAwesomeIcon icon={props.currentValue.length ? faTimes : faSearch} />
            </button>
        </div>
    );
};

export default SearchBar;
