import { ExtendedColumn, HoveroverButton } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext } from 'react';
import ClientTagPill from '../../../../CommonComponents/ClientTags/ClientTagPill';
import { LARGE_COLUMN_WIDTH, SMALL_COLUMN_WIDTH } from '../../../EducationDataGrid/constant';
import { ClientTagsRequest } from '../../Api/ClientTagsRequest';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import ClientTag from '../../Models/ClientTag';
import { UniqueKeyType } from '../../Models/Enums';
import { RepositoryFactoryContext } from '../../index';
import CampusDataGrid, { PromptMeta } from '../CampusDataGrid';
import ClientTagsAddSisp from './ClientTagsAddSisp';
import ClientTagsEditSisp from './ClientTagsEditSisp';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

export enum ClientTagsColumnKey {
    ID,
    NAME,
    DESCRIPTION,
    HEX_COLOUR,
}

const ClientTagsTable: FunctionComponent<Props> = (props: Props) => {
    const addSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP);
    const editSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);

    const repository = useContext(RepositoryFactoryContext).getApiRepository(new ClientTagsRequest());

    const Columns: Record<ClientTagsColumnKey, ExtendedColumn> = {
        [ClientTagsColumnKey.ID]: {
            key: 'id',
            name: 'ID',
            sortable: false,
        },
        [ClientTagsColumnKey.NAME]: {
            key: 'name',
            name: 'Tag',
            sortable: true,
            width: LARGE_COLUMN_WIDTH,
            renderCell: (cellProps) => {
                return (
                    <>
                        <ClientTagPill tag={cellProps.row} />
                        <HoveroverButton
                            contents="Edit"
                            eventBusMessageTarget={editSispUniqueKey}
                            eventBusMessage={cellProps.row}
                        />
                    </>
                );
            },
        },
        [ClientTagsColumnKey.DESCRIPTION]: {
            key: 'description',
            name: 'Description',
            sortable: true,
            width: LARGE_COLUMN_WIDTH,
            renderCell: (fprops) => {
                const row = fprops.row as ClientTag;
                return <>{row.description}</>;
            },
        },
        [ClientTagsColumnKey.HEX_COLOUR]: {
            key: 'hex_colour',
            name: 'Colour',
            sortable: true,
            resizable: false,
            width: SMALL_COLUMN_WIDTH,
            renderCell: (cellProps) => {
                return (
                    <>
                        <div
                            style={{
                                display: 'inline-block',
                                height: '15px',
                                width: '15px',
                                marginRight: '5px',
                                backgroundColor: '#' + cellProps.row.hex_colour,
                            }}
                        ></div>
                        #{String(cellProps.row.hex_colour).toUpperCase()}
                    </>
                );
            },
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[ClientTagsColumnKey.NAME],
        Columns[ClientTagsColumnKey.DESCRIPTION],
    ];

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_tax_rates.png',
    };

    return (
        <>
            <CampusDataGrid
                repository={repository}
                dataGridMeta={{
                    uniqueKey: props.dataGridUniqueKey,
                    entitySingular: props.dataGridEntitySingular,
                    entityPlural: props.dataGridEntityPlural,
                    defaultColumns: DefaultColumns,
                    columnOptions: Columns,
                    defaultSortColumn: 'id',
                    defaultSortDirection: 'DESC',
                }}
                actionBarMeta={{ searchPlaceHolder: props.searchFilterPlaceholder, includeCounts: true }}
                addSispMeta={{
                    key: addSispUniqueKey,
                    sisp: ClientTagsAddSisp,
                }}
                editSispMeta={{
                    sisp: ClientTagsEditSisp,
                }}
                promptMeta={promptMeta}
            />
        </>
    );
};

export default ClientTagsTable;
