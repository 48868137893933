import { REHYDRATE } from 'redux-persist';
import PersistenceKey from "../../Helpers/PersistenceKey";
import * as ActionTypes from "./ActionTypes";
import HttpRequestService from "../../Services/HttpRequestService/HttpRequestService";
import SavedSearch from "./SavedSearch";
import * as EntityState from "../../ValueObjects/EntityState/EntityState";

const initialState = {
    currentFilter: '',
    searchResults: undefined,
    allKnownResults: [],
    pendingRequest: undefined,
    totalAvailableResults: undefined,
    moreLoading: false,
    currentSavedSearch: undefined,
    savedSearchSaving: false,
    savedSearchesLoaded: false,
    error: undefined,
    deletingItem: undefined
};

function mergeResults(existingResults, newResults) {
    if (existingResults === undefined) return newResults;
    return existingResults.filter(existingResult => {
        return newResults.map(newResult => newResult.id).indexOf(existingResult.id) === -1;
    }).map(existingResult => existingResult.clone()).concat(newResults);
}

function replaceOrAddResult(allKnownResults, incomingResult) {
    if (allKnownResults === undefined) allKnownResults = [];
    const matches = result => result.id === incomingResult.id;
    const newKnownResults = allKnownResults.map(result => {
        const updatedResult = result.clone();
        return matches(result) ? incomingResult : updatedResult;
    });
    if (allKnownResults.find(matches) === undefined) newKnownResults.push(incomingResult);
    return newKnownResults;
}

export default function (state, action) {
    if (state === undefined) {
        state = initialState;
    }

    switch (action.type) {
        case REHYDRATE: {
            const reducerNameInRoot = 'savedSearches';
            if (!PersistenceKey.checkActionForRoot(action, reducerNameInRoot)) return state;
            const serializedState = action.payload[reducerNameInRoot];
            return Object.assign({}, state, serializedState, {
                searchResults: undefined,
                allKnownResults: serializedState.allKnownResults ? serializedState.allKnownResults.map(result => SavedSearch.deserialize(result)) : [],
                pendingRequest: undefined,
                moreLoading: false,
                currentSavedSearch: serializedState.currentSavedSearch ? SavedSearch.deserialize(serializedState.currentSavedSearch) : undefined,
                savedSearchSaving: false,
                savedSearchesLoaded: false,
                error: undefined,
                deletingItem: undefined
            });
        }

        case ActionTypes.SAVED_SEARCHES_LOADED: {
            if (!action.payload.savedSearches) return Object.assign({}, state, {savedSearchesLoaded: true});
            const incomingResults = action.payload.savedSearches.searches;
            return Object.assign({}, state, {
                searchResults: incomingResults,
                allKnownResults: mergeResults(state.allKnownResults, incomingResults),
                totalAvailableResults: action.payload.savedSearches.total,
                savedSearchesLoaded: true
            });
        }
        case ActionTypes.SAVED_SEARCHES_UPDATE_FILTER: {
            if (state.pendingRequest) HttpRequestService.cancel(state.pendingRequest);
            return Object.assign({}, state, {
                moreLoading: false,
                currentFilter: action.payload.filter,
                pendingRequest: action.payload.requestToken,
                searchResults: undefined,
                totalAvailableResults: undefined
            });
        }
        case ActionTypes.SAVED_SEARCHES_MORE_LOADING: {
            return Object.assign({}, state, {
                moreLoading: true
            });
        }
        case ActionTypes.SAVED_SEARCHES_MORE_LOADED: {
            const incomingResults = action.payload.additionalSavedSearches.searches;
            return Object.assign({}, state, {
                moreLoading: false,
                searchResults: mergeResults(state.searchResults, incomingResults),
                allKnownResults: mergeResults(state.allKnownResults, incomingResults)
            });
        }
        case ActionTypes.SAVED_SEARCHES_LOADING_CANCELLED: {
            return state;
        }
        case ActionTypes.SAVED_SEARCHES_SAVING: {
            return Object.assign({}, state, {
                savedSearchSaving: true
            });
        }
        case ActionTypes.SAVED_SEARCHES_SAVED: {
            const savedEntity = SavedSearch.fromApi(action.payload.savedSearch);

            return Object.assign({}, state, {
                savedSearchSaving: false,
                currentSavedSearch: savedEntity,
                searchResults: undefined,
                allKnownResults: replaceOrAddResult(state.allKnownResults, savedEntity),
                currentFilter: ''
            });
        }
        case ActionTypes.SAVED_SEARCHES_SAVE_FAILED: {
            return Object.assign({}, state, {
                savedSearchSaving: false,
                error: action.payload.error
            });
        }
        case ActionTypes.SAVED_SEARCHES_LOAD_SEARCH: {
            const filter = state.allKnownResults.filter((r) => r.id === action.payload.id);
            const newCurrentSavedSearch = filter.length > 0 ? filter[0] : undefined;
            return Object.assign({}, state, {
                currentSavedSearch: newCurrentSavedSearch
            });
        }
        case ActionTypes.SAVED_SEARCHES_CLEAR_SEARCH: {
            return Object.assign({}, state, {
                currentSavedSearch: undefined
            });
        }
        case ActionTypes.SAVED_SEARCHES_DELETING_ITEM: {
            return Object.assign({}, state, {
                deletingItem: action.payload.id
            });
        }
        case ActionTypes.SAVED_SEARCHES_DELETING_ITEM_FAILED: {
            return Object.assign({}, state, {
                deletingItem: undefined,
                error: action.payload.error
            });
        }
        case ActionTypes.SAVED_SEARCHES_ITEM_DELETED: {
            return Object.assign({}, state, {
                searchResults: state.searchResults.filter((r) => r.id !== action.payload.id),
                totalAvailableResults: state.totalAvailableResults - 1
            });
        }
        default: {
            return state;
        }
    }
}