import { filter as filterTypes, getWindowFieldValue, SortOrder } from '@sprint/sprint-react-components';
import { FilterBlock } from '@sprint/sprint-react-components/dist/filter/FilterSISP';
import _ from 'lodash';
export interface SimpleFilterRequest {
    filterRequest: SimpleFilters;
    page: number;
    limit: number;
    sort?: string;
    order?: SortOrder;
    filter?: string | undefined;
}

export interface SimpleFilters {
    selectedFilters: filterTypes.SelectedFilters[];
}

export const generateSimpleFilterRequestBody = (filterSections: filterTypes.SelectedFilters[]) => {
    // Unlike the edu data going to the API,
    // Here we just want to loop over the fields and leave the structure mostly as-is, but replace/fix some values.
    const orConditions = (fieldFilter: filterTypes.FieldFilter, orCondition: FilterBlock) => {
        // Individual 'or' conditions for value manipulation
        if (fieldFilter.type == filterTypes.FieldType.DATE) {
            if (orCondition.operator.operator == filterTypes.DateOperator.IS_WINDOW) {
                orCondition.value = getWindowFieldValue(orCondition) ?? null;
            }
        }
        return orCondition;
    };
    const fieldBlock = (field: filterTypes.FieldFilter) => {
        // Each field / AND block
        field.orConditions = _.map(field.orConditions, (orCondition) => orConditions(field, orCondition));
        return field;
    };
    const filterBlock = (blocks: filterTypes.FieldFilter[]) => {
        // each OR block
        return _.map(blocks, (block) => fieldBlock(block));
    };
    const filterSection = (section: filterTypes.SelectedFilters) => {
        // Each section (i.e. Contact Filters, Organisation Filters etc)
        return { key: section.key, filters: _.map(section.filters, (block) => filterBlock(block)) };
    };
    const requestFilterBody: SimpleFilters = {
        selectedFilters: _.map(filterSections, (section) => filterSection(section)),
    };
    return requestFilterBody;
};
