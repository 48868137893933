import { OptionTypeBase } from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import BulkEditSingleFieldModal, { Field } from '../../../../CommonComponents/BulkEdit/BulkEditSingleFieldModal';
import { toFields, useCustomProperties } from '../../../../CommonComponents/BulkEdit/CustomPropertyHelpers';
import BulkUpdateRepository, { UpdateType } from '../../Api/BulkEditRepository';
import { Deal } from '../../Models/Deal';
import { DealPipeline, DealPipelineStage } from '../../Models/DealPipeline';

interface Props {
    rows: Deal[];
    close: () => void;
}

const DealsBulkEdit = (props: Props) => {
    const type: UpdateType = 'deals';

    const bulkUpdateRepository = new BulkUpdateRepository();

    const [pipeline, setPipeline] = useState<DealPipeline>();
    const [ids, setIds] = useState<number[]>([]);
    const [fields, setFields] = useState<Field[]>([]);
    const [customPropertyFields, setCustomPropertyFields] = useState<Field[]>([]);

    const customProperties = useCustomProperties(type);

    const getFields = (): Field[] => {
        const items: Field[] = [
            {
                key: 'owned_by',
                name: 'Owned By',
                type: 'owner',
                save: async (data: Record<string, any>) => {
                    await bulkUpdateRepository.update({
                        type,
                        field: 'owned_by',
                        value: data['owned_by'].id,
                        ids,
                    });
                    return;
                },
            },
            {
                key: 'tags',
                name: 'Tags',
                type: 'tags',
                save: async (data: Record<string, any>) => {
                    await bulkUpdateRepository.update({
                        type,
                        field: 'tags',
                        value: data['tags'],
                        ids,
                    });
                    return;
                },
                permissionKey: 'dealsCustomTags',
            },
        ];

        // can only bulk edit stage when all selected deals are on the same pipeline
        if (pipeline) {
            items.push({
                key: 'stage',
                name: 'Stage',
                type: 'enum',
                loadOptions: async (_filter: string, _loadedOptions, { page }) => {
                    const stages: OptionTypeBase[] = JSON.parse(pipeline.stages).map((stage: DealPipelineStage) => {
                        return {
                            value: stage.id,
                            label: stage.name,
                        };
                    });
                    return {
                        options: stages,
                        hasMore: false,
                        additional: {
                            page: page + 1,
                        },
                    };
                },
                save: async (data: Record<string, any>) => {
                    await bulkUpdateRepository.update({
                        type,
                        field: 'stage',
                        value: data['stage'].value,
                        ids,
                    });
                    return;
                },
            });
        } else {
            items.push({
                key: 'stage',
                name: 'Stage',
                type: 'warning',
                save: async () => {},
                message: 'The selected items are not all on the same pipeline.',
            });
        }

        items.push({
            key: 'close_date',
            name: 'Closed Date',
            type: 'date',
            save: async (data: Record<string, any>) => {
                await bulkUpdateRepository.update({
                    type,
                    field: 'close_date',
                    value: data['close_date'],
                    ids,
                });
                return;
            },
        });

        return items;
    };

    useEffect(() => {
        if (
            props.rows.length &&
            props.rows[0].pipeline?.id &&
            _.every(
                props.rows,
                (row: Deal) => row.pipeline && row.pipeline.id && row.pipeline.id === props.rows[0].pipeline!.id!,
            )
        ) {
            setPipeline(props.rows[0].pipeline);
        }
        setIds(_.map(props.rows, (row) => row.id!));
        setFields(getFields());
    }, []);

    useEffect(() => {
        if (customProperties && ids.length) {
            setCustomPropertyFields(toFields(type, customProperties, bulkUpdateRepository, ids));
        }
    }, [ids, customProperties]);

    useEffect(() => {
        if (customPropertyFields.length) {
            setFields([...getFields(), ...customPropertyFields]);
        }
    }, [customPropertyFields]);

    return <BulkEditSingleFieldModal fields={fields} close={props.close} />;
};

export default DealsBulkEdit;
