'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import './select.scss';
import Value from "./Pieces/Value";
import DropDown from "./Pieces/DropDown";
import DropDownStateless from "./Pieces/DropDownStateless";
import Spinner from "../Spinner/Spinner";

class Select extends React.Component {

    constructor(props) {
        super(props);

        // Allow removeEventListener on bound functions
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    getPositionOfDropDown() {
        if (!this.container) return null;
        const rects = this.container.getClientRects();
        if (rects.length === 0) return null;

        const element = rects[0];
        let offset = element.height - 1;

        // Should the drop down be placed above the text box?
        const windowHeight = window.innerHeight;
        let placeDropDownAbove = windowHeight - element.y < 350;

        // Do not place drop down above if the height of the dropdown won't fit above
        if (element.y < 350) placeDropDownAbove = false;

        if (placeDropDownAbove) offset = -Math.abs(offset);

        return offset;
    }

    handleClick(e) {
        if (!this.container || this.container.contains(e.target)) return;
        if (!this.props.open) return;
        this.onBlur();
        this.props.toggle();
    }

    showOptions(e) {
        e.preventDefault();
        if (this.props.open) this.onBlur();
        this.props.toggle();
    }

    onBlur() {
        if (!this.props.onBlur) return;
        this.props.onBlur();
    }

    updateSelectedOption(val) {
        this.props.updateSelectedOption(val);
    }

    clearOption(e) {
        e.preventDefault();
        e.stopPropagation(); // Prevent dropdown opening on clear
        this.props.updateSelectedOption(undefined);
    }

    getDropDown() {
        if (!this.props.open) return null;

        return this.props.updateFilter === undefined
            ? <DropDown
                selectedOptions={[this.props.selectedOption]}
                availableOptions={this.props.availableOptions}
                updateSelectedOptions={this.updateSelectedOption.bind(this)}
                multi={false}
                position={this.getPositionOfDropDown()}
                deleteItem={this.props.deleteItem}
                deletingItem={this.props.deletingItem}
                showFilter={this.props.showFilter}
                noResultsLabel={this.props.noResultsLabel}
            />
            : <DropDownStateless
                selectedOptions={[this.props.selectedOption]}
                availableOptions={this.props.availableOptions}
                updateSelectedOptions={this.updateSelectedOption.bind(this)}
                currentFilter={this.props.currentFilter}
                updateFilter={this.props.updateFilter}
                totalAvailable={this.props.totalAvailable}
                loadMore={this.props.loadMore}
                moreLoading={this.props.moreLoading}
                multi={false}
                position={this.getPositionOfDropDown()}
                deleteItem={this.props.deleteItem}
                deletingItem={this.props.deletingItem}
                showFilter={this.props.showFilter}
                noResultsLabel={this.props.noResultsLabel}
            />;
    }

    render() {
        let className = "react-select";
        if (this.props.open) className += ' react-select--open';

        let selectedOptionLabels = [];
        if (typeof this.props.selectedOption !== 'undefined') {
            // Find selected options from availableOptions or allKnownOptions arrays for correct label rendering
            // Note: allKnownOptions is used for when a selected option is not in scope of user's current connected filter search (allKnownOptions is optional)
            selectedOptionLabels = [].concat(this.props.availableOptions, this.props.allKnownOptions).filter((option) => {
                if (typeof option === 'undefined') return false;
                if (option.id) return String(option.id) === String(this.props.selectedOption);
                if (typeof option !== 'object') return String(option) === String(this.props.selectedOption);
                return false;
            }).map((option) => { // Extract correct value for label rendering
                if (!option.id) return option; // Options are just flat strings/numbers, use option itself
                // Options are in object form, use relevant property:
                if (option.labelElement) return option.labelElement;
                return option.label;
            });
        }

        // Only show optional clear button when something is selected
        let allowClear = selectedOptionLabels.length
            ? this.props.allowClear
            : false;

        // Show placeholder when no selection or spinner when loading selection
        // For single selections the placeholder is also used for value
        let placeholder = this.props.placeholder;
        if (!this.props.open
            && this.props.moreLoading
            && this.props.selectedOption
            && !selectedOptionLabels.length) placeholder = <Spinner/>;
        else if (selectedOptionLabels.length) placeholder = selectedOptionLabels[0];

        return <div className={className} ref={el => (this.container = el)}>
            <Value
                open={this.props.open}
                showOptions={this.showOptions.bind(this)}
                clearOptions={this.clearOption.bind(this)}
                allowClear={allowClear}
                placeholder={placeholder}
                shortenPlaceHolder={this.props.shortenPlaceholder}
                disabled={this.props.disabled}
            />
            {this.getDropDown()}
        </div>;
    }

}

Select.propTypes = {
    placeholder: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    shortenPlaceholder: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    selectedOption: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    availableOptions: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]).isRequired,
            label: PropTypes.string.isRequired,
            labelElement: PropTypes.element,
        })
    ])),
    updateSelectedOption: PropTypes.func.isRequired,
    allowClear: PropTypes.bool,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    showFilter: PropTypes.bool,
    noResultsLabel: PropTypes.string,

    // for ajax filtering:
    currentFilter: PropTypes.string,
    updateFilter: PropTypes.func,

    // for pagination:
    totalAvailable: PropTypes.number,
    moreLoading: PropTypes.bool,
    loadMore: PropTypes.func,

    // use these to add a delete button next to each option:
    deleteItem: PropTypes.func,
    deletingItem: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};

Select.defaultProps = {
    placeholder: 'Select',
    showFilter: true,
    noResultsLabel: 'No Results'
};

export default Select;