'use strict';

export const DEALS_UPDATE_FILTER = 'deals/DEALS_UPDATE_FILTER';
export const DEALS_LOADED = 'deals/DEALS_LOADED';
export const DEALS_MORE_LOADING = 'deals/DEALS_MORE_LOADING';
export const DEALS_MORE_LOADED = 'deals/DEALS_MORE_LOADED';
export const DEALS_IDS_LOADED = 'deals/DEALS_IDS_LOADED';
export const DEALS_LOADING_FAILED = 'deals/DEALS_LOADING_FAILED';
export const DEALS_LOADING_CANCELLED = 'deals/DEALS_LOADING_CANCELLED';
export const DEALS_UPDATING_PIPELINE = 'deals/DEALS_UPDATING_PIPELINE';
export const DEALS_UPDATED_PIPELINE = 'deals/DEALS_UPDATED_PIPELINE';
export const DEALS_UPDATING_PIPELINE_FAILED = 'deals/DEALS_UPDATING_PIPELINE_FAILED';
export const DEALS_DELETING = 'deals/DEALS_DELETING';
export const DEALS_DELETED = 'deals/DEALS_DELETED';
export const DEALS_DELETING_FAILED = 'deals/DEALS_DELETING_FAILED';

export const DEAL_NEW = 'deals/DEAL_NEW';
export const DEAL_UPDATE_FIELD = 'deals/DEAL_UPDATE_FIELD';
export const DEAL_SAVING = 'deals/DEAL_SAVING';
export const DEAL_SAVED = 'deals/DEAL_SAVED';
export const DEAL_SAVING_FAILED = 'deals/DEAL_SAVING_FAILED';
export const DEAL_SELECT = 'deals/DEAL_SELECT';
export const DEAL_LOADING = 'deals/DEAL_LOADING';
export const DEAL_LOADED = 'deals/DEAL_LOADED';
export const DEAL_LOADING_FAILED = 'deals/DEAL_LOADING_FAILED';
export const DEAL_INVALIDATED = 'deals/DEAL_INVALIDATED';
export const DEAL_DELETING = 'deals/DEAL_DELETING';
export const DEAL_DELETED = 'deals/DEAL_DELETED';
export const DEAL_LOADING_LINKS = 'deals/DEAL_LOADING_LINKS';
export const DEAL_LOADED_LINKS = 'deals/DEAL_LOADED_LINKS';
export const DEAL_LOADING_LINKS_FAILED = 'deals/DEAL_LOADING_LINKS_FAILED';
