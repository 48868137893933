'use strict';

import CampusEntity from "../../CampusEntity/CampusEntity";
import DealPipelineStage from "./DealPipelineStage/DealPipelineStage";
import User from "../../User/User";

export default class DealPipeline extends CampusEntity {

    id = undefined;
    name = '';
    stages = undefined;
    defaultDealLengthDays = 0;
    builtIn = false;
    isDefault = false;
    created = '';
    createdBy = undefined;
    modified = '';
    modifiedBy = undefined;

    static blank() {
        return new DealPipeline();
    }

    static fromApi(pipeline) {
        const entity = DealPipeline.blank();

        // Construct from expected API output object
        // Note: `is_logged_in` will not be set for the below Users, access via Client object
        entity.id = pipeline.id;
        entity.name = pipeline.name;
        entity.stages = pipeline.stages ? pipeline.stages.map(dealPipelineStage => DealPipelineStage.fromApi(dealPipelineStage)) : undefined;
        entity.defaultDealLengthDays = pipeline.default_deal_length_days;
        entity.builtIn = pipeline.built_in;
        entity.isDefault = pipeline.is_default;
        entity.created = pipeline.created;
        entity.createdBy = pipeline.created_by ? User.fromApi(pipeline.created_by) : undefined;
        entity.modified = pipeline.modified;
        entity.modifiedBy = pipeline.modified_by ? User.fromApi(pipeline.modified_by) : undefined;

        return entity;
    }

    static defaultStages() {
        return [DealPipelineStage.blankClosedWon(), DealPipelineStage.blankClosedLost()];
    }

    static select(entityArray, id) {
        return entityArray.find(entity => entity.matches(id));
    }

    matches(id) {
        return String(this.id) === String(id);
    }

    clone() {
        return Object.assign(DealPipeline.blank(), this);
    }

    forSelect() {
        return {
            id: this.id,
            label: this.name
        }
    }

    forApi() {
        const formData = new FormData();

        // Note: FormData will transmit a string of 'undefined' if the field is empty, so check before appending.
        if (this.id) formData.append('id', this.id);
        if (this.name) formData.append('name', this.name);
        if (this.stages) formData.append('stages', JSON.stringify(this.stages.map(dealPipelineStage => dealPipelineStage.forApi())));
        if (this.defaultDealLengthDays) formData.append('default_deal_length_days', this.defaultDealLengthDays);
        if (this.isDefault) formData.append('is_default', this.isDefault ? '1' : '0');

        return formData;
    }

}
