export const PAGE_SIZE = 250;

export const X_SMALL_COLUMN_WIDTH = 75;
export const SMALL_COLUMN_WIDTH = 100;
export const MEDIUM_COLUMN_WIDTH = 150;
export const DEFAULT_COLUMN_WIDTH = 200;
export const LARGE_COLUMN_WIDTH = 240;
export const X_LARGE_COLUMN_WIDTH = 290;
export const XX_LARGE_COLUMN_WIDTH = 400;

export const MONTHS_CHECKED_EXPIRES_AFTER = 6;
