import { Banner } from '@sprint/sprint-react-components';
import React, { FunctionComponent } from 'react';
import DealLostReasonsTable from './DealLostReasons/DealLostReasonsTable';
import DealPipelinesTable from './DealPipelines/DealPipelinesTable';
import DealTypesTable from './DealTypes/DealTypesTable';

const childTableHeightShown = '22rem';

const DealsTableCollection: FunctionComponent<any> = () => {
    const dealTypeUniqueKey = 'deal_type';
    const dealLostReasonUniqueKey = 'deal_lost_reason';
    const dealPipelinesUniqueKey = 'deal_pipelines';

    return (
        <div>
            <div id="list-deal-types" className="data-table-after">
                <h4 className="datatable-settings-header">Deal Types</h4>
                <div
                    id="DealTypesTable"
                    className="react-data-grid-flex-container"
                    style={{ height: childTableHeightShown }}
                >
                    <Banner target={dealTypeUniqueKey} />
                    <DealTypesTable
                        searchFilterPlaceholder="Search Deal Type"
                        dataGridUniqueKey={dealTypeUniqueKey}
                        dataGridEntitySingular="Deal Type"
                        dataGridEntityPlural="Deal Types"
                    />
                </div>
            </div>
            <div id="list-deal-lost-reasons" className="data-table-after">
                <h4 className="datatable-settings-header">Deal Lost Reason</h4>
                <div
                    id="DealLostReasonsTable"
                    className="react-data-grid-flex-container"
                    style={{
                        height: childTableHeightShown,
                    }}
                >
                    <Banner target={dealLostReasonUniqueKey} />
                    <DealLostReasonsTable
                        searchFilterPlaceholder="Search Deal Lost Reason"
                        dataGridUniqueKey={dealLostReasonUniqueKey}
                        dataGridEntitySingular="Reason"
                        dataGridEntityPlural="Reasons"
                    />
                </div>
            </div>
            <div id="list-deal-pipelines">
                <h4 className="datatable-settings-header">Deal Pipelines</h4>
                <div
                    id="DealPipelinesTable"
                    className="react-data-grid-flex-container"
                    style={{ height: childTableHeightShown }}
                >
                    <Banner target={dealPipelinesUniqueKey} />
                    <DealPipelinesTable
                        searchFilterPlaceholder="Search Deal Pipeline"
                        dataGridUniqueKey={dealPipelinesUniqueKey}
                        dataGridEntitySingular="Pipeline"
                        dataGridEntityPlural="Pipelines"
                    />
                </div>
            </div>
        </div>
    );
};

export default DealsTableCollection;
