'use strict';

import CampusEntity from "../CampusEntity/CampusEntity";
import User from "../User/User";
import Contact from "../Contact/Contact";
import Organisation from "../Organisation/Organisation";
import Price from "../../ValueObjects/Price/Price";
import SaleItem from "./SaleItem";
import PaymentItem from "./PaymentItem";
import Quote from "../Quote/Quote";
import DateHelper from "../../Helpers/DateHelper";

export default class Sale extends CampusEntity {

    id = undefined;
    invoiceNumber = '';
    invoiceDate = '';
    dueDate = '';
    purchaseOrderNumber = '';
    note = '';
    contact = undefined;
    organisation = undefined;
    items = [];
    status = '';
    grossTotal = undefined;
    vatTotal = undefined;
    netTotal = undefined;
    amountDue = undefined;
    soldBy = undefined;
    payments = [];
    quote = undefined;
    created = '';
    createdBy = undefined;
    modified = '';
    modifiedBy = undefined;

    static blank() {
        return new Sale();
    }

    static fromApi(sale) {
        const entity = Sale.blank();

        // Construct from expected API output object
        // Note: `is_logged_in` will not be set for the below Users, access via Client object
        entity.id = sale.id;
        entity.invoiceNumber = sale.invoice_number;
        entity.invoiceDate = sale.invoice_date;
        entity.dueDate = sale.due_date;
        entity.purchaseOrderNumber = sale.purchase_order_number;
        entity.note = sale.note;
        entity.contact = sale.contact ? Contact.fromApi(sale.contact) : undefined;
        entity.organisation = sale.organisation ? Organisation.fromApi(sale.organisation) : undefined;
        entity.items = sale.items ? sale.items.map(item => SaleItem.fromApi(item)) : undefined;
        entity.status = sale.status;
        entity.grossTotal = sale.gross_total ? Price.fromApi(sale.gross_total) : undefined;
        entity.vatTotal = sale.vat_total ? Price.fromApi(sale.vat_total) : undefined;
        entity.netTotal = sale.net_total ? Price.fromApi(sale.net_total) : undefined;
        entity.amountDue = sale.amount_due ? Price.fromApi(sale.amount_due) : undefined;
        entity.soldBy = sale.sold_by ? User.fromApi(sale.sold_by) : undefined;
        entity.payments = sale.payments ? sale.payments.map(item => PaymentItem.fromApi(item)) : undefined;
        entity.quote = sale.quote ? Quote.fromApi(sale.quote) : undefined;
        entity.created = sale.created;
        entity.createdBy = sale.created_by ? User.fromApi(sale.created_by) : undefined;
        entity.modified = sale.modified;
        entity.modifiedBy = sale.modified_by ? User.fromApi(sale.modified_by) : undefined;

        return entity;
    }

    static select(entityArray, id) {
        return entityArray.find(entity => entity.matches(id));
    }

    matches(id) {
        return String(this.id) === String(id);
    }

    clone() {
        return Object.assign(Sale.blank(), this);
    }

    forSelect() {
        return {
            id: this.id,
            label: this.invoiceNumber + (this.invoiceDate ? ' - ' + DateHelper.formattedString(this.invoiceDate, false) : '')
        }
    }

    makeViewUrl(client) {
        const relativePath = 'sales/view/' + this.id;
        if (client) return client.makeUrl(relativePath);
        else return relativePath;
    }

    static makeAddUrl(quoteId = 0, organisationId = 0, contactId = 0, dealId = 0, client = undefined) {
        const relativePath = `sales/add/${quoteId}/${organisationId}/${contactId}/${dealId}`;
        if (client) return client.makeUrl(relativePath);
        else return relativePath;
    }

}
