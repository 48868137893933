import React, { FunctionComponent } from 'react';
import '../ViewPanel/viewpanel.scss';
import './loadingindicator.scss';

interface Props {
    noBorder?: boolean;
    noLabelBar?: boolean;
    large?: boolean;
    randomWidthLimit?: number;
}

const DEFAULT_RANDOM_WIDTH_LIMIT = 250;

const Item: FunctionComponent<Props> = (props: Props) => {
    const width = Math.floor(Math.random() * (props.randomWidthLimit ?? DEFAULT_RANDOM_WIDTH_LIMIT)) + 100;
    const sizeClassModifier = props.large ? ' large' : '';

    return (
        <div className={'react-view-panel-item' + (props.noBorder ? ' borderless' : '')}>
            {props.noLabelBar ? null : (
                <div className={'react-loading-bar' + sizeClassModifier} style={{ width: width + 'px' }} />
            )}
            <div
                className={'react-loading-bar input-bar' + sizeClassModifier}
                style={{ width: width + 50 + 'px', marginBottom: 0 }}
            />
        </div>
    );
};

export default Item;
