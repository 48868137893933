import * as ActionTypes from "./ActionTypes";
import * as EntityState from "../../ValueObjects/EntityState/EntityState";
import Sale from "./Sale";
import HttpRequestService from "../../Services/HttpRequestService/HttpRequestService";
import Deal from "../Deal/Deal";

const initialState = {
    currentFilter: '',
    searchResults: undefined,
    allKnownResults: [],
    pendingRequest: undefined,
    totalAvailableResults: undefined,
    moreLoading: false
};

// Note:  When the times comes to fully upgrade Sales/Quotes to react, copy the rest of DealReducer.js into here

function select(allKnownResults, id) {
    return Object.assign(Sale.blank(), Sale.select(allKnownResults, id));
}

function initialiseResults(incomingResults) {
    return incomingResults.map(result => result.transitionState(EntityState.LOADED));
}

function mergeResults(existingResults, newResults) {
    if (existingResults === undefined) return newResults;
    return existingResults.filter(existingResult => {
        return newResults.map(newResult => newResult.id).indexOf(existingResult.id) === -1;
    }).map(existingResult => existingResult.clone()).concat(newResults);
}

function replaceResult(allKnownResults, incomingResult) {
    if (allKnownResults === undefined) return undefined;
    const matches = result => result.id === incomingResult.id;
    return allKnownResults.map(result => {
        const updatedResult = result.clone();
        return matches(result) ? incomingResult.clone() : updatedResult;
    });
}

function replaceOrAddResult(allKnownResults, incomingResult) {
    if (allKnownResults === undefined) allKnownResults = [];
    const matches = result => result.id === incomingResult.id;
    const newKnownResults = replaceResult(allKnownResults, incomingResult);
    if (allKnownResults.find(matches) === undefined) newKnownResults.push(incomingResult.clone());
    return newKnownResults;
}

export default function (state, action) {
    if (state === undefined) {
        state = initialState;
    }

    switch (action.type) {
        case ActionTypes.SALES_LOADED: {
            const incomingResults = initialiseResults(action.payload.sales.items);
            return Object.assign({}, state, {
                searchResults: incomingResults,
                allKnownResults: mergeResults(state.allKnownResults, incomingResults),
                totalAvailableResults: action.payload.sales.total ? action.payload.sales.total : state.totalAvailableResults
            });
        }
        case ActionTypes.SALES_UPDATE_FILTER: {
            if (state.pendingRequest) HttpRequestService.cancel(state.pendingRequest);
            return Object.assign({}, state, {
                moreLoading: false,
                currentFilter: action.payload.textFilter,
                pendingRequest: action.payload.requestToken,
                searchResults: undefined,
                totalAvailableResults: undefined
            });
        }
        case ActionTypes.SALES_MORE_LOADING: {
            return Object.assign({}, state, {
                moreLoading: true
            });
        }
        case ActionTypes.SALES_MORE_LOADED: {
            const incomingResults = initialiseResults(action.payload.sales.items);
            return Object.assign({}, state, {
                moreLoading: false,
                searchResults: mergeResults(state.searchResults, incomingResults),
                allKnownResults: mergeResults(state.allKnownResults, incomingResults)
            });
        }
        case ActionTypes.SALES_LOADING_CANCELLED: {
            return state;
        }
        case ActionTypes.SALES_IDS_LOADED: {
            const incomingResults = initialiseResults(action.payload.sales.items);
            return Object.assign({}, state, {
                moreLoading: false,
                searchResults: mergeResults(state.searchResults, incomingResults),
                allKnownResults: mergeResults(state.allKnownResults, incomingResults)
            });
        }
        case ActionTypes.SALE_LINKING_TO_DEAL: {
            const savingEntity = select(state.allKnownResults, action.payload.id);
            savingEntity.transitionState(EntityState.SAVING);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, savingEntity),
                searchResults: replaceResult(state.searchResults, savingEntity)
            });
        }
        case ActionTypes.SALE_LINKED_TO_DEAL: {
            try {
                const savedEntity = Sale.fromApi(action.payload.data);
                savedEntity.transitionState(EntityState.SAVED);

                return Object.assign({}, state, {
                    allKnownResults: replaceOrAddResult(state.allKnownResults, savedEntity),
                    searchResults: replaceResult(state.searchResults, savedEntity),
                    selectedId: savedEntity.id
                });
            } catch (e) {
                const targetEntity = select(state.allKnownResults, action.payload.id);
                targetEntity.transitionState(EntityState.ERROR, 'Unable to initialise sale object: ' + e.message);

                return Object.assign({}, state, {
                    allKnownResults: replaceOrAddResult(state.allKnownResults, targetEntity)
                });
            }
        }
        case ActionTypes.SALE_LINKING_TO_DEAL_FAILED: {
            const targetEntity = select(state.allKnownResults, action.payload.id);
            targetEntity.transitionState(EntityState.ERROR, action.payload.error);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, targetEntity),
                searchResults: replaceResult(state.searchResults, targetEntity)
            });
        }
        default: {
            return state;
        }
    }
}