'use strict';

import PropTypes from 'prop-types';
import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import SavedSearch from '../../../../Entities/SavedSearch/SavedSearch';

class Closed extends React.Component {
    saveExisting() {
        this.props.save(this.props.currentSavedSearch);
    }

    saveButton() {
        if (!this.props.currentSavedSearch) return null;
        return (
            <Button size="sm" variant="default" onClick={this.saveExisting.bind(this)}>
                Save
            </Button>
        );
    }

    saveAsButton() {
        return (
            <Button size="sm" variant="default" onClick={this.props.toggle}>
                Save as
            </Button>
        );
    }

    render() {
        return (
            <ButtonGroup>
                {this.saveButton()}
                {this.saveAsButton()}
            </ButtonGroup>
        );
    }
}

Closed.propTypes = {
    toggle: PropTypes.func,
    currentSavedSearch: PropTypes.instanceOf(SavedSearch),
    save: PropTypes.func.isRequired,
};

export default Closed;
