import { DefaultPillFormatter, ExtendedColumn, HoveroverButton } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext } from 'react';
import { EngagementMethodsRequest } from '../../Api/EngagementMethodsRequest';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import EngagementMethodType from '../../Models/EngagementMethodType';
import { UniqueKeyType } from '../../Models/Enums';
import { RepositoryFactoryContext } from '../../index';
import CampusDataGrid, { ActionBarMeta, AddSispMeta, DataGridMeta, PromptMeta } from '../CampusDataGrid';
import EngagementMethodsAddSisp from './EngagementMethodsAddSisp';
import EngagementMethodsEditSisp from './EngagementMethodsEditSisp';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

enum EngagementMethodsColumnKey {
    ID,
    ENGAGEMENT_METHOD,
    PROCESSING_GROUND,
    DEFAULT_DATE,
}

const EngagementMethodsTable: FunctionComponent<Props> = (props: Props) => {
    const editSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);

    const engagementMethodsRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new EngagementMethodsRequest(),
    );
    const Columns: Record<EngagementMethodsColumnKey, ExtendedColumn> = {
        [EngagementMethodsColumnKey.ID]: {
            key: 'id',
            name: 'ID',
            sortable: false,
        },
        [EngagementMethodsColumnKey.ENGAGEMENT_METHOD]: {
            key: 'engagement_method',
            name: 'Engagement Method',
            sortable: true,
            width: '1fr',
            renderCell: (fprops) => {
                const engagement_method = (fprops.row as EngagementMethodType).engagement_method;
                const is_default = (fprops.row as EngagementMethodType).is_default;
                return (
                    <>
                        <DefaultPillFormatter cellContent={engagement_method} showPill={is_default}>
                            <HoveroverButton
                                contents="Edit"
                                eventBusMessageTarget={editSispUniqueKey}
                                eventBusMessage={fprops.row as EngagementMethodType}
                            />
                        </DefaultPillFormatter>
                    </>
                );
            },
        },
        [EngagementMethodsColumnKey.PROCESSING_GROUND]: {
            key: 'processing_ground',
            name: 'Processing Ground',
            sortable: true,
            width: '1fr',
        },
        [EngagementMethodsColumnKey.DEFAULT_DATE]: {
            key: 'default_date',
            name: 'Default Date',
            sortable: true,
            width: '1fr',
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[EngagementMethodsColumnKey.ENGAGEMENT_METHOD],
        Columns[EngagementMethodsColumnKey.PROCESSING_GROUND],
        Columns[EngagementMethodsColumnKey.DEFAULT_DATE],
    ];

    const dataGridMeta: DataGridMeta = {
        uniqueKey: props.dataGridUniqueKey,
        entitySingular: props.dataGridEntitySingular,
        entityPlural: props.dataGridEntityPlural,
        columnOptions: Columns,
        defaultColumns: DefaultColumns,
        frozenColumns: [],
    };

    const actionBarMeta: ActionBarMeta = {
        searchPlaceHolder: props.searchFilterPlaceholder,
        includeCounts: true,
    };

    const addSispMeta: AddSispMeta = {
        key: UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP),
        sisp: EngagementMethodsAddSisp,
    };

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_engagement_methods.png',
        iconHeight: 80,
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.CREATING_ENGAGEMENT_METHODS),
    };

    return (
        <>
            <CampusDataGrid
                repository={engagementMethodsRepository}
                actionBarMeta={actionBarMeta}
                addSispMeta={addSispMeta}
                editSispMeta={{ sisp: EngagementMethodsEditSisp }}
                dataGridMeta={dataGridMeta}
                promptMeta={promptMeta}
            />
        </>
    );
};

export default EngagementMethodsTable;
