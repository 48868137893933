import { AppEvent, EventBusInstance } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import LimitIssue from '../../Models/LimitIssue';

declare function dictionaryValue(key: string): string;

interface Props {
    uniqueKey: string;
    limitName: string;
}

const MultipleLimitBanner: FunctionComponent<Props> = (props: Props) => {
    // State: General
    const [shown, setShown] = useState<boolean>(false);
    const [dismissable, setDismissable] = useState<boolean>(true);
    const [limitIssues, setLimitIssues] = useState<LimitIssue[]>([]);

    useEffect(() => {
        EventBusInstance.subscribe('limit-banner', (event: AppEvent<LimitIssue[] | null>) => {
            if (event.target !== props.uniqueKey) return;
            if (event.message === null) {
                setShown(false);
            } else {
                setLimitIssues(event.message);
                // If there is at least one limit reached the banner cant be dismissed
                setDismissable(!event.message.some((limitIssue: LimitIssue) => !limitIssue.near_limit));
                setShown(true);
            }
        });
    }, []);

    const supportEmail = dictionaryValue('company.email_support');
    const mailToSupport = `mailto:${supportEmail}`;

    return (
        <div className="banner-container">
            <Alert show={shown} onClose={() => setShown(false)} variant="danger" dismissible={dismissable}>
                <b>The following {props.limitName} limits:</b>
                <ul>
                    {limitIssues.map((limitIssue: LimitIssue) => {
                        return limitIssue.near_limit ? (
                            <li>
                                You've used {limitIssue.used_count}/{limitIssue.limit} {limitIssue.type}{' '}
                                {props.limitName} and are nearing your limit
                            </li>
                        ) : (
                            <li>
                                You've reached your limit of {limitIssue.limit} {limitIssue.type} {props.limitName}
                            </li>
                        );
                    })}
                </ul>
                <>
                    Unlock space for more by&nbsp;
                    <a
                        href={mailToSupport}
                        className="rdg-link"
                        style={{ color: '#ffffff', textDecoration: 'underline' }}
                    >
                        upgrading your plan.
                    </a>
                </>
            </Alert>
        </div>
    );
};

export default MultipleLimitBanner;
