import HttpRequestService from '../../Services/HttpRequestService/HttpRequestService';

export function teachers(schoolId, educationDataSource) {
    return new Promise(function (resolve, reject) {
        new HttpRequestService()
            .get(`education/${educationDataSource}/schools/get_teachers_by_school_id/${schoolId}`)
            .then((response) => {
                if (!response) return reject('Invalid response returned. ERR-REACT-SCH-01');
                if (response.status !== 200)
                    return reject('Unable to load teachers, endpoint not responding. ERR-REACT-SCH-02');
                if (response.data.success === false) return reject(response.data.error);
                resolve(response.data.data);
            })
            .catch((error) => reject(error.message));
    });
}

export function unsubscribeTeacher(teacherId, educationSource) {
    return new Promise(function (resolve, reject) {
        new HttpRequestService()
            .get('education/ajax/unsubscribe_teacher/' + teacherId + '/' + educationSource)
            .then((response) => {
                if (!response) return reject('Invalid response returned. ERR-REACT-SCH-03');
                if (response.status !== 200)
                    return reject('Unable to load teachers, endpoint not responding. ERR-REACT-SCH-04');
                if (response.data.success === false) return reject(response.data.error);
                resolve(response.data.data);
            })
            .catch((error) => reject(error.message));
    });
}
