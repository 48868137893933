import { APP_COLOURS } from '../AppColours';

interface ChartColour {
    backgroundColor: string;
    borderColor: string;
}

class ChartColourHelper {
    public static availableColours: Array<string> = [
        APP_COLOURS.DARK_BLUE,
        APP_COLOURS.STANDARD_BLUE,
        APP_COLOURS.GREEN,
        APP_COLOURS.BRIGHT_GREEN,
        APP_COLOURS.ORANGE,
        APP_COLOURS.PINK,
    ];

    public static colourCycle(index: number): string {
        return '#' + this.availableColours[index % this.availableColours.length];
    }

    public static primarySettings(): ChartColour {
        return this.chartColour(APP_COLOURS.STANDARD_BLUE);
    }

    public static secondarySettings(): ChartColour {
        return this.chartColour(APP_COLOURS.GREEN);
    }

    private static chartColour(colour: string): ChartColour {
        return {
            backgroundColor: '#' + colour,
            borderColor: '#' + colour,
        };
    }
}

export default ChartColourHelper;
