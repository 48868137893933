import { faArrowDown, faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';

interface Props {
    /** float percentage difference with 0 as the base -
     * e.g. 0.6 would display as 60%, -0.23 would display as -23%, 2.3 would display as 230% */
    diff: number;
}

const PercentDiff: FunctionComponent<Props> = (props: Props) => {
    return props.diff > 0 ? (
        <span className="text-success">
            <strong>
                {props.diff.toFixed(1)}% <FontAwesomeIcon icon={faArrowUp} />
            </strong>
        </span>
    ) : (
        <span className="text-danger">
            <strong>
                {/* Flip the number to positive for display */}
                {Math.abs(props.diff).toFixed(1)}% <FontAwesomeIcon icon={faArrowDown} />
            </strong>
        </span>
    );
};

export default PercentDiff;
