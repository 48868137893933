'use strict';

export const DEALLOSTREASONS_LOADED = 'dealLostReasons/DEALLOSTREASONS_LOADED';
export const DEALLOSTREASONS_LOADING_FAILED = 'dealLostReasons/DEALLOSTREASONS_LOADING_FAILED';

export const DEALLOSTREASON_NEW = 'dealLostReasons/DEALLOSTREASON_NEW';
export const DEALLOSTREASON_UPDATE_FIELD = 'dealLostReasons/DEALLOSTREASON_UPDATE_FIELD';
export const DEALLOSTREASON_SAVING = 'dealLostReasons/DEALLOSTREASON_SAVING';
export const DEALLOSTREASON_SAVED = 'dealLostReasons/DEALLOSTREASON_SAVED';
export const DEALLOSTREASON_SAVING_FAILED = 'dealLostReasons/DEALLOSTREASON_SAVING_FAILED';
export const DEALLOSTREASON_SELECT = 'dealLostReasons/DEALLOSTREASON_SELECT';
export const DEALLOSTREASON_LOADING = 'dealLostReasons/DEALLOSTREASON_LOADING';
export const DEALLOSTREASON_LOADED = 'dealLostReasons/DEALLOSTREASON_LOADED';
export const DEALLOSTREASON_LOADING_FAILED = 'dealLostReasons/DEALLOSTREASON_LOADING_FAILED';
export const DEALLOSTREASON_INVALIDATED = 'dealLostReasons/DEALLOSTREASON_INVALIDATED';
export const DEALLOSTREASON_DELETING = 'dealLostReasons/DEALLOSTREASON_DELETING';
export const DEALLOSTREASON_DELETED = 'dealLostReasons/DEALLOSTREASON_DELETED';