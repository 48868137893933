'use strict';

import CampusEntity from "../CampusEntity/CampusEntity";
import User from "../User/User";
import Contact from "../Contact/Contact";
import Organisation from "../Organisation/Organisation";
import Price from "../../ValueObjects/Price/Price";
import QuoteItem from "./QuoteItem";
import DateHelper from "../../Helpers/DateHelper";

export default class Quote extends CampusEntity {

    id = undefined;
    contact = undefined;
    organisation = undefined;
    owner = undefined;
    quoteNumber = '';
    quoteDate = '';
    dueDate = '';
    quoteStatus = '';
    closedReason = '';
    archived = false;
    notes = '';
    items = [];
    netTotal = undefined;
    vatTotal = undefined;
    grossTotal = undefined;
    created = '';
    createdBy = undefined;
    modified = '';
    modifiedBy = undefined;

    static blank() {
        return new Quote();
    }

    static fromApi(quote) {
        const entity = Quote.blank();

        // Construct from expected API output object
        // Note: `is_logged_in` will not be set for the below Users, access via Client object
        entity.id = quote.id;
        entity.contact = quote.contact ? Contact.fromApi(quote.contact) : undefined;
        entity.organisation = quote.organisation ? Organisation.fromApi(quote.organisation) : undefined;
        entity.owner = quote.owner ? User.fromApi(quote.owner) : undefined;
        entity.quoteNumber = quote.quote_number;
        entity.quoteDate = quote.quote_date;
        entity.dueDate = quote.due_date;
        entity.quoteStatus = quote.quote_status;
        entity.closedReason = quote.closed_reason;
        entity.archived = quote.archived;
        entity.notes = quote.notes;
        entity.items = quote.items ? quote.items.map(item => QuoteItem.fromApi(item)) : undefined;
        entity.netTotal = quote.net_total ? Price.fromApi(quote.net_total) : undefined;
        entity.vatTotal = quote.vat_total ? Price.fromApi(quote.vat_total) : undefined;
        entity.grossTotal = quote.gross_total ? Price.fromApi(quote.gross_total) : undefined;
        entity.created = quote.created;
        entity.createdBy = quote.created_by ? User.fromApi(quote.created_by) : undefined;
        entity.modified = quote.modified;
        entity.modifiedBy = quote.modified_by ? User.fromApi(quote.modified_by) : undefined;

        return entity;
    }

    static select(entityArray, id) {
        return entityArray.find(entity => entity.matches(id));
    }

    matches(id) {
        return String(this.id) === String(id);
    }

    clone() {
        return Object.assign(Quote.blank(), this);
    }

    forSelect() {
        return {
            id: this.id,
            label: this.quoteNumber + (this.quoteDate ? ' - ' + DateHelper.formattedString(this.quoteDate, false) : '')
        }
    }

    makeViewUrl(client) {
        const relativePath = 'quotes/view/' + this.id;
        if (client) return client.makeUrl(relativePath);
        else return relativePath;
    }

    static makeAddUrl(organisationId = 0, contactId = 0, dealId = 0, client = undefined) {
        const relativePath = `quotes/add/${organisationId}/${contactId}/${dealId}`;
        if (client) return client.makeUrl(relativePath);
        else return relativePath;
    }

}
