import { REHYDRATE } from 'redux-persist';
import PersistenceKey from "../../../Helpers/PersistenceKey";
import * as ActionTypes from '../Actions/ActionTypes';

const initialState = {
    selectedMultiView: 'Table',
    changeDataTableVisibility: false,
    fromPersistedState: false
};

export default function (state, action) {
    if (state === undefined) {
        state = initialState;
    }

    const newFieldBlurList = Object.assign({}, state.fieldBlurList);

    switch (action.type) {
        case REHYDRATE:
            const reducerNameInRoot = 'board';
            if (!PersistenceKey.checkActionForRoot(action, reducerNameInRoot)) return state;
            const serializedState = action.payload[reducerNameInRoot];
            return Object.assign({}, state, serializedState, {
                changeDataTableVisibility: true,
                fromPersistedState: true
            });

        case ActionTypes.DATA_TABLE_VISIBILITY_CHANGED:
            return Object.assign({}, state, {
                changeDataTableVisibility: false,
                fromPersistedState: false
            });
        case ActionTypes.SWITCH_MULTI_VIEW:
            return Object.assign({}, state, {
                selectedMultiView: action.payload.selectedMultiView,
                changeDataTableVisibility: true,
                fromPersistedState: false
            });
        default:
            return state;
    }
}