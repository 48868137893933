import { LogLevel, SearchQuery, SortOrder, showBanner } from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { RepositoryFactoryContext } from '../..';
import BulkEditSingleFieldModal, { Field } from '../../../../CommonComponents/BulkEdit/BulkEditSingleFieldModal';
import { toFields, useCustomProperties } from '../../../../CommonComponents/BulkEdit/CustomPropertyHelpers';
import IEngagementMethod from '../../../../Entities/EngagementMethod/Interfaces/IEngagementMethod';
import BulkUpdateRepository, { UpdateType } from '../../Api/BulkEditRepository';
import { EngagementMethodsRequest } from '../../Api/EngagementMethodsRequest';
import Contact from '../../Models/Contact';

interface Props {
    rows: Contact[];
    close: () => void;
}

const ContactsBulkEdit = (props: Props) => {
    const type: UpdateType = 'contacts';

    const engagementMethodRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new EngagementMethodsRequest(),
    );

    const bulkUpdateRepository = new BulkUpdateRepository();

    const [ids, setIds] = useState<number[]>([]);
    const [fields, setFields] = useState<Field[]>([]);
    const [customPropertyFields, setCustomPropertyFields] = useState<Field[]>([]);

    const customProperties = useCustomProperties(type);

    const loadEngagementMethods = async (
        filter?: string,
        page?: number,
    ): Promise<{ value: number; label: string; iso: string }[]> => {
        const query = new SearchQuery(page ?? 1, 100, 'id', SortOrder.ASC, filter);
        return engagementMethodRepository
            .search(query)
            .then((results) => {
                const res = _.map(results.results as any, (engagementMethod: IEngagementMethod) => {
                    return {
                        value: engagementMethod.id ?? 0,
                        label: engagementMethod.engagement_method ?? '',
                        iso: engagementMethod.iso,
                        raw: engagementMethod,
                    };
                });
                return res;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to get Engagement Methods - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return [];
            });
    };

    const getFields = (): Field[] => [
        {
            key: 'owned_by',
            name: 'Owned By',
            type: 'owner',
            save: async (data: Record<string, any>) => {
                await bulkUpdateRepository.update({
                    type,
                    field: 'owned_by',
                    value: data['owned_by'].id,
                    ids,
                });
                return;
            },
        },
        {
            key: 'engagement_method',
            name: 'Engagement Method',
            type: 'enum',
            loadOptions: async (filter: string, _loadedOptions, { page }) => {
                const res = await loadEngagementMethods(filter, page);
                return {
                    options: res,
                    hasMore: false,
                    additional: {
                        page: page + 1,
                    },
                };
            },
            save: async (data: Record<string, any>) => {
                await bulkUpdateRepository.update({
                    type,
                    field: 'engagement_method_id',
                    value: data['engagement_method'].value,
                    ids,
                });
                return;
            },
        },
        {
            key: 'tags',
            name: 'Tags',
            type: 'tags',
            save: async (data: Record<string, any>) => {
                await bulkUpdateRepository.update({
                    type,
                    field: 'tags',
                    value: data['tags'],
                    ids,
                });
                return;
            },
            permissionKey: 'contactsCustomTags',
        },
    ];

    useEffect(() => {
        setIds(_.map(props.rows, (row) => row.id));
        setFields(getFields());
    }, []);

    useEffect(() => {
        if (customProperties && ids.length) {
            setCustomPropertyFields(toFields(type, customProperties, bulkUpdateRepository, ids));
        }
    }, [ids, customProperties]);

    useEffect(() => {
        if (customPropertyFields.length) {
            setFields([...getFields(), ...customPropertyFields]);
        }
    }, [customPropertyFields]);

    return <BulkEditSingleFieldModal fields={fields} close={props.close} />;
};

export default ContactsBulkEdit;
