import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    EventBusInstance,
    ExportableField,
    LogLevel,
    Modal,
    ModalType,
    showBanner,
} from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import DataGridRepository from '../Api/DataGridRepository';
import { SimpleFilterRequest } from './Filters';

interface Props {
    entity: string;
    filter: SimpleFilterRequest;
    selectedFields: string[];
    exportableFields: ExportableField[];
    repository: DataGridRepository<any>;
    modalShown: boolean;
    closeModal: () => void;
    totalRecords?: number;
    setExportModalShow: (value: boolean) => void;
    /** Any external parameters that should be sent to the export API */
    externalParameters?: any;
    /** Disclaimer element to show before triggering the export, for example a limit warning */
    disclaimer?: React.ReactNode;
    disclaimerTitle?: string;
    /** When true, the progress bar will always be full and animated */
    noProgress?: boolean;
    /** When true, ignores any specific selected IDs when running the export (All rows will be exported) */
    allSelected?: boolean;
}

export const SimpleExportProgressModal: FunctionComponent<Props> = (props: Props) => {
    const [exportedRows, setExportedRows] = useState(0);
    const [exportRetryAttempted, setExportRetryAttempted] = useState(false);
    const [exportCancelRequested, setExportCancelRequested] = useState(false);
    const exportCancelRequestedRef = useRef(exportCancelRequested);

    const [exportStarted, setExportStarted] = useState<boolean>(false);

    const [selectedIds, setSelectedIds] = useState<number[]>();
    EventBusInstance.subscribe('selected-rows-change', (event) => {
        setSelectedIds(() => {
            return Array.from((event.message as Set<number>).values());
        });
    });

    let exportedRowsCount = 0;

    useEffect(() => {
        exportCancelRequestedRef.current = exportCancelRequested;
    }, [exportCancelRequested]);

    const onStartExport = async (): Promise<boolean> => {
        // Reset export progress
        setExportedRows(0);
        setExportCancelRequested(false);
        setExportRetryAttempted(false);

        return props.repository
            .export(
                props.entity,
                props.filter,
                _.filter(props.exportableFields, (exportableField) =>
                    _.includes(props.selectedFields, exportableField.key),
                ),
                (rows: number) => {
                    setExportedRows(rows);
                    exportedRowsCount += rows;
                },
                () => exportCancelRequestedRef.current,
                () => {
                    setExportRetryAttempted(true);
                },
                { ...props.externalParameters, selectedIds: !props.allSelected ? selectedIds : undefined },
            )
            .then((complete) => {
                if (complete) {
                    showBanner({
                        message: 'Your export was completed successfully.',
                        level: LogLevel.SUCCESS,
                    });
                    EventBusInstance.publish({
                        topic: 'update-datagrid-rows',
                        message: { exportedRows: exportedRowsCount },
                        target: 'export-complete',
                    });
                    props.closeModal();
                } else {
                    showBanner({
                        message: 'Your export has been cancelled.',
                        level: LogLevel.SUCCESS,
                    });
                }
                props.setExportModalShow(false);
                return complete;
            })
            .catch((ex) => {
                showBanner({
                    message: `Unable to complete export - ${ex.message}`,
                });
                props.setExportModalShow(false);
                return false;
            });
    };

    const onCancelExport = async (): Promise<boolean> => {
        setExportCancelRequested(true);
        props.setExportModalShow(false);
        return true;
    };

    const onConfirmDisclaimer = async (): Promise<boolean> => {
        setExportStarted(true);
        return true;
    };

    useEffect(() => {
        // Start the export on show
        if (exportStarted) onStartExport();
    }, [exportStarted]);

    useEffect(() => {
        // Start the export on show
        if (props.modalShown && !props.disclaimer) {
            return setExportStarted(true);
        }
        setExportStarted(false);
    }, [props.modalShown]);

    return props.modalShown ? (
        exportStarted ? (
            <Modal
                className="export-progress-modal"
                title="Exporting"
                blocking={true}
                close={() => props.setExportModalShow(false)}
                onSubmit={onCancelExport}
                closeOnSubmitSuccess={false}
                closeOnSubmitFailure={false}
                isOpen={props.modalShown}
                type={ModalType.EXPORT}
            >
                Export in progress, don't leave this page.
                <ProgressBar
                    animated
                    now={props.noProgress ? 100 : (exportedRows / (props.totalRecords ?? exportedRows)) * 100}
                />
                <span className="progress-caption">
                    {exportedRows} of {props.totalRecords} rows
                </span>
                {exportRetryAttempted && (
                    <span className="warning-caption">
                        <FontAwesomeIcon icon={faExclamationTriangle} /> Experiencing network issues, export may take
                        longer than ususal.
                    </span>
                )}
            </Modal>
        ) : (
            <Modal
                className="export-disclaimer-modal"
                title={props.disclaimerTitle ?? 'Export'}
                blocking={false}
                close={() => props.setExportModalShow(false)}
                onSubmit={onConfirmDisclaimer}
                closeOnSubmitSuccess={false}
                closeOnSubmitFailure={false}
                isOpen={props.modalShown}
                type={ModalType.EXPORT_CONFIRM}
            >
                {props.disclaimer}
            </Modal>
        )
    ) : (
        <></>
    );
};
