import { Checkbox, isValidString, LogLevel, showBanner, Sisp } from '@sprint/sprint-react-components';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { RepositoryFactoryContext } from '../../index';
import { TaskTypeRequest } from '../../Api/TaskTypeRequest';
import TaskType from '../../Models/TaskType';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
}

const TaskTypesAddSisp: FunctionComponent<Props> = (props: Props) => {
    const taskTypesRepository = useContext(RepositoryFactoryContext).getApiRepository(new TaskTypeRequest());

    const focusRef = useRef<HTMLInputElement>(null);

    const [newType, setNewType] = useState<string>('');
    const [newTypeValid, setNewTypeValid] = useState<boolean>(true);

    const [newDefault, setNewDefault] = useState<boolean>(false);

    useEffect(() => {
        if (props.shown) {
            focusRef.current?.focus();
        }
    }, [props.shown]);

    const validate = async (): Promise<boolean> => {
        const typeValid = !!newType && isValidString(newType);
        setNewTypeValid(typeValid);
        return typeValid;
    };

    const reset = () => {
        setNewType('');
        setNewDefault(false);
        setNewTypeValid(true);
    };

    const handleAddRow = async (): Promise<boolean> => {
        const TaskType: TaskType = {
            type: newType,
            is_default: newDefault,
            // Doesnt change can only be false
            built_in: false,
        };

        return taskTypesRepository
            .create(TaskType)
            .then(props.onSuccess)
            .then(async (success) => {
                reset();
                return success;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to create Task Type - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        if ((await validate()) && (await handleAddRow())) props.onClose();
    };

    return (
        <Sisp
            isOpen={props.shown}
            onSubmit={handleAddRow}
            onCancel={() => {
                reset();
                props.onClose();
            }}
            validate={validate}
        >
            <h4>Add a Task Type</h4>
            <Form onSubmit={onSubmitForm}>
                <Form.Group>
                    <Form.Label>
                        Task Type <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        ref={focusRef}
                        type="text"
                        isInvalid={!newTypeValid}
                        value={newType || ''}
                        onChange={(event) => {
                            setNewType(event.target.value);
                            setNewTypeValid(true);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {!newTypeValid && 'This field is required.'}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Checkbox
                        label="Make this the default task type"
                        isChecked={newDefault}
                        onChange={(event) => setNewDefault(event.target.checked)}
                    />
                </Form.Group>
            </Form>
        </Sisp>
    );
};

export default TaskTypesAddSisp;
