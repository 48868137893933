import { filter as filterTypes } from '@sprint/sprint-react-components';
import { v4 as uuidv4 } from 'uuid';

// Symbol stringification inspired by https://stackoverflow.com/questions/45152582/javascript-object-with-symbol-property-gets-removed-by-stringify/45171308#45171308
// and https://www.thomasmaximini.com/json-stringify-symbols-and-react-components
export default class FilterLocalStorageManager {
    static makeKey = (baseKey: string, userId: number) => {
        return baseKey + '-persistPreviousFilters-user(' + userId + ')';
    };

    static set = (filterLocalStorageKey: string, selectedFilterState: filterTypes.SelectedFilters[]) => {
        if (localStorage.getItem(filterLocalStorageKey) == this.stringifyForLocalStorage(selectedFilterState)) return;
        localStorage.setItem(filterLocalStorageKey, this.stringifyForLocalStorage(selectedFilterState));
    };

    static stringifyForLocalStorage = (selectedFilterState: filterTypes.SelectedFilters[]) => {
        return JSON.stringify(selectedFilterState, (k, v) =>
            typeof v === 'symbol' ? `$$Symbol:${Symbol.keyFor(v)}` : v,
        );
    };

    static parseFromLocalStorage = (filterLocalStorageKey: string) => {
        const filterLs = localStorage.getItem(filterLocalStorageKey);
        if (!filterLs) return;
        return JSON.parse(filterLs, (k, v) => {
            const symbol_matches = v && v.match && v.match(/^\$\$Symbol:(.*)$/);
            if (symbol_matches) {
                return Symbol.for(symbol_matches[1]);
            }

            const key_matches = ['key'].includes(k) ? true : false;
            if (key_matches) {
                // Render each filter with a unique key to stop react moaning, replace null keys with uuid
                // Warning: This should be safe but be aware that the filterRequest also has 'key' keys.
                // i.e. {'key': 'primaryFilters'}, but these should never be null, if they are the filters wouldn't work anyway.
                return v ?? uuidv4();
            }

            return v;
        });
    };

    static clear = (filterLocalStorageKey: string) => {
        localStorage.removeItem(filterLocalStorageKey);
    };
}
