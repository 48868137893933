import {
    Modal,
    ModalType,
} from '@sprint/sprint-react-components';
import React, { FunctionComponent, useState } from 'react';
import { Button } from 'react-bootstrap';
import './PrivacyPolicyNeededModal.scss';

interface Props {
    shown: boolean;
    onClose: () => void;
}

const PrivacyPolicyNeededModal: FunctionComponent<Props> = (props: Props) => {
    const settingsLegalProcessingPath: string = '/settings/legal_processing';

    return (
        <Modal
            className="privacy-policy-needed-modal"
            close={props.onClose}
            isOpen={props.shown}
            title="You Need a Privacy Policy Link"
            type={ModalType.INFO}
            footerOverride={
                <>
                    <Button
                        variant="primary"
                        onClick={(event) => {
                            event.preventDefault();
                            window.location.href = settingsLegalProcessingPath;
                        }}
                    >
                        Add You Privacy Policy Link
                    </Button>
                    <Button variant="default" onClick={props.onClose}>
                        Cancel
                    </Button>
                </>
            }
        >
            <p>
                Before you can create any new, or edit any existing forms you need to add a link to your privacy policy.
            </p>
        </Modal>
    );
};

export default PrivacyPolicyNeededModal;

