import { ExtendedColumn } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext } from 'react';
import { ucwords } from '../../../../Helpers/StringHelper';
import UnsubscribedRequest from '../../Api/UnsubscribeRequests';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import UnsubscribedContact from '../../Models/UnsubscribedContact';
import { DictionaryContext, RepositoryFactoryContext } from '../../index';
import CampusDataGrid, { ExportableExtendedColumn } from '../CampusDataGrid';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
    type: string;
}

export enum UnsubscribedContactsColumnKeys {
    ID,
    NAME,
    ORGANISATION,
    EMAIL,
    UNSUBSCRIBED,
    REASON,
}

const UnsubscribedContacts: FunctionComponent<Props> = (props: Props) => {
    const repository = useContext(RepositoryFactoryContext).getApiRepository(new UnsubscribedRequest(props.type));
    const dictionary = useContext(DictionaryContext);
    const Columns: Record<UnsubscribedContactsColumnKeys, ExportableExtendedColumn> = {
        [UnsubscribedContactsColumnKeys.ID]: {
            key: 'id',
            name: 'Id',
        },
        [UnsubscribedContactsColumnKeys.NAME]: {
            key: 'name',
            name: 'Name',
            sortable: true,
            width: '1fr',
            renderCell: (props) => {
                const { name, id } = props.row as UnsubscribedContact;
                return (
                    <>
                        <a href={'/subscribers/contacts/view/' + id}>{name}</a>
                    </>
                );
            },
            exportFormatter: (entity: any) => (entity as UnsubscribedContact)?.name || '',
        },
        [UnsubscribedContactsColumnKeys.ORGANISATION]: {
            key: 'organisation_name',
            name: ucwords(dictionary['organisation']),
            sortable: true,
            width: '2fr',
            renderCell: (props) => {
                const { organisation_name, organisation_id } = props.row as UnsubscribedContact;
                return (
                    <>
                        <a href={'/subscribers/organisations/view/' + organisation_id}>{organisation_name}</a>
                    </>
                );
            },
            exportFormatter: (entity: any) => (entity as UnsubscribedContact)?.organisation_name || '',
        },
        [UnsubscribedContactsColumnKeys.EMAIL]: {
            key: 'email',
            name: 'Email',
            sortable: true,
            width: '2fr',
            renderCell: (props) => {
                return <div dangerouslySetInnerHTML={{ __html: (props.row as UnsubscribedContact).email }}></div>;
            },
            exportFormatter: (entity: any) => (entity as UnsubscribedContact)?.email || '',
        },
        [UnsubscribedContactsColumnKeys.UNSUBSCRIBED]: {
            key: 'unsubscribed_date',
            name: 'Date',
            width: '1fr',
            sortable: true,
        },
        [UnsubscribedContactsColumnKeys.REASON]: {
            key: 'unsubscribed_reason',
            name: 'Reason',
            width: '1fr',
            sortable: false,
        },
    };
    const DefaultColumns: ExtendedColumn[] = [
        Columns[UnsubscribedContactsColumnKeys.NAME],
        Columns[UnsubscribedContactsColumnKeys.ORGANISATION],
        Columns[UnsubscribedContactsColumnKeys.EMAIL],
        Columns[UnsubscribedContactsColumnKeys.UNSUBSCRIBED],
        Columns[UnsubscribedContactsColumnKeys.REASON],
    ];
    return (
        <>
            <CampusDataGrid
                repository={repository}
                dataGridMeta={{
                    uniqueKey: props.dataGridUniqueKey,
                    entitySingular: props.dataGridEntitySingular,
                    entityPlural: props.dataGridEntityPlural,
                    defaultColumns: DefaultColumns,
                    columnOptions: Columns,
                    hideSelect: true,
                }}
                actionBarMeta={{
                    searchPlaceHolder: props.searchFilterPlaceholder,
                    includeCounts: true,
                    hideAddButton: true,
                    extraActionBarMeta: {
                        exportMeta: {
                            allowExport: true,
                            entity: 'Contact_Unsubscribes',
                        },
                    },
                }}
                promptMeta={{
                    icon: '/assets/application/img/prompts/no_unsubscribed_contacts.png',
                    iconHeight: 80,
                    helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.UNSUBSCRIBE_CONTACT),
                }}
            />
        </>
    );
};
export default UnsubscribedContacts;
