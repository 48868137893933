'use strict';

import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import Client from '../../../../Entities/Client/Client';
import Deal from '../../../../Entities/Deal/Deal';
import DealPipeline from '../../../../Entities/Deal/DealPipeline/DealPipeline';
import NoDealsFromFilter from './NoDealsFromFilter';
import Pipeline from './Pipeline';

import { EventBusInstance } from '@sprint/sprint-react-components';
import '../../../../CommonComponents/Board/board.scss';

class PipelineGroup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedIds: [],
            draggingId: null,
        };

        // Allow removeEventListener on bound functions
        this.onWindowClick = this.onWindowClick.bind(this);
        this.onWindowKeyDown = this.onWindowKeyDown.bind(this);
        this.onWindowTouchEnd = this.onWindowTouchEnd.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // Ensure selectedIds are still valid against provided filtered deals
        return {
            selectedIds: nextProps.deals
                ? prevState.selectedIds.filter((id) => nextProps.deals.map((deal) => deal.id).includes(id))
                : [],
        };
    }

    componentDidMount() {
        window.addEventListener('click', this.onWindowClick);
        window.addEventListener('keydown', this.onWindowKeyDown);
        window.addEventListener('touchend', this.onWindowTouchEnd);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onWindowClick);
        window.removeEventListener('keydown', this.onWindowKeyDown);
        window.removeEventListener('touchend', this.onWindowTouchEnd);
    }

    onDragStart(start) {
        const draggingId = start.draggableId;
        const selected = this.state.selectedIds.find((id) => String(id) === String(draggingId));

        // If dragging an item that is not selected - unselect all items
        if (!selected) {
            this.unselectAll();
        }
        this.setState({
            draggingId: draggingId,
        });
    }

    onDragEnd(result) {
        const { draggableId, destination, source } = result;

        if (!destination || result.reason === 'CANCEL' || destination.droppableId === source.droppableId) {
            this.setState({
                draggingId: null,
            });
            return;
        }

        const delimiterIndex = destination.droppableId.indexOf('-');
        if (delimiterIndex < 0) return;

        const idsToMove = this.state.selectedIds.length ? this.state.selectedIds : [draggableId];
        const pipelineId = destination.droppableId.substring(0, delimiterIndex);
        const stageName = destination.droppableId.substring(delimiterIndex + 1);

        if (this.props.dealMoveAction) this.props.dealMoveAction(idsToMove, pipelineId, stageName);

        this.setState({
            draggingId: null,
        });
    }

    onWindowKeyDown(event) {
        if (event.defaultPrevented) return;

        if (event.key === 'Escape') {
            this.unselectAll();
        }
    }

    onWindowClick(event) {
        if (event.defaultPrevented) return;

        this.unselectAll();
    }

    onWindowTouchEnd(event) {
        if (event.defaultPrevented) return;

        this.unselectAll();
    }

    toggleSelection(id) {
        const selectedIds = this.state.selectedIds;
        const wasSelected = selectedIds.includes(id);

        const newIds = (() => {
            // Item was not previously selected
            // now will be the only selected item
            if (!wasSelected) {
                return [id];
            }

            // Item was part of a selected group
            // will now become the only selected item
            if (selectedIds.length > 1) {
                return [id];
            }

            // Item was previously selected but not in a group
            // we will now clear the selection
            return [];
        })();

        this.setState({
            selectedIds: newIds,
        });
    }

    toggleSelectionInGroup(id) {
        // New DG's: Clears the Preview for the selected Deal when multiple deals are selected
        EventBusInstance.publish({
            topic: 'show-hoverover-component',
            message: true,
            target: 'deals-data-grid-board-preview-close',
        });

        // Original Logic
        const selectedIds = this.state.selectedIds;
        const index = selectedIds.indexOf(id);

        // If not selected - add it to the selected items
        if (index === -1) {
            this.setState({
                selectedIds: [...selectedIds, id],
            });
            return;
        }

        // It was previously selected and now needs to be removed from the group
        const shallow = [...selectedIds];
        shallow.splice(index, 1);
        this.setState({
            selectedIds: shallow,
        });
    }

    addToSelection(ids) {
        if (!ids && !ids.length) return;

        this.setState({
            selectedIds: [...this.state.selectedIds, ...ids],
        });
    }

    unselectAll() {
        this.setState({
            selectedIds: [],
        });
    }

    dealSelectedInPipeline(deal, pipeline) {
        return this.state.selectedIds.includes(deal.id) && deal.dealPipeline && deal.dealPipeline.id === pipeline.id;
    }

    pipelineHasDealsSelected(pipeline) {
        if (!pipeline) return false;

        return this.props.deals.find((deal) => this.dealSelectedInPipeline(deal, pipeline)) !== undefined;
    }

    deleteSelectedAction(pipeline, event) {
        if (!pipeline) return;

        // Mark the event as used - prevent this event clearing every selection
        event.preventDefault();

        const dealsToDelete = this.props.deals.filter((deal) => this.dealSelectedInPipeline(deal, pipeline));
        const dealsToDeleteById = dealsToDelete.map((deal) => deal.id);

        if (this.props.dealDeleteAction) this.props.dealDeleteAction(dealsToDelete);

        // Remove the deleted pipeline deals from selection
        this.setState({
            selectedIds: this.state.selectedIds.filter((id) => !dealsToDeleteById.includes(id)),
        });
    }

    render() {
        if (this.props.pipelines && this.props.pipelines.length === 0)
            return <p>No deal pipelines selected, please adjust your filter.</p>;
        else {
            return this.props.deals && this.props.deals.length ? (
                <DragDropContext onDragStart={this.onDragStart.bind(this)} onDragEnd={this.onDragEnd.bind(this)}>
                    <div className="react-board--row-group">
                        {this.props.pipelines.map((pipeline) => (
                            <Pipeline
                                key={pipeline.id}
                                pipeline={pipeline}
                                stageStatesInSearch={this.props.stageStatesInSearch}
                                deals={this.props.deals}
                                client={this.props.client}
                                showDeleteSelected={
                                    !!this.state.selectedIds.length && this.pipelineHasDealsSelected(pipeline)
                                }
                                deleteSelectedAction={this.deleteSelectedAction.bind(this)}
                                dealPreviewAction={this.props.dealPreviewAction}
                                dealEditAction={this.props.dealEditAction}
                                loadMoreDealsAction={this.props.loadMoreDealsAction}
                                moreDealsAvailable={this.props.moreDealsAvailable}
                                moreDealsLoading={this.props.moreDealsLoading}
                                selectedIds={this.state.selectedIds}
                                draggingId={this.state.draggingId}
                                toggleSelection={this.toggleSelection.bind(this)}
                                toggleSelectionInGroup={this.toggleSelectionInGroup.bind(this)}
                                addToSelection={this.addToSelection.bind(this)}
                            />
                        ))}
                    </div>
                </DragDropContext>
            ) : (
                <NoDealsFromFilter />
            );
        }
    }
}

PipelineGroup.propTypes = {
    pipelines: PropTypes.arrayOf(PropTypes.instanceOf(DealPipeline)),
    stageStatesInSearch: PropTypes.arrayOf(PropTypes.string),
    deals: PropTypes.arrayOf(PropTypes.instanceOf(Deal)),
    client: PropTypes.instanceOf(Client),
    dealPreviewAction: PropTypes.func,
    dealEditAction: PropTypes.func,
    dealDeleteAction: PropTypes.func,
    dealMoveAction: PropTypes.func,
    loadMoreDealsAction: PropTypes.func,
    moreDealsAvailable: PropTypes.bool,
    moreDealsLoading: PropTypes.bool,
};

PipelineGroup.defaultProps = {};

export default PipelineGroup;
