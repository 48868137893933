import React, { FunctionComponent } from 'react';

interface Props {
    count?: number;
}

const NumberToLocaleStringComponent: FunctionComponent<Props> = (props: Props) => {
    return <>{props.count !== undefined ? Number(props.count).toLocaleString() : 'N/A'}</>;
};

export default NumberToLocaleStringComponent;
