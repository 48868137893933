'use strict';

import * as ActionTypes from "./ActionTypes";
import DealPipelineRepository from "./DealPipelineRepository";

export function loadDealPipelines() {
    return (dispatch) => {
        DealPipelineRepository.all()
            .then(response => dispatch(dealPipelinesLoaded(response)))
            .catch(e => dispatch(dealPipelinesLoadingFailed(e.message ? e.message : e)));
    }
}

function dealPipelinesLoaded(dealPipelines) {
    return {
        type: ActionTypes.DEALPIPELINES_LOADED,
        payload: {
            dealPipelines: dealPipelines
        }
    }
}

function dealPipelinesLoadingFailed(error) {
    return {
        type: ActionTypes.DEALPIPELINES_LOADING_FAILED,
        payload: {
            error: error
        }
    }
}

export function newDealPipeline() {
    return {
        type: ActionTypes.DEALPIPELINE_NEW
    }
}

export function updateDealPipelineField(id, fieldName, value) {
    return {
        type: ActionTypes.DEALPIPELINE_UPDATE_FIELD,
        payload: {
            id: id,
            fieldName: fieldName,
            value: value
        }
    }
}

export function updateDealPipelineStage(id, existingStageName, stageEntity) {
    return {
        type: ActionTypes.DEALPIPELINE_UPDATE_STAGE,
        payload: {
            id: id,
            existingStageName: existingStageName,
            stageEntity: stageEntity
        }
    }
}

export function reorderDealPipelineStage(id, oldStageIndex, newStageIndex) {
    return {
        type: ActionTypes.DEALPIPELINE_REORDER_STAGE,
        payload: {
            id: id,
            oldStageIndex: oldStageIndex,
            newStageIndex: newStageIndex
        }
    }
}

export function deleteDealPipelineStage(id, stageEntity) {
    return {
        type: ActionTypes.DEALPIPELINE_DELETE_STAGE,
        payload: {
            id: id,
            stageEntity: stageEntity
        }
    }
}

export function saveDealPipeline(entity) {
    return (dispatch) => {
        dispatch(savingDealPipeline(entity));
        DealPipelineRepository.save(entity).then((response) => {
            dispatch(dealPipelineSaved(response));
        }).catch(error => {
            dispatch(savingDealPipelineFailed(entity.id, error));
        });
    }
}

export function selectDealPipeline(id) {
    return {
        type: ActionTypes.DEALPIPELINE_SELECT,
        payload: {
            id: id
        }
    }
}

export function loadDealPipeline(id) {
    return (dispatch) => {
        dispatch(loadingDealPipeline(id));
        DealPipelineRepository.find(id).then((response) => {
            dispatch(dealPipelineLoaded(response));
        }).catch(error => {
            dispatch(loadingDealPipelineFailed(id, error));
        });
    }
}

export function invalidateDealPipeline(id) {
    return {
        type: ActionTypes.DEALPIPELINE_INVALIDATED,
        payload: {
            id: id
        }
    }
}

export function deleteDealPipeline(id) {
    return (dispatch) => {
        dispatch(deletingDealPipeline(id));
        DealPipelineRepository.delete(id).then(() => {
            dispatch(dealPipelineDeleted(id));
        }).catch(error => {
            dispatch(savingDealPipelineFailed(id, error));
        });
    }
}

function savingDealPipeline(entity) {
    return {
        type: ActionTypes.DEALPIPELINE_SAVING,
        payload: {
            entity: entity
        }
    }
}

function dealPipelineSaved(rawEntity) {
    return {
        type: ActionTypes.DEALPIPELINE_SAVED,
        payload: {
            data: rawEntity
        }
    }
}

function savingDealPipelineFailed(id, error) {
    return {
        type: ActionTypes.DEALPIPELINE_SAVING_FAILED,
        payload: {
            id: id,
            error: error
        }
    }
}

function loadingDealPipeline(id) {
    return {
        type: ActionTypes.DEALPIPELINE_LOADING,
        payload: {
            id: id
        }
    }
}

function dealPipelineLoaded(rawEntity) {
    return {
        type: ActionTypes.DEALPIPELINE_LOADED,
        payload: {
            data: rawEntity
        }
    }
}

function loadingDealPipelineFailed(id, error) {
    return {
        type: ActionTypes.DEALPIPELINE_LOADING_FAILED,
        payload: {
            id: id,
            error: error
        }
    }
}

function deletingDealPipeline(id) {
    return {
        type: ActionTypes.DEALPIPELINE_DELETING,
        payload: {
            id: id
        }
    }
}

function dealPipelineDeleted(id) {
    return {
        type: ActionTypes.DEALPIPELINE_DELETED,
        payload: {
            id: id
        }
    }
}