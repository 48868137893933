'use strict';

import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import DropDownButtonMenu from '../../../../CommonComponents/DropDownMenu/DropDownButtonMenu';
import Deal from '../../../../Entities/Deal/Deal';
import DealPipeline from '../../../../Entities/Deal/DealPipeline/DealPipeline';
import DealPipelineStage from '../../../../Entities/Deal/DealPipeline/DealPipelineStage/DealPipelineStage';
import Currency from '../../../../ValueObjects/Currency/Currency';
import * as DealStageState from '../../../../ValueObjects/DealStageState/DealStageState';
import PermissionsWrappedLegacyComponent from '../PermissionsWrappedLegacyComponent';
import DealCard from './DealCard';

import '../../../../CommonComponents/Board/board.scss';

class PipelineStage extends React.Component {
    actionMenuOptionClicked(option) {
        switch (option) {
            case 'Select All':
                if (this.props.deals && this.props.inCurrentSearch) {
                    if (this.props.addToSelection)
                        this.props.addToSelection(
                            this.props.deals.filter(this.dealsByPipelineStage.bind(this)).map((deal) => deal.id),
                        );
                }
                break;
        }
    }

    dealsByPipelineStage(deal) {
        return (
            deal.dealPipeline &&
            deal.dealPipeline.matches(this.props.pipeline.id) &&
            deal.currentStage &&
            deal.currentStage.matches(this.props.stage.name)
        );
    }

    columnClassName(snapshot) {
        let className = 'react-board--column--content ';
        if (snapshot.isDraggingOver) className += ' dragging-over';
        return className;
    }

    render() {
        const stageDeals =
            this.props.deals && this.props.inCurrentSearch
                ? this.props.deals.filter(this.dealsByPipelineStage.bind(this))
                : [];
        const stageTotalValue = stageDeals.reduce(
            (total, deal) => total + (deal.value ? deal.value.forDecimal() : 0),
            0,
        );

        return (
            <div className="react-board--column">
                <div className="react-board--column--header">
                    <div className="header--button-group">
                        <DropDownButtonMenu
                            small={true}
                            flat={true}
                            showCaret={false}
                            options={['Select All']}
                            optionClicked={this.actionMenuOptionClicked.bind(this)}
                            label={<FontAwesomeIcon icon={faEllipsisV} />}
                            menuOffsetHorizontal={-140}
                            menuOffsetVertical={8}
                        />
                    </div>
                    <span className="header--text">
                        <span className="header--text--title">{this.props.stage.name}</span>
                        <span className="header--text--count">({stageDeals ? stageDeals.length : 0})</span>
                    </span>
                </div>
                <Droppable droppableId={this.props.pipeline.id + '-' + this.props.stage.name}>
                    {(provided, snapshot) => (
                        <div
                            className={this.columnClassName(snapshot)}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {this.props.inCurrentSearch ? null : (
                                <span className="content--notice">Results hidden by current filter.</span>
                            )}
                            {stageDeals && stageDeals.length
                                ? stageDeals.map((deal, index) => {
                                      const isSelected = this.props.selectedIds.includes(deal.id);
                                      const isGhosting =
                                          isSelected && !!this.props.draggingId && this.props.draggingId !== deal.id;
                                      return (
                                          <PermissionsWrappedLegacyComponent
                                              legacyComponent={DealCard}
                                              key={deal.id}
                                              index={index}
                                              deal={deal}
                                              previewAction={this.props.dealPreviewAction}
                                              editAction={this.props.dealEditAction}
                                              isSelected={isSelected}
                                              isGhosting={isGhosting}
                                              selectionCount={this.props.selectedIds.length}
                                              toggleSelection={this.props.toggleSelection}
                                              toggleSelectionInGroup={this.props.toggleSelectionInGroup}
                                          />
                                      );
                                  })
                                : null}
                            {provided.placeholder}
                            {snapshot.isDraggingOver && this.props.stage.stageState !== DealStageState.OPEN ? (
                                <span className="content--notice">Any linked quotes will also be closed.</span>
                            ) : null}
                        </div>
                    )}
                </Droppable>
                <div className="react-board--column--footer">
                    <span className="footer--text">
                        <span className="footer--text--label">
                            {this.props.currency ? this.props.currency.symbol : null}
                        </span>
                        <span className="footer--text--value">{Math.round(stageTotalValue)}</span>
                    </span>
                </div>
            </div>
        );
    }
}

PipelineStage.propTypes = {
    index: PropTypes.number.isRequired,
    pipeline: PropTypes.instanceOf(DealPipeline).isRequired,
    stage: PropTypes.instanceOf(DealPipelineStage).isRequired,
    deals: PropTypes.arrayOf(PropTypes.instanceOf(Deal)),
    inCurrentSearch: PropTypes.bool,
    currency: PropTypes.instanceOf(Currency),
    dealPreviewAction: PropTypes.func,
    dealEditAction: PropTypes.func,
    selectedIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    draggingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    toggleSelection: PropTypes.func,
    toggleSelectionInGroup: PropTypes.func,
    addToSelection: PropTypes.func,
};

PipelineStage.defaultProps = {};

export default PipelineStage;
