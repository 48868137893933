'use strict';

export const READY = 'ready';
export const INVALIDATED = 'invalidated';
export const SAVING = 'saving';
export const SAVED = 'saved';
export const MODIFIED = 'modified';
export const LOADING = 'loading';
export const LOADED = 'loaded';
export const DELETING = 'deleting';
export const DELETED = 'deleted';
export const ERROR = 'error';

export default class EntityState {

    constructor(currentState) {
        this.saving = (currentState && currentState.saving) ? currentState.saving : false;
        this.loading = (currentState && currentState.loading) ? currentState.loading : false;
        this.deleting = (currentState && currentState.deleting) ? currentState.deleting : false;
        this.synced = (currentState && currentState.synced) ? currentState.synced : false;
        this.ready = (currentState && currentState.ready) ? currentState.ready : false;
        this.error = (currentState && currentState.error) ? currentState.error : undefined;
    }

    clone() {
        return Object.assign(new EntityState(), this);
    }

    static initialState() {
        return new EntityState();
    }

    static transition(currentState, action, data) {
        switch (action) {
            case READY:
                return EntityState.setReady(currentState);
            case INVALIDATED:
                return EntityState.setInvalidated(currentState);
            case SAVING:
                return EntityState.setSaving(currentState);
            case SAVED:
                return EntityState.setSaved(currentState);
            case MODIFIED:
                return EntityState.setModified(currentState);
            case LOADING:
                return EntityState.setLoading(currentState);
            case LOADED:
                return EntityState.setLoaded(currentState);
            case DELETING:
                return EntityState.setDeleting(currentState);
            case DELETED:
                return EntityState.setDeleted(currentState);
            case ERROR:
                return EntityState.setError(currentState, data);
            default:
                return currentState;
        }
    }

    static setReady(currentState) {
        const nextState = new EntityState(currentState);
        nextState.ready = true;
        return nextState;
    }

    static setInvalidated(currentState) {
        const nextState = new EntityState(currentState);
        nextState.ready = false;
        return nextState;
    }

    static setSaving(currentState) {
        const nextState = new EntityState(currentState);
        nextState.saving = true;
        nextState.loading = false;
        nextState.error = undefined;
        return nextState;
    }

    static setSaved(currentState) {
        const nextState = new EntityState(currentState);
        nextState.saving = false;
        nextState.synced = true;
        nextState.loading = false;
        nextState.ready = true;
        nextState.error = undefined;
        return nextState;
    }

    static setModified(currentState) {
        const nextState = new EntityState(currentState);
        nextState.synced = false;
        return nextState;
    }

    static setLoading(currentState) {
        const nextState = new EntityState(currentState);
        nextState.saving = false;
        nextState.loading = true;
        nextState.error = undefined;
        return nextState;
    }

    static setLoaded(currentState) {
        const nextState = new EntityState(currentState);
        nextState.saving = false;
        nextState.synced = true;
        nextState.loading = false;
        nextState.ready = true;
        return nextState;
    }

    static setDeleting(currentState) {
        const nextState = new EntityState(currentState);
        nextState.saving = true;
        nextState.loading = false;
        nextState.deleting = true;
        nextState.error = undefined;
        return nextState;
    }

    static setDeleted(currentState) {
        const nextState = new EntityState(currentState);
        nextState.saving = false;
        nextState.loading = false;
        nextState.deleting = false;
        nextState.synced = false;
        nextState.ready = false;
        nextState.error = undefined;
        return nextState;
    }

    static setError(currentState, error) {
        const nextState = new EntityState(currentState);
        nextState.saving = false;
        nextState.loading = false;
        nextState.deleting = false;
        nextState.synced = false;
        nextState.error = error;
        return nextState;
    }

    isTransceiving() {
        return (this.loading || this.saving || this.deleting);
    }

    isInitialLoading() {
        return (!this.ready && this.loading);
    }

    isReloading() {
        return (this.ready && this.loading);
    }
}