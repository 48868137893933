'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { closeModal, openModal, updateModal } from '../../../CommonComponents/Modal/ModalActions';
import { loadDeal } from '../../../Entities/Deal/Actions';
import { linkSaleToDeal } from '../../../Entities/Sale/Actions';
import ConnectedSaleFormSelect from '../../../Entities/Sale/Components/ConnectedSaleFormSelect';
import { saleLinkModalOpened } from '../Actions/Actions';

import { Button } from 'react-bootstrap';
import AlertBanner, { AlertLevel } from '../../../CommonComponents/AlertBanner/AlertBanner';
import Deal from '../../../Entities/Deal/Deal';
import Validation from '../../../Helpers/Validation';

class ModalSaleLink extends React.Component {
    constructor(props) {
        super(props);

        this.initialState = {
            blurred: false,
            submitAttempted: false,
            saleId: undefined,
            modalOpen: false,
        };

        this.state = this.initialState;
    }

    componentDidUpdate() {
        if (this.props.modalOpenRequested) {
            this.props.dispatch(saleLinkModalOpened());
            this.setState(this.initialState);
            this.showSaleLinkModal();
        }
        if (this.props.reloadNewlyLinkedDeal) this.props.dispatch(loadDeal(this.props.dealId));
        // Make in-context props updates re-render modal
        if (this.state.modalOpen) this.props.dispatch(updateModal());
    }

    onUpdateSaleId(id) {
        this.setState({
            saleId: id,
        });
    }

    onBlur() {
        this.setState({
            blurred: true,
        });
    }

    onSaveAttempted() {
        this.setState({
            submitAttempted: true,
        });
    }

    saleLinkAction() {
        this.onSaveAttempted();

        if (this.validate().hasErrors()) return;
        if (!this.state.saleId || !this.props.dealId) return;

        this.props.dispatch(linkSaleToDeal(this.state.saleId, this.props.dealId));
        this.closeModalAction();
    }

    closeModalAction() {
        this.props.dispatch(closeModal());
        this.setState({
            modalOpen: false,
        });
    }

    showSaleLinkModal() {
        this.props.dispatch(
            openModal(
                this.renderModalBody.bind(this),
                this.props.dealClosing ? 'No Sales Associated' : 'Link a Sale',
                this.renderModalFooter.bind(this),
            ),
        );
        this.setState({
            modalOpen: true,
        });
    }

    validate() {
        const validation = new Validation({ sale: this.state.blurred }, this.state.submitAttempted);

        if (!this.state.saleId) validation.addError('sale', 'Please select a sale.');
        if (!this.props.dealId) validation.addError('sale', 'No deal to link sale to.');

        return validation;
    }

    organisationId() {
        if (this.props.deal.relatesToOrganisation) {
            return this.props.deal.relatesToOrganisation.id;
        } else if (this.props.deal.relatesToContact && this.props.deal.relatesToContact.organisation) {
            return this.props.deal.relatesToContact.organisation.id;
        } else {
            return 0;
        }
    }

    renderModalBody() {
        const validation = this.validate();
        const organisationId = this.organisationId();

        return (
            <div>
                {this.props.error ? (
                    <AlertBanner alertLevel={AlertLevel.DANGER}>{this.props.error.toString()}</AlertBanner>
                ) : null}
                {this.props.dealClosing ? (
                    <p>
                        You have closed a deal without any associated sales, please select one below
                        {this.props.showCreateButton ? ' or press Create a Sale.' : '.'}
                    </p>
                ) : (
                    <p>
                        Please select an existing sale below
                        {this.props.showCreateButton ? ', or press Create a Sale.' : '.'}
                    </p>
                )}
                {this.props.dealClosing ? <p>Otherwise, press Cancel to continue without linking a sale.</p> : null}
                <ConnectedSaleFormSelect
                    label="Sale"
                    placeHolder="Please select"
                    value={this.state.saleId}
                    onChange={this.onUpdateSaleId.bind(this)}
                    onBlur={this.onBlur.bind(this)}
                    clearable={false}
                    validationErrorMessage={validation.messageForField('sale')}
                    advancedSearch={
                        organisationId
                            ? {
                                  organisations: [organisationId],
                              }
                            : undefined
                    }
                />
            </div>
        );
    }

    renderModalFooter() {
        return (
            <div>
                <Button variant="primary" onClick={this.saleLinkAction.bind(this)}>
                    Link to Sale
                </Button>
                {this.props.showCreateButton ? (
                    <Button href={this.props.deal.saleAddUrl(this.props.client)} variant="default">
                        Create a Sale
                    </Button>
                ) : null}
                <Button variant="default" onClick={this.closeModalAction.bind(this)}>
                    Cancel
                </Button>
            </div>
        );
    }

    render() {
        return <div className="react-modal--sale-link">{null}</div>;
    }
}

function select(state) {
    let error = undefined;

    let currentDeal = Deal.select(state.deal.allKnownResults, state.saleLink.dealId);
    if (currentDeal === undefined) currentDeal = Deal.blank();

    const formLoading = state.client.client === undefined;

    if (state.client.error) error = state.client.error;
    if (state.sale.error) error = state.sale.error;

    return {
        client: state.client.client,
        deal: currentDeal,
        formLoading: formLoading,
        modalOpenRequested: state.saleLink.modalOpenRequested,
        dealId: state.saleLink.dealId,
        reloadNewlyLinkedDeal: state.saleLink.reloadNewlyLinkedDeal,
        dealClosing: state.saleLink.dealClosing,
        showCreateButton: state.saleLink.showCreateButton,
    };
}

ModalSaleLink.propTypes = {};

ModalSaleLink.defaultProps = {};

export default connect(select)(ModalSaleLink);
