import CampaignType from '../Models/CampaignType';
import DataGridRequest from './DataGridRequest';
export class PaymentsRequest extends DataGridRequest<CampaignType> {
    Url = 'payments/api';
}

export class UnpaidInvoicesRequest extends DataGridRequest<CampaignType> {
    Url = 'payments/unpaid_invoices';
}

export class HistoricalPaymentsRequest extends DataGridRequest<CampaignType> {
    Url = 'payments/historic_payments';
}
