import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface Props {
    title: string;
    helptext: string;
}
const HelpTooltip: FunctionComponent<Props> = (props: Props) => {
    const popover = (
        <Popover id="popover-trigger" title={props.title} className="help-tooltip-popover">
            <Popover.Title>{props.title}</Popover.Title>
            <Popover.Content>
                <div dangerouslySetInnerHTML={{ __html: props.helptext }}></div>
            </Popover.Content>
        </Popover>
    );

    return (
        <>
            <OverlayTrigger rootClose={true} overlay={popover} placement="auto" trigger={'click'}>
                <span className="question-hint no-print">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
            </OverlayTrigger>
        </>
    );
};

export default HelpTooltip;
