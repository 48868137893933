import { Modal, ModalType } from '@sprint/sprint-react-components';
import React from 'react';
import { Button } from 'react-bootstrap';

interface Props {
    href: string;
    close: () => void;
}

const MicrosoftAgreementModal = (props: Props) => {
    return (
        <Modal
            title="Connect to Your Microsoft Outlook Account"
            isOpen={true}
            type={ModalType.INFO}
            footerOverride={
                <Button href={props.href} onClick={props.close}>
                    Continue
                </Button>
            }
            close={props.close}
        >
            <p>
                When you integrate your Microsoft account with Campus it will be able to access data and information
                from your account and send emails.
            </p>
            <p>
                You can revoke Campus's access from within your Microsoft account by following the instructions listed{' '}
                <a
                    target="_blank"
                    href="https://answers.microsoft.com/en-us/outlook_com/forum/all/managing-apps-and-services-connected-to-our/63b5d3aa-30b9-49dd-a729-9ba13df4e1eb"
                >
                    here
                </a>
                .
            </p>
            <p>
                For more information on the Microsoft integration and the types of data we can access, please see our{' '}
                <a target="_blank" href="https://www.sprint-education.co.uk/legal/privacy-policy">
                    Privacy Policy
                </a>
                .
            </p>
        </Modal>
    );
};

export default MicrosoftAgreementModal;
