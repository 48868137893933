import { EdApiModel } from '../EdApiModel';

export enum CustomFieldType {
    'NUMBER' = 'Number',
    'BOOLEAN' = 'Yes or No',
    'DATE' = 'Date',
    'LIST' = 'List',
    'STRING' = 'Text',
}

export enum CustomFieldNumberFormat {
    'FORMATTED' = 'Formatted',
    'UNFORMATTED' = 'Unformatted',
    'CURRENCY' = 'Currency',
}

export const CustomFieldNumberFormatExample: Record<CustomFieldNumberFormat, string> = {
    [CustomFieldNumberFormat.FORMATTED]: '12,345.67',
    [CustomFieldNumberFormat.UNFORMATTED]: '12345.67',
    [CustomFieldNumberFormat.CURRENCY]: '£12,345.67',
};

interface CustomFieldTag {
    id?: number;
    tag: string;
    icon: string;
}

interface EdApiCustomField extends EdApiModel {
    name: string;
    tags: CustomFieldTag[];
    type: CustomFieldType;
    numberFormat?: CustomFieldNumberFormat;
    listItems?: string;
    description: string;
    expireAt?: string;
    permissionItemName: string;
    includeInCCFilterSisp: boolean;
    allowInCCMarketingListFilter: boolean;
}

export default EdApiCustomField;
