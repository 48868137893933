'use strict';

export default class PersistenceKey {

    constructor(userId, appName) {
        this.userId = userId;
        this.appName = appName;
    }

    generateRoot() {
        return `user(${this.userId})-app(${this.appName})-root`;
    }

    // For reducers persisted separately from the root reducer
    generateBranch(reducerName) {
        return `user(${this.userId})-app(${this.appName})-branch(${reducerName})`;
    }

    static checkKeyForRoot(persistedKey) {
        return persistedKey.substring(persistedKey.lastIndexOf('-') + 1) === 'root';
    }

    static checkActionForRoot(action, reducerName) {
        // Check persistence key is root, and for a named reducer nested in root payload
        return PersistenceKey.checkKeyForRoot(action.key) && action.payload && action.payload[reducerName];
    }

    static checkKeyForBranch(persistedKey, reducerName) {
        return persistedKey.substring(persistedKey.lastIndexOf('-') + 1) === `branch(${reducerName})`;
    }

    static checkActionForBranch(action, reducerName) {
        // Check persistence key is a reducer-named branch, and for a basic payload
        // Do note, reducer branches persisted separately from root will deserialize to an un-nested payload
        // Therefore there are no additional levels to check
        return PersistenceKey.checkKeyForBranch(action.key, reducerName) && action.payload;
    }

}