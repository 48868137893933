import { ExtendedColumn, LogLevel, showBanner } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext } from 'react';
import UnsubscribedRequest from '../../Api/UnsubscribeRequests';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import { UnsubscribedType } from '../../Models/Enums';
import Unsubscribe from '../../Models/Unsubscribe';
import { RepositoryFactoryContext } from '../../index';
import CampusDataGrid, { ExportableExtendedColumn, PromptMeta } from '../CampusDataGrid';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
    type: string;
}

export enum UnsubscribesColumnKeys {
    ID,
    NAME,
    EMAIL,
    PACKAGE,
    UNSUBSCRIBED,
    REASON,
}

const UnsubscribedTable: FunctionComponent<Props> = (props: Props) => {
    const repository = useContext(RepositoryFactoryContext).getApiRepository(new UnsubscribedRequest(props.type));

    const Columns: Record<UnsubscribesColumnKeys, ExportableExtendedColumn> = {
        [UnsubscribesColumnKeys.ID]: {
            key: 'id',
            name: 'Id',
            sortable: false,
        },
        [UnsubscribesColumnKeys.NAME]: {
            key: 'name',
            name: 'Name',
            sortable: false,
            width: '3fr',
            renderCell: (props) => {
                const { name, school_id, package_url } = props.row as Unsubscribe;
                return school_id ? (
                    <a href={'/education/' + package_url + '/schools/' + school_id}>{name}</a>
                ) : (
                    <p>{name}</p>
                );
            },
        },
        [UnsubscribesColumnKeys.EMAIL]: {
            key: 'email',
            name: 'Email',
            sortable: false,
            width: '3fr',
            renderCell: (props) => {
                return <div dangerouslySetInnerHTML={{ __html: (props.row as Unsubscribe).email }}></div>;
            },
        },
        [UnsubscribesColumnKeys.PACKAGE]: {
            key: 'edu_data_package',
            name: 'Data Set',
            sortable: false,
            width: '1fr',
        },
        [UnsubscribesColumnKeys.UNSUBSCRIBED]: {
            key: 'unsubscribe_date',
            name: 'Unsubscribed',
            sortable: false,
            width: '1.5fr',
        },
        [UnsubscribesColumnKeys.REASON]: {
            key: 'unsubscribed_reason',
            name: 'Reason',
            sortable: false,
            width: '2fr',
        },
    };
    const DefaultColumns: ExtendedColumn[] = [
        Columns[UnsubscribesColumnKeys.NAME],
        Columns[UnsubscribesColumnKeys.EMAIL],
        Columns[UnsubscribesColumnKeys.PACKAGE],
        Columns[UnsubscribesColumnKeys.UNSUBSCRIBED],
        Columns[UnsubscribesColumnKeys.REASON],
    ];

    const generatePromptMeta = (unsubscribeType: string): PromptMeta => {
        let promptMeta: PromptMeta;
        switch (unsubscribeType) {
            case UnsubscribedType.TEACHERS:
                promptMeta = {
                    icon: '/assets/application/img/prompts/no_unsubscribed_schools.png',
                    entitySingular: 'Unsubscribed Teacher',
                    entityPlural: 'Unsubscribed Teachers',
                    iconHeight: 80,
                    helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.UNSUBSCRIBE_TEACHER),
                };
                break;
            case UnsubscribedType.SCHOOLS:
                promptMeta = {
                    icon: '/assets/application/img/prompts/no_unsubscribed_schools.png',
                    entitySingular: 'Unsubscribed School',
                    entityPlural: 'Unsubscribed Schools',
                    iconHeight: 80,
                    helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.UNSUBSCRIBE_SCHOOL),
                };
                break;
            default:
                throw new Error('unknown type {type}');
        }
        return promptMeta;
    };

    return (
        <>
            <CampusDataGrid
                repository={repository}
                dataGridMeta={{
                    uniqueKey: props.dataGridUniqueKey,
                    entitySingular: props.dataGridEntitySingular,
                    entityPlural: props.dataGridEntityPlural,
                    defaultColumns: DefaultColumns,
                    columnOptions: Columns,
                    hideSelect: true,
                    defaultSortColumn: 'unsubscribedDate',
                }}
                actionBarMeta={{
                    searchPlaceHolder: props.searchFilterPlaceholder,
                    includeCounts: true,
                    hideAddButton: true,
                    extraActionBarMeta: {
                        customActions: [
                            {
                                name: 'Export',
                                action: () => {
                                    // Hit the account export_unsubscribes endpoint
                                    // The response handler is the same as export.js
                                    // So if this is changed it'll need to be changed there too.
                                    fetch('/account/export/export_unsubscribes/' + props.type)
                                        .then((res) => {
                                            if (res.ok) {
                                                res.blob().then((blob) => {
                                                    const url = window.URL.createObjectURL(blob);
                                                    const a = document.createElement('a');
                                                    a.style.display = 'none';
                                                    a.href = url;
                                                    // Content-Disposition attachment;filename=[file_name]
                                                    a.download =
                                                        res.headers
                                                            .get('Content-Disposition')
                                                            ?.split(';')[1]
                                                            .split('=')[1] ?? 'export.csv';
                                                    document.body.appendChild(a);
                                                    a.click();
                                                    window.URL.revokeObjectURL(url);
                                                    a.remove();
                                                });
                                            } else {
                                                showBanner({
                                                    message: res?.statusText,
                                                    level: LogLevel.ERROR,
                                                    dismissable: true,
                                                });
                                            }
                                        })
                                        .catch((err) => {
                                            showBanner({
                                                message: err?.message ?? err,
                                                level: LogLevel.ERROR,
                                                dismissable: true,
                                            });
                                        });
                                },
                            },
                        ],
                    },
                }}
                promptMeta={generatePromptMeta(props.type)}
            />
        </>
    );
};
export default UnsubscribedTable;
