import {
    AppEvent,
    EventBusInstance,
    Modal,
    ModalType,
} from '@sprint/sprint-react-components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import EmailType from '../../Models/EmailType';

interface Props {
    uniqueKey: string;
    title: string;
}

const EmailsPreviewModal: FunctionComponent<Props> = (props: Props) => {
    // State: General
    const [shown, setShown] = useState<boolean>(false);
    const [emailContents, setEmailContents] = useState<string>('');

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<EmailType>) => {
            if (event.target !== props.uniqueKey) return;
            setEmailContents(event.message.html);
            setShown(true);
        });
    }, [shown]);

    const onClose = () => {
        setShown(false);
    }

    return (
        <Modal
            className="emails-preview-modal"
            close={onClose}
            isOpen={shown}
            title={props.title}
            type={ModalType.INFO}
            fullScreen={true}
        >
            <div dangerouslySetInnerHTML={{ __html: emailContents }}></div>
        </Modal>
    );
};

export default EmailsPreviewModal;
