'use strict';

import CampusEntity from "../CampusEntity/CampusEntity";
import TaskType from "../TaskType/TaskType";
import User from "../User/User";
import * as TaskStatus from "../../ValueObjects/TaskStatus/TaskStatus";
import * as TaskPriority from "../../ValueObjects/TaskPriority/TaskPriority";
import Contact from "../Contact/Contact";
import Organisation from "../Organisation/Organisation";
import Deal from "../Deal/Deal";
import DateHelper from "../../Helpers/DateHelper";

export default class Task extends CampusEntity {

    id = undefined;
    name = '';
    description = '';
    taskType = undefined;
    assignedTo = undefined;
    assignedBy = undefined;
    private = false;
    dueDate = '';
    completedDate = '';
    status = TaskStatus.defaultOption();
    priority = TaskPriority.defaultOption();
    created = '';
    createdBy = undefined;
    modified = '';
    modifiedBy = undefined;
    relatesTo = '';
    relatesToContact = undefined;
    relatesToOrganisation = undefined;
    relatesToDeal = undefined;

    static blank() {
        return new Task();
    }

    static fromApi(task) {
        const entity = Task.blank();

        // Construct from expected API output object
        // Note: `is_logged_in` will not be set for the below Users, access via Client object
        entity.id = task.id;
        entity.name = task.name;
        entity.description = task.description;
        entity.taskType = task.task_type ? TaskType.fromApi(task.task_type) : undefined;
        entity.assignedTo = task.assigned_to ? User.fromApi(task.assigned_to) : undefined;
        entity.assignedBy = task.assigned_by ? User.fromApi(task.assigned_by) : undefined;
        entity.private = !!(task.private === '1');
        entity.dueDate = task.due_date;
        entity.completedDate = task.completed_date;
        entity.status = task.status;
        entity.priority = task.priority;
        entity.created = task.created;
        entity.createdBy = task.created_by ? User.fromApi(task.created_by) : undefined;
        entity.modified = task.modified;
        entity.modifiedBy = task.modified_by ? User.fromApi(task.modified_by) : undefined;
        entity.relatesTo = task.relates_to;
        entity.relatesToContact = task.relates_to_contact ? Contact.fromApi(task.relates_to_contact) : undefined;
        entity.relatesToOrganisation = task.relates_to_organisation ? Organisation.fromApi(task.relates_to_organisation) : undefined;
        entity.relatesToDeal = task.relates_to_deal ? Deal.fromApi(task.relates_to_deal) : undefined;

        return entity;
    }

    static select(entityArray, id) {
        return entityArray.find(entity => entity.matches(id));
    }

    matches(id) {
        return String(this.id) === String(id);
    }

    clone() {
        return Object.assign(Task.blank(), this);
    }

    isOverdue() {
        if (!this.dueDate) return false;
        return (DateHelper.isPast(this.dueDate) && this.status !== TaskStatus.COMPLETE);
    }

    makeViewUrl(client) {
        const relativePath = 'tasks/view/' + this.id;
        if (client) return client.makeUrl(relativePath);
        else return relativePath;
    }

    forApi() {
        const formData = new FormData();

        // Note: FormData will transmit a string of 'undefined' if the field is empty, so check before appending.
        if (this.id) formData.append('id', this.id);
        if (this.name) formData.append('name', this.name);
        if (this.description) formData.append('description', this.description);
        if (this.taskType) formData.append('task_type_id', this.taskType.id);
        if (this.assignedTo) formData.append('assigned_to_id', this.assignedTo.id);
        if (this.assignedBy) formData.append('assigned_by_id', this.assignedBy.id);
        if (this.private) formData.append('private', this.private ? 'on' : ''); // Note: this is due to compatibility with processing of old form prettyCheckboxes
        if (this.dueDate) formData.append('due_date', this.dueDate);
        if (this.completedDate) formData.append('completed_date', this.completedDate);
        if (this.status) formData.append('status', this.status);
        if (this.priority) formData.append('priority', this.priority);
        if (this.relatesTo) formData.append('relates_to', this.relatesTo);
        if (this.relatesToContact) formData.append('relates_to_contact', this.relatesToContact.id);
        if (this.relatesToOrganisation) formData.append('relates_to_organisation', this.relatesToOrganisation.id);
        if (this.relatesToDeal) formData.append('relates_to_deal', this.relatesToDeal.id);

        return formData;
    }

}
