'use strict';

import * as DealStageState from '../../../../ValueObjects/DealStageState/DealStageState';
import CampusEntity from '../../../CampusEntity/CampusEntity';

export default class DealPipelineStage extends CampusEntity {
    id = null;
    name = '';
    stageState = DealStageState.OPEN;
    mandatoryFields = [];
    isDefault = false;
    stagePosition = 0;

    static blank() {
        return new DealPipelineStage();
    }

    static blankOpen() {
        let entity = DealPipelineStage.blank();

        entity.name = DealStageState.OPEN;
        entity.stageState = DealStageState.OPEN;

        return entity;
    }

    static blankClosedWon() {
        let entity = DealPipelineStage.blank();

        entity.name = DealStageState.CLOSED_WON;
        entity.stageState = DealStageState.CLOSED_WON;

        return entity;
    }

    static blankClosedLost() {
        let entity = DealPipelineStage.blank();

        entity.name = DealStageState.CLOSED_LOST;
        entity.stageState = DealStageState.CLOSED_LOST;

        return entity;
    }

    static fromApi(stage) {
        const entity = DealPipelineStage.blank();

        // Construct from expected API output object
        entity.id = Number(stage.id);
        entity.name = stage.name;
        entity.stageState = stage.stage_state;
        entity.mandatoryFields = stage.mandatory_fields;
        entity.isDefault = stage.is_default;
        entity.stagePosition = Number(stage.stage_position);

        return entity;
    }

    static filterByState(entityArray, stageState) {
        return entityArray.filter((entity) => entity.stageState === stageState);
    }

    static select(entityArray, id) {
        return entityArray.find((entity) => entity.matches(id));
    }

    matches(id) {
        return String(this.name) === String(id);
    }

    clone() {
        return Object.assign(DealPipelineStage.blank(), this);
    }

    isOpen() {
        return this.stageState === DealStageState.OPEN;
    }

    isClosed() {
        return this.stageState !== DealStageState.OPEN;
    }

    forSelect() {
        return {
            id: this.name,
            label: this.name,
        };
    }

    forApi() {
        return {
            id: this.id,
            name: this.name,
            stage_state: this.stageState,
            mandatory_fields: this.mandatoryFields,
            is_default: this.isDefault,
        };
    }
}
