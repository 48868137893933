import * as ActionTypes from "./ActionTypes";
import User from "./User"

const initialState = {
    searchResults: undefined,
    error: undefined
};

export default function (state, action) {
    let error = undefined;

    if (state === undefined) {
        state = initialState;
    }

    switch (action.type) {
        case ActionTypes.USERS_LOADED:
            try {
                return Object.assign({}, state, {
                    searchResults: action.payload.users.map(user => User.fromApi(user))
                });
            } catch (e) {
                error = 'Unable to initialise user object: ' + e.message;
            }
            break;
        case ActionTypes.USERS_LOADING_FAILED:
            error = 'Unable to load user: ' + action.payload.error;
            break;
        default:
            return state;
    }

    if (error) {
        return {
            searchResults: undefined,
            error: error
        };
    } else return state;
}