import React, { FunctionComponent, useEffect, useState } from 'react';
import PrivacyPolicyNeededModal from './PrivacyPolicyNeededModal';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
}

const FormsAddRedirect: FunctionComponent<Props> = (props: Props) => {
    const addUrl = '/forms/home/add';
    const [privacyPolicyNeededModalShown, setPrivacyPolicyNeededModalShown] = useState<boolean>(false);
    const privacyPolicyOk = JSON.parse(
        String((document.getElementById('privacy_policy_ok') as HTMLInputElement).value),
    );

    useEffect(() => {
        if (props.shown) {
            if (!privacyPolicyOk) {
                setPrivacyPolicyNeededModalShown(true);
            } else {
                window.location.href = addUrl;
            }
        }
    }, [props.shown]);

    return privacyPolicyNeededModalShown ? (
        <PrivacyPolicyNeededModal
            shown={privacyPolicyNeededModalShown}
            onClose={() => setPrivacyPolicyNeededModalShown(false)}
        />
    ) : (
        <></>
    );
};

export default FormsAddRedirect;
