import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, FunctionComponent } from 'react';

interface Props {
    value: string | number;
    label: any;
    checked: boolean;
    onChange: (value: string | number, checked: boolean) => void;
    disabled?: boolean;
    onBlur?: () => void;
}

const CheckBox: FunctionComponent<Props> = (props: Props) => {
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.onChange(props.value, e.target.checked);
    };

    const onBlur = () => {
        if (!props.onBlur) return;
        props.onBlur();
    };

    return (
        <span className="pretty p-default p-curve p-fill p-icon p-smooth p-bigger-medium pretty-word-wrap">
            <input
                type="checkbox"
                value={props.value}
                checked={props.checked}
                disabled={props.disabled}
                onChange={onChange}
                onBlur={onBlur}
            />
            <div className="state p-info">
                <label className="ch-medium">
                    <span>{props.label}</span>
                </label>
                <FontAwesomeIcon icon={faCheck} className="icon" transform="shrink-8" />
            </div>
        </span>
    );
};

export default CheckBox;
