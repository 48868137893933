import { EventBusInstance } from '@sprint/sprint-react-components';
import {
    TEACHERS_LOADED,
    TEACHERS_LOADING,
    TEACHERS_LOADING_FAILED,
    TEACHER_SELECTION_CHANGED,
    TEACHER_UNSUBSCRIBED,
    UNSUBSCRIBE_TEACHER,
    UNSUBSCRIBE_TEACHER_FAILED,
} from './Actions/ActionTypes';

const initialState = {
    teachers: undefined,
    teachersLoading: true,
    hasTeacherEmailsModule: false,
    error: undefined,
    teachersUnsubscribing: [],
    teachersSelected: [],
};

export default function (state, action) {
    if (state === undefined) {
        state = initialState;
    }

    switch (action.type) {
        case TEACHERS_LOADING:
            return { ...state, teachers: undefined, teachersLoading: true, teachersSelected: [] };
        case TEACHERS_LOADED:
            return {
                ...state,
                teachers: action.payload.teachers.teachers,
                hasTeacherEmailsModule: !action.payload.teachers.teacher_emails_disabled,
                teachersLoading: false,
            };
        case UNSUBSCRIBE_TEACHER:
            const u = [...state.teachersUnsubscribing];
            u.push(action.payload.teacherId);
            return { ...state, teachersUnsubscribing: u };
        case TEACHER_UNSUBSCRIBED:
            EventBusInstance.publish({
                topic: 'update-datagrid-rows',
                message: state.teachers.filter((t) => t.id == action.payload.teacherId)[0],
                target: 'teacher-unsubscribed-banner',
            });
            return {
                ...state,
                teachers: state.teachers.map((t) => {
                    // make sure to clone the object to avoid malforming the state (redux errors if you do)
                    const teacherCopy = _.cloneDeep(t);
                    if (teacherCopy.id === action.payload.teacherId) teacherCopy.marketing_unsubscribed = true;
                    return teacherCopy;
                }),
                teachersUnsubscribing: state.teachersUnsubscribing.filter((t) => t !== action.payload.teacherId),
                teachersSelected: updateTeacherSelection(state.teachersSelected, action.payload.teacherId, false),
            };
        case UNSUBSCRIBE_TEACHER_FAILED:
        // fall through
        case TEACHERS_LOADING_FAILED:
            return { ...state, teachersLoading: false, error: action.payload.error };
        case TEACHER_SELECTION_CHANGED:
            return {
                ...state,
                teachersSelected: updateTeacherSelection(
                    state.teachersSelected,
                    action.payload.teacherId,
                    action.payload.checkedState,
                ),
            };
        default:
            return state;
    }
}

const updateTeacherSelection = (teachersSelected, teacherId, checkedState) => {
    let teachersSelectedCopy = _.clone(teachersSelected);
    if (checkedState) {
        // add
        if (!_.includes(teachersSelectedCopy, teacherId)) {
            teachersSelectedCopy.push(teacherId);
        }
    } else {
        // remove
        teachersSelectedCopy = _.without(teachersSelectedCopy, teacherId);
    }
    return teachersSelectedCopy;
};
