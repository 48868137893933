'use strict';

import HttpRequestService from "../../../Services/HttpRequestService/HttpRequestService";

export default class DealLostReasonRepository {

    static all() {
        return new Promise(function(resolve, reject) {
            new HttpRequestService()
                .get('deals/ajax/get_deal_lost_reasons')
                .then(response => {
                    if (response.status !== 200) return reject('Unable to load deal lost reasons, endpoint not responding. ERR-REACT-DLR-07');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.message);
                })
        });
    }

    static save(dealLostReasonEntity) {
        return new Promise(function(resolve, reject) {
            if (typeof dealLostReasonEntity.forApi !== 'function') return reject('Unable to save deal lost reason, invalid deal lost reason entity provided. ERR-REACT-DLR-06');
            new HttpRequestService()
                .post('settings/deal_lost_reasons/save', dealLostReasonEntity.forApi())
                .then(response => {
                    if (!response) return reject('Invalid response returned. ERR-REACT-DLR-01');
                    if (response.status !== 200) return reject('Unable to save deal lost reason, endpoint not responding. ERR-REACT-DLR-02');
                    if (!response.data.data) return reject('Invalid response returned. ERR-REACT-DLR-05');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve(response.data.data);
                })
                .catch(error => reject(error.message));
        });
    }

    static find(dealLostReasonId) {
        return new Promise(function(resolve, reject) {
            new HttpRequestService()
                .get('settings/deal_lost_reasons/get/' + dealLostReasonId)
                .then(response => {
                    if (!response) return reject('Invalid response returned. ERR-REACT-DLR-03');
                    if (response.status !== 200) return reject('Unable to load deal lost reason, endpoint not responding. ERR-REACT-DLR-04');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve(response.data.data);
                })
                .catch(error => reject(error.message));
        })
    }

    static delete(dealLostReasonId) {
        return new Promise(function(resolve, reject) {
            if (dealLostReasonId < 1) return reject('Unable to delete deal lost reason, invalid ID provided. ERR-REACT-DLR-11');

            const formData = new FormData();
            formData.append('id', dealLostReasonId);

            new HttpRequestService()
                .post('settings/deal_lost_reasons/delete/', formData)
                .then(response => {
                    if (!response) return reject('Invalid response returned. ERR-REACT-DLR-09');
                    if (response.status !== 200) return reject('Unable to delete deal lost reason, endpoint not responding. ERR-REACT-DLR-10');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve();
                })
                .catch(error => reject(error.message))
        });
    }


}