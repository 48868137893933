import { Checkbox, isValidString, LogLevel, showBanner, Sisp, PendingButton } from '@sprint/sprint-react-components';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { RepositoryFactoryContext } from '../../index';
import { DealLostReasonRequest } from '../../Api/DealLostReasonRequest';
import DealLostReason from '../../Models/DealLostReason';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
    uniqueKey: string;
}

const DealLostReasonsAddSisp: FunctionComponent<Props> = (props: Props) => {
    const dealLostReasonsRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new DealLostReasonRequest(),
    );

    const focusRef = useRef<HTMLInputElement>(null);

    const [newReason, setNewReason] = useState<string>('');
    const [newReasonValid, setNewReasonValid] = useState<boolean>(true);

    const [newDefault, setNewDefault] = useState<boolean>(false);

    const [isSaving, setIsSaving] = useState<boolean>(false);

    useEffect(() => {
        if (props.shown) {
            focusRef.current?.focus();
        }
    }, [props.shown]);

    const validate = async (): Promise<boolean> => {
        const reasonValid = !!newReason && isValidString(newReason);
        setNewReasonValid(reasonValid);
        return reasonValid;
    };

    const reset = () => {
        setNewReason('');
        setNewDefault(false);
        setNewReasonValid(true);
    };

    const handleAddRow = async (): Promise<boolean> => {
        const DealLostReason: DealLostReason = {
            reason: newReason,
            is_default: newDefault,
            // Doesnt change can only be false
            built_in: false,
        };

        return dealLostReasonsRepository
            .create(DealLostReason)
            .then(props.onSuccess)
            .then(async (success) => {
                reset();
                return success;
            })
            .catch((err) => {
                showBanner(
                    {
                        message: 'Failed to create Lost Reason - ' + (err?.message ?? err),
                        level: LogLevel.ERROR,
                    },
                    props.uniqueKey,
                );
                return false;
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        setIsSaving(true);
        e.preventDefault();
        if ((await validate()) && (await handleAddRow())) props.onClose();
        setIsSaving(false);
    };

    const onSubmitAndAddAnother = async (e: FormEvent) => {
        setIsSaving(true);
        e.preventDefault();
        if ((await validate()) && (await handleAddRow())) {
            reset();
            focusRef.current?.focus();
        }
        setIsSaving(false);
    };

    return (
        <Sisp
            isOpen={props.shown}
            onSubmit={handleAddRow}
            onCancel={() => {
                reset();
                props.onClose();
            }}
            validate={validate}
            footerOverride={
                <>
                    <Button
                        variant="default"
                        onClick={() => {
                            reset();
                            props.onClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <PendingButton variant="default" onClick={onSubmitAndAddAnother} pending={isSaving}>
                        Save & Add Another
                    </PendingButton>
                    <PendingButton variant="primary" onClick={onSubmitForm} pending={isSaving}>
                        Save
                    </PendingButton>
                </>
            }
        >
            <h4>Add a Lost Reason</h4>
            <Form onSubmit={onSubmitForm}>
                <Form.Group>
                    <Form.Label>
                        Lost Reason <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        ref={focusRef}
                        type="text"
                        isInvalid={!newReasonValid}
                        value={newReason || ''}
                        onChange={(event) => {
                            setNewReason(event.target.value);
                            setNewReasonValid(true);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {!newReasonValid && 'This field is required.'}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Checkbox
                        label="Make this the default lost reason"
                        isChecked={newDefault}
                        onChange={(event) => setNewDefault(event.target.checked)}
                    />
                </Form.Group>
            </Form>
        </Sisp>
    );
};

export default DealLostReasonsAddSisp;
