'use strict';

import HttpRequestService from "../../Services/HttpRequestService/HttpRequestService";

export default class SaleRepository {

    static filter(textFilter, advancedSearch, cancelToken, offset) {
        return new Promise(function(resolve, reject) {
            const formData = new FormData();

            if (advancedSearch) formData.append('advancedSearch', JSON.stringify(advancedSearch));

            let limit = advancedSearch ? 10 : 5;

            new HttpRequestService()
                .post('sales/ajax/filter/' + offset + '/' + limit + '/' + textFilter, formData, cancelToken)
                .then(response => {
                    if (!response) return reject('Invalid response returned. ERR-REACT-SA-01');
                    if (response.status === 499) return resolve(undefined); // Request cancelled by user
                    if (response.status !== 200) return reject('Unable to load sales, endpoint not responding. ERR-REACT-SA-02');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve(response.data.data);
                })
                .catch(error => reject(error.message));
        });
    }

    static loadIdList(idList) {
        return new Promise(function(resolve, reject) {
            const formData = new FormData();
            formData.append('id_list', JSON.stringify(idList));
            new HttpRequestService()
                .post('sales/ajax/by_ids', formData)
                .then(response => {
                    if (!response) return reject('Invalid response returned. ERR-REACT-SA-03');
                    if (response.status !== 200) return reject('Unable to load sales, endpoint not responding. ERR-REACT-SA-04');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve(response.data.data);
                })
                .catch(error => reject(error.message));
        });
    }

    static linkToDeal(saleId, dealId) {
        return new Promise(function(resolve, reject) {
            if (!saleId) return reject('Unable to update sale, invalid sale ID provided. ERR-REACT-SA-05');
            if (!dealId) return reject('Unable to update sale, invalid deal ID provided. ERR-REACT-SA-06');

            const formData = new FormData();
            formData.append('sale_id', saleId);
            formData.append('deal_id', dealId);

            new HttpRequestService()
                .post('sales/ajax/link_to_deal/', formData)
                .then(response => {
                    if (!response) return reject('Invalid response returned. ERR-REACT-SA-07');
                    if (response.status !== 200) return reject('Unable to update sale, endpoint not responding. ERR-REACT-SA-08');
                    if (response.data.success === false) return reject(response.data.error);
                    if (!response.data.data) return reject('Invalid response returned. ERR-REACT-SA-09');
                    resolve(response.data.data);
                })
                .catch(error => reject(error.message))
        });
    }
}