'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import MultiSelect from "../../../../CommonComponents/Select/MultiSelect";
import Spinner from "../../../../CommonComponents/Spinner/Spinner";

class DealLostReasonMultiSelect extends React.Component {

    render() {
        let placeholder = <span>{'Lost Reason: '}<Spinner/></span>;
        if (this.props.availableValues !== undefined) {
            placeholder = this.props.selectedValues.map((v) => {
                const item = this.props.availableValues.filter(x => x.id === v);
                return item.length > 0 ? item[0].reason : '';
            }).join(", ");
            if (!placeholder) placeholder = 'Lost Reason: All';
        }

        const availableOptions = this.props.availableValues ? this.props.availableValues.map(function(dealLostReason) {
            dealLostReason.label = dealLostReason.reason;
            return dealLostReason;
        }) : undefined;

        return <MultiSelect
            placeholder={placeholder}
            open={this.props.open}
            toggle={this.props.toggle}
            selectedOptions={this.props.selectedValues}
            availableOptions={availableOptions}
            updateSelectedOptions={this.props.updateSelectedOptions}
        />;
    }

}

DealLostReasonMultiSelect.propTypes = {
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    updateSelectedOptions: PropTypes.func.isRequired,
    availableValues: PropTypes.array,
    selectedValues: PropTypes.array
};

export default DealLostReasonMultiSelect;