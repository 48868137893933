import Limit from '../Models/Limit';
import LimitIssue from '../Models/LimitIssue';
import { NearLimitMethod } from '../Models/Enums';

export const calculateNearLimit = (limit: Limit, nearLimitMethod: NearLimitMethod, percentLimit?: number): boolean => {
    switch (nearLimitMethod) {
        case NearLimitMethod.ONE_LESS:
            return limit.limit - limit.used === 1;
        case NearLimitMethod.PERCENTAGE:
        default:
            return (limit.used / limit.limit) * 100 >= percentLimit!;
    }
};

// In the case where there are multiple limit issues want to order the issues
// so the reached limits are first in the list before the near limit issues
export const orderLimitIssues = (limitIssues: LimitIssue[]): LimitIssue[] => {
    return limitIssues.sort((limitIssueA, limitIssueB) => {
        if (limitIssueA.near_limit === limitIssueB.near_limit) {
            return 0;
        }
        return limitIssueA.near_limit ? 1 : -1;
    });
};
