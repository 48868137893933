'use strict';

export const QUOTES_UPDATE_FILTER = 'quote/QUOTES_UPDATE_FILTER';
export const QUOTES_LOADED = 'quote/QUOTES_LOADED';
export const QUOTES_MORE_LOADING = 'quote/QUOTES_MORE_LOADING';
export const QUOTES_MORE_LOADED = 'quote/QUOTES_MORE_LOADED';
export const QUOTES_IDS_LOADED = 'quote/QUOTES_IDS_LOADED';
export const QUOTES_LOADING_CANCELLED = 'quote/QUOTES_LOADING_CANCELLED';
export const QUOTES_LOADING_FAILED = 'quote/QUOTES_LOADING_FAILED';
export const QUOTE_LINKING_TO_DEAL = 'quote/QUOTE_LINKING_TO_DEAL';
export const QUOTE_LINKED_TO_DEAL = 'quote/QUOTE_LINKED_TO_DEAL';
export const QUOTE_LINKING_TO_DEAL_FAILED = 'quote/QUOTE_LINKING_TO_DEAL_FAILED';