'use strict';

import FormReducer from "./Reducers/FormReducer";
import PreviewReducer from "./Reducers/PreviewReducer";
import ViewReducer from "./Reducers/ViewReducer";
import BoardReducer from "./Reducers/BoardReducer";
import DealReducer from "../../Entities/Deal/DealReducer";
import DealTypeReducer from "../../Entities/Deal/DealType/DealTypeReducer";
import DealPipelineReducer from "../../Entities/Deal/DealPipeline/DealPipelineReducer";
import DealLostReasonReducer from "../../Entities/Deal/DealLostReason/DealLostReasonReducer";
import UserReducer from "../../Entities/User/UserReducer";
import ClientReducer from "../../Entities/Client/ClientReducer";
import ContactReducer from "../../Entities/Contact/ContactReducer";
import OrganisationReducer from "../../Entities/Organisation/OrganisationReducer";
import QuoteReducer from "../../Entities/Quote/QuoteReducer";
import QuoteLinkReducer from "./Reducers/QuoteLinkReducer";
import SaleReducer from "../../Entities/Sale/SaleReducer";
import SaleLinkReducer from "./Reducers/SaleLinkReducer";
import ModalReducer from "../../CommonComponents/Modal/ModalReducer";
import AdvancedSearchReducer from "./Reducers/AdvancedSearchReducer";
import SavedSearchReducer from "../../Entities/SavedSearch/SavedSearchReducer";

// combineReducers() is performed in configureStore.js
export default {
    'form': FormReducer,
    'preview': PreviewReducer,
    'view': ViewReducer,
    'board': BoardReducer,
    'deal': DealReducer,
    'dealType': DealTypeReducer,
    'dealPipeline': DealPipelineReducer,
    'dealLostReason': DealLostReasonReducer,
    'user': UserReducer,
    'client': ClientReducer,
    'contacts': ContactReducer,
    'organisations': OrganisationReducer,
    'quoteLink': QuoteLinkReducer,
    'quote': QuoteReducer,
    'saleLink': SaleLinkReducer,
    'sale': SaleReducer,
    'modal': ModalReducer,
    'advancedSearch': AdvancedSearchReducer,
    'savedSearches': SavedSearchReducer
};