import {
    AsyncDropDownPaginated,
    Modal,
    ModalType,
    OptionTypeBase,
    OptionTypeBaseUserFormatter,
    SearchQuery,
    SortOrder,
    showBanner,
} from '@sprint/sprint-react-components';

import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { RepositoryFactoryContext, UserContext } from '../..';
import { UserTypeRequest } from '../../Api/UserTypeRequest';
import UserType from '../../Models/UserType';

interface Props {
    selectedRowsForDeletion: number[];
    modalShown: boolean;
    reloadDataGrid: () => void;
    closeModal: () => void;
    onDeleteRows: (ids: number[]) => Promise<boolean>;
}

const BillingUserReplacementModal: React.FunctionComponent<Props> = (props: Props) => {
    const thisUser = useContext(UserContext);
    const usersRepository = useContext(RepositoryFactoryContext).getApiRepository<Partial<UserType>>(
        new UserTypeRequest(),
    );

    // GUI state
    const [selectedUser, setSelectedUser] = useState<OptionTypeBase | null>(null);
    const [needsNewSelection, setNeedsNewSelection] = useState(true);
    const [initialised, setInitialised] = useState(false);

    useEffect(() => {
        if (!props.modalShown) {
            setInitialised(false);
            setSelectedUser(null);
            return;
        }

        usersRepository
            .search(new SearchQuery(1, 1000))
            .then((results) => {
                const leftOverUsers = _.filter(results.results as UserType[], (user) => {
                    return !_.includes(props.selectedRowsForDeletion, user.id);
                });
                const billingUsers = _.filter(leftOverUsers, 'billing_user');
                // If this would leave us with no billing user, must set a new one
                setNeedsNewSelection(billingUsers.length < 1);
                setInitialised(true);
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get users - ' + (err?.message ?? err),
                });
            });
    }, [props.modalShown]);

    const loadUsers = (filter?: string, page?: number): Promise<{ value: number; label: JSX.Element }[]> => {
        // Fetch all users for client account
        const query = new SearchQuery(page ?? 1, 100, 'first_name', SortOrder.ASC, filter);
        return usersRepository
            .search(query)
            .then((results) => {
                return _.map(
                    _.filter(results.results as UserType[], (user: UserType) => {
                        return (
                            // Dont include other users that are being deleted
                            !_.includes(props.selectedRowsForDeletion, user.id) &&
                            user.id != thisUser.id &&
                            // Filter down to text search
                            _.includes(user?.name?.toLowerCase(), filter?.toLowerCase())
                        );
                    }),
                    (user: UserType) => {
                        return OptionTypeBaseUserFormatter(user);
                    },
                );
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get users - ' + (err?.message ?? err),
                });
                return [];
            });
    };

    const deleteUsers = () => {
        if (selectedUser) {
            return usersRepository.search(new SearchQuery(1, 1000)).then((results) => {
                // Set our selected user as new billing user
                const user = _.find(results.results, (user) => user.id == selectedUser.value);
                if (user) {
                    user.billing_user = true;
                    return usersRepository.update(user).then(() => props.onDeleteRows(props.selectedRowsForDeletion));
                } else {
                    return false;
                }
            });
        }

        return props.onDeleteRows(props.selectedRowsForDeletion);
    };

    return (
        <>
            <Modal
                type={ModalType.DELETE}
                isOpen={props.modalShown && initialised}
                close={props.closeModal}
                onSubmit={deleteUsers}
                title={'Delete User'}
                className="delete-billing-user"
                blocking={true}
                confirmed={!needsNewSelection || selectedUser != null}
            >
                {needsNewSelection && (
                    <>
                        <p>
                            Deleting {props.selectedRowsForDeletion.length > 1 ? 'these users' : 'this user'} would
                            leave you with no billing contacts, please choose a replacement before deleting
                        </p>
                        <Form>
                            <Row>
                                <Form.Group>
                                    <AsyncDropDownPaginated
                                        className="mx-4"
                                        id="replacement-billing-user-dropdown"
                                        isInvalid={false}
                                        placeholder="Choose a new billing user"
                                        value={selectedUser}
                                        onChange={(selected: OptionTypeBase) => {
                                            setSelectedUser(selected);
                                        }}
                                        loadOptions={async (filter: string, _loadedOptions, { page }) => {
                                            const res = await loadUsers(filter, page);
                                            return {
                                                options: res,
                                                hasMore: false,
                                                additional: {
                                                    page: page + 1,
                                                },
                                            };
                                        }}
                                        menuPortalTarget={document.body}
                                    />
                                </Form.Group>
                            </Row>

                            <input type="submit" hidden />
                        </Form>
                    </>
                )}
                {!needsNewSelection && (
                    <>
                        Are you sure you want to delete
                        {props.selectedRowsForDeletion.length > 1
                            ? ` these ${props.selectedRowsForDeletion.length} 'users'`
                            : ' this user?'}
                    </>
                )}
            </Modal>
        </>
    );
};

export default BillingUserReplacementModal;
