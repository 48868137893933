import React, { FunctionComponent, useEffect } from 'react';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
}

const EmailsAddRedirect: FunctionComponent<Props> = (props: Props) => {
    const addUrl = '/emails/create';

    useEffect(() => {
        if (props.shown) {
            window.location.href = addUrl;
        }
    }, [props.shown]);

    return <></>;
};

export default EmailsAddRedirect;
