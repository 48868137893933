import React, { FunctionComponent, useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import ColourCircle from '../ClientTags/ColourCircle';
import COLOUR_PRESETS from '../ClientTags/ColourPresets';

interface Props {
    colour: string;
    onChange: (colour: string) => void;
    showPresets?: boolean;
}

const CampusColourPicker: FunctionComponent<Props> = (props: Props) => {
    const [colour, setColour] = useState<string>(props.colour);

    useEffect(() => {
        props.onChange(colour);
    }, [colour]);

    return (
        <>
            {props.showPresets && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #ddd',
                        padding: '5px',
                    }}
                >
                    {COLOUR_PRESETS.map((preset) => {
                        return (
                            <ColourCircle
                                colour={preset}
                                key={preset}
                                onSelect={(colour) => {
                                    setColour(colour);
                                    props.onChange(colour);
                                    return;
                                }}
                            />
                        );
                    })}
                </div>
            )}
            <div
                style={{
                    padding: '5px',
                }}
            >
                <HexColorPicker color={colour} onChange={setColour} />
            </div>
        </>
    );
};

export default CampusColourPicker;
