import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    AppEvent,
    Checkbox,
    EventBusInstance,
    isValidString,
    LogLevel,
    PendingButton,
    showBanner,
    Sisp,
} from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import DealPipelineStage from '../../../../Entities/Deal/DealPipeline/DealPipelineStage/DealPipelineStage';
import StageList from '../../../DealSettings/Components/PipelineStageList/StageList';
import { DealPipelineRequest } from '../../Api/DealPipelineRequest';
import { RepositoryFactoryContext } from '../../index';
import { DealPipeline } from '../../Models/DealPipeline';
import DealPipelineEditState from '../../Models/DealPipelineEditState';

interface Props {
    uniqueKey: string;
    onSuccess: (results: any) => Promise<boolean>;
}

const DealPipelinesEditSisp: FunctionComponent<Props> = (props: Props) => {
    const [shown, setShown] = useState<boolean>(false);
    const dealPipelinesRepository = useContext(RepositoryFactoryContext).getApiRepository(new DealPipelineRequest());

    const focusRef = useRef<HTMLInputElement>(null);
    const newPipelineStageRef = useRef<HTMLInputElement>(null);

    const [rowId, setRowId] = useState<number>(0);

    const [editName, setEditName] = useState<string>('');
    const [editNameValid, setEditNameValid] = useState<boolean>(true);

    const [editDealLength, setEditDealLength] = useState<string>('0');
    const [editDealLengthValid, setEditDealLengthValid] = useState<boolean>(true);

    const [editStageName, setEditStageName] = useState<string>('');
    const [editStageNameValid, setEditStageNameValid] = useState<boolean>(true);

    const [editDefault, setEditDefault] = useState<boolean>(false);

    const [editStageIndex, setEditStageIndex] = useState<number>(0);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isAdd, setIsAdd] = useState<boolean>(false);

    const [pipelinePreEditStages, setPipelinePreEditStages] = useState<DealPipelineStage[]>([]);
    const [pipelinePostEditStages, setPipelinePostEditStages] = useState<DealPipelineStage[]>([]);
    const [pipelineStages, setPipelineStages] = useState<DealPipelineStage[]>([]);
    const [showAddStage, setShowAddStage] = useState<boolean>(false);

    const [isSaving, setIsSaving] = useState<boolean>(false);

    useEffect(() => {
        if (shown && !showAddStage) {
            if (focusRef.current) {
                focusRef.current.focus();
                focusRef.current.selectionStart = focusRef.current.value.length;
                focusRef.current.selectionEnd = focusRef.current.value.length;
            }
            setEditNameValid(true);
        }
    }, [shown, showAddStage]);

    useEffect(() => {
        if (showAddStage) {
            newPipelineStageRef.current?.focus();
        }
    }, [showAddStage]);

    useEffect(() => {
        EventBusInstance.subscribe(
            'show-hoverover-component',
            (event: AppEvent<DealPipelineEditState>) => {
                if (event.target !== props.uniqueKey) return;
                setRowId(event.message.id);
                setEditName(event.message.name);
                setEditDefault(event.message.is_default);
                setPipelineStages(
                    (JSON.parse(event.message.stages) as DealPipelineStage[]).map((dealPipelineStage) => {
                        return DealPipelineStage.fromApi(dealPipelineStage);
                    }),
                );
                setEditDealLength(event.message.default_deal_length_days.toString());
                setShown(true);
            },
            props.uniqueKey,
        );
    }, [shown]);

    const validate = async (): Promise<boolean> => {
        const nameValid = !!editName && isValidString(editName);
        const dealLengthValid = Number.isInteger(Number(editDealLength)) && editDealLength !== '';
        setEditNameValid(nameValid);
        setEditDealLengthValid(dealLengthValid);
        return nameValid && dealLengthValid;
    };

    const handleEditRow = async (): Promise<boolean> => {
        // Make sure default stages are always last
        const allStages = _.cloneDeep(pipelineStages);
        const DealPipeline: DealPipeline = {
            id: rowId,
            name: editName,
            stages: JSON.stringify(allStages.map((dealPipelineStage) => dealPipelineStage.forApi())),
            default_deal_length_days: editDealLength as unknown as number,
            is_default: editDefault,
            // Doesnt change can only be false
            built_in: false,
        };

        return dealPipelinesRepository
            .update(DealPipeline)
            .then((results: any) => {
                props.onSuccess(results.data);
                return Promise.resolve(true);
            })
            .catch((err) => {
                showBanner(
                    {
                        message: 'Failed to edit Deal Pipeline - ' + (err?.message ?? err),
                        level: LogLevel.ERROR,
                    },
                    props.uniqueKey.split('-')[0],
                );
                return Promise.resolve(false);
            });
    };

    const handleAddStage = async (): Promise<boolean> => {
        const editPipelineStages = pipelineStages;
        if (isEdit) {
            const targetStage = editPipelineStages[editStageIndex];
            targetStage.name = editStageName;
            editPipelineStages.splice(editStageIndex, 1, targetStage);
        }
        if (isAdd) {
            const editStage: DealPipelineStage = DealPipelineStage.blankOpen();
            editStage.name = editStageName;
            editPipelineStages.push(editStage);
        }
        setIsEdit(false);
        setIsAdd(false);
        setPipelineStages(editPipelineStages);
        return true;
    };

    const onAddStageClick = async (): Promise<boolean> => {
        const defaultStageIndex = pipelineStages.findIndex((stage) => stage.stageState == 'Closed (Won)') + 1;
        // Populate pre-edit stages
        const preEditStages = pipelineStages.filter((stage, index) => {
            return stage.stagePosition < defaultStageIndex;
        });
        setPipelinePreEditStages(preEditStages);
        // Populate post-edit stages
        const postEditStages = pipelineStages.filter((stage, index) => {
            return stage.stagePosition >= defaultStageIndex;
        });
        setPipelinePostEditStages(postEditStages);
        setIsAdd(true);
        setShowAddStage(true);

        return true;
    };

    const cancelAddEditStage = async (): Promise<void> => {
        setIsAdd(false);
        setIsEdit(false);
        setPipelinePreEditStages([]);
        setPipelinePostEditStages([]);
        setShowAddStage(false);
        setEditStageName('');
        setEditStageNameValid(true);
    };

    const handleReorderStage = async (oldStageIndex: number, editStageIndex: number): Promise<boolean> => {
        if (!isEdit) {
            const editPipelineStages = _.cloneDeep(pipelineStages);
            const targetStage = editPipelineStages[oldStageIndex];
            targetStage.stagePosition = editStageIndex + 1;
            editPipelineStages[editStageIndex].stagePosition = oldStageIndex + 1;

            editPipelineStages.splice(oldStageIndex, 1);
            editPipelineStages.splice(editStageIndex, 0, targetStage);

            setPipelineStages(editPipelineStages);
            return true;
        }
        return false;
    };

    const handleDeleteStage = async (stageEntity: DealPipelineStage): Promise<boolean> => {
        if (isEdit) {
            // Handle edit index if delete called whilst in edit mode
            const deleteIndex = pipelineStages.findIndex((stage) => stage == stageEntity);
            if (deleteIndex < editStageIndex) {
                setEditStageIndex(editStageIndex - 1);
            }
            // Update PreEditStages
            const editPipelinePreEditStages = pipelinePreEditStages.filter((stage) => {
                return stage !== stageEntity;
            });
            setPipelinePreEditStages(editPipelinePreEditStages);
            // Update PostEditStages
            const editPipelinePostEditStages = pipelinePostEditStages.filter((stage) => {
                return stage !== stageEntity;
            });
            setPipelinePostEditStages(editPipelinePostEditStages);
        }
        if (isAdd) {
            // Update PreEditStages
            const newPipelinePreEditStages = pipelinePreEditStages.filter((stage) => {
                return stage !== stageEntity;
            });
            setPipelinePreEditStages(newPipelinePreEditStages);
        }
        const editPipelineStages = pipelineStages.filter((stage) => {
            return stage !== stageEntity;
        });
        setPipelineStages(editPipelineStages);
        return true;
    };

    const handleEditStage = async (stageEntity: DealPipelineStage): Promise<boolean> => {
        const editItemIndex = pipelineStages.findIndex((stage) => stage == stageEntity);
        setEditStageIndex(editItemIndex);
        // Populate pre-edit stages
        const preEditStages = pipelineStages.filter((stage: DealPipelineStage, index) => {
            return stage.stagePosition < editItemIndex + 1;
        });
        setPipelinePreEditStages(preEditStages);
        // Populate post-edit stages
        const postEditStages = pipelineStages.filter((stage, index) => {
            return stage.stagePosition > editItemIndex + 1;
        });
        setPipelinePostEditStages(postEditStages);
        setIsEdit(true);
        setEditStageName(stageEntity.name);
        setShowAddStage(true);

        return true;
    };

    const onSubmitAddStage = async (e: FormEvent) => {
        e.preventDefault();
        const stageNameValid = !!editStageName && isValidString(editStageName);
        setEditStageNameValid(stageNameValid);
        if (stageNameValid) {
            handleAddStage();
            setShowAddStage(false);
            setEditStageName('');
        }
    };

    const onSubmitForm = async (e: FormEvent) => {
        setIsSaving(true);
        e.preventDefault();
        if ((await validate()) && (await handleEditRow())) setShown(false);
        setIsSaving(false);
    };

    return (
        <Sisp
            isOpen={shown}
            onSubmit={handleEditRow}
            onCancel={() => {
                setShown(false);
            }}
            validate={validate}
            footerOverride={
                <>
                    <Button
                        variant="default"
                        onClick={() => {
                            setShown(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <PendingButton variant="primary" onClick={onSubmitForm} pending={isSaving}>
                        Save
                    </PendingButton>
                </>
            }
        >
            <h4>Edit a Deal Pipeline</h4>
            <Form onSubmit={onSubmitForm}>
                <Form.Group>
                    <Form.Label>
                        Pipeline Name <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        autoComplete="off"
                        ref={focusRef}
                        name="text"
                        isInvalid={!editNameValid}
                        value={editName || ''}
                        onChange={(event) => {
                            setEditName(event.target.value);
                            setEditNameValid(true);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {!editNameValid && 'This field is required.'}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Checkbox
                        label="Make this the default pipeline"
                        isChecked={editDefault}
                        onChange={(event) => setEditDefault(event.target.checked)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        Typical Deal Length (in days) <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        name="deal_length"
                        isInvalid={!editDealLengthValid}
                        value={editDealLength || ''}
                        onChange={(event) => {
                            setEditDealLength(event.target.value);
                            setEditDealLengthValid(true);
                        }}
                    />
                    <span className="help-block">This is used to auto-populate the close date on edit deals.</span>
                    <Form.Control.Feedback type="invalid">
                        {!editDealLengthValid && 'This field is required.'}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <div>
                        {!showAddStage && (
                            <Button
                                variant="default"
                                size="sm"
                                className="pull-right"
                                style={{ margin: '10px 0' }}
                                onClick={() => onAddStageClick()}
                            >
                                Add a Stage
                            </Button>
                        )}
                        <Form.Label>Stages</Form.Label>
                    </div>
                </Form.Group>
                {(isAdd || isEdit) && pipelinePreEditStages.length != 0 && (
                    <Form.Group>
                        <StageList
                            stages={pipelinePreEditStages}
                            editAction={handleEditStage}
                            deleteAction={handleDeleteStage}
                            reorderAction={handleReorderStage}
                        />
                    </Form.Group>
                )}
                {showAddStage && (
                    <Form.Group style={{ position: 'relative' }}>
                        <Button className="pull-right" onClick={onSubmitAddStage}>
                            Save
                        </Button>
                        <Form.Control
                            name="stage_name"
                            ref={newPipelineStageRef}
                            isInvalid={!editStageNameValid}
                            value={editStageName || ''}
                            style={{ width: '84%' }}
                            onChange={(event) => {
                                setEditStageName(event.target.value);
                                setEditStageNameValid(true);
                            }}
                        />
                        <FontAwesomeIcon
                            icon={faTimes}
                            className="close"
                            style={{ position: 'absolute', right: '18%', bottom: '0', top: '0', margin: 'auto 0' }}
                            onClick={cancelAddEditStage}
                        />
                    </Form.Group>
                )}
                {!(isAdd || isEdit) && (
                    <Form.Group>
                        <StageList
                            stages={pipelineStages}
                            editAction={handleEditStage}
                            deleteAction={handleDeleteStage}
                            reorderAction={handleReorderStage}
                        />
                    </Form.Group>
                )}
                {(isAdd || isEdit) && (
                    <Form.Group>
                        <StageList
                            stages={pipelinePostEditStages}
                            editAction={handleEditStage}
                            deleteAction={handleDeleteStage}
                            reorderAction={handleReorderStage}
                        />
                    </Form.Group>
                )}
            </Form>
        </Sisp>
    );
};

export default DealPipelinesEditSisp;
