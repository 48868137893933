'use strict';

import React from 'react';
import {connect} from "react-redux";
import {
    deleteSavedSearch,
    filterSavedSearches,
    loadMore
} from "../Actions";
import SavedSearchSelect from "./SavedSearchSelect";
import PropTypes from 'prop-types';

class ConnectedSavedSearchSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    filterSavedSearches(filter) {
        this.props.dispatch(filterSavedSearches(filter, this.props.entityName));
    }

    componentDidMount() {
        if (this.props.searchResults === undefined) this.props.dispatch(filterSavedSearches('', this.props.entityName));
    }

    loadMore() {
        this.props.dispatch(loadMore(this.props.searchResults.length, this.props.currentFilter, this.props.entityName));
    }

    toggle() {
        this.props.toggle(this.props.component);
    }

    deleteSavedSearch(id) {
        if (id === this.props.selectedValue) this.props.deleteCurrentlySelectedSearch();
        this.props.dispatch(deleteSavedSearch(id, this.props.entityName));
    }

    searchResultsForSelect() {
        if (!this.props.searchResults) return undefined;
        return this.props.searchResults.map(entity => entity.forSelect());
    }

    allKnownResultsForSelect() {
        if (!this.props.allKnownValues) return undefined;
        return this.props.allKnownValues.map(entity => entity.forSelect());
    }

    render() {
        return <SavedSearchSelect
            selectedValue={null}
            open={this.props.open}
            toggle={this.toggle.bind(this)}
            updateSelectedOption={this.props.updateSelectedOption}
            availableValues={this.searchResultsForSelect()}
            currentFilter={this.props.currentFilter}
            updateFilter={this.filterSavedSearches.bind(this)}
            allKnownValues={this.allKnownResultsForSelect()}
            totalAvailable={this.props.totalAvailableResults}
            loadMore={this.loadMore.bind(this)}
            moreLoading={this.props.moreLoading}
            deleteItem={this.deleteSavedSearch.bind(this)}
            deletingItem={this.props.deletingItem}
        />;
    }
}

ConnectedSavedSearchSelect.propTypes = {
    selectedValue: PropTypes.number,
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    updateSelectedOption: PropTypes.func.isRequired,
    component: PropTypes.string,
    entityName: PropTypes.string.isRequired,
    deleteCurrentlySelectedSearch: PropTypes.func
};

function select(state) {
    return {
        currentFilter: state.savedSearches.currentFilter,
        searchResults: state.savedSearches.searchResults,
        allKnownResults: state.savedSearches.allKnownResults,
        totalAvailableResults: state.savedSearches.totalAvailableResults,
        moreLoading: state.savedSearches.moreLoading,
        deletingItem: state.savedSearches.deletingItem
    }
}

export default connect(select)(ConnectedSavedSearchSelect);