export interface Stat {
    key: string;
    title?: string;
    format?: StatFormat;
    rateKey?: string;
    help?: {
        title: string;
        text: string;
    };
}

export enum StatFormat {
    ROUND,
    PERCENT,
}

export function formatStat(value: number, format?: StatFormat) {
    switch (format) {
        case StatFormat.PERCENT:
            return `${value.toFixed(1)}%`;
        case StatFormat.ROUND:
        default:
            return Math.round(value).toLocaleString();
    }
}
