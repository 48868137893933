import {
    DateFormatter,
    EventBusInstance,
    ExtendedColumn,
    filter as filterTypes,
    HoveroverButton,
    LogLevel,
    Modal,
    ModalType,
    OptionTypeBaseUserFormatter,
    SearchQuery,
    SearchQueryBuilder,
    showBanner,
    SortOrder,
    StyledPill,
    UserFormatter,
} from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { RepositoryFactoryContext } from '../..';
import { UserTypeRequest } from '../../Api/UserTypeRequest';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import { ClientGroupType, HTMLColors, UniqueKeyType } from '../../Models/Enums';
import UserType from '../../Models/UserType';
import CampusDataGrid, { ActionBarMeta, AddSispMeta, DataGridMeta, PromptMeta } from '../CampusDataGrid';

import { UserPermissionsContext } from '@client/Context/UserPermissions';
import { Button } from 'react-bootstrap';
import { ClientGroupsRequest } from '../../Api/ClientGroupsRequest';
import { LimitsRequest } from '../../Api/LimitsRequest';
import { TemplatesRequest } from '../../Api/TemplatesRequest';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import ClientGroup from '../../Models/ClientGroup';
import { NearLimitMethod } from '../../Models/Enums';
import Limit from '../../Models/Limit';
import Template from '../../Models/Template';
import ClientGroupsAddSisp from '../ClientGroups/ClientGroupsAddSisp';
import TemplatesAddEditModal from './TemplatesAddEditModal';
import './TemplatesTable.scss';

declare function dictionaryValue(key: string): string;

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

enum TemplatesColumnKey {
    ID,
    NAME,
    FOLDER,
    OWNED_BY,
    CREATED_DATE,
    MODIFIED_DATE,
    VERSION,
}

const TemplatesTable: FunctionComponent<Props> = (props: Props) => {
    const templatesRepository = useContext(RepositoryFactoryContext).getApiRepository(new TemplatesRequest());
    const usersRepository = useContext(RepositoryFactoryContext).getApiRepository(new UserTypeRequest());
    const clientGroupsRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new ClientGroupsRequest(ClientGroupType.EMAIL_TEMPLATES),
    );

    // Permissions
    const permissions = useContext(UserPermissionsContext);
    const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
    const supportEmail = dictionaryValue('company.email_support');
    const mailToSupport = `mailto:${supportEmail}`;

    // Limits
    const limitsRepository = useContext(RepositoryFactoryContext).getApiRepository(new LimitsRequest());
    const [hasReachedLimit, setHasReachedLimit] = useState<boolean>(false);
    const [updateLimits, setUpdateLimits] = useState<boolean>(true);
    const collectLimits = () => {
        // Collect Limits
        limitsRepository
            .search(new SearchQueryBuilder().build())
            .then((results: any) => {
                const limitBlock = results.results.find((limit: Limit) => {
                    return limit.key == 'limit_templates';
                });
                if (limitBlock) {
                    setHasReachedLimit(limitBlock.limit <= limitBlock.used);
                }
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get Limits - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                    dismissable: false,
                });
                return false;
            });
    };

    // Reload state to pass onto the DG
    const [reload, setReload] = useState(false);

    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [editTemplate, setEditTemplate] = useState<Template | null>(null);

    // Folder SISP
    const [showFolderSisp, setShowFolderSisp] = useState<boolean>(false);

    // State: Columns
    const Columns: Record<TemplatesColumnKey, ExtendedColumn> = {
        [TemplatesColumnKey.ID]: {
            key: 'id',
            name: 'ID',
        },
        [TemplatesColumnKey.NAME]: {
            key: 'name',
            name: 'Name',
            sortable: true,
            renderCell: (fprops) => {
                const handleEditButtonClick = () => {
                    const template_row = fprops.row as Template;
                    setEditTemplate(template_row);
                    setShowEditModal(true);
                };

                const template_row = fprops.row as Template;
                const name = template_row.name;
                return (
                    <>
                        <span>{name}</span>
                        <HoveroverButton contents="Edit" showHoverover={true} handleClick={handleEditButtonClick} />
                    </>
                );
            },
            width: '3fr',
        },
        [TemplatesColumnKey.FOLDER]: {
            key: 'folder',
            name: 'Folder',
            sortable: permissions.clientGroupsEmailTemplates.isEnabled,
            renderCell: (fprops) => {
                const template_row = fprops.row as Template;
                const client_group = template_row.client_group;
                return client_group ? <span>{client_group.name}</span> : <></>;
            },
            width: '3fr',
        },
        [TemplatesColumnKey.OWNED_BY]: {
            key: 'owned_by',
            name: 'Owned By',
            sortable: true,
            renderCell: UserFormatter,
            width: '1fr',
        },
        [TemplatesColumnKey.CREATED_DATE]: {
            key: 'created_at',
            name: 'Created Date',
            sortable: true,
            renderCell: DateFormatter,
            width: '1fr',
        },
        [TemplatesColumnKey.MODIFIED_DATE]: {
            key: 'modified_at',
            name: 'Modified Date',
            sortable: true,
            renderCell: DateFormatter,
            width: '1fr',
        },
        [TemplatesColumnKey.VERSION]: {
            key: 'version',
            name: 'Version',
            sortable: true,
            renderCell: (fprops) => {
                const template_row = fprops.row as Template;
                const version = template_row.version;
                const versionString = `V${version}`;
                return (
                    <StyledPill
                        cellContent={versionString}
                        style={{ backgroundColor: version == 1 ? HTMLColors.WARNING : HTMLColors.SUCCESS }}
                    />
                );
            },
            width: '2fr',
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[TemplatesColumnKey.NAME],
        Columns[TemplatesColumnKey.FOLDER],
        Columns[TemplatesColumnKey.OWNED_BY],
        Columns[TemplatesColumnKey.CREATED_DATE],
        Columns[TemplatesColumnKey.MODIFIED_DATE],
        Columns[TemplatesColumnKey.VERSION],
    ];

    useEffect(() => {
        if (updateLimits) {
            collectLimits();
            setUpdateLimits(false);
        }
    }, [updateLimits]);

    useEffect(() => {
        // reset reload if true
        if (reload) setReload(false);
    }, [reload]);

    // Filters
    const ownerFilter: filterTypes.BasicFilter = {
        title: 'Owner',
        name: 'ownedBy',
        loadOptions: () => {
            // Fetch all users for client account
            const query = new SearchQuery(1, 100, 'id', SortOrder.ASC, '');
            return usersRepository
                .search(query)
                .then((results) => {
                    return {
                        options: _.map(results.results as UserType[], (user: UserType) => {
                            return OptionTypeBaseUserFormatter(user);
                        }),
                    };
                })
                .catch((err: any) => {
                    showBanner({
                        message: 'Failed to get users - ' + (err?.message ?? err),
                    });
                });
        },
    };
    const folderFilter: filterTypes.BasicFilter = {
        title: 'Folder',
        name: 'folder',
        loadOptions: () => {
            // Fetch all users for client account
            const query = new SearchQuery(1, 1000, 'id', SortOrder.ASC, '');
            return clientGroupsRepository
                .search(query)
                .then((results) => {
                    return {
                        options: _.map(results.results as ClientGroup[], (group: ClientGroup) => {
                            return {
                                value: group.id!,
                                label: group.name,
                            };
                        }),
                    };
                })
                .catch((err: any) => {
                    showBanner({
                        message: 'Failed to get users - ' + (err?.message ?? err),
                    });
                });
        },
    };

    const filters: filterTypes.BasicFilter[] = permissions.clientGroupsEmailTemplates.isEnabled
        ? [ownerFilter, folderFilter]
        : [ownerFilter];

    // Views
    const actionBarMeta: ActionBarMeta = {
        searchPlaceHolder: props.searchFilterPlaceholder,
        includeCounts: true,
        filterBarMeta: {
            filters: filters,
        },
        addButtonOverride: (
            <>
                <Button
                    disabled={hasReachedLimit}
                    onClick={() => {
                        EventBusInstance.publish({
                            topic: 'show-hoverover-component',
                            message: null,
                            target: UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP),
                        });
                    }}
                >
                    Create Template
                    {hasReachedLimit && (
                        <>
                            {' '}
                            <span>
                                <i className={'fal fa-lock'} style={{ marginLeft: '2px' }} />
                            </span>
                        </>
                    )}
                </Button>
                <Button
                    onClick={() => {
                        if (permissions.clientGroupsEmailTemplates.isEnabled) {
                            setShowFolderSisp(true);
                        } else {
                            setShowUpgradeModal(true);
                        }
                    }}
                >
                    Create Folder
                    {!permissions.clientGroupsEmailTemplates.isEnabled && (
                        <>
                            {' '}
                            <span>
                                <i className={'fal fa-lock'} style={{ marginLeft: '2px' }} />
                            </span>
                        </>
                    )}
                </Button>
            </>
        ),
    };

    // Meta
    const addSispMeta: AddSispMeta = {
        key: UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP),
        sisp: TemplatesAddEditModal,
        additionalProps: { setUpdateLimits: setUpdateLimits },
    };

    const dataGridMeta: DataGridMeta = {
        uniqueKey: props.dataGridUniqueKey,
        entitySingular: props.dataGridEntitySingular,
        entityPlural: props.dataGridEntityPlural,
        columnOptions: { ...Columns },
        defaultColumns: DefaultColumns,
        frozenColumns: [],
        draggableColumns: true,
        defaultSortColumn: 'id',
        forceReload: reload,
    };

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_emails.png',
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.ADD_DEAL),
    };

    return (
        <>
            {permissions.clientGroupsEmailTemplates.isEnabled && (
                <ClientGroupsAddSisp
                    shown={showFolderSisp}
                    onClose={() => setShowFolderSisp(false)}
                    onSuccess={async (e) => {
                        return true;
                    }}
                    clientGroupType={ClientGroupType.EMAIL_TEMPLATES}
                    dataGridUniqueKey={UniqueKeyBuilder.make(
                        `${props.dataGridUniqueKey}_folder`,
                        UniqueKeyType.ADD_SISP,
                    )}
                    entitySingular={'Folder'}
                    formId={'form_id'}
                />
            )}
            <Modal
                isOpen={showUpgradeModal}
                type={ModalType.INFO}
                title="You need to upgrade for this feature"
                close={() => setShowUpgradeModal(false)}
            >
                <p>
                    You can't add folders to your templates because your current subscription doesn't give you access.
                </p>
                <p>But, don't worry, it's super-easy to upgrade, simply drop us an email!</p>
                <br />
                <p>
                    <a href={mailToSupport} className="btn btn-success">
                        Upgrade Here
                    </a>
                </p>
            </Modal>
            <CampusDataGrid
                repository={templatesRepository}
                actionBarMeta={actionBarMeta}
                dataGridMeta={dataGridMeta}
                promptMeta={promptMeta}
                addSispMeta={addSispMeta}
                editSispMeta={{
                    sisp: TemplatesAddEditModal,
                    additionalProps: {
                        onSuccess: async () => {
                            setReload(true);
                            return true;
                        },
                        onClose: () => {
                            setShowEditModal(false);
                        },
                        shown: showEditModal,
                        editTemplate: editTemplate,
                    },
                }}
                limitsModalMeta={{
                    limits: [
                        {
                            limitApiKey: 'limit_templates',
                            nearLimitMethod: NearLimitMethod.PERCENTAGE,
                            percentLimit: 80,
                        },
                    ],
                }}
            />
        </>
    );
};

export default TemplatesTable;
