import { AppEvent, ClickableLink, EventBusInstance, LogLevel, showBanner, Sisp } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import urlJoin from 'url-join';
import { FormSubmissionsRequest } from '../../Api/FormSubmissionsRequest';
import { RepositoryFactoryContext } from '../../index';
import FormSubmissionsType from '../../Models/FormSubmissionsType';
import './FormSubmissionsPreviewSisp.scss';

interface Props {
    uniqueKey: string;
}

interface FormField {
    id: number;
    label: string;
    value: string;
}

interface FormSubmission {
    name: string;
    date: string;
    form_id: number;
    fields: FormField[];
    contact_id: number;
}

const FormSubmissionsPreviewSisp: FunctionComponent<Props> = (props: Props) => {
    const formSubmissionsRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new FormSubmissionsRequest(),
    );

    // State: General
    const [shown, setShown] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [formSubmission, setFormSubmission] = useState<FormSubmission | null>(null);

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<FormSubmissionsType>) => {
            if (event.target !== props.uniqueKey) return;
            setIsLoading(true);
            setShown(true);
            getFormSubmissionDetails(event.message.id);
        });
    }, [shown]);

    const getFormSubmissionDetails = async (id: number): Promise<boolean> => {
        return formSubmissionsRepository
            .post_action('get_submission_details', id)
            .then((results: any) => {
                setFormSubmission(results.data);
                setIsLoading(false);
                return true;
            })
            .catch((err) => {
                setShown(false);
                showBanner({
                    message: 'Failed to find form submissions details - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    return (
        <Sisp
            className="form-submissions-preview"
            isOpen={shown}
            onCancel={() => setShown(false)}
            showSubmitButton={false}
            cancelButtonText="Close"
        >
            <h4>Form Submission</h4>
            {isLoading ? (
                <div style={{ position: 'relative', alignItems: 'center' }}>
                    <Card
                        className="loading-spinner-container filter-loading-spinner"
                        style={{ background: '#f9f9f9' }}
                    >
                        <Spinner animation="border" role="status" />
                    </Card>
                </div>
            ) : (
                <>
                    {formSubmission && (
                        <div className="form-completion-item">
                            <p>
                                <ClickableLink
                                    placeholder={formSubmission.name}
                                    url={urlJoin('/forms/home/edit', formSubmission.form_id.toString())}
                                />
                            </p>
                            <p>{formSubmission.date}</p>
                        </div>
                    )}
                    {formSubmission &&
                        formSubmission.fields.map((field: FormField) => (
                            <div className="form-completion-item" key={['field', field.id].join('-')}>
                                <p className="side-panel-small" key={['label', field.id].join('-')}>
                                    {field.label}
                                </p>
                                <p className="side-panel" key={['value', field.id].join('-')}>
                                    {field.value}
                                </p>
                            </div>
                        ))}
                </>
            )}
        </Sisp>
    );
};

export default FormSubmissionsPreviewSisp;
