import React, { FunctionComponent } from 'react';
import './TeacherAvatar.scss';

interface Props {
    firstName?: string;
    lastName?: string;
}

const TeacherAvatar: FunctionComponent<Props> = (props: Props) => {
    return (
        <div className="teacher-avatar">
            <span>
                {props.firstName && Array.from(props.firstName).length > 0
                    ? Array.from(props.firstName)[0].toUpperCase()
                    : ''}
                {props.lastName && Array.from(props.lastName).length > 0
                    ? Array.from(props.lastName)[0].toUpperCase()
                    : ''}
            </span>
        </div>
    );
};

export default TeacherAvatar;
