import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import BulkEditSingleFieldModal, { Field } from '../../../../CommonComponents/BulkEdit/BulkEditSingleFieldModal';
import { toFields, useCustomProperties } from '../../../../CommonComponents/BulkEdit/CustomPropertyHelpers';
import BulkUpdateRepository, { UpdateType } from '../../Api/BulkEditRepository';
import { TasksPriority, TasksStatus } from '../../Models/Enums';
import TasksType from '../../Models/TasksType';

interface Props {
    rows: TasksType[];
    close: () => void;
}

const TasksBulkEdit = (props: Props) => {
    const type: UpdateType = 'tasks';

    const bulkUpdateRepository = new BulkUpdateRepository();

    const [ids, setIds] = useState<number[]>([]);
    const [fields, setFields] = useState<Field[]>([]);
    const [customPropertyFields, setCustomPropertyFields] = useState<Field[]>([]);

    const customProperties = useCustomProperties(type);

    const getFields = (): Field[] => [
        {
            key: 'assigned_to',
            name: 'Assigned To',
            type: 'owner',
            save: async (data: Record<string, any>) => {
                await bulkUpdateRepository.update({
                    type,
                    field: 'assigned_to',
                    value: data['assigned_to'].id,
                    ids,
                });
                return;
            },
        },
        {
            key: 'status',
            name: 'Status',
            type: 'enum',
            loadOptions: async (_filter: string, _loadedOptions, { page }) => {
                const statuses = _.map(Object.values(TasksStatus), (value: string) => {
                    return {
                        value: value,
                        label: value,
                    };
                });
                return {
                    options: statuses,
                    hasMore: false,
                    additional: {
                        page: page + 1,
                    },
                };
            },
            save: async (data: Record<string, any>) => {
                await bulkUpdateRepository.update({
                    type,
                    field: 'status',
                    value: data['status'].value,
                    ids,
                });
                return;
            },
        },
        {
            key: 'priority',
            name: 'Priority',
            type: 'enum',
            loadOptions: async (_filter: string, _loadedOptions, { page }) => {
                const priorities = _.map(Object.values(TasksPriority), (value: string) => {
                    return {
                        value: value,
                        label: value,
                    };
                });
                return {
                    options: priorities,
                    hasMore: false,
                    additional: {
                        page: page + 1,
                    },
                };
            },
            save: async (data: Record<string, any>) => {
                await bulkUpdateRepository.update({
                    type,
                    field: 'priority',
                    value: data['priority'].value,
                    ids,
                });
                return;
            },
        },
    ];

    useEffect(() => {
        setIds(_.map(props.rows, (row) => row.id!));
        setFields(getFields());
    }, []);

    useEffect(() => {
        if (customProperties && ids.length) {
            setCustomPropertyFields(toFields(type, customProperties, bulkUpdateRepository, ids));
        }
    }, [ids, customProperties]);

    useEffect(() => {
        if (customPropertyFields.length) {
            setFields([...getFields(), ...customPropertyFields]);
        }
    }, [customPropertyFields]);

    return <BulkEditSingleFieldModal fields={fields} close={props.close} />;
};

export default TasksBulkEdit;
