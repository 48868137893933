'use strict';

import CampusEntity from '../CampusEntity/CampusEntity';

export default class CustomProperty extends CampusEntity {
    id = undefined;
    name = '';
    value = undefined;
    dataType = undefined;
    showInView = undefined;
    archived = undefined;

    static blank() {
        return new CustomProperty();
    }

    static fromApi(customProperty) {
        const entity = CustomProperty.blank();

        // Construct from expected API output object
        entity.id = customProperty.id;
        entity.name = customProperty.name;
        entity.value = customProperty.value;
        entity.dataType = customProperty.data_type;
        entity.showInView = customProperty.show_in_view;
        entity.archived = customProperty.archived;

        return entity;
    }

    static select(entityArray, id) {
        return entityArray.find((entity) => entity.matches(id));
    }

    matches(id) {
        return String(this.id) === String(id);
    }

    clone() {
        return Object.assign(CustomProperty.blank(), this);
    }
}
