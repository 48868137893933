import {
    AppEvent,
    EventBusInstance,
    isValidString,
    LogLevel,
    lookupValidator,
    showBanner,
    Sisp,
} from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { UserTypeRequest } from '../../Api/UserTypeRequest';
import { PermissionsContext, RepositoryFactoryContext } from '../../index';
import UserType from '../../Models/UserType';
import UserTypeEditState from '../../Models/UserTypeEditState';

interface Props {
    uniqueKey: string;
    onSuccess: (event: any) => Promise<boolean>;
}

const UsersEditSisp: FunctionComponent<Props> = (props: Props) => {
    const [shown, setShown] = useState<boolean>(false);

    const usersRepository = useContext(RepositoryFactoryContext).getApiRepository(new UserTypeRequest());
    const permissions = useContext(PermissionsContext);

    const focusRef = useRef<HTMLInputElement>(null);

    const [rowId, setRowId] = useState<number>(0);

    const [initialEmail, setInitialEmail] = useState<string>('');
    const [editEmail, setEditEmail] = useState<string>('');
    const [editFirstName, setEditFirstName] = useState<string>('');
    const [editLastName, setEditLastName] = useState<string>('');
    const [editRequires2fa, setRequires2fa] = useState<boolean>(false);
    const [editBillingUser, setBillingUser] = useState<boolean>(false);

    const editUserTypeDefault = {
        admin: false,
        manager: false,
        user: false,
    };
    const [editUserType, setEditUserType] = useState(editUserTypeDefault);

    const validationStateDefault = {
        email: true,
        firstName: true,
        lastName: true,
        userType: true,
    };
    const [validationState, setValidationState] = useState(validationStateDefault);

    const [validationFeedback, setValidationFeedback] = useState<Record<string, string | JSX.Element>>({
        email: '',
    });

    useEffect(() => {
        if (shown) {
            if (focusRef.current) {
                focusRef.current.focus();
                focusRef.current.selectionStart = focusRef.current.value.length;
                focusRef.current.selectionEnd = focusRef.current.value.length;
            }
            setValidationState(validationStateDefault);
            setValidationFeedback({ email: '' });
        }
    }, [shown]);

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<UserTypeEditState>) => {
            if (event.target !== props.uniqueKey) return;
            setRowId(event.message.id);
            setInitialEmail(event.message.email);
            setEditEmail(event.message.email);
            setEditFirstName(event.message.first_name);
            setEditLastName(event.message.last_name);
            setRequires2fa(event.message.requires_2fa);
            setBillingUser(event.message.billing_user);
            setEditUserType({ ...editUserTypeDefault, [event.message.level.toLowerCase()]: true });
            setShown(true);
        });
    }, [shown]);

    const updateUserType = (userType: string) => {
        setEditUserType({ ...editUserTypeDefault, [userType]: true });
    };

    const checkEmailIsUnique = async (email: string): Promise<boolean> => {
        return usersRepository
            .post_action('check_email', undefined, { email: email })
            .then((results: any) => {
                setValidationFeedback((prevState) => {
                    return {
                        ...prevState,
                        email: results.data.result ? '' : 'Sorry, this email address is already in use.',
                    };
                });
                return results.data.result;
            })
            .catch((err) => {
                setValidationFeedback((prevState) => {
                    return { ...prevState, email: 'Email address check failed' };
                });
                return false;
            });
    };

    const validate = async (): Promise<boolean> => {
        const firstNameValid = !!editFirstName && isValidString(editFirstName);
        const lastNameValid = !!editLastName && isValidString(editLastName);
        const userTypeValid = _.some(editUserType);

        let emailValid = false;
        if (editEmail !== '') {
            const emailValidator = lookupValidator('email', 'text');
            emailValid = emailValidator.validate(editEmail);
            setValidationFeedback((prevState) => {
                return { ...prevState, email: emailValid ? '' : emailValidator.feedback('Email') };
            });
            if (emailValid) {
                emailValid = initialEmail === editEmail ? true : await checkEmailIsUnique(editEmail);
            }
        } else {
            setValidationFeedback((prevState) => {
                return { ...prevState, email: 'This field is required.' };
            });
        }

        const editValidationState = {
            email: emailValid,
            firstName: firstNameValid,
            lastName: lastNameValid,
            userType: userTypeValid,
        };
        setValidationState(editValidationState);

        return _.every(editValidationState);
    };

    const handleEditRow = async (): Promise<boolean> => {
        let editUserTypeStr = '';
        for (const [key, value] of Object.entries(editUserType)) {
            if (value) {
                editUserTypeStr = key;
                break;
            }
        }

        const editUser: UserType = {
            id: rowId,
            email: editEmail,
            first_name: editFirstName,
            last_name: editLastName,
            requires_2fa: editRequires2fa,
            billing_user: editBillingUser,
            level: editUserTypeStr,
        };

        return usersRepository
            .update(editUser)
            .then((results: any) => {
                props.onSuccess(results.data);
                showBanner({
                    message: 'User details have been updated',
                    level: LogLevel.SUCCESS,
                });
                return Promise.resolve(true);
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to edit User details - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return Promise.resolve(false);
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        if ((await validate()) && (await handleEditRow())) setShown(false);
    };

    return (
        <Sisp
            className="users-sisp-edit"
            isOpen={shown}
            onSubmit={handleEditRow}
            onCancel={() => {
                setShown(false);
            }}
            validate={validate}
        >
            <h4>Edit a User</h4>
            <Form onSubmit={onSubmitForm}>
                <Form.Group>
                    <Form.Label>
                        Email Address <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        autoComplete="off"
                        ref={focusRef}
                        type="text"
                        isInvalid={!validationState.email}
                        value={editEmail || ''}
                        onChange={(event) => {
                            setEditEmail(event.target.value);
                            setValidationState((prevState) => {
                                return { ...prevState, email: true };
                            });
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {!validationState.email && validationFeedback.email}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        First Name <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        autoComplete="off"
                        type="text"
                        isInvalid={!validationState.firstName}
                        value={editFirstName || ''}
                        onChange={(event) => {
                            setEditFirstName(event.target.value);
                            setValidationState((prevState) => {
                                return { ...prevState, firstName: true };
                            });
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {!validationState.firstName && 'This field is required.'}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        Last Name <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        autoComplete="off"
                        type="text"
                        isInvalid={!validationState.lastName}
                        value={editLastName || ''}
                        onChange={(event) => {
                            setEditLastName(event.target.value);
                            setValidationState((prevState) => {
                                return { ...prevState, lastName: true };
                            });
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {!validationState.lastName && 'This field is required.'}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Billing</Form.Label>
                    <Form.Check
                        custom={true}
                        id={'user-billing-user-edit'}
                        type="switch"
                        checked={editBillingUser}
                        label="Billing User"
                        onChange={(event) => {
                            setBillingUser(event.target.checked);
                        }}
                    />
                </Form.Group>
                {permissions['two_factor_visible'] && (
                    <Form.Group>
                        <Form.Label>Two Factor Authentication</Form.Label>
                        <Form.Check
                            custom={true}
                            id={'user-require-2fa-edit'}
                            disabled={!permissions['two_factor_enabled']}
                            type="switch"
                            checked={editRequires2fa}
                            label="Require Two Factor Authentication"
                            onChange={(event) => {
                                setRequires2fa(event.target.checked);
                            }}
                        />
                    </Form.Group>
                )}
                <Form.Group>
                    <Form.Label>
                        User Type <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Check
                        id="user-type-admin-edit"
                        name="user-type"
                        type="radio"
                        label="Admin (like you)"
                        custom
                        checked={editUserType.admin}
                        onChange={() => updateUserType('admin')}
                    />
                    <Form.Check
                        id="user-type-manager-edit"
                        name="user-type"
                        type="radio"
                        label="Manager (has full access except to the billing areas)"
                        custom
                        checked={editUserType.manager}
                        onChange={() => updateUserType('manager')}
                    />
                    <Form.Check
                        id="user-type-user-edit"
                        name="user-type"
                        type="radio"
                        label="User (cannot change any fundamental settings)"
                        custom
                        checked={editUserType.user}
                        onChange={() => updateUserType('user')}
                    />
                    {!validationState.userType && (
                        <Form.Control.Feedback type="invalid">Please select a User Type.</Form.Control.Feedback>
                    )}
                </Form.Group>
            </Form>
        </Sisp>
    );
};

export default UsersEditSisp;
