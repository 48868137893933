import QuoteCloseReason from '../Models/QuoteCloseReason';
import QuoteType from '../Models/QuoteType';
import DataGridRequest from './DataGridRequest';
export class QuotesRequest extends DataGridRequest<QuoteType> {
    Url = 'quotes/api';
}

export class QuoteClosedReasonOptionsRequest extends DataGridRequest<QuoteCloseReason> {
    Url = 'quotes/closed_reason_options';
}
