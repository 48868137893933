'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import CheckBox from "../../CheckBox/CheckBox";
import Spinner from "../../Spinner/Spinner";
import Option from "./Option.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/pro-regular-svg-icons";

function sortAvailableOptionsPutCheckedFirst(availableOptions, selectedOptions) {
    const checkedOptions = availableOptions.filter(o => selectedOptions.indexOf(o.id ? o.id : o) !== -1);
    const notCheckedOptions = availableOptions.filter(o => selectedOptions.indexOf(o.id ? o.id : o) === -1);
    return checkedOptions.concat(notCheckedOptions);
}

class DropDownList extends React.Component {

    checkBoxValueChanged(key, checked) {
        let newSelection = this.props.selectedOptions.slice();
        if (checked) {
            newSelection.push(key);
        } else {
            newSelection = newSelection.filter((o) => o !== key);
        }

        this.props.updateSelectedOptions(newSelection);
    }

    selectOption(key) {
        this.props.updateSelectedOptions(key);
    }

    deleteItem(e, id) {
        e.stopPropagation();
        this.props.deleteItem(id);
    }

    deleteButton(value) {
        const icon = (value === this.props.deletingItem) ? <Spinner/> : <FontAwesomeIcon icon={faTimes}/>;
        const onClick = value === this.props.deletingItem ? null : (e) => this.deleteItem(e, value);
        return <span className="react-select--delete-option" onClick={onClick}>
            {icon}
            </span>;
    }

    addDeleteToLabel(label, value) {
        const className = value === this.props.deletingItem ? 'react-select--strike-through' : '';
        return <span className={className}>
            {this.deleteButton(value)}
            {label}
        </span>
    }

    visibleFields() {
        if (this.props.availableOptions === undefined) return <li><a><Spinner/></a></li>;
        if (this.props.availableOptions.length === 0) return <li><a>{this.props.noResultsLabel}</a></li>;

        // we want to sort the available options so the checked ones are first
        const availableOptions = sortAvailableOptionsPutCheckedFirst(this.props.availableOptions, this.props.selectedOptions);

        return availableOptions.map(
            (option) => {
                const value = option.id !== undefined ? option.id : option;
                let label = option.id !== undefined ? option.label : option;
                if (option.labelElement) label = option.labelElement;

                if (this.props.deleteItem) {
                    label = this.addDeleteToLabel(label, value);
                }

                const final = this.props.multi ? <CheckBox
                    label={label}
                    value={value}
                    checked={this.props.selectedOptions.indexOf(value) !== -1}
                    onChange={this.checkBoxValueChanged.bind(this)}
                /> : <Option
                    label={label}
                    value={value}
                    onChange={this.selectOption.bind(this)}
                />;

                return <li key={value}>{final}</li>;
            }
        );
    }

    render() {
        return <ul>
            {this.visibleFields()}
            {this.props.children}
        </ul>;
    }

}

DropDownList.propTypes = {
    updateSelectedOptions: PropTypes.func.isRequired,
    selectedOptions: PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
    ])),
    availableOptions: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]).isRequired,
            label: PropTypes.string.isRequired,
            labelElement: PropTypes.element,
        })
    ])),
    multi: PropTypes.bool,
    deleteItem: PropTypes.func,
    deletingItem: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    noResultsLabel: PropTypes.string
};

DropDownList.defaultProps = {
    noResultsLabel: 'No Results'
};

export default DropDownList;