import * as ActionTypes from "./ActionTypes";
import * as EntityState from "../../../ValueObjects/EntityState/EntityState";
import DealLostReason from "./DealLostReason";

const initialState = {
    searchResults: undefined,
    allKnownResults: [ DealLostReason.blank() ],

    selectedId: undefined,
    idToLoad: undefined,
    error: undefined
};

function select(allKnownResults, id) {
    return Object.assign(DealLostReason.blank(), DealLostReason.select(allKnownResults, id));
}

function mergeResults(existingResults, newResults) {
    if (existingResults === undefined) return newResults;
    return existingResults.filter(existingResult => {
        return newResults.map(newResult => newResult.id).indexOf(existingResult.id) === -1;
    }).map(existingResult => existingResult.clone()).concat(newResults);
}

function replaceOrAddResult(allKnownResults, incomingResult) {
    if (allKnownResults === undefined) allKnownResults = [];
    const matches = result => result.id === incomingResult.id;
    const newKnownResults = allKnownResults.map(result => {
        const updatedResult = result.clone();
        if (incomingResult.isDefault) updatedResult.isDefault = false;
        return matches(result) ? incomingResult : updatedResult;
    });
    if (allKnownResults.find(matches) === undefined) newKnownResults.push(incomingResult);
    return newKnownResults;
}

function removeResult(allKnownResults, resultToRemove) {
    if (!resultToRemove) return allKnownResults;
    return allKnownResults.filter(result => result.id !== resultToRemove.id);
}

function adjustValues(dealLostReason) {
    return dealLostReason;
}

export default function (state, action) {
    if (state === undefined) {
        state = initialState;
    }

    const selectedEntity = select(state.allKnownResults, state.selectedId);

    switch (action.type) {
        case ActionTypes.DEALLOSTREASONS_LOADED: {
            try {
                const results = action.payload.dealLostReasons.map(dealLostReason => DealLostReason.fromApi(dealLostReason));
                return Object.assign({}, state, {
                    searchResults: results,
                    allKnownResults: mergeResults(state.allKnownResults, results)
                });
            } catch (e) {
                return Object.assign({}, state, {
                    error: 'Unable to initialise deal lost reason object: ' + e.message
                });
            }
        }
        case ActionTypes.DEALLOSTREASONS_LOADING_FAILED: {
            return Object.assign({}, state, {
                error: 'Unable to load deal lost reasons: ' + action.payload.error
            });
        }
        case ActionTypes.DEALLOSTREASON_NEW: {
            const blankEntity = DealLostReason.blank();

            blankEntity.transitionState(EntityState.READY);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, blankEntity),
                selectedId: blankEntity.id
            });
        }
        case ActionTypes.DEALLOSTREASON_UPDATE_FIELD: {
            const targetEntity = select(state.allKnownResults, action.payload.id);
            targetEntity[action.payload.fieldName] = action.payload.value;
            targetEntity.transitionState(EntityState.MODIFIED);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, adjustValues(targetEntity))
            });
        }
        case ActionTypes.DEALLOSTREASON_SAVING: {
            const savingEntity = action.payload.entity.clone();
            savingEntity.transitionState(EntityState.SAVING);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, savingEntity)
            });
        }
        case ActionTypes.DEALLOSTREASON_SAVED: {
            try {
                const savedEntity = DealLostReason.fromApi(action.payload.data);
                savedEntity.transitionState(EntityState.SAVED);

                return Object.assign({}, state, {
                    allKnownResults: replaceOrAddResult(state.allKnownResults, savedEntity),
                    searchResults: undefined,
                    selectedId: savedEntity.id
                });
            } catch (e) {
                const targetEntity = select(state.allKnownResults, action.payload.id);
                targetEntity.transitionState(EntityState.ERROR, 'Unable to initialise deal lost reason object: ' + e.message);

                return Object.assign({}, state, {
                    allKnownResults: replaceOrAddResult(state.allKnownResults, targetEntity)
                });
            }
        }
        case ActionTypes.DEALLOSTREASON_SAVING_FAILED: {
            const targetEntity = select(state.allKnownResults, action.payload.id);
            targetEntity.transitionState(EntityState.ERROR, action.payload.error);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, targetEntity)
            });
        }
        case ActionTypes.DEALLOSTREASON_SELECT: {
            const targetEntity = select(state.allKnownResults, action.payload.id);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, targetEntity),
                selectedId: action.payload.id,
                idToLoad: targetEntity.id ? undefined : action.payload.id,
                error: undefined
            });
        }
        case ActionTypes.DEALLOSTREASON_LOADING: {
            const targetEntity = select(state.allKnownResults, action.payload.id);
            targetEntity.transitionState(EntityState.LOADING);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, targetEntity),
                idToLoad: undefined
            });
        }
        case ActionTypes.DEALLOSTREASON_LOADED: {
            try {
                const loadedEntity = DealLostReason.fromApi(action.payload.data);
                loadedEntity.transitionState(EntityState.LOADED);

                return Object.assign({}, state, {
                    allKnownResults: replaceOrAddResult(state.allKnownResults, loadedEntity),
                    idToLoad: undefined,
                    error: undefined
                });
            } catch (e) {
                return Object.assign({}, state, {
                    idToLoad: undefined,
                    error: 'Unable to initialise deal lost reason object: ' + e.message
                });
            }
        }
        case ActionTypes.DEALLOSTREASON_LOADING_FAILED: {
            return Object.assign({}, state, {
                idToLoad: undefined,
                error: action.payload.error
            });
        }
        case ActionTypes.DEALLOSTREASON_INVALIDATED: {
            const targetEntity = select(state.allKnownResults, action.payload.id);
            targetEntity.transitionState(EntityState.INVALIDATED);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, targetEntity),
                idToLoad: targetEntity.id // Reload invalidated entity from server
            });
        }
        case ActionTypes.DEALLOSTREASON_DELETING: {
            const targetEntity = select(state.allKnownResults, action.payload.id);
            targetEntity.transitionState(EntityState.DELETING);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, targetEntity)
            });
        }
        case ActionTypes.DEALLOSTREASON_DELETED: {
            const targetEntity = select(state.allKnownResults, action.payload.id);

            return Object.assign({}, state, {
                allKnownResults: removeResult(state.allKnownResults, targetEntity),
                selectedId: undefined
            });
        }
        default: {
            return state;
        }
    }
}