import { SearchQuery, SearchResult } from '@sprint/sprint-react-components';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import HttpRequestService from '../../../CommonComponents/HttpRequestService/HttpRequestService';
import SimpleSearchResultCounts from '../Components/SimpleSearchResultCounts';

interface SimpleType {
    id?: number;
}

export default class TeacherDropDownRepository<T extends SimpleType> {
    private _cancelTokens: Record<string, CancelTokenSource> = {};

    private _http: HttpRequestService;
    private _baseUrl: string;

    constructor(
        private _authToken: string,
        path: string,
    ) {
        this._http = new HttpRequestService(this._authToken);
        this._baseUrl = path;
    }

    private isSuccessCode(statusCode: number) {
        return statusCode >= 200 && statusCode <= 299;
    }

    private isClientErrorCode(statusCode: number) {
        return statusCode >= 400 && statusCode <= 499;
    }

    public async search(query: SearchQuery): Promise<SearchResult<T, SimpleSearchResultCounts>> {
        const params = query.getExtendedParameters();
        if (!params?.hasOwnProperty('organisationId'))
            throw new Error('organisationId Parameter is required on extended parameters for this endpoint');

        const cancelTokenKey = this.search.name;
        if (this._cancelTokens[cancelTokenKey]) this._cancelTokens[cancelTokenKey].cancel();
        this._cancelTokens[cancelTokenKey] = axios.CancelToken.source();

        return this._http
            .get(this._baseUrl + `?${query.toQueryString()}&organisationId=${params.organisationId}`, cancelTokenKey)
            .then((res: AxiosResponse<{ items: T[]; meta: SimpleSearchResultCounts }> | null) => {
                if (res && this.isSuccessCode(res.status)) {
                    const { items: data, meta: counts } = res.data;
                    return new SearchResult<T, SimpleSearchResultCounts>(data, counts);
                }
                throw new Error(res?.statusText);
            })
            .catch((err) => {
                if (axios.isCancel(err))
                    return new SearchResult<T, SimpleSearchResultCounts>([], new SimpleSearchResultCounts(), true);
                throw err;
            });
    }
}
