import { AppEvent, EventBusInstance } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import FormsType from '../../Models/FormsType';

interface Props {
    uniqueKey: string;
    onSuccess: (event: any) => Promise<boolean>;
}

const FormsEditRedirect: FunctionComponent<Props> = (props: Props) => {
    const editUrlPrefix = '/forms/home/edit/';
    const [shown, setShown] = useState<boolean>(false);

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<FormsType>) => {
            if (event.target !== props.uniqueKey) return;
            const editUrl = editUrlPrefix + event.message.id;
            window.location.href = editUrl;
        });
    }, [shown]);

    return <></>;
};

export default FormsEditRedirect;
