'use strict';

export const UPDATE_SELECTED_COMPONENTS = 'dealAdvancedSearch/UPDATE_SELECTED_COMPONENTS';
export const UPDATE_SEARCH = 'dealAdvancedSearch/UPDATE_SEARCH';
export const REMOVE_COMPONENT = 'dealAdvancedSearch/REMOVE_COMPONENT';
export const LOAD_SEARCH = 'dealAdvancedSearch/LOAD_SEARCH';
export const OPEN_COMPONENT = 'dealAdvancedSearch/OPEN_COMPONENT';
export const CLEAR_SEARCH = 'dealAdvancedSearch/CLEAR_SEARCH';
export const DATA_TABLE_UPDATING = 'dealAdvancedSearch/DATA_TABLE_UPDATING';
export const DATA_TABLE_UPDATED = 'dealAdvancedSearch/DATA_TABLE_UPDATED';
export const LOADED_ENTITIES_UPDATING = 'dealAdvancedSearch/LOADED_ENTITIES_UPDATING';

export const DEAL_DATA_GRID_PIPELINE_FILTER = 'dealAdvancedSearch/DEAL_DATA_GRID_PIPELINE_FILTER'; // UPDATE SEARCH
