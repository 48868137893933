import { ClickableLink, ExtendedColumn, StyledPill } from '@sprint/sprint-react-components';
import CSS from 'csstype';
import React, { FunctionComponent, useContext } from 'react';
import { ImportRequest } from '../../Api/ImportRequest';
import { HTMLColors, ImportStatus } from '../../Models/Enums';
import ImportType from '../../Models/ImportType';
import { RepositoryFactoryContext } from '../../index';
import CampusDataGrid, { ActionBarMeta, DataGridMeta } from '../CampusDataGrid';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

enum ImportColumnKey {
    ID,
    NAME,
    DATE,
    TYPE,
    STATUS,
    PROCESSED,
    DUPLICATES,
    ERRORS,
}

const ImportTable: FunctionComponent<Props> = (props: Props) => {
    const importRepository = useContext(RepositoryFactoryContext).getApiRepository(new ImportRequest());

    const Columns: Record<ImportColumnKey, ExtendedColumn> = {
        [ImportColumnKey.ID]: {
            key: 'id',
            name: 'ID',
            sortable: false,
        },
        [ImportColumnKey.NAME]: {
            key: 'name',
            name: 'Name',
            sortable: false,
            width: '4fr',
            renderCell: (props) => {
                const name = (props.row as ImportType).name;
                return <ClickableLink placeholder={name.value} url={name.url} />;
            },
        },
        [ImportColumnKey.DATE]: {
            key: 'date',
            name: 'Date',
            sortable: false,
            width: '2fr',
        },
        [ImportColumnKey.TYPE]: {
            key: 'type',
            name: 'Type',
            sortable: false,
            width: '2fr',
        },
        [ImportColumnKey.STATUS]: {
            key: 'status',
            name: 'Status',
            sortable: false,
            width: '1fr',
            renderCell: (props) => {
                const getStatusColor = (status: string) => {
                    switch (status) {
                        case ImportStatus.COMPLETE:
                            return HTMLColors.SUCCESS;
                        case ImportStatus.CANCELLED:
                        case ImportStatus.CANCELLING:
                            return HTMLColors.DANGER;
                        default:
                            return HTMLColors.PRIMARY;
                    }
                };
                const status = (props.row as ImportType).status;
                const pillStyle: CSS.Properties = {
                    backgroundColor: getStatusColor(status),
                };
                return <StyledPill cellContent={status} style={pillStyle} />;
            },
        },
        [ImportColumnKey.PROCESSED]: {
            key: 'processed',
            name: 'Processed',
            sortable: false,
            width: '1fr',
        },
        [ImportColumnKey.DUPLICATES]: {
            key: 'duplicates',
            name: 'Duplicates',
            sortable: false,
            width: '1fr',
        },
        [ImportColumnKey.ERRORS]: {
            key: 'errors',
            name: 'Errors',
            sortable: false,
            width: '1fr',
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[ImportColumnKey.NAME],
        Columns[ImportColumnKey.DATE],
        Columns[ImportColumnKey.TYPE],
        Columns[ImportColumnKey.STATUS],
        Columns[ImportColumnKey.PROCESSED],
        Columns[ImportColumnKey.DUPLICATES],
        Columns[ImportColumnKey.ERRORS],
    ];

    const dataGridMeta: DataGridMeta = {
        uniqueKey: props.dataGridUniqueKey,
        entitySingular: props.dataGridEntitySingular,
        entityPlural: props.dataGridEntityPlural,
        columnOptions: Columns,
        defaultColumns: DefaultColumns,
        frozenColumns: [],
        hideSelect: true,
    };

    const actionBarMeta: ActionBarMeta = {
        searchPlaceHolder: props.searchFilterPlaceholder,
        includeCounts: true,
        hideAddButton: true,
    };

    return (
        <>
            <CampusDataGrid repository={importRepository} actionBarMeta={actionBarMeta} dataGridMeta={dataGridMeta} />
        </>
    );
};

export default ImportTable;
