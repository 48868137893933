'use strict';

import { EventBusInstance } from '@sprint/sprint-react-components';
import HttpRequestService from '../../Services/HttpRequestService/HttpRequestService';
import * as EntityState from '../../ValueObjects/EntityState/EntityState';
import Quote from '../Quote/Quote';
import Sale from '../Sale/Sale';
import Task from '../Task/Task';
import * as ActionTypes from './ActionTypes';
import Deal from './Deal';
import DealRepository from './DealRepository';

export function filterDeals(textFilter, advancedSearch) {
    return (dispatch) => {
        const token = new HttpRequestService().generateToken();
        dispatch(updateCurrentFilter(textFilter, advancedSearch, token));
        DealRepository.filter(escape(textFilter.replace(/[*]/g, '')), advancedSearch, token, 0)
            .then((response) => dispatch(response ? dealsLoaded(response) : loadingDealsCancelled()))
            .catch((e) => dispatch(loadingDealsFailed(e.message ? e.message : e)));
    };
}

export function filterDealsDataGrid(simpleFilterRequest, repository) {
    return (dispatch) => {
        repository
            .filter(simpleFilterRequest)
            .then((response) =>
                dispatch(!response.wasCancelled ? dealsLoadedDataGrid(response) : loadingDealsCancelled()),
            )
            .catch((e) => dispatch(loadingDealsFailed(e.message ? e.message : e)));
    };
}

export function loadMoreDeals(offset, textFilter, advancedSearch) {
    return (dispatch) => {
        dispatch(dealsMoreLoading());
        const token = new HttpRequestService().generateToken();
        DealRepository.filter(escape(textFilter.replace(/[*]/g, '')), advancedSearch, token, offset)
            .then((response) => dispatch(response ? dealsMoreLoaded(response) : loadingDealsCancelled()))
            .catch((e) => dispatch(loadingDealsFailed(e.message ? e.message : e)));
    };
}

export function loadIdList(idList) {
    return (dispatch) => {
        dispatch(dealsMoreLoading());
        DealRepository.loadIdList(idList)
            .then((response) => dispatch(dealsSpecificIdsLoaded(response)))
            .catch((e) => dispatch(loadingDealsFailed(e.message ? e.message : e)));
    };
}

export function updateDealPipelines(entities, pipelineId, stageName, stageId) {
    return (dispatch) => {
        dispatch(dealsUpdatingPipeline(entities));
        DealRepository.updatePipeline(
            entities.map((entity) => entity.id),
            pipelineId,
            stageName,
            stageId,
        )
            .then((response) => dispatch(dealsUpdatedPipeline(response)))
            .catch((e) => dispatch(dealsUpdatingPipelineFailed(e.message ? e.message : e)));
    };
}

export function deleteDeals(entities) {
    const ids = entities.map((entity) => entity.id);
    return (dispatch) => {
        dispatch(dealsDeleting(entities));
        DealRepository.deleteMultiple(ids)
            .then(() => {
                EventBusInstance.publish({
                    topic: 'update-datagrid-rows',
                    message: true,
                    target: 'deals-data-grid-board-delete',
                });
                dispatch(dealsDeleted(ids));
            })
            .catch((e) => dispatch(dealsDeletingFailed(e.message ? e.message : e)));
    };
}

function updateCurrentFilter(textFilter, advancedSearch, token) {
    return {
        type: ActionTypes.DEALS_UPDATE_FILTER,
        payload: {
            textFilter: textFilter,
            advancedSearch: advancedSearch,
            requestToken: token,
        },
    };
}

function dealsLoaded(deals) {
    return {
        type: ActionTypes.DEALS_LOADED,
        payload: {
            deals: deals
                ? {
                      ...deals,
                      items: deals.items.reduce((result, deal) => {
                          try {
                              result.push(Deal.fromApi(deal));
                          } catch (e) {}
                          return result;
                      }, []),
                  }
                : deals,
        },
    };
}

function dealsLoadedDataGrid(deals) {
    let count = 0;
    deals.results.forEach((element) => {
        if (element.stage) {
            count = count + 1;
        }
    });
    EventBusInstance.publish({
        topic: 'update-datagrid-rows',
        message: { ...deals.counts, itemCount: count },
        target: 'deals-data-grid-board-counts',
    });
    return {
        type: ActionTypes.DEALS_LOADED,
        payload: {
            deals: deals
                ? {
                      ...deals,
                      items: deals.results.reduce((result, deal) => {
                          try {
                              result.push(Deal.fromDataGridApi(deal));
                          } catch (e) {}
                          return result;
                      }, []),
                  }
                : deals,
        },
    };
}

function dealsMoreLoading() {
    return {
        type: ActionTypes.DEALS_MORE_LOADING,
    };
}

function dealsMoreLoaded(deals) {
    return {
        type: ActionTypes.DEALS_MORE_LOADED,
        payload: {
            deals: deals
                ? {
                      ...deals,
                      items: deals.items.reduce((result, deal) => {
                          try {
                              result.push(Deal.fromApi(deal));
                          } catch (e) {}
                          return result;
                      }, []),
                  }
                : deals,
        },
    };
}

function dealsSpecificIdsLoaded(deals) {
    return {
        type: ActionTypes.DEALS_IDS_LOADED,
        payload: {
            deals: deals
                ? {
                      ...deals,
                      items: deals.items.reduce((result, deal) => {
                          try {
                              result.push(Deal.fromApi(deal));
                          } catch (e) {}
                          return result;
                      }, []),
                  }
                : deals,
        },
    };
}

function loadingDealsCancelled() {
    return {
        type: ActionTypes.DEALS_LOADING_CANCELLED,
    };
}

function loadingDealsFailed(error) {
    return {
        type: ActionTypes.DEALS_LOADING_FAILED,
        payload: {
            error: error,
        },
    };
}

function dealsUpdatingPipeline(entities) {
    return {
        type: ActionTypes.DEALS_UPDATING_PIPELINE,
        payload: {
            deals: entities,
        },
    };
}

function dealsUpdatedPipeline(rawEntities) {
    return {
        type: ActionTypes.DEALS_UPDATED_PIPELINE,
        payload: {
            deals: rawEntities.reduce((result, rawEntity) => {
                try {
                    result.push(Deal.fromApi(rawEntity));
                } catch (e) {}
                return result;
            }, []),
        },
    };
}

function dealsUpdatingPipelineFailed(error) {
    return {
        type: ActionTypes.DEALS_UPDATING_PIPELINE_FAILED,
        payload: {
            error: error,
        },
    };
}

function dealsDeleting(entities) {
    return {
        type: ActionTypes.DEALS_DELETING,
        payload: {
            deals: entities,
        },
    };
}

function dealsDeleted(ids) {
    return {
        type: ActionTypes.DEALS_DELETED,
        payload: {
            ids: ids,
        },
    };
}

function dealsDeletingFailed(error) {
    return {
        type: ActionTypes.DEALS_DELETING_FAILED,
        payload: {
            error: error,
        },
    };
}

export function newDeal(
    interactableType = undefined,
    interactableId = undefined,
    loggedInUser = undefined,
    defaultDealType = undefined,
    defaultDealPipeline = undefined,
    assignToLoggedInUser = false,
) {
    return {
        type: ActionTypes.DEAL_NEW,
        payload: {
            interactableType: interactableType,
            interactableId: interactableId,
            loggedInUser: loggedInUser,
            defaultDealType: defaultDealType,
            defaultDealPipeline: defaultDealPipeline,
            assignToLoggedInUser: assignToLoggedInUser,
        },
    };
}

export function updateDealField(id, fieldName, value) {
    return {
        type: ActionTypes.DEAL_UPDATE_FIELD,
        payload: {
            id: id,
            fieldName: fieldName,
            value: value,
        },
    };
}

export function saveDeal(entity) {
    return (dispatch) => {
        dispatch(savingDeal(entity));
        DealRepository.save(entity)
            .then((response) => {
                dispatch(dealSaved(response));
            })
            .catch((error) => {
                dispatch(savingDealFailed(entity.id, error));
            });
    };
}

export function selectDeal(id) {
    return {
        type: ActionTypes.DEAL_SELECT,
        payload: {
            id: id,
        },
    };
}

export function loadDeal(id) {
    return (dispatch) => {
        dispatch(loadingDeal(id));
        DealRepository.find(id)
            .then((response) => {
                dispatch(dealLoaded(response));
            })
            .catch((error) => {
                dispatch(loadingDealFailed(id, error));
            });
    };
}

export function invalidateDeal(id) {
    return {
        type: ActionTypes.DEAL_INVALIDATED,
        payload: {
            id: id,
        },
    };
}

export function deleteDeal(id) {
    return (dispatch) => {
        dispatch(deletingDeal(id));
        DealRepository.delete(id)
            .then(() => {
                dispatch(dealDeleted(id));
            })
            .catch((error) => {
                dispatch(savingDealFailed(id, error));
            });
    };
}

export function loadLinks(id) {
    return (dispatch) => {
        dispatch(loadingLinks(id));
        DealRepository.links(id)
            .then((response) => {
                dispatch(linksLoaded(id, response));
            })
            .catch((error) => {
                dispatch(loadingLinksFailed(id, error));
            });
    };
}

function savingDeal(entity) {
    return {
        type: ActionTypes.DEAL_SAVING,
        payload: {
            entity: entity,
        },
    };
}

function dealSaved(rawEntity) {
    return {
        type: ActionTypes.DEAL_SAVED,
        payload: {
            data: rawEntity,
        },
    };
}

function savingDealFailed(id, error) {
    return {
        type: ActionTypes.DEAL_SAVING_FAILED,
        payload: {
            id: id,
            error: error,
        },
    };
}

function loadingDeal(id) {
    return {
        type: ActionTypes.DEAL_LOADING,
        payload: {
            id: id,
        },
    };
}

function dealLoaded(rawEntity) {
    return {
        type: ActionTypes.DEAL_LOADED,
        payload: {
            data: rawEntity,
        },
    };
}

function loadingDealFailed(id, error) {
    return {
        type: ActionTypes.DEAL_LOADING_FAILED,
        payload: {
            id: id,
            error: error,
        },
    };
}

function deletingDeal(id) {
    return {
        type: ActionTypes.DEAL_DELETING,
        payload: {
            id: id,
        },
    };
}

function dealDeleted(id) {
    return {
        type: ActionTypes.DEAL_DELETED,
        payload: {
            id: id,
        },
    };
}

function loadingLinks(id) {
    return {
        type: ActionTypes.DEAL_LOADING_LINKS,
        payload: {
            id: id,
        },
    };
}

function linksLoaded(id, responseData) {
    return {
        type: ActionTypes.DEAL_LOADED_LINKS,
        payload: {
            id: id,
            quotes: responseData.quotes
                ? responseData.quotes.reduce((result, rawEntity) => {
                      try {
                          result.push(Quote.fromApi(rawEntity).transitionState(EntityState.READY));
                      } catch (e) {}
                      return result;
                  }, [])
                : [],
            sales: responseData.sales
                ? responseData.sales.reduce((result, rawEntity) => {
                      try {
                          result.push(Sale.fromApi(rawEntity).transitionState(EntityState.READY));
                      } catch (e) {}
                      return result;
                  }, [])
                : [],
            tasks: responseData.tasks
                ? responseData.tasks.reduce((result, rawEntity) => {
                      try {
                          result.push(Task.fromApi(rawEntity).transitionState(EntityState.READY));
                      } catch (e) {}
                      return result;
                  }, [])
                : [],
        },
    };
}

function loadingLinksFailed(id, error) {
    return {
        type: ActionTypes.DEAL_LOADING_LINKS_FAILED,
        payload: {
            id: id,
            error: error,
        },
    };
}
