import {
    AppEvent,
    Checkbox,
    EventBusInstance,
    isValidString,
    LogLevel,
    showBanner,
    Sisp,
} from '@sprint/sprint-react-components';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { DealTypeRequest } from '../../Api/DealTypeRequest';
import { RepositoryFactoryContext } from '../../index';
import DealType from '../../Models/DealType';
import DealTypeEditState from '../../Models/DealTypeEditState';

interface Props {
    uniqueKey: string;
    onSuccess: (results: any) => Promise<boolean>;
}

const DealTypesEditSisp: FunctionComponent<Props> = (props: Props) => {
    const [shown, setShown] = useState<boolean>(false);

    const dealTypesRepository = useContext(RepositoryFactoryContext).getApiRepository(new DealTypeRequest());

    const focusRef = useRef<HTMLInputElement>(null);

    const [rowId, setRowId] = useState<number>(0);

    const [editType, setEditType] = useState<string>('');
    const [editTypeValid, setEditTypeValid] = useState<boolean>(true);

    const [editDefault, setEditDefault] = useState<boolean>(false);

    useEffect(() => {
        if (shown) {
            if (focusRef.current) {
                focusRef.current.focus();
                focusRef.current.selectionStart = focusRef.current.value.length;
                focusRef.current.selectionEnd = focusRef.current.value.length;
            }
            setEditTypeValid(true);
        }
    }, [shown]);

    useEffect(() => {
        EventBusInstance.subscribe(
            'show-hoverover-component',
            (event: AppEvent<DealTypeEditState>) => {
                if (event.target !== props.uniqueKey) return;
                setRowId(event.message.id);
                setEditType(event.message.type);
                setEditDefault(event.message.is_default);
                setShown(true);
            },
            props.uniqueKey,
        );
    }, [shown]);

    const validate = async (): Promise<boolean> => {
        const typeValid = !!editType && isValidString(editType);
        setEditTypeValid(typeValid);
        return typeValid;
    };

    const handleEditRow = async (): Promise<boolean> => {
        const DealType: DealType = {
            id: rowId,
            type: editType,
            is_default: editDefault,
            // Doesnt change can only be false
            built_in: false,
        };

        return dealTypesRepository
            .update(DealType)
            .then((results: any) => {
                props.onSuccess(results.data);
                return Promise.resolve(true);
            })
            .catch((err) => {
                showBanner(
                    {
                        message: 'Failed to edit Deal Type - ' + (err?.message ?? err),
                        level: LogLevel.ERROR,
                    },
                    props.uniqueKey.split('-')[0],
                );
                return Promise.resolve(false);
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        if ((await validate()) && (await handleEditRow())) setShown(false);
    };

    return (
        <Sisp
            isOpen={shown}
            onSubmit={handleEditRow}
            onCancel={() => {
                setShown(false);
            }}
            validate={validate}
        >
            <h4>Edit a Deal Type</h4>
            <Form onSubmit={onSubmitForm}>
                <Form.Group>
                    <Form.Label>
                        Deal Type <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        ref={focusRef}
                        type="text"
                        isInvalid={!editTypeValid}
                        value={editType || ''}
                        onChange={(event) => {
                            setEditType(event.target.value);
                            setEditTypeValid(true);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {!editTypeValid && 'This field is required.'}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Checkbox
                        label="Make this the default deal type"
                        isChecked={editDefault}
                        onChange={(event) => setEditDefault(event.target.checked)}
                    />
                </Form.Group>
            </Form>
        </Sisp>
    );
};

export default DealTypesEditSisp;
