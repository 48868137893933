'use strict';

import * as SymbolPosition from "./SymbolPosition";

export default class Currency {

    iso = '';
    symbolPosition = SymbolPosition.BEFORE;
    symbol = '';
    isZeroDecimal = false;

    static blank() {
        return new Currency();
    }

    static fromApi(currency) {
        const entity = Currency.blank();

        // Construct from expected API output object
        entity.iso = currency.iso_code;
        entity.symbolPosition = currency.position;
        entity.symbol = currency.symbol;
        entity.isZeroDecimal = currency.zero_decimal;

        return entity;
    }

    forSelect() {
        return {
            id: this.iso,
            label: this.iso + '(' + this.symbol + ')'
        }
    }

    static currencyAmountFormatted(pennies, currency, hideDecimal) {
        if (!currency) return pennies.toString();

        const sign = (pennies < 0) ? '-' : '';
        let amount = Math.abs(pennies);
        if (!currency.isZeroDecimal) amount = (amount / 100);

        const fractionDigits = hideDecimal ? 0 : 2;

        // Format as per client's Locale (with locale decimal point and thousands separator chars)
        let formatted_amount = amount.toLocaleString(
            currency.iso ? currency.iso.replace('_', '-') : 'en',
            {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits
            }
        );

        if (currency.symbolPosition === SymbolPosition.AFTER) {
            return sign + formatted_amount + currency.symbol;
        } else {
            return sign + currency.symbol + formatted_amount;
        }
    }

    static penniesFromDecimal(decimal, currency) {
        if (currency && currency.isZeroDecimal) {
            return Math.round(parseFloat(decimal));
        } else {
            return Math.round(parseFloat(decimal) * 100);
        }
    }

    static decimalFromPennies(pennies, currency) {
        if (currency && currency.isZeroDecimal) {
            return Math.round(parseFloat(pennies));
        } else {
            return parseFloat(pennies) / 100;
        }
    }

}
