'use strict';

const PLACEHOLDER_MAX_LENGTH = 30;

export function shortenPlaceholder(placeholder) {
    if (placeholder.length <= PLACEHOLDER_MAX_LENGTH) return placeholder;

    // multiple options?
    const placeholderOptions = placeholder.split(",").map(o => o.trim());

    // only one option
    if (placeholderOptions.length === 1) {
        return placeholder.substring(0, PLACEHOLDER_MAX_LENGTH) + ' ...';
    }

    // multiple options
    let newPlaceholder = placeholderOptions[0];
    for (let i = 1; i < placeholderOptions.length; i++) {
        let tempPlaceholder = newPlaceholder + ', ' + placeholderOptions[i];
        if (tempPlaceholder.length > PLACEHOLDER_MAX_LENGTH) return newPlaceholder + ', ' + placeholderOptions[i].substring(0,1) + ' ...';
        newPlaceholder = tempPlaceholder;
    }

    return newPlaceholder;
}