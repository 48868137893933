import * as SchoolRepository from '../../../Entities/School/SchoolRepository';
import * as ActionTypes from './ActionTypes';

export function loadTeachers(schoolId, educationDataSource) {
    return (dispatch) => {
        dispatch(loadingTeachers());
        SchoolRepository.teachers(schoolId, educationDataSource)
            .then((response) => {
                dispatch(teachersLoaded(response));
            })
            .catch((error) => {
                dispatch(teachersLoadingFailed(error));
            });
    };
}

export function unsubscribeTeacher(teacherId, educationSource) {
    return (dispatch) => {
        dispatch(unsubscribingTeacher(teacherId));
        SchoolRepository.unsubscribeTeacher(teacherId, educationSource)
            .then(() => {
                dispatch(teacherUnsubscribed(teacherId));
            })
            .catch((error) => {
                dispatch(unsubscribeTeacherFailed(error));
            });
    };
}

export function toggleTeacherSelect(teacherId, checkedState) {
    return {
        type: ActionTypes.TEACHER_SELECTION_CHANGED,
        payload: {
            teacherId: teacherId,
            checkedState: checkedState,
        },
    };
}

function loadingTeachers() {
    return {
        type: ActionTypes.TEACHERS_LOADING,
    };
}

function teachersLoaded(teachers) {
    return {
        type: ActionTypes.TEACHERS_LOADED,
        payload: {
            teachers: teachers,
        },
    };
}

function teachersLoadingFailed(error) {
    return {
        type: ActionTypes.TEACHERS_LOADING_FAILED,
        payload: {
            error: error,
        },
    };
}

function unsubscribingTeacher(teacherId) {
    return {
        type: ActionTypes.UNSUBSCRIBE_TEACHER,
        payload: {
            teacherId: teacherId,
        },
    };
}

function teacherUnsubscribed(teacherId) {
    return {
        type: ActionTypes.TEACHER_UNSUBSCRIBED,
        payload: {
            teacherId: teacherId,
        },
    };
}

function unsubscribeTeacherFailed(error) {
    return {
        type: ActionTypes.UNSUBSCRIBE_TEACHER_FAILED,
        payload: {
            error: error,
        },
    };
}
