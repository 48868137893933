'use strict';

import User from '../User/User';
import Currency from "../../ValueObjects/Currency/Currency";

export default class Client {

    loggedInUser = User.blank();
    settings = {
        locale: Locale.blank(),
        permissions: Permissions.blank(),
    };
    limits = Limits.blank();
    company = Company.blank();

    static blank() {
        return new Client();
    }

    static fromApi(client) {
        const entity = Client.blank();

        // Construct from expected API output object
        entity.loggedInUser = User.fromApi(client.logged_in_user);
        entity.settings = {
            locale: Locale.fromApi(client.settings.locale),
            permissions: Permissions.fromApi(client.settings.permissions),
        };
        entity.limits = Limits.fromApi(client.settings.limits);
        entity.company = Company.fromApi(client.company);

        return entity;
    }

    makeUrl(relativePath) {
        if (this.company && this.company.appBaseUrl) {
            return this.company.appBaseUrl + '/' + relativePath;
        } else return relativePath;
    }

    supportEmailEnabled() {
        return this.settings.permissions.permissionEnabled('SUPPORT_EMAIL');
    }

    supportEmailInvisible() {
        return this.settings.permissions.permissionInvisible('SUPPORT_EMAIL');
    }

    supportTelephoneEnabled() {
        return this.settings.permissions.permissionEnabled('SUPPORT_TELEPHONE');
    }

    supportTelephoneInvisible() {
        return this.settings.permissions.permissionInvisible('SUPPORT_TELEPHONE');
    }

    crmEnabled() {
        return this.settings.permissions.permissionEnabled('CRM_MODULE');
    }

    salesEnabled() {
        return this.settings.permissions.permissionEnabled('MONEY_MODULE');
    }

    quotesEnabled() {
        return this.settings.permissions.permissionEnabled('QUOTES_MODULE');
    }

    teacherEmailsEnabled() {
        return this.settings.permissions.permissionEnabled('EDUK_TEACHER_EMAILS'); // EDUK V1
    }

    teacherEmailsInvisible() {
        return this.settings.permissions.permissionInvisible('EDUK_TEACHER_EMAILS'); // EDUK V1
    }

    tasksEnabled() {
        return this.settings.permissions.permissionEnabled('TASKS_MODULE');
    }

    tasksInvisible() {
        return this.settings.permissions.permissionInvisible('TASKS_MODULE');
    }

    dealsEnabled() {
        return this.settings.permissions.permissionEnabled('DEALS_MODULE');
    }

    dealsInvisible() {
        return this.settings.permissions.permissionInvisible('DEALS_MODULE');
    }

}

class Locale {
    
    currency = undefined;
    dateFormat = '';
    dateTimeFormat = '';
    dateFormatFancy = '';
    dateTimeFormatFancy = '';
    iso = '';
    timezone = '';

    static blank() {
        return new Locale();
    }

    static fromApi(locale) {
        const entity = Locale.blank();

        // Construct from expected API output object
        entity.currency = locale.currency ? Currency.fromApi(locale.currency) : undefined;
        entity.dateFormat = locale.date_format;
        entity.dateTimeFormat = locale.date_time_format;
        entity.dateFormatFancy = locale.fancy_date_format;
        entity.dateTimeFormatFancy = locale.fancy_date_time_format;
        entity.iso = locale.iso;
        entity.timezone = locale.timezone;

        return entity;
    }

    getDateFormatForDatePicker(withTime = false) {
        switch (withTime ? this.dateTimeFormat : this.dateFormat) {
            case 'm/d/Y':
                return 'MM/DD/YYYY';
            case 'm/d/Y H:i':
            case 'm/d/Y H:i:s':
                return 'MM/DD/YYYY HH:mm';
            case 'd/m/Y':
                return 'DD/MM/YYYY';
            case 'd/m/Y H:i':
            case 'd/m/Y H:i:s':
                return 'DD/MM/YYYY HH:mm';
            default:
                return 'dd/mm/yyyy';
        }
    }
}

class Permissions {

    modules = [];

    static blank() {
        return new Permissions();
    }

    static fromApi(permissions) {
        const entity = Permissions.blank();

        entity.modules = permissions.modules;

        return entity;
    }

    permissionEnabled(permission) {
        if (this.modules[permission] === undefined) return false;
        return this.modules[permission] === 'ENABLED';
    }

    permissionDisabled(permission) {
        if (this.modules[permission] === undefined) return true;
        return this.modules[permission] === 'DISABLED';
    }

    permissionInvisible(permission) {
        if (this.modules[permission] === undefined) return true;
        return this.modules[permission] === 'INVISIBLE';
    }

}

class Limits {

    limits = [];

    static blank() {
        return new Limits();
    }

    static fromApi(limits) {
        const entity = Limits.blank();

        entity.limits = limits;

        return entity;
    }

    limitValue(limit) {
        if (typeof this.limits[limit] === 'undefined') return null;
        return this.limits[limit];
    }

}

class Company {

    name = '';
    email = '';
    emailSupport = '';
    telephone = '';
    dictionary = undefined;
    appBaseUrl = '';
    kbBaseUrl = '';

    static blank() {
        return new Company();
    }

    static fromApi(company) {
        const entity = Company.blank();

        entity.name = company.name;
        entity.email = company.email;
        entity.emailSupport = company.email_support;
        entity.telephone = company.telephone;
        entity.dictionary = JSON.parse(company.dictionary);
        entity.appBaseUrl = company.app_base_url;
        entity.kbBaseUrl = company.kb_base_url;

        return entity;
    }

    dictionaryFromKey(category, key) {
        if (typeof this.dictionary !== 'object') return undefined;
        if (typeof this.dictionary[category] !== 'object') return undefined;
        return this.dictionary[category][key];
    }

}
