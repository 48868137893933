'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {filterContacts, loadIdList, loadMore} from "../Actions";
import ConnectedCampusFormSelect from "../../../CommonComponents/Form/ConnectedCampusFormSelect";

class ConnectedContactFormSelect extends React.Component {

    render() {
        return <ConnectedCampusFormSelect
            {...this.props}
            reducerName="contacts"
            filterEntity={filterContacts}
            loadIdList={loadIdList}
            loadMore={loadMore}
        />
    }

}

ConnectedContactFormSelect.propTypes = {
    label: PropTypes.string.isRequired,
    validationErrorMessage: PropTypes.string,
    placeHolder: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    clearable: PropTypes.bool,
    noResultsLabel: PropTypes.string,
};

ConnectedContactFormSelect.defaultProps = {
    label: 'Contact',
    placeHolder: 'Please select',
    noResultsLabel: 'No Contacts Found'
};

export default ConnectedContactFormSelect;