import {
    AsyncDropDownPaginated,
    Banner,
    DropDown,
    hideBanner,
    LogLevel,
    OptionTypeBase,
    PendingButton,
    SearchQuery,
    SearchResult,
    showBanner,
    Sisp,
    SortOrder,
} from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { v4 as uuidv4 } from 'uuid';
import { RepositoryFactoryContext } from '../..';
import { HistoricalPaymentsRequest, PaymentsRequest, UnpaidInvoicesRequest } from '../../Api/PaymentsRequest';
import { PaymentTypeRequest } from '../../Api/PaymentTypeRequest';
import PaymentAddState from '../../Models/PaymentAddState';
import PaymentType from '../../Models/PaymentType';
import UnpaidInvoice from '../../Models/UnpaidInvoice';
import SalesPaymentsTable from '../Sales/SalesPaymentsTable';
import './PaymentsAddSisp.scss';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
}

const PaymentsAddSisp: FunctionComponent<Props> = (props: Props) => {
    const currencyRegexp = /[^0-9\.]+/g;
    const validNewPaymentRegexp = /^(([0-9]*\.?[0-9]{2}){1})$/g;
    const [paymentMethods, setPaymentMethods] = useState<OptionTypeBase[]>([]);

    const bannerTarget = 'payment-add-sisp-banner-target';
    const paymentsRepository = useContext(RepositoryFactoryContext).getApiRepository(new PaymentsRequest());
    const paymentTypesRepository = useContext(RepositoryFactoryContext).getApiRepository(new PaymentTypeRequest());
    const historicalPaymentsRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new HistoricalPaymentsRequest(),
    );
    const unpaidInvoicesRepository = useContext(RepositoryFactoryContext).getApiRepository(new UnpaidInvoicesRequest());

    const focusRef = useRef<HTMLInputElement>(null);

    // General: state
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingInvoices, setIsLoadingInvoices] = useState<boolean>(true);
    const [isLoadingPaymentMethods, setIsLoadingPaymentMethods] = useState<boolean>(true);

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [dropDownKey, setDropDownKey] = useState<string>('');

    const [selectedSaleId, setSelectedSaleId] = useState<number | null>(null);
    const [outstandingPayment, setOutstandingPayment] = useState<string | null>(null);

    const [newPayment, setNewPayment] = useState<string>('');
    const [newPaymentDate, setNewPaymentDate] = useState<Date | null>(null);
    const [newPaymentMethod, setNewPaymentMethod] = useState<OptionTypeBase | null>(null);

    const [newPaymentValid, setNewPaymentValid] = useState<boolean>(true);
    const [newPaymentDateValid, setNewPaymentDateValid] = useState<boolean>(true);
    const [newPaymentMethodValid, setNewPaymentMethodValid] = useState<boolean>(true);

    const [historicalPayments, setHistoricalPayments] = useState();
    const [showPaymentsTable, setShowPaymentsTable] = useState<boolean>(false);

    const [unpaidInvoice, setUnpaidInvoice] = useState<OptionTypeBase | null>(null);
    const [numberOfUnpaidInvoices, setNumberOfUnpaidInvoices] = useState<number>(0);

    useEffect(() => {
        if (props.shown) {
            setIsLoading(true);
            focusRef.current?.focus();
            setNewPaymentDate(new Date());
            setDropDownKey(uuidv4());
            getNumberOfUnpaidInvoices();
            populatePaymentMethodsDropdown();
        }
    }, [props.shown]);

    useEffect(() => {
        setIsLoading(isLoadingInvoices && isLoadingPaymentMethods);
    }, [isLoadingInvoices, isLoadingPaymentMethods]);

    const getNumberOfUnpaidInvoices = async () => {
        let res: OptionTypeBase[] = await getUnpaidInvoices('');
        res = _.filter(res, (obj) => obj.label !== 'has_more');
        setNumberOfUnpaidInvoices(res.length);
        setIsLoadingInvoices(false);
    };

    const populatePaymentMethodsDropdown = async () => {
        let res: OptionTypeBase[] = await getPaymentMethods('');
        res = _.filter(res, (obj) => obj.label !== 'has_more');
        setPaymentMethods(res);
        setIsLoadingPaymentMethods(false);
    };

    const showFailureBanner = (err?: any) => {
        showBanner({
            message: 'Failed to get Unpaid Invoice details - ' + (err?.message ?? err),
            level: LogLevel.ERROR,
            dismissable: false,
        });
    };

    useEffect(() => {
        if (selectedSaleId) {
            collectHistoricalPayments(selectedSaleId);
            if (unpaidInvoice) {
                const invoice_details_query = new SearchQuery(1, 100);
                invoice_details_query.setExtendedParameters({ invoice_id: unpaidInvoice.value });
                unpaidInvoicesRepository
                    .search(invoice_details_query)
                    .then((results: any) => {
                        if (results.results.length > 0) {
                            const unpaidInvoiceDetails: UnpaidInvoice = results.results[0];
                            const outstandingMessage = buildOutstandingMessage(
                                unpaidInvoiceDetails.total,
                                unpaidInvoiceDetails.total_due,
                            );
                            setOutstandingPayment(unpaidInvoiceDetails.total_due.replace(currencyRegexp, ''));
                            showBanner(
                                {
                                    message: outstandingMessage,
                                    level: LogLevel.ERROR,
                                    dismissable: false,
                                },
                                bannerTarget,
                            );
                        } else {
                            showFailureBanner('issue creating outstanding payment');
                        }
                    })
                    .catch((err: any) => {
                        showFailureBanner(err);
                    });
            }
        } else {
            reset();
        }
    }, [selectedSaleId]);

    const collectHistoricalPayments = async (invoice_id: number) => {
        const invoice_query = new SearchQuery(1, 100);
        invoice_query.setExtendedParameters({ sale_id: invoice_id });
        historicalPaymentsRepository
            .search(invoice_query)
            .then((results: any) => {
                if (results.results.length > 0) {
                    setHistoricalPayments(results);
                }
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get Historical Payment - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                    dismissable: false,
                });
            });
    };

    useEffect(() => {
        if (historicalPayments && (historicalPayments as SearchResult<unknown>).results.length > 0) {
            setShowPaymentsTable(true);
        }
    }, [historicalPayments]);

    const buildOutstandingMessage = (total: string, total_due: string) => {
        return `${total_due} is outstanding (sale value ${total})`;
    };

    const validate = async (): Promise<boolean> => {
        const paymentValid = newPayment.length > 0 && validNewPaymentRegexp.test(newPayment);
        setNewPaymentValid(paymentValid);

        const dateValid = newPaymentDate != null;
        setNewPaymentDateValid(dateValid);

        const paymentMethodValid = newPaymentMethod != null;
        setNewPaymentMethodValid(paymentMethodValid);

        return paymentValid && dateValid && paymentMethodValid;
    };

    const reset = () => {
        setSelectedSaleId(null);
        setHistoricalPayments(undefined);
        setShowPaymentsTable(false);
        setNewPayment('');
        setNewPaymentDate(new Date());
        setNewPaymentMethod(null);
        setOutstandingPayment(null);

        setNewPaymentValid(true);
        setNewPaymentDateValid(true);
        setNewPaymentMethodValid(true);
        setUnpaidInvoice(null);

        hideBanner(bannerTarget);
    };

    const dropdownMapLambda = (element: UnpaidInvoice) => {
        return {
            value: element.id,
            label: element.dropdown_label,
        };
    };

    const getUnpaidInvoices = async (filter: string, page?: number) => {
        // Build filterRequest
        const query: SearchQuery = new SearchQuery(page ?? 1, 100, 'id', SortOrder.DESC, filter);
        let res: OptionTypeBase[] = [];

        return unpaidInvoicesRepository
            .search(query)
            .then((result: any) => {
                res = _.map(result.results, (unpaidInvoice: UnpaidInvoice) => {
                    return dropdownMapLambda(unpaidInvoice);
                });
                res.push({
                    value: result.counts.currentPage < result.counts.totalPages,
                    label: 'has_more',
                });
                return res;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to get Unpaid Invoices - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return res;
            });
    };

    const getPaymentMethods = async (filter: string, page?: number) => {
        // Build filterRequest
        const query: SearchQuery = new SearchQuery(page ?? 1, 100, 'id', SortOrder.DESC, filter);
        let res: OptionTypeBase[] = [];

        return paymentTypesRepository
            .search(query)
            .then((result: any) => {
                res = _.map(result.results, (paymentType: PaymentType) => {
                    return { label: paymentType.payment_type, value: paymentType.id! };
                });
                res.push({
                    value: result.counts.currentPage < result.counts.totalPages,
                    label: 'has_more',
                });
                return res;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to get Payment Methods - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return res;
            });
    };

    const handleAddRow = async (): Promise<boolean> => {
        const payment: PaymentAddState = {
            invoice_id: selectedSaleId!,
            amount_received: newPayment,
            payment_date: newPaymentDate!.toISOString().substring(0, 10),
            payment_type_id: newPaymentMethod!.value,
        };

        return paymentsRepository
            .create(payment)
            .then(props.onSuccess)
            .then(async (success) => {
                reset();
                return success;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to create Payment - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        setIsSaving(true);
        e.preventDefault();
        if ((await validate()) && (await handleAddRow())) props.onClose();
        setIsSaving(false);
    };

    const onSubmitAndAddAnother = async (e: FormEvent) => {
        setIsSaving(true);
        e.preventDefault();
        if ((await validate()) && (await handleAddRow())) {
            reset();
            setDropDownKey(uuidv4());
            focusRef.current?.focus();
        }
        setIsSaving(false);
        setIsLoadingInvoices(true);
        getNumberOfUnpaidInvoices();
    };

    return (
        <Sisp
            isOpen={props.shown}
            onSubmit={handleAddRow}
            onCancel={() => {
                reset();
                props.onClose();
            }}
            validate={validate}
            footerOverride={
                <>
                    <Button
                        variant="default"
                        onClick={() => {
                            reset();
                            props.onClose();
                        }}
                    >
                        Cancel
                    </Button>
                    {outstandingPayment !== null && numberOfUnpaidInvoices > 1 && (
                        <PendingButton variant="default" onClick={onSubmitAndAddAnother} pending={isSaving}>
                            Save & Add Another
                        </PendingButton>
                    )}
                    {outstandingPayment !== null && (
                        <PendingButton variant="primary" onClick={onSubmitForm} pending={isSaving}>
                            Save
                        </PendingButton>
                    )}
                </>
            }
        >
            <h4>Add a Payment</h4>
            {isLoading ? (
                <div style={{ position: 'relative', alignItems: 'center' }}>
                    <Card
                        className="loading-spinner-container filter-loading-spinner"
                        style={{ background: '#f9f9f9' }}
                    >
                        <Spinner animation="border" role="status" />
                    </Card>
                </div>
            ) : (
                <Form onSubmit={onSubmitForm}>
                    <Form.Group>
                        <Form.Label>
                            Sale <span className="required-field-marker">*</span>
                        </Form.Label>
                        <AsyncDropDownPaginated
                            id={'unpaid_invoices_dropdown'}
                            stateKey={dropDownKey}
                            value={unpaidInvoice}
                            isInvalid={false}
                            onChange={(option: OptionTypeBase) => {
                                reset();
                                const selectedItem = option?.value ?? null;
                                setUnpaidInvoice(option);
                                setSelectedSaleId(selectedItem);
                            }}
                            loadOptions={async (filter: string, _loadedOptions, { page }) => {
                                let res = await getUnpaidInvoices(filter, page);
                                // Get has_more entry from results
                                const hasMore = res.find((obj) => obj.label === 'has_more');
                                // Remove has_more entry from main results
                                res = _.filter(res, (obj) => obj.label !== 'has_more');
                                return {
                                    options: res,
                                    hasMore: hasMore?.value as boolean,
                                    additional: {
                                        page: page + 1,
                                    },
                                };
                            }}
                            isClearable={false}
                            menuPortalTarget={document.body}
                        />
                    </Form.Group>
                    {selectedSaleId != null && (
                        <Form.Group>
                            <Banner target={bannerTarget} />
                        </Form.Group>
                    )}
                    {showPaymentsTable && (
                        <Form.Group>
                            <SalesPaymentsTable dataGridUniqueKey="SalesPayments" dataGridData={historicalPayments} />
                        </Form.Group>
                    )}
                    {outstandingPayment != null && (
                        <>
                            <Form.Group>
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <Form.Label style={{ justifyContent: 'flex-start', width: '100%' }}>
                                        Amount Received <span className="required-field-marker">*</span>
                                    </Form.Label>
                                    {outstandingPayment != null && (
                                        <button
                                            type="button"
                                            className="btn btn-link"
                                            style={{ justifyContent: 'flex-end', margin: '0px' }}
                                            onClick={() => {
                                                setNewPayment(outstandingPayment);
                                            }}
                                        >
                                            Pay in Full
                                        </button>
                                    )}
                                </div>
                                <Form.Control
                                    disabled={selectedSaleId == null}
                                    type="text"
                                    isInvalid={!newPaymentValid}
                                    value={newPayment}
                                    onChange={(event) => {
                                        let entered_value = event.target.value.replace(currencyRegexp, '');
                                        if (entered_value.includes('.')) {
                                            const tokens = entered_value.split('.');
                                            if (tokens[1].length > 2) {
                                                entered_value = `${tokens[0]}.${tokens[1].substring(0, 2)}`;
                                            }
                                        }
                                        setNewPayment(entered_value);
                                        setNewPaymentValid(true);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {!newPaymentValid && newPayment != '' && 'This field is required.'}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Payment Date <span className="required-field-marker">*</span>
                                </Form.Label>
                                {newPaymentDate && (
                                    <DatePicker
                                        selected={newPaymentDate}
                                        onChange={setNewPaymentDate}
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control"
                                        isClearable={false}
                                        disabled={selectedSaleId == null}
                                    />
                                )}
                                <Form.Control.Feedback type="invalid">
                                    {!newPaymentDateValid && !newPaymentDate && 'This field is required.'}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group style={{ height: '240px' }}>
                                <Form.Label>
                                    Payment Method <span className="required-field-marker">*</span>
                                </Form.Label>
                                <DropDown
                                    disabled={selectedSaleId == null}
                                    value={newPaymentMethod}
                                    isInvalid={false}
                                    onChange={(selected: OptionTypeBase) => {
                                        setNewPaymentMethod(selected);
                                    }}
                                    options={paymentMethods}
                                    menuPosition="fixed"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {!newPaymentMethodValid && !newPaymentMethod && 'This field is required.'}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </>
                    )}
                </Form>
            )}
        </Sisp>
    );
};

export default PaymentsAddSisp;
