'use strict';

export default class TaskType {

    id = undefined;
    type = '';
    builtIn = false;
    isDefault = false;

    static blank() {
        return new TaskType();
    }

    static fromApi(taskType) {
        const entity = TaskType.blank();

        // Construct from expected API output object
        entity.id = taskType.id;
        entity.type = taskType.type;
        entity.builtIn = taskType.built_in;
        entity.isDefault = taskType.is_default;

        return entity;
    }

    forSelect() {
        return {
            id: this.id,
            label: this.type
        }
    }

}
