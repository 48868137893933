import { DefaultPillFormatter, ExtendedColumn, HoveroverButton } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext } from 'react';
import { PaymentTypeRequest } from '../../Api/PaymentTypeRequest';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import { UniqueKeyType } from '../../Models/Enums';
import PaymentType from '../../Models/PaymentType';
import PaymentTypeEditState from '../../Models/PaymentTypeEditState';
import { RepositoryFactoryContext } from '../../index';
import CampusDataGrid, { ActionBarMeta, AddSispMeta, DataGridMeta, PromptMeta } from '../CampusDataGrid';
import PaymentTypesAddSisp from './PaymentTypesAddSisp';
import PaymentTypesEditSisp from './PaymentTypesEditSisp';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

export enum PaymentTypesColumnKey {
    ID,
    PAYMENT_TYPE,
}

const PaymentTypesTable: FunctionComponent<Props> = (props: Props) => {
    const editSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);

    const paymentTypesRepository = useContext(RepositoryFactoryContext).getApiRepository(new PaymentTypeRequest());

    const Columns: Record<PaymentTypesColumnKey, ExtendedColumn> = {
        [PaymentTypesColumnKey.ID]: {
            key: 'id',
            name: 'ID',
            sortable: false,
        },
        [PaymentTypesColumnKey.PAYMENT_TYPE]: {
            key: 'payment_type',
            name: 'Name',
            sortable: true,
            renderCell: (props) => {
                const id = (props.row as PaymentType).id!;
                const name = (props.row as PaymentType).payment_type;
                const is_default = (props.row as PaymentType).is_default!;
                const eventBusMessage: PaymentTypeEditState = {
                    id: id,
                    payment_type: name,
                    is_default: is_default,
                };
                return (
                    <>
                        <DefaultPillFormatter cellContent={name} showPill={is_default}>
                            <HoveroverButton
                                contents="Edit"
                                eventBusMessageTarget={editSispUniqueKey}
                                eventBusMessage={eventBusMessage}
                            />
                        </DefaultPillFormatter>
                    </>
                );
            },
        },
    };

    const DefaultColumns: ExtendedColumn[] = [Columns[PaymentTypesColumnKey.PAYMENT_TYPE]];

    const dataGridMeta: DataGridMeta = {
        uniqueKey: props.dataGridUniqueKey,
        entitySingular: props.dataGridEntitySingular,
        entityPlural: props.dataGridEntityPlural,
        columnOptions: Columns,
        defaultColumns: DefaultColumns,
        frozenColumns: [],
    };

    const actionBarMeta: ActionBarMeta = {
        searchPlaceHolder: props.searchFilterPlaceholder,
        includeCounts: true,
    };

    const addSispMeta: AddSispMeta = {
        key: UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP),
        sisp: PaymentTypesAddSisp,
    };

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_payment_types.png',
        iconHeight: 80,
    };

    return (
        <>
            <CampusDataGrid
                repository={paymentTypesRepository}
                actionBarMeta={actionBarMeta}
                addSispMeta={addSispMeta}
                editSispMeta={{ sisp: PaymentTypesEditSisp }}
                dataGridMeta={dataGridMeta}
                promptMeta={promptMeta}
            />
        </>
    );
};

export default PaymentTypesTable;
