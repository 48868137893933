export enum APP_COLOURS {
    BLACK = '000000',
    WHITE = 'FFFFFF',
    // Brand Colours
    DARK_BLUE = '17375E',
    STANDARD_BLUE = '0099CC',
    GREEN = '2FC2AD',
    BRIGHT_GREEN = '23E783',
    ORANGE = 'FFC252',
    PINK = 'EF6B94',
}
