'use strict';

import { EventBusInstance } from '@sprint/sprint-react-components';
import { filterDeals, filterDealsDataGrid } from '../../../Entities/Deal/Actions';
import * as ActionTypes from './AdvancedSearchActionTypes';

export function openComponent(component) {
    return {
        type: ActionTypes.OPEN_COMPONENT,
        payload: {
            component: component,
        },
    };
}

export function updateSelectedComponents(selectedComponents) {
    return {
        type: ActionTypes.UPDATE_SELECTED_COMPONENTS,
        payload: {
            selectedComponents: selectedComponents,
        },
    };
}

export function updateSearch(newSearch) {
    return (dispatch) => {
        dispatch(actionUpdateSearch(newSearch));
    };
}

export function removeComponent(componentToRemove) {
    return (dispatch) => {
        dispatch(actionRemoveComponent(componentToRemove));
    };
}

export function updateDataTable(currentSearch) {
    return (dispatch) => {
        if (typeof DealsDataTable !== 'undefined' && typeof DealsDataTable.updateAdvancedSearch === 'function') {
            try {
                DealsDataTable.updateAdvancedSearch(currentSearch, (response) => {
                    dispatch(dataTableUpdated(response['recordsTotal']));
                });
            } catch (e) {}
        }
        dispatch(dataTableUpdating());
    };
}

export function updateLoadedEntities(currentTextFilter, currentSearch) {
    return (dispatch) => {
        dispatch(filterDeals(currentTextFilter, currentSearch));
        dispatch(loadedEntitiesUpdating());
    };
}

export function updateLoadedEntitiesDataGrid(simpleFilterRequest, repository) {
    EventBusInstance.publish({
        topic: 'update-datagrid-rows',
        message: true,
        target: 'deals-data-grid-board-loading',
    });
    return (dispatch) => {
        dispatch(filterDealsDataGrid(simpleFilterRequest, repository));
        dispatch(loadedEntitiesUpdating());
    };
}

export function advLoadSearch(searchToLoad) {
    return (dispatch) => {
        dispatch(actionLoadSearch(searchToLoad));
    };
}

export function advClearSearch() {
    return (dispatch) => {
        dispatch(actionClearSearch());
    };
}

function actionUpdateSearch(newSearch) {
    return {
        type: ActionTypes.UPDATE_SEARCH,
        payload: {
            newSearch: newSearch,
        },
    };
}

export function actionDealDataGridPipelineFilter(pipelineArray) {
    return {
        type: ActionTypes.DEAL_DATA_GRID_PIPELINE_FILTER,
        payload: {
            pipelineArray: pipelineArray,
        },
    };
}

function dataTableUpdating() {
    return {
        type: ActionTypes.DATA_TABLE_UPDATING,
    };
}

function dataTableUpdated(dataTableRowCount = -1) {
    return {
        type: ActionTypes.DATA_TABLE_UPDATED,
        payload: {
            dataTableRowCount: dataTableRowCount,
        },
    };
}

function loadedEntitiesUpdating() {
    return {
        type: ActionTypes.LOADED_ENTITIES_UPDATING,
    };
}

function actionRemoveComponent(componentToRemove) {
    return {
        type: ActionTypes.REMOVE_COMPONENT,
        payload: {
            componentToRemove: componentToRemove,
        },
    };
}

export function actionLoadSearch(searchToLoad) {
    return {
        type: ActionTypes.LOAD_SEARCH,
        payload: {
            searchToLoad: searchToLoad,
        },
    };
}

export function actionClearSearch() {
    return {
        type: ActionTypes.CLEAR_SEARCH,
    };
}
