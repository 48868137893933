'use strict';

import Contact from "../Contact/Contact";

export default class Organisation {

    id = undefined;
    name = '';
    contactInformation = undefined;
    sector = '';
    address = undefined;
    organisationType = '';
    contacts = undefined;
    link = '';
    deleted = undefined;

    static blank() {
        return new Organisation();
    }

    static fromApi(organisation) {
        const entity = Organisation.blank();

        // Construct from expected API output object
        // Note: `is_logged_in` will not be set for the below Users, access via Client object
        entity.id = organisation.id;
        entity.name = organisation.name;
        entity.contactInformation = organisation.contact_information ? new OrganisationContactInformation(organisation.contact_information) : undefined;
        entity.sector = organisation.sector;
        entity.address = organisation.address;
        entity.organisationType = organisation.organisation_type;
        entity.contacts = (organisation.contacts && Array.isArray(organisation.contacts)) ? organisation.contacts.map(contact => Contact.fromApi(contact)) : undefined;
        entity.link = organisation.link;
        entity.deleted = organisation.deleted;

        return entity;
    }

    forSelect() {
        return {
            id: this.id,
            label: this.name,
        };
    }

    forApi() {
        const formData = new FormData();

        // Note: FormData will transmit a string of 'undefined' if the field is empty, so check before appending.
        if (this.id) formData.append('id', this.id);
        if (this.name) formData.append('name', this.name);

        if (this.contactInformation) formData.append('telephone', this.contactInformation.telephone);
        if (this.contactInformation) formData.append('fax', this.contactInformation.fax);
        if (this.contactInformation) formData.append('email', this.contactInformation.email);
        if (this.contactInformation) formData.append('website', this.contactInformation.website);

        if (this.sector) formData.append('sector', this.sector);

        if (this.address) formData.append('address1', this.address.address1);
        if (this.address) formData.append('address2', this.address.address2);
        if (this.address) formData.append('address3', this.address.address3);
        if (this.address) formData.append('postcode_prefix', this.address.postcode_prefix);
        if (this.address) formData.append('postcode_suffix', this.address.postcode_suffix);
        if (this.address) formData.append('county_id', this.address.county_id);

        if (this.organisationType) formData.append('organisation_type', this.organisationType);

        return formData;
    }
}

class OrganisationContactInformation {
    constructor(contactInformation) {
        this.telephone = contactInformation.telephone;
        this.fax = contactInformation.fax;
        this.email = contactInformation.email;
        this.website = contactInformation.website;
    }
}
