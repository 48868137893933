import React, { FunctionComponent, useEffect } from 'react';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
}

const SalesAddRedirect: FunctionComponent<Props> = (props: Props) => {
    const addUrl = '/sales/home/add';

    useEffect(() => {
        if (props.shown) {
            window.location.href = addUrl;
        }
    }, [props.shown]);

    return <></>;
};

export default SalesAddRedirect;
