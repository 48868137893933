'use strict';

export const SAVED_SEARCHES_UPDATE_FILTER = 'savedSearches/SAVED_SEARCHES_UPDATE_FILTER';
export const SAVED_SEARCHES_LOADED = 'savedSearches/SAVED_SEARCHES_LOADED';
export const SAVED_SEARCHES_MORE_LOADING = 'savedSearches/SAVED_SEARCHES_MORE_LOADING';
export const SAVED_SEARCHES_MORE_LOADED = 'savedSearches/SAVED_SEARCHES_MORE_LOADED';
export const SAVED_SEARCHES_LOADING_CANCELLED = 'savedSearches/SAVED_SEARCHES_LOADING_CANCELLED';
export const SAVED_SEARCHES_LOADING_FAILED = 'savedSearches/SAVED_SEARCHES_LOADING_FAILED';
export const SAVED_SEARCHES_SAVING = 'savedSearches/SAVING';
export const SAVED_SEARCHES_SAVED = 'savedSearches/SAVED';
export const SAVED_SEARCHES_SAVE_FAILED = 'savedSearches/SAVE_FAILED';
export const SAVED_SEARCHES_LOAD_SEARCH = 'savedSearches/LOAD_SEARCH';
export const SAVED_SEARCHES_CLEAR_SEARCH = 'savedSearches/CLEAR_SEARCH';
export const SAVED_SEARCHES_DELETING_ITEM = 'savedSearches/DELETING_ITEM';
export const SAVED_SEARCHES_ITEM_DELETED = 'savedSearches/ITEM_DELETED';
export const SAVED_SEARCHES_DELETING_ITEM_FAILED = 'savedSearches/DELETING_ITEM_FAILED';
