import { EventBusInstance } from '@sprint/sprint-react-components';
import { REHYDRATE } from 'redux-persist';
import DateHelper from '../../Helpers/DateHelper';
import PersistenceKey from '../../Helpers/PersistenceKey';
import HttpRequestService from '../../Services/HttpRequestService/HttpRequestService';
import * as DealStageState from '../../ValueObjects/DealStageState/DealStageState';
import * as EntityState from '../../ValueObjects/EntityState/EntityState';
import * as ClientActionTypes from '../Client/ActionTypes';
import Client from '../Client/Client';
import Contact from '../Contact/Contact';
import Organisation from '../Organisation/Organisation';
import * as ActionTypes from './ActionTypes';
import Deal from './Deal';
import * as DealPipelineActionTypes from './DealPipeline/ActionTypes';
import DealPipeline from './DealPipeline/DealPipeline';
import * as DealTypeActionTypes from './DealType/ActionTypes';
import DealType from './DealType/DealType';

const initialState = {
    currentFilter: '',
    searchResults: undefined,
    allKnownResults: [Deal.blank()],
    pendingRequest: undefined,
    totalAvailableResults: undefined,
    allLoading: false,
    moreLoading: false,
    resultsLoaded: false,

    selectedId: undefined,
    idToLoad: undefined,
    error: undefined,
};

function select(allKnownResults, id) {
    return Object.assign(Deal.blank(), Deal.select(allKnownResults, id));
}

function initialiseResults(incomingResults) {
    return incomingResults.map((result) => result.transitionState(EntityState.LOADED));
}

function mergeResults(existingResults, newResults) {
    if (existingResults === undefined) return newResults;
    return existingResults
        .filter((existingResult) => {
            return newResults.map((newResult) => newResult.id).indexOf(existingResult.id) === -1;
        })
        .map((existingResult) => existingResult.clone())
        .concat(newResults);
}

function replaceResult(allKnownResults, incomingResult) {
    if (allKnownResults === undefined) return undefined;
    const matches = (result) => result.id === incomingResult.id;
    return allKnownResults.map((result) => {
        const updatedResult = result.clone();
        return matches(result) ? incomingResult.clone() : updatedResult;
    });
}

function replaceOrAddResult(allKnownResults, incomingResult) {
    if (allKnownResults === undefined) allKnownResults = [];
    const matches = (result) => result.id === incomingResult.id;
    const newKnownResults = replaceResult(allKnownResults, incomingResult);
    if (allKnownResults.find(matches) === undefined) newKnownResults.push(incomingResult.clone());
    return newKnownResults;
}

function removeResult(allKnownResults, resultToRemove) {
    if (!resultToRemove) return allKnownResults;
    if (!allKnownResults) return allKnownResults;
    return allKnownResults.filter((result) => result.id !== resultToRemove.id);
}

function removeResults(allKnownResults, resultsToRemove) {
    if (!resultsToRemove) return allKnownResults;
    if (!allKnownResults) return allKnownResults;
    return allKnownResults.filter((result) => resultsToRemove.indexOf(result.id) === -1);
}

function adjustValues(deal) {
    if (!deal.currentStage || deal.currentStage.stageState !== DealStageState.CLOSED_LOST) {
        deal.dealLostReason = undefined;
    }

    if (deal.relatesTo === 'contact' && deal.relatesToContact) {
        // Ensure relatesToOrganisation is not replaced with less detailed version
        // from relatesToContact if already populated from API
        if (
            deal.relatesToOrganisation &&
            deal.relatesToContact.organisation &&
            deal.relatesToOrganisation.id !== deal.relatesToContact.organisation.id
        ) {
            deal.relatesToOrganisation = deal.relatesToContact.organisation;
        }
    } else if (deal.relatesTo === 'organisation') {
        deal.relatesToContact = undefined;
    }

    return deal;
}

export default function (state, action) {
    if (state === undefined) {
        state = initialState;
    }

    const selectedEntity = select(state.allKnownResults, state.selectedId);

    switch (action.type) {
        case REHYDRATE: {
            const reducerNameInRoot = 'deal';
            if (!PersistenceKey.checkActionForBranch(action, reducerNameInRoot)) return state;
            // Reducer branches persisted separately from root deserialize to an un-nested payload
            const serializedState = action.payload;
            // Only rehydrate certain values
            return Object.assign({}, state, {
                currentFilter: serializedState.currentFilter,
            });
        }

        case ActionTypes.DEALS_LOADED: {
            EventBusInstance.publish({
                topic: 'update-datagrid-rows',
                message: false,
                target: 'deals-data-grid-board-loading',
            });
            if (!action.payload.deals) return Object.assign({}, state, { resultsLoaded: true });
            const incomingResults = initialiseResults(action.payload.deals.items);
            return Object.assign({}, state, {
                searchResults: incomingResults,
                allKnownResults: mergeResults(state.allKnownResults, incomingResults),
                totalAvailableResults: action.payload.deals.total,
                allLoading: false,
                resultsLoaded: true,
                error: undefined,
            });
        }
        case ActionTypes.DEALS_UPDATE_FILTER: {
            if (state.pendingRequest) HttpRequestService.cancel(state.pendingRequest);
            return Object.assign({}, state, {
                allLoading: true,
                moreLoading: false,
                currentFilter: action.payload.textFilter,
                pendingRequest: action.payload.requestToken,
            });
        }
        case ActionTypes.DEALS_MORE_LOADING: {
            return Object.assign({}, state, {
                moreLoading: true,
            });
        }
        case ActionTypes.DEALS_MORE_LOADED: {
            const incomingResults = initialiseResults(action.payload.deals.items);
            return Object.assign({}, state, {
                moreLoading: false,
                searchResults: mergeResults(state.searchResults, incomingResults),
                allKnownResults: mergeResults(state.allKnownResults, incomingResults),
                error: undefined,
            });
        }
        case ActionTypes.DEALS_LOADING_CANCELLED: {
            return state;
        }
        case ActionTypes.DEALS_IDS_LOADED: {
            const incomingResults = initialiseResults(action.payload.deals.items);
            return Object.assign({}, state, {
                moreLoading: false,
                searchResults: mergeResults(state.searchResults, incomingResults),
                allKnownResults: mergeResults(state.allKnownResults, incomingResults),
            });
        }
        case ActionTypes.DEALS_LOADING_FAILED: {
            return Object.assign({}, state, {
                allLoading: false,
                moreLoading: false,
                error: action.payload.error,
            });
        }
        case ActionTypes.DEALS_UPDATING_PIPELINE: {
            const incomingResults = action.payload.deals.map((result) =>
                result.transitionState(EntityState.MODIFIED).transitionState(EntityState.SAVING),
            );
            return Object.assign({}, state, {
                searchResults: mergeResults(state.searchResults, incomingResults),
                allKnownResults: mergeResults(state.allKnownResults, incomingResults),
            });
        }
        case ActionTypes.DEALS_UPDATED_PIPELINE: {
            const incomingResults = initialiseResults(action.payload.deals);
            return Object.assign({}, state, {
                searchResults: mergeResults(state.searchResults, incomingResults),
                allKnownResults: mergeResults(state.allKnownResults, incomingResults),
            });
        }
        case ActionTypes.DEALS_UPDATING_PIPELINE_FAILED: {
            return Object.assign({}, state, {
                error: action.payload.error,
            });
        }
        case ActionTypes.DEALS_DELETING: {
            const incomingResults = action.payload.deals.map((result) => result.transitionState(EntityState.DELETING));
            return Object.assign({}, state, {
                searchResults: mergeResults(state.searchResults, incomingResults),
                allKnownResults: mergeResults(state.allKnownResults, incomingResults),
            });
        }
        case ActionTypes.DEALS_DELETED: {
            const deletedResultIds = action.payload.ids;
            return Object.assign({}, state, {
                searchResults: removeResults(state.searchResults, deletedResultIds),
                allKnownResults: removeResults(state.allKnownResults, deletedResultIds),
                totalAvailableResults: deletedResultIds
                    ? state.totalAvailableResults - deletedResultIds.length
                    : state.totalAvailableResults,
            });
        }
        case ActionTypes.DEALS_DELETING_FAILED: {
            return Object.assign({}, state, {
                error: action.payload.error,
            });
        }
        case ActionTypes.DEAL_NEW: {
            const blankEntity = Deal.blank();
            if (action.payload.interactableType === 'contacts') {
                blankEntity.relatesTo = 'contact';
                blankEntity.relatesToContact = Object.assign(Contact.blank(), { id: action.payload.interactableId });
            }
            if (action.payload.interactableType === 'organisation') {
                blankEntity.relatesTo = 'organisation';
                blankEntity.relatesToOrganisation = Object.assign(Organisation.blank(), {
                    id: action.payload.interactableId,
                });
            }
            if (action.payload.loggedInUser) {
                if (action.payload.assignToLoggedInUser) blankEntity.ownedBy = action.payload.loggedInUser;
            }
            if (action.payload.defaultDealType) blankEntity.dealType = action.payload.defaultDealType;
            if (action.payload.defaultDealPipeline) {
                blankEntity.dealPipeline = action.payload.defaultDealPipeline;

                if (action.payload.defaultDealPipeline.stages) {
                    const defaultPipelineStage = action.payload.defaultDealPipeline.stages.find(
                        (pipelineStage) => pipelineStage.isDefault,
                    );
                    blankEntity.currentStage = defaultPipelineStage
                        ? defaultPipelineStage
                        : action.payload.defaultDealPipeline.stages[0];
                }

                if (action.payload.defaultDealPipeline.defaultDealLengthDays) {
                    blankEntity.closeDate = new DateHelper().daysAhead(
                        action.payload.defaultDealPipeline.defaultDealLengthDays,
                    );
                }
            }

            blankEntity.transitionState(EntityState.READY);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, blankEntity),
                selectedId: blankEntity.id,
            });
        }
        case ActionTypes.DEAL_UPDATE_FIELD: {
            const targetEntity = select(state.allKnownResults, action.payload.id);
            targetEntity[action.payload.fieldName] = action.payload.value;
            targetEntity.transitionState(EntityState.MODIFIED);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, adjustValues(targetEntity)),
            });
        }
        case ActionTypes.DEAL_SAVING: {
            const savingEntity = action.payload.entity.clone();
            savingEntity.transitionState(EntityState.SAVING);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, savingEntity),
                searchResults: replaceResult(state.searchResults, savingEntity),
            });
        }
        case ActionTypes.DEAL_SAVED: {
            try {
                const savedEntity = Deal.fromApi(action.payload.data);
                savedEntity.transitionState(EntityState.SAVED);

                return Object.assign({}, state, {
                    allKnownResults: replaceOrAddResult(state.allKnownResults, savedEntity),
                    searchResults: replaceResult(state.searchResults, savedEntity),
                    selectedId: savedEntity.id,
                });
            } catch (e) {
                const targetEntity = select(state.allKnownResults, action.payload.id);
                targetEntity.transitionState(EntityState.ERROR, 'Unable to initialise deal object: ' + e.message);

                return Object.assign({}, state, {
                    allKnownResults: replaceOrAddResult(state.allKnownResults, targetEntity),
                });
            }
        }
        case ActionTypes.DEAL_SAVING_FAILED: {
            const targetEntity = select(state.allKnownResults, action.payload.id);
            targetEntity.transitionState(EntityState.ERROR, action.payload.error);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, targetEntity),
                searchResults: replaceResult(state.searchResults, targetEntity),
            });
        }
        case ActionTypes.DEAL_SELECT: {
            const targetEntity = select(state.allKnownResults, action.payload.id);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, targetEntity),
                selectedId: action.payload.id,
                idToLoad: targetEntity.id ? undefined : action.payload.id,
                error: undefined,
            });
        }
        case ActionTypes.DEAL_LOADING: {
            const targetEntity = select(state.allKnownResults, action.payload.id);
            targetEntity.transitionState(EntityState.LOADING);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, targetEntity),
                idToLoad: undefined,
            });
        }
        case ActionTypes.DEAL_LOADED: {
            try {
                const loadedEntity = Deal.fromApi(action.payload.data);
                loadedEntity.transitionState(EntityState.LOADED);

                return Object.assign({}, state, {
                    allKnownResults: replaceOrAddResult(state.allKnownResults, loadedEntity),
                    searchResults: replaceResult(state.searchResults, loadedEntity),
                    idToLoad: undefined,
                    error: undefined,
                });
            } catch (e) {
                return Object.assign({}, state, {
                    idToLoad: undefined,
                    error: 'Unable to initialise deal object: ' + e.message,
                });
            }
        }
        case ActionTypes.DEAL_LOADING_FAILED: {
            return Object.assign({}, state, {
                idToLoad: undefined,
                error: action.payload.error,
            });
        }
        case ActionTypes.DEAL_INVALIDATED: {
            const targetEntity = select(state.allKnownResults, action.payload.id);
            targetEntity.transitionState(EntityState.INVALIDATED);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, targetEntity),
                idToLoad: targetEntity.id, // Reload invalidated entity from server
            });
        }
        case ActionTypes.DEAL_DELETING: {
            const targetEntity = select(state.allKnownResults, action.payload.id);
            targetEntity.transitionState(EntityState.DELETING);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, targetEntity),
                searchResults: replaceResult(state.searchResults, targetEntity),
            });
        }
        case ActionTypes.DEAL_DELETED: {
            const targetEntity = select(state.allKnownResults, action.payload.id);

            return Object.assign({}, state, {
                allKnownResults: removeResult(state.allKnownResults, targetEntity),
                searchResults: removeResult(state.searchResults, targetEntity),
                selectedId: undefined,
            });
        }
        case ActionTypes.DEAL_LOADING_LINKS: {
            const targetEntity = select(state.allKnownResults, action.payload.id);
            targetEntity['loadingLinks'] = true;
            targetEntity['quotes'] = undefined;
            targetEntity['sales'] = undefined;
            targetEntity['tasks'] = undefined;

            return Object.assign({}, state, {
                allKnownResults: replaceResult(state.allKnownResults, adjustValues(targetEntity)),
                searchResults: replaceResult(state.searchResults, adjustValues(targetEntity)),
            });
        }
        case ActionTypes.DEAL_LOADED_LINKS: {
            const targetEntity = select(state.allKnownResults, action.payload.id);
            targetEntity['loadingLinks'] = false;
            targetEntity['quotes'] = action.payload.quotes ? action.payload.quotes : [];
            targetEntity['sales'] = action.payload.sales ? action.payload.sales : [];
            targetEntity['tasks'] = action.payload.tasks ? action.payload.tasks : [];

            return Object.assign({}, state, {
                allKnownResults: replaceResult(state.allKnownResults, adjustValues(targetEntity)),
                searchResults: replaceResult(state.searchResults, adjustValues(targetEntity)),
            });
        }
        case ActionTypes.DEAL_LOADING_LINKS_FAILED: {
            const targetEntity = select(state.allKnownResults, action.payload.id);
            targetEntity['loadingLinks'] = false;
            targetEntity['quotes'] = [];
            targetEntity['sales'] = [];
            targetEntity['tasks'] = [];
            targetEntity.transitionState(EntityState.ERROR, action.payload.error);

            return Object.assign({}, state, {
                allKnownResults: replaceResult(state.allKnownResults, adjustValues(targetEntity)),
                searchResults: replaceResult(state.searchResults, adjustValues(targetEntity)),
            });
        }
        case ClientActionTypes.CLIENT_LOADED: {
            // Set assignedBy to loggedInUser if fresh deal
            if (selectedEntity && !selectedEntity.id && action.payload.client) {
                try {
                    selectedEntity['assignedBy'] = Client.fromApi(action.payload.client).loggedInUser;
                    selectedEntity.transitionState(EntityState.MODIFIED);

                    return Object.assign({}, state, {
                        allKnownResults: replaceOrAddResult(state.allKnownResults, adjustValues(selectedEntity)),
                    });
                } catch (e) {
                    return state;
                }
            } else return state;
        }
        case DealTypeActionTypes.DEALTYPES_LOADED: {
            // Set dealType to default deal type if fresh deal
            if (selectedEntity && !selectedEntity.id && action.payload.dealTypes) {
                try {
                    // Note: the server always gives us the default (or equivalent) deal type first
                    selectedEntity['dealType'] = DealType.fromApi(action.payload.dealTypes[0]);
                    selectedEntity.transitionState(EntityState.MODIFIED);

                    return Object.assign({}, state, {
                        allKnownResults: replaceOrAddResult(state.allKnownResults, adjustValues(selectedEntity)),
                    });
                } catch (e) {
                    return state;
                }
            } else return state;
        }
        case DealPipelineActionTypes.DEALPIPELINES_LOADED: {
            // Set dealPipeline and currentStage to default deal pipeline if fresh deal
            if (selectedEntity && !selectedEntity.id && action.payload.dealPipelines) {
                try {
                    // Note: the server always gives us the default (or equivalent) deal pipeline first
                    selectedEntity['dealPipeline'] = DealPipeline.fromApi(action.payload.dealPipelines[0]);

                    if (selectedEntity['dealPipeline'].stages) {
                        const defaultPipelineStage = selectedEntity['dealPipeline'].stages.find(
                            (pipelineStage) => pipelineStage.isDefault,
                        );
                        selectedEntity['currentStage'] = defaultPipelineStage
                            ? defaultPipelineStage
                            : selectedEntity['dealPipeline'].stages[0];
                    }

                    if (selectedEntity['dealPipeline'].defaultDealLengthDays) {
                        selectedEntity['closeDate'] = new DateHelper().daysAhead(
                            selectedEntity['dealPipeline'].defaultDealLengthDays,
                        );
                    }

                    selectedEntity.transitionState(EntityState.MODIFIED);

                    return Object.assign({}, state, {
                        allKnownResults: replaceOrAddResult(state.allKnownResults, adjustValues(selectedEntity)),
                    });
                } catch (e) {
                    return state;
                }
            } else return state;
        }
        default: {
            return state;
        }
    }
}
