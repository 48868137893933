'use strict';

import HttpRequestService from "../../../Services/HttpRequestService/HttpRequestService";

export default class DealTypeRepository {

    static all() {
        return new Promise(function(resolve, reject) {
            new HttpRequestService()
                .get('deals/ajax/get_deal_types')
                .then(response => {
                    if (response.status !== 200) return reject('Unable to load deal types, endpoint not responding. ERR-REACT-DT-07');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.message);
                })
        });
    }

    static save(dealTypeEntity) {
        return new Promise(function(resolve, reject) {
            if (typeof dealTypeEntity.forApi !== 'function') return reject('Unable to save deal type, invalid deal type entity provided. ERR-REACT-DT-06');
            new HttpRequestService()
                .post('settings/deal_types/save', dealTypeEntity.forApi())
                .then(response => {
                    if (!response) return reject('Invalid response returned. ERR-REACT-DT-01');
                    if (response.status !== 200) return reject('Unable to save deal type, endpoint not responding. ERR-REACT-DT-02');
                    if (!response.data.data) return reject('Invalid response returned. ERR-REACT-DT-05');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve(response.data.data);
                })
                .catch(error => reject(error.message));
        });
    }

    static find(dealTypeId) {
        return new Promise(function(resolve, reject) {
            new HttpRequestService()
                .get('settings/deal_types/get/' + dealTypeId)
                .then(response => {
                    if (!response) return reject('Invalid response returned. ERR-REACT-DT-03');
                    if (response.status !== 200) return reject('Unable to load deal type, endpoint not responding. ERR-REACT-DT-04');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve(response.data.data);
                })
                .catch(error => reject(error.message));
        })
    }
    
    static delete(dealTypeId) {
        return new Promise(function(resolve, reject) {
            if (dealTypeId < 1) return reject('Unable to delete deal type, invalid ID provided. ERR-REACT-DT-11');

            const formData = new FormData();
            formData.append('id', dealTypeId);

            new HttpRequestService()
                .post('settings/deal_types/delete/', formData)
                .then(response => {
                    if (!response) return reject('Invalid response returned. ERR-REACT-DT-09');
                    if (response.status !== 200) return reject('Unable to delete deal type, endpoint not responding. ERR-REACT-DT-10');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve();
                })
                .catch(error => reject(error.message))
        });
    }

}