'use strict';

import * as ActionTypes from "./ActionTypes";
import SavedSearchRepository from "./SavedSearchRepository";
import HttpRequestService from "../../Services/HttpRequestService/HttpRequestService";
import SavedSearch from "./SavedSearch";

export function filterSavedSearches(filter, entityName) {
    return (dispatch) => {
        const token = new HttpRequestService().generateToken();
        dispatch(updateCurrentFilter(filter, token));
        SavedSearchRepository.filter(filter, token, 0, entityName)
            .then(response => dispatch(response ? savedSearchesLoaded(response) : savedSearchesLoadingCancelled()))
            .catch(e => dispatch(savedSearchesLoadingFailed(e.message ? e.message : e)));
    }
}

export function loadMore(offset, filter, entityName) {
    return (dispatch) => {
        dispatch(savedSearchMoreLoading());
        const token = new HttpRequestService().generateToken();
        SavedSearchRepository.filter(filter, token, offset, entityName)
            .then(response => dispatch(response ? savedSearchesMoreLoaded(response) : savedSearchesLoadingCancelled()))
            .catch(e => dispatch(savedSearchesLoadingFailed(e.message ? e.message : e)));
    }
}

export function saveSearch(savedSearchEntity, entityName) {
    return (dispatch) => {
        dispatch(savingSearch());
        SavedSearchRepository.save(savedSearchEntity, entityName)
            .then(response => {
                dispatch(searchSaved(response));
                dispatch(filterSavedSearches('', entityName));
            })
            .catch(e => dispatch(searchSaveFailed(e.message ? e.message : e)));
    }
}

export function loadSearch(id) {
    return {
        type: ActionTypes.SAVED_SEARCHES_LOAD_SEARCH,
        payload: {
            id: id
        }
    }
}

export function deleteSavedSearch(id, entityName) {
    return (dispatch) => {
        dispatch(deletingItem(id));
        SavedSearchRepository.delete(id, entityName)
            .then(() => dispatch(itemDeleted(id)))
            .catch(e => dispatch(deleteItemFailed(e.message)));
    }
}

export function clearSearch() {
    return {
        type: ActionTypes.SAVED_SEARCHES_CLEAR_SEARCH
    }
}

function updateCurrentFilter(filter, token) {
    return {
        type: ActionTypes.SAVED_SEARCHES_UPDATE_FILTER,
        payload: {
            filter: filter,
            requestToken: token
        }
    }
}

function savedSearchesLoaded(savedSearches) {
    return {
        type: ActionTypes.SAVED_SEARCHES_LOADED,
        payload: {
            savedSearches: savedSearches
                ? {
                    ...savedSearches,
                    searches: savedSearches.searches.reduce((result, savedSearch) => {
                        try {
                            result.push(SavedSearch.fromApi(savedSearch));
                        } catch (e) {
                        }
                        return result;
                    }, [])
                }
                : savedSearches
        }
    }
}

function savedSearchMoreLoading() {
    return {
        type: ActionTypes.SAVED_SEARCHES_MORE_LOADING
    }
}

function savedSearchesMoreLoaded(additionalSavedSearches) {
    return {
        type: ActionTypes.SAVED_SEARCHES_MORE_LOADED,
        payload: {
            additionalSavedSearches: additionalSavedSearches
                ? {
                    ...additionalSavedSearches,
                    searches: additionalSavedSearches.searches.reduce((result, savedSearch) => {
                        try {
                            result.push(SavedSearch.fromApi(savedSearch));
                        } catch (e) {
                        }
                        return result;
                    }, [])
                }
                : additionalSavedSearches
        }
    }
}

function savedSearchesLoadingCancelled() {
    return {
        type: ActionTypes.SAVED_SEARCHES_LOADING_CANCELLED
    }
}

function savedSearchesLoadingFailed(error) {
    return {
        type: ActionTypes.SAVED_SEARCHES_LOADING_FAILED,
        payload: {
            error: error
        }
    }
}

function savingSearch() {
    return {
        type: ActionTypes.SAVED_SEARCHES_SAVING
    }
}

function searchSaved(savedSearch) {
    return {
        type: ActionTypes.SAVED_SEARCHES_SAVED,
        payload: {
            savedSearch: savedSearch
        }
    }
}

function searchSaveFailed(error) {
    return {
        type: ActionTypes.SAVED_SEARCHES_SAVE_FAILED,
        payload: {
            error: error
        }
    }
}

function deletingItem(id) {
    return {
        type: ActionTypes.SAVED_SEARCHES_DELETING_ITEM,
        payload: {
            id: id
        }
    }
}

function deleteItemFailed(error) {
    return {
        type: ActionTypes.SAVED_SEARCHES_DELETING_ITEM_FAILED,
        payload: {
            error: error
        }
    }
}

function itemDeleted(id) {
    return {
        type: ActionTypes.SAVED_SEARCHES_ITEM_DELETED,
        payload: {
            id: id
        }
    }
}