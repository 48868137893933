import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';

interface Props {
    content: string | JSX.Element | ((...args: any[]) => any);
    close: () => void;
}

const Header: FunctionComponent<Props> = (props: Props) => {
    const content = () => {
        return <h4 className="modal-title">{typeof props.content === 'function' ? props.content() : props.content}</h4>;
    };

    return (
        <div className="modal-header">
            <FontAwesomeIcon icon={faTimes} className="close" onClick={props.close} />
            {content()}
        </div>
    );
};

export default Header;
