import { filter as filterTypes, GenderOfPupils, SearchQuery, SortOrder } from '@sprint/sprint-react-components';
import _ from 'lodash';
import { FilterExtendedColumn } from '../../CampusDataGrid';
import ApiFilterOptionsService from './ApiFilterOptionsService';

const SyncedDataFilterableFieldsEDIN: FilterExtendedColumn[] = [
    {
        key: 'name',
        name: 'Establishment Name',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'school_type',
        name: 'Type',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'country',
        name: 'Country',
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldAsyncOptions: (filter: string, page?: number) => {
            const PAGE_SIZE = 100;
            const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'country', SortOrder.ASC, filter);
            query.setExtendedParameters({
                fieldname: 'country',
            });
            const optionsService = new ApiFilterOptionsService();
            return optionsService.getFilterOptions(query);
        },
    },
    {
        key: 'phone',
        name: 'Telephone',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'website',
        name: 'Website URL',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'school_info.age_range_lower',
        name: 'Minimum Age',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'school_info.age_range_upper',
        name: 'Maximum Age',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'school_info.number_on_roll',
        name: 'On Roll',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'pupils.gender_of_pupils',
        name: 'Gender of Pupils',
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldOptions: () =>
            _.map(Object.values(GenderOfPupils), (gender) => ({
                value: gender,
                name: gender,
            })),
    },
    {
        key: 'id',
        name: 'ID',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'address1',
        name: 'Address 1',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'address2',
        name: 'Address 2',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'address3',
        name: 'Address 3',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'address4',
        name: 'Address 4',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'postcode',
        name: 'Full Postal Code',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'region',
        name: 'Region',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'school_info.timezone',
        name: 'Timezone',
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldAsyncOptions: (filter: string, page?: number) => {
            const PAGE_SIZE = 100;
            const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'edin_timezone', SortOrder.ASC, filter);
            query.setExtendedParameters({
                fieldname: 'edin_timezone',
            });
            const optionsService = new ApiFilterOptionsService();
            return optionsService.getFilterOptions(query);
        },
    },
    {
        key: 'school_info.associations',
        name: 'Associations',
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldAsyncOptions: (filter: string, page?: number) => {
            const PAGE_SIZE = 100;
            const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'edin_associations', SortOrder.ASC, filter);
            query.setExtendedParameters({
                fieldname: 'edin_associations',
            });
            const optionsService = new ApiFilterOptionsService();
            return optionsService.getFilterOptions(query);
        },
    },
    {
        key: 'school_info.curriculums',
        name: 'Curriculums',
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldAsyncOptions: (filter: string, page?: number) => {
            const PAGE_SIZE = 100;
            const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'edin_curriculums', SortOrder.ASC, filter);
            query.setExtendedParameters({
                fieldname: 'edin_curriculums',
            });
            const optionsService = new ApiFilterOptionsService();
            return optionsService.getFilterOptions(query);
        },
    },
    {
        key: 'school_info.groups',
        name: 'Groups',
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldAsyncOptions: (filter: string, page?: number) => {
            const PAGE_SIZE = 100;
            const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'edin_groups', SortOrder.ASC, filter);
            query.setExtendedParameters({
                fieldname: 'edin_groups',
            });
            const optionsService = new ApiFilterOptionsService();
            return optionsService.getFilterOptions(query);
        },
    },
    {
        key: 'school_info.languages',
        name: 'Languages',
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldAsyncOptions: (filter: string, page?: number) => {
            const PAGE_SIZE = 100;
            const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'edin_languages', SortOrder.ASC, filter);
            query.setExtendedParameters({
                fieldname: 'edin_languages',
            });
            const optionsService = new ApiFilterOptionsService();
            return optionsService.getFilterOptions(query);
        },
    },
];

export { SyncedDataFilterableFieldsEDIN };
