export const OPEN = 'Open';
export const CLOSED_WON = 'Closed (Won)';
export const CLOSED_LOST = 'Closed (Lost)';

export function allOptions() {
    return [
        OPEN, CLOSED_WON, CLOSED_LOST
    ];
}

export function defaultOption () {
    return OPEN;
}

export function forSelect() {
    return allOptions().map(option => ({
        id: option,
        label: option
    }));
}