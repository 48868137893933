import React, { ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import UserType from '../../Applications/DataGrids/Models/UserType';
import TagsEditDropdown from '../ClientTags/TagsEditDropdown';

interface Props {
    name: ReactNode;
    value: UserType | null;
    onChange: (newValue: number[] | null) => void;
    className?: string;
}

const FormTagsEditor = (props: Props) => {
    const changeValue = (val: any) => {
        props.onChange(val.map((v: { value: number; label: any }) => v.value));
    };

    return (
        <Form.Group className={props.className}>
            <TagsEditDropdown onChange={changeValue} />
        </Form.Group>
    );
};

export default FormTagsEditor;
