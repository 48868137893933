import * as ActionTypes from "./ActionTypes";
import Client from './Client.js';

const initialState = {
    client: undefined,
    error: undefined
};

export default function (state, action) {
    let error = undefined;

    if (state === undefined) {
        state = initialState;
    }

    switch (action.type) {
        case ActionTypes.CLIENT_LOADED:
            try {
                return Object.assign({}, state, {
                    client: Client.fromApi(action.payload.client)
                });
            } catch (e) {
                error = 'Unable to initialise client object: ' + e.message;
            }
            break;
        case ActionTypes.CLIENT_LOADING_FAILED:
            error = 'Unable to load client: ' + action.payload.error;
            break;
        default:
            return state;
    }

    if (error) {
        return {
            client: undefined,
            error: error
        };
    } else return state;
}