'use strict';

import axios from 'axios';

// default is not to time out ever; use caution when tuning this and make sure to test in many different environments!
const DEFAULT_TIMEOUT_MS = 0;

export default class HttpRequestService {

    constructor(options = {}) {
        this.endpointPrefix = '/';
        this.source = undefined;
        this.timeoutMs = options.timeoutMs || DEFAULT_TIMEOUT_MS;
    }

    /**
     * @param endpoint
     * @param cancelToken
     * @returns {Promise<any>}
     */
    get(endpoint, cancelToken) {
        const settings = {};
        if (cancelToken) {
            settings.cancelToken = cancelToken.token;
        }
        settings.timeout = this.timeoutMs;
        return axios.get(this.endpointPrefix + endpoint, settings).catch(function (thrown) {
            if (axios.isCancel(thrown)) return { status: 499, statusText: thrown.message, data: undefined};
            else throw thrown;
        });
    }

    /**
     * @param endpoint
     * @param formData {FormData}
     * @param cancelToken
     * @returns {Promise<any>}
     */
    post(endpoint, formData, cancelToken) {
        const settings = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };
        settings.timeout = this.timeoutMs;

        if (cancelToken) {
            settings.cancelToken = cancelToken.token;
        }

        return axios.post(this.endpointPrefix + endpoint, formData, settings).catch(function (thrown) {
            if (axios.isCancel(thrown)) return { status: 499, statusText: thrown.message, data: undefined};
            else throw thrown;
        });
    }



    generateToken() {
        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
        return this.source;
    }

    static cancel(token) {
        token.cancel("Cancelled by user");
    }
}