import { filter as filterTypes, SearchQuery, SortOrder } from '@sprint/sprint-react-components';
import { FilterExtendedColumn } from '../../CampusDataGrid';
import ApiFilterOptionsService from './ApiFilterOptionsService';
const SyncedDataFilterableFieldsEDUS: FilterExtendedColumn[] = [
    {
        key: 'name',
        name: 'Establishment Name',
        description: 'Name of the establishment',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'school_info.entity',
        name: 'Entity',
        description: 'Whether the establishment is a Public School, Private School, or District (LEA)',
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldAsyncOptions: (filter: string, page?: number) => {
            const PAGE_SIZE = 100;
            const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'edus_entity', SortOrder.ASC, filter);
            query.setExtendedParameters({
                fieldname: 'edus_entity',
            });
            const optionsService = new ApiFilterOptionsService();
            return optionsService.getFilterOptions(query);
        },
    },
    {
        key: 'school_info.level',
        name: 'Level',
        description: 'Student level taught at the establishment',
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldAsyncOptions: (filter: string, page?: number) => {
            const PAGE_SIZE = 100;
            const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'edus_level', SortOrder.ASC, filter);
            query.setExtendedParameters({
                fieldname: 'edus_level',
            });
            const optionsService = new ApiFilterOptionsService();
            return optionsService.getFilterOptions(query);
        },
    },
    {
        key: 'address4',
        name: 'City',
        description: 'City in which the  establishment is located',
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldAsyncOptions: (filter: string, page?: number) => {
            const PAGE_SIZE = 100;
            const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'edus_city', SortOrder.ASC, filter);
            query.setExtendedParameters({
                fieldname: 'edus_city',
            });
            const optionsService = new ApiFilterOptionsService();
            return optionsService.getFilterOptions(query);
        },
    },
    {
        key: 'region',
        name: 'State',
        description: 'State in which the establishment is located',
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldAsyncOptions: (filter: string, page?: number) => {
            const PAGE_SIZE = 100;
            const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'edus_state', SortOrder.ASC, filter);
            query.setExtendedParameters({
                fieldname: 'edus_state',
            });
            const optionsService = new ApiFilterOptionsService();
            return optionsService.getFilterOptions(query);
        },
    },
    {
        key: 'email',
        name: 'Email',
        description: 'Generic email of the establishment',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'phone',
        name: 'Telephone',
        description: 'Telephone number of the establishment',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'website',
        name: 'Website URL',
        description: 'Website of the establishment',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'school_info.grades.grades_taught_lower',
        name: 'Lowest Grade Taught',
        description: 'Lowest grade offered by the establishment',
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldAsyncOptions: (filter: string, page?: number) => {
            const PAGE_SIZE = 100;
            const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'edus_grades_taught_lower', SortOrder.ASC, filter);
            query.setExtendedParameters({
                fieldname: 'edus_grades_taught_lower',
            });
            const optionsService = new ApiFilterOptionsService();
            return optionsService.getFilterOptions(query);
        },
    },
    {
        key: 'school_info.grades.grades_taught_upper',
        name: 'Highest Grade Taught',
        description: 'Highest grade offered by the establishment',
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldAsyncOptions: (filter: string, page?: number) => {
            const PAGE_SIZE = 100;
            const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'edus_grades_taught_upper', SortOrder.ASC, filter);
            query.setExtendedParameters({
                fieldname: 'edus_grades_taught_upper',
            });
            const optionsService = new ApiFilterOptionsService();
            return optionsService.getFilterOptions(query);
        },
    },
    {
        key: 'school_info.number_on_roll',
        name: 'On Roll',
        description: 'Number of pupils/students on roll at the establishment',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'id',
        name: 'ID',
        description: "Campus's ID for the establishment",
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'address1',
        name: 'Address 1',
        description: "Address line 1 of the establishment's location",
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'address2',
        name: 'Address 2',
        description: "Address line 2 of the establishment's location",
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'address3',
        name: 'Address 3',
        description: "Address line 3 of the establishment's location",
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'school_info.secondary_location_zip_code',
        name: 'Secondary Zip Code',
        description: "Secondary ZIP code of the establishment's location",
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'school_info.establishment_state',
        name: 'Registered State',
        description: 'State the Establishment is registered in',
        filterFieldType: filterTypes.FieldType.STRING,
    },

    {
        key: 'postcode',
        name: 'Zip Code',
        description: "Full four or five digit zip code of the establishment's location",
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'school_info.mailing_address.address1',
        name: 'Mailing Address 1',
        description: "Address line 1 of the establishment's mailing address",
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'school_info.mailing_address.address2',
        name: 'Mailing Address 2',
        description: "Address line 2 of the establishment's mailing address",
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'school_info.mailing_address.address3',
        name: 'Mailing Address 3',
        description: "Address line 3 of the establishment's mailing address",
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'school_info.mailing_address.address4',
        name: 'Mailing City',
        description: 'Mailing city of the establishment',
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldAsyncOptions: (filter: string, page?: number) => {
            const PAGE_SIZE = 100;
            const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'edus_mailing_city', SortOrder.ASC, filter);
            query.setExtendedParameters({
                fieldname: 'edus_mailing_city',
            });
            const optionsService = new ApiFilterOptionsService();
            return optionsService.getFilterOptions(query);
        },
    },
    {
        key: 'school_info.mailing_address.postcode',
        name: 'Mailing ZIP Code',
        description: "Full four or five digit postcode of the establishment's mailing address",
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'school_info.mailing_address.region',
        name: 'Mailing State',
        description: 'Mailing state of the establishment',
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldAsyncOptions: (filter: string, page?: number) => {
            const PAGE_SIZE = 100;
            const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'edus_mailing_state', SortOrder.ASC, filter);
            query.setExtendedParameters({
                fieldname: 'edus_mailing_state',
            });
            const optionsService = new ApiFilterOptionsService();
            return optionsService.getFilterOptions(query);
        },
    },
    {
        key: 'school_info.secondary_mailing_zip_code',
        name: 'Mailing Secondary ZIP Code',
        description: "Establishment's secondary mailing ZIP code",
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'school_info.school_type',
        name: 'Type',
        description: 'Type of establishment',
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldAsyncOptions: (filter: string, page?: number) => {
            const PAGE_SIZE = 100;
            const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'edus_school_type', SortOrder.ASC, filter);
            query.setExtendedParameters({
                fieldname: 'edus_school_type',
            });
            const optionsService = new ApiFilterOptionsService();
            return optionsService.getFilterOptions(query);
        },
    },
    {
        key: 'school_info.nces_school_id',
        name: 'NCES School ID',
        description: 'National Center for Education Statistics (NCES) school identifier number',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'school_info.nces_unique_school_id',
        name: 'NCES Unique School ID',
        description:
            'Abbreviated National Center for Education Statistics (NCES) school identifier number (only public schools have values in this filter)',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'school_info.state_school_id',
        name: 'State School ID',
        description: 'State school identifier number',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'lea.name',
        name: 'District of School',
        description: 'District/LEA the school is part of (only public schools have values in this filter)',
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldAsyncOptions: (filter: string, page?: number) => {
            const PAGE_SIZE = 100;
            const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'edus_lea', SortOrder.ASC, filter);
            query.setExtendedParameters({
                fieldname: 'edus_lea',
            });
            const optionsService = new ApiFilterOptionsService();
            return optionsService.getFilterOptions(query);
        },
    },
    {
        key: 'workforce.number_of_teachers',
        name: 'Number of Teachers',
        description: 'Number of full time teachers at the establishment',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    // Grades
    {
        key: 'school_info.grades.no_grades',
        name: 'No Grades Offered',
        description: 'Whether the establishment teaches no grades',
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    {
        key: 'school_info.grades.grade_pk_offered',
        name: 'Pre-Kindergarten Grade Offered',
        description: 'Whether the establishment offers Pre-Kindergarten Grade',
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    {
        key: 'school_info.grades.grade_kg_offered',
        name: 'Kindergarten Grade Offered',
        description: 'Whether the establishment offers Kindergarten Grade',
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    {
        key: 'school_info.grades.grade_01_offered',
        name: 'Grade 1 Offered',
        description: 'Whether the establishment offers Grade 1',
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    {
        key: 'school_info.grades.grade_02_offered',
        name: 'Grade 2 Offered',
        description: 'Whether the establishment offers Grade 2',
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    {
        key: 'school_info.grades.grade_03_offered',
        name: 'Grade 3 Offered',
        description: 'Whether the establishment offers Grade 3',
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    {
        key: 'school_info.grades.grade_04_offered',
        name: 'Grade 4 Offered',
        description: 'Whether the establishment offers Grade 4',
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    {
        key: 'school_info.grades.grade_05_offered',
        name: 'Grade 5 Offered',
        description: 'Whether the establishment offers Grade 5',
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    {
        key: 'school_info.grades.grade_06_offered',
        name: 'Grade 6 Offered',
        description: 'Whether the establishment offers Grade 6',
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    {
        key: 'school_info.grades.grade_07_offered',
        name: 'Grade 7 Offered',
        description: 'Whether the establishment offers Grade 7',
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    {
        key: 'school_info.grades.grade_08_offered',
        name: 'Grade 8 Offered',
        description: 'Whether the establishment offers Grade 8',
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    {
        key: 'school_info.grades.grade_09_offered',
        name: 'Grade 9 Offered',
        description: 'Whether the establishment offers Grade 9',
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    {
        key: 'school_info.grades.grade_10_offered',
        name: 'Grade 10 Offered',
        description: 'Whether the establishment offers Grade 10',
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    {
        key: 'school_info.grades.grade_11_offered',
        name: 'Grade 11 Offered',
        description: 'Whether the establishment offers Grade 11',
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    {
        key: 'school_info.grades.grade_12_offered',
        name: 'Grade 12 Offered',
        description: 'Whether the establishment offers Grade 12',
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    {
        key: 'school_info.grades.grade_13_offered',
        name: 'Grade 13 Offered',
        description: 'Whether the establishment offers Grade 13',
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    {
        key: 'school_info.grades.grade_ug_offered',
        name: 'UG Grade Offered',
        description: 'Whether the establishment offers UG Grade',
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    {
        key: 'school_info.grades.grade_ae_offered',
        name: 'Adult Education Offered',
        description: 'Whether the establishment offers Adult Education',
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    // End Grades
    {
        key: 'school_info.out_of_state',
        name: 'Out of State',
        description: 'Denotes if the mailing or location address is in another state',
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    {
        key: 'pupils.studends_free_lunch_qualified',
        name: 'Number of Students Free Lunch Qualified',
        description: 'Number of students/pupils who qualify for free lunch',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'pupils.studends_free_lunch_qualified_percentage',
        name: 'Percentage of Students Free Lunch Qualified',
        description: 'Percentage of students/pupils who qualify for free lunch',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'pupils.studends_reduced_price_lunch_qualified',
        name: 'Number of Students Reduced Price Lunch Qualified',
        description: 'Number of students/pupils who qualify for reduced price lunch',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'pupils.studends_reduced_price_lunch_qualified_percentage',
        name: 'Percentage of Students Reduced Price Lunch Qualified',
        description: 'Percentage of students/pupils who qualify for reduced price lunch',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'school_info.charter_school',
        name: 'Charter School',
        description: 'Denotes if the school is a Charter school',
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    {
        key: 'school_info.magnet_school',
        name: 'Magnet School',
        description: 'Denotes if the school is a Magnet school (only public schools have values in this filter)',
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    {
        key: 'school_info.charter_district',
        name: 'Charter District',
        description: 'District/LEA charter status',
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldAsyncOptions: (filter: string, page?: number) => {
            const PAGE_SIZE = 100;
            const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'edus_charter_district', SortOrder.ASC, filter);
            query.setExtendedParameters({
                fieldname: 'edus_charter_district',
            });
            const optionsService = new ApiFilterOptionsService();
            return optionsService.getFilterOptions(query);
        },
    },
    {
        key: 'school_info.title_i_status',
        name: 'Title I School Status',
        description:
            'Title I is the largest federally funded educational program that provides supplemental funds to school districts to assist schools with large concentrations of low income students to help meet their educational goals (only public schools have values in this filter)',
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldAsyncOptions: (filter: string, page?: number) => {
            const PAGE_SIZE = 100;
            const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'edus_title_i_status', SortOrder.ASC, filter);
            query.setExtendedParameters({
                fieldname: 'edus_title_i_status',
            });
            const optionsService = new ApiFilterOptionsService();
            return optionsService.getFilterOptions(query);
        },
    },
    {
        key: 'school_info.participates_in_nslp',
        name: 'Participates in NSLPA',
        description:
            "Denotes whether the establishment participates in the The National School Lunch Program (NSLP) and if it does, whether it participates under the Community Eligibility Option (CEO) or one of the three 'Provisions' (only public schools have values in this filter)",
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldAsyncOptions: (filter: string, page?: number) => {
            const PAGE_SIZE = 100;
            const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'edus_participates_in_nslp', SortOrder.ASC, filter);
            query.setExtendedParameters({
                fieldname: 'edus_participates_in_nslp',
            });
            const optionsService = new ApiFilterOptionsService();
            return optionsService.getFilterOptions(query);
        },
    },
    {
        key: 'school_info.virtual_status',
        name: 'Virtual Status',
        description:
            'What type of virtual tuition the establishment provides to the students (only public schools have values in this filter)',
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldAsyncOptions: (filter: string, page?: number) => {
            const PAGE_SIZE = 100;
            const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'edus_virtual_status', SortOrder.ASC, filter);
            query.setExtendedParameters({
                fieldname: 'edus_virtual_status',
            });
            const optionsService = new ApiFilterOptionsService();
            return optionsService.getFilterOptions(query);
        },
    },
    {
        key: 'school_info.shared_time',
        name: 'Shared Time',
        description:
            "Denotes if the establishment is a 'Shared time school' that provides instruction on a regular basis to students whose membership is reported by another school only public schools have values in this filter)",
        filterFieldType: filterTypes.FieldType.BOOLEAN,
    },
    {
        key: 'school_info.lea_id',
        name: 'District/LEA ID',
        description: 'National Center for Education Statistics (NCES) agency identifier number',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'school_info.lea_operational_schools',
        name: 'Operational Schools',
        description: 'Number of operational schools associated with the District',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
];

export { SyncedDataFilterableFieldsEDUS };
