'use strict';

import Moment from "moment/moment";
import 'moment/locale/en-gb';
import 'moment/locale/en-ie';
import 'moment/locale/en-ca';
import 'moment/locale/en-au';
import 'moment/locale/en-nz';
import * as RangePreset from '../ValueObjects/DateTimeRangePreset/DateTimeRangePreset';

export const MONTH = 'Month';
export const YEAR = 'Year';

export const DEFAULT_API_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export default class DateHelper {

    constructor(outputFormat = DEFAULT_API_FORMAT) {
        this.outputFormat = outputFormat;
    }

    from(rangeName) {
        switch (rangeName) {
            case RangePreset.ALL:
            case RangePreset.PAST:
                return this.startOfTime();
            case RangePreset.FUTURE:
                return this.now();
            case RangePreset.TODAY:
                return this.todayStart();
            case RangePreset.YESTERDAY:
                return this.yesterdayStart();
            case RangePreset.TOMORROW:
                return this.tomorrowStart();
            case RangePreset.THIS_WEEK:
                return this.thisWeekStart(Moment());
            case RangePreset.LAST_WEEK:
                return this.thisWeekStart(Moment().subtract(1, 'week'));
            case RangePreset.NEXT_WEEK:
                return this.thisWeekStart(Moment().add(1, 'week'));
            case RangePreset.THIS_MONTH:
                return this.thisMonthStart(Moment());
            case RangePreset.LAST_MONTH:
                return this.thisMonthStart(Moment().subtract(1, 'month'));
            case RangePreset.NEXT_MONTH:
                return this.thisMonthStart(Moment().add(1, 'month'));
            case RangePreset.THIS_YEAR:
                return this.thisYearStart(Moment());
            case RangePreset.LAST_YEAR:
                return this.thisYearStart(Moment().subtract(1, 'year'));
            case RangePreset.NEXT_YEAR:
                return this.thisYearStart(Moment().add(1, 'year'));
        }
    }

    to(rangeName) {
        switch (rangeName) {
            case RangePreset.ALL:
            case RangePreset.FUTURE:
                return this.endOfTime();
            case RangePreset.PAST:
                return this.now();
            case RangePreset.TODAY:
                return this.todayEnd();
            case RangePreset.YESTERDAY:
                return this.yesterdayEnd();
            case RangePreset.TOMORROW:
                return this.tomorrowEnd();
            case RangePreset.THIS_WEEK:
                return this.thisWeekEnd(Moment());
            case RangePreset.LAST_WEEK:
                return this.thisWeekEnd(Moment().subtract(1, 'week'));
            case RangePreset.NEXT_WEEK:
                return this.thisWeekEnd(Moment().add(1, 'week'));
            case RangePreset.THIS_MONTH:
                return this.thisMonthEnd(Moment());
            case RangePreset.LAST_MONTH:
                return this.thisMonthEnd(Moment().subtract(1, 'month'));
            case RangePreset.NEXT_MONTH:
                return this.thisMonthEnd(Moment().add(1, 'month'));
            case RangePreset.THIS_YEAR:
                return this.thisYearEnd(Moment());
            case RangePreset.LAST_YEAR:
                return this.thisYearEnd(Moment().subtract(1, 'year'));
            case RangePreset.NEXT_YEAR:
                return this.thisYearEnd(Moment().add(1, 'year'));

        }
    }

    is(rangeName, from, to) {
        switch (rangeName) {
            case RangePreset.ALL:
                return from === this.startOfTime() && to === this.endOfTime();
            case RangePreset.PAST:
                return from === this.startOfTime() && to === this.now();
            case RangePreset.FUTURE:
                return from === this.now() && to === this.endOfTime();
            case RangePreset.TODAY:
                return from === this.todayStart() && to === this.todayEnd();
            case RangePreset.YESTERDAY:
                return from === this.yesterdayStart() && to === this.yesterdayEnd();
            case RangePreset.TOMORROW:
                return from === this.tomorrowStart() && to === this.tomorrowEnd();
            case RangePreset.THIS_WEEK:
                return from === this.thisWeekStart(Moment()) && to === this.thisWeekEnd(Moment());
            case RangePreset.LAST_WEEK:
                return from === this.thisWeekStart(Moment().subtract(7, 'days')) && to === this.thisWeekEnd(Moment().subtract(7, 'days'));
            case RangePreset.NEXT_WEEK:
                return from === this.thisWeekStart(Moment().add(7, 'days')) && to === this.thisWeekEnd(Moment().add(7, 'days'));
            case RangePreset.THIS_MONTH:
                return from === this.thisMonthStart(Moment()) && to === this.thisMonthEnd(Moment());
            case RangePreset.LAST_MONTH:
                return from === this.thisMonthStart(Moment().subtract(1, 'month')) && to === this.thisMonthEnd(Moment().subtract(1, 'month'));
            case RangePreset.NEXT_MONTH:
                return from === this.thisMonthStart(Moment().add(1, 'month')) && to === this.thisMonthEnd(Moment().add(1, 'month'));
            case MONTH:
                return from === this.thisMonthStart(Moment(from)) && to === this.thisMonthEnd(Moment(from));
            case RangePreset.THIS_YEAR:
                return from === this.thisYearStart(Moment()) && to === this.thisYearEnd(Moment());
            case RangePreset.LAST_YEAR:
                return from === this.thisYearStart(Moment().subtract(1, 'year')) && to === this.thisYearEnd(Moment().subtract(1, 'year'));
            case RangePreset.NEXT_YEAR:
                return from === this.thisYearStart(Moment().add(1, 'year')) && to === this.thisYearEnd(Moment().add(1, 'year'));
            case YEAR:
                return from === this.thisYearStart(Moment(from)) && to === this.thisYearEnd(Moment(from));
        }
    }

    rangeString(from, to, preset, includeTime = false, locale_iso = 'en-GB') {
        try {
            // If preset us used, then return it as the range summary
            if (preset) return preset;

            // Matches known range preset? Return its name as range summary
            if (this.is(RangePreset.TODAY, from, to)) return RangePreset.TODAY;
            else if (this.is(RangePreset.YESTERDAY, from, to)) return RangePreset.YESTERDAY;
            else if (this.is(RangePreset.TOMORROW, from, to)) return RangePreset.TOMORROW;
            else if (this.is(RangePreset.THIS_WEEK, from, to)) return RangePreset.THIS_WEEK;
            else if (this.is(RangePreset.LAST_WEEK, from, to)) return RangePreset.LAST_WEEK;
            else if (this.is(RangePreset.NEXT_WEEK, from, to)) return RangePreset.NEXT_WEEK;
            else if (this.is(RangePreset.THIS_MONTH, from, to)) return RangePreset.THIS_MONTH;
            else if (this.is(RangePreset.LAST_MONTH, from, to)) return RangePreset.LAST_MONTH;
            else if (this.is(RangePreset.NEXT_MONTH, from, to)) return RangePreset.NEXT_MONTH;
            else if (this.is(RangePreset.THIS_YEAR, from, to)) return RangePreset.THIS_YEAR;
            else if (this.is(RangePreset.LAST_YEAR, from, to)) return RangePreset.LAST_YEAR;
            else if (this.is(RangePreset.NEXT_YEAR, from, to)) return RangePreset.NEXT_YEAR;

            // Make condensed human-readable summary...

            // Initialise base strings
            let fromDateString = '', toDateString = '';
            let fromTimeString = '', toTimeString = '';
            let intendedFromFormat = '', intendedToFormat = '';
            const defaultFormat = 'dddd Do MMM YYYY';

            // From date is this/last/next week or same month/year? Use condensed format to imply relative closeness to current date.
            if (Moment(from).isSame(Moment().startOf('week'), 'week')) intendedFromFormat = 'dddd';
            else if (Moment(from).isSame(Moment().subtract(1, 'week').startOf('week'), 'week')) intendedFromFormat = '[Last] dddd';
            else if (Moment(from).isSame(Moment().add(1, 'week').startOf('week'), 'week')) intendedFromFormat = '[Next] dddd';
            else if (Moment(from).isSame(Moment(), 'month') && Moment(to).isSame(Moment(), 'month')) intendedFromFormat = 'dddd Do MMM';
            else if (Moment(from).isSame(Moment(), 'year') && Moment(to).isSame(Moment(), 'year')) intendedFromFormat = 'dddd Do MMM';
            else intendedFromFormat = defaultFormat;

            // To date is this/last/next week or same month/year? Use condensed format to imply relative closeness to current date.
            if (Moment(to).isSame(Moment().startOf('week'), 'week')) intendedToFormat = 'dddd';
            else if (Moment(to).isSame(Moment().subtract(1, 'week').startOf('week'), 'week')) intendedToFormat = '[Last] dddd';
            else if (Moment(to).isSame(Moment().add(1, 'week').startOf('week'), 'week')) intendedToFormat = '[Next] dddd';
            else if (Moment(to).isSame(Moment(), 'month') && Moment(from).isSame(Moment(), 'month')) intendedToFormat = 'dddd Do MMM';
            else if (Moment(to).isSame(Moment(), 'year') && Moment(from).isSame(Moment(), 'year')) intendedToFormat = 'dddd Do MMM';
            else intendedToFormat = defaultFormat;

            // Dates are month to month or year to year? Use condensed format to imply start through to end of month/year
            // Note: Use the lowest resolution of a minute
            if (Moment(from).isSame(Moment(from).startOf('year'), 'minute') && Moment(to).isSame(Moment(to).endOf('year'), 'minute')) {
                // Across whole years / same whole year
                intendedFromFormat = 'YYYY';
                intendedToFormat = 'YYYY'
            }
            else if (Moment(from).isSame(Moment(from).startOf('month'), 'minute') && Moment(to).isSame(Moment(to).endOf('month'), 'minute')) {
                // Across whole months / same whole month
                if (Moment(from).isSame(Moment(), 'year') && Moment(to).isSame(Moment(), 'year')) {
                    // Both dates within current year? Use just month format to imply relative closeness to current date.
                    intendedFromFormat = 'MMMM';
                    intendedToFormat = 'MMMM';
                }
                else {
                    // Either date is outside of current year? Use month and year format
                    intendedFromFormat = 'MMMM YYYY';
                    intendedToFormat = 'MMMM YYYY';
                }
            }

            // From date is Today/Yesterday/Today? Use noun reference, otherwise format as per intendedFormat
            if (Moment(from).isSame(Moment(), 'day')) fromDateString = RangePreset.TODAY;
            else if (Moment(from).isSame(Moment().subtract(1, 'days'), 'day')) fromDateString = RangePreset.YESTERDAY;
            else if (Moment(from).isSame(Moment().add(1, 'days'), 'day')) fromDateString = RangePreset.TOMORROW;
            else fromDateString = Moment(from).locale(locale_iso).format(intendedFromFormat);

            // To date is Today/Yesterday/Today? Use noun reference, otherwise format as per intendedFormat
            if (Moment(to).isSame(Moment(), 'day')) toDateString = RangePreset.TODAY;
            else if (Moment(to).isSame(Moment().subtract(1, 'days'), 'day')) toDateString = RangePreset.YESTERDAY;
            else if (Moment(to).isSame(Moment().add(1, 'days'), 'day')) toDateString = RangePreset.TOMORROW;
            else toDateString = Moment(to).locale(locale_iso).format(intendedToFormat);

            // Format times and ignore if at start/end of day (implies whole day)
            if (includeTime) fromTimeString = Moment(from).locale(locale_iso).format('HH:mm');
            if (includeTime) toTimeString = Moment(to).locale(locale_iso).format('HH:mm');
            if (fromTimeString === '00:00') fromTimeString = '';
            if ((!fromTimeString || fromDateString !== toDateString) && toTimeString === '23:59') toTimeString = '';

            // Compose final string
            if (fromDateString === toDateString && fromTimeString !== toTimeString) {
                return fromDateString + ' ' + fromTimeString + ' to ' + toTimeString;
            } else if (fromDateString === toDateString && fromTimeString === toTimeString) {
                return fromDateString + (includeTime && fromTimeString ? ' at ' + fromTimeString : '');
            } else {
                let fromString = fromDateString + (includeTime && fromTimeString ? ' at ' + fromTimeString : '');
                let toString = toDateString + (includeTime && toTimeString ? ' at ' + toTimeString : '');
                return ((fromString === toString) ? fromString : fromString + ' to ' + toString);
            }
        } catch (err) {
            return from.toString() + ' to ' + to.toString();
        }
    }

    startOfTime() {
        return Moment('1970-01-01 00:00:00').format(this.outputFormat);
    }

    yesterdayStart() {
        return Moment().startOf('day').subtract(1, 'days').format(this.outputFormat);
    }

    yesterdayEnd() {
        return Moment().endOf('day').subtract(1, 'days').format(this.outputFormat);
    }

    todayStart() {
        return Moment().startOf('day').format(this.outputFormat);
    }

    now() {
        return Moment().format(this.outputFormat);
    }

    todayEnd() {
        return Moment().endOf('day').format(this.outputFormat);
    }

    tomorrowStart() {
        return Moment().add(1, 'days').startOf('day').format(this.outputFormat);
    }

    tomorrowEnd() {
        return Moment().add(1, 'days').endOf('day').format(this.outputFormat);
    }

    thisDayStart(moment) {
        return moment.startOf('day').format(this.outputFormat);
    }

    thisDayEnd(moment) {
        return moment.endOf('day').format(this.outputFormat);
    }

    thisWeekStart(moment) {
        return moment.startOf('week').format(this.outputFormat);
    }

    thisWeekEnd(moment) {
        return moment.endOf('week').format(this.outputFormat);
    }

    thisMonthStart(moment) {
        return moment.startOf('month').format(this.outputFormat);
    }

    thisMonthEnd(moment) {
        return moment.endOf('month').format(this.outputFormat);
    }

    thisYearStart(moment) {
        return moment.startOf('year').format(this.outputFormat);
    }

    thisYearEnd(moment) {
        return moment.endOf('year').format(this.outputFormat);
    }

    endOfTime() {
        return Moment('9999-12-31 23:59:59').format(this.outputFormat);
    }

    daysAhead(days) {
        return Moment().add(days, 'days').format(this.outputFormat);
    }

    static isPast(date) {
        return Moment(date).isBefore(Moment());
    }

    static isFuture(date) {
        return Moment(date).isAfter(Moment());
    }

    static isMidnight(date, inputFormat = DEFAULT_API_FORMAT) {
        return date === new this(inputFormat).thisDayStart(Moment(date));
    }

    static relativeString(date) {
        return Moment(date).fromNow();
    }

    static formattedString(date, includeTime = false) {
        return Moment(date).format(includeTime ? 'Do, MMM YYYY h:mma' : 'Do, MMM YYYY');
    }

}