import {
    AppEvent,
    EventBusInstance,
    isValidString,
    isValidUrl,
    LogLevel,
    showBanner,
    Sisp,
} from '@sprint/sprint-react-components';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { UrlsRequest } from '../../Api/UrlsRequest';
import { RepositoryFactoryContext } from '../../index';
import Url from '../../Models/Url';
import UrlEditState from '../../Models/UrlEditState';

interface Props {
    uniqueKey: string;
    onSuccess: (results: any) => Promise<boolean>;
}

const UrlsEditSisp: FunctionComponent<Props> = (props: Props) => {
    const [shown, setShown] = useState<boolean>(false);

    const urlsRepository = useContext(RepositoryFactoryContext).getApiRepository(new UrlsRequest());

    const focusRef = useRef<HTMLInputElement>(null);
    const [rowId, setRowId] = useState<number>(0);

    const [editName, setEditName] = useState<string>('');
    const [editUrl, setEditUrl] = useState<string>('');
    const [editNameValid, setEditNameValid] = useState<boolean>(true);
    const [editUrlValid, setEditUrlValid] = useState<boolean>(true);

    useEffect(() => {
        if (shown) {
            if (focusRef.current) {
                focusRef.current.focus();
                focusRef.current.selectionStart = focusRef.current.value.length;
                focusRef.current.selectionEnd = focusRef.current.value.length;
            }
            setEditNameValid(true);
            setEditUrlValid(true);
        }
    }, [shown]);

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<UrlEditState>) => {
            if (event.target !== props.uniqueKey) return;
            setRowId(event.message.id);
            setEditName(event.message.name);
            setEditUrl(event.message.url);
            setShown(true);
        });
    }, [shown]);

    const validate = async (): Promise<boolean> => {
        const nameValid = !!editName && isValidString(editName);
        setEditNameValid(nameValid);

        const urlValid = !!editUrl && isValidUrl(editUrl);
        setEditUrlValid(urlValid);

        return nameValid && urlValid;
    };

    const handleEditRow = async (): Promise<boolean> => {
        const Url: Url = {
            id: rowId,
            name: editName,
            url: editUrl,
        };
        return urlsRepository
            .update(Url)
            .then((results: any) => {
                props.onSuccess(results.data);
                return Promise.resolve(true);
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to edit Url - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return Promise.resolve(false);
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        if ((await validate()) && (await handleEditRow())) setShown(false);
    };

    return (
        <Sisp
            isOpen={shown}
            onSubmit={handleEditRow}
            onCancel={() => {
                setShown(false);
            }}
            validate={validate}
        >
            <h4>Edit a URL</h4>
            <Form onSubmit={onSubmitForm}>
                <Form.Group>
                    <Form.Label>
                        Name <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        autoComplete="off"
                        ref={focusRef}
                        type="text"
                        isInvalid={!editNameValid}
                        value={editName || ''}
                        onChange={(event) => {
                            setEditName(event.target.value);
                            setEditNameValid(true);
                        }}
                    />
                    <span className="help-block">E.g. Case Studies</span>
                    <Form.Control.Feedback type="invalid">
                        {!editNameValid && 'This field is required.'}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        URL <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        isInvalid={!editUrlValid}
                        value={editUrl || ''}
                        onChange={(event) => {
                            setEditUrl(event.target.value);
                            setEditUrlValid(true);
                        }}
                    />
                    <span className="help-block">E.g. http://www.mysite.com/case-studies</span>
                    <Form.Control.Feedback type="invalid">
                        {(!editUrlValid && editUrl == '' && 'This field is required.') ||
                            (!editUrlValid && editUrl != '' && 'This field must be a valid URL')}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
        </Sisp>
    );
};

export default UrlsEditSisp;
