import SalesType from '../Models/SalesType';
import SalesPaymentType from '../Models/SalesPaymentType';
import DataGridRequest from './DataGridRequest';
export class SalesRequest extends DataGridRequest<SalesType> {
    Url = 'sales/home/api';
}

export class SalesPaymentRequest extends DataGridRequest<SalesPaymentType> {
    Url = 'sales/payments/api';
}
