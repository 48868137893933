'use strict';

import HttpRequestService from "../../Services/HttpRequestService/HttpRequestService";

export default class ContactRepository {

    static filter(filter, cancelToken, offset) {
        return new Promise(function(resolve, reject) {
            new HttpRequestService()
                .get('subscribers/contacts/filter/' + offset + '/3/' + filter, cancelToken)
                .then(response => {
                    if (!response) return reject('Invalid response returned');
                    if (response.status === 499) return resolve(undefined); // Request cancelled by user
                    if (response.status !== 200) return reject('Unable to load contacts, endpoint not responding. ERR-REACT-ECO-01');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve(response.data.data);
                })
                .catch(error => reject(error.message));
        });
    }

    static loadIdList(idList) {
        return new Promise(function(resolve, reject) {
            const formData = new FormData();
            formData.append('id_list', JSON.stringify(idList));
            new HttpRequestService()
                .post('subscribers/contacts/by_ids', formData)
                .then(response => {
                    if (!response) return reject('Invalid response returned');
                    if (response.status !== 200) return reject('Unable to load contacts, endpoint not responding. ERR-REACT-ECO-02');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve(response.data.data);
                })
                .catch(error => reject(error.message));
        });
    }
}