import { lookupValidator, LogLevel, showBanner, Sisp, PendingButton } from '@sprint/sprint-react-components';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { RepositoryFactoryContext } from '../../index';
import { ReplyToAddressRequest } from '../../Api/ReplyToAddressRequest';
import ReplyToAddress from '../../Models/ReplyToAddress';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
}

const ReplyToAddressAddSisp: FunctionComponent<Props> = (props: Props) => {
    const replyToAddressRepository = useContext(RepositoryFactoryContext).getApiRepository(new ReplyToAddressRequest());

    const focusRef = useRef<HTMLInputElement>(null);

    const [newEmail, setNewEmail] = useState<string>('');
    const [newEmailValid, setNewEmailValid] = useState<boolean>(true);

    const [showInformationDialogue, setShowInformationDialogue] = useState<boolean>(false);

    const [isSending, setIsSending] = useState<boolean>(false);

    const [emailValidationFeedback, setEmailValidationFeedback] = useState<Record<string, string>>({ email: '' });

    useEffect(() => {
        if (props.shown) {
            focusRef.current?.focus();
            setShowInformationDialogue(false);
        }
    }, [props.shown]);

    const validate = async (): Promise<boolean> => {
        let emailValid = false;
        if (newEmail !== '') {
            const emailValidator = lookupValidator('email', 'text');
            emailValid = emailValidator.validate(newEmail);
            setEmailValidationFeedback({ email: emailValid ? '' : emailValidator.feedback('Email') });
        } else {
            setEmailValidationFeedback({ email: 'This field is required.' });
        }

        setNewEmailValid(emailValid);
        return emailValid;
    };

    const reset = () => {
        setNewEmail('');
        setEmailValidationFeedback({ email: '' });
        setNewEmailValid(true);
    };

    const handleAddRow = async (): Promise<boolean> => {
        const ReplyToAddress: ReplyToAddress = {
            email_address: newEmail,
            verified_at: '',
            verified_by: '',
        };

        return replyToAddressRepository
            .create(ReplyToAddress)
            .then(props.onSuccess)
            .then(async (success) => {
                showBanner({
                    message: 'Verification email sent.',
                    level: LogLevel.SUCCESS,
                });
                reset();
                return success;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to create Email - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        setIsSending(true);
        e.preventDefault();
        if ((await validate()) && (await handleAddRow())) setShowInformationDialogue(true);
        setIsSending(false);
    };

    return (
        <Sisp
            isOpen={props.shown}
            onSubmit={handleAddRow}
            onCancel={() => {
                reset();
                props.onClose();
            }}
            validate={validate}
            footerOverride={
                <ReplyToAddressAddSispFooterOverride
                    onClose={() => {
                        props.onClose();
                        reset();
                    }}
                    onSubmitForm={onSubmitForm}
                    showInformationDialogue={showInformationDialogue}
                    isSending={isSending}
                ></ReplyToAddressAddSispFooterOverride>
            }
        >
            <h4>Verify a Reply To Address</h4>
            {!showInformationDialogue && (
                <>
                    <p>Enter the email address you'd like to verify as a Reply To Address below.</p>
                    <Form onSubmit={onSubmitForm}>
                        <Form.Group>
                            <Form.Label>
                                Email Address <span className="required-field-marker">*</span>
                            </Form.Label>
                            <Form.Control
                                ref={focusRef}
                                type="text"
                                isInvalid={!newEmailValid}
                                value={newEmail || ''}
                                onChange={(event) => {
                                    setNewEmail(event.target.value);
                                    setNewEmailValid(true);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {!newEmailValid && emailValidationFeedback.email}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </>
            )}
            {showInformationDialogue && (
                <p className="alert alert-success">
                    We have sent you a verification email.
                    <br />
                    <br />
                    Please click on the link in the email to verify your address and then you can come back to continue.
                </p>
            )}
        </Sisp>
    );
};

interface FooterOverrideProps {
    onClose: () => void;
    onSubmitForm: (e: FormEvent) => void;
    showInformationDialogue: boolean;
    isSending: boolean;
}

const ReplyToAddressAddSispFooterOverride: FunctionComponent<FooterOverrideProps> = (props: FooterOverrideProps) => {
    return (
        <>
            {!props.showInformationDialogue && (
                <>
                    <Button variant="default" onClick={props.onClose}>
                        Cancel
                    </Button>
                    <PendingButton variant="primary" onClick={props.onSubmitForm} pending={props.isSending}>
                        Send Verification Email
                    </PendingButton>
                </>
            )}
            {props.showInformationDialogue && <Button onClick={props.onClose}>Ok</Button>}
        </>
    );
};

export default ReplyToAddressAddSisp;
