import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { closeModal } from './ModalActions';
import Body from './Pieces/Body';
import Footer from './Pieces/Footer';
import Header from './Pieces/Header';

interface Props {
    open: boolean;
    disableClose?: boolean;
    header?: any;
    footer?: any;
    content?: any;
    updateCanary?: any;
}

const CampusModal: FunctionComponent<Props> = (props: Props) => {
    // State: General
    const [opening, setOpening] = useState<boolean>(true);
    const [closing, setClosing] = useState<boolean>(false);

    // Refs
    const mainDialogRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        setClosing(!props.open);
        setOpening(props.open);
        setTimeout(() => {
            setClosing(props.open);
            setOpening(!props.open);
        }, 300);
    }, [props.open]);

    const close = () => {
        if (!props.disableClose || props.disableClose !== true) {
            dispatch(closeModal());
        }
    };

    const clickOutside = (e: any) => {
        if (!mainDialogRef.current || mainDialogRef.current.contains(e.target)) return;
        close();
    };

    const header = () => {
        if (!props.header) return null;
        return <Header content={props.header} close={close.bind(this)} />;
    };

    const body = () => {
        if (!props.content) return null;
        return <Body content={props.content} />;
    };

    const footer = () => {
        if (!props.footer) return null;
        return <Footer content={props.footer} />;
    };

    const content = () => {
        return (
            <div className="modal-content">
                {header()}
                {body()}
                {footer()}
            </div>
        );
    };

    let modalClasses = 'modal fade';
    let backdropClasses = 'modal-backdrop fade';
    const styles = { display: 'none' };

    if (props.open) {
        if (!opening) {
            modalClasses += ' in';
            backdropClasses += ' in';
        }
        styles.display = 'block';
    } else {
        if (closing) styles.display = 'block';
    }

    return (
        <div>
            <div className={modalClasses} style={styles} onClick={clickOutside}>
                <div className="modal-dialog" ref={mainDialogRef}>
                    {props.open || closing ? content() : null}
                </div>
            </div>
            <div className={backdropClasses} style={styles} />
        </div>
    );
};

function select(state: any) {
    if (state.modal === undefined)
        // eslint-disable-next-line no-console
        console.error('No modal value on shared store. You need to add the ModalReducer to your root reducer');
    return {
        open: state.modal.settings.open,
        header: state.modal.settings.header,
        footer: state.modal.settings.footer,
        content: state.modal.settings.content,
        updateCanary: state.modal.internal.updateCanary,
    };
}

export default connect(select)(CampusModal);
