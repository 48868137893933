'use strict';

import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import './dropdown.scss';
import Menu from './Pieces/Menu';

class DropDownButtonMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };

        // Allow removeEventListener on bound functions
        this.handleClick = this.handleClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false);
        document.addEventListener('keyup', this.handleKeyPress, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
        document.removeEventListener('keyup', this.handleKeyPress, false);
    }

    handleClick(e) {
        if (!this.container || this.container.contains(e.target)) return;
        this.close();
    }

    handleKeyPress(e) {
        if (e.keyCode === 27) this.close();
    }

    close() {
        this.setState({
            open: false,
        });
    }

    toggle(e) {
        e.preventDefault();

        this.setState({
            open: !this.state.open,
        });
    }

    optionClicked(value) {
        this.close();
        if (this.props.optionClicked) this.props.optionClicked(value);
    }

    render() {
        return (
            <div className="react-drop-down-button-menu" ref={(el) => (this.container = el)}>
                <Button
                    size={this.props.small ? 'sm' : undefined}
                    variant={this.props.primary ? 'primary' : 'default'}
                    disabled={this.props.disabled}
                    onClick={this.toggle.bind(this)}
                    className={this.props.flat ? 'btn-flat' : ''}
                >
                    {this.props.label}
                    {this.props.showCaret ? (
                        <span className="icon-caret">
                            <FontAwesomeIcon icon={faCaretDown} />
                        </span>
                    ) : null}
                </Button>
                <Menu
                    open={this.state.open}
                    noOptions={this.props.noOptions}
                    options={this.props.options}
                    optionClicked={this.optionClicked.bind(this)}
                    offsetHorizontal={this.props.menuOffsetHorizontal}
                    offsetVertical={this.props.menuOffsetVertical}
                />
            </div>
        );
    }
}

DropDownButtonMenu.propTypes = {
    small: PropTypes.bool,
    primary: PropTypes.bool,
    flat: PropTypes.bool,
    disabled: PropTypes.bool,
    showCaret: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    noOptions: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                label: PropTypes.string.isRequired,
                labelElement: PropTypes.element,
            }),
        ]),
    ),
    optionClicked: PropTypes.func,
    menuOffsetHorizontal: PropTypes.number,
    menuOffsetVertical: PropTypes.number,
};

DropDownButtonMenu.defaultProps = {
    noOptions: 'No Items',
    showCaret: true,
    menuOffsetHorizontal: 0,
    menuOffsetVertical: -1,
};

export default DropDownButtonMenu;
