import { AppEvent, EventBusInstance } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import QuotesEditState from '../../Models/QuotesEditState';

interface Props {
    uniqueKey: string;
    onSuccess: (event: any) => Promise<boolean>;
}

const QuotesEditRedirect: FunctionComponent<Props> = (props: Props) => {
    const editUrlPrefix = '/quotes/edit/';
    const [shown, setShown] = useState<boolean>(false);

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<QuotesEditState>) => {
            if (event.target !== props.uniqueKey) return;
            const editUrl = editUrlPrefix + event.message.id;
            window.location.href = editUrl;
        });
    }, [shown]);

    return <></>;
};

export default QuotesEditRedirect;
