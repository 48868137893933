import { formatStat, Stat } from '@client/CommonComponents/StatCards/Stat';
import React, { FunctionComponent, useEffect, useState } from 'react';
import NumbersHelper from '../../Applications/CampaignReporting/Common/Helpers/NumbersHelper';
import HttpRequestService from '../HttpRequestService/HttpRequestService';
import PercentDiff from '../PercentDiff/PercentDiff';
import Spinner from '../Spinner/Spinner';
import './StatCard.scss';

interface Props {
    stat: Stat;
    url: string;
    body: any;
    icon?: JSX.Element;
    comparison?: {
        label: string;
        value: number;
    };
}

interface StatResponse {
    value: number;
}

export enum StatFormat {
    ROUND,
    PERCENT,
}

const StatCard: FunctionComponent<Props> = (props: Props) => {
    const http = new HttpRequestService('');
    const [stat, setStat] = useState<number>();
    const [loading, setLoading] = useState<boolean>(true);

    async function getStat() {
        const body = {
            ...props.body,
            fields: [props.stat.key],
        };
        const data = await http.post(props.url, body, `stat-card-${props.url}-${props.stat.key}`);
        setStat(Number((data.data[props.stat.key] as StatResponse).value));
        setLoading(false);
    }

    useEffect(() => {
        if (props.body === undefined) return;
        setLoading(true);
        getStat();
    }, [props.body]);

    return (
        <div className="card stat-card">
            <div className="stat-card-title">{props.stat.title}</div>{' '}
            <div className="stat-card-value">
                {loading && <Spinner />}
                {!loading && stat !== undefined && (
                    <>
                        {formatStat(stat, props.stat.format)}
                        {props.comparison && (
                            <span className="stat-card-comparison">
                                <PercentDiff diff={NumbersHelper.percentageDifference(stat, props.comparison.value)} />{' '}
                                {props.comparison.label}
                            </span>
                        )}
                    </>
                )}
            </div>
            {props.icon && (
                <div className="stat-card-icon-wrapper">
                    <div className="stat-card-icon">{props.icon}</div>
                </div>
            )}
        </div>
    );
};

export default StatCard;
