'use strict';

import * as ActionTypes from "./ActionTypes";
import ContactRepository from "./ContactRepository";
import HttpRequestService from "../../Services/HttpRequestService/HttpRequestService";
import Contact from "./Contact";

export function filterContacts(filter) {
    return (dispatch) => {
        const token = new HttpRequestService().generateToken();
        dispatch(updateCurrentFilter(filter, token));
        ContactRepository.filter(filter, token, 0)
            .then(response => dispatch(response ? contactsLoaded(response) : contactsLoadingCancelled()))
            .catch(e => dispatch(contactsLoadingFailed(e.message ? e.message : e)));
    }
}

export function loadMore(offset, filter) {
    return (dispatch) => {
        dispatch(contactsMoreLoading());
        const token = new HttpRequestService().generateToken();
        ContactRepository.filter(filter, token, offset)
            .then(response => dispatch(response ? contactsMoreLoaded(response) : contactsLoadingCancelled()))
            .catch(e => dispatch(contactsLoadingFailed(e.message ? e.message : e)));
    }
}

export function loadIdList(idList) {
    return (dispatch) => {
        dispatch(contactsMoreLoading());
        ContactRepository.loadIdList(idList)
            .then(response => dispatch(contactsSpecificIdsLoaded(response)))
            .catch(e => dispatch(contactsLoadingFailed(e.message ? e.message : e)));
    }
}

function updateCurrentFilter(filter, token) {
    return {
        type: ActionTypes.UPDATE_FILTER,
        payload: {
            filter: filter,
            requestToken: token
        }
    }
}

function contactsLoaded(contacts) {
    return  {
        type: ActionTypes.DATA_LOADED,
        payload: {
            contacts: contacts
                ? {
                    ...contacts,
                    items: contacts.items.reduce((result, contact) => {
                        try {
                            result.push(Contact.fromApi(contact));
                        } catch (e) {
                        }
                        return result;
                    }, [])
                }
                : contacts
        }
    }
}

function contactsMoreLoading() {
    return {
        type: ActionTypes.MORE_LOADING
    }
}

function contactsMoreLoaded(additionalContacts) {
    return {
        type: ActionTypes.MORE_LOADED,
        payload: {
            additionalContacts: additionalContacts
                ? {
                    ...additionalContacts,
                    items: additionalContacts.items.reduce((result, contact) => {
                        try {
                            result.push(Contact.fromApi(contact));
                        } catch (e) {
                        }
                        return result;
                    }, [])
                }
                : additionalContacts
        }
    }
}

function contactsSpecificIdsLoaded(additionalContacts) {
    return {
        type: ActionTypes.IDS_LOADED,
        payload: {
            additionalContacts: additionalContacts
                ? {
                    ...additionalContacts,
                    items: additionalContacts.items.reduce((result, contact) => {
                        try {
                            result.push(Contact.fromApi(contact));
                        } catch (e) {
                        }
                        return result;
                    }, [])
                }
                : additionalContacts
        }
    }
}

function contactsLoadingCancelled() {
    return {
        type: ActionTypes.LOADING_CANCELLED
    }
}

function contactsLoadingFailed(error) {
    return {
        type: ActionTypes.LOADING_FAILED,
        payload: {
            error: error
        }
    }
}