'use strict';

import React from 'react';
import Select from "../../../CommonComponents/Select/Select";
import PropTypes from 'prop-types';
import Spinner from "../../../CommonComponents/Spinner/Spinner";
import SavedSearch from "../SavedSearch";

class SavedSearchSelect extends React.Component {

    optionSetDefaultAction(savedSearchEntity) {
        return savedSearchEntity.isDefault
            ? <span onClick={(e) => {}}
                className="react-select--option-action action-persist">Unset default</span>
            : <span onClick={(e) => {}}
                className="react-select--option-action">Set as default</span>;
    }

    optionsWithActions() {
        return this.props.availableValues
            ? this.props.availableValues.map(savedSearchEntity => {
                return Object.assign(SavedSearch.blank(), savedSearchEntity, {
                    labelElement: <span>
                        <span className="react-select--option-clip">{savedSearchEntity.forLabel()}</span>
                    </span>
                });
            })
            : undefined;
    }

    render() {
        const selectedItem = this.props.availableValues ? this.props.availableValues.filter(v => v.id === this.props.selectedValue) : [];
        const placeholder = selectedItem.length > 0 ? selectedItem[0].label : 'Load';

        let availableOptions = this.optionsWithActions();

        return <Select
            placeholder={placeholder}
            open={this.props.open}
            toggle={this.props.toggle}
            selectedOption={this.props.selectedValue ? this.props.selectedValue.toString() : null}
            availableOptions={availableOptions}
            updateSelectedOption={this.props.updateSelectedOption}
            currentFilter={this.props.currentFilter}
            updateFilter={this.props.updateFilter}
            totalAvailable={this.props.totalAvailable}
            loadMore={this.props.loadMore}
            moreLoading={this.props.moreLoading}
            deleteItem={this.props.deleteItem}
            deletingItem={this.props.deletingItem}
            noResultsLabel="No Saved Filters"
        />;
    }
}

SavedSearchSelect.propTypes = {
    availableValues: PropTypes.arrayOf(PropTypes.instanceOf(SavedSearch)),
    allKnownValues: PropTypes.arrayOf(PropTypes.instanceOf(SavedSearch)),
    selectedValue: PropTypes.number,
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    updateSelectedOption: PropTypes.func.isRequired,
    currentFilter: PropTypes.string.isRequired,
    updateFilter: PropTypes.func.isRequired,
    totalAvailable: PropTypes.number,
    loadMore: PropTypes.func,
    moreLoading: PropTypes.bool,
    deleteItem: PropTypes.func,
    deletingItem: PropTypes.number
};

export default SavedSearchSelect;