import { instanceToPlain } from 'class-transformer';
import urlJoin from 'url-join';
import HttpRequestService from '../../../CommonComponents/HttpRequestService/HttpRequestService';

export type UpdateType = 'contacts' | 'organisations' | 'deals' | 'tasks';

export default class BulkUpdateRepository {
    private _http: HttpRequestService;
    private _baseUrl: string;

    constructor() {
        this._http = new HttpRequestService('');
        this._baseUrl = '/api/v1/bulk/';
    }

    private isSuccessCode(statusCode: number) {
        return statusCode >= 200 && statusCode <= 299;
    }

    private isClientErrorCode(statusCode: number) {
        return statusCode >= 400 && statusCode <= 499;
    }

    public async update(options: {
        type: UpdateType;
        customProperty?: true;
        field: string;
        value: any;
        ids: number[];
    }): Promise<any> {
        const { type, customProperty, field, value, ids } = options;
        const url = urlJoin(this._baseUrl, type);
        const body = customProperty ? { field: 'custom_property', name: field, value, ids } : { field, value, ids };
        return this._http.patch(url, instanceToPlain(body)).then((res) => {
            if (this.isSuccessCode(res.status)) return res.data;
            if (this.isClientErrorCode(res.status)) {
                throw new Error(res.data?.error || 'An unknown error occurred');
            }
            throw new Error(res.statusText);
        });
    }
}
