'use strict';

import * as ActionTypes from "./ActionTypes";
import OrganisationRepository from "./OrganisationRepository";
import HttpRequestService from "../../Services/HttpRequestService/HttpRequestService";
import Contact from "../Contact/Contact";
import Organisation from "./Organisation";

export function filterOrganisations(filter) {
    return (dispatch) => {
        const token = new HttpRequestService().generateToken();
        dispatch(updateCurrentFilter(filter, token));
        OrganisationRepository.filter(filter, token, 0)
            .then(response => dispatch(response ? organisationsLoaded(response) : organisationsLoadingCancelled()))
            .catch(e => dispatch(organisationsLoadingFailed(e.message ? e.message : e)));
    }
}

export function loadMore(offset, filter) {
    return (dispatch) => {
        dispatch(organisationsMoreLoading());
        const token = new HttpRequestService().generateToken();
        OrganisationRepository.filter(filter, token, offset)
            .then(response => dispatch(response ? organisationsMoreLoaded(response) : organisationsLoadingCancelled()))
            .catch(e => dispatch(organisationsLoadingFailed(e.message ? e.message : e)));
    }
}

export function loadIdList(idList) {
    return (dispatch) => {
        dispatch(organisationsMoreLoading());
        OrganisationRepository.loadIdList(idList)
            .then(response => dispatch(organisationsSpecificIdsLoaded(response)))
            .catch(e => dispatch(organisationsLoadingFailed(e.message ? e.message : e)));
    }
}

function updateCurrentFilter(filter, token) {
    return {
        type: ActionTypes.UPDATE_FILTER,
        payload: {
            filter: filter,
            requestToken: token
        }
    }
}

function organisationsLoaded(organisations) {
    return {
        type: ActionTypes.DATA_LOADED,
        payload: {
            organisations: organisations
                ? {
                    ...organisations,
                    items: organisations.items.reduce((result, organisation) => {
                        try {
                            result.push(Organisation.fromApi(organisation));
                        } catch (e) {
                        }
                        return result;
                    }, [])
                }
                : organisations
        }
    }
}

function organisationsMoreLoading() {
    return {
        type: ActionTypes.MORE_LOADING
    }
}

function organisationsMoreLoaded(additionalOrganisations) {
    return {
        type: ActionTypes.MORE_LOADED,
        payload: {
            additionalOrganisations: additionalOrganisations
                ? {
                    ...additionalOrganisations,
                    items: additionalOrganisations.items.reduce((result, organisation) => {
                        try {
                            result.push(Organisation.fromApi(organisation));
                        } catch (e) {
                        }
                        return result;
                    }, [])
                }
                : additionalOrganisations
        }
    }
}

function organisationsSpecificIdsLoaded(additionalOrganisations) {
    return {
        type: ActionTypes.IDS_LOADED,
        payload: {
            additionalOrganisations: additionalOrganisations
                ? {
                    ...additionalOrganisations,
                    items: additionalOrganisations.items.reduce((result, organisation) => {
                        try {
                            result.push(Organisation.fromApi(organisation));
                        } catch (e) {
                        }
                        return result;
                    }, [])
                }
                : additionalOrganisations
        }
    }
}

function organisationsLoadingCancelled() {
    return {
        type: ActionTypes.LOADING_CANCELLED
    }
}

function organisationsLoadingFailed(error) {
    return {
        type: ActionTypes.LOADING_FAILED,
        payload: {
            error: error
        }
    }
}