'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import CampusFormControl from "./CampusFormControl";

class ConnectedCampusFormSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            appliedTextFilter: '',
            appliedAdvancedSearch: {}
        };
    }

    componentDidMount() {
        if (this.props.searchResults === undefined) this.filterEntity('');
    }

    componentDidUpdate() {
        if (this.state.appliedTextFilter !== this.props.currentFilter
        || !this.isAdvancedSearchIdentical()) {
            this.filterEntity(this.props.currentFilter)
        }
    }

    isAdvancedSearchIdentical() {
        if (!this.props.advancedSearch && !this.state.appliedAdvancedSearch) return true;
        if (!this.props.advancedSearch || !this.state.appliedAdvancedSearch) return false;

        try {
            return Object.keys(this.props.advancedSearch).length === Object.keys(this.state.appliedAdvancedSearch).length &&
            Object.keys(this.props.advancedSearch).every(key =>
                this.state.appliedAdvancedSearch.hasOwnProperty(key) && JSON.stringify(this.props.advancedSearch[key]) === JSON.stringify(this.state.appliedAdvancedSearch[key])
            );
        } catch (ex) {
            return false;
        }
    }

    static getDerivedStateFromProps(props, prevState) {
        const selectedValue = props.value;

        if (!selectedValue) return prevState;

        // Load selected entity from server if not already loaded
        const selectedValueNotPresent = (props.allKnownResults.filter(r => String(r.id) === String(selectedValue)).length === 0);
        if (selectedValueNotPresent) props.dispatch(props.loadIdList([selectedValue]));

        return prevState;
    }

    filterEntity(filter) {
        this.props.dispatch(this.props.filterEntity(filter, this.props.advancedSearch));
        this.setState({
            appliedTextFilter: filter,
            appliedAdvancedSearch: this.props.advancedSearch
        });
    }

    loadMore() {
        this.props.dispatch(this.props.loadMore(this.props.searchResults ? this.props.searchResults.length : 0, this.props.currentFilter, this.props.advancedSearch));
    }

    searchResultsForSelect() {
        if (!this.props.searchResults) return undefined;
        return this.props.searchResults.map(entity => entity.forSelect());
    }

    allKnownResultsForSelect() {
        if (!this.props.allKnownResults) return undefined;
        return this.props.allKnownResults.map(entity => entity.forSelect());
    }

    render() {
        let selectFilter = {
            currentFilter: this.props.currentFilter,
            updateFilter: this.filterEntity.bind(this),
            noResultsLabel: this.props.noResultsLabel,
            allKnownOptions: this.allKnownResultsForSelect(),
            totalAvailable: this.props.totalAvailableResults,
            moreLoading: this.props.moreLoading,
            loadMore: this.loadMore.bind(this),
        };

        return <CampusFormControl
            inputType="select"
            label={this.props.label}
            validationErrorMessage={this.props.validationErrorMessage}
            placeHolder={this.props.placeHolder}
            value={this.props.value}
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            options={this.searchResultsForSelect()}
            clearable={this.props.clearable}
            selectFilter={selectFilter}
        />
    }

}

ConnectedCampusFormSelect.propTypes = {
    label: PropTypes.string.isRequired,
    validationErrorMessage: PropTypes.string,
    placeHolder: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    clearable: PropTypes.bool,
    noResultsLabel: PropTypes.string,
    reducerName: PropTypes.string.isRequired,
    filterEntity: PropTypes.func.isRequired,
    loadIdList: PropTypes.func.isRequired,
    loadMore: PropTypes.func.isRequired,
    advancedSearch: PropTypes.object
};

ConnectedCampusFormSelect.defaultProps = {
};

function select(state, props) {
    return {
        currentFilter: state[props.reducerName].currentFilter,
        searchResults: state[props.reducerName].searchResults,
        allKnownResults: state[props.reducerName].allKnownResults,
        totalAvailableResults: state[props.reducerName].totalAvailableResults,
        moreLoading: state[props.reducerName].moreLoading
    }
}

export default connect(select)(ConnectedCampusFormSelect);