'use strict';

import CampusEntity from "../CampusEntity/CampusEntity";

export default class SavedSearch extends CampusEntity {

    id = undefined;
    name = '';
    entity = '';
    fields = undefined;
    isDefault = false;
    shared = false;

    static blank() {
        return new SavedSearch();
    }

    static fromApi(savedSearch) {
        const entity = SavedSearch.blank();

        // Construct from expected API output object
        entity.id = savedSearch.id;
        entity.name = savedSearch.name;
        entity.entity = savedSearch.entity;
        entity.fields = savedSearch.fields;
        entity.isDefault = savedSearch.is_default;
        entity.shared = savedSearch.shared;

        return entity;
    }

    static deserialize(plainObject) {
        // Hydrate a new instance of this class
        return Object.assign(SavedSearch.blank(), plainObject);
    }

    static select(entityArray, id) {
        return entityArray.find(entity => entity.matches(id));
    }

    matches(id) {
        return String(this.id) === String(id);
    }

    clone() {
        return Object.assign(SavedSearch.blank(), this);
    }

    forApi() {
        let formData = new FormData();

        // Note: FormData will transmit a string of 'undefined' if the field is empty, so check before appending.
        if (this.id) formData.append('id', this.id);
        if (this.name) formData.append('name', this.name);
        if (this.entity) formData.append('entity', this.entity);
        if (this.fields) formData.append('fields', JSON.stringify(this.fields));
        if (this.isDefault) formData.append('is_default', this.isDefault ? '1' : '0');
        if (this.shared) formData.append('shared', this.shared ? '1' : '0');

        return formData;
    }

    forLabel() {
        return this.name;
    }

    forSelect() {
        return Object.assign(SavedSearch.blank(), this, { label: this.forLabel() });
    }

}
