'use strict';

import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import Spinner from '../../../../CommonComponents/Spinner/Spinner';
import Client from '../../../../Entities/Client/Client';
import Deal from '../../../../Entities/Deal/Deal';
import DealPipeline from '../../../../Entities/Deal/DealPipeline/DealPipeline';
import PipelineStage from './PipelineStage';

import '../../../../CommonComponents/Board/board.scss';

class Pipeline extends React.Component {
    deleteSelectedAction(e) {
        if (typeof this.props.deleteSelectedAction === 'function')
            this.props.deleteSelectedAction(this.props.pipeline, e);
    }

    render() {
        return (
            <div className="react-board--section">
                <div className="react-board--section--header">
                    <span className="header--text">{this.props.pipeline.name}</span>
                    <div className="header--button-group">
                        {this.props.showDeleteSelected ? (
                            <Button size="sm" variant="default" onClick={this.deleteSelectedAction.bind(this)}>
                                Delete All Selected
                            </Button>
                        ) : null}
                    </div>
                </div>
                <div className="react-board--section--content">
                    {this.props.pipeline.stages.map((stage, index) => (
                        <PipelineStage
                            key={this.props.pipeline.id + '-' + stage.name}
                            index={index}
                            pipeline={this.props.pipeline}
                            stage={stage}
                            deals={this.props.deals}
                            inCurrentSearch={this.props.stageStatesInSearch.indexOf(stage.stageState) > -1}
                            currency={this.props.client ? this.props.client.settings.locale.currency : undefined}
                            dealPreviewAction={this.props.dealPreviewAction}
                            dealEditAction={this.props.dealEditAction}
                            selectedIds={this.props.selectedIds}
                            draggingId={this.props.draggingId}
                            toggleSelection={this.props.toggleSelection}
                            toggleSelectionInGroup={this.props.toggleSelectionInGroup}
                            addToSelection={this.props.addToSelection}
                        />
                    ))}
                </div>
                {this.props.moreDealsAvailable ? (
                    <div className="react-board--pagination-controls pagination-controls--centered">
                        <Button size="sm" onClick={this.props.loadMoreDealsAction}>
                            {this.props.moreDealsLoading ? <Spinner /> : 'View More...'}
                        </Button>
                    </div>
                ) : null}
            </div>
        );
    }
}

Pipeline.propTypes = {
    pipeline: PropTypes.instanceOf(DealPipeline).isRequired,
    stageStatesInSearch: PropTypes.arrayOf(PropTypes.string),
    deals: PropTypes.arrayOf(PropTypes.instanceOf(Deal)),
    client: PropTypes.instanceOf(Client),
    showDeleteSelected: PropTypes.bool,
    deleteSelectedAction: PropTypes.func,
    dealPreviewAction: PropTypes.func,
    dealEditAction: PropTypes.func,
    loadMoreDealsAction: PropTypes.func,
    moreDealsAvailable: PropTypes.bool,
    moreDealsLoading: PropTypes.bool,
    selectedIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    draggingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    toggleSelection: PropTypes.func,
    toggleSelectionInGroup: PropTypes.func,
    addToSelection: PropTypes.func,
};

Pipeline.defaultProps = {};

export default Pipeline;
