import EmojiPicker, { EmojiClickData, EmojiStyle } from 'emoji-picker-react';
import React, { FunctionComponent } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface Props {
    onEmojiClick: (emoji: EmojiClickData, event: MouseEvent) => void;
}

const PopoverEmojiPicker: FunctionComponent<Props> = (props: Props) => {
    const emojiPickerPopover = (
        <Popover id="popover-emoji-picker">
            <EmojiPicker
                onEmojiClick={props.onEmojiClick}
                skinTonesDisabled={true}
                style={{ width: '100%', border: 0 }}
                // Load native unicode emojis instead of loading each emoji through an API
                emojiStyle={EmojiStyle.NATIVE}
                previewConfig={{
                    showPreview: false,
                }}
            />
        </Popover>
    );
    return (
        <OverlayTrigger overlay={emojiPickerPopover} trigger="click" placement="bottom" rootClose>
            <div style={{ cursor: 'pointer', fontSize: '1.15em' }}>🙂</div>
        </OverlayTrigger>
    );
};

export default PopoverEmojiPicker;
