'use strict';

import HttpRequestService from "../../Services/HttpRequestService/HttpRequestService";

export default class SavedSearchRepository {

    static filter(filter, cancelToken, offset, entityName) {
        return new Promise(function(resolve, reject) {
            new HttpRequestService()
                .get('saved_searches/filter/' + entityName + '/' + offset + '/10/' + filter, cancelToken)
                .then(response => {
                    if (!response) return reject('Unable to load searches, endpoint not responding. ERR-REACT-ESS-01');
                    if (response.status === 499) return resolve(undefined); // Request cancelled by user
                    if (response.status !== 200) return reject('Unable to load searches, endpoint not responding. ERR-REACT-ESS-02');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve({
                        searches: response.data.data.searches.map(s => {
                            s.fields = JSON.parse(s.fields);
                            return s;
                        }),
                        total: response.data.data.total
                    });
                })
                .catch(error => {
                    reject(error.message);
                })
        });
    }

    static save(savedSearchEntity, entityName) {
        return new Promise((resolve, reject) => {
            if (typeof savedSearchEntity.forApi !== 'function') return reject('Unable to save search, invalid entity provided. ERR-REACT-ESS-06');

            // Entity name reference gets set here
            savedSearchEntity.entity = entityName;

            new HttpRequestService()
                .post('saved_searches/save', savedSearchEntity.forApi())
                .then(response => {
                    if (!response) return reject('Unable to load searches, endpoint not responding. ERR-REACT-ESS-03');
                    if (response.status !== 200) return reject('Unable to save search, endpoint not responding. ERR-REACT-ESS-04');
                    if (response.data.success === false) return reject(response.data.error);

                    const savedSearch = response.data.data;
                    savedSearch.fields = JSON.parse(response.data.data.fields);

                    resolve(savedSearch);
                })
                .catch(error => {
                    reject(error.message);
                })
        });
    }

    static delete(id, entityName) {
        return new Promise(function(resolve, reject) {
            new HttpRequestService()
                .get('saved_searches/delete/' + entityName + '/' + id)
                .then(response => {
                    if (!response) return reject('Unable to delete search, endpoint not responding. ERR-REACT-ESS-04');
                    if (response.status !== 200) return reject('Unable to delete search, endpoint not responding. ERR-REACT-ESS-05');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve();
                })
                .catch(error => {
                    reject(error.message);
                })
        });
    }

}