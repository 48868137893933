'use strict';

import { faLock } from '@fortawesome/pro-light-svg-icons';
import { faGripLines } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Button } from 'react-bootstrap';
import Label from '../../../../CommonComponents/Label/Label';
import DealPipelineStage from '../../../../Entities/Deal/DealPipeline/DealPipelineStage/DealPipelineStage';

import './stagelist.scss';

class StageListItem extends React.Component {
    editActionClick(e, stage) {
        e.preventDefault();
        if (this.props.editAction) this.props.editAction(stage);
    }

    deleteActionClick(e, stage) {
        e.preventDefault();
        if (this.props.deleteAction) this.props.deleteAction(stage);
    }

    itemClassName(snapshot, isDragDisabled) {
        let className = 'react-stage-list--item ';
        if (snapshot.isDragging) className += ' dragging';
        if (isDragDisabled) className += ' fixed';
        return className;
    }

    render() {
        return (
            <Draggable
                draggableId={this.props.stage.name + String(this.props.index)}
                index={this.props.index}
                isDragDisabled={this.props.isDragDisabled}
            >
                {(provided, snapshot) => (
                    <div
                        className={this.itemClassName(snapshot, this.props.isDragDisabled)}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        {this.props.isDragDisabled ? (
                            <span className="item--grip" title="This cannot be reordered">
                                <FontAwesomeIcon icon={faLock} />
                            </span>
                        ) : (
                            <span className="item--grip" title="Drag to reorder">
                                <FontAwesomeIcon icon={faGripLines} />
                            </span>
                        )}
                        <span className="item--text">{this.props.stage.name}</span>{' '}
                        {this.props.stage.isDefault ? (
                            <Label info={true} border={false}>
                                Default
                            </Label>
                        ) : null}
                        <span className="item--options">
                            {this.props.editAction ? (
                                <Button
                                    className="btn-row-option"
                                    variant="default"
                                    onClick={(e) => this.editActionClick(e, this.props.stage)}
                                >
                                    Edit
                                </Button>
                            ) : null}
                            {this.props.deleteAction ? (
                                <Button
                                    className="btn-row-option"
                                    variant="default"
                                    onClick={(e) => this.deleteActionClick(e, this.props.stage)}
                                >
                                    Delete
                                </Button>
                            ) : null}
                        </span>
                        <span className="item--addendum">{this.props.stage.stageState}</span>
                    </div>
                )}
            </Draggable>
        );
    }
}

StageListItem.propTypes = {
    stage: PropTypes.instanceOf(DealPipelineStage).isRequired,
    index: PropTypes.number.isRequired,
    isDragDisabled: PropTypes.bool,
    editAction: PropTypes.func,
    deleteAction: PropTypes.func,
};

StageListItem.defaultProps = {};

export default StageListItem;
