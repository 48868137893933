import { LogLevel, PendingButton, Sisp, isValidString, isValidUrl, showBanner } from '@sprint/sprint-react-components';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { UrlsRequest } from '../../Api/UrlsRequest';
import Url from '../../Models/Url';
import { RepositoryFactoryContext } from '../../index';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
}

const UrlsAddSisp: FunctionComponent<Props> = (props: Props) => {
    const urlsRepository = useContext(RepositoryFactoryContext).getApiRepository(new UrlsRequest());

    const focusRef = useRef<HTMLInputElement>(null);
    const [newName, setNewName] = useState<string>('');
    const [newUrl, setNewUrl] = useState<string>('');

    const [newNameValid, setNewNameValid] = useState<boolean>(true);
    const [newUrlValid, setNewUrlValid] = useState<boolean>(true);

    const [isSaving, setIsSaving] = useState<boolean>(false);

    useEffect(() => {
        if (props.shown) {
            focusRef.current?.focus();
        }
    }, [props.shown]);

    const validate = async (): Promise<boolean> => {
        const nameValid = !!newName && isValidString(newName);
        setNewNameValid(nameValid);

        const urlValid = !!newUrl && isValidUrl(newUrl);
        setNewUrlValid(urlValid);

        return nameValid && urlValid;
    };

    const reset = () => {
        setNewName('');
        setNewNameValid(true);
        setNewUrl('');
        setNewUrlValid(true);
    };

    const handleAddRow = async (): Promise<boolean> => {
        const Url: Url = {
            name: newName,
            url: newUrl,
        };

        return urlsRepository
            .create(Url)
            .then(props.onSuccess)
            .then(async (success) => {
                reset();
                return success;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to create Url - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        setIsSaving(true);
        e.preventDefault();
        if ((await validate()) && (await handleAddRow())) props.onClose();
        setIsSaving(false);
    };

    const onSubmitAndAddAnother = async (e: FormEvent) => {
        setIsSaving(true);
        e.preventDefault();
        if ((await validate()) && (await handleAddRow())) {
            reset();
            focusRef.current?.focus();
        }
        setIsSaving(false);
    };

    return (
        <Sisp
            isOpen={props.shown}
            onSubmit={handleAddRow}
            onCancel={() => {
                reset();
                props.onClose();
            }}
            validate={validate}
            footerOverride={
                <>
                    <Button
                        variant="default"
                        onClick={() => {
                            reset();
                            props.onClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <PendingButton variant="default" onClick={onSubmitAndAddAnother} pending={isSaving}>
                        Save & Add Another
                    </PendingButton>
                    <PendingButton variant="primary" onClick={onSubmitForm} pending={isSaving}>
                        Save
                    </PendingButton>
                </>
            }
        >
            <h4>Add a Url</h4>
            <Form onSubmit={onSubmitForm}>
                <Form.Group>
                    <Form.Label>
                        Name <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        autoComplete="off"
                        ref={focusRef}
                        type="text"
                        isInvalid={!newNameValid}
                        value={newName || ''}
                        onChange={(event) => {
                            setNewName(event.target.value);
                            setNewNameValid(true);
                        }}
                    />
                    <span className="help-block">E.g. Case Studies</span>
                    <Form.Control.Feedback type="invalid">
                        {!newNameValid && 'This field is required.'}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        URL <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        // ref={focusRef}
                        type="text"
                        isInvalid={!newUrlValid}
                        value={newUrl || ''}
                        onChange={(event) => {
                            setNewUrl(event.target.value);
                            setNewUrlValid(true);
                        }}
                    />
                    <span className="help-block">E.g. http://www.mysite.com/case-studies</span>
                    <Form.Control.Feedback type="invalid">
                        {(!newUrlValid && newUrl == '' && 'This field is required.') ||
                            (!newUrlValid && newUrl != '' && 'This field must be a valid URL')}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
        </Sisp>
    );
};

export default UrlsAddSisp;
