import { AsyncDropDown, AsyncDropDownProps, StyledPill } from '@sprint/sprint-react-components';
import React from 'react';
import { components, OptionProps } from 'react-select';
import { HTMLColors } from '../Models/Enums';

const LimitReachedCustomOptionComponent = (props: OptionProps<any>) => {
    return (
        <components.Option {...props}>
            {props.data.label}
            {props.isDisabled && (
                <span style={{ marginLeft: '8px' }}>
                    <StyledPill cellContent={'Limit Reached'} style={{ backgroundColor: HTMLColors.DANGER }} />
                </span>
            )}
        </components.Option>
    );
};

const LimitReachedAsyncDropDown = (props: AsyncDropDownProps) => (
    <AsyncDropDown customOptionComponent={LimitReachedCustomOptionComponent} {...props} />
);

export default LimitReachedAsyncDropDown;
