import * as ActionTypes from '../Actions/ActionTypes';
import * as SaleActionTypes from "../../../Entities/Sale/ActionTypes";
import * as DealActionTypes from "../../../Entities/Deal/ActionTypes"

const initialState = {
    modalOpenRequested: false,
    dealId: undefined,
    reloadNewlyLinkedDeal: false,
    dealClosing: false,
    showCreateButton: true
};

export default function (state, action) {
    if (state === undefined) {
        state = initialState;
    }

    const newFieldBlurList = Object.assign({}, state.fieldBlurList);

    switch (action.type) {
        case ActionTypes.OPEN_SALE_LINK_MODAL:
            return Object.assign({}, state, {
                modalOpenRequested: true,
                dealId: action.payload.dealId,
                reloadNewlyLinkedDeal: false,
                dealClosing: action.payload.dealClosing,
                showCreateButton: action.payload.showCreateButton
            });
        case ActionTypes.SALE_LINK_MODAL_OPENED:
            return Object.assign({}, state, {
                modalOpenRequested: false
            });
        case SaleActionTypes.SALE_LINKED_TO_DEAL:
            return Object.assign({}, state, {
                reloadNewlyLinkedDeal: true
            });
        case DealActionTypes.DEAL_LOADING:
            return Object.assign({}, state, {
                reloadNewlyLinkedDeal: false
            });
        default:
            return state;
    }
}