'use strict';

import HttpRequestService from "../../Services/HttpRequestService/HttpRequestService";

export default class ClientRepository {

    static all() {
        return new Promise(function(resolve, reject) {
            new HttpRequestService()
                .get('account/ajax/get_client')
                .then(response => {
                    if (response.status !== 200) return reject('Unable to load client info, endpoint not responding. ERR-REACT-AEC-01');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.message);
                })
        });
    }

}