import { DefaultPillFormatter, ExtendedColumn, HoveroverButton } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext } from 'react';
import { ProductRequest } from '../../Api/ProductRequest';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import { UniqueKeyType } from '../../Models/Enums';
import Product from '../../Models/Product';
import ProductEditState from '../../Models/ProductEditState';
import { RepositoryFactoryContext } from '../../index';
import CampusDataGrid, { ActionBarMeta, AddSispMeta, DataGridMeta, PromptMeta } from '../CampusDataGrid';
import ProductsAddSisp from './ProductsAddSisp';
import ProductsEditSisp from './ProductsEditSisp';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

export enum ProductsColumnKey {
    ID,
    PRODUCT_NAME,
    PRODUCT_DESCRIPTION,
    NET_PRICE,
    VAT_RATE,
}

const ProductsTable: FunctionComponent<Props> = (props: Props) => {
    const editSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);

    const productRepository = useContext(RepositoryFactoryContext).getApiRepository(new ProductRequest());

    const Columns: Record<ProductsColumnKey, ExtendedColumn> = {
        [ProductsColumnKey.ID]: {
            key: 'id',
            name: 'ID',
            sortable: false,
        },
        [ProductsColumnKey.PRODUCT_NAME]: {
            key: 'product_name',
            name: 'Name',
            sortable: true,
            width: '2fr',
            renderCell: (props) => {
                const id = (props.row as Product).id!;
                const name = (props.row as Product).product_name;
                const product_description = (props.row as Product).product_description;
                const net_price = (props.row as Product).net_price;
                const vat_rate = (props.row as Product).vat_rate;
                const eventBusMessage: ProductEditState = {
                    id: id,
                    product_name: name,
                    product_description: product_description,
                    net_price: net_price,
                    vat_rate: vat_rate,
                };
                return (
                    <>
                        <DefaultPillFormatter cellContent={name}>
                            <HoveroverButton
                                contents="Edit"
                                eventBusMessageTarget={editSispUniqueKey}
                                eventBusMessage={eventBusMessage}
                            />
                        </DefaultPillFormatter>
                    </>
                );
            },
        },
        [ProductsColumnKey.PRODUCT_DESCRIPTION]: {
            key: 'product_description',
            name: 'Description',
            width: '5fr',
            sortable: true,
        },
        [ProductsColumnKey.NET_PRICE]: {
            key: 'net_price',
            name: 'Price',
            width: '1fr',
            sortable: true,
        },
        [ProductsColumnKey.VAT_RATE]: {
            key: 'vat_rate',
            name: 'Tax Rate',
            width: '1fr',
            sortable: true,
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[ProductsColumnKey.PRODUCT_NAME],
        Columns[ProductsColumnKey.PRODUCT_DESCRIPTION],
        Columns[ProductsColumnKey.NET_PRICE],
        Columns[ProductsColumnKey.VAT_RATE],
    ];

    const dataGridMeta: DataGridMeta = {
        uniqueKey: props.dataGridUniqueKey,
        entitySingular: props.dataGridEntitySingular,
        entityPlural: props.dataGridEntityPlural,
        columnOptions: Columns,
        defaultColumns: DefaultColumns,
        frozenColumns: [],
    };

    const actionBarMeta: ActionBarMeta = {
        searchPlaceHolder: props.searchFilterPlaceholder,
        includeCounts: true,
    };

    const addSispMeta: AddSispMeta = {
        key: UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP),
        sisp: ProductsAddSisp,
    };

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_products.png',
        iconHeight: 47,
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.MANAGE_PRODUCTS_SERVICES),
    };

    return (
        <>
            <CampusDataGrid
                repository={productRepository}
                actionBarMeta={actionBarMeta}
                addSispMeta={addSispMeta}
                editSispMeta={{ sisp: ProductsEditSisp }}
                dataGridMeta={dataGridMeta}
                promptMeta={promptMeta}
            />
        </>
    );
};
export default ProductsTable;
