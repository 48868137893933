import React, { FunctionComponent } from 'react';
import './loadingindicator.scss';

interface Props {
    subHeader?: boolean;
    buttonBlock?: boolean;
    large?: boolean;
    noMargin?: boolean;
}

const Header: FunctionComponent<Props> = (props: Props) => {
    const sizeClassModifier = props.large ? ' large' : '';
    const style = props.noMargin ? {} : { margin: '10px 0 30px' };

    return (
        <div>
            <div className="clearfix" style={style}>
                {props.buttonBlock ? (
                    <div className={'react-loading-bar button-bar pull-right' + sizeClassModifier} />
                ) : null}
                <div className={'react-loading-bar blue short header-bar' + sizeClassModifier} />
                {props.subHeader ? (
                    <div className={'react-loading-bar shorter sub-header-bar' + sizeClassModifier} />
                ) : null}
            </div>
        </div>
    );
};

export default Header;
