'use strict';

import * as ActionTypes from "./ActionTypes";
import SaleRepository from "./SaleRepository";
import HttpRequestService from "../../Services/HttpRequestService/HttpRequestService";
import Sale from "./Sale";

export function filterSales(textFilter, advancedSearch) {
    return (dispatch) => {
        const token = new HttpRequestService().generateToken();
        dispatch(updateCurrentFilter(textFilter, advancedSearch, token));
        SaleRepository.filter(textFilter, advancedSearch, token, 0)
            .then(response => dispatch(response ? salesLoaded(response) : salesLoadingCancelled()))
            .catch(e => dispatch(salesLoadingFailed(e.message ? e.message : e)));
    }
}

export function loadMore(offset, textFilter, advancedSearch) {
    return (dispatch) => {
        dispatch(salesMoreLoading());
        const token = new HttpRequestService().generateToken();
        SaleRepository.filter(textFilter, advancedSearch, token, offset)
            .then(response => dispatch(response ? salesMoreLoaded(response) : salesLoadingCancelled()))
            .catch(e => dispatch(salesLoadingFailed(e.message ? e.message : e)));
    }
}

export function loadIdList(idList) {
    return (dispatch) => {
        dispatch(salesMoreLoading());
        SaleRepository.loadIdList(idList)
            .then(response => dispatch(salesSpecificIdsLoaded(response)))
            .catch(e => dispatch(salesLoadingFailed(e.message ? e.message : e)));
    }
}

function updateCurrentFilter(textFilter, advancedSearch, token) {
    return {
        type: ActionTypes.SALES_UPDATE_FILTER,
        payload: {
            textFilter: textFilter,
            advancedSearch: advancedSearch,
            requestToken: token
        }
    }
}

function salesLoaded(sales) {
    return  {
        type: ActionTypes.SALES_LOADED,
        payload: {
            sales: sales
                ? {
                    ...sales,
                    items: sales.items.reduce((result, sale) => {
                        try {
                            result.push(Sale.fromApi(sale));
                        } catch (e) {
                        }
                        return result;
                    }, [])
                }
                : sales
        }
    }
}

function salesMoreLoading() {
    return {
        type: ActionTypes.SALES_MORE_LOADING
    }
}

function salesMoreLoaded(sales) {
    return {
        type: ActionTypes.SALES_MORE_LOADED,
        payload: {
            sales: sales
                ? {
                    ...sales,
                    items: sales.items.reduce((result, sale) => {
                        try {
                            result.push(Sale.fromApi(sale));
                        } catch (e) {
                        }
                        return result;
                    }, [])
                }
                : sales
        }
    }
}

function salesSpecificIdsLoaded(sales) {
    return {
        type: ActionTypes.SALES_IDS_LOADED,
        payload: {
            sales: sales
                ? {
                    ...sales,
                    items: sales.items.reduce((result, sale) => {
                        try {
                            result.push(Sale.fromApi(sale));
                        } catch (e) {
                        }
                        return result;
                    }, [])
                }
                : sales
        }
    }
}

function salesLoadingCancelled() {
    return {
        type: ActionTypes.SALES_LOADING_CANCELLED
    }
}

function salesLoadingFailed(error) {
    return {
        type: ActionTypes.SALES_LOADING_FAILED,
        payload: {
            error: error
        }
    }
}

export function linkSaleToDeal(saleId, dealId) {
    return (dispatch) => {
        dispatch(linkingSaleToDeal(saleId));
        SaleRepository.linkToDeal(saleId, dealId)
            .then(response => dispatch(saleLinkedToDeal(response)))
            .catch(e => dispatch(linkingSaleToDealFailed(e.message ? e.message : e)));
    }
}

function linkingSaleToDeal(id) {
    return {
        type: ActionTypes.SALE_LINKING_TO_DEAL,
        payload: {
            id: id
        }
    }
}

function saleLinkedToDeal(rawEntity) {
    return {
        type: ActionTypes.SALE_LINKED_TO_DEAL,
        payload: {
            data: rawEntity
        }
    }
}

function linkingSaleToDealFailed(error) {
    return {
        type: ActionTypes.SALE_LINKING_TO_DEAL_FAILED,
        payload: {
            error: error
        }
    }
}