import {
    AppEvent,
    Checkbox,
    DropDown,
    EventBusInstance,
    isValidString,
    LogLevel,
    OptionTypeBase,
    showBanner,
    Sisp,
} from '@sprint/sprint-react-components';
import { format, parse } from 'date-fns';
import _ from 'lodash';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { EngagementMethodsRequest } from '../../Api/EngagementMethodsRequest';
import { RepositoryFactoryContext } from '../../index';
import EngagementMethodType from '../../Models/EngagementMethodType';
import { ProcessingGround } from '../../Models/Enums';
import './EngagementMethodsSisps.scss';

interface Props {
    uniqueKey: string;
    onSuccess: (event: any) => Promise<boolean>;
}

const EngagementMethodsEditSisp: FunctionComponent<Props> = (props: Props) => {
    const engagementMethodsRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new EngagementMethodsRequest(),
    );

    const focusRef = useRef<HTMLInputElement>(null);

    // State: General
    const [shown, setShown] = useState<boolean>(false);

    const [rowId, setRowId] = useState<number>(0);
    const [editEngagementMethod, setEditEngagementMethod] = useState<string>('');
    const [editDefault, setEditDefault] = useState<boolean>(false);
    const [editProcessingGround, setEditProcessingGround] = useState<OptionTypeBase>();
    const [editEngagementMethodDate, setEditEngagementMethodDate] = useState<Date>();

    const validationStateDefault = {
        engagementMethod: true,
        processingGround: true,
    };
    const [validationState, setValidationState] = useState(validationStateDefault);

    useEffect(() => {
        if (shown) {
            if (focusRef.current) {
                focusRef.current.focus();
                focusRef.current.selectionStart = focusRef.current.value.length;
                focusRef.current.selectionEnd = focusRef.current.value.length;
            }
            setValidationState(validationStateDefault);
        }
    }, [shown]);

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<EngagementMethodType>) => {
            if (event.target !== props.uniqueKey) return;
            setRowId(event.message.id!);
            setEditEngagementMethod(event.message.engagement_method);
            setEditProcessingGround({
                value: event.message.processing_ground,
                label: event.message.processing_ground,
            });
            if (event.message.default_date) {
                setEditEngagementMethodDate(parse(event.message.default_date, 'do, LLL yyyy', new Date()));
            } else {
                setEditEngagementMethodDate(undefined);
            }
            setEditDefault(event.message.is_default);
            setShown(true);
        });
    }, []);

    const validate = async (): Promise<boolean> => {
        const editValidationState = {
            engagementMethod: !!editEngagementMethod && isValidString(editEngagementMethod),
            processingGround: !!editProcessingGround,
        };

        setValidationState(editValidationState);
        return _.every(editValidationState);
    };

    const handleEditRow = async (): Promise<boolean> => {
        const EngagementMethod: EngagementMethodType = {
            id: rowId,
            engagement_method: editEngagementMethod,
            processing_ground: editProcessingGround!.value,
            default_date: editEngagementMethodDate ? format(editEngagementMethodDate, 'dd/MM/yyyy') : undefined,
            is_default: editDefault,
        };

        return engagementMethodsRepository
            .update(EngagementMethod)
            .then((results: any) => {
                props.onSuccess(results.data);
                showBanner({
                    message: 'Engagement Method details have been updated',
                    level: LogLevel.SUCCESS,
                });
                return Promise.resolve(true);
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to edit Engagement Method details - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return Promise.resolve(false);
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        if ((await validate()) && (await handleEditRow())) setShown(false);
    };

    return (
        <Sisp
            className="engagement-methods-sisp-edit"
            isOpen={shown}
            onSubmit={handleEditRow}
            onCancel={() => {
                setShown(false);
            }}
            validate={validate}
        >
            <h4>Edit an Engagement Method</h4>
            <Form onSubmit={onSubmitForm}>
                <Form.Group>
                    <Form.Label>
                        Engagement Method <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        ref={focusRef}
                        type="text"
                        isInvalid={!validationState.engagementMethod}
                        value={editEngagementMethod || ''}
                        onChange={(event) => {
                            setEditEngagementMethod(event.target.value);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {!validationState.engagementMethod && 'This field is required.'}
                    </Form.Control.Feedback>
                    <Form.Group style={{ marginTop: '15px' }}>
                        <Checkbox
                            label="Make this the default engagement method"
                            isChecked={editDefault}
                            onChange={(event) => setEditDefault(event.target.checked)}
                        />
                    </Form.Group>
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        Processing Ground <span className="required-field-marker">*</span>
                    </Form.Label>
                    <DropDown
                        value={editProcessingGround}
                        isInvalid={false}
                        onChange={(selected: OptionTypeBase) => {
                            setEditProcessingGround(selected);
                        }}
                        options={Object.values(ProcessingGround).map((value: string) => {
                            return {
                                value: value,
                                label: value,
                            };
                        })}
                        menuPosition="fixed"
                    />
                    <Form.Control.Feedback type="invalid">
                        {!validationState.processingGround && 'This field is required.'}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="engagement-method-date">
                    <Form.Label>Engagement Method Date</Form.Label>
                    <br />
                    <Form.Text muted>Does your Engagement Method relate to a distinct date?</Form.Text>
                    <DatePicker
                        className="engagement-method-date-picker"
                        selected={editEngagementMethodDate}
                        onChange={(date: Date) => setEditEngagementMethodDate(date)}
                        isClearable={true}
                        dateFormat="dd/MM/yyyy"
                    />
                </Form.Group>
            </Form>
        </Sisp>
    );
};

export default EngagementMethodsEditSisp;
