/* eslint-disable quotes */
/**
 * This file was generated by a script and should not be changed manually.
 * Any modifications to this file may cause errors or inconsistencies.
 * It can be regenerated in flourish-and-blotts.
 */

export enum IdaciQuintile {
    '1st Fifth (least deprived)' = '1st Fifth (least deprived)',
    '2nd Fifth' = '2nd Fifth',
    '3rd Fifth' = '3rd Fifth',
    '4th Fifth' = '4th Fifth',
    '5th Fifth (most deprived)' = '5th Fifth (most deprived)',
}

export enum UngradedInspectionOverallOutcome {
    'Remains Good' = 'Remains Good',
    'Remains Good (Improving)' = 'Remains Good (Improving)',
    'Remains Good (Concerns)' = 'Remains Good (Concerns)',
    'Remains Outstanding' = 'Remains Outstanding',
    'Remains Outstanding (Concerns)' = 'Remains Outstanding (Concerns)',
}

export enum InspectionType {
    'Academy First Section 5' = 'Academy First Section 5',
    'Exempt School Inspection' = 'Exempt School Inspection',
    'Exempt School Inspection (Following Complaint)' = 'Exempt School Inspection (Following Complaint)',
    'Maintained Academy and School Short inspection' = 'Maintained Academy and School Short inspection',
    'Non-Exempt Inspection Following Request From Provider For Inspection' = 'Non-Exempt Inspection Following Request From Provider For Inspection',
    'Notice to Improve Section 5 Reinspection (Historic)' = 'Notice to Improve Section 5 Reinspection (Historic)',
    'Pilot 2011 Section 8 Deemed Section 5 (Historic)' = 'Pilot 2011 Section 8 Deemed Section 5 (Historic)',
    'Requires Improvement Monitoring (Visit 1)' = 'Requires Improvement Monitoring (Visit 1)',
    'Requires Improvement Monitoring (Visit 2)' = 'Requires Improvement Monitoring (Visit 2)',
    'Requires Improvement Monitoring (Visit 3)' = 'Requires Improvement Monitoring (Visit 3)',
    'Requires Improvement Section 5 Reinspection (Visit 1)' = 'Requires Improvement Section 5 Reinspection (Visit 1)',
    'Requires Improvement Section 5 Reinspection (Visit 2)' = 'Requires Improvement Section 5 Reinspection (Visit 2)',
    'Requires Improvement Section 5 Reinspection (Visit 3)' = 'Requires Improvement Section 5 Reinspection (Visit 3)',
    'Requires Improvement Section 5 Reinspection (Visit 4)' = 'Requires Improvement Section 5 Reinspection (Visit 4)',
    'Section 5 Inspection' = 'Section 5 Inspection',
    'Section 8 Deemed Section 5 Pilot Inspection (08-09 Historic)' = 'Section 8 Deemed Section 5 Pilot Inspection (08-09 Historic)',
    'Section 8 No Formal Designation Visit' = 'Section 8 No Formal Designation Visit',
    'Schools into Special Measures (Visit 1)' = 'Schools into Special Measures (Visit 1)',
    'Schools into Special Measures (Visit 2)' = 'Schools into Special Measures (Visit 2)',
    'Schools into Special Measures (Visit 3)' = 'Schools into Special Measures (Visit 3)',
    'Schools into Special Measures (Visit 4)' = 'Schools into Special Measures (Visit 4)',
    'Schools into Special Measures (Visit 5)' = 'Schools into Special Measures (Visit 5)',
    'Schools with Serious Weaknesses (Visit 1)' = 'Schools with Serious Weaknesses (Visit 1)',
    'Schools with Serious Weaknesses (Visit 2)' = 'Schools with Serious Weaknesses (Visit 2)',
    'Schools with Serious Weaknesses (Visit 3)' = 'Schools with Serious Weaknesses (Visit 3)',
    'Section 5 Response to Intervention (Sep 06 - Aug 07 Historic)' = 'Section 5 Response to Intervention (Sep 06 - Aug 07 Historic)',
    'Section 5 Response to Intervention (Historic)' = 'Section 5 Response to Intervention (Historic)',
    'Section 8 Inspection Due to Parental Complaint' = 'Section 8 Inspection Due to Parental Complaint',
    'Section 8 Inspection of Good and Outstanding Providers' = 'Section 8 Inspection of Good and Outstanding Providers',
    'Section 8 Inspection of Good and Non-Exempt Outstanding Providers' = 'Section 8 Inspection of Good and Non-Exempt Outstanding Providers',
    'Section 5 Serious Weaknesses Reinspection' = 'Section 5 Serious Weaknesses Reinspection',
    'Special Measures (Historic)' = 'Special Measures (Historic)',
    'Section 5 Special Measures Reinspection' = 'Section 5 Special Measures Reinspection',
    'Independent School Pre-Registration Inspection' = 'Independent School Pre-Registration Inspection',
    'Independent School Material Change Inspection' = 'Independent School Material Change Inspection',
    'Independent School Standard Inspection' = 'Independent School Standard Inspection',
    'Independent School Progress Monitoring Inspection' = 'Independent School Progress Monitoring Inspection',
    'Independent School Emergency Inspection' = 'Independent School Emergency Inspection',
}

export enum InspectionTypeGrouping {
    'Academy First Section 5' = 'Academy First Section 5',
    'Notice to Improve Section 5 Reinspection (Historic)' = 'Notice to Improve Section 5 Reinspection (Historic)',
    'Requires Improvement Section 5 Reinspection (Visit 1)' = 'Requires Improvement Section 5 Reinspection (Visit 1)',
    'Requires Improvement Section 5 Reinspection (Visit 2)' = 'Requires Improvement Section 5 Reinspection (Visit 2)',
    'Requires Improvement Section 5 Reinspection (Visit 3)' = 'Requires Improvement Section 5 Reinspection (Visit 3)',
    'Requires Improvement Section 5 Reinspection (Visit 4)' = 'Requires Improvement Section 5 Reinspection (Visit 4)',
    'Section 5 Inspection' = 'Section 5 Inspection',
    'Section 5 Response to Intervention (Sep 06 - Aug 07 Historic)' = 'Section 5 Response to Intervention (Sep 06 - Aug 07 Historic)',
    'Section 5 Response to Intervention (Historic)' = 'Section 5 Response to Intervention (Historic)',
    'Section 8 Conversion' = 'Section 8 Conversion',
    'Section 8 Deemed Section 5' = 'Section 8 Deemed Section 5',
    'Section 5 Serious Weaknesses Reinspection' = 'Section 5 Serious Weaknesses Reinspection',
    'Section 5 Special Measures Reinspection' = 'Section 5 Special Measures Reinspection',
    'Independent School Pre-Registration Inspection' = 'Independent School Pre-Registration Inspection',
    'Independent School Material Change Inspection' = 'Independent School Material Change Inspection',
    'Independent School Standard Inspection' = 'Independent School Standard Inspection',
    'Independent School Progress Monitoring Inspection' = 'Independent School Progress Monitoring Inspection',
    'Independent School Emergency Inspection' = 'Independent School Emergency Inspection',
    'Independent School Education and Boarding/Residential Aligned Inspection' = 'Independent School Education and Boarding/Residential Aligned Inspection',
}

export enum EventTypeGrouping {
    'Independent School Inspection' = 'Independent School Inspection',
    'Section 5' = 'Section 5',
    'Section 8 Deemed Section 5)' = 'Section 8 Deemed Section 5)',
    'Section 8 Conversion' = 'Section 8 Conversion',
}

export enum OverallEffectivenessScore {
    'Outstanding' = 'Outstanding',
    'Good' = 'Good',
    'Requires Improvement' = 'Requires Improvement',
    'Inadequate' = 'Inadequate',
    'No Judgement' = 'No Judgement',
}

export enum CategoryOfConcern {
    'Special Measures' = 'Special Measures',
    'Serious Weakness' = 'Serious Weakness',
}

export enum QualityOfEducationScore {
    'Outstanding' = 'Outstanding',
    'Good' = 'Good',
    'Requires Improvement' = 'Requires Improvement',
    'Inadequate' = 'Inadequate',
    'No Judgement' = 'No Judgement',
}

export enum BehaviourAndAttitudesScore {
    'Outstanding' = 'Outstanding',
    'Good' = 'Good',
    'Requires Improvement' = 'Requires Improvement',
    'Inadequate' = 'Inadequate',
    'No Judgement' = 'No Judgement',
}

export enum PersonalDevelopmentScore {
    'Outstanding' = 'Outstanding',
    'Good' = 'Good',
    'Requires Improvement' = 'Requires Improvement',
    'Inadequate' = 'Inadequate',
    'No Judgement' = 'No Judgement',
}

export enum EffectivenessOfLeadershipAndManagementScore {
    'Outstanding' = 'Outstanding',
    'Good' = 'Good',
    'Requires Improvement' = 'Requires Improvement',
    'Inadequate' = 'Inadequate',
}

export enum IsSafeguardingEffective {
    'Yes' = 'Yes',
    'No' = 'No',
    'No Judgement' = 'No Judgement',
}

export enum EarlyYearsProvisionScore {
    'Outstanding' = 'Outstanding',
    'Good' = 'Good',
    'Requires Improvement' = 'Requires Improvement',
    'Inadequate' = 'Inadequate',
    'Does Not Apply' = 'Does Not Apply',
    'No Judgement' = 'No Judgement',
    'Insufficient Evidence' = 'Insufficient Evidence',
}

export enum SixthFormProvisionScore {
    'Outstanding' = 'Outstanding',
    'Good' = 'Good',
    'Requires Improvement' = 'Requires Improvement',
    'Inadequate' = 'Inadequate',
    'No Judgement' = 'No Judgement',
    'Insufficient Evidence' = 'Insufficient Evidence',
}

export enum OverallEffectivenessPreviousScore {
    'Outstanding' = 'Outstanding',
    'Good' = 'Good',
    'Requires Improvement' = 'Requires Improvement',
    'Inadequate' = 'Inadequate',
    'No Judgement' = 'No Judgement',
}

export enum CategoryOfConcernPreviousScore {
    'Special Measures' = 'Special Measures',
    'Serious Weakness' = 'Serious Weakness',
}

export enum QualityOfEducationPreviousScore {
    'Outstanding' = 'Outstanding',
    'Good' = 'Good',
    'Requires Improvement' = 'Requires Improvement',
    'Inadequate' = 'Inadequate',
    'No Judgement' = 'No Judgement',
}

export enum BehaviourAndAttitudePreviousScore {
    'Outstanding' = 'Outstanding',
    'Good' = 'Good',
    'Requires Improvement' = 'Requires Improvement',
    'Inadequate' = 'Inadequate',
    'No Judgement' = 'No Judgement',
}

export enum PersonalDevelopmentPreviousScore {
    'Outstanding' = 'Outstanding',
    'Good' = 'Good',
    'Requires Improvement' = 'Requires Improvement',
    'Inadequate' = 'Inadequate',
    'No Judgement' = 'No Judgement',
}

export enum EffectivenessOfLeadershipAndManagementPreviousScore {
    'Outstanding' = 'Outstanding',
    'Good' = 'Good',
    'Requires Improvement' = 'Requires Improvement',
    'Inadequate' = 'Inadequate',
}

export enum IsSafeguardingEffectivePreviousScore {
    'Yes' = 'Yes',
    'No' = 'No',
    'No Judgement' = 'No Judgement',
}

export enum EarlyYearsProvisionPreviousScore {
    'Outstanding' = 'Outstanding',
    'Good' = 'Good',
    'Requires Improvement' = 'Requires Improvement',
    'Inadequate' = 'Inadequate',
    'Does Not Apply' = 'Does Not Apply',
    'No Judgement' = 'No Judgement',
    'Insufficient Evidence' = 'Insufficient Evidence',
}

export enum SixthFormProvisionPreviousScore {
    'Outstanding' = 'Outstanding',
    'Good' = 'Good',
    'Requires Improvement' = 'Requires Improvement',
    'Inadequate' = 'Inadequate',
    'No Judgement' = 'No Judgement',
    'Insufficient Evidence' = 'Insufficient Evidence',
}

export function ofstedLabelFactory(property: string | undefined) {
    switch (property) {
        case 'idaciQuintile':
            return `IDACI Quintile`;
        case 'latestUngradedInspectionNumberSinceLastInspection':
            return `Latest Inspection No. Since Last Graded Inspection`;
        case 'ungradedInspectionRelatesToUrnOfSchool':
            return `Inspection Relates to URN of School`;
        case 'urnDuringUngradedInspection':
            return `URN During Inspection`;
        case 'laEstablishmentDuringUngradedInspection':
            return `LA Establishment No. During Inspection`;
        case 'numberUngradedInspectionsSinceLastGradedInspection':
            return `No. Inspections Since Last Graded Inspection`;
        case 'dateOfLastUngradedInspection':
            return `Last Inspection Date`;
        case 'ungradedInspectionPublicationDate':
            return `Inspection Published Date`;
        case 'didLatestUngradedInspectionConvertToGradedInspection':
            return `Converted to Graded Inspection`;
        case 'ungradedInspectionOverallOutcome':
            return `Overall Outcome`;
        case 'numberOfMonitoringAndUrgentInspectionsSinceLastGradedInspection':
            return `No. Monitoring/Urgent Inspections Since Last Graded Inspection`;
        case 'inspectionNumberOfLatestGradedInspection':
            return `Inspection No. of Latest Graded Inspection`;
        case 'inspectionType':
            return `Inspection Type`;
        case 'inspectionTypeGrouping':
            return `Inspection Type Grouping`;
        case 'eventTypeGrouping':
            return `Event Type Grouping`;
        case 'inspectionStartDate':
            return `Inspection Start Date`;
        case 'inspectionEndDate':
            return `Inspection End Date`;
        case 'publicationDate':
            return `Publication Date`;
        case 'gradedInspectionRelatesToUrnOfSchool':
            return `Inspection Relates to URN of School`;
        case 'urnDuringGradedInspection':
            return `URN During Inspection`;
        case 'laEstablishmentDuringGradedInspection':
            return `LA Establishment No. During Inspection`;
        case 'overallEffectivenessScore':
            return `Overall Effectiveness`;
        case 'categoryOfConcern':
            return `Category of Concern`;
        case 'qualityOfEducationScore':
            return `Quality of Education`;
        case 'behaviourAndAttitudesScore':
            return `Behaviour and Attitudes`;
        case 'personalDevelopmentScore':
            return `Personal Development`;
        case 'effectivenessOfLeadershipAndManagementScore':
            return `Effectiveness of Leadership and Management`;
        case 'isSafeguardingEffective':
            return `Safeguarding is Effective`;
        case 'earlyYearsProvisionScore':
            return `Early Years Provision`;
        case 'sixthFormProvisionScore':
            return `Sixth Form Provision`;
        case 'previousGradedInspectionNumber':
            return `Previous Graded Inspection No.`;
        case 'previousInspectionStartDate':
            return `Previous Inspection Start Date`;
        case 'previousInspectionEndDate':
            return `Previous Inspection End Date`;
        case 'previousPublicationDate':
            return `Previous Publication Date`;
        case 'previousGradedInspectionRelatesToUrnOfSchool':
            return `Previous Inspection Relates to URN of School`;
        case 'urnDuringPreviousGradedInspection':
            return `URN During Previous Inspection`;
        case 'laEstablishmentDuringPreviousGradedInspection':
            return `LA Establishment No. During Previous Inspection`;
        case 'overallEffectivenessPreviousScore':
            return `Overall Effectiveness of Previous Inspection`;
        case 'categoryOfConcernPreviousScore':
            return `Category of Concern of Previous Inspection`;
        case 'qualityOfEducationPreviousScore':
            return `Quality of Education of Previous Inspection`;
        case 'behaviourAndAttitudePreviousScore':
            return `Behaviour and Attitudes of Previous Inspection`;
        case 'personalDevelopmentPreviousScore':
            return `Personal Development of Previous Inspection`;
        case 'effectivenessOfLeadershipAndManagementPreviousScore':
            return `Effectiveness of Leadership and Management of Previous Inspection`;
        case 'isSafeguardingEffectivePreviousScore':
            return `Safeguarding was Effective in Previous Inspection`;
        case 'earlyYearsProvisionPreviousScore':
            return `Early Years Provision at Previous Inspection`;
        case 'sixthFormProvisionPreviousScore':
            return `Sixth Form Provision at Previous Inspection`;
        case 'source':
            return `Source`;
        default:
            return undefined;
    }
}

export function ofstedSummaryFactory(property: string | undefined) {
    switch (property) {
        case 'idaciQuintile':
            return `<p>IDACI (Income Deprivation Affecting Children Index 2019) is used to indicate deprivation</p>`;
        case 'latestUngradedInspectionNumberSinceLastInspection':
            return `<p>Inspection number for the latest ungraded inspection following a previous graded inspection</p>`;
        case 'ungradedInspectionRelatesToUrnOfSchool':
            return `<p>Whether the ungraded inspection relates to the unique reference number of the current school</p>`;
        case 'urnDuringUngradedInspection':
            return `<p>Unique reference number at the time of the ungraded inspection</p>`;
        case 'laEstablishmentDuringUngradedInspection':
            return `<p>Local authority establishment number at the time of the ungraded inspection</p>`;
        case 'numberUngradedInspectionsSinceLastGradedInspection':
            return `<p>Number of ungraded inspections since the last graded inspection</p>`;
        case 'dateOfLastUngradedInspection':
            return `<p>Last date of ungraded inspection</p>`;
        case 'ungradedInspectionPublicationDate':
            return `<p>Ungraded inspection publication date</p>`;
        case 'didLatestUngradedInspectionConvertToGradedInspection':
            return `<p>Whether the latest ungraded inspection converted into a graded inspection</p>`;
        case 'ungradedInspectionOverallOutcome':
            return `<p>Overall outcome of the ungraded inspection</p>`;
        case 'numberOfMonitoringAndUrgentInspectionsSinceLastGradedInspection':
            return `<p>Number of monitoring or urgent inspections since the last graded inspection</p>`;
        case 'inspectionNumberOfLatestGradedInspection':
            return `<p>Inspection number of latest graded inspection</p>`;
        case 'inspectionType':
            return `<p>Type of inspection undertaken</p>`;
        case 'inspectionTypeGrouping':
            return `<p>Inspection Type Grouping</p>`;
        case 'eventTypeGrouping':
            return `<p>Event type grouping</p>`;
        case 'inspectionStartDate':
            return `<p>Inspection start date</p>`;
        case 'inspectionEndDate':
            return `<p>Inspection end date</p>`;
        case 'publicationDate':
            return `<p>Inspection publication date</p>`;
        case 'gradedInspectionRelatesToUrnOfSchool':
            return `<p>Whether the graded inspection relates to the unique reference number of the current school</p>`;
        case 'urnDuringGradedInspection':
            return `<p>Unique reference number at the time of the latest graded inspection</p>`;
        case 'laEstablishmentDuringGradedInspection':
            return `<p>Local authority establishment number at the time of the latest graded inspection</p>`;
        case 'overallEffectivenessScore':
            return `<p>Overall effectiveness of the establishment</p>`;
        case 'categoryOfConcern':
            return `<p>Type of category of concern arising from an inadequate inspection</p>`;
        case 'qualityOfEducationScore':
            return `<p>Quality of education provided at the establishment</p>`;
        case 'behaviourAndAttitudesScore':
            return `<p>Behaviour and attitudes at the establishment</p>`;
        case 'personalDevelopmentScore':
            return `<p>Personal development at the establishment</p>`;
        case 'effectivenessOfLeadershipAndManagementScore':
            return `<p>Effectiveness of leadership and management at the establishment</p>`;
        case 'isSafeguardingEffective':
            return `<p>Is the safeguarding effective at the establishment</p>`;
        case 'earlyYearsProvisionScore':
            return `<p>Score of early years provision if it is provided</p>`;
        case 'sixthFormProvisionScore':
            return `<p>Score of sixth form provision if it is provided</p>`;
        case 'previousGradedInspectionNumber':
            return `<p>Previous Graded Inspection Number</p>`;
        case 'previousInspectionStartDate':
            return `<p>Start date of the previous inspection</p>`;
        case 'previousInspectionEndDate':
            return `<p>End date of the previous inspection</p>`;
        case 'previousPublicationDate':
            return `<p>Date of the previous publication</p>`;
        case 'previousGradedInspectionRelatesToUrnOfSchool':
            return `<p>Whether the previous graded inspection relates to the unique reference number of the current school</p>`;
        case 'urnDuringPreviousGradedInspection':
            return `<p>Unique reference number at the time of the previous graded inspection</p>`;
        case 'laEstablishmentDuringPreviousGradedInspection':
            return `<p>Local authority establishment number at the time of the previous graded inspection.</p>`;
        case 'overallEffectivenessPreviousScore':
            return `<p>Overall effectiveness of the previous graded inspection</p>`;
        case 'categoryOfConcernPreviousScore':
            return `<p>Category of concern of the previous graded inspection</p>`;
        case 'qualityOfEducationPreviousScore':
            return `<p>Quality of education of the previous graded inspection</p>`;
        case 'behaviourAndAttitudePreviousScore':
            return `<p>Behaviour and attitudes of the previous graded inspection</p>`;
        case 'personalDevelopmentPreviousScore':
            return `<p>Personal development of the previous graded inspection</p>`;
        case 'effectivenessOfLeadershipAndManagementPreviousScore':
            return `<p>Effectiveness of leadership and management of the previous graded inspection</p>`;
        case 'isSafeguardingEffectivePreviousScore':
            return `<p>Whether safeguarding was effective in the previous inspection</p>`;
        case 'earlyYearsProvisionPreviousScore':
            return `<p>Early years provision at the previous graded Inspection</p>`;
        case 'sixthFormProvisionPreviousScore':
            return `<p>Sixth form provision at the previous graded Inspection</p>`;
        case 'source':
            return `<p>The government source from which the data originates</p>`;
        default:
            return undefined;
    }
}

export function ofstedDescriptionFactory(property: string | undefined) {
    switch (property) {
        case 'idaciQuintile':
            return `<h4>IDACI</h4>
<p>The deprivation of a school is based on the mean of the deprivation indices associated with the home post codes of the pupils attending the school rather than the location of the school itself. This makes it very reliable in that you might have a school in an affluent area with a large intake of pupils from a neighbouring deprived area. The IDACI accounts for this.</p>

<p>Schools are divided into five equal groups (quintiles), from ‘most deprived’ (quintile 5) to ‘least deprived’ (quintile 1).</p>

<p>Note that this data is only available for English state schools.</p>`;
        case 'latestUngradedInspectionNumberSinceLastInspection':
            return `<h4>Graded Inspections</h4>
<p>These are the regular inspections that most people are familiar with. During these inspections, Ofsted inspectors visit schools, colleges, and other institutions to evaluate the quality of education, the behavior and attitudes of students, the personal development of students, the effectiveness of leadership and management, and early years provision where applicable. At the end of a graded inspection, the institution receives a judgement that falls into one of four categories: 'Outstanding', 'Good', 'Requires Improvement', or 'Inadequate'.</p>

<h4>Ungraded Inspections</h4>
<p>Ungraded inspections are typically more focused and do not result in the standard four-point scale judgement. They may be monitoring visits, which are used to check on the progress of a school that has been judged to require improvement or has been placed in special measures. There can also be interim visits to check on specific areas of concern or to follow up on complaints. These inspections are about ensuring compliance with certain regulations or to confirm that previously identified issues have been addressed. The outcome of these inspections is a report that does not grade the institution overall but provides commentary on whether standards are being met or whether improvements have been made.</p>

<p>The main difference between the two is the outcome: graded inspections lead to a judgement on the overall effectiveness of the institution, while ungraded inspections are typically focused on specific aspects and do not result in a comprehensive grade.</p>`;
        case 'ungradedInspectionRelatesToUrnOfSchool':
            return `<h4>Unique Reference Number</h4>
<p>Also known as a URN this is a number assigned to each school, college, and childcare provider in the UK by the Department for Education (DfE). It is used to identify the institution in all official records and communications.</p>

<h4>Ungraded Inspections</h4>

<p>Ungraded inspections are typically more focused and do not result in the standard four-point scale judgement. They may be monitoring visits, which are used to check on the progress of a school that has been judged to require improvement or has been placed in special measures. There can also be interim visits to check on specific areas of concern or to follow up on complaints. These inspections are about ensuring compliance with certain regulations or to confirm that previously identified issues have been addressed. The outcome of these inspections is a report that does not grade the institution overall but provides commentary on whether standards are being met or whether improvements have been made.</p>

<p>The main difference between the two is the outcome: graded inspections lead to a judgement on the overall effectiveness of the institution, while ungraded inspections are typically focused on specific aspects and do not result in a comprehensive grade.</p>`;
        case 'urnDuringUngradedInspection':
            return `<h4>Unique Reference Number</h4>
<p>Also known as a URN this is a number assigned to each school, college, and childcare provider in the UK by the Department for Education (DfE). It is used to identify the institution in all official records and communications.</p>

<h4>Ungraded Inspections</h4>
<p>Ungraded inspections are typically more focused and do not result in the standard four-point scale judgement. They may be monitoring visits, which are used to check on the progress of a school that has been judged to require improvement or has been placed in special measures. There can also be interim visits to check on specific areas of concern or to follow up on complaints. These inspections are about ensuring compliance with certain regulations or to confirm that previously identified issues have been addressed. The outcome of these inspections is a report that does not grade the institution overall but provides commentary on whether standards are being met or whether improvements have been made.</p>`;
        case 'laEstablishmentDuringUngradedInspection':
            return `<h4>Local Authority Establishment Number</h4>
<p>The Local Authority (LA) establishment number, sometimes also referred to as the school establishment number, is a unique number assigned to a school by the local authority in which it is located. This number is used in conjunction with the school's Unique Reference Number (URN) to uniquely identify a school within local and national government databases and systems.<p>

<p>Each school has a URN, which is a national identifier, and an LA establishment number, which is a local identifier. When combined, these numbers ensure precise identification of the school for administrative purposes, data collection, and when coordinating between different services or departments that might deal with the school, such as education services, social services, and Ofsted inspections.</p>

<h4>Ungraded Inspections</h4>
<p>Ungraded inspections are typically more focused and do not result in the standard four-point scale judgement. They may be monitoring visits, which are used to check on the progress of a school that has been judged to require improvement or has been placed in special measures. There can also be interim visits to check on specific areas of concern or to follow up on complaints. These inspections are about ensuring compliance with certain regulations or to confirm that previously identified issues have been addressed. The outcome of these inspections is a report that does not grade the institution overall but provides commentary on whether standards are being met or whether improvements have been made.</p>`;
        case 'numberUngradedInspectionsSinceLastGradedInspection':
            return `<p>This only counts inspections which took place since 1 September 2015 (the introduction of the Common Inspection Framework).</p>

<h4>Ungraded Inspections</h4>
<p>Ungraded inspections are typically more focused and do not result in the standard four-point scale judgement. They may be monitoring visits, which are used to check on the progress of a school that has been judged to require improvement or has been placed in special measures. There can also be interim visits to check on specific areas of concern or to follow up on complaints. These inspections are about ensuring compliance with certain regulations or to confirm that previously identified issues have been addressed. The outcome of these inspections is a report that does not grade the institution overall but provides commentary on whether standards are being met or whether improvements have been made.</p>

<p>The main difference between the two is the outcome: graded inspections lead to a judgement on the overall effectiveness of the institution, while ungraded inspections are typically focused on specific aspects and do not result in a comprehensive grade.</p>`;
        case 'dateOfLastUngradedInspection':
            return `<h4>Ungraded Inspections</h4>
<p>Ungraded inspections are typically more focused and do not result in the standard four-point scale judgement. They may be monitoring visits, which are used to check on the progress of a school that has been judged to require improvement or has been placed in special measures. There can also be interim visits to check on specific areas of concern or to follow up on complaints. These inspections are about ensuring compliance with certain regulations or to confirm that previously identified issues have been addressed. The outcome of these inspections is a report that does not grade the institution overall but provides commentary on whether standards are being met or whether improvements have been made.</p>`;
        case 'ungradedInspectionPublicationDate':
            return `<h4>Ungraded Inspections</h4>
<p>Ungraded inspections are typically more focused and do not result in the standard four-point scale judgement. They may be monitoring visits, which are used to check on the progress of a school that has been judged to require improvement or has been placed in special measures. There can also be interim visits to check on specific areas of concern or to follow up on complaints. These inspections are about ensuring compliance with certain regulations or to confirm that previously identified issues have been addressed. The outcome of these inspections is a report that does not grade the institution overall but provides commentary on whether standards are being met or whether improvements have been made.</p>`;
        case 'didLatestUngradedInspectionConvertToGradedInspection':
            return `<h4>Graded Inspections</h4>
<p>These are the regular inspections that most people are familiar with. During these inspections, Ofsted inspectors visit schools, colleges, and other institutions to evaluate the quality of education, the behavior and attitudes of students, the personal development of students, the effectiveness of leadership and management, and early years provision where applicable. At the end of a graded inspection, the institution receives a judgement that falls into one of four categories: 'Outstanding', 'Good', 'Requires Improvement', or 'Inadequate'.</p>

<h4>Ungraded Inspections</h4>
<p>Ungraded inspections are typically more focused and do not result in the standard four-point scale judgement. They may be monitoring visits, which are used to check on the progress of a school that has been judged to require improvement or has been placed in special measures. There can also be interim visits to check on specific areas of concern or to follow up on complaints. These inspections are about ensuring compliance with certain regulations or to confirm that previously identified issues have been addressed. The outcome of these inspections is a report that does not grade the institution overall but provides commentary on whether standards are being met or whether improvements have been made.</p>

<p>The main difference between the two is the outcome: graded inspections lead to a judgement on the overall effectiveness of the institution, while ungraded inspections are typically focused on specific aspects and do not result in a comprehensive grade.</p>`;
        case 'ungradedInspectionOverallOutcome':
            return `<h4>Ungraded Inspections</h4>
<p>Ungraded inspections are typically more focused and do not result in the standard four-point scale judgement. They may be monitoring visits, which are used to check on the progress of a school that has been judged to require improvement or has been placed in special measures. There can also be interim visits to check on specific areas of concern or to follow up on complaints. These inspections are about ensuring compliance with certain regulations or to confirm that previously identified issues have been addressed. The outcome of these inspections is a report that does not grade the institution overall but provides commentary on whether standards are being met or whether improvements have been made.</p>`;
        case 'numberOfMonitoringAndUrgentInspectionsSinceLastGradedInspection':
            return `<h4>Monitoring Inspections</h4>
<p>These occur when a school or further education institution has been judged as 'Requires Improvement' or 'Inadequate' (which includes those placed into 'Special Measures' or having 'Serious Weaknesses'). Monitoring inspections are used to assess the progress the institution has made towards the actions set out in their post-inspection improvement plan. These inspections do not result in a new grade. Instead, the inspector will report on the progress made by the institution in addressing the areas for improvement identified at their previous inspection. For schools in 'Special Measures', these monitoring inspections are a statutory requirement.</p>

<h4>Urgent Inspections</h4>
<p>These can be triggered by concerns about an institution's performance which are raised in various ways – through complaints, other inspections, or information passed to Ofsted. The concerns could be about the quality of education, safeguarding issues, the welfare of pupils, or other serious issues. An urgent inspection is carried out without giving the usual notice to the institution (which might be as short as the same day), to assess the nature and extent of the problems and to determine what actions need to be taken. Depending on the findings, Ofsted may change the school's rating and can place the school into categories of concern such as 'Special Measures' or 'Serious Weaknesses', or recommend other immediate actions.</p>

<h4>Graded Inspections</h4>
<p>These are the regular inspections that most people are familiar with. During these inspections, Ofsted inspectors visit schools, colleges, and other institutions to evaluate the quality of education, the behavior and attitudes of students, the personal development of students, the effectiveness of leadership and management, and early years provision where applicable. At the end of a graded inspection, the institution receives a judgement that falls into one of four categories: 'Outstanding', 'Good', 'Requires Improvement', or 'Inadequate'.</p>`;
        case 'inspectionNumberOfLatestGradedInspection':
            return `<h4>Inspection Number</h4>
<p>The Ofsted inspection number is a unique identifier assigned to each individual inspection that takes place. This number is used to reference and track the specific inspection throughout its lifecycle, from the planning stages through to the publication of the inspection report.</p>

<p>Each time a school or other educational institution is inspected, the resulting report is logged with an Ofsted inspection number. This makes it easier for schools, parents, and other stakeholders to find and reference specific inspection reports on the Ofsted website or in other records. When discussing or inquiring about a particular inspection, providing this number can help ensure that everyone is referring to the same event.</p>

<p>The Ofsted inspection number is different from the Unique Reference Number (URN) which is assigned to each educational institution and remains the same across all inspections that institution undergoes. The inspection number, on the other hand, changes with each new inspection carried out.</p>

<h4>Graded Inspections</h4>
<p>These are the regular inspections that most people are familiar with. During these inspections, Ofsted inspectors visit schools, colleges, and other institutions to evaluate the quality of education, the behavior and attitudes of students, the personal development of students, the effectiveness of leadership and management, and early years provision where applicable. At the end of a graded inspection, the institution receives a judgement that falls into one of four categories: 'Outstanding', 'Good', 'Requires Improvement', or 'Inadequate'.</p>`;
        case 'inspectionType':
            return `<p>Below is a description of each of the types of inspection</p>

<h4>Academy First Section 5</h4>
<p>A "Section 5" inspection refers to the main type of inspection carried out under Section 5 of the Education Act 2005 (as amended). This is the standard inspection for state schools in England, including academies and city technology colleges, as well as some non-association independent schools.</p>

<p>An "Academy First Section 5" inspection, typically means the first full Ofsted inspection that an academy receives under the Section 5 framework. For academies, which are state-funded but independently run schools, this inspection usually takes place within three years of the academy opening or converting from a previously existing state school.</p>

<h4>Exempt School Inspection</h4>
<p>An "Exempt School Inspection" refers to the type of inspection that some schools, which are rated 'Outstanding', may be subject to. Thes schools are exempt from routine inspections under Section 5 of the Education Act 2005. However, they are not exempt from inspection altogether.</p>

<p>Exempt inspections might be less frequent or comprehensive than the standard Section 5 inspections and when Ofsted does inspect these exempt schools, the process may focus on particular areas of concern or on validating the school's continued 'Outstanding' status.</p>

<h4>Exempt School Inspection (Following Complaint)</h4>
<p>These are where an exempt school has an inspection undertaken following a complaint.</p>

<h4>Independent School Standard Inspection</h4>
<p>An "Independent School Standard Inspection" refers to an inspection specifically designed for independent schools in the UK. These inspections assess whether the school meets the Independent School Standards set out by the Department for Education. The focus of these inspections is slightly different from that of state schools due to the independent nature and governance of these institutions.</p>

<p>Unlike state schools, independent schools are not rated on a four-point scale (Outstanding, Good, Requires Improvement, Inadequate). Instead, the report will state whether the school meets or does not meet each of the Independent School Standards. If a school fails to meet one or more of the standards, it will be required to take action to address these areas.</p>

<h4>Maintained Academy and School Short inspection</h4>
<p>A "Maintained Academy and School Short Inspection" is a specific type of inspection designed for certain schools. This inspection format applies to maintained schools, academies, and non-exempt schools that were judged to be 'Good' in their previous full inspection. The purpose of a short inspection is to determine if the school remains 'Good' and to ensure that it continues to provide a high quality of education.</p>

<p>Short inspections are typically shorter than standard inspections, usually lasting for one day and focus on the quality of education, leadership, and safeguarding. Inspectors assess whether the school continues to provide a good standard of education and whether there might be evidence of a decline or improvement.</p>

<p>Unlike standard inspections, a short inspection does not result in a full set of graded judgments. Instead, the possible outcomes are: that the school continues to be 'Good', and the next inspection will also be a short inspection (unless circumstances change); If there is evidence that the school might be 'Outstanding', a full inspection will be arranged to determine if the school can receive the higher rating; If there are concerns that the school may no longer be 'Good', or if it requires improvement or has serious weaknesses, a full inspection will be scheduled to determine the new rating.</p>

<p>The short inspection model is designed to reduce the burden of inspection on high-performing schools while still maintaining accountability and educational standards. It allows Ofsted to focus more resources on schools that are struggling or where there is more uncertainty about the quality of education being provided.</p>

<h4>Non-Exempt Inspection Following Request From Provider For Inspection</h4>
<p>A "Non-Exempt Inspection Following Request From Provider For Inspection" is a type of inspection that occurs when a school or educational provider that is normally exempt from regular inspections requests an inspection from Ofsted.</p>

<p>Schools that are rated as 'Outstanding' by Ofsted are generally exempt from routine inspections, although they can still be inspected under certain circumstances e.g. if they have a desire for an updated evaluation; there has been a change in the school's circumstances; the school requires reassurance to stakeholders; or has a need for strategic planning.</p>

<p>When a non-exempt school requests an inspection, Ofsted will schedule and conduct the inspection similar to regular inspections, assessing the school against the current inspection framework and providing a new rating based on the findings. This proactive approach by schools reflects a commitment to transparency and continuous improvement.</p>

<h4>Notice to Improve Section 5 Reinspection (Historic)</h4>
<p>A "Notice to Improve Section 5 Reinspection" typically refers to a follow-up inspection that occurs after a school has received a notice to improve due to concerns identified in a previous inspection.</p>

<p><strong>Notice to Improve:</strong> This is a formal notification given to a school following an inspection where significant areas of concern are identified. The notice outlines specific areas where the school must make improvements. It is often issued when a school is rated as 'Requires Improvement' or 'Inadequate', particularly in certain key areas.</p>

<p><strong>Section 5 Inspection:</strong> This refers to the regular inspections conducted under Section 5 of the Education Act 2005. These inspections are comprehensive and result in a graded judgment covering various aspects of the school's performance, including the quality of education, behavior and attitudes, personal development, and leadership and management.<p>

<p><strong>Section 5 Reinspection:</strong> After a school has received a Notice to Improve, Ofsted will conduct a reinspection to assess the progress made in addressing the concerns. This reinspection is still a Section 5 inspection, but with a focus on evaluating the improvements made since the previous report. The reinspection will determine whether the school has made sufficient progress in the areas identified for improvement.<p>

<p>During the reinspection, Ofsted inspectors will assess whether the school has effectively addressed the issues outlined in the Notice to Improve and whether overall standards have been raised. The outcome of this reinspection can lead to an updated rating for the school, which could be an improvement or, if insufficient progress has been made, could result in more serious measures such as placing the school in special measures.</p>

<h4>Pilot 2011 Section 8 Deemed Section 5 (Historic)</h4>
<p>A "Pilot 2011 Section 8 Deemed Section 5 inspection" refers to a specific type of inspection process that was part of a pilot program conducted by Ofsted around 2011. This terminology combines elements of two different types of inspections: Section 8 and Section 5 inspections, as defined under the Education Act 2005.</p>

<h4>Requires Improvement Monitoring (Visits 1, 2, and 3)</h4>
<p> "Requires Improvement Monitoring Inspections" are a specific type of inspection conducted at schools that have been rated as 'Requires Improvement' in their previous full Ofsted inspection. The purpose of these monitoring inspections is to evaluate the progress that the school has made in addressing the areas for improvement identified in the previous inspection report.</p>

<h4>Requires Improvement Section 5 Reinspection (Visits 1, 2, 3, and 4)</h4>
<p></p>

<h4>Section 5 Inspection</h4>
<p></p>

<h4>Section 8 Deemed Section 5 Pilot Inspection (08-09 Historic)</h4>
<p></p>

<h4>Section 8 No Formal Designation Visit</h4>
<p></p>

<h4>Schools into Special Measures (Visits 1, 2, 3, 4, and 5)</h4>
<p></p>

<h4>Schools with Serious Weaknesses (Visits 1, 2, and 3)</h4>
<p></p>

<h4>Section 5 Response to Intervention (Sep 06 - Aug 07 Historic)</h4>
<p></p>

<h4>Section 5 Response to Intervention (Historic)</h4>
<p></p>

<h4>Section 8 Inspection Due to Parental Complaint</h4>
<p></p>

<h4>Section 8 Inspection of Good and Outstanding Providers</h4>
<p></p>

<h4>Section 8 Inspection of Good and Non-Exempt Outstanding Providers</h4>
<p></p>

<h4>Section 5 Serious Weaknesses Reinspection</h4>
<p></p>

<h4>Special Measures (Historic)</h4>
<p></p>

<h4>Section 5 Special Measures Reinspection</h4>
<p></p>`;
        case 'gradedInspectionRelatesToUrnOfSchool':
            return `<h4>Unique Reference Number</h4>
<p>Also known as a URN this is a number assigned to each school, college, and childcare provider in the UK by the Department for Education (DfE). It is used to identify the institution in all official records and communications.</p>

<h4>Graded Inspections</h4>
<p>These are the regular inspections that most people are familiar with. During these inspections, Ofsted inspectors visit schools, colleges, and other institutions to evaluate the quality of education, the behavior and attitudes of students, the personal development of students, the effectiveness of leadership and management, and early years provision where applicable. At the end of a graded inspection, the institution receives a judgement that falls into one of four categories: 'Outstanding', 'Good', 'Requires Improvement', or 'Inadequate'.</p>`;
        case 'urnDuringGradedInspection':
            return `<h4>Unique Reference Number</h4>
<p>Also known as a URN this is a number assigned to each school, college, and childcare provider in the UK by the Department for Education (DfE). It is used to identify the institution in all official records and communications.</p>

<h4>Graded Inspections</h4>
<p>These are the regular inspections that most people are familiar with. During these inspections, Ofsted inspectors visit schools, colleges, and other institutions to evaluate the quality of education, the behavior and attitudes of students, the personal development of students, the effectiveness of leadership and management, and early years provision where applicable. At the end of a graded inspection, the institution receives a judgement that falls into one of four categories: 'Outstanding', 'Good', 'Requires Improvement', or 'Inadequate'.</p>`;
        case 'laEstablishmentDuringGradedInspection':
            return `<h4>Graded Inspections</h4>
<p>These are the regular inspections that most people are familiar with. During these inspections, Ofsted inspectors visit schools, colleges, and other institutions to evaluate the quality of education, the behavior and attitudes of students, the personal development of students, the effectiveness of leadership and management, and early years provision where applicable. At the end of a graded inspection, the institution receives a judgement that falls into one of four categories: 'Outstanding', 'Good', 'Requires Improvement', or 'Inadequate'.</p>

<h4>Local Authority Establishment Number</h4>
<p>The Local Authority (LA) establishment number, sometimes also referred to as the school establishment number, is a unique number assigned to a school by the local authority in which it is located. This number is used in conjunction with the school's Unique Reference Number (URN) to uniquely identify a school within local and national government databases and systems.<p>

<p>Each school has a URN, which is a national identifier, and an LA establishment number, which is a local identifier. When combined, these numbers ensure precise identification of the school for administrative purposes, data collection, and when coordinating between different services or departments that might deal with the school, such as education services, social services, and Ofsted inspections.</p>`;
        case 'overallEffectivenessScore':
            return `<h4>Overall effectiveness of the establishment</h4>
<p>The overall effectiveness of an establishment is assessed on a four-point scale. This scale is used to provide a clear, overall judgment on the quality and standards of the establishment being inspected, whether it's a school, college, nursery, or another type of educational or care provider. The four points on the scale are:<p>

<p><strong>Outstanding:</strong> This is the highest rating. An establishment rated as 'Outstanding' is exceptionally effective in delivering outcomes that provide exceptionally well for all its pupils’ needs. This means that the establishment significantly exceeds the minimum requirements and has high-quality standards across all areas.</p>

<p><strong>Good:</strong> Establishments rated as 'Good' are effective in delivering outcomes that provide well for all its pupils' needs. This means that the establishment meets the minimum requirements in most areas and may exceed them in some.</p>

<p><strong>Requires Improvement:</strong> This rating is given to establishments that are not performing as well as they should, and where the quality of education, care, and other key areas are not yet good. These establishments are not failing but have areas that need improvement to reach the 'Good' rating.</p>

<p><strong>Inadequate:</strong></p> This is the lowest rating and indicates that the establishment is failing to meet the minimum requirements in a number of areas. An 'Inadequate' rating can lead to significant intervention, including placing the establishment in special measures or requiring an action plan for improvement.</p>`;
        case 'categoryOfConcern':
            return `<p>"Category of concern" is a designation given to schools that are not meeting certain educational standards. These categories are part of Ofsted's assessment framework and are used to identify schools that require significant improvement. There are two main categories of concern:</p>

<h4>Requires Improvement</h4>
<p>This category is for schools that are not performing as well as they should be and have areas where they need to make improvements. Schools in this category do not have serious failings that would warrant being placed in special measures, but they are not yet achieving a 'Good' standard. Schools in the 'Requires Improvement' category receive more frequent inspections until they improve.</p>

<h4>Inadequate</h4>
<p>This category indicates serious concerns about the performance of the school. There are two sub-categories within 'Inadequate':</p>

<p><strong>Serious Weaknesses:</strong> This is used when a school is failing in one or more key areas but has the capacity to improve without the need for more intensive external support.</p>

<p><strong>Special Measures:</strong> This is the most serious category and is used when a school is failing to provide an acceptable standard of education, and the school’s leaders, managers, or governors have not demonstrated that they have the capacity to secure the necessary improvement. A school in special measures will receive regular monitoring by Ofsted and may be subject to intervention by local authorities or the Department for Education, which can include changes in leadership or governance.

Being placed in a category of concern is a significant matter for a school, requiring targeted action to address the identified issues. The aim of these categories is to ensure that schools receive the support and scrutiny necessary to improve the quality of education they provide to their students.<p>`;
        case 'qualityOfEducationScore':
            return `<h4>Quality of education provided at the establishment</h4>
<p>The quality of education provided at the establishment is assessed on a four-point scale. This scale is used to provide a clear, overall judgment on the quality and standards of the establishment being inspected, whether it's a school, college, nursery, or another type of educational or care provider. The four points on the scale are:<p>

<p><strong>Outstanding:</strong> This is the highest rating. An establishment rated as 'Outstanding' is exceptionally effective in delivering outcomes that provide exceptionally well for all its pupils’ needs. This means that the establishment significantly exceeds the minimum requirements and has high-quality standards across all areas.</p>

<p><strong>Good:</strong> Establishments rated as 'Good' are effective in delivering outcomes that provide well for all its pupils' needs. This means that the establishment meets the minimum requirements in most areas and may exceed them in some.</p>

<p><strong>Requires Improvement:</strong> This rating is given to establishments that are not performing as well as they should, and where the quality of education, care, and other key areas are not yet good. These establishments are not failing but have areas that need improvement to reach the 'Good' rating.</p>

<p><strong>Inadequate:</strong></p> This is the lowest rating and indicates that the establishment is failing to meet the minimum requirements in a number of areas. An 'Inadequate' rating can lead to significant intervention, including placing the establishment in special measures or requiring an action plan for improvement.</p>`;
        case 'behaviourAndAttitudesScore':
            return `<h4>Behaviour and attitudes at the establishment</h4>
<p>The behaviour and attitudes at the establishment is assessed on a four-point scale. This scale is used to provide a clear, overall judgment on the quality and standards of the establishment being inspected, whether it's a school, college, nursery, or another type of educational or care provider. The four points on the scale are:<p>

<p><strong>Outstanding:</strong> This is the highest rating. An establishment rated as 'Outstanding' is exceptionally effective in delivering outcomes that provide exceptionally well for all its pupils’ needs. This means that the establishment significantly exceeds the minimum requirements and has high-quality standards across all areas.</p>

<p><strong>Good:</strong> Establishments rated as 'Good' are effective in delivering outcomes that provide well for all its pupils' needs. This means that the establishment meets the minimum requirements in most areas and may exceed them in some.</p>

<p><strong>Requires Improvement:</strong> This rating is given to establishments that are not performing as well as they should, and where the quality of education, care, and other key areas are not yet good. These establishments are not failing but have areas that need improvement to reach the 'Good' rating.</p>

<p><strong>Inadequate:</strong></p> This is the lowest rating and indicates that the establishment is failing to meet the minimum requirements in a number of areas. An 'Inadequate' rating can lead to significant intervention, including placing the establishment in special measures or requiring an action plan for improvement.</p>`;
        case 'personalDevelopmentScore':
            return `<h4>Personal development at the establishment</h4>
<p>The personal development at the establishment is assessed on a four-point scale. This scale is used to provide a clear, overall judgment on the quality and standards of the establishment being inspected, whether it's a school, college, nursery, or another type of educational or care provider. The four points on the scale are:<p>

<p><strong>Outstanding:</strong> This is the highest rating. An establishment rated as 'Outstanding' is exceptionally effective in delivering outcomes that provide exceptionally well for all its pupils’ needs. This means that the establishment significantly exceeds the minimum requirements and has high-quality standards across all areas.</p>

<p><strong>Good:</strong> Establishments rated as 'Good' are effective in delivering outcomes that provide well for all its pupils' needs. This means that the establishment meets the minimum requirements in most areas and may exceed them in some.</p>

<p><strong>Requires Improvement:</strong> This rating is given to establishments that are not performing as well as they should, and where the quality of education, care, and other key areas are not yet good. These establishments are not failing but have areas that need improvement to reach the 'Good' rating.</p>

<p><strong>Inadequate:</strong></p> This is the lowest rating and indicates that the establishment is failing to meet the minimum requirements in a number of areas. An 'Inadequate' rating can lead to significant intervention, including placing the establishment in special measures or requiring an action plan for improvement.</p>`;
        case 'effectivenessOfLeadershipAndManagementScore':
            return `<h4>Effectiveness of leadership and management at the establishment</h4>
<p>The effectiveness of leadership and management at the establishment is assessed on a four-point scale. This scale is used to provide a clear, overall judgment on the quality and standards of the establishment being inspected, whether it's a school, college, nursery, or another type of educational or care provider. The four points on the scale are:<p>

<p><strong>Outstanding:</strong> This is the highest rating. An establishment rated as 'Outstanding' is exceptionally effective in delivering outcomes that provide exceptionally well for all its pupils’ needs. This means that the establishment significantly exceeds the minimum requirements and has high-quality standards across all areas.</p>

<p><strong>Good:</strong> Establishments rated as 'Good' are effective in delivering outcomes that provide well for all its pupils' needs. This means that the establishment meets the minimum requirements in most areas and may exceed them in some.</p>

<p><strong>Requires Improvement:</strong> This rating is given to establishments that are not performing as well as they should, and where the quality of education, care, and other key areas are not yet good. These establishments are not failing but have areas that need improvement to reach the 'Good' rating.</p>

<p><strong>Inadequate:</strong></p> This is the lowest rating and indicates that the establishment is failing to meet the minimum requirements in a number of areas. An 'Inadequate' rating can lead to significant intervention, including placing the establishment in special measures or requiring an action plan for improvement.</p>`;
        case 'isSafeguardingEffective':
            return `<p>Whether safeguarding is effective is a crucial question that inspectors seek to answer when assessing a school, college, or any other educational or care setting. Safeguarding refers to the measures and actions taken to promote the welfare of children and protect them from harm. Assessing the effectiveness of safeguarding is a central part of any Ofsted inspection, reflecting the importance of ensuring that children and young people are safe and protected in educational environments.</p>`;
        case 'earlyYearsProvisionScore':
            return `<p>The early years provision score refers to the judgment given to the quality of provision in the Early Years Foundation Stage (EYFS) within a school or standalone early years setting. This judgment is an integral part of the overall Ofsted inspection for schools and settings that cater to children from birth to five years old.</p>

<p>The Early Years Provision is assessed against the EYFS framework, which sets standards for the learning, development, and care of young children.</p>`;
        case 'sixthFormProvisionScore':
            return `<p>The sixth form provision score refers to the judgment given specifically to the quality of provision in the sixth form of a school or college. This score is part of the overall Ofsted inspection for institutions that offer post-16 education, typically for students aged 16 to 18.</p>`;
        case 'previousGradedInspectionRelatesToUrnOfSchool':
            return `<h4>Unique Reference Number</h4>
<p>Also known as a URN this is a number assigned to each school, college, and childcare provider in the UK by the Department for Education (DfE). It is used to identify the institution in all official records and communications.</p>

<h4>Graded Inspections</h4>
<p>These are the regular inspections that most people are familiar with. During these inspections, Ofsted inspectors visit schools, colleges, and other institutions to evaluate the quality of education, the behavior and attitudes of students, the personal development of students, the effectiveness of leadership and management, and early years provision where applicable. At the end of a graded inspection, the institution receives a judgement that falls into one of four categories: 'Outstanding', 'Good', 'Requires Improvement', or 'Inadequate'.</p>`;
        case 'urnDuringPreviousGradedInspection':
            return `<h4>Unique Reference Number</h4>
<p>Also known as a URN this is a number assigned to each school, college, and childcare provider in the UK by the Department for Education (DfE). It is used to identify the institution in all official records and communications.</p>

<h4>Graded Inspections</h4>
<p>These are the regular inspections that most people are familiar with. During these inspections, Ofsted inspectors visit schools, colleges, and other institutions to evaluate the quality of education, the behavior and attitudes of students, the personal development of students, the effectiveness of leadership and management, and early years provision where applicable. At the end of a graded inspection, the institution receives a judgement that falls into one of four categories: 'Outstanding', 'Good', 'Requires Improvement', or 'Inadequate'.</p>`;
        case 'laEstablishmentDuringPreviousGradedInspection':
            return `<h4>Graded Inspections</h4>
<p>These are the regular inspections that most people are familiar with. During these inspections, Ofsted inspectors visit schools, colleges, and other institutions to evaluate the quality of education, the behavior and attitudes of students, the personal development of students, the effectiveness of leadership and management, and early years provision where applicable. At the end of a graded inspection, the institution receives a judgement that falls into one of four categories: 'Outstanding', 'Good', 'Requires Improvement', or 'Inadequate'.</p>

<h4>Local Authority Establishment Number</h4>
<p>The Local Authority (LA) establishment number, sometimes also referred to as the school establishment number, is a unique number assigned to a school by the local authority in which it is located. This number is used in conjunction with the school's Unique Reference Number (URN) to uniquely identify a school within local and national government databases and systems.<p>

<p>Each school has a URN, which is a national identifier, and an LA establishment number, which is a local identifier. When combined, these numbers ensure precise identification of the school for administrative purposes, data collection, and when coordinating between different services or departments that might deal with the school, such as education services, social services, and Ofsted inspections.</p>`;
        case 'overallEffectivenessPreviousScore':
            return `<h4>Overall effectiveness of the establishment</h4>
<p>The overall effectiveness of an establishment is assessed on a four-point scale. This scale is used to provide a clear, overall judgment on the quality and standards of the establishment being inspected, whether it's a school, college, nursery, or another type of educational or care provider. The four points on the scale are:<p>

<p><strong>Outstanding:</strong> This is the highest rating. An establishment rated as 'Outstanding' is exceptionally effective in delivering outcomes that provide exceptionally well for all its pupils’ needs. This means that the establishment significantly exceeds the minimum requirements and has high-quality standards across all areas.</p>

<p><strong>Good:</strong> Establishments rated as 'Good' are effective in delivering outcomes that provide well for all its pupils' needs. This means that the establishment meets the minimum requirements in most areas and may exceed them in some.</p>

<p><strong>Requires Improvement:</strong> This rating is given to establishments that are not performing as well as they should, and where the quality of education, care, and other key areas are not yet good. These establishments are not failing but have areas that need improvement to reach the 'Good' rating.</p>

<p><strong>Inadequate:</strong></p> This is the lowest rating and indicates that the establishment is failing to meet the minimum requirements in a number of areas. An 'Inadequate' rating can lead to significant intervention, including placing the establishment in special measures or requiring an action plan for improvement.</p>`;
        case 'categoryOfConcernPreviousScore':
            return `<p>"Category of concern" is a designation given to schools that are not meeting certain educational standards. These categories are part of Ofsted's assessment framework and are used to identify schools that require significant improvement. There are two main categories of concern:</p>

<h4>Requires Improvement</h4>
<p>This category is for schools that are not performing as well as they should be and have areas where they need to make improvements. Schools in this category do not have serious failings that would warrant being placed in special measures, but they are not yet achieving a 'Good' standard. Schools in the 'Requires Improvement' category receive more frequent inspections until they improve.</p>

<h4>Inadequate</h4>
<p>This category indicates serious concerns about the performance of the school. There are two sub-categories within 'Inadequate':</p>

<p><strong>Serious Weaknesses:</strong> This is used when a school is failing in one or more key areas but has the capacity to improve without the need for more intensive external support.</p>

<p><strong>Special Measures:</strong> This is the most serious category and is used when a school is failing to provide an acceptable standard of education, and the school’s leaders, managers, or governors have not demonstrated that they have the capacity to secure the necessary improvement. A school in special measures will receive regular monitoring by Ofsted and may be subject to intervention by local authorities or the Department for Education, which can include changes in leadership or governance.

Being placed in a category of concern is a significant matter for a school, requiring targeted action to address the identified issues. The aim of these categories is to ensure that schools receive the support and scrutiny necessary to improve the quality of education they provide to their students.<p>`;
        case 'qualityOfEducationPreviousScore':
            return `<h4>Quality of education provided at the establishment</h4>
<p>The quality of education provided at the establishment is assessed on a four-point scale. This scale is used to provide a clear, overall judgment on the quality and standards of the establishment being inspected, whether it's a school, college, nursery, or another type of educational or care provider. The four points on the scale are:<p>

<p><strong>Outstanding:</strong> This is the highest rating. An establishment rated as 'Outstanding' is exceptionally effective in delivering outcomes that provide exceptionally well for all its pupils’ needs. This means that the establishment significantly exceeds the minimum requirements and has high-quality standards across all areas.</p>

<p><strong>Good:</strong> Establishments rated as 'Good' are effective in delivering outcomes that provide well for all its pupils' needs. This means that the establishment meets the minimum requirements in most areas and may exceed them in some.</p>

<p><strong>Requires Improvement:</strong> This rating is given to establishments that are not performing as well as they should, and where the quality of education, care, and other key areas are not yet good. These establishments are not failing but have areas that need improvement to reach the 'Good' rating.</p>

<p><strong>Inadequate:</strong></p> This is the lowest rating and indicates that the establishment is failing to meet the minimum requirements in a number of areas. An 'Inadequate' rating can lead to significant intervention, including placing the establishment in special measures or requiring an action plan for improvement.</p>`;
        case 'behaviourAndAttitudePreviousScore':
            return `<h4>Behaviour and attitudes at the establishment</h4>
<p>The behaviour and attitudes at the establishment is assessed on a four-point scale. This scale is used to provide a clear, overall judgment on the quality and standards of the establishment being inspected, whether it's a school, college, nursery, or another type of educational or care provider. The four points on the scale are:<p>

<p><strong>Outstanding:</strong> This is the highest rating. An establishment rated as 'Outstanding' is exceptionally effective in delivering outcomes that provide exceptionally well for all its pupils’ needs. This means that the establishment significantly exceeds the minimum requirements and has high-quality standards across all areas.</p>

<p><strong>Good:</strong> Establishments rated as 'Good' are effective in delivering outcomes that provide well for all its pupils' needs. This means that the establishment meets the minimum requirements in most areas and may exceed them in some.</p>

<p><strong>Requires Improvement:</strong> This rating is given to establishments that are not performing as well as they should, and where the quality of education, care, and other key areas are not yet good. These establishments are not failing but have areas that need improvement to reach the 'Good' rating.</p>

<p><strong>Inadequate:</strong></p> This is the lowest rating and indicates that the establishment is failing to meet the minimum requirements in a number of areas. An 'Inadequate' rating can lead to significant intervention, including placing the establishment in special measures or requiring an action plan for improvement.</p>`;
        case 'personalDevelopmentPreviousScore':
            return `<h4>Personal development at the establishment</h4>
<p>The personal development at the establishment is assessed on a four-point scale. This scale is used to provide a clear, overall judgment on the quality and standards of the establishment being inspected, whether it's a school, college, nursery, or another type of educational or care provider. The four points on the scale are:<p>

<p><strong>Outstanding:</strong> This is the highest rating. An establishment rated as 'Outstanding' is exceptionally effective in delivering outcomes that provide exceptionally well for all its pupils’ needs. This means that the establishment significantly exceeds the minimum requirements and has high-quality standards across all areas.</p>

<p><strong>Good:</strong> Establishments rated as 'Good' are effective in delivering outcomes that provide well for all its pupils' needs. This means that the establishment meets the minimum requirements in most areas and may exceed them in some.</p>

<p><strong>Requires Improvement:</strong> This rating is given to establishments that are not performing as well as they should, and where the quality of education, care, and other key areas are not yet good. These establishments are not failing but have areas that need improvement to reach the 'Good' rating.</p>

<p><strong>Inadequate:</strong></p> This is the lowest rating and indicates that the establishment is failing to meet the minimum requirements in a number of areas. An 'Inadequate' rating can lead to significant intervention, including placing the establishment in special measures or requiring an action plan for improvement.</p>`;
        case 'effectivenessOfLeadershipAndManagementPreviousScore':
            return `<h4>Effectiveness of leadership and management at the establishment</h4>
<p>The effectiveness of leadership and management at the establishment is assessed on a four-point scale. This scale is used to provide a clear, overall judgment on the quality and standards of the establishment being inspected, whether it's a school, college, nursery, or another type of educational or care provider. The four points on the scale are:<p>

<p><strong>Outstanding:</strong> This is the highest rating. An establishment rated as 'Outstanding' is exceptionally effective in delivering outcomes that provide exceptionally well for all its pupils’ needs. This means that the establishment significantly exceeds the minimum requirements and has high-quality standards across all areas.</p>

<p><strong>Good:</strong> Establishments rated as 'Good' are effective in delivering outcomes that provide well for all its pupils' needs. This means that the establishment meets the minimum requirements in most areas and may exceed them in some.</p>

<p><strong>Requires Improvement:</strong> This rating is given to establishments that are not performing as well as they should, and where the quality of education, care, and other key areas are not yet good. These establishments are not failing but have areas that need improvement to reach the 'Good' rating.</p>

<p><strong>Inadequate:</strong></p> This is the lowest rating and indicates that the establishment is failing to meet the minimum requirements in a number of areas. An 'Inadequate' rating can lead to significant intervention, including placing the establishment in special measures or requiring an action plan for improvement.</p>`;
        case 'isSafeguardingEffectivePreviousScore':
            return `<p>Whether safeguarding is effective is a crucial question that inspectors seek to answer when assessing a school, college, or any other educational or care setting. Safeguarding refers to the measures and actions taken to promote the welfare of children and protect them from harm. Assessing the effectiveness of safeguarding is a central part of any Ofsted inspection, reflecting the importance of ensuring that children and young people are safe and protected in educational environments.</p>`;
        case 'earlyYearsProvisionPreviousScore':
            return `<p>The early years provision score refers to the judgment given to the quality of provision in the Early Years Foundation Stage (EYFS) within a school or standalone early years setting. This judgment is an integral part of the overall Ofsted inspection for schools and settings that cater to children from birth to five years old.</p>

<p>The Early Years Provision is assessed against the EYFS framework, which sets standards for the learning, development, and care of young children.</p>`;
        case 'sixthFormProvisionPreviousScore':
            return `<p>The sixth form provision score refers to the judgment given specifically to the quality of provision in the sixth form of a school or college. This score is part of the overall Ofsted inspection for institutions that offer post-16 education, typically for students aged 16 to 18.</p>`;
        default:
            return undefined;
    }
}

export function ofstedGroupFactory(property: string | undefined) {
    switch (property) {
        case 'latestUngradedInspectionNumberSinceLastInspection':
            return `Ungraded Inspection`;
        case 'numberUngradedInspectionsSinceLastGradedInspection':
            return `Ungraded Inspection`;
        case 'dateOfLastUngradedInspection':
            return `Ungraded Inspection`;
        case 'ungradedInspectionPublicationDate':
            return `Ungraded Inspection`;
        case 'didLatestUngradedInspectionConvertToGradedInspection':
            return `Ungraded Inspection`;
        case 'ungradedInspectionOverallOutcome':
            return `Ungraded Inspection`;
        case 'numberOfMonitoringAndUrgentInspectionsSinceLastGradedInspection':
            return `Ungraded Inspection`;
        case 'inspectionNumberOfLatestGradedInspection':
            return `Latest Graded Inspection`;
        case 'inspectionType':
            return `Latest Graded Inspection`;
        case 'inspectionTypeGrouping':
            return `Latest Graded Inspection`;
        case 'eventTypeGrouping':
            return `Latest Graded Inspection`;
        case 'inspectionStartDate':
            return `Latest Graded Inspection`;
        case 'inspectionEndDate':
            return `Latest Graded Inspection`;
        case 'publicationDate':
            return `Latest Graded Inspection`;
        case 'gradedInspectionRelatesToUrnOfSchool':
            return `Latest Graded Inspection`;
        case 'overallEffectivenessScore':
            return `Latest Graded Inspection`;
        case 'categoryOfConcern':
            return `Latest Graded Inspection`;
        case 'qualityOfEducationScore':
            return `Latest Graded Inspection`;
        case 'behaviourAndAttitudesScore':
            return `Latest Graded Inspection`;
        case 'personalDevelopmentScore':
            return `Latest Graded Inspection`;
        case 'effectivenessOfLeadershipAndManagementScore':
            return `Latest Graded Inspection`;
        case 'isSafeguardingEffective':
            return `Latest Graded Inspection`;
        case 'earlyYearsProvisionScore':
            return `Latest Graded Inspection`;
        case 'sixthFormProvisionScore':
            return `Latest Graded Inspection`;
        case 'previousGradedInspectionNumber':
            return `Previous Graded Inspection`;
        case 'previousInspectionStartDate':
            return `Previous Graded Inspection`;
        case 'previousInspectionEndDate':
            return `Previous Graded Inspection`;
        case 'previousPublicationDate':
            return `Previous Graded Inspection`;
        case 'overallEffectivenessPreviousScore':
            return `Previous Graded Inspection`;
        case 'categoryOfConcernPreviousScore':
            return `Previous Graded Inspection`;
        case 'qualityOfEducationPreviousScore':
            return `Previous Graded Inspection`;
        case 'behaviourAndAttitudePreviousScore':
            return `Previous Graded Inspection`;
        case 'personalDevelopmentPreviousScore':
            return `Previous Graded Inspection`;
        case 'effectivenessOfLeadershipAndManagementPreviousScore':
            return `Previous Graded Inspection`;
        case 'isSafeguardingEffectivePreviousScore':
            return `Previous Graded Inspection`;
        case 'earlyYearsProvisionPreviousScore':
            return `Previous Graded Inspection`;
        case 'sixthFormProvisionPreviousScore':
            return `Previous Graded Inspection`;
        default:
            return undefined;
    }
}

export function ofstedDataTypeFactory(property: string | undefined) {
    switch (property) {
        case 'establishmentId':
            return `number`;
        case 'urn':
            return `string`;
        case 'idaciQuintile':
            return `enum`;
        case 'latestUngradedInspectionNumberSinceLastInspection':
            return `string`;
        case 'ungradedInspectionRelatesToUrnOfSchool':
            return `boolean`;
        case 'urnDuringUngradedInspection':
            return `string`;
        case 'laEstablishmentDuringUngradedInspection':
            return `string`;
        case 'numberUngradedInspectionsSinceLastGradedInspection':
            return `smallint`;
        case 'dateOfLastUngradedInspection':
            return `date`;
        case 'ungradedInspectionPublicationDate':
            return `date`;
        case 'didLatestUngradedInspectionConvertToGradedInspection':
            return `boolean`;
        case 'ungradedInspectionOverallOutcome':
            return `enum`;
        case 'numberOfMonitoringAndUrgentInspectionsSinceLastGradedInspection':
            return `smallint`;
        case 'inspectionNumberOfLatestGradedInspection':
            return `string`;
        case 'inspectionType':
            return `enum`;
        case 'inspectionTypeGrouping':
            return `enum`;
        case 'eventTypeGrouping':
            return `enum`;
        case 'inspectionStartDate':
            return `date`;
        case 'inspectionEndDate':
            return `date`;
        case 'publicationDate':
            return `date`;
        case 'gradedInspectionRelatesToUrnOfSchool':
            return `boolean`;
        case 'urnDuringGradedInspection':
            return `string`;
        case 'laEstablishmentDuringGradedInspection':
            return `string`;
        case 'overallEffectivenessScore':
            return `enum`;
        case 'categoryOfConcern':
            return `enum`;
        case 'qualityOfEducationScore':
            return `enum`;
        case 'behaviourAndAttitudesScore':
            return `enum`;
        case 'personalDevelopmentScore':
            return `enum`;
        case 'effectivenessOfLeadershipAndManagementScore':
            return `enum`;
        case 'isSafeguardingEffective':
            return `enum`;
        case 'earlyYearsProvisionScore':
            return `enum`;
        case 'sixthFormProvisionScore':
            return `enum`;
        case 'previousGradedInspectionNumber':
            return `string`;
        case 'previousInspectionStartDate':
            return `date`;
        case 'previousInspectionEndDate':
            return `date`;
        case 'previousPublicationDate':
            return `date`;
        case 'previousGradedInspectionRelatesToUrnOfSchool':
            return `boolean`;
        case 'urnDuringPreviousGradedInspection':
            return `string`;
        case 'laEstablishmentDuringPreviousGradedInspection':
            return `string`;
        case 'overallEffectivenessPreviousScore':
            return `enum`;
        case 'categoryOfConcernPreviousScore':
            return `enum`;
        case 'qualityOfEducationPreviousScore':
            return `enum`;
        case 'behaviourAndAttitudePreviousScore':
            return `enum`;
        case 'personalDevelopmentPreviousScore':
            return `enum`;
        case 'effectivenessOfLeadershipAndManagementPreviousScore':
            return `enum`;
        case 'isSafeguardingEffectivePreviousScore':
            return `enum`;
        case 'earlyYearsProvisionPreviousScore':
            return `enum`;
        case 'sixthFormProvisionPreviousScore':
            return `enum`;
        case 'source':
            return `string`;
        case 'stateOrIndependent':
            return `string`;
        case 'createdAt':
            return `number`;
        case 'createdBy':
            return `string`;
        default:
            return undefined;
    }
}

export function ofstedEnumFactory(property: string | undefined) {
    switch (property) {
        case 'idaciQuintile':
            return IdaciQuintile;
        case 'ungradedInspectionOverallOutcome':
            return UngradedInspectionOverallOutcome;
        case 'inspectionType':
            return InspectionType;
        case 'inspectionTypeGrouping':
            return InspectionTypeGrouping;
        case 'eventTypeGrouping':
            return EventTypeGrouping;
        case 'overallEffectivenessScore':
            return OverallEffectivenessScore;
        case 'categoryOfConcern':
            return CategoryOfConcern;
        case 'qualityOfEducationScore':
            return QualityOfEducationScore;
        case 'behaviourAndAttitudesScore':
            return BehaviourAndAttitudesScore;
        case 'personalDevelopmentScore':
            return PersonalDevelopmentScore;
        case 'effectivenessOfLeadershipAndManagementScore':
            return EffectivenessOfLeadershipAndManagementScore;
        case 'isSafeguardingEffective':
            return IsSafeguardingEffective;
        case 'earlyYearsProvisionScore':
            return EarlyYearsProvisionScore;
        case 'sixthFormProvisionScore':
            return SixthFormProvisionScore;
        case 'overallEffectivenessPreviousScore':
            return OverallEffectivenessPreviousScore;
        case 'categoryOfConcernPreviousScore':
            return CategoryOfConcernPreviousScore;
        case 'qualityOfEducationPreviousScore':
            return QualityOfEducationPreviousScore;
        case 'behaviourAndAttitudePreviousScore':
            return BehaviourAndAttitudePreviousScore;
        case 'personalDevelopmentPreviousScore':
            return PersonalDevelopmentPreviousScore;
        case 'effectivenessOfLeadershipAndManagementPreviousScore':
            return EffectivenessOfLeadershipAndManagementPreviousScore;
        case 'isSafeguardingEffectivePreviousScore':
            return IsSafeguardingEffectivePreviousScore;
        case 'earlyYearsProvisionPreviousScore':
            return EarlyYearsProvisionPreviousScore;
        case 'sixthFormProvisionPreviousScore':
            return SixthFormProvisionPreviousScore;
        default:
            return undefined;
    }
}

export function ofstedShowInCardFactory(property: string | undefined) {
    switch (property) {
        case 'idaciQuintile':
        case 'latestUngradedInspectionNumberSinceLastInspection':
        case 'numberUngradedInspectionsSinceLastGradedInspection':
        case 'dateOfLastUngradedInspection':
        case 'ungradedInspectionPublicationDate':
        case 'didLatestUngradedInspectionConvertToGradedInspection':
        case 'ungradedInspectionOverallOutcome':
        case 'numberOfMonitoringAndUrgentInspectionsSinceLastGradedInspection':
        case 'inspectionNumberOfLatestGradedInspection':
        case 'inspectionType':
        case 'inspectionTypeGrouping':
        case 'eventTypeGrouping':
        case 'inspectionStartDate':
        case 'inspectionEndDate':
        case 'publicationDate':
        case 'overallEffectivenessScore':
        case 'categoryOfConcern':
        case 'qualityOfEducationScore':
        case 'behaviourAndAttitudesScore':
        case 'personalDevelopmentScore':
        case 'effectivenessOfLeadershipAndManagementScore':
        case 'isSafeguardingEffective':
        case 'earlyYearsProvisionScore':
        case 'sixthFormProvisionScore':
        case 'previousGradedInspectionNumber':
        case 'previousInspectionStartDate':
        case 'previousInspectionEndDate':
        case 'previousPublicationDate':
        case 'overallEffectivenessPreviousScore':
        case 'categoryOfConcernPreviousScore':
        case 'qualityOfEducationPreviousScore':
        case 'behaviourAndAttitudePreviousScore':
        case 'personalDevelopmentPreviousScore':
        case 'effectivenessOfLeadershipAndManagementPreviousScore':
        case 'isSafeguardingEffectivePreviousScore':
        case 'earlyYearsProvisionPreviousScore':
        case 'sixthFormProvisionPreviousScore':
            return true;
        default:
            return false;
    }
}

export default abstract class OfstedGeneratedEntity {
    id?: number;
    establishmentId?: number;
    urn?: string;
    idaciQuintile?: IdaciQuintile;
    latestUngradedInspectionNumberSinceLastInspection?: string;
    ungradedInspectionRelatesToUrnOfSchool?: boolean;
    urnDuringUngradedInspection?: string;
    laEstablishmentDuringUngradedInspection?: string;
    numberUngradedInspectionsSinceLastGradedInspection?: number;
    dateOfLastUngradedInspection?: Date;
    ungradedInspectionPublicationDate?: Date;
    didLatestUngradedInspectionConvertToGradedInspection?: boolean;
    ungradedInspectionOverallOutcome?: UngradedInspectionOverallOutcome;
    numberOfMonitoringAndUrgentInspectionsSinceLastGradedInspection?: number;
    inspectionNumberOfLatestGradedInspection?: string;
    inspectionType?: InspectionType;
    inspectionTypeGrouping?: InspectionTypeGrouping;
    eventTypeGrouping?: EventTypeGrouping;
    inspectionStartDate?: Date;
    inspectionEndDate?: Date;
    publicationDate?: Date;
    gradedInspectionRelatesToUrnOfSchool?: boolean;
    urnDuringGradedInspection?: string;
    laEstablishmentDuringGradedInspection?: string;
    overallEffectivenessScore?: OverallEffectivenessScore;
    categoryOfConcern?: CategoryOfConcern;
    qualityOfEducationScore?: QualityOfEducationScore;
    behaviourAndAttitudesScore?: BehaviourAndAttitudesScore;
    personalDevelopmentScore?: PersonalDevelopmentScore;
    effectivenessOfLeadershipAndManagementScore?: EffectivenessOfLeadershipAndManagementScore;
    isSafeguardingEffective?: IsSafeguardingEffective;
    earlyYearsProvisionScore?: EarlyYearsProvisionScore;
    sixthFormProvisionScore?: SixthFormProvisionScore;
    previousGradedInspectionNumber?: string;
    previousInspectionStartDate?: Date;
    previousInspectionEndDate?: Date;
    previousPublicationDate?: Date;
    previousGradedInspectionRelatesToUrnOfSchool?: boolean;
    urnDuringPreviousGradedInspection?: string;
    laEstablishmentDuringPreviousGradedInspection?: string;
    overallEffectivenessPreviousScore?: OverallEffectivenessPreviousScore;
    categoryOfConcernPreviousScore?: CategoryOfConcernPreviousScore;
    qualityOfEducationPreviousScore?: QualityOfEducationPreviousScore;
    behaviourAndAttitudePreviousScore?: BehaviourAndAttitudePreviousScore;
    personalDevelopmentPreviousScore?: PersonalDevelopmentPreviousScore;
    effectivenessOfLeadershipAndManagementPreviousScore?: EffectivenessOfLeadershipAndManagementPreviousScore;
    isSafeguardingEffectivePreviousScore?: IsSafeguardingEffectivePreviousScore;
    earlyYearsProvisionPreviousScore?: EarlyYearsProvisionPreviousScore;
    sixthFormProvisionPreviousScore?: SixthFormProvisionPreviousScore;
    source?: string;
    stateOrIndependent?: string;
}
