import React, { FunctionComponent, useEffect, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface Props {
    template?: string;
    popoverContentOverride?: string;
}

const HiddenEmailAddress: FunctionComponent<Props> = (props: Props) => {
    const [firstPattern, setFirstPattern] = useState<string>('');
    const [lastPattern, setLastPattern] = useState<string>('');

    useEffect(() => {
        setFirstPattern(newPattern());
        setLastPattern(newPattern());
    }, []);

    const newPattern = () => {
        const chars = 'abcdefghijklmnopqrstuvwxyz';
        let pattern = '';
        for (let i = 0; i < 6; i++) {
            pattern += chars[Math.floor(Math.random() * chars.length)];
        }

        return pattern;
    };

    const firstLetter = props.template?.substring(0, 1);
    const last = props.template?.split('.')[props.template?.split('.').length - 1];

    return props.template && props.template?.trim() !== '' ? (
        <OverlayTrigger
            placement="bottom"
            overlay={(overlayProps) => (
                <Popover id="popover-basic" {...overlayProps}>
                    <Popover.Content>
                        {props.popoverContentOverride ?? (
                            <>
                                Even though you can't see this email address you can still use it to create lists and
                                send marketing emails.
                            </>
                        )}
                    </Popover.Content>
                </Popover>
            )}
        >
            <span>
                {firstLetter}
                <span className="obfuscated-email js-obfuscated-email">
                    {firstPattern}
                    <em>@</em>
                    {lastPattern}
                </span>
                .{last}
            </span>
        </OverlayTrigger>
    ) : (
        <span>&nbsp;</span>
    );
};

export default HiddenEmailAddress;
