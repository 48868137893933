import React, { FunctionComponent, PropsWithChildren } from 'react';
import './banner.scss';

export enum AlertLevel {
    INFO,
    SUCCESS,
    WARNING,
    DANGER,
    TERTIARY,
}

type Props = PropsWithChildren<{
    alertLevel: AlertLevel;
    primary?: boolean;
    tertiary?: boolean;
}>;

const AlertBanner: FunctionComponent<Props> = (props: Props) => {
    let className = 'react-alert';
    if (props.primary) className += ' react-alert--primary';
    else if (props.tertiary) className += ' react-alert--tertiary';

    switch (props.alertLevel) {
        case AlertLevel.INFO:
            className += ' react-alert--info';
            break;
        case AlertLevel.SUCCESS:
            className += ' react-alert--success';
            break;
        case AlertLevel.WARNING:
            className += ' react-alert--warning';
            break;
        case AlertLevel.DANGER:
            className += ' react-alert--danger';
            break;
        case AlertLevel.TERTIARY:
            className += ' react-alert--tertiary';
            break;
    }

    return <div className={className}>{props.children}</div>;
};

export default AlertBanner;
