import React, { ChangeEvent, FunctionComponent } from 'react';

import './radio-button.scss';

interface Props {
    label: any;
    value: string | number;
    checked: boolean;
    onChange: (value: string | number, checked: boolean) => void;
    group?: string;
    disabled?: boolean;
    onBlur?: () => void;
}

const RadioButton: FunctionComponent<Props> = (props: Props) => {
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.onChange(props.value, e.target.checked);
    };

    const onBlur = () => {
        if (!props.onBlur) return;
        props.onBlur();
    };

    return (
        <span className="react-radio-button pretty p-default p-round">
            <input
                type="radio"
                name={props.group}
                value={props.value}
                checked={props.checked}
                disabled={props.disabled}
                onChange={onChange}
                onBlur={onBlur}
            />
            <div className="state p-info">
                <label className="ra-medium">
                    <span>{props.label}</span>
                </label>
            </div>
        </span>
    );
};

export default RadioButton;
