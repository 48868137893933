import {
    AppEvent,
    Checkbox,
    EventBusInstance,
    isValidString,
    LogLevel,
    showBanner,
    Sisp,
} from '@sprint/sprint-react-components';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { DealLostReasonRequest } from '../../Api/DealLostReasonRequest';
import { RepositoryFactoryContext } from '../../index';
import DealLostReason from '../../Models/DealLostReason';
import DealLostReasonEditState from '../../Models/DealLostReasonEditState';

interface Props {
    uniqueKey: string;
    onSuccess: (results: any) => Promise<boolean>;
}

const DealLostReasonsEditSisp: FunctionComponent<Props> = (props: Props) => {
    const [shown, setShown] = useState<boolean>(false);

    const dealLostReasonsRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new DealLostReasonRequest(),
    );

    const focusRef = useRef<HTMLInputElement>(null);

    const [rowId, setRowId] = useState<number>(0);

    const [editReason, setEditReason] = useState<string>('');
    const [editReasonValid, setEditReasonValid] = useState<boolean>(true);

    const [editDefault, setEditDefault] = useState<boolean>(false);

    useEffect(() => {
        if (shown) {
            if (focusRef.current) {
                focusRef.current.focus();
                focusRef.current.selectionStart = focusRef.current.value.length;
                focusRef.current.selectionEnd = focusRef.current.value.length;
            }
            setEditReasonValid(true);
        }
    }, [shown]);

    useEffect(() => {
        EventBusInstance.subscribe(
            'show-hoverover-component',
            (event: AppEvent<DealLostReasonEditState>) => {
                if (event.target !== props.uniqueKey) return;
                setRowId(event.message.id);
                setEditReason(event.message.reason);
                setEditDefault(event.message.is_default);
                setShown(true);
            },
            props.uniqueKey,
        );
    }, [shown]);

    const validate = async (): Promise<boolean> => {
        const reasonValid = !!editReason && isValidString(editReason);
        setEditReasonValid(reasonValid);
        return reasonValid;
    };

    const handleEditRow = async (): Promise<boolean> => {
        const DealLostReason: DealLostReason = {
            id: rowId,
            reason: editReason,
            is_default: editDefault,
            // Doesnt change can only be false
            built_in: false,
        };

        return dealLostReasonsRepository
            .update(DealLostReason)
            .then((results: any) => {
                props.onSuccess(results.data);
                return Promise.resolve(true);
            })
            .catch((err) => {
                showBanner(
                    {
                        message: 'Failed to edit Lost Reason - ' + (err?.message ?? err),
                        level: LogLevel.ERROR,
                    },
                    props.uniqueKey.split('-')[0],
                );
                return Promise.resolve(false);
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        if ((await validate()) && (await handleEditRow())) setShown(false);
    };

    return (
        <Sisp
            isOpen={shown}
            onSubmit={handleEditRow}
            onCancel={() => {
                setShown(false);
            }}
            validate={validate}
        >
            <h4>Edit a Lost Reason</h4>
            <Form onSubmit={onSubmitForm}>
                <Form.Group>
                    <Form.Label>
                        Lost Reason <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        ref={focusRef}
                        type="text"
                        isInvalid={!editReasonValid}
                        value={editReason || ''}
                        onChange={(event) => {
                            setEditReason(event.target.value);
                            setEditReasonValid(true);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {!editReasonValid && 'This field is required.'}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Checkbox
                        label="Make this the default lost reason"
                        isChecked={editDefault}
                        onChange={(event) => setEditDefault(event.target.checked)}
                    />
                </Form.Group>
            </Form>
        </Sisp>
    );
};

export default DealLostReasonsEditSisp;
