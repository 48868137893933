'use strict';

import React from 'react';
import PropTypes from "prop-types";
import {shortenPlaceholder} from "../functions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/pro-regular-svg-icons";

class Value extends React.Component {

    displayValue() {
        if (this.props.shortenPlaceholder) return shortenPlaceholder(this.props.placeholder);
        if (!this.props.usePills || this.props.selectedOptions.length === 0) return this.props.placeholder;
        return this.props.availableOptions.filter((o) => this.props.selectedOptions.indexOf(o.id) !== -1).map((o) => {
            return <span key={o.id} className="label label-tertiary">{o.label}</span>
        });
    }

    clearButton() {
        if (!this.props.allowClear || this.props.disabled) return;
        return <span onClick={this.props.clearOptions} className="clear-button">
            <FontAwesomeIcon icon={faTimes}/>
        </span>;
    }

    render() {
        let className = "react-select--icon";
        let valueClassName = "react-select--value";
        if (this.props.open) className += " open";
        if (this.props.usePills) valueClassName += " uses-pills";
        if (this.props.selectedOptions.length > 0) valueClassName += " has-selections";
        if (this.props.disabled) valueClassName += " disabled";
        return <a href="#" className={valueClassName} onClick={this.props.showOptions}>
            {this.displayValue()}
            {this.clearButton()}
            <span className={className}>
                <i/>
            </span>
        </a>;
    }

}

Value.propTypes = {
    selectedOptions: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])),
    availableOptions: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]).isRequired,
            label: PropTypes.string.isRequired,
            labelElement: PropTypes.element,
        })
    ])),
    showOptions: PropTypes.func,
    clearOptions: PropTypes.func,
    allowClear: PropTypes.bool,
    shortenPlaceHolder: PropTypes.bool,
    placeholder: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    open: PropTypes.bool,
    disabled: PropTypes.bool,
    usePills: PropTypes.bool
};

Value.defaultProps = {
    availableOptions: [],
    selectedOptions: [],
    shortenPlaceHolder: false,
    placeholder: 'Select',
    open: false,
    usePills: false
};

export default Value;