'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {basicRandomId} from "../../Helpers/UniqueId";
import CheckBox from "../CheckBox/CheckBox";
import DateTimeInput from "../DateTime/DateTimeInput";
import RadioButtonGroup from "../RadioButton/RadioButtonGroup";
import Select from "../Select/Select";
import MultiSelect from "../Select/MultiSelect";
import ToolTip from "../ToolTip/ToolTip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/pro-regular-svg-icons";

import './form.scss';
import '../../sass/input.scss';

class CampusFormControl extends React.Component {

    constructor(props) {
        super(props);
        this.id = 'form-input-' + basicRandomId();
        this.state = {open: false};
    }

    onChange(e) {
        e.preventDefault();
        if (!this.props.onChange) return;
        this.props.onChange(e.target.value);
    }

    onBlur() {
        if (!this.props.onBlur) return;
        this.props.onBlur();
    }

    toggle() {
        this.setState({open: !this.state.open});
    }

    changeValue(val) {
        this.props.onChange(val);
        if (this.props.inputType === 'multiselect') return;
        this.setState({open: false});
        this.onBlur();
    }

    changeCheckBox(val, checked) {
        this.props.onChange(checked);
    }

    changeRadioButton(option) {
        this.props.onChange(option);
    }

    label() {
        if (this.props.inputType === 'checkbox') return null;
        return <label htmlFor={this.id}>{this.props.label}</label>;
    }

    clearValue() {
        this.changeValue('');
    }

    inputClearButton() {
        if (!this.props.clearable || !this.props.value) return null;
        return <span onClick={this.clearValue.bind(this)} className="clear-button">
            <FontAwesomeIcon icon={faTimes}/>
        </span>;
    }

    inputElement() {
        switch (this.props.inputType) {
            case 'text':
            case 'email':
            case 'number':
                return <div className="input-wrapper">
                    <input
                        id={this.id}
                        type="text"
                        className="form-control"
                        placeholder={this.props.placeHolder}
                        value={this.props.value}
                        onChange={this.onChange.bind(this)}
                        onBlur={this.onBlur.bind(this)}
                        disabled={this.props.disabled}
                    />
                    {this.inputClearButton()}
                </div>;
            case 'textarea':
                return <textarea
                    id={this.id}
                    rows={this.props.size}
                    className="form-control"
                    placeholder={this.props.placeHolder}
                    value={this.props.value}
                    onChange={this.onChange.bind(this)}
                    onBlur={this.onBlur.bind(this)}
                    disabled={this.props.disabled}
                />;
            case 'select':
                return <Select
                    id={this.id}
                    placeholder={this.props.placeHolder}
                    availableOptions={this.props.options}
                    selectedOption={this.props.value ? this.props.value : null}
                    updateSelectedOption={this.changeValue.bind(this)}
                    onBlur={this.onBlur.bind(this)}
                    allowClear={this.props.clearable}
                    open={this.state.open}
                    toggle={this.toggle.bind(this)}
                    currentFilter={this.props.selectFilter.currentFilter}
                    updateFilter={this.props.selectFilter.updateFilter}
                    allKnownOptions={this.props.selectFilter.allKnownOptions}
                    noResultsLabel={this.props.selectFilter.noResultsLabel}
                    totalAvailable={this.props.selectFilter.totalAvailable}
                    moreLoading={this.props.selectFilter.moreLoading}
                    loadMore={this.props.selectFilter.loadMore}
                    disabled={this.props.disabled}
                />;
            case 'multiselect':
                return <MultiSelect
                    id={this.id}
                    placeholder={this.props.placeHolder}
                    availableOptions={this.props.options}
                    selectedOptions={this.props.value ? this.props.value : []}
                    updateSelectedOptions={this.changeValue.bind(this)}
                    onBlur={this.onBlur.bind(this)}
                    allowClear={this.props.clearable}
                    open={this.state.open}
                    toggle={this.toggle.bind(this)}
                    usePills={true}
                    currentFilter={this.props.selectFilter.currentFilter}
                    updateFilter={this.props.selectFilter.updateFilter}
                    allKnownOptions={this.props.selectFilter.allKnownOptions}
                    noResultsLabel={this.props.selectFilter.noResultsLabel}
                    totalAvailable={this.props.selectFilter.totalAvailable}
                    moreLoading={this.props.selectFilter.moreLoading}
                    loadMore={this.props.selectFilter.loadMore}
                    disabled={this.props.disabled}
                />;
            case 'datetime':
                return <DateTimeInput
                    {...this.props}
                    value={this.props.value}
                    locale={this.props.locale ? this.props.locale.iso : 'en-GB'}
                    onChange={this.props.onChange}
                    onBlur={this.onBlur.bind(this)}
                    timeFormat={true}
                    showClear={this.props.clearable}
                />;
            case 'date':
                return <DateTimeInput
                    {...this.props}
                    value={this.props.value}
                    locale={this.props.locale ? this.props.locale.iso : 'en-GB'}
                    onChange={this.props.onChange}
                    onBlur={this.onBlur.bind(this)}
                    timeFormat={false}
                    showClear={this.props.clearable}
                />;
            case 'checkbox':
                return <CheckBox
                    id={this.id}
                    value={1}
                    label={this.props.label}
                    checked={this.props.value}
                    onChange={this.changeCheckBox.bind(this)}
                    onBlur={this.onBlur.bind(this)}
                    disabled={this.props.disabled}
                />;
            case 'radiobutton':
                return <RadioButtonGroup
                    id={this.id}
                    options={this.props.options}
                    value={this.props.value}
                    onChange={this.changeRadioButton.bind(this)}
                    onBlur={this.onBlur.bind(this)}
                    disabled={this.props.disabled}
                />;
            default:
                return <code>Input Type not valid</code>
        }
    }

    error() {
        if (!this.props.validationErrorMessage) return;
        return <span className="help-block">{this.props.validationErrorMessage}</span>;
    }

    helpBlock() {
        if (!this.props.helpBlock) return;
        return <div className="help-block ignore-error">{this.props.helpBlock}</div>
    }

    render() {
        const className = this.props.validationErrorMessage ? 'react-form-control form-group has-error' : 'react-form-control form-group';
        if (this.props.toolTip) {
            return <div className={className}>
                <ToolTip placement={this.props.toolTipPosition} tooltip={this.props.toolTip}>
                    {this.label()}
                    {this.inputElement()}
                    {this.error()}
                    {this.helpBlock()}
                </ToolTip>
            </div>;
        } else {
            return <div className={className}>
                {this.label()}
                {this.inputElement()}
                {this.error()}
                {this.helpBlock()}
            </div>;
        }
    }

}

CampusFormControl.propTypes = {
    inputType: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validationErrorMessage: PropTypes.string,
    placeHolder: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
        PropTypes.array
    ]),
    disabled: PropTypes.bool,
    size: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    options: PropTypes.array,
    locale: PropTypes.object,
    clearable: PropTypes.bool,
    helpBlock: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    toolTip: PropTypes.string,
    toolTipPosition: PropTypes.string,
    selectFilter: PropTypes.shape({
        // for select ajax filtering:
        currentFilter: PropTypes.string,
        updateFilter: PropTypes.func,
        noResultsLabel: PropTypes.string,
        allKnownOptions: PropTypes.array,

        // for select pagination:
        totalAvailable: PropTypes.number,
        moreLoading: PropTypes.bool,
        loadMore: PropTypes.func
    }),
};

CampusFormControl.defaultProps = {
    validationErrorMessage: '',
    placeHolder: '',
    value: '',
    disabled: false,
    size: '',
    clearable: false,
    toolTip: '',
    toolTipPosition: 'bottom',
    selectFilter: {
        currentFilter: undefined,
        updateFilter: undefined,
        noResultsLabel: undefined,
        allKnownOptions: undefined,
        totalAvailable: undefined,
        moreLoading: false,
        loadMore: undefined
    }
};

export default CampusFormControl;