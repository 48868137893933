'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import MultiSelect from "../../../CommonComponents/Select/MultiSelect";
import Spinner from "../../../CommonComponents/Spinner/Spinner";

class UserMultiSelect extends React.Component {

    render() {
        let title = (this.props.title ? this.props.title : 'User') + ': ';

        let placeholder = <span>{title}<Spinner/></span>;
        if (this.props.availableValues !== undefined) {
            placeholder = this.props.selectedValues.map((v) => {
                const item = this.props.availableValues.filter(x => x.id === v);
                return item.length > 0 ? item[0].name : '';
            }).join(", ");
            if (!placeholder) placeholder = title + 'All';
            else placeholder = title + placeholder;
        }

        const availableOptions = this.props.availableValues ? this.props.availableValues.map(user => user.forSelect()) : undefined;

        return <MultiSelect
            placeholder={placeholder}
            open={this.props.open}
            toggle={this.props.toggle}
            selectedOptions={this.props.selectedValues}
            availableOptions={availableOptions}
            updateSelectedOptions={this.props.updateSelectedOptions}
        />;
    }

}

UserMultiSelect.propTypes = {
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    updateSelectedOptions: PropTypes.func.isRequired,
    availableValues: PropTypes.array,
    selectedValues: PropTypes.array,
    title: PropTypes.string.isRequired
};

export default UserMultiSelect;