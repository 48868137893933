'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import User from "../User";
import './user.scss';

class UserViewItem extends React.Component {

    formatUserName() {
        if (!this.props.user) return 'Nobody';
        if (this.props.loggedInUser) {
            if (String(this.props.loggedInUser.id) === String(this.props.user.id)) return 'You';
        }
        return this.props.user.name;
    }

    avatar() {
        return (this.props.user && this.props.user.avatar && this.props.showAvatar)
        ? <img src={this.props.user.avatar} alt="" className="avatar img-rounded" height={this.props.avatarHeight}/>
        : null;
    }

    render() {
        return <span className="react-user-view-item">
            {this.avatar()}
            {this.formatUserName()}
        </span>;
    }

}

UserViewItem.propTypes = {
    user: PropTypes.instanceOf(User),
    loggedInUser: PropTypes.instanceOf(User),
    showAvatar: PropTypes.bool,
    avatarHeight: PropTypes.number
};

UserViewItem.defaultProps = {
    showAvatar: true,
    avatarHeight: 20
};

export default UserViewItem;