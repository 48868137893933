import * as ActionTypes from '../Actions/ActionTypes';

const initialState = {
    sispOpen: false,
    autoClose: false
};

export default function (state, action) {
    if (state === undefined) {
        state = initialState;
    }

    switch (action.type) {
        case ActionTypes.OPEN_PREVIEW_SISP:
            return Object.assign({}, state, {
                sispOpen: true,
                autoClose: action.payload.autoClose === undefined ? state.autoClose : !!action.payload.autoClose
            });
        case ActionTypes.CLOSE_PREVIEW_SISP:
        case ActionTypes.OPEN_FORM_SISP:
        case ActionTypes.OPEN_INTERACTION_SISP:
            return Object.assign({}, state, {
                sispOpen: false
            });
        default:
            return state;
    }
}