import { AppEvent, EventBusInstance, PendingButton, Sisp } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import ClientTag from '../../Models/ClientTag';
import ClientTagsForm from './ClientTagsForm';

interface Props {
    uniqueKey: string;
    onSuccess: (event: any) => Promise<boolean>;
}

const ClientTagsEditSisp: FunctionComponent<Props> = (props: Props) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [shown, setShown] = useState<boolean>(false);
    const [message, setMessage] = useState<ClientTag>();

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<ClientTag>) => {
            if (event.target !== props.uniqueKey) return;
            setMessage(event.message);
            setShown(true);
        });
    }, [shown]);

    return (
        <Sisp
            isOpen={shown}
            onCancel={() => {
                setShown(false);
                setIsSubmitting(false);
            }}
            footerOverride={
                <>
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            setIsSubmitting(false);
                            setShown(false);
                        }}
                        variant="secondary"
                        form="tag_add_edit_form"
                    >
                        Cancel
                    </Button>
                    <PendingButton
                        onClick={(event) => {
                            setIsSubmitting(true);
                        }}
                        pending={isSubmitting}
                        type="submit"
                        form="tag_add_edit_form"
                    >
                        Save
                    </PendingButton>
                </>
            }
        >
            <h4>Edit a Tag</h4>
            <ClientTagsForm
                data={message}
                onSubmit={(data: any) => {
                    props.onSuccess(data);
                    setIsSubmitting(false);
                    setShown(false);
                }}
            />
        </Sisp>
    );
};

export default ClientTagsEditSisp;
