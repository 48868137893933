'use strict';

import Currency from "../Currency/Currency";

export default class Price {

    pennies = 0;
    currency = undefined;
    draft = undefined;

    static blank() {
        return new Price();
    }

    static fromApi(price) {
        const entity = Price.blank();

        // Construct from expected API output object
        entity.pennies = price.pennies;
        entity.currency = price.currency ? Currency.fromApi(price.currency) : undefined;
        entity.draft = undefined;

        return entity;
    }

    static fromPennies(pennies, currency) {
        const entity = Price.blank();

        entity.pennies = pennies;
        entity.currency = currency;

        return entity;
    }

    static fromDecimal(decimal, currency) {
        return this.fromPennies(Currency.penniesFromDecimal(decimal, currency), currency);
    }

    static fromInput(input, currency) {
        const price = Price.fromDecimal(input, currency);
        price.draft = input;
        return price;
    }

    static isEmptyInput(candidate) {
        return String(candidate).trim() === '';
    }

    static isValidInput(candidate) {
        return ((typeof candidate === 'number' || typeof candidate === 'string') && !isNaN(candidate))
    }

    accumulate(inputPrice) {
        return Object.assign(Price.blank(), this, {
            pennies: this.pennies + inputPrice.pennies
        });
    }

    divide(divisor) {
        return Object.assign(Price.blank(), this, {
            pennies: divisor > 0 ? Math.ceil(this.pennies / divisor) : 0
        });
    }

    priceFormatted(hideDecimal) {
        return Currency.currencyAmountFormatted(this.pennies, this.currency, hideDecimal);
    }

    forDecimal() {
        return Currency.decimalFromPennies(this.pennies, this.currency);
    }

    forInput() {
        if (!this.draft) return this.forDecimal();

        let draft = String(this.draft);
        return (
            draft.slice(-1) === '.'
            || draft.slice(-2) === '.0'
        ) ? this.draft : this.forDecimal()
    }

}
