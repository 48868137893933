'use strict';

export const SALES_UPDATE_FILTER = 'sale/SALES_UPDATE_FILTER';
export const SALES_LOADED = 'sale/SALES_LOADED';
export const SALES_MORE_LOADING = 'sale/SALES_MORE_LOADING';
export const SALES_MORE_LOADED = 'sale/SALES_MORE_LOADED';
export const SALES_IDS_LOADED = 'sale/SALES_IDS_LOADED';
export const SALES_LOADING_CANCELLED = 'sale/SALES_LOADING_CANCELLED';
export const SALES_LOADING_FAILED = 'sale/SALES_LOADING_FAILED';
export const SALE_LINKING_TO_DEAL = 'sale/SALE_LINKING_TO_DEAL';
export const SALE_LINKED_TO_DEAL = 'sale/SALE_LINKED_TO_DEAL';
export const SALE_LINKING_TO_DEAL_FAILED = 'sale/SALE_LINKING_TO_DEAL_FAILED';