import * as ActionTypes from "./ActionTypes";
import * as EntityState from "../../../ValueObjects/EntityState/EntityState";
import * as DealStageState from '../../../ValueObjects/DealStageState/DealStageState';
import DealPipeline from "./DealPipeline";
import DealPipelineStage from "./DealPipelineStage/DealPipelineStage";

const initialState = {
    searchResults: undefined,
    allKnownResults: [ DealPipeline.blank() ],

    selectedId: undefined,
    idToLoad: undefined,
    error: undefined
};

function select(allKnownResults, id) {
    return Object.assign(DealPipeline.blank(), DealPipeline.select(allKnownResults, id));
}

function mergeResults(existingResults, newResults) {
    if (existingResults === undefined) return newResults;
    return existingResults.filter(existingResult => {
        return newResults.map(newResult => newResult.id).indexOf(existingResult.id) === -1;
    }).map(existingResult => existingResult.clone()).concat(newResults);
}

function replaceOrAddResult(allKnownResults, incomingResult) {
    if (allKnownResults === undefined) allKnownResults = [];
    const matches = result => result.id === incomingResult.id;
    const newKnownResults = allKnownResults.map(result => {
        const updatedResult = result.clone();
        if (incomingResult.isDefault) updatedResult.isDefault = false;
        return matches(result) ? incomingResult : updatedResult;
    });
    if (allKnownResults.find(matches) === undefined) newKnownResults.push(incomingResult);
    return newKnownResults;
}

function replaceOrAddStage(stages, existingStageName, incomingStage) {
    if (stages === undefined) stages = [];
    const matches = stage => stage.name === existingStageName;
    const conflicts = stage => stage.name === incomingStage.name && !matches(stage);

    // Prevent name change conflicting with existing stage and causing overwrite/duplication
    if (stages.filter(conflicts).length) return stages;

    // Prevent closed stages from becoming defaults
    if (incomingStage.isClosed() && incomingStage.isDefault) incomingStage.isDefault = false;

    const newStages = stages.map(stage => {
        const updatedStage = stage.clone();
        if (incomingStage.isDefault) updatedStage.isDefault = false;
        return matches(stage) ? incomingStage : updatedStage;
    });
    if (stages.find(matches) === undefined) newStages.push(incomingStage);
    return newStages;
}

function removeResult(allKnownResults, resultToRemove) {
    if (!resultToRemove) return allKnownResults;
    return allKnownResults.filter(result => result.id !== resultToRemove.id);
}

function removeStage(stages, stageToRemove) {
    if (!stageToRemove) return stages;
    return stages.filter(stage => stage.name !== stageToRemove.name);
}

function enforceStageOrder(stages) {
    if (!stages) return stages;

    const filterStagesByState = stageState => DealPipelineStage.filterByState(stages, stageState);
    const openStages = filterStagesByState(DealStageState.OPEN);
    const closedWonStages = filterStagesByState(DealStageState.CLOSED_WON);
    const closedLostStages = filterStagesByState(DealStageState.CLOSED_LOST);

    return [].concat(openStages, closedWonStages, closedLostStages);
}

function adjustValues(dealPipeline) {
    if (dealPipeline.stages) dealPipeline.stages = enforceStageOrder(dealPipeline.stages);

    return dealPipeline;
}

export default function (state, action) {
    if (state === undefined) {
        state = initialState;
    }

    const selectedEntity = select(state.allKnownResults, state.selectedId);

    switch (action.type) {
        case ActionTypes.DEALPIPELINES_LOADED: {
            try {
                const results = action.payload.dealPipelines.map(dealPipeline => DealPipeline.fromApi(dealPipeline));
                return Object.assign({}, state, {
                    searchResults: results,
                    allKnownResults: mergeResults(state.allKnownResults, results)
                });
            } catch (e) {
                return Object.assign({}, state, {
                    error: 'Unable to initialise deal pipeline object: ' + e.message
                });
            }
        }
        case ActionTypes.DEALPIPELINES_LOADING_FAILED: {
            return Object.assign({}, state, {
                error: 'Unable to load deal pipelines: ' + action.payload.error
            });
        }
        case ActionTypes.DEALPIPELINE_NEW: {
            const blankEntity = DealPipeline.blank();

            blankEntity.stages = DealPipeline.defaultStages();

            blankEntity.transitionState(EntityState.READY);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, blankEntity),
                selectedId: blankEntity.id
            });
        }
        case ActionTypes.DEALPIPELINE_UPDATE_FIELD: {
            const targetEntity = select(state.allKnownResults, action.payload.id);
            targetEntity[action.payload.fieldName] = action.payload.value;
            targetEntity.transitionState(EntityState.MODIFIED);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, adjustValues(targetEntity))
            });
        }
        case ActionTypes.DEALPIPELINE_UPDATE_STAGE: {
            const targetEntity = select(state.allKnownResults, action.payload.id);
            targetEntity['stages'] = replaceOrAddStage(
                targetEntity['stages'],
                action.payload.existingStageName,
                action.payload.stageEntity
            );
            targetEntity.transitionState(EntityState.MODIFIED);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, adjustValues(targetEntity))
            });
        }
        case ActionTypes.DEALPIPELINE_REORDER_STAGE: {
            const targetEntity = select(state.allKnownResults, action.payload.id);

            const newStagesArray = Array.from(targetEntity['stages']);
            const targetStage = newStagesArray[action.payload.oldStageIndex];
            newStagesArray.splice(action.payload.oldStageIndex, 1);
            newStagesArray.splice(action.payload.newStageIndex, 0, targetStage);
            targetEntity['stages'] = newStagesArray;

            targetEntity.transitionState(EntityState.MODIFIED);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, adjustValues(targetEntity))
            });
        }
        case ActionTypes.DEALPIPELINE_DELETE_STAGE: {
            const targetEntity = select(state.allKnownResults, action.payload.id);
            targetEntity['stages'] = removeStage(targetEntity['stages'], action.payload.stageEntity);
            targetEntity.transitionState(EntityState.MODIFIED);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, adjustValues(targetEntity))
            });
        }
        case ActionTypes.DEALPIPELINE_SAVING: {
            const savingEntity = action.payload.entity.clone();
            savingEntity.transitionState(EntityState.SAVING);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, savingEntity)
            });
        }
        case ActionTypes.DEALPIPELINE_SAVED: {
            try {
                const savedEntity = DealPipeline.fromApi(action.payload.data);
                savedEntity.transitionState(EntityState.SAVED);

                return Object.assign({}, state, {
                    allKnownResults: replaceOrAddResult(state.allKnownResults, savedEntity),
                    searchResults: undefined,
                    selectedId: savedEntity.id
                });
            } catch (e) {
                const targetEntity = select(state.allKnownResults, action.payload.id);
                targetEntity.transitionState(EntityState.ERROR, 'Unable to initialise deal pipeline object: ' + e.message);

                return Object.assign({}, state, {
                    allKnownResults: replaceOrAddResult(state.allKnownResults, targetEntity)
                });
            }
        }
        case ActionTypes.DEALPIPELINE_SAVING_FAILED: {
            const targetEntity = select(state.allKnownResults, action.payload.id);
            targetEntity.transitionState(EntityState.ERROR, action.payload.error);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, targetEntity)
            });
        }
        case ActionTypes.DEALPIPELINE_SELECT: {
            const targetEntity = select(state.allKnownResults, action.payload.id);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, targetEntity),
                selectedId: action.payload.id,
                idToLoad: targetEntity.id ? undefined : action.payload.id,
                error: undefined
            });
        }
        case ActionTypes.DEALPIPELINE_LOADING: {
            const targetEntity = select(state.allKnownResults, action.payload.id);
            targetEntity.transitionState(EntityState.LOADING);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, targetEntity),
                idToLoad: undefined
            });
        }
        case ActionTypes.DEALPIPELINE_LOADED: {
            try {
                const loadedEntity = DealPipeline.fromApi(action.payload.data);
                loadedEntity.transitionState(EntityState.LOADED);

                return Object.assign({}, state, {
                    allKnownResults: replaceOrAddResult(state.allKnownResults, loadedEntity),
                    idToLoad: undefined,
                    error: undefined
                });
            } catch (e) {
                return Object.assign({}, state, {
                    idToLoad: undefined,
                    error: 'Unable to initialise deal pipeline object: ' + e.message
                });
            }
        }
        case ActionTypes.DEALPIPELINE_LOADING_FAILED: {
            return Object.assign({}, state, {
                idToLoad: undefined,
                error: action.payload.error
            });
        }
        case ActionTypes.DEALPIPELINE_INVALIDATED: {
            const targetEntity = select(state.allKnownResults, action.payload.id);
            targetEntity.transitionState(EntityState.INVALIDATED);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, targetEntity),
                idToLoad: targetEntity.id // Reload invalidated entity from server
            });
        }
        case ActionTypes.DEALPIPELINE_DELETING: {
            const targetEntity = select(state.allKnownResults, action.payload.id);
            targetEntity.transitionState(EntityState.DELETING);

            return Object.assign({}, state, {
                allKnownResults: replaceOrAddResult(state.allKnownResults, targetEntity)
            });
        }
        case ActionTypes.DEALPIPELINE_DELETED: {
            const targetEntity = select(state.allKnownResults, action.payload.id);

            return Object.assign({}, state, {
                allKnownResults: removeResult(state.allKnownResults, targetEntity),
                selectedId: undefined
            });
        }
        default: {
            return state;
        }
    }
}