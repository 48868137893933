export const HIGHEST = 'Highest';
export const HIGH = 'High';
export const MEDIUM = 'Medium';
export const LOW = 'Low';
export const LOWEST = 'Lowest';

export function allOptions() {
    return [
        HIGHEST, HIGH, MEDIUM, LOW, LOWEST
    ];
}

export function defaultOption () {
    return MEDIUM;
}

export function forSelect() {
    return allOptions().map(option => ({
        id: option,
        label: option
    }));
}