'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {filterOrganisations, loadIdList, loadMore} from "../Actions";
import OrganisationMultiSelect from "./OrganisationMultiSelect";

class ConnectedOrganisationMultiSelect extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        if (this.props.searchResults === undefined) this.filterOrganisations('');
    }

    filterOrganisations(filter) {
        this.props.dispatch(filterOrganisations(filter));
    }

    loadMore() {
        this.props.dispatch(loadMore(this.props.searchResults ? this.props.searchResults.length : 0, this.props.currentFilter));
    }

    searchResultsForSelect() {
        if (!this.props.searchResults) return undefined;
        return this.props.searchResults.map(entity => entity.forSelect());
    }

    allKnownResultsForSelect() {
        if (!this.props.allKnownResults) return undefined;
        return this.props.allKnownResults.map(entity => entity.forSelect());
    }

    render() {
        return <OrganisationMultiSelect
            selectedValues={this.props.selectedValues}
            open={this.props.open}
            toggle={this.props.toggle}
            updateSelectedOptions={this.props.updateSelectedOptions}
            availableValues={this.searchResultsForSelect()}
            currentFilter={this.props.currentFilter}
            updateFilter={this.filterOrganisations.bind(this)}
            allKnownValues={this.allKnownResultsForSelect()}
            totalAvailable={this.props.totalAvailableResults}
            loadMore={this.loadMore.bind(this)}
            moreLoading={this.props.moreLoading}
        />;
    }

}

ConnectedOrganisationMultiSelect.getDerivedStateFromProps = (props, prevState) => {
    const selectedValuesNotPresent = props.selectedValues.filter(v => {
        return props.allKnownResults.filter(r => r.id === v).length === 0;
    });

    if (selectedValuesNotPresent.length > 0) props.dispatch(loadIdList(selectedValuesNotPresent));

    return prevState;
};

ConnectedOrganisationMultiSelect.propTypes = {
    selectedValues: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    updateSelectedOptions: PropTypes.func.isRequired
};

function select(state) {
    return {
        currentFilter: state.organisations.currentFilter,
        searchResults: state.organisations.searchResults,
        allKnownResults: state.organisations.allKnownResults,
        totalAvailableResults: state.organisations.totalAvailableResults,
        moreLoading: state.organisations.moreLoading
    }
}

export default connect(select)(ConnectedOrganisationMultiSelect);