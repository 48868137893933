'use strict';

export default class Validation {

    constructor(fieldBlurList = undefined, formSubmitAttempted = true) {
        this.errors = [];
        this.fieldBlurList = fieldBlurList;
        this.formSubmitAttempted = formSubmitAttempted;
    }

    addError(fieldName, message) {
        this.errors.push({
            fieldName: fieldName,
            message: message
        })
    }

    hasErrors() {
        return this.errors.length > 0;
    }

    messageForField(fieldName) {
        const filtered = this.errors.filter(e => e.fieldName === fieldName);
        if (filtered.length === 0) return '';

        // Suppress validation message if field has never been touched and submit button has never been pressed.
        if (this.fieldBlurList && !this.fieldBlurList[fieldName] && !this.formSubmitAttempted) return '';

        return filtered[0].message;
    }


}