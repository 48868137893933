import { UserPermissionsContext } from '@client/Context/UserPermissions';
import {
    ExtendedColumn,
    FilterBarFilterType,
    filter as filterType,
    HoveroverButton,
    OptionTypeBase,
    SearchQuery,
    SortOrder,
    StyledPill,
} from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { ClientGroupsRequest } from '../../Api/ClientGroupsRequest';
import { CustomPropertiesDataTypeRequest } from '../../Api/CustomPropertiesDataTypeRequest';
import { CustomPropertiesRequest } from '../../Api/CustomPropertiesRequest';
import { CustomPropertiesTypeRequest } from '../../Api/CustomPropertiesTypeRequest';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import { RepositoryFactoryContext } from '../../index';
import ClientGroup from '../../Models/ClientGroup';
import CustomProperty from '../../Models/CustomProperty';
import CustomPropertyDataType from '../../Models/CustomPropertyDataType';
import CustomPropertyEditState from '../../Models/CustomPropertyEditState';
import { CustomPropertyType } from '../../Models/CustomPropertyType';
import { ClientGroupType, HTMLColors, UniqueKeyType } from '../../Models/Enums';
import CampusDataGrid, {
    ActionBarMeta,
    AddSispMeta,
    DataGridMeta,
    EditSispMeta,
    LimitsModalMeta,
    PromptMeta,
} from '../CampusDataGrid';
import CustomPropertiesAddSisp from './CustomPropertiesAddSisp';
import CustomPropertiesEditSisp from './CustomPropertiesEditSisp';
import './CustomPropertiesTable.scss';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

enum CustomPropertiesColumnKey {
    ID,
    NAME,
    DESCRIPTION,
    TYPE,
    DATA_TYPE,
    GROUP,
    SORT_ORDER,
}

enum CustomPropertiesFiltersKey {
    TYPE,
    DATA_TYPE,
    GROUP,
    ARCHIVED,
}

const CustomPropertiesTable: FunctionComponent<Props> = (props: Props) => {
    const editSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);
    const archiveModalUniqueKey = UniqueKeyBuilder.make(editSispUniqueKey, UniqueKeyType.ARCHIVE_ACTION);
    const restoreModalUniqueKey = UniqueKeyBuilder.make(editSispUniqueKey, UniqueKeyType.RESTORE_ACTION);

    const userPermissions = useContext(UserPermissionsContext);
    const customPropertiesRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new CustomPropertiesRequest(),
    );
    const customPropertiesTypeRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new CustomPropertiesTypeRequest(),
    );
    const customPropertiesDataTypeRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new CustomPropertiesDataTypeRequest(),
    );
    const customPropertiesClientGroupsRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new ClientGroupsRequest(ClientGroupType.CUSTOM_PROPERTIES),
    );

    const [reload, setReload] = useState<boolean>(false);

    const Columns: Record<CustomPropertiesColumnKey, ExtendedColumn> = {
        [CustomPropertiesColumnKey.ID]: {
            key: 'id',
            name: 'ID',
        },
        [CustomPropertiesColumnKey.NAME]: {
            key: 'name',
            name: 'Name',
            sortable: true,
            width: '4fr',
            renderCell: (fprops) => {
                const id = (fprops.row as CustomProperty).id!;
                const name = (fprops.row as CustomProperty).name;
                const description = (fprops.row as CustomProperty).description;
                const type = (fprops.row as CustomProperty).type;
                const data_type = (fprops.row as CustomProperty).data_type;
                const sort_order = (fprops.row as CustomProperty).sort_order;
                const available_in_forms = (fprops.row as CustomProperty).available_in_forms;
                const show_in_view = (fprops.row as CustomProperty).show_in_view;
                const archived = (fprops.row as CustomProperty).archived;
                const options = (fprops.row as CustomProperty).options;
                const client_group_id = (fprops.row as CustomProperty).client_group_info?.id ?? null;
                const eventBusMessage: CustomPropertyEditState = {
                    id: id,
                    name: name,
                    description: description,
                    type: type,
                    data_type: data_type,
                    sort_order: sort_order,
                    available_in_forms: available_in_forms,
                    show_in_view: show_in_view,
                    archived: archived,
                    options: options,
                    client_group_id: client_group_id,
                };
                return (
                    <>
                        {name}
                        {archived ? (
                            <StyledPill
                                cellContent="Archived"
                                style={{ backgroundColor: HTMLColors.ARCHIVED, marginLeft: '5px' }}
                            />
                        ) : (
                            <HoveroverButton
                                contents="Edit"
                                eventBusMessageTarget={editSispUniqueKey}
                                eventBusMessage={eventBusMessage}
                            />
                        )}
                        <HoveroverButton
                            contents="Archive"
                            showHoverover={!archived}
                            eventBusMessageTarget={archiveModalUniqueKey}
                            eventBusMessage={eventBusMessage}
                        />
                        <HoveroverButton
                            contents="Restore"
                            showHoverover={archived}
                            eventBusMessageTarget={restoreModalUniqueKey}
                            eventBusMessage={eventBusMessage}
                        />
                    </>
                );
            },
        },
        [CustomPropertiesColumnKey.DESCRIPTION]: {
            key: 'description',
            name: 'Description',
            width: '3fr',
        },
        [CustomPropertiesColumnKey.TYPE]: {
            key: 'type',
            name: 'Type',
            sortable: true,
            width: '2fr',
        },
        [CustomPropertiesColumnKey.DATA_TYPE]: {
            key: 'data_type',
            name: 'Data Type',
            sortable: true,
            width: '2fr',
        },
        [CustomPropertiesColumnKey.GROUP]: {
            key: 'client_group',
            name: 'Group',
            sortable: true,
            width: '2fr',
            renderCell: (fprops) => {
                const client_group_info = (fprops.row as CustomProperty).client_group_info;
                const client_group_name = client_group_info ? client_group_info.name : '';
                return <>{client_group_name}</>;
            },
        },
        [CustomPropertiesColumnKey.SORT_ORDER]: {
            key: 'sort_order',
            name: 'Sort Order',
            sortable: true,
            width: '1fr',
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[CustomPropertiesColumnKey.NAME],
        Columns[CustomPropertiesColumnKey.DESCRIPTION],
        Columns[CustomPropertiesColumnKey.TYPE],
        Columns[CustomPropertiesColumnKey.DATA_TYPE],
        Columns[CustomPropertiesColumnKey.SORT_ORDER],
    ];

    if (userPermissions.clientGroupsCustomProperties.isEnabled) {
        DefaultColumns.splice(3, 0, Columns[CustomPropertiesColumnKey.GROUP]);
    }

    // Filter Bar
    const FilterBarFilters: Record<CustomPropertiesFiltersKey, filterType.BasicFilter> = {
        [CustomPropertiesFiltersKey.TYPE]: {
            title: 'Type',
            name: 'type',
            loadOptions: async () => {
                const query = new SearchQuery(1, 100);
                return customPropertiesTypeRepository
                    .search(query)
                    .then((results: any) => {
                        return {
                            options: results.results.map((result: OptionTypeBase) => {
                                return { value: result.value, label: result.label };
                            }),
                        };
                    })
                    .catch((err: any) => {
                        return null;
                    });
            },
        },
        [CustomPropertiesFiltersKey.DATA_TYPE]: {
            title: 'Data Type',
            name: 'data_type',
            loadOptions: async () => {
                const query = new SearchQuery(1, 100);
                return customPropertiesDataTypeRepository
                    .search(query)
                    .then((results: any) => {
                        return {
                            options: results.results.map((result: CustomPropertyDataType) => {
                                return { value: result.value, label: result.label };
                            }),
                        };
                    })
                    .catch((err: any) => {
                        return null;
                    });
            },
        },
        [CustomPropertiesFiltersKey.GROUP]: {
            title: 'Groups',
            name: 'groups',
            loadOptions: async () => {
                const query = new SearchQuery(1, 1000);
                return customPropertiesClientGroupsRepository
                    .search(query)
                    .then((results: any) => {
                        return {
                            options: results.results.map((result: ClientGroup) => {
                                return { value: result.id, label: result.name };
                            }),
                        };
                    })
                    .catch((err: any) => {
                        return null;
                    });
            },
        },
        [CustomPropertiesFiltersKey.ARCHIVED]: {
            title: 'Show Archived',
            name: 'archived',
            filterType: FilterBarFilterType.CHECKBOX,
        },
    };

    const getFilterBarFilters = (): filterType.BasicFilter[] => {
        const filterBarFilters = [
            FilterBarFilters[CustomPropertiesFiltersKey.TYPE],
            FilterBarFilters[CustomPropertiesFiltersKey.DATA_TYPE],
            FilterBarFilters[CustomPropertiesFiltersKey.ARCHIVED],
        ];
        if (userPermissions.clientGroupsCustomProperties.isEnabled) {
            filterBarFilters.splice(2, 0, FilterBarFilters[CustomPropertiesFiltersKey.GROUP]);
        }
        return filterBarFilters;
    };

    useEffect(() => {
        // reset reload if true
        if (reload) setReload(false);
    }, [reload]);

    const dataGridMeta: DataGridMeta = {
        uniqueKey: props.dataGridUniqueKey,
        entitySingular: props.dataGridEntitySingular,
        entityPlural: props.dataGridEntityPlural,
        columnOptions: Columns,
        defaultColumns: DefaultColumns,
        frozenColumns: [],
        forceReload: reload,
        defaultSortColumn: 'name',
        defaultSortDirection: SortOrder.ASC,
    };

    const actionBarMeta: ActionBarMeta = {
        searchPlaceHolder: props.searchFilterPlaceholder,
        includeCounts: true,
        filterBarMeta: {
            filters: getFilterBarFilters(),
        },
    };

    const addSispMeta: AddSispMeta = {
        key: UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP),
        sisp: CustomPropertiesAddSisp,
    };

    const editSispMeta: EditSispMeta = {
        sisp: CustomPropertiesEditSisp,
        additionalProps: {
            reloadDataGrid: () => setReload(true),
        },
    };

    // The limitApiKeys correspond to the values in the custom fields limit builders
    // which can be found in App\Campus\Limit\LimitBuilders
    const limitsModalMeta: LimitsModalMeta = {
        limits: [
            { limitApiKey: 'custom_properties_contacts_limit', type: CustomPropertyType.CONTACT },
            { limitApiKey: 'custom_properties_organisations_limit', type: CustomPropertyType.ORGANISATION },
            { limitApiKey: 'custom_properties_tasks_limit', type: CustomPropertyType.TASK },
            { limitApiKey: 'custom_properties_deals_limit', type: CustomPropertyType.DEAL },
        ],
    };

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_custom_properties.png',
        iconHeight: 80,
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.CREATE_CUSTOM_PROPERTY),
    };

    return (
        <>
            <CampusDataGrid
                repository={customPropertiesRepository}
                actionBarMeta={actionBarMeta}
                addSispMeta={addSispMeta}
                editSispMeta={editSispMeta}
                dataGridMeta={dataGridMeta}
                limitsModalMeta={limitsModalMeta}
                promptMeta={promptMeta}
            />
        </>
    );
};

export default CustomPropertiesTable;
