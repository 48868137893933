import { User } from '@sprint/sprint-react-components';
import _ from 'lodash';
import { PermissionsState } from '../../../Context/UserPermissions';
import { UserPermissionLevel } from '../Models/Enums';

export type DataGridColumnPermission = 'active' | 'invisible' | 'disabled';

class UserPermissionsHelper {
    public static isUserAnAdmin = (user: User): boolean => {
        // If user has a type of master then it has admin
        // level permissions
        if (user.type === UserPermissionLevel.ADMIN) {
            return true;
        }

        const userLevel: string = _.capitalize(user.level);
        if (userLevel === UserPermissionLevel.ADMIN) {
            return true;
        }

        return false;
    };

    public static isUserAnAdminOrManager = (user: User): boolean => {
        if (UserPermissionsHelper.isUserAnAdmin(user)) {
            return true;
        } else {
            const userLevel: string = _.capitalize(user.level);
            if (userLevel === UserPermissionLevel.MANAGER) {
                return true;
            }
        }

        return false;
    };

    public static makeDataGridColumnPermission = (state: PermissionsState): DataGridColumnPermission => {
        let permission: DataGridColumnPermission = 'invisible';
        if (state.isEnabled) {
            permission = 'active';
        } else if (state.isVisible) {
            permission = 'disabled';
        }
        return permission;
    };
}

export default UserPermissionsHelper;
