import { faPhone } from '@fortawesome/pro-duotone-svg-icons';
import { faAt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './AvailableTeacherData.scss';

interface Props {
    email?: string;
    schoolPhone?: string;
    linkedInProfile?: string;
}

const AvailableTeacherData: FunctionComponent<Props> = (props: Props) => {
    return (
        <div className="available-teacher-data flex-wrapper">
            <OverlayTrigger
                overlay={(overlayProps) => (
                    <Tooltip id="email-tooltip" {...overlayProps}>
                        {props.email ? 'This person has an email address' : 'This person doesn’t have an email address'}
                    </Tooltip>
                )}
            >
                <div className={'square' + (!props.email ? ' disabled' : '')}>
                    <FontAwesomeIcon icon={faAt} />
                </div>
            </OverlayTrigger>
            <OverlayTrigger
                overlay={(overlayProps) => (
                    <Tooltip id="phone-tooltip" {...overlayProps}>
                        {props.schoolPhone
                            ? 'This person has a school phone number'
                            : 'This person doesn’t have a school phone number'}
                    </Tooltip>
                )}
            >
                <div className={'square' + (!props.schoolPhone ? ' disabled' : '')}>
                    <FontAwesomeIcon icon={faPhone} />
                </div>
            </OverlayTrigger>
            {/* Commented out until we have linkedin data */}
            {/* <OverlayTrigger
                overlay={(overlayProps) => (
                    <Tooltip id="linkedin-tooltip" {...overlayProps}>
                        {props.linkedInProfile
                            ? 'This person has a LinkedIn profile'
                            : 'This person doesn’t have a LinkedIn profile'}
                    </Tooltip>
                )}
            >
                <div className={'square' + (!props.linkedInProfile ? ' disabled' : '')}>
                    <FontAwesomeIcon icon={faLinkedin} />
                </div>
            </OverlayTrigger> */}
        </div>
    );
};

export default AvailableTeacherData;
