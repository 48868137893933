'use strict';

import React from 'react';
import PropTypes from "prop-types";
import MultiSelect from "../../../CommonComponents/Select/MultiSelect";
import Spinner from "../../../CommonComponents/Spinner/Spinner";

class OrganisationMultiSelect extends React.Component {

    addSelectedValuesToAvailableOptions(availableOptions, selectedValues, allKnownValues) {
        // get the actual values from the list of IDs
        let selectedOptions = allKnownValues.filter(v => selectedValues.indexOf(v.id) !== -1);

        // remove any that are already in the availableOptions array
        selectedOptions = selectedOptions.filter(o => {
            return availableOptions.map(ao => parseInt(ao.id)).indexOf(parseInt(o.id)) === -1;
        });

        return availableOptions.concat(selectedOptions);
    }

    render() {
        let placeholder = typeof this.props.selectedValues !== 'undefined'
            ? this.props.selectedValues.map((v) => {
                const item = this.props.allKnownValues.filter(x => x.id === v);
                return item.length > 0 ? item[0].label : '';
            }).filter(s => !!s).join(", ")
            : '';

        // Show spinner when loading selection and no labels yet available
        if (!placeholder && this.props.moreLoading
            && typeof this.props.selectedValues !== 'undefined'
            && this.props.selectedValues.length > 0) placeholder = <span>{'Organisations: '}<Spinner/></span>;
        else if (!placeholder) placeholder = 'Organisations: All';

        let availableOptions = this.props.availableValues;

        if (!this.props.currentFilter && availableOptions !== undefined
            && typeof this.props.selectedValues !== 'undefined'
            && this.props.selectedValues.length > 0) {
            availableOptions = this.addSelectedValuesToAvailableOptions(availableOptions, this.props.selectedValues, this.props.allKnownValues);
        }

        return <MultiSelect
            placeholder={placeholder}
            open={this.props.open}
            toggle={this.props.toggle}
            selectedOptions={this.props.selectedValues}
            availableOptions={availableOptions}
            updateSelectedOptions={this.props.updateSelectedOptions}
            currentFilter={this.props.currentFilter}
            updateFilter={this.props.updateFilter}
            totalAvailable={this.props.totalAvailable}
            loadMore={this.props.loadMore}
            moreLoading={this.props.moreLoading}
        />;
    }
}

OrganisationMultiSelect.propTypes = {
    availableValues: PropTypes.array,
    allKnownValues: PropTypes.array,
    selectedValues: PropTypes.array,
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    updateSelectedOptions: PropTypes.func.isRequired,
    currentFilter: PropTypes.string.isRequired,
    updateFilter: PropTypes.func.isRequired,
    totalAvailable: PropTypes.number,
    loadMore: PropTypes.func,
    moreLoading: PropTypes.bool
};

export default OrganisationMultiSelect;