import { DefaultPillFormatter, ExtendedColumn, HoveroverButton } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext } from 'react';
import { VatRateRequest } from '../../Api/VatRateRequest';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import { UniqueKeyType } from '../../Models/Enums';
import VatRate from '../../Models/VatRate';
import VatRateEditState from '../../Models/VatRateEditState';
import { RepositoryFactoryContext } from '../../index';
import CampusDataGrid, { PromptMeta } from '../CampusDataGrid';
import VatRatesAddSisp from './VatRatesAddSisp';
import VatRatesEditSisp from './VatRatesEditSisp';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

export enum VatRatesColumnKey {
    ID,
    VAT_RATE,
    DESCRIPTION,
}

const VatRatesTable: FunctionComponent<Props> = (props: Props) => {
    const addSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP);
    const editSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);

    const vatRateRepository = useContext(RepositoryFactoryContext).getApiRepository(new VatRateRequest());

    const Columns: Record<VatRatesColumnKey, ExtendedColumn> = {
        [VatRatesColumnKey.ID]: {
            key: 'id',
            name: 'ID',
            sortable: false,
        },
        [VatRatesColumnKey.VAT_RATE]: {
            key: 'vat_rate',
            name: 'Tax Rate %',
            sortable: true,
            width: '2fr',
            renderCell: (props) => {
                const id = (props.row as VatRate).id!;
                const name = (props.row as VatRate).vat_rate;
                const description = (props.row as VatRate).description;
                const is_default = (props.row as VatRate).is_default!;
                const eventBusMessage: VatRateEditState = {
                    id: id,
                    vat_rate: name,
                    description: description,
                    is_default: is_default,
                };
                return (
                    <>
                        <DefaultPillFormatter cellContent={name} showPill={is_default}>
                            <HoveroverButton
                                contents="Edit"
                                eventBusMessageTarget={editSispUniqueKey}
                                eventBusMessage={eventBusMessage}
                            />
                        </DefaultPillFormatter>
                    </>
                );
            },
        },
        [VatRatesColumnKey.DESCRIPTION]: {
            key: 'description',
            name: 'Description',
            width: '8fr',
            sortable: true,
            resizable: false,
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[VatRatesColumnKey.VAT_RATE],
        Columns[VatRatesColumnKey.DESCRIPTION],
    ];

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_tax_rates.png',
    };

    return (
        <>
            <CampusDataGrid
                repository={vatRateRepository}
                dataGridMeta={{
                    uniqueKey: props.dataGridUniqueKey,
                    entitySingular: props.dataGridEntitySingular,
                    entityPlural: props.dataGridEntityPlural,
                    defaultColumns: DefaultColumns,
                    columnOptions: Columns,
                }}
                actionBarMeta={{ searchPlaceHolder: props.searchFilterPlaceholder, includeCounts: true }}
                addSispMeta={{
                    key: addSispUniqueKey,
                    sisp: VatRatesAddSisp,
                }}
                editSispMeta={{
                    sisp: VatRatesEditSisp,
                }}
                promptMeta={promptMeta}
            />
        </>
    );
};

export default VatRatesTable;
