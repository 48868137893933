import {
    Modal,
    ModalType,
} from '@sprint/sprint-react-components';
import React, { FunctionComponent, useState, useEffect } from 'react';

interface Props {
    modalShown: boolean;
    cancelId: number;
    closeModal: () => void;
    onSubmit: (id: number) => Promise<boolean>;
}

const CampaignsCancelModal: FunctionComponent<Props> = (props: Props) => {

    const [cancelId, setCancelId] = useState<number>(props.cancelId);

    useEffect(() => {
        setCancelId(props.cancelId);
    }, [props.cancelId]);

    const onSubmit = async (): Promise<boolean> => {
        return props
            .onSubmit(cancelId)
            .then(() => {
                props.closeModal();
                return true;
            });
    };

    return (
        <Modal
            isOpen={props.modalShown}
            type={ModalType.YESNO}
            title="Cancel Campaign"
            close={() => props.closeModal()}
            onSubmit={onSubmit}
        >
            Are you sure you want to cancel this campaign?
        </Modal>
    );
}

export default CampaignsCancelModal;
