import React, { ReactNode } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
    value: string;
    name: ReactNode;
    className?: string;
}

const FormWarning = (props: Props): JSX.Element => {
    return (
        <Form.Group className={props.className}>
            <Form.Label className="match-padding">{props.name}</Form.Label>
            <Form.Control disabled={true} value={props.value} type="text" isInvalid={true} />
        </Form.Group>
    );
};

export default FormWarning;
