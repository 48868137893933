import { AppEvent, EventBusInstance, Sisp } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import CrmPreviewRepository from '../../Api/CrmPreviewRepository';
import Contact from '../../Models/Contact';

interface Props {
    uniqueKey: string;
}

const OrganisationsPreviewSisp: FunctionComponent<Props> = (props: Props) => {
    const organisationPreviewRepository = new CrmPreviewRepository('', '/subscribers/organisations/preview');
    const [shown, setShown] = useState<boolean>(false);

    // Form state
    const [contact, setContact] = useState<Contact>();
    const [contactHtml, setContactHtml] = useState<any>(null);

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<Contact>) => {
            if (event.target !== props.uniqueKey) {
                // Reset this sisp if another sisp was triggered
                setContact(undefined);
                setShown(false);
                return;
            }
            setShown(true);
            setContact(event.message);
        });
    }, [shown]);

    useEffect(() => {
        if (!contact) return;
        setContactHtml(null);
        organisationPreviewRepository.preview(contact?.id).then((results) => {
            setContactHtml(results);
        });
    }, [contact]);

    return (
        <Sisp
            isOpen={shown}
            onCancel={() => {
                setContact(undefined);
                setShown(false);
            }}
            footerOverride={
                <>
                    <Button
                        onClick={(event: any) => {
                            setContact(undefined);
                            setShown(false);
                        }}
                    >
                        Close
                    </Button>
                </>
            }
        >
            {contactHtml ? (
                <div dangerouslySetInnerHTML={{ __html: contactHtml }}></div>
            ) : (
                <div style={{ position: 'relative', textAlign: 'center', width: '100%' }}>
                    <Spinner animation="border" role="status" />
                </div>
            )}
        </Sisp>
    );
};

export default OrganisationsPreviewSisp;
