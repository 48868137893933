import ClientTagPill from '@client/CommonComponents/ClientTags/ClientTagPill';
import TagsEditDropdown from '@client/CommonComponents/ClientTags/TagsEditDropdown';
import { UserPermissionsContext } from '@client/Context/UserPermissions';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    AppEvent,
    AsyncDropDown,
    AsyncDropDownPaginated,
    DropDown,
    EventBusInstance,
    LogLevel,
    OptionTypeBase,
    PendingButton,
    SearchQuery,
    showBanner,
    Sisp,
    SortOrder,
} from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { ucwords } from '../../../../Helpers/StringHelper';
import { ContactsRequest } from '../../Api/ContactsRequest';
import { DealLostReasonRequest } from '../../Api/DealLostReasonRequest';
import { DealPipelineRequest } from '../../Api/DealPipelineRequest';
import { DealsRequest } from '../../Api/DealsRequest';
import { DealTypeRequest } from '../../Api/DealTypeRequest';
import { OrganisationsRequest } from '../../Api/OrganisationsRequest';
import { UserTypeRequest } from '../../Api/UserTypeRequest';
import SimpleFilterRequestBuilder from '../../HelperFunctions/SimpleFilterRequestBuilder';
import { DictionaryContext, RepositoryFactoryContext } from '../../index';
import Contact from '../../Models/Contact';
import { Deal, DealEditState } from '../../Models/Deal';
import DealLostReason from '../../Models/DealLostReason';
import { DealPipeline, DealPipelineStage } from '../../Models/DealPipeline';
import DealType from '../../Models/DealType';
import Organisation from '../../Models/Organisation';
import UserType from '../../Models/UserType';
import CustomPropertyForm, { AvailablePropertyTypes } from '../CustomProperties/CustomPropertyForm';
import './DealsSisp.scss';
import { CLOSED_LOST, convertDealToEditState } from './helpers';

interface Props {
    uniqueKey: string;
    onSuccess: (results: any) => Promise<boolean>;
}

const DealsEditSisp: FunctionComponent<Props> = (props: Props) => {
    const [shown, setShown] = useState<boolean>(false);
    const currencyRegexp = /[^0-9\.]+/g;
    const validPaymentRegexp = /^(([0-9]*\.?[0-9]{0,2}){0,1})$/g;

    const dealsRepository = useContext(RepositoryFactoryContext).getApiRepository(new DealsRequest());
    const dealUsersRepository = useContext(RepositoryFactoryContext).getApiRepository(new UserTypeRequest());
    const dealTypesRepository = useContext(RepositoryFactoryContext).getApiRepository(new DealTypeRequest());
    const dealPipelinesRepository = useContext(RepositoryFactoryContext).getApiRepository(new DealPipelineRequest());
    const dealLostReasonsRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new DealLostReasonRequest(),
    );
    const contactsRepository = useContext(RepositoryFactoryContext).getApiRepository(new ContactsRequest());
    const organisationsRepository = useContext(RepositoryFactoryContext).getApiRepository(new OrganisationsRequest());

    const dictionary = useContext(DictionaryContext);
    const permissions = useContext(UserPermissionsContext);
    const customTagsEnabled = permissions.dealsCustomTags.isEnabled;

    const focusRef = useRef<HTMLInputElement>(null);

    const relatesToOptions = {
        none: false,
        contacts: false,
        organisations: false,
    };

    const [relatesToContact, setRelatesToContact] = useState<OptionTypeBase | null>(null);
    const [relatesToOrganisation, setRelatesToOrganisation] = useState<OptionTypeBase | null>(null);

    const [showContact, setShowContact] = useState<boolean>(false);
    const [showOrganisation, setShowOrganisation] = useState<boolean>(false);

    const [rowId, setRowId] = useState<number>();
    const [newName, setNewName] = useState<string>('');
    const [newValue, setNewValue] = useState<string>('');
    const [newDealType, setNewDealType] = useState<number | null>(null);
    const [newPipeline, setNewPipeline] = useState<number | null>(null);
    const [newStage, setNewStage] = useState<OptionTypeBase | null>(null);
    const [newOwnedBy, setNewOwnedBy] = useState<number | null>(null);
    const [newFollowUpDetails, setNewFollowUpDetails] = useState<Date | null>(null);
    const [newCloseByDetails, setNewCloseByDetails] = useState<Date | null>(null);
    const [newRelatesToDetails, setNewRelatesToDetails] = useState(relatesToOptions);
    const [newDescription, setNewDescription] = useState<string>('');
    const [newLostReason, setNewLostReason] = useState<number | null>(null);

    const [newNameValid, setNewNameValid] = useState<boolean>(true);
    const [newValueValid, setNewValueValid] = useState<boolean>(true);
    const [newDealTypeValid, setNewDealTypeValid] = useState<boolean>(true);
    const [newPipelineValid, setNewPipelineValid] = useState<boolean>(true);
    const [newStageValid, setNewStageValid] = useState<boolean>(true);
    const [newOwnedByValid, setNewOwnedByValid] = useState<boolean>(true);
    const [newDescriptionValid, setNewDescriptionValid] = useState<boolean>(true);
    const [newCustomProperties, setNewCustomProperties] = useState<{ [key: string]: string }>({});

    const [relatesToContactValid, setRelatesToContactValid] = useState<boolean>(true);
    const [relatesToOrganisationValid, setRelatesToOrganisationValid] = useState<boolean>(true);

    // State: Tags
    const [tags, setTags] = useState<{ value: number; label: any }[]>();

    // Dropdown Loading
    const [allDataLoaded, setAllDataLoaded] = useState<boolean>(false);
    const [usersLoaded, setUsersLoaded] = useState<boolean>(false);
    const [dealTypesLoaded, setDealTypesLoaded] = useState<boolean>(false);
    const [dealPipelinesLoaded, setDealPipelinesLoaded] = useState<boolean>(false);
    const [relatesToLoaded, setRelatesToLoaded] = useState<boolean>(true);

    const [meUserId, setMeUserId] = useState<number | null>(null);
    const [userDropdownOptions, setUserDropdownOptions] = useState<UserType[]>([]);
    const [dealTypesDropdownOptions, setDealTypesDropdownOptions] = useState<DealType[]>([]);
    const [dealPipelinesDropdownOptions, setDealPipelinesDropdownOptions] = useState<DealPipeline[]>([]);
    const [dealStagesDropdownOptions, setDealStagesDropdownOptions] = useState<OptionTypeBase[]>([]);
    const [dealLostReasonOptions, setDealLostReasonOptions] = useState<DealLostReason[]>([]);

    const [submitting, setSubmitting] = useState<boolean>(false);

    const [customPropertyValues, setCustomPropertyValues] = useState<any>();
    const customProperties: any = JSON.parse(
        String((document.getElementById('custom-properties') as HTMLInputElement).value),
    );

    useEffect(() => {
        setAllDataLoaded(usersLoaded && dealTypesLoaded && dealPipelinesLoaded && relatesToLoaded);
    }, [usersLoaded, dealTypesLoaded, dealPipelinesLoaded, relatesToLoaded]);

    useEffect(() => {
        if (shown) {
            getUsers();
            getDealTypes();
            getDealLostReasonOptions();
            getDealPipelines();
            if (focusRef.current) {
                focusRef.current.focus();
                focusRef.current.selectionStart = focusRef.current.value.length;
                focusRef.current.selectionEnd = focusRef.current.value.length;
            }
        }
    }, [shown]);

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<Deal>) => {
            if (event.target !== props.uniqueKey) {
                // Close sisp if another one has been opened
                setShown(false);
                return;
            }
            reset();
            const getDealData = 'id' in event.message && Object.keys(event.message).length === 1;
            const promise: Promise<Deal | null> = getDealData
                ? getDeal(event.message.id!)
                : Promise.resolve(event.message);
            promise.then((dealData) => {
                if (dealData) {
                    const deal = convertDealToEditState(dealData);
                    setRowId(deal.id);
                    setNewName(deal.name);
                    setNewValue(deal.value?.replace(currencyRegexp, '') || '');
                    setNewDealType(deal.deal_type_id);
                    setNewPipeline(deal.pipeline_id ?? null);
                    setNewStage({ value: deal.stage_id, label: deal.stage });
                    setNewLostReason(deal.lost_reason_id ?? null);
                    setNewOwnedBy(deal.owned_by_id ?? null);
                    setNewFollowUpDetails(deal.follow_up_date ?? null);
                    setNewCloseByDetails(deal.close_by_date ?? null);
                    setNewDescription(deal.description);
                    if (deal.relates_to_contact != undefined) {
                        setRelatesToOrganisation(null);
                        setShowOrganisation(false);
                        setShowContact(true);
                        updateRelatesTo('contacts');
                        getContactById(deal.relates_to_contact);
                    } else if (deal.relates_to_organisation != undefined) {
                        setRelatesToContact(null);
                        setShowOrganisation(true);
                        setShowContact(false);
                        updateRelatesTo('organisations');
                        getOrganisationById(deal.relates_to_organisation);
                    } else {
                        setRelatesToContact(null);
                        setRelatesToOrganisation(null);
                        setShowOrganisation(false);
                        setShowContact(false);
                        updateRelatesTo('none');
                    }
                    setCustomPropertyValues(deal.custom_properties);
                    if (customTagsEnabled) {
                        setTags(
                            dealData.tags?.map((t) => {
                                return {
                                    value: t.id ?? 0,
                                    label: <ClientTagPill tag={t} />,
                                };
                            }) ?? [],
                        );
                    }
                    setShown(true);
                }
            });
        });
    }, [shown]);

    const updateRelatesTo = (userType: string) => {
        setNewRelatesToDetails({ ...relatesToOptions, [userType]: true });
    };

    const validate = async (): Promise<boolean> => {
        const nameValid = newName != null && newName != '';
        setNewNameValid(nameValid);

        const valueValid = validPaymentRegexp.test(newValue);
        setNewValueValid(valueValid);

        const dealTypeValid = newDealType != null;
        setNewDealTypeValid(dealTypeValid);

        const pipelineValid = newPipeline != null;
        setNewPipelineValid(pipelineValid);

        const stageValid = newStage !== null;
        setNewStageValid(stageValid);

        const ownedByValid = newOwnedBy != null;
        setNewOwnedByValid(ownedByValid);

        const descriptionValid = newDescription != null;
        setNewDescriptionValid(descriptionValid);

        const relatesToContactValid = showContact ? relatesToContact != null : relatesToContact == null;
        setRelatesToContactValid(relatesToContactValid);

        const relatesToOrganisationValid = showOrganisation
            ? relatesToOrganisation != null
            : relatesToOrganisation == null;
        setRelatesToOrganisationValid(relatesToOrganisationValid);

        return (
            nameValid &&
            valueValid &&
            dealTypeValid &&
            pipelineValid &&
            stageValid &&
            ownedByValid &&
            descriptionValid &&
            relatesToContactValid &&
            relatesToOrganisationValid
        );
    };

    const reset = () => {
        setNewName('');
        setNewValue('');
        setNewDealType(null);
        setNewPipeline(null);
        setNewStage(null);
        setNewLostReason(null);
        setNewOwnedBy(null);
        setNewFollowUpDetails(null);
        setNewCloseByDetails(null);
        setNewRelatesToDetails(relatesToOptions);
        setNewDescription('');
        setDealStagesDropdownOptions([]);
        setNewCustomProperties({});

        setCustomPropertyValues(undefined);
        setShowContact(false);
        setShowOrganisation(false);
        setRelatesToContact(null);
        setRelatesToOrganisation(null);
        updateRelatesTo('none');
    };

    const getDeal = async (id: number): Promise<Deal | null> => {
        const filterRequest = SimpleFilterRequestBuilder.idFilter(id.toString());
        return dealsRepository
            .filter(filterRequest)
            .then((results: any) => {
                return results.results[0];
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get Deal - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                    dismissable: false,
                });
                return null;
            });
    };

    const getUsers = async () => {
        const query = new SearchQuery(1, 100);
        return dealUsersRepository
            .search(query)
            .then((results: any) => {
                if (results.wasCancelled) return results;
                setUserDropdownOptions(results.results);
                (results.results as UserType[]).forEach((user: UserType) => {
                    if (user.name == 'You') {
                        setMeUserId(user.id ?? null);
                    }
                });
                setUsersLoaded(true);
            })
            .catch((err: any) => {
                return null;
            });
    };

    const getDealTypes = async () => {
        const query = new SearchQuery(1, 100);
        return dealTypesRepository
            .search(query)
            .then((results: any) => {
                if (results.wasCancelled) return results;
                setDealTypesDropdownOptions(results.results);
                setDealTypesLoaded(true);
            })
            .catch((err: any) => {
                return null;
            });
    };

    const getDealPipelines = async () => {
        const query = new SearchQuery(1, 100);
        return dealPipelinesRepository
            .search(query)
            .then((results: any) => {
                if (results.wasCancelled) return results;
                setDealPipelinesDropdownOptions(results.results);
                getDealStagesDropdownOptions(newPipeline!, results.results);
                setDealPipelinesLoaded(true);
            })
            .catch((err: any) => {
                return null;
            });
    };

    const getDealLostReasonOptions = () => {
        const query = new SearchQuery(1, 100);
        return dealLostReasonsRepository
            .search(query)
            .then((results: any) => {
                if (results.wasCancelled) return results;
                setDealLostReasonOptions(results.results);
            })
            .catch((err: any) => {
                return null;
            });
    };

    const getContacts = async (filter: string, page?: number) => {
        // Build filterRequest
        const query: SearchQuery = new SearchQuery(page ?? 1, 5, 'co.firstname', SortOrder.ASC, filter);
        let res: OptionTypeBase[] = [];

        return contactsRepository
            .search(query)
            .then((result: any) => {
                res = _.map(result.results, (contact: Contact) => {
                    return {
                        value: contact.id,
                        label: `${contact.full_name} [${contact.organisation.name}]`,
                    };
                });
                res.push({
                    value: result.counts.currentPage < result.counts.totalPages,
                    label: 'has_more',
                });
                return res;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to get Contacts - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return res;
            });
    };

    const getContactById = async (id: number, page?: number) => {
        setRelatesToLoaded(false);
        // Build filterRequest
        const query: SearchQuery = new SearchQuery(page ?? 1, 5, 'co.id', SortOrder.ASC);
        query.setExtendedParameters({
            ids: JSON.stringify([id]),
        });
        let res: OptionTypeBase[] = [];

        return contactsRepository
            .search(query)
            .then((result: any) => {
                res = _.map(result.results, (contact: Contact) => {
                    return {
                        value: contact.id,
                        label: `${contact.full_name} [${contact.organisation.name}]`,
                    };
                });
                setRelatesToContact(res[0]);
                setRelatesToLoaded(true);
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to get Contacts - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return res;
            });
    };

    const getOrganisations = async (filter: string, page?: number) => {
        // Build filterRequest
        const query: SearchQuery = new SearchQuery(page ?? 1, 5, 'o.organisation_name', SortOrder.ASC, filter);
        let res: OptionTypeBase[] = [];

        return organisationsRepository
            .search(query)
            .then((result: any) => {
                res = _.map(result.results, (organisation: Organisation) => {
                    return {
                        value: organisation.id,
                        label: organisation.name,
                    };
                });
                res.push({
                    value: result.counts.currentPage < result.counts.totalPages,
                    label: 'has_more',
                });
                return res;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to get Organisations - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return res;
            });
    };

    const getOrganisationById = async (id: number, page?: number) => {
        setRelatesToLoaded(false);
        // Build filterRequest
        const query: SearchQuery = new SearchQuery(page ?? 1, 5, 'o.id', SortOrder.ASC);
        query.setExtendedParameters({
            ids: JSON.stringify([id]),
        });
        let res: OptionTypeBase[] = [];

        return organisationsRepository
            .search(query)
            .then((result: any) => {
                res = _.map(result.results, (organisation: Organisation) => {
                    return {
                        value: organisation.id,
                        label: organisation.name,
                    };
                });
                res.push({
                    value: result.counts.currentPage < result.counts.totalPages,
                    label: 'has_more',
                });
                setRelatesToOrganisation(res[0]);
                setRelatesToLoaded(true);
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to get ' + ucwords(dictionary['organisations']) + ' - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return res;
            });
    };

    const onGetUsers = async (filter: string) => {
        if (filter.length == 0) {
            return userDropdownOptions.map(userTypeDropdownMapLambda);
        } else {
            return userDropdownOptions
                .filter((user: UserType) => user.full_name?.toLowerCase().includes(filter.toLowerCase()))
                .map(userTypeDropdownMapLambda);
        }
    };

    const onGetDealTypes = async (filter: string) => {
        if (filter.length == 0) {
            return dealTypesDropdownOptions.map(dealTypesDropdownMapLambda);
        } else {
            return dealTypesDropdownOptions
                .filter((dealType: DealType) => dealType.type.toLowerCase().includes(filter.toLowerCase()))
                .map(dealTypesDropdownMapLambda);
        }
    };

    const onGetDealLostReasons = async (filter: string) => {
        if (filter.length == 0) {
            return dealLostReasonOptions.map(dealLostDropdownMapLambda);
        } else {
            return dealTypesDropdownOptions
                .filter((dealType: DealType) => dealType.type.toLowerCase().includes(filter.toLowerCase()))
                .map(dealTypesDropdownMapLambda);
        }
    };

    const onGetDealPipelines = async (filter: string) => {
        if (filter.length == 0) {
            return dealPipelinesDropdownOptions.map(dealPipelinesDropdownMapLambda);
        } else {
            return dealPipelinesDropdownOptions
                .filter((dealPipeline: DealPipeline) => dealPipeline.name.toLowerCase().includes(filter.toLowerCase()))
                .map(dealPipelinesDropdownMapLambda);
        }
    };

    const dealTypesDropdownMapLambda = (dealType: DealType) => {
        return {
            value: dealType.id,
            label: dealType.type,
        };
    };

    const dealLostDropdownMapLambda = (dealLostReason: DealLostReason) => {
        return {
            value: dealLostReason.id,
            label: dealLostReason.reason,
        };
    };

    const userTypeDropdownMapLambda = (element: UserType) => {
        return {
            value: element.id,
            label: (
                <span>
                    <img src={element.avatar} className="img-rounded" width="20" /> {element.name}
                </span>
            ),
        };
    };

    const dealPipelinesDropdownMapLambda = (pipeline: DealPipeline) => {
        return {
            value: pipeline.id,
            label: pipeline.name,
        };
    };

    const getDealStagesDropdownOptions = (pipelineId?: number, pipelines?: DealPipeline[]) => {
        let stages: OptionTypeBase[] = [];
        const dealPipelines: DealPipeline[] = pipelines ?? dealPipelinesDropdownOptions;
        if (pipelineId) {
            const pipeline: DealPipeline | undefined = _.find(dealPipelines, { id: pipelineId });
            if (pipeline) {
                stages = JSON.parse(pipeline.stages).map((stage: DealPipelineStage) => {
                    return {
                        value: stage.id,
                        label: stage.name,
                    };
                });
            }
        }
        if (stages.length) {
            if (!pipelines) {
                setNewStage(stages[0]);
            }
        } else {
            setNewStage(null);
        }
        setDealStagesDropdownOptions(stages);
    };

    const handleEditRow = async (): Promise<boolean> => {
        const Deal: DealEditState = {
            id: rowId!,
            name: newName,
            value: newValue,
            deal_type_id: newDealType!,
            pipeline_id: newPipeline!,
            stage_id: newStage!.value,
            stage: newStage!.label,
            lost_reason_id: newLostReason ?? undefined,
            owned_by_id: newOwnedBy!,
            follow_up_date: newFollowUpDetails ?? undefined,
            close_by_date: newCloseByDetails ?? undefined,
            relates_to_contact: relatesToContact ? (relatesToContact.value as number) : undefined,
            relates_to_organisation: relatesToOrganisation ? (relatesToOrganisation.value as number) : undefined,
            description: newDescription,
            tag_ids: tags?.map((t) => t.value),
        };
        setSubmitting(true);
        return dealsRepository
            .update({ ...Deal, ...newCustomProperties })
            .then((results: any) => {
                props.onSuccess(results.data);
                showBanner({
                    message: 'Deal details have been updated',
                    level: LogLevel.SUCCESS,
                });
                setSubmitting(false);
                return Promise.resolve(true);
            })
            .catch((err) => {
                showBanner(
                    {
                        message: 'Failed to create Deal - ' + (err?.message ?? err),
                        level: LogLevel.ERROR,
                    },
                    props.uniqueKey,
                );
                setSubmitting(false);
                return Promise.resolve(false);
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        if ((await validate()) && (await handleEditRow())) setShown(false);
    };

    const setCustomPropertyValue = (key: any, value: any) => {
        if (!(key in newCustomProperties && newCustomProperties[key] == value)) {
            setNewCustomProperties((prevState) => ({
                ...prevState,
                [key]: value,
            }));
        }
    };

    return (
        <Sisp
            isOpen={shown}
            onSubmit={handleEditRow}
            onCancel={() => {
                reset();
                setShown(false);
            }}
            validate={validate}
            footerOverride={
                <>
                    <Button
                        variant="default"
                        onClick={() => {
                            reset();
                            setShown(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <div
                        style={{
                            opacity: allDataLoaded ? 1 : 0.5,
                            pointerEvents: allDataLoaded ? 'auto' : 'none',
                        }}
                    >
                        <PendingButton pending={submitting} onClick={onSubmitForm}>
                            Save
                        </PendingButton>
                    </div>
                </>
            }
        >
            <h4>Edit a Deal</h4>
            {allDataLoaded && (
                <Form onSubmit={onSubmitForm}>
                    <Form.Group>
                        <Form.Label>
                            Deal Name <span className="required-field-marker">*</span>
                        </Form.Label>
                        <Form.Control
                            autoComplete="off"
                            ref={focusRef}
                            name="text"
                            isInvalid={!newNameValid}
                            value={newName}
                            onChange={(event) => {
                                setNewName(event.target.value);
                                setNewNameValid(true);
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            {!newNameValid && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Value (£)</Form.Label>
                        <Form.Control
                            name="text"
                            isInvalid={!newValueValid}
                            value={newValue}
                            onChange={(event) => {
                                let entered_value = event.target.value?.replace(currencyRegexp, '') || '';
                                if (entered_value.includes('.')) {
                                    const tokens = entered_value.split('.');
                                    if (tokens[1].length > 2) {
                                        entered_value = `${tokens[0]}.${tokens[1].substring(0, 2)}`;
                                    }
                                }
                                setNewValue(entered_value);
                                setNewValueValid(true);
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            {!newValueValid && 'Invalid value.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Relates To <span className="required-field-marker">*</span>
                        </Form.Label>
                        <div className="radio-button-group align-horizontal">
                            <Form.Check
                                id="type-none-edit"
                                name="type-none-edit"
                                type="radio"
                                label="None"
                                className="radio-button"
                                custom
                                checked={newRelatesToDetails.none}
                                onChange={() => {
                                    setRelatesToContact(null);
                                    setRelatesToOrganisation(null);
                                    setShowOrganisation(false);
                                    setShowContact(false);
                                    updateRelatesTo('none');
                                }}
                            />
                            <Form.Check
                                id="type-contact-edit"
                                name="type-contact-edit"
                                type="radio"
                                label="Contact"
                                className="radio-button"
                                custom
                                checked={newRelatesToDetails.contacts}
                                onChange={() => {
                                    setRelatesToOrganisation(null);
                                    setShowOrganisation(false);
                                    setShowContact(true);
                                    updateRelatesTo('contacts');
                                }}
                            />
                            <Form.Check
                                id="type-organisation-edit"
                                name="type-organisation-edit"
                                type="radio"
                                label={ucwords(dictionary['organisation'])}
                                className="radio-button"
                                custom
                                checked={newRelatesToDetails.organisations}
                                onChange={() => {
                                    setRelatesToContact(null);
                                    setShowOrganisation(true);
                                    setShowContact(false);
                                    updateRelatesTo('organisations');
                                }}
                            />
                        </div>
                    </Form.Group>
                    {showContact && (
                        <Form.Group>
                            <Form.Label>Contacts</Form.Label>
                            <AsyncDropDownPaginated
                                id={'contacts_dropdown'}
                                value={relatesToContact}
                                isInvalid={false}
                                menuPlacement="auto"
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                onChange={(selected: OptionTypeBase) => {
                                    setRelatesToContact(selected);
                                }}
                                loadOptions={async (filter: string, _loadedOptions, { page }) => {
                                    let res = await getContacts(filter, page);
                                    // Get has_more entry from results
                                    const hasMore = res.find((obj) => obj.label === 'has_more');
                                    // Remove has_more entry from main results
                                    res = _.filter(res, (obj) => obj.label !== 'has_more');
                                    return {
                                        options: res,
                                        hasMore: hasMore?.value as boolean,
                                        additional: {
                                            page: page + 1,
                                        },
                                    };
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {showContact && !relatesToContactValid && 'This field is required.'}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}
                    {showOrganisation && (
                        <Form.Group>
                            <Form.Label>{ucwords(dictionary['organisations'])}</Form.Label>
                            <AsyncDropDownPaginated
                                id={'organisations_dropdown'}
                                value={relatesToOrganisation}
                                isInvalid={false}
                                menuPlacement="auto"
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                onChange={(selected: OptionTypeBase) => {
                                    setRelatesToOrganisation(selected);
                                }}
                                loadOptions={async (filter: string, _loadedOptions, { page }) => {
                                    let res = await getOrganisations(filter, page);
                                    // Get has_more entry from results
                                    const hasMore = res.find((obj) => obj.label === 'has_more');
                                    // Remove has_more entry from main results
                                    res = _.filter(res, (obj) => obj.label !== 'has_more');
                                    return {
                                        options: res,
                                        hasMore: hasMore?.value as boolean,
                                        additional: {
                                            page: page + 1,
                                        },
                                    };
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {showOrganisation && !relatesToOrganisationValid && 'This field is required.'}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}
                    <Form.Group className="deals-date">
                        <Form.Label>Follow Up</Form.Label>
                        <DatePicker
                            className="deals-date-picker"
                            selected={newFollowUpDetails}
                            onChange={(date: Date) => setNewFollowUpDetails(date)}
                            isClearable={true}
                            dateFormat="dd/MM/yyyy"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="type-label-option">
                            Type <span className="required-field-marker">*</span>
                        </Form.Label>
                        <AsyncDropDown
                            value={
                                newDealType != null
                                    ? dealTypesDropdownOptions
                                          .map(dealTypesDropdownMapLambda)
                                          .find((option) => option.value == newDealType)
                                    : null
                            }
                            isInvalid={false}
                            onChange={(option: OptionTypeBase) => {
                                setNewDealType(option?.value ?? null);
                                setNewDealTypeValid(true);
                            }}
                            loadOptions={onGetDealTypes}
                            isClearable={false}
                            menuPortalTarget={document.body}
                        />
                        <Form.Control.Feedback type="invalid">
                            {!newDealTypeValid && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="type-label-option">
                            Pipeline <span className="required-field-marker">*</span>
                        </Form.Label>
                        <AsyncDropDown
                            value={
                                newPipeline != null
                                    ? dealPipelinesDropdownOptions
                                          .map(dealPipelinesDropdownMapLambda)
                                          .find((option) => option.value == newPipeline)
                                    : null
                            }
                            isInvalid={false}
                            onChange={(option: OptionTypeBase) => {
                                setNewPipeline(option?.value ?? null);
                                getDealStagesDropdownOptions(option?.value);
                                setNewPipelineValid(true);
                            }}
                            loadOptions={onGetDealPipelines}
                            isClearable={false}
                            menuPortalTarget={document.body}
                        />
                        <Form.Control.Feedback type="invalid">
                            {!newPipelineValid && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="type-label-option">
                            Stage <span className="required-field-marker">*</span>
                        </Form.Label>
                        <DropDown
                            value={newStage}
                            isInvalid={false}
                            onChange={(option: OptionTypeBase) => {
                                setNewStage(option);
                            }}
                            menuPosition="fixed"
                            options={dealStagesDropdownOptions}
                        />
                        <Form.Control.Feedback type="invalid">
                            {!newStageValid && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {newStage && newStage['label'] === CLOSED_LOST && (
                        <Form.Group>
                            <Form.Label className="type-label-option">Lost Reason</Form.Label>
                            <AsyncDropDown
                                value={
                                    newLostReason != null
                                        ? dealLostReasonOptions
                                              .map(dealLostDropdownMapLambda)
                                              .find((option) => option.value == newLostReason)
                                        : null
                                }
                                isInvalid={false}
                                onChange={(option: OptionTypeBase) => {
                                    setNewLostReason(option?.value ?? null);
                                }}
                                loadOptions={onGetDealLostReasons}
                                isClearable={false}
                                menuPortalTarget={document.body}
                            />
                        </Form.Group>
                    )}
                    <Form.Group>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <Form.Label style={{ justifyContent: 'flex-start', width: '100%' }}>
                                Owned by <span className="required-field-marker">*</span>
                            </Form.Label>
                            {meUserId && (
                                <button
                                    type="button"
                                    className="btn btn-link"
                                    style={{ justifyContent: 'flex-end', margin: '0px' }}
                                    onClick={() => {
                                        setNewOwnedBy(meUserId);
                                        setNewOwnedByValid(true);
                                    }}
                                >
                                    Assign to me
                                </button>
                            )}
                        </div>
                        <AsyncDropDown
                            value={
                                newOwnedBy != null
                                    ? userDropdownOptions
                                          .map(userTypeDropdownMapLambda)
                                          .find((option) => option.value == newOwnedBy)
                                    : null
                            }
                            isInvalid={false}
                            onChange={(option: OptionTypeBase) => {
                                setNewOwnedBy(option?.value ?? null);
                                setNewOwnedByValid(true);
                            }}
                            loadOptions={onGetUsers}
                            isClearable={false}
                            menuPortalTarget={document.body}
                        />
                        <Form.Control.Feedback type="invalid">
                            {!newOwnedByValid && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Closed Date</Form.Label>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <div style={{ justifyContent: 'flex-start', width: '100%' }}>
                                <DatePicker
                                    className="form-control"
                                    selected={newCloseByDetails}
                                    onChange={(date: Date) => {
                                        setNewCloseByDetails(date);
                                    }}
                                    isClearable={false}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                            {newCloseByDetails != null && (
                                <div style={{ position: 'absolute', right: '2rem', paddingTop: '1.9%' }}>
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        style={{ height: '17px', cursor: 'default' }}
                                        className="close date-picker-close"
                                        onClick={() => setNewCloseByDetails(null)}
                                    />
                                </div>
                            )}
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="type-label-option">Description</Form.Label>
                        <Form.Control
                            name="text"
                            isInvalid={!newDescriptionValid}
                            value={newDescription}
                            onChange={(event) => {
                                setNewDescription(event.target.value);
                            }}
                            as="textarea"
                            rows={5}
                        />
                    </Form.Group>
                    {customTagsEnabled && (
                        <TagsEditDropdown
                            onChange={(tags) => {
                                setTags(tags);
                            }}
                            existingTags={tags}
                        />
                    )}
                    {permissions.customPropertiesDeals.isEnabled && (
                        <CustomPropertyForm
                            propertyType={AvailablePropertyTypes.deals}
                            customProperties={customProperties}
                            customPropertyValues={customPropertyValues}
                            updateFormPropertyState={setCustomPropertyValues}
                            setPropertyValue={setCustomPropertyValue}
                        />
                    )}
                </Form>
            )}
            {!allDataLoaded && (
                <div style={{ position: 'relative', alignItems: 'center' }}>
                    <Card
                        className="loading-spinner-container filter-loading-spinner"
                        style={{ background: '#f9f9f9' }}
                    >
                        <Spinner animation="border" role="status" />
                    </Card>
                </div>
            )}
        </Sisp>
    );
};

export default DealsEditSisp;
