'use strict';

import React from 'react';

/**
 * interface OptionProps {
 *   value: string
 *   label: string
 *   onChange(key: string): void
 * }
 */
export default class Option extends React.Component {

    constructor(props) {
        super(props);
    }

    selectOption(e) {
        e.preventDefault();
        this.props.onChange(this.props.value);
    }

    render() {
        return <a href="#" onClick={this.selectOption.bind(this)}>
            {this.props.label}
        </a>;
    }

}