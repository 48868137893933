import _ from 'lodash';
import React, { FunctionComponent } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

import './multi-view-switcher.scss';

interface Props {
    availableViews?: string[];
    selectedView?: string;
    onSelect?: (view: string, e: React.MouseEvent<HTMLButtonElement>) => void;
}
const MultiViewSwitcher: FunctionComponent<Props> = (props: Props) => {
    return (
        <div className="react-multi-view-switcher">
            <ButtonGroup size="sm">
                {_.map(props.availableViews, (view: string, i: number) => {
                    return (
                        <Button
                            key={i}
                            variant="default"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                if (props.onSelect) props.onSelect(view, e);
                            }}
                            className={view === props.selectedView ? 'selected' : undefined}
                        >
                            {view}
                        </Button>
                    );
                })}
            </ButtonGroup>
        </div>
    );
};

MultiViewSwitcher.defaultProps = {
    availableViews: ['Table', 'Board'],
    selectedView: 'Table',
};

export default MultiViewSwitcher;
