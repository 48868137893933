import { AsyncDropDownPaginated, SearchQuery, SortOrder, showBanner } from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { ClientTagsRequest } from '../../Applications/DataGrids/Api/ClientTagsRequest';
import ClientTag from '../../Applications/DataGrids/Models/ClientTag';
import { RepositoryFactoryContext } from '../../Applications/DataGrids/index';
import { APP_COLOURS } from '../Colours/AppColours';
import HelpTooltip from '../Tooltips/HelpTooltip';
import ClientTagPill from './ClientTagPill';

interface Props {
    onChange: (tags: { value: number; label: any }[]) => void;
    existingTags?: { value: number; label: any }[];
    note?: string;
}

export const EDIT_CAMPAIGN_TAGS_EVENT_KEY = 'edit-campaign-tags';

const TagsEditDropdown: FunctionComponent<Props> = (props: Props) => {
    const tagsRepository = useContext(RepositoryFactoryContext).getApiRepository(new ClientTagsRequest());

    // State: General
    const [filter, setFilter] = useState<string>('');
    const [tags, setTags] = useState<{ value: number; label: any }[]>(props.existingTags ?? []);

    useEffect(() => {
        props.onChange(tags);
    }, [tags]);

    const loadTags = async (filter?: string, page?: number): Promise<{ value: number; label: JSX.Element }[]> => {
        // Fetch all users for client account
        const query = new SearchQuery(page ?? 1, 100, 'id', SortOrder.ASC, filter);
        return tagsRepository
            .search(query)
            .then((results) => {
                return _.map(
                    _.filter(results.results as ClientTag[], (tag: ClientTag) => {
                        return _.includes(tag?.name?.toLowerCase(), filter?.toLowerCase());
                    }),
                    (tag: ClientTag) => {
                        return {
                            value: tag.id ?? 0,
                            label: <ClientTagPill tag={tag} />,
                        };
                    },
                );
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get users - ' + (err?.message ?? err),
                });
                return [];
            });
    };

    const handleTagQuickAdd = async (name: string): Promise<boolean> => {
        const newTag = {
            name: name,
            hex_colour: APP_COLOURS.BLACK,
        } as ClientTag;
        return tagsRepository
            .create(newTag)
            .then((result: any) => {
                setFilter('');
                setTags((prevState) => {
                    return [
                        ...prevState,
                        {
                            value: result.data.id ?? 0,
                            label: <ClientTagPill tag={result.data} />,
                        },
                    ];
                });
                return true;
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get users - ' + (err?.message ?? err),
                });
                return false;
            });
    };

    return (
        <Form.Group>
            <Form.Label>Tags</Form.Label>
            <HelpTooltip
                title={'What are tags?'}
                helptext={
                    'Tags allow you to assign custom, filterable keywords to your data across Campus. You can create and edit existing tags <a href="/settings/tags">here</a>.'
                }
            />
            <AsyncDropDownPaginated
                id="tags"
                value={tags}
                isMulti={true}
                loadOptions={async (fieldFilter: string, _loadedOptions, { page }) => {
                    const res = await loadTags(fieldFilter, page);
                    return {
                        options: res,
                        hasMore: false,
                        additional: {
                            page: page + 1,
                        },
                    };
                }}
                // Anchor options dropdown to body
                menuPortalTarget={document.getElementsByTagName('body')[0]}
                onFilter={(fieldFilter) => setFilter(fieldFilter)}
                isInvalid={false}
                onChange={(values) => {
                    setTags(
                        values.map((v) => {
                            return { value: v.value, label: v.label };
                        }),
                    );
                    setFilter('');
                }}
                customiseMenu={
                    filter ? (
                        <Button
                            onClick={() => handleTagQuickAdd(filter)}
                            style={{
                                width: 'calc(100% - 10px)',
                                margin: '5px',
                                textAlign: 'left',
                            }}
                            variant="secondary"
                        >
                            Add "{filter}"
                        </Button>
                    ) : (
                        <></>
                    )
                }
            />
            {props.note && <small>{props.note}</small>}
            <a style={{ float: 'right', margin: '5px' }} href="/settings/tags" target="_blank">
                Edit existing tags
            </a>
        </Form.Group>
    );
};

export default TagsEditDropdown;
