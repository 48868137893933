import { ExtendedColumn, StyledPill, UserFormatter } from '@sprint/sprint-react-components';
import CSS from 'csstype';
import React, { FunctionComponent, useContext } from 'react';
import { ReplyToAddressRequest } from '../../Api/ReplyToAddressRequest';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import { UniqueKeyType } from '../../Models/Enums';
import ReplyToAddress from '../../Models/ReplyToAddress';
import { RepositoryFactoryContext } from '../../index';
import CampusDataGrid from '../CampusDataGrid';
import ReplyToAddressAddSisp from './ReplyToAddressAddSisp';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

enum ReplyToAddressColumnKey {
    ID,
    EMAIL_ADDRESS,
    VERIFIED_BY,
    VERIFIED_AT,
}

const ReplyToAddressTable: FunctionComponent<Props> = (props: Props) => {
    const addSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP);

    const replyToAddressRepository = useContext(RepositoryFactoryContext).getApiRepository(new ReplyToAddressRequest());

    // Formatter
    const VerifiedBaseFormatterWrapper = (props: React.PropsWithChildren<any>, element: JSX.Element) => {
        const verified_at = (props.row as ReplyToAddress).verified_at;
        if (verified_at == null) {
            const pillStyle: CSS.Properties = {
                backgroundColor: '#808080',
            };
            return <StyledPill cellContent={'Pending Verification'} style={pillStyle} />;
        } else {
            return element;
        }
    };

    const VerifiedByColumnFormatter = (props: React.PropsWithChildren<any>) => {
        return VerifiedBaseFormatterWrapper(
            props,
            <UserFormatter
                column={props.column}
                row={props.row}
                onRowChange={props.onRowChange}
                isCellEditable={props.isCellEditable}
                tabIndex={0}
                rowIdx={props.rowIdx}
            />,
        );
    };

    const VerifiedAtColumnFormatter = (props: React.PropsWithChildren<any>) => {
        const verified_at = (props.row as ReplyToAddress).verified_at;
        return VerifiedBaseFormatterWrapper(props, <span title={verified_at}>{verified_at}</span>);
    };

    const Columns: Record<ReplyToAddressColumnKey, ExtendedColumn> = {
        [ReplyToAddressColumnKey.ID]: {
            key: 'id',
            name: 'ID',
        },
        [ReplyToAddressColumnKey.EMAIL_ADDRESS]: {
            key: 'email_address',
            name: 'Email',
            sortable: true,
            width: '1fr',
        },
        [ReplyToAddressColumnKey.VERIFIED_BY]: {
            key: 'verified_by',
            name: 'Verified By',
            sortable: true,
            renderCell: VerifiedByColumnFormatter,
            width: '1fr',
        },
        [ReplyToAddressColumnKey.VERIFIED_AT]: {
            key: 'verified_at',
            name: 'Verified',
            sortable: true,
            renderCell: VerifiedAtColumnFormatter,
            width: '1fr',
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[ReplyToAddressColumnKey.EMAIL_ADDRESS],
        Columns[ReplyToAddressColumnKey.VERIFIED_AT],
        Columns[ReplyToAddressColumnKey.VERIFIED_BY],
    ];

    return (
        <>
            <CampusDataGrid
                repository={replyToAddressRepository}
                dataGridMeta={{
                    uniqueKey: props.dataGridUniqueKey,
                    entitySingular: props.dataGridEntitySingular,
                    entityPlural: props.dataGridEntityPlural,
                    defaultColumns: DefaultColumns,
                    columnOptions: Columns,
                }}
                addSispMeta={{ key: addSispUniqueKey, sisp: ReplyToAddressAddSisp }}
                actionBarMeta={{
                    searchPlaceHolder: props.searchFilterPlaceholder,
                    includeCounts: true,
                }}
                promptMeta={{
                    icon: '/assets/application/img/prompts/no_verified_emails.png',
                    entitySingular: 'Email Address',
                    entityPlural: 'Email Addresses',
                    iconHeight: 80,
                    helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.VERIFY_EMAIL),
                }}
            />
        </>
    );
};

export default ReplyToAddressTable;
