import { DataGrid, ExtendedColumn, SearchQuery } from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';

interface Props {
    dataGridUniqueKey: string;
    dataGridData: any;
}

enum SalesPaymentsColumnKey {
    ID,
    DATE,
    AMOUNT,
    METHOD,
}

const SalesPaymentsTable: FunctionComponent<Props> = (props: Props) => {
    // State: Columns
    const [columns, setColumns] = useState([] as ExtendedColumn[]);
    const Columns: Record<SalesPaymentsColumnKey, ExtendedColumn> = {
        [SalesPaymentsColumnKey.ID]: {
            key: 'id',
            name: 'ID',
            sortable: false,
        },
        [SalesPaymentsColumnKey.DATE]: {
            key: 'date',
            name: 'Date',
            sortable: false,
        },
        [SalesPaymentsColumnKey.AMOUNT]: {
            key: 'amount',
            name: 'Amount',
            sortable: false,
        },
        [SalesPaymentsColumnKey.METHOD]: {
            key: 'method',
            name: 'Method',
            sortable: false,
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[SalesPaymentsColumnKey.DATE],
        Columns[SalesPaymentsColumnKey.AMOUNT],
        Columns[SalesPaymentsColumnKey.METHOD],
    ];

    const [defaultColumns, setDefaultColumns] = useState<ExtendedColumn[]>(DefaultColumns);

    useEffect(() => {
        setColumns([...defaultColumns]);
    }, [defaultColumns]);

    const onGetRows = async (query: SearchQuery) => {
        return props.dataGridData;
    };

    return (
        <>
            <DataGrid
                uniqueKey={props.dataGridUniqueKey}
                entitySingular={''}
                entityPlural={''}
                basicFilter={''}
                columns={columns}
                onGetRows={onGetRows}
                onUpdateRow={() => Promise.resolve()}
                addModal={null}
                showAddModal={false}
                onCloseAddModal={_.noop}
                hideFooter={true}
            />
        </>
    );
};

export default SalesPaymentsTable;
