'use strict';


import User from "../User/User";

export default class PaymentType {

    id = undefined;
    paymentType = '';
    isDefault = false;
    created = '';
    createdBy = undefined;
    modified = '';
    modifiedBy = undefined;

    static blank() {
        return new PaymentType();
    }

    static fromApi(item) {
        const entity = PaymentType.blank();

        // Construct from expected API output object
        // Note: `is_logged_in` will not be set for the below Users, access via Client object
        entity.id = item.id;
        entity.paymentType = item.payment_type;
        entity.isDefault = item.is_default;
        entity.created = item.created;
        entity.createdBy = item.created_by ? User.fromApi(item.created_by) : undefined;
        entity.modified = item.modified;
        entity.modifiedBy = item.modified_by ? User.fromApi(item.modified_by) : undefined;

        return entity;
    }

    static select(entityArray, id) {
        return entityArray.find(entity => entity.matches(id));
    }

    matches(id) {
        return String(this.id) === String(id);
    }

    clone() {
        return Object.assign(PaymentType.blank(), this);
    }

}
