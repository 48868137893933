import { Checkbox, isValidString, LogLevel, PendingButton, showBanner, Sisp } from '@sprint/sprint-react-components';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { DealTypeRequest } from '../../Api/DealTypeRequest';
import { RepositoryFactoryContext } from '../../index';
import DealType from '../../Models/DealType';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
    uniqueKey: string;
}

const DealTypesAddSisp: FunctionComponent<Props> = (props: Props) => {
    const dealTypesRepository = useContext(RepositoryFactoryContext).getApiRepository(new DealTypeRequest());

    const focusRef = useRef<HTMLInputElement>(null);

    const [newType, setNewType] = useState<string>('');
    const [newTypeValid, setNewTypeValid] = useState<boolean>(true);

    const [newDefault, setNewDefault] = useState<boolean>(false);

    const [isSaving, setIsSaving] = useState<boolean>(false);

    useEffect(() => {
        if (props.shown) {
            focusRef.current?.focus();
        }
    }, [props.shown]);

    const validate = async (): Promise<boolean> => {
        const typeValid = !!newType && isValidString(newType);
        setNewTypeValid(typeValid);
        return typeValid;
    };

    const reset = () => {
        setNewType('');
        setNewDefault(false);
        setNewTypeValid(true);
    };

    const handleAddRow = async (): Promise<boolean> => {
        const DealType: DealType = {
            type: newType,
            is_default: newDefault,
            // Doesnt change can only be false
            built_in: false,
        };

        return dealTypesRepository
            .create(DealType)
            .then(props.onSuccess)
            .then(async (success) => {
                reset();
                return success;
            })
            .catch((err) => {
                showBanner(
                    {
                        message: 'Failed to create Deal Type - ' + (err?.message ?? err),
                        level: LogLevel.ERROR,
                    },
                    props.uniqueKey,
                );
                return false;
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        setIsSaving(true);
        e.preventDefault();
        if ((await validate()) && (await handleAddRow())) props.onClose();
        setIsSaving(false);
    };

    const onSubmitAndAddAnother = async (e: FormEvent) => {
        setIsSaving(true);
        e.preventDefault();
        if ((await validate()) && (await handleAddRow())) {
            reset();
            focusRef.current?.focus();
        }
        setIsSaving(false);
    };

    return (
        <Sisp
            isOpen={props.shown}
            onSubmit={handleAddRow}
            onCancel={() => {
                reset();
                props.onClose();
            }}
            validate={validate}
            footerOverride={
                <>
                    <Button
                        variant="default"
                        onClick={() => {
                            reset();
                            props.onClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <PendingButton variant="default" onClick={onSubmitAndAddAnother} pending={isSaving}>
                        Save & Add Another
                    </PendingButton>
                    <PendingButton variant="primary" onClick={onSubmitForm} pending={isSaving}>
                        Save
                    </PendingButton>
                </>
            }
        >
            <h4>Add a Deal Type</h4>
            <Form onSubmit={onSubmitForm}>
                <Form.Group>
                    <Form.Label>
                        Deal Type <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        ref={focusRef}
                        type="text"
                        isInvalid={!newTypeValid}
                        value={newType || ''}
                        onChange={(event) => {
                            setNewType(event.target.value);
                            setNewTypeValid(true);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {!newTypeValid && 'This field is required.'}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Checkbox
                        label="Make this the default deal type"
                        isChecked={newDefault}
                        onChange={(event) => setNewDefault(event.target.checked)}
                    />
                </Form.Group>
            </Form>
        </Sisp>
    );
};

export default DealTypesAddSisp;
