import { SearchQuery } from '@sprint/sprint-react-components';
import axios, { AxiosInstance, AxiosRequestConfig, CancelTokenSource } from 'axios';
import _ from 'lodash';

export default class HttpRequestService {
    private _api: AxiosInstance;
    private _cancelTokens: Record<string, CancelTokenSource> = {};

    constructor(private _authToken: string) {
        this._api = axios.create({
            validateStatus: () => true,
        });
        this._api.defaults.headers.common['Authorization'] = `Bearer ${this._authToken}`;
    }

    public get(url: string, cancelTokenKey?: string) {
        if (cancelTokenKey) {
            if (this._cancelTokens[cancelTokenKey]) this._cancelTokens[cancelTokenKey].cancel();
            this._cancelTokens[cancelTokenKey] = axios.CancelToken.source();
        }

        const cancelToken = cancelTokenKey ? this._cancelTokens[cancelTokenKey].token : undefined;
        return this._api.get(url, { cancelToken });
    }

    public post(url: string, data?: any, cancelTokenKey?: string, extraOptions?: AxiosRequestConfig) {
        if (cancelTokenKey) {
            if (this._cancelTokens[cancelTokenKey]) this._cancelTokens[cancelTokenKey].cancel();
            this._cancelTokens[cancelTokenKey] = axios.CancelToken.source();
        }

        const cancelToken = cancelTokenKey ? this._cancelTokens[cancelTokenKey].token : undefined;
        return this._api.post(url, data, {
            cancelToken,
            ...extraOptions,
        });
    }

    public patch(url: string, data: any, extraOptions?: AxiosRequestConfig) {
        return this._api.patch(
            url,
            _.mapValues(data, (value) => {
                return value === undefined ? null : value;
            }),
            extraOptions,
        );
    }

    public deletePage(url: string, query: SearchQuery) {
        return this._api.delete(url + '?' + query.toQueryString());
    }

    public delete(url: string, ids?: number[]) {
        if (ids && ids.length > 0) {
            url += '?' + ids.map((id) => `ids=${id}`).join('&');
        }

        return this._api.delete(url);
    }

    public deleteMany(url: string, data: any) {
        return this._api.delete(url, { data: data });
    }
}
