import {
    DefaultPillFormatter,
    ExtendedColumn,
    HoveroverButton,
    NonSelectableRow,
} from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext } from 'react';
import { DealPipelineRequest } from '../../Api/DealPipelineRequest';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import { DealPipeline } from '../../Models/DealPipeline';
import DealPipelineEditState from '../../Models/DealPipelineEditState';
import { UniqueKeyType } from '../../Models/Enums';
import { RepositoryFactoryContext } from '../../index';
import CampusDataGrid, { ActionBarMeta, AddSispMeta, DataGridMeta, PromptMeta } from '../CampusDataGrid';
import DealPipelinesAddSisp from './DealPipelinesAddSisp';
import DealPipelinesEditSisp from './DealPipelinesEditSisp';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

enum DealPipelinesColumnKey {
    ID,
    LAST_MODIFIED,
    NAME,
}

const DealPipelinesTable: FunctionComponent<Props> = (props: Props) => {
    const editSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);

    const dealPipelinesRepository = useContext(RepositoryFactoryContext).getApiRepository(new DealPipelineRequest());

    const Columns: Record<DealPipelinesColumnKey, ExtendedColumn> = {
        [DealPipelinesColumnKey.ID]: {
            key: 'id',
            name: 'ID',
            sortable: false,
        },
        [DealPipelinesColumnKey.LAST_MODIFIED]: {
            key: 'modified',
            name: 'Last Modified',
            sortable: true,
        },
        [DealPipelinesColumnKey.NAME]: {
            key: 'name',
            name: 'Name',
            sortable: true,
            resizable: false,
            renderCell: (fprops) => {
                const name = (fprops.row as DealPipeline).name;
                const stages = (fprops.row as DealPipeline).stages;
                const default_deal_length_days = (fprops.row as DealPipeline).default_deal_length_days;
                const is_default = (fprops.row as DealPipeline).is_default;
                const built_in = (fprops.row as DealPipeline).built_in;
                const id = (fprops.row as DealPipeline).id!;
                const eventBusMessage: DealPipelineEditState = {
                    id: id,
                    name: name,
                    stages: stages,
                    default_deal_length_days: default_deal_length_days,
                    is_default: is_default,
                };
                return (
                    <>
                        <DefaultPillFormatter cellContent={name} showPill={is_default}>
                            <HoveroverButton
                                contents="Edit"
                                showHoverover={!built_in}
                                eventBusMessageTarget={editSispUniqueKey}
                                eventBusMessage={eventBusMessage}
                            />
                        </DefaultPillFormatter>
                    </>
                );
            },
        },
    };

    const DefaultColumns: ExtendedColumn[] = [Columns[DealPipelinesColumnKey.NAME]];

    const findNonSelectableRows = (rows: any): NonSelectableRow[] | null => {
        return rows
            .filter((row: any) => (row as DealPipeline).built_in)
            .map((row: any) => ({ id: row.id, reason: 'Built-in Type' }));
    };

    const dataGridMeta: DataGridMeta = {
        uniqueKey: props.dataGridUniqueKey,
        entitySingular: props.dataGridEntitySingular,
        entityPlural: props.dataGridEntityPlural,
        columnOptions: Columns,
        defaultColumns: DefaultColumns,
        frozenColumns: [],
        defaultSortColumn: 'modified',
        nonSelectableRows: findNonSelectableRows,
    };

    const actionBarMeta: ActionBarMeta = {
        searchPlaceHolder: props.searchFilterPlaceholder,
        includeCounts: true,
    };

    const addSispMeta: AddSispMeta = {
        key: UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP),
        sisp: DealPipelinesAddSisp,
    };

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/deal_pipelines.svg',
        iconHeight: 90,
    };

    return (
        <>
            <CampusDataGrid
                repository={dealPipelinesRepository}
                actionBarMeta={actionBarMeta}
                addSispMeta={addSispMeta}
                editSispMeta={{ sisp: DealPipelinesEditSisp }}
                dataGridMeta={dataGridMeta}
                promptMeta={promptMeta}
            />
        </>
    );
};

export default DealPipelinesTable;
