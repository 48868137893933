import { ClickableLink, EventBusInstance, ExtendedColumn } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { ucwords } from '../../../../Helpers/StringHelper';
import { FormSubmissionsRequest } from '../../Api/FormSubmissionsRequest';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import { UniqueKeyType } from '../../Models/Enums';
import FormSubmissionsType from '../../Models/FormSubmissionsType';
import { DictionaryContext, RepositoryFactoryContext } from '../../index';
import CampusDataGrid, { ActionBarMeta, DataGridMeta, PreviewSispMeta, PromptMeta } from '../CampusDataGrid';
import FormSubmissionsPreviewSisp from './FormSubmissionsPreviewSisp';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

enum FormSubmissionsColumnKey {
    ID,
    FORM,
    CONTACT_MATCHES,
    ORGANISATION_MATCHES,
    DATE,
}

const FormSubmissionsTable: FunctionComponent<Props> = (props: Props) => {
    const previewFormSubmissionsUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.PREVIEW_SISP);

    const formSubmissionsRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new FormSubmissionsRequest(),
    );
    const [reload, setReload] = useState<boolean>(true);

    const dictionary = useContext(DictionaryContext);

    const Columns: Record<FormSubmissionsColumnKey, ExtendedColumn> = {
        [FormSubmissionsColumnKey.ID]: {
            key: 'id',
            name: 'ID',
            sortable: false,
        },
        [FormSubmissionsColumnKey.FORM]: {
            key: 'form',
            name: 'Form',
            sortable: true,
            width: '1fr',
            renderCell: (props) => {
                const id = (props.row as FormSubmissionsType).id;
                const form = (props.row as FormSubmissionsType).form;

                return (
                    <>
                        <a
                            href=""
                            className="rdg-link"
                            onClick={(event) => {
                                event.preventDefault();
                                EventBusInstance.publish({
                                    topic: 'show-hoverover-component',
                                    message: props.row as FormSubmissionsType,
                                    target: previewFormSubmissionsUniqueKey,
                                });
                            }}
                        >
                            {form}
                        </a>
                    </>
                );
            },
        },
        [FormSubmissionsColumnKey.CONTACT_MATCHES]: {
            key: 'contact_matches',
            name: 'Contact Matches',
            sortable: true,
            width: '1fr',
            renderCell: (props) => {
                const contact_matches = (props.row as FormSubmissionsType).contact_matches;
                return <ClickableLink placeholder={contact_matches.name} url={contact_matches.url} />;
            },
        },
        [FormSubmissionsColumnKey.ORGANISATION_MATCHES]: {
            key: 'organisation_matches',
            name: ucwords(dictionary['organisation']) + ' Matches',
            sortable: true,
            width: '1fr',
            renderCell: (props) => {
                const organisation_matches = (props.row as FormSubmissionsType).organisation_matches;
                return <ClickableLink placeholder={organisation_matches.name} url={organisation_matches.url} />;
            },
        },
        [FormSubmissionsColumnKey.DATE]: {
            key: 'date',
            name: 'Date',
            sortable: true,
            width: '1fr',
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[FormSubmissionsColumnKey.FORM],
        Columns[FormSubmissionsColumnKey.CONTACT_MATCHES],
        Columns[FormSubmissionsColumnKey.ORGANISATION_MATCHES],
        Columns[FormSubmissionsColumnKey.DATE],
    ];

    useEffect(() => {
        setReload(false);
    }, [reload]);

    const dataGridMeta: DataGridMeta = {
        uniqueKey: props.dataGridUniqueKey,
        entitySingular: props.dataGridEntitySingular,
        entityPlural: props.dataGridEntityPlural,
        columnOptions: Columns,
        defaultColumns: DefaultColumns,
        frozenColumns: [],
        forceReload: reload,
        defaultSortColumn: 'date',
    };

    const actionBarMeta: ActionBarMeta = {
        searchPlaceHolder: props.searchFilterPlaceholder,
        includeCounts: true,
        hideAddButton: true,
    };

    const previewSispMeta: PreviewSispMeta = {
        key: UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.PREVIEW_SISP),
        sisp: FormSubmissionsPreviewSisp,
    };

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_form_submissions.png',
        iconHeight: 68,
        additionalSubtext: [
            'Submissions come through the Campus forms on your website.',
            'Nobody has filled one in yet.',
        ],
    };

    return (
        <>
            <CampusDataGrid
                repository={formSubmissionsRepository}
                actionBarMeta={actionBarMeta}
                dataGridMeta={dataGridMeta}
                previewSispMeta={previewSispMeta}
                promptMeta={promptMeta}
            />
        </>
    );
};

export default FormSubmissionsTable;
