import * as ActionTypes from '../Actions/ActionTypes';
import * as DealsActionType from '../../../Entities/Deal/ActionTypes';

const initialState = {
    fieldBlurList: {},
    submitted: false,
    submitAttempted: false,
    addAnother: false,
    updateAlertNotifications: false,
    updatePromptPage: false,
    updateViewPage: false,
    updateDataTable: false,
    dataTableRowCount: -1,
    sispOpen: false,
    returnOrigin: ''
};

export default function (state, action) {
    if (state === undefined) {
        state = initialState;
    }

    const newFieldBlurList = Object.assign({}, state.fieldBlurList);

    switch (action.type) {
        case ActionTypes.FIELD_BLURRED:
            newFieldBlurList[action.payload.fieldName] = true;

            return Object.assign({}, state, {
                fieldBlurList: newFieldBlurList
            });
        case ActionTypes.FORM_SUBMIT_ATTEMPTED:
            return Object.assign({}, state, {
                submitAttempted: true,
                addAnother: action.payload.addAnother
            });
        case ActionTypes.FORM_RESET:
            return Object.assign({}, state, {
                fieldBlurList: {},
                submitted: false,
                submitAttempted: false,
                addAnother: false
            });
        case ActionTypes.ALERT_NOTIFICATIONS_UPDATED:
            return Object.assign({}, state, {
                updateAlertNotifications: false
            });
        case ActionTypes.PROMPT_PAGE_UPDATED:
            return Object.assign({}, state, {
                updatePromptPage: false
            });
        case ActionTypes.VIEW_PAGE_UPDATED:
            return Object.assign({}, state, {
                updateViewPage: false
            });
        case ActionTypes.DATA_TABLE_UPDATING:
            return Object.assign({}, state, {
                updateDataTable: false
            });
        case ActionTypes.DATA_TABLE_UPDATED:
            return Object.assign({}, state, {
                updateDataTable: false,
                dataTableRowCount: action.payload.dataTableRowCount >= 0 ? action.payload.dataTableRowCount : state.dataTableRowCount
            });
        case ActionTypes.OPEN_FORM_SISP:
            return Object.assign({}, state, {
                sispOpen: true,
                returnOrigin: action.payload.returnOrigin
            });
        case ActionTypes.CLOSE_FORM_SISP:
        case ActionTypes.OPEN_PREVIEW_SISP:
        case ActionTypes.OPEN_INTERACTION_SISP:
            return Object.assign({}, state, {
                sispOpen: false
            });
        case DealsActionType.DEAL_SAVED:
            return Object.assign({}, state, {
                sispOpen: (state.sispOpen && state.addAnother),
                submitted: true,
                updateAlertNotifications: true,
                updatePromptPage: true,
                updateViewPage: true,
                updateDataTable: true
            });
        case DealsActionType.DEALS_DELETED:
        case DealsActionType.DEALS_UPDATED_PIPELINE:
            return Object.assign({}, state, {
                updateViewPage: true,
                updateDataTable: true
            });
        default:
            return state;
    }
}