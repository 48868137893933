import React from 'react';
import ImportReviewTable from './Grids/Import/ImportReviewTable';

export class DataGridFactory {
    public static import_review(importId: number): JSX.Element {
        return (
            <ImportReviewTable
                searchFilterPlaceholder="Search Item"
                dataGridUniqueKey="ImportReview"
                dataGridEntitySingular="Import"
                dataGridEntityPlural="Imports"
                importId={importId}
            />
        );
    }
}
