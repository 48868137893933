import { UnsubscribedType } from '../Models/Enums';
import Unsubscribe from '../Models/Unsubscribe';
import DataGridRequest from './DataGridRequest';

export default class UnsubscribedRequest extends DataGridRequest<Unsubscribe> {
    constructor(type: string) {
        super();
        switch (type) {
            case UnsubscribedType.TEACHERS:
                this.Url = '/settings/education_unsubscribes/api/teachers';
                break;
            case UnsubscribedType.SCHOOLS:
                this.Url = '/settings/education_unsubscribes/api/schools';
                break;
            case UnsubscribedType.CONTACTS:
                this.Url = '/settings/contacts_unsubscribes/api';
                break;
            default:
                throw new Error('unknown type {type}');
        }
    }
    Url: string;
}
