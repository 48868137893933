'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import MultiSelect from "../../../CommonComponents/Select/MultiSelect";

class AddFieldControl extends React.Component {

    updateSelectedOptions(components) {
        this.props.updateSelectedComponents(components);
    }

    toggle() {
        this.props.toggle();
    }

    render() {
        return <div className="react-adv--add-field-control">
            <MultiSelect
                placeholder="Add a Filter"
                open={this.props.open}
                toggle={this.toggle.bind(this)}
                selectedOptions={this.props.components}
                availableOptions={this.props.availableComponents}
                updateSelectedOptions={this.updateSelectedOptions.bind(this)}
            />
        </div>;
    }

}

AddFieldControl.propTypes = {
    open: PropTypes.bool,
    components: PropTypes.array,
    toggle: PropTypes.func,
    updateSelectedComponents: PropTypes.func
};

export default AddFieldControl;