'use strict';

import CampusEntity from "../../CampusEntity/CampusEntity";
import User from "../../User/User";

export default class DealType extends CampusEntity {

    id = undefined;
    type = '';
    builtIn = false;
    isDefault = false;
    created = '';
    createdBy = undefined;
    modified = '';
    modifiedBy = undefined;

    static blank() {
        return new DealType();
    }

    static fromApi(type) {
        const entity = DealType.blank();

        // Construct from expected API output object
        // Note: `is_logged_in` will not be set for the below Users, access via Client object
        entity.id = type.id;
        entity.type = type.type;
        entity.builtIn = type.built_in;
        entity.isDefault = type.is_default;
        entity.created = type.created;
        entity.createdBy = type.created_by ? User.fromApi(type.created_by) : undefined;
        entity.modified = type.modified;
        entity.modifiedBy = type.modified_by ? User.fromApi(type.modified_by) : undefined;

        return entity;
    }

    static select(entityArray, id) {
        return entityArray.find(entity => entity.matches(id));
    }

    matches(id) {
        return String(this.id) === String(id);
    }

    clone() {
        return Object.assign(DealType.blank(), this);
    }

    forSelect() {
        return {
            id: this.id,
            label: this.type
        }
    }

    forApi() {
        const formData = new FormData();

        // Note: FormData will transmit a string of 'undefined' if the field is empty, so check before appending.
        if (this.id) formData.append('id', this.id);
        if (this.type) formData.append('type', this.type);
        if (this.isDefault) formData.append('is_default', this.isDefault ? '1' : '0');

        return formData;
    }

}
