import React, { FunctionComponent } from 'react';
import SimpleSearchResultCounts from './SimpleSearchResultCounts';

interface Props {
    singularText: string;
    pluralText: string;
    counts?: SimpleSearchResultCounts;
}

const SimpleCountsBar: FunctionComponent<Props> = (props: Props) => {
    return (
        <div className="counts">
            <div className="count-row">
                <div className="count">
                    {props.counts?.totalItems?.toLocaleString() || 0}{' '}
                    {!props.counts?.totalItems || props.counts?.totalItems > 1 ? props.pluralText : props.singularText}
                </div>
            </div>
        </div>
    );
};

export default SimpleCountsBar;
