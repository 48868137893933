import { ExtendedColumn, HoveroverButton } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext } from 'react';
import { UrlsRequest } from '../../Api/UrlsRequest';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import { UniqueKeyType } from '../../Models/Enums';
import Url from '../../Models/Url';
import UrlEditState from '../../Models/UrlEditState';
import { RepositoryFactoryContext } from '../../index';
import CampusDataGrid, { PromptMeta } from '../CampusDataGrid';
import UrlsAddSisp from './UrlsAddSisp';
import UrlsEditSisp from './UrlsEditSisp';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

enum UrlsColumnKey {
    ID,
    NAME,
    URL,
}

const UrlsTable: FunctionComponent<Props> = (props: Props) => {
    const addSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP);
    const editSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);

    const urlsRepository = useContext(RepositoryFactoryContext).getApiRepository(new UrlsRequest());

    const Columns: Record<UrlsColumnKey, ExtendedColumn> = {
        [UrlsColumnKey.ID]: {
            key: 'id',
            name: 'ID',
        },
        [UrlsColumnKey.NAME]: {
            key: 'name',
            name: 'Name',
            sortable: true,
            width: '1fr',
            renderCell: (fprops) => {
                const name = (fprops.row as Url).name;
                const url = (fprops.row as Url).url;
                const id = (fprops.row as Url).id!;
                const eventBusMessage: UrlEditState = { id: id, name: name, url: url };
                return (
                    <>
                        {name}
                        <HoveroverButton
                            contents="Edit"
                            eventBusMessageTarget={editSispUniqueKey}
                            eventBusMessage={eventBusMessage}
                        />
                    </>
                );
            },
        },
        [UrlsColumnKey.URL]: {
            key: 'url',
            name: 'URL',
            sortable: true,
            width: '3fr',
            renderCell: (fprops) => {
                const url = (fprops.row as Url).url;
                return <>{url}</>;
            },
        },
    };

    const DefaultColumns: ExtendedColumn[] = [Columns[UrlsColumnKey.NAME], Columns[UrlsColumnKey.URL]];

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_urls.png',
    };

    return (
        <>
            <CampusDataGrid
                repository={urlsRepository}
                dataGridMeta={{
                    uniqueKey: props.dataGridUniqueKey,
                    entitySingular: props.dataGridEntitySingular,
                    entityPlural: props.dataGridEntityPlural,
                    defaultColumns: DefaultColumns,
                    columnOptions: Columns,
                }}
                actionBarMeta={{ searchPlaceHolder: props.searchFilterPlaceholder, includeCounts: true }}
                addSispMeta={{
                    key: addSispUniqueKey,
                    sisp: UrlsAddSisp,
                }}
                editSispMeta={{
                    sisp: UrlsEditSisp,
                }}
                promptMeta={promptMeta}
            />
        </>
    );
};

export default UrlsTable;
