import { LogLevel, Modal, ModalType, showBanner } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext } from 'react';
import { ContactsRequest } from '../../Api/ContactsRequest';
import { RepositoryFactoryContext } from '../../index';

interface Props {
    selectedRowsForDeletion: number[];
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
    modalShown: boolean;
    reloadDataGrid: () => void;
    closeModal: () => void;
}

const CRMDeleteModal: FunctionComponent<Props> = (props: Props) => {
    const repository = useContext(RepositoryFactoryContext).getApiRepository(new ContactsRequest());

    const deleteRows = (): Promise<boolean> => {
        return repository
            .delete(props.selectedRowsForDeletion)
            .then(() => {
                showBanner({
                    message: props.dataGridEntitySingular + '(s) deleted successfully',
                    level: LogLevel.SUCCESS,
                });
                props.reloadDataGrid();
                return true;
            })
            .catch((err: any) => {
                showBanner({
                    message: err?.message ?? err,
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    const title = () =>
        props.selectedRowsForDeletion.length > 1
            ? `Delete ${props.dataGridEntityPlural}`
            : `Delete ${props.dataGridEntitySingular}`;

    const warning = () =>
        `Are you sure you want to delete ${
            props.selectedRowsForDeletion.length > 1
                ? `these ${props.dataGridEntityPlural.toLowerCase()}`
                : `this ${props.dataGridEntitySingular.toLowerCase()}`
        }?`;

    const additionalWarning = () =>
        `Deleting ${
            props.selectedRowsForDeletion.length > 1
                ? `these ${props.dataGridEntityPlural.toLowerCase()}`
                : `this ${props.dataGridEntitySingular.toLowerCase()}`
        } will remove them from your CRM Module. Deleted data cannot be recovered.`;

    return (
        <Modal
            isOpen={props.modalShown}
            type={ModalType.DELETE}
            title={title()}
            close={props.closeModal}
            onSubmit={deleteRows}
        >
            <>
                {warning()}
                <br />
                <br />
                {additionalWarning()}
            </>
        </Modal>
    );
};

export default CRMDeleteModal;
