'use strict';

import * as ActionTypes from './ActionTypes';
import {filterDeals} from "../../../Entities/Deal/Actions";

export function blurField(fieldName) {
    return {
        type: ActionTypes.FIELD_BLURRED,
        payload: {
            fieldName: fieldName,
        }
    }
}

export function submitFormAttempted(addAnother = false) {
    return {
        type: ActionTypes.FORM_SUBMIT_ATTEMPTED,
        payload: {
            addAnother: addAnother
        }
    }
}

export function resetForm() {
    return {
        type: ActionTypes.FORM_RESET
    }
}

export function updateActivities() {
    return (dispatch) => {
        // If classic activities panel exists, then update get latest notifications
        if (typeof viewActivities !== 'undefined' && typeof viewActivities.getLatestActivity === 'function') {
            try {
                viewActivities.getLatestActivity();
            } catch (e) {
            }
        }
        dispatch(activitiesUpdated());
    }
}

export function updateAlertNotifications(message, isError = false) {
    return (dispatch) => {
        // If classic Alert Notifications controller exists, then update notifications
        if (typeof AlertNotifications !== 'undefined') {
            try {
                if (!message) {
                    if (typeof AlertNotifications.clear() === 'function') AlertNotifications.clear()
                } else if (!isError) {
                    if (typeof AlertNotifications.showSuccess === 'function') AlertNotifications.showSuccess(message);
                } else if (isError) {
                    if (typeof AlertNotifications.showError === 'function') AlertNotifications.showError(message);
                }
            } catch (e) {
            }
        }
        dispatch(alertNotificationsUpdated());
    }
}

export function updatePromptPage() {
    return (dispatch) => {
        const onPromptPage = !!document.getElementsByClassName('panel-prompt').length;
        // If on classic prompt page, do classic update
        if (onPromptPage) {
            window.location.reload();
        }
        dispatch(promptPageUpdated());
    }
}

export function updateViewPage() {
    return (dispatch) => {
        if (typeof DealsViewPage !== 'undefined' && typeof DealsViewPage.refreshFromServer === 'function') {
            try {
                DealsViewPage.refreshFromServer();
            } catch (e) {
            }
        }
        dispatch(viewPageUpdated());
    }
}

export function exitViewPage(redirect = '/deals') {
    return (dispatch) => {
        window.location = redirect;
        dispatch(viewPageExited());
    }
}

export function updateDataTable() {
    return (dispatch) => {
        if (typeof DealsDataTable !== 'undefined' && typeof DealsDataTable.refresh === 'function') {
            try {
                DealsDataTable.refresh((response) => { dispatch(dataTableUpdated(response['recordsTotal'])); });
            } catch (e) {
            }
        }
        dispatch(dataTableUpdating());
    }
}

export function changeDataTableVisibility(selectedMultiView, textFilter, advancedSearch, fromPersistedState) {
    return (dispatch) => {
        if (typeof DealsDataTable !== 'undefined' && typeof DealsDataTable.visibility === 'function') {
            try {
                const showTable = selectedMultiView === 'Table';
                if (DealsDataTable.visibility(showTable)) {
                    if (!fromPersistedState) {
                        if (showTable) boardFilterToTable(textFilter);
                        else tableFilterToBoard(dispatch, textFilter, advancedSearch);
                    } else {
                        // Sync board/table filters the other way round when re-hydrating persisted state
                        if (showTable) tableFilterToBoard(dispatch, textFilter, advancedSearch);
                        else boardFilterToTable(textFilter);
                    }
                    dispatch(dataTableVisibilityChanged());
                }
            } catch (e) {
            }
        }
    }
}

function boardFilterToTable (textFilter) {
    // Push current redux text filter value to jQuery data table
    if (typeof DealsDataTable.updateTextFilter === 'function') DealsDataTable.updateTextFilter(textFilter);
}

function tableFilterToBoard(dispatch, textFilter, advancedSearch) {
    // Pull text filter value from jQuery data table and update redux (if changed)
    const dataTableTextFilter = (typeof DealsDataTable.updateTextFilter === 'function') ? DealsDataTable.getTextFilter() : undefined;
    if (dataTableTextFilter !== textFilter && dataTableTextFilter !== undefined) dispatch(filterDeals(dataTableTextFilter, advancedSearch));
}

export function openFormSisp(returnOrigin = '') {
    return {
        type: ActionTypes.OPEN_FORM_SISP,
        payload: {
            returnOrigin: returnOrigin
        }
    }
}

export function closeFormSisp() {
    return {
        type: ActionTypes.CLOSE_FORM_SISP
    }
}

export function openPreviewSisp(autoClose) {
    return {
        type: ActionTypes.OPEN_PREVIEW_SISP,
        payload: {
            autoClose: autoClose
        }
    }
}

export function closePreviewSisp() {
    return {
        type: ActionTypes.CLOSE_PREVIEW_SISP
    }
}

export function openInteractionSisp(type = '') {
    return {
        type: ActionTypes.OPEN_INTERACTION_SISP,
        payload: {
            interactionType: type
        }
    }
}

export function closeInteractionSisp() {
    return {
        type: ActionTypes.CLOSE_INTERACTION_SISP
    }
}

export function openQuoteLinkModal(dealId, dealClosing = false, showCreateButton = true) {
    return {
        type: ActionTypes.OPEN_QUOTE_LINK_MODAL,
        payload: {
            dealId: dealId,
            dealClosing: dealClosing,
            showCreateButton: showCreateButton
        }
    }
}

export function quoteLinkModalOpened() {
    return {
        type: ActionTypes.QUOTE_LINK_MODAL_OPENED
    }
}

export function openSaleLinkModal(dealId, dealClosing = false, showCreateButton = true) {
    return {
        type: ActionTypes.OPEN_SALE_LINK_MODAL,
        payload: {
            dealId: dealId,
            dealClosing: dealClosing,
            showCreateButton: showCreateButton
        }
    }
}

export function saleLinkModalOpened() {
    return {
        type: ActionTypes.SALE_LINK_MODAL_OPENED
    }
}

export function switchMultiView(viewName) {
    return {
        type: ActionTypes.SWITCH_MULTI_VIEW,
        payload: {
            selectedMultiView: viewName
        }
    }
}

function activitiesUpdated() {
    return {
        type: ActionTypes.ACTIVITIES_UPDATED
    }
}

function alertNotificationsUpdated() {
    return {
        type: ActionTypes.ALERT_NOTIFICATIONS_UPDATED
    }
}

function promptPageUpdated() {
    return {
        type: ActionTypes.PROMPT_PAGE_UPDATED
    }
}

function viewPageUpdated() {
    return {
        type: ActionTypes.VIEW_PAGE_UPDATED
    }
}

function viewPageExited() {
    return {
        type: ActionTypes.VIEW_PAGE_EXITED
    }
}

function dataTableUpdating() {
    return {
        type: ActionTypes.DATA_TABLE_UPDATING
    }
}

function dataTableUpdated(dataTableRowCount = -1) {
    return {
        type: ActionTypes.DATA_TABLE_UPDATED,
        payload: {
            dataTableRowCount: dataTableRowCount
        }
    }
}

function dataTableVisibilityChanged() {
    return {
        type: ActionTypes.DATA_TABLE_VISIBILITY_CHANGED
    }
}