'use strict';

import Price from "../../ValueObjects/Price/Price";

export default class QuoteItem {

    id = undefined;
    quantity = 0;
    productName = '';
    description = '';
    netPrice = undefined;
    vatRate = 0;

    static blank() {
        return new QuoteItem();
    }

    static fromApi(item) {
        const entity = QuoteItem.blank();

        entity.id = item.id;
        entity.quantity = item.quantity;
        entity.productName = item.product_name;
        entity.description = item.description;
        entity.netPrice = item.net_price ? Price.fromApi(item.net_price) : undefined;
        entity.vatRate = item.vat_rate;

        return entity;
    }

    static select(entityArray, id) {
        return entityArray.find(entity => entity.matches(id));
    }

    matches(id) {
        return String(this.id) === String(id);
    }

    clone() {
        return Object.assign(QuoteItem.blank(), this);
    }

}
