'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import MultiSelect from '../../CommonComponents/Select/MultiSelect';
import * as DealStageState from './DealStageState';

class DealStageStateMultiSelect extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let placeholder = this.props.selectedValues.join(', ');
        const notSelected = DealStageState.allOptions().filter(o => this.props.selectedValues.indexOf(o) === -1);
        if (!placeholder || notSelected.length === 0) placeholder = 'State: All';
        else if (notSelected.length === 1 && notSelected.includes(DealStageState.OPEN)) placeholder = 'Closed';

        return <MultiSelect
            placeholder={placeholder}
            open={this.props.open}
            toggle={this.props.toggle}
            selectedOptions={this.props.selectedValues}
            availableOptions={DealStageState.allOptions()}
            updateSelectedOptions={this.props.updateSelectedOptions}
        />;
    }

}

DealStageStateMultiSelect.propTypes = {
    selectedValues: PropTypes.arrayOf(PropTypes.string),
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    updateSelectedOptions: PropTypes.func
};

export default DealStageStateMultiSelect;