import { UserPermissionsContext } from '@client/Context/UserPermissions';
import React, { useContext } from 'react';

// Created this wrapper so that the permissions context can be easily reused
// Within non-functional components (e.g. small components within our old redux react)
interface Props {
    legacyComponent: React.ComponentType<any>;
}

const PermissionsWrappedLegacyComponent = (props: Props & any) => {
    const contextValue = useContext(UserPermissionsContext);
    return <props.legacyComponent {...props} permissionsContext={contextValue} />;
};

export default PermissionsWrappedLegacyComponent;
