import { User } from '@sprint/sprint-react-components';
import { AxiosResponse } from 'axios';
import HttpRequestService from '../../../CommonComponents/HttpRequestService/HttpRequestService';

export default class UserRepository {
    private _http: HttpRequestService;

    constructor(private _authToken: string) {
        this._http = new HttpRequestService(this._authToken);
    }

    private isSuccessCode(statusCode: number) {
        return statusCode >= 200 && statusCode <= 299;
    }

    public async getAllUsers(): Promise<any> {
        return this._http
            .get('/account/ajax/get_all_users')
            .then((res: AxiosResponse<{ data: User[] }> | null) => {
                if (res && this.isSuccessCode(res.status)) {
                    const { data: users } = res.data;
                    return users;
                }
            })
            .catch((err) => {
                throw err;
            });
    }
}
