import React, { CSSProperties, FunctionComponent, PropsWithChildren } from 'react';
import './label.scss';

interface Props {
    primary?: boolean;
    tertiary?: boolean;
    info?: boolean;
    success?: boolean;
    warning?: boolean;
    danger?: boolean;
    border?: boolean;
    className?: string;
    style?: CSSProperties;
}

const Label: FunctionComponent<Props> = (props: PropsWithChildren<Props>) => {
    let className = 'react-label';

    if (props.primary) className += ' react-label--primary';
    else if (props.tertiary) className += ' react-label--tertiary';
    else if (props.info) className += ' react-label--info';
    else if (props.success) className += ' react-label--success';
    else if (props.warning) className += ' react-label--warning';
    else if (props.danger) className += ' react-label--danger';
    else className += ' react-label--info';

    if (!props.border) className += ' react-label--no-border';

    if (props.className) className += ' ' + props.className;
    return (
        <label className={className} style={props.style}>
            {props.children}
        </label>
    );
};

Label.defaultProps = {
    border: true,
};

export default Label;
