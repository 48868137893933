'use strict';

import React from 'react';
import Datetime from 'react-datetime';
import Moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/en-ie';
import 'moment/locale/en-ca';
import 'moment/locale/en-au';
import 'moment/locale/en-nz';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/pro-regular-svg-icons';
import './datetime.scss';
import PropTypes from 'prop-types';

class DateTimeInput extends React.Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.renderInput = this.renderInput.bind(this);
    }

    handleChange(momentDate) {
        // Convert moment date to output format string (for server usage etc)
        this.props.onChange(momentDate && Moment(momentDate).isValid() ? Moment(momentDate).format(this.props.outputFormat) : '');
    }

    handleBlur(momentDate) {
        if (!this.props.onBlur) return;
        // Convert moment date to output format string (for server usage etc)
        this.props.onBlur(momentDate && Moment(momentDate).isValid() ? Moment(momentDate).format(this.props.outputFormat) : '');
    }

    render() {
        let className = 'react-dtp';
        if (this.props.dimInput) className += ' dim-input';
        if (this.props.popupAbove) className += ' popup-display-above';
        if (this.props.popupRight) className += ' popup-display-right';
        if (this.props.showArrow) {
            className += ' popup-arrow';
            className += this.props.popupAbove ? ' popup-arrow-bottom' : ' popup-arrow-top';
            className += this.props.popupRight ? ' popup-arrow-right' : ' popup-arrow-left';
        }

        return <Datetime
            {...this.props}
            value={Moment(this.props.value, this.props.outputFormat)}
            onChange={this.handleChange.bind(this)}
            onBlur={this.handleBlur.bind(this)}
            className={className}
            // Only render custom input if clear-button overlay is required,
            // otherwise use built-in input renderer
            renderInput={this.props.showClear ? this.renderInput : null}
        />
    }

    renderInput(props, openCalendar, closeCalendar) {
        function clear(e){
            e.preventDefault();
            props.onChange({target: {value: ''}});
        }
        return (
            <div>
                <input {...props} />
                <a onClick={clear} className={"clear-button" + (props.value ? "" : " hide")} href="#">
                    <FontAwesomeIcon icon={faTimes}/>
                </a>
            </div>
        );
    }

}

DateTimeInput.propTypes = {
    showClear: PropTypes.bool,
    showArrow: PropTypes.bool,
    popupAbove: PropTypes.bool,
    popupRight: PropTypes.bool,
    dimInput: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    outputFormat: PropTypes.string
};

DateTimeInput.defaultProps = {
    showClear: true,
    showArrow: true,
    popupAbove: false,
    popupRight: false,
    dimInput: false,
    outputFormat: 'YYYY-MM-DD HH:mm:ss' // Server-friendly format
};

export default DateTimeInput;