import React from 'react';
import Header from '../LoadingIndicator/Header';
import Item from '../LoadingIndicator/Item';
import './board.scss';

const BoardLoading = () => {
    const itemNoBorder = true;
    const itemNoLabelBar = false;
    const itemRandomWidthLimit = 150;

    return (
        <div className="react-board react-board-loading">
            <div className="react-board--row-group">
                <div className="react-board--section">
                    <div className="react-board--section--header">
                        <Header noMargin={true} large={false} />
                    </div>
                    <div className="react-board--section--content">
                        <div className="react-board--column">
                            <div className="react-board--column--header">
                                <Header noMargin={true} large={true} />
                            </div>
                            <div className="react-board--column--content">
                                <Item
                                    noBorder={itemNoBorder}
                                    noLabelBar={itemNoLabelBar}
                                    randomWidthLimit={itemRandomWidthLimit}
                                />
                                <Item
                                    noBorder={itemNoBorder}
                                    noLabelBar={itemNoLabelBar}
                                    randomWidthLimit={itemRandomWidthLimit}
                                />
                                <Item
                                    noBorder={itemNoBorder}
                                    noLabelBar={itemNoLabelBar}
                                    randomWidthLimit={itemRandomWidthLimit}
                                />
                                <Item
                                    noBorder={itemNoBorder}
                                    noLabelBar={itemNoLabelBar}
                                    randomWidthLimit={itemRandomWidthLimit}
                                />
                                <Item
                                    noBorder={itemNoBorder}
                                    noLabelBar={itemNoLabelBar}
                                    randomWidthLimit={itemRandomWidthLimit}
                                />
                            </div>
                        </div>
                        <div className="react-board--column">
                            <div className="react-board--column--header">
                                <Header noMargin={true} large={true} />
                            </div>
                            <div className="react-board--column--content">
                                <Item
                                    noBorder={itemNoBorder}
                                    noLabelBar={itemNoLabelBar}
                                    randomWidthLimit={itemRandomWidthLimit}
                                />
                                <Item
                                    noBorder={itemNoBorder}
                                    noLabelBar={itemNoLabelBar}
                                    randomWidthLimit={itemRandomWidthLimit}
                                />
                            </div>
                        </div>
                        <div className="react-board--column">
                            <div className="react-board--column--header">
                                <Header noMargin={true} large={true} />
                            </div>
                            <div className="react-board--column--content">
                                <Item
                                    noBorder={itemNoBorder}
                                    noLabelBar={itemNoLabelBar}
                                    randomWidthLimit={itemRandomWidthLimit}
                                />
                                <Item
                                    noBorder={itemNoBorder}
                                    noLabelBar={itemNoLabelBar}
                                    randomWidthLimit={itemRandomWidthLimit}
                                />
                                <Item
                                    noBorder={itemNoBorder}
                                    noLabelBar={itemNoLabelBar}
                                    randomWidthLimit={itemRandomWidthLimit}
                                />
                                <Item
                                    noBorder={itemNoBorder}
                                    noLabelBar={itemNoLabelBar}
                                    randomWidthLimit={itemRandomWidthLimit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BoardLoading;
