import { filter as filterTypes, GenderOfPupils } from '@sprint/sprint-react-components';
import _ from 'lodash';
import { FilterExtendedColumn } from '../../CampusDataGrid';

const SyncedDataFilterableFieldsEDUK: FilterExtendedColumn[] = [
    {
        key: 'name',
        name: 'School Name',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'phone',
        name: 'Telephone Number',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'fax',
        name: 'Fax',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'website',
        name: 'Website URL',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'address1',
        name: 'Address 1',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'address2',
        name: 'Address 2',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'address4',
        name: 'Town',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'postcode',
        name: 'Postcode',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'county',
        name: 'County',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'lea',
        name: 'Local Authority',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'region',
        name: 'Region',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'school_type',
        name: 'Type',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    // Extra school info fields
    {
        key: 'school_info.number_on_roll',
        name: 'Number of Pupils on Roll',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'school_info.age_range_lower',
        name: 'Minimum Age',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'school_info.age_range_upper',
        name: 'Maximum Age',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'school_info.urn',
        name: 'URN',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'school_info.establishment_status',
        name: 'Status',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    {
        key: 'school_info.religious_denomination',
        name: 'Religious Denomination',
        filterFieldType: filterTypes.FieldType.STRING,
    },
    // Pupil fields
    {
        key: 'pupils.gender_of_pupils',
        name: 'Gender of Pupils',
        filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
        filterFieldOptions: () =>
            _.map(Object.values(GenderOfPupils), (gender) => ({
                value: gender,
                name: gender,
            })),
    },
    {
        key: 'pupils.absence',
        name: 'Pupils - Absence Percentage',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'pupils.number_of_boys',
        name: 'Pupils - Number of Boys',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'pupils.number_of_girls',
        name: 'Pupils - Number of Girls',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'pupils.english_foreign_language',
        name: 'Pupils - Pupils with English as a foreign language',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'pupils.sens_statement_or_school_action_plus',
        name: 'Pupils - Pupils with SEN support',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    // Financial Fields
    {
        key: 'financial.income_total',
        name: 'Finances - Total Income',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'financial.expenditure_ict',
        name: 'Finances - ICT Expenditure',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'financial.expenditure_catering',
        name: 'Finances - Catering Expenditure',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'financial.expenditure_other',
        name: 'Finances - Other Expenditure',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'financial.expenditure_premises',
        name: 'Finances - Premises Expenditure',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'financial.expenditure_energy',
        name: 'Finances - Energy Expenditure',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'financial.expenditure_backoffice',
        name: 'Finances - Back Office Expenditure',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'financial.expenditure_supply_staff',
        name: 'Finances - Supply Staff Expenditure',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'financial.expenditure_support_staff',
        name: 'Finances - Support Staff Expenditure',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'financial.expenditure_teaching_staff',
        name: 'Finances - Teaching Staff Expenditure',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'financial.expenditure_otherstaff',
        name: 'Finances - Other Staff Expenditure',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'financial.expenditure_bought_in_services',
        name: 'Finances - Bought-in Services Expenditure',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'financial.expenditure_learning_resources',
        name: 'Finances - Learning Resources Expenditure',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'financial.expenditure_total',
        name: 'Finances - Total Expenditure',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'financial.income_grant_funding',
        name: 'Finances - Grant Funding Income',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'financial.pupil_premium_amount',
        name: 'Finances - Pupil Premium Amount',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'financial.pupils_pupil_premium',
        name: 'Finances - Pupils Eligible for Premium',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'financial.percentage_pupil_premium',
        name: 'Finances - Percentage of Pupils Eligible for Premium',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'financial.income_self_generated',
        name: 'Finances - Self Generated Income',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    // Workforce fields
    {
        key: 'workforce.number_of_teachers',
        name: 'Workforce - Number of Teachers',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'workforce.number_of_support_staff',
        name: 'Workforce - Number of Support Staff',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
    {
        key: 'workforce.number_of_teaching_assistants',
        name: 'Workforce - Number of Teaching Assistants',
        filterFieldType: filterTypes.FieldType.NUMBER,
    },
];

export { SyncedDataFilterableFieldsEDUK };
