'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {isNumber, INT_MAX} from "./functions";

import './number-range.scss';

class NumberRange extends React.Component {

    value() {
        let iconClassName = "react-number-range--icon";
        if (this.props.open) iconClassName += " open";

        let title = (this.props.title ? this.props.title.replace(' Between', '') : 'Number Range') + ': ';
        let value = 'Any';

        if (isNumber(this.props.fromValue) || isNumber(this.props.toValue)) {
            let from = isNumber(this.props.fromValue)
                ? this.props.fromValue.toString()
                : '0';
            let to = (isNumber(this.props.toValue) && this.props.toValue.toString() !== INT_MAX.toString())
                ? this.props.toValue.toString()
                : 'Max';
            value = from === to ? from : (from + ' - ' + to);
        }

        return <a href="#" className="react-number-range--value" onClick={this.props.toggle}>
            {title + value}
            <span className={iconClassName}>
                <i/>
            </span>
        </a>;
    }

    handleChange(e) {
        let from = this.props.fromValue;
        let to = this.props.toValue;
        if (e.target.name === 'from') from = isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value);
        if (e.target.name === 'to') to = isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value);
        this.props.update(from, to);
    }

    dropDown() {
        if (!this.props.open) return null;

        const from = isNumber(this.props.fromValue) ? this.props.fromValue : '';
        const to = isNumber(this.props.toValue) ? this.props.toValue : '';

        return <div className="react-number-range--dropdown">
            <div className="react-number-range--inputs">
                <div>
                    <label>Between</label>
                    <input type="number" step={1} name="from" value={from} className="form-control" onChange={this.handleChange.bind(this)} autoFocus={true} />
                </div>
                <div>
                    <label>and</label>
                    <input type="number" step={1} name="to" value={to} className="form-control" onChange={this.handleChange.bind(this)}/>
                </div>
            </div>
        </div>;
    }

    render() {
        let className = "react-number-range";
        if (this.props.open) className += ' react-number-range--open';
        return <div className={className}>
            {this.value()}
            {this.dropDown()}
        </div>
    }

}

NumberRange.propTypes = {
    open: PropTypes.bool.isRequired,
    fromValue: PropTypes.any,
    toValue: PropTypes.any,
    toggle: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
};

export default NumberRange;