'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import '../dropdown.scss';

class MenuOption extends React.Component {

    onClick(e){
        e.preventDefault();
        if (this.props.action) this.props.action(this.props.value);
    }

    render() {
        return <li className={"react-drop-down-menu-option" + (!this.props.action ? " no-action" : "")}>
            {<a href="#" onClick={this.onClick.bind(this)}>{this.props.children}</a>}
        </li>;
    }

}

MenuOption.propTypes = {
    value: PropTypes.string,
    action: PropTypes.func
};

MenuOption.defaultProps = {};

export default MenuOption;