'use strict';

import Price from "../../ValueObjects/Price/Price";

export default class SaleItem {

    quantity = 0;
    productName = '';
    productDescription = '';
    netPrice = undefined;
    vatRate = 0;
    subTotal = undefined;

    static blank() {
        return new SaleItem();
    }

    static fromApi(item) {
        const entity = SaleItem.blank();

        // Construct from expected API output object
        entity.quantity = item.quantity;
        entity.productName = item.product_name;
        entity.productDescription = item.product_description;
        entity.netPrice = item.net_price ? Price.fromApi(item.net_price) : undefined;
        entity.vatRate = item.vat_rate;
        entity.subTotal = item.sub_total ? Price.fromApi(item.sub_total) : undefined;

        return entity;
    }

    static select(entityArray, id) {
        return entityArray.find(entity => entity.matches(id));
    }

    matches(productName) {
        return String(this.productName) === String(productName);
    }

    clone() {
        return Object.assign(SaleItem.blank(), this);
    }

}
