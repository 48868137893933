'use strict';

export const DEALTYPES_LOADED = 'dealTypes/DEALTYPES_LOADED';
export const DEALTYPES_LOADING_FAILED = 'dealTypes/DEALTYPES_LOADING_FAILED';

export const DEALTYPE_NEW = 'dealTypes/DEALTYPE_NEW';
export const DEALTYPE_UPDATE_FIELD = 'dealTypes/DEALTYPE_UPDATE_FIELD';
export const DEALTYPE_SAVING = 'dealTypes/DEALTYPE_SAVING';
export const DEALTYPE_SAVED = 'dealTypes/DEALTYPE_SAVED';
export const DEALTYPE_SAVING_FAILED = 'dealTypes/DEALTYPE_SAVING_FAILED';
export const DEALTYPE_SELECT = 'dealTypes/DEALTYPE_SELECT';
export const DEALTYPE_LOADING = 'dealTypes/DEALTYPE_LOADING';
export const DEALTYPE_LOADED = 'dealTypes/DEALTYPE_LOADED';
export const DEALTYPE_LOADING_FAILED = 'dealTypes/DEALTYPE_LOADING_FAILED';
export const DEALTYPE_INVALIDATED = 'dealTypes/DEALTYPE_INVALIDATED';
export const DEALTYPE_DELETING = 'dealTypes/DEALTYPE_DELETING';
export const DEALTYPE_DELETED = 'dealTypes/DEALTYPE_DELETED';