import { filter as filterTypes, SearchQuery, showBanner } from '@sprint/sprint-react-components';
import ListFilterOptionsRepository from '../../../Api/ListFilterOptionsRepository';
import { FilterOptionsService, formatterInterface } from './FilterOptionsService';

export default class ApiFilterOptionsService implements FilterOptionsService {
    private listFilterOptionsRepository;

    constructor() {
        this.listFilterOptionsRepository = new ListFilterOptionsRepository(
            '',
            '/subscribers/lists/api/contact/field_options',
        );
    }

    public getFilterOptions(query: SearchQuery, formatter?: formatterInterface): Promise<filterTypes.EnumOption[]> {
        return this.listFilterOptionsRepository
            .search(query)
            .then((result) => {
                const res: filterTypes.EnumOption[] =
                    formatter !== undefined ? formatter(result.results) : result.results;
                res.push({
                    value: result.counts.currentPage < result.counts.totalPages,
                    name: 'has_more',
                });
                return res;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to get field options - ' + (err?.message ?? err),
                });
                return [];
            });
    }
}
