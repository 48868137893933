import { PendingButton, Sisp } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useState } from 'react';
import { Button } from 'react-bootstrap';
import ClientTagsForm from './ClientTagsForm';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
}

const ClientTagsAddSisp: FunctionComponent<Props> = (props: Props) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    return (
        <Sisp
            isOpen={props.shown}
            onCancel={() => {
                setIsSubmitting(false);
                props.onClose();
            }}
            footerOverride={
                <>
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            setIsSubmitting(false);
                            props.onClose();
                        }}
                        variant="secondary"
                        form="tag_add_edit_form"
                    >
                        Cancel
                    </Button>
                    <PendingButton
                        onClick={(event) => {
                            setIsSubmitting(true);
                        }}
                        pending={isSubmitting}
                        type="submit"
                        form="tag_add_edit_form"
                    >
                        Save
                    </PendingButton>
                </>
            }
        >
            <h4>Add a Tag</h4>
            <ClientTagsForm
                onSubmit={(data: any) => {
                    setIsSubmitting(false);
                    props.onSuccess(data);
                    props.onClose();
                }}
            />
        </Sisp>
    );
};

export default ClientTagsAddSisp;
