import React from 'react';
import Header from '../LoadingIndicator/Header';
import Item from '../LoadingIndicator/Item';

const SispLoading = () => {
    return (
        <div>
            <Header key={0} />
            <Item key={1} />
            <Item key={2} />
            <Item key={3} />
            <Item key={4} />
        </div>
    );
};

export default SispLoading;
