import { filter as filterTypes } from '@sprint/sprint-react-components';

export class DealsBoardHelpers {
    static processOrBlock = (orCondition: filterTypes.FilterBlock, allPipelineIds: number[]) => {
        let results = [];
        const operator = orCondition.operator.operator;
        const values = (orCondition.value as filterTypes.EnumOption[]).map(
            (item: filterTypes.EnumOption) => item.value,
        ) as number[];
        if (operator == 'is_any_of') {
            // Display content of Values
            results = values;
        } else if (operator == 'is_none_of') {
            // Return Map function
            const newValues = allPipelineIds.filter((id: number) => !values.includes(id));
            results = newValues;
        } else {
            // Show all Pipelines
            results = allPipelineIds;
        }
        return results;
    };

    static extractPipelinesFromFilter = (
        selectedFilterState: filterTypes.SelectedFilters[],
        allPipelineIds: number[],
    ): number[] => {
        let pipelinesToDispatch: number[] = [];
        selectedFilterState[0]?.filters?.forEach((filter: filterTypes.FieldFilter[]) => {
            filter.forEach((filter: filterTypes.FieldFilter) => {
                if (filter.key == 'pipeline') {
                    filter.orConditions.forEach((orCondition: filterTypes.FilterBlock) => {
                        const orBlockPipelines = DealsBoardHelpers.processOrBlock(orCondition, allPipelineIds);
                        pipelinesToDispatch = [...new Set([...pipelinesToDispatch, ...orBlockPipelines])];
                    });
                }
            });
        });
        return pipelinesToDispatch;
    };
}
