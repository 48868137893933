'use strict';

import HttpRequestService from '../../Services/HttpRequestService/HttpRequestService';

export default class DealRepository {
    static filter(textFilter, advancedSearch, cancelToken, offset) {
        return new Promise(function (resolve, reject) {
            const formData = new FormData();

            if (advancedSearch) formData.append('advancedSearch', JSON.stringify(advancedSearch));

            let limit = 0;

            new HttpRequestService()
                .post('deals/ajax/filter/' + offset + '/' + limit + '/' + textFilter, formData, cancelToken)
                .then((response) => {
                    if (!response) return reject('Invalid response returned. ERR-REACT-DE-07');
                    if (response.status === 499) return resolve(undefined); // Request cancelled by user
                    if (response.status !== 200)
                        return reject('Unable to load deals, endpoint not responding. ERR-REACT-DE-08');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve({
                        items: response.data.data.items.map((s) => {
                            //s.custom_fields = JSON.parse(s.custom_fields);
                            return s;
                        }),
                        total: response.data.data.total,
                    });
                })
                .catch((error) => {
                    reject(error.message);
                });
        });
    }

    static loadIdList(idList) {
        return new Promise(function (resolve, reject) {
            const formData = new FormData();
            formData.append('id_list', JSON.stringify(idList));
            new HttpRequestService()
                .post('deals/ajax/by_ids', formData)
                .then((response) => {
                    if (!response) return reject('Invalid response returned. ERR-REACT-DE-19');
                    if (response.status !== 200)
                        return reject('Unable to load deals, endpoint not responding. ERR-REACT-DE-20');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve(response.data.data);
                })
                .catch((error) => reject(error.message));
        });
    }

    static save(dealEntity) {
        return new Promise(function (resolve, reject) {
            if (typeof dealEntity.forApi !== 'function')
                return reject('Unable to save deal, invalid deal entity provided. ERR-REACT-DE-06');
            new HttpRequestService()
                .post('deals/ajax/save', dealEntity.forApi())
                .then((response) => {
                    if (!response) return reject('Invalid response returned. ERR-REACT-DE-01');
                    if (response.status !== 200)
                        return reject('Unable to save deal, endpoint not responding. ERR-REACT-DE-02');
                    if (response.data.success === false) return reject(response.data.error);
                    if (!response.data.data) return reject('Invalid response returned. ERR-REACT-DE-05');
                    resolve(response.data.data);
                })
                .catch((error) => reject(error.message));
        });
    }

    static find(dealId) {
        return new Promise(function (resolve, reject) {
            new HttpRequestService()
                .get('deals/ajax/get_deal/' + dealId)
                .then((response) => {
                    if (!response) return reject('Invalid response returned. ERR-REACT-DE-03');
                    if (response.status !== 200)
                        return reject('Unable to load deal, endpoint not responding. ERR-REACT-DE-04');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve(response.data.data);
                })
                .catch((error) => reject(error.message));
        });
    }

    static links(dealId) {
        return new Promise(function (resolve, reject) {
            new HttpRequestService()
                .get('deals/ajax/get_links/' + dealId)
                .then((response) => {
                    if (!response) return reject('Invalid response returned. ERR-REACT-DE-03');
                    if (response.status !== 200)
                        return reject(
                            "Unable to load deal's quotes, sales and tasks, endpoint not responding. ERR-REACT-DE-04",
                        );
                    if (response.data.success === false) return reject(response.data.error);
                    resolve(response.data.data);
                })
                .catch((error) => reject(error.message));
        });
    }

    static delete(dealId) {
        return new Promise(function (resolve, reject) {
            if (dealId < 1) return reject('Unable to delete deal, invalid ID provided. ERR-REACT-DE-11');

            const formData = new FormData();
            formData.append('id', dealId);

            new HttpRequestService()
                .post('deals/ajax/delete_deal/', formData)
                .then((response) => {
                    if (!response) return reject('Invalid response returned. ERR-REACT-DE-09');
                    if (response.status !== 200)
                        return reject('Unable to delete deal, endpoint not responding. ERR-REACT-DE-10');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve();
                })
                .catch((error) => reject(error.message));
        });
    }

    static deleteMultiple(dealIds) {
        return new Promise(function (resolve, reject) {
            if (!Array.isArray(dealIds))
                return reject('Unable to deal deals, invalid deal ID array provided. ERR-REACT-DE-16');

            const formData = new FormData();
            formData.append('ids', JSON.stringify(dealIds));

            new HttpRequestService()
                .post('deals/ajax/delete_selected/', formData)
                .then((response) => {
                    if (!response) return reject('Invalid response returned. ERR-REACT-DE-17');
                    if (response.status !== 200)
                        return reject('Unable to delete deals, endpoint not responding. ERR-REACT-DE-18');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve();
                })
                .catch((error) => reject(error.message));
        });
    }

    static updatePipeline(dealIds, pipelineId, stageName, stageId) {
        return new Promise(function (resolve, reject) {
            if (!Array.isArray(dealIds))
                return reject('Unable to update deals, invalid deal ID array provided. ERR-REACT-DE-15');

            const formData = new FormData();
            formData.append('ids', JSON.stringify(dealIds));
            formData.append('pipeline_id', pipelineId);
            formData.append('stage', stageName);
            formData.append('stage_id', stageId);

            new HttpRequestService()
                .post('deals/ajax/update_pipelines/', formData)
                .then((response) => {
                    if (!response) return reject('Invalid response returned. ERR-REACT-DE-12');
                    if (response.status !== 200)
                        return reject('Unable to update deals, endpoint not responding. ERR-REACT-DE-13');
                    if (response.data.success === false) return reject(response.data.error);
                    if (!response.data.data) return reject('Invalid response returned. ERR-REACT-DE-14');
                    resolve(response.data.data);
                })
                .catch((error) => reject(error.message));
        });
    }
}
