'use strict';

import { EventBusInstance } from '@sprint/sprint-react-components';
import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import AlertBanner, { AlertLevel } from '../../../CommonComponents/AlertBanner/AlertBanner';
import BoardLoading from '../../../CommonComponents/Board/BoardLoading';
import { closeModal, openModal, updateModal } from '../../../CommonComponents/Modal/ModalActions';
import { deleteDeals, loadMoreDeals, selectDeal, updateDealPipelines } from '../../../Entities/Deal/Actions';
import Deal from '../../../Entities/Deal/Deal';
import { loadDealPipelines } from '../../../Entities/Deal/DealPipeline/Actions';
import * as DealStageState from '../../../ValueObjects/DealStageState/DealStageState';
import {
    changeDataTableVisibility,
    openFormSisp,
    openPreviewSisp,
    openSaleLinkModal,
    resetForm
} from '../Actions/Actions';
import PipelineGroup from './DealBoard/PipelineGroup';
import ModalSaleLink from './ModalSaleLink';

class DealBoard extends React.Component {
    componentDidMount() {
        if (this.props.dealPipelineSearchResults === undefined) this.props.dispatch(loadDealPipelines());
        this.checkDataTableRequirements();
    }

    componentDidUpdate() {
        if (this.props.modalOpen) this.props.dispatch(updateModal());
        this.checkDataTableRequirements();
    }

    checkDataTableRequirements() {
        if (this.props.changeDataTableVisibility)
            this.props.dispatch(
                changeDataTableVisibility(
                    this.props.selectedMultiView,
                    this.props.currentTextFilter,
                    this.props.currentSearch,
                    this.props.fromPersistedState,
                ),
            );
    }

    openPreviewDealSisp(dealId) {
        this.props.dispatch(selectDeal(dealId));
        this.props.dispatch(openPreviewSisp(true));
        EventBusInstance.publish({
            topic: 'show-hoverover-component',
            message: dealId,
            target: 'deals-data-grid-board-preview-show',
        });
    }

    openEditDealSisp(dealId) {
        this.props.dispatch(resetForm());
        this.props.dispatch(selectDeal(dealId));
        this.props.dispatch(openFormSisp('board'));
    }

    closeModalAction() {
        this.props.dispatch(closeModal());
    }

    deleteDealsAction(deals) {
        EventBusInstance.publish({
            topic: 'update-datagrid-rows',
            message: true,
            target: 'deals-data-grid-board-loading',
        });
        this.props.dispatch(deleteDeals(deals));
        this.closeModalAction();
    }

    showDeleteDealsModal(deals) {
        if (!deals || !deals.length) return;

        this.props.dispatch(
            openModal(
                () => (
                    <div>
                        {deals.length > 1 ? (
                            <p>Are you sure that you want to delete these {deals.length} deals?</p>
                        ) : (
                            <p>Are you sure that you want to delete this deal?</p>
                        )}
                        <p>Deleted data cannot be recovered, ever.</p>
                    </div>
                ),
                () => (deals.length > 1 ? 'Delete These Deals' : 'Delete This Deal'),
                () => (
                    <div>
                        <Button variant="danger" onClick={this.deleteDealsAction.bind(this, deals)}>
                            Delete
                        </Button>
                        <Button variant="default" onClick={this.closeModalAction.bind(this)}>
                            Cancel
                        </Button>
                    </div>
                ),
            ),
        );
    }

    updateDealPipelineAndStage(dealIds, pipelineId, stageName) {
        dealIds = dealIds.map(String);

        const dealPipelineEntity = this.props.dealPipelineSearchResults
            ? this.props.dealPipelineSearchResults.find((dealPipeline) => dealPipeline.matches(pipelineId))
            : undefined;
        if (!dealPipelineEntity) return;

        const hasAvailableStages = !!(dealPipelineEntity && dealPipelineEntity.stages);
        const dealPipelineStageEntity =
            stageName && hasAvailableStages
                ? dealPipelineEntity.stages.find((dealPipelineStage) => dealPipelineStage.matches(stageName))
                : undefined;
        if (!dealPipelineStageEntity) return;

        let updatedDeals = this.props.searchResults
            .filter((deal) => dealIds.indexOf(String(deal.id)) > -1)
            .map((deal) => {
                const updatedDeal = deal.clone();

                updatedDeal.dealPipeline = dealPipelineEntity;
                updatedDeal.currentStage = dealPipelineStageEntity;

                return updatedDeal;
            });

        this.props.dispatch(updateDealPipelines(updatedDeals, pipelineId, stageName, dealPipelineStageEntity.id));

        // If singular deal is now closed (won) and doesn't yet have a sale then prompt to add one
        if (updatedDeals.length === 1) {
            const singularDeal = updatedDeals[0];
            if (
                singularDeal.id &&
                singularDeal.currentStage &&
                singularDeal.currentStage.stageState === DealStageState.CLOSED_WON &&
                singularDeal.saleCount === 0
            )
                this.props.dispatch(openSaleLinkModal(singularDeal.id, true));
        }
    }

    loadMoreDeals() {
        this.props.dispatch(
            loadMoreDeals(
                this.props.searchResults ? this.props.searchResults.length : 0,
                this.props.currentTextFilter,
                this.props.currentSearch,
            ),
        );
    }

    board() {
        if (this.props.dealPipelineSearchResults.length === 0) {
            return (
                <p>
                    No deal pipelines, please create one in <a href="/settings/deals/add_pipeline">settings.</a>
                </p>
            );
        }

        const pipelinesToRender =
            this.props.currentSearch && this.props.currentSearch.pipelines.length > 0
                ? this.props.dealPipelineSearchResults.filter(
                      (pipeline) => this.props.currentSearch.pipelines.indexOf(pipeline.id) > -1,
                  )
                : this.props.dealPipelineSearchResults;

        const stageStatesInSearch =
            this.props.currentSearch && this.props.currentSearch.stageStates.length > 0
                ? this.props.currentSearch.stageStates
                : DealStageState.allOptions();

        return (
            <PipelineGroup
                pipelines={pipelinesToRender}
                stageStatesInSearch={stageStatesInSearch}
                deals={this.props.searchResults}
                client={this.props.client}
                dealPreviewAction={this.openPreviewDealSisp.bind(this)}
                dealEditAction={this.openEditDealSisp.bind(this)}
                dealDeleteAction={this.showDeleteDealsModal.bind(this)}
                dealMoveAction={this.updateDealPipelineAndStage.bind(this)}
                loadMoreDealsAction={this.loadMoreDeals.bind(this)}
                moreDealsAvailable={
                    this.props.searchResults
                        ? this.props.searchResults.length < this.props.totalAvailableResults
                        : false
                }
                moreDealsLoading={this.props.moreLoading}
            />
        );
    }

    render() {
        return this.props.selectedMultiView === 'Board' ? (
            <div className="react-board react-deal-board">
                {this.props.error ? (
                    <AlertBanner alertLevel={AlertLevel.DANGER}>{this.props.error.toString()}</AlertBanner>
                ) : null}
                {this.props.boardLoading ? <BoardLoading /> : this.board()}
                <ModalSaleLink />
            </div>
        ) : null;
    }
}

function select(state) {
    let error = undefined;

    let currentDeal = Deal.select(state.deal.allKnownResults, state.deal.selectedId);
    if (currentDeal === undefined) currentDeal = Deal.blank();

    const boardLoading =
        state.deal.searchResults === undefined ||
        !state.deal.resultsLoaded ||
        state.dealPipeline.searchResults === undefined;

    if (state.client.error) error = state.client.error;
    if (state.dealPipeline.error) error = state.dealPipeline.error;
    if (currentDeal.state().error) error = currentDeal.state().error;
    if (state.deal.error) error = state.deal.error;

    return {
        client: state.client.client,
        currentSearch: state.advancedSearch.currentSearch,
        currentTextFilter: state.deal.currentFilter,
        searchResults: state.deal.searchResults,
        totalAvailableResults: state.deal.totalAvailableResults,
        moreLoading: state.deal.moreLoading,
        dealPipelineSearchResults: state.dealPipeline.searchResults,
        deal: currentDeal,
        dealSaving: currentDeal.state().saving,
        boardLoading: boardLoading,
        selectedMultiView: state.board.selectedMultiView,
        changeDataTableVisibility: state.board.changeDataTableVisibility,
        fromPersistedState: state.board.fromPersistedState,
        modalOpen: state.modal.settings.open,
        error: error,
    };
}

DealBoard.propTypes = {};

DealBoard.defaultProps = {};

export default connect(select)(DealBoard);
