import {
    ClickableLink,
    DataGrid,
    DataGridActionBar,
    ExtendedColumn,
    LogLevel,
    SearchQuery,
    showBanner,
    StyledPill,
} from '@sprint/sprint-react-components';
import CSS from 'csstype';
import _ from 'lodash';
import { FunctionComponent, default as React, useContext, useEffect, useState } from 'react';
import { RepositoryFactoryContext } from '../..';
import { ImportRowRequest } from '../../Api/ImportRowRequest';
import SimpleCountsBar from '../../Components/SimpleCountsBar';
import SimpleSearchResultCounts from '../../Components/SimpleSearchResultCounts';
import { HTMLColors, ImportReviewStatus } from '../../Models/Enums';
import ImportRowType from '../../Models/ImportRowType';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
    importId: number;
}

enum ImportReviewColumnKey {
    ID,
    ITEM,
    RESULT,
    NOTES,
}

const ImportReviewTable: FunctionComponent<Props> = (props: Props) => {
    const importRowRepository = useContext(RepositoryFactoryContext).getApiRepository(new ImportRowRequest());

    // State: General
    const [fetchDataSuccess, setFetchDataSuccess] = useState<boolean>(true);

    // State: Columns
    const [columns, setColumns] = useState([] as ExtendedColumn[]);
    const Columns: Record<ImportReviewColumnKey, ExtendedColumn> = {
        [ImportReviewColumnKey.ID]: {
            key: 'id',
            name: 'ID',
            sortable: false,
        },
        [ImportReviewColumnKey.ITEM]: {
            key: 'item',
            name: 'Item',
            sortable: false,
            width: '2fr',
            renderCell: (props) => {
                const item = (props.row as ImportRowType).item;
                return <ClickableLink placeholder={item.value} url={item.url} />;
            },
        },
        [ImportReviewColumnKey.RESULT]: {
            key: 'result',
            name: 'Result',
            sortable: false,
            resizable: false,
            width: '1fr',
            renderCell: (props) => {
                const getStatusColor = (result: string) => {
                    switch (result) {
                        case ImportReviewStatus.IMPORTED:
                            return HTMLColors.SUCCESS;
                        case ImportReviewStatus.DUPLICATE:
                            return HTMLColors.PRIMARY;
                        case ImportReviewStatus.ERROR:
                        default:
                            return HTMLColors.DANGER;
                    }
                };
                const result = (props.row as ImportRowType).result;
                const pillStyle: CSS.Properties = {
                    backgroundColor: getStatusColor(result),
                };
                return <StyledPill cellContent={result} style={pillStyle} />;
            },
        },
        [ImportReviewColumnKey.NOTES]: {
            key: 'notes',
            name: 'Notes',
            sortable: false,
            width: '1fr',
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[ImportReviewColumnKey.ITEM],
        Columns[ImportReviewColumnKey.RESULT],
        Columns[ImportReviewColumnKey.NOTES],
    ];

    const [defaultColumns, setDefaultColumns] = useState<ExtendedColumn[]>(DefaultColumns);

    // DataGridActionBar
    const [filter, setFilter] = useState<string>('');
    const [counts, setCounts] = useState<SimpleSearchResultCounts>({
        currentPage: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalItems: 0,
        totalPages: 0,
    });

    useEffect(() => {
        setColumns([...defaultColumns]);
    }, [defaultColumns]);

    const onGetRows = async (query: SearchQuery) => {
        return importRowRepository
            .search(query, props.importId)
            .then((results: any) => {
                if (results.wasCancelled) return results;
                setCounts(results.counts);
                return results;
            })
            .catch((err: any) => {
                setFetchDataSuccess(false);
                showBanner({
                    message: 'Failed to get Import Rows - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                    dismissable: false,
                });
                return null;
            });
    };

    return fetchDataSuccess ? (
        <>
            <DataGridActionBar
                onFilterChange={setFilter}
                filter={filter}
                filterPlaceholder={props.searchFilterPlaceholder}
                onAddButtonClick={_.noop}
                hideAddButton={true}
                counts={
                    <SimpleCountsBar
                        counts={counts}
                        singularText={props.dataGridEntitySingular}
                        pluralText={props.dataGridEntityPlural}
                    />
                }
            />
            <DataGrid
                uniqueKey={props.dataGridUniqueKey}
                entitySingular={''}
                entityPlural={''}
                basicFilter={filter}
                columns={columns}
                onGetRows={onGetRows}
                onUpdateRow={() => Promise.resolve()}
                addModal={null}
                showAddModal={false}
                onCloseAddModal={_.noop}
                enableDraggableColumns={false}
            />
        </>
    ) : (
        <></>
    );
};

export default ImportReviewTable;
