'use strict';

export const DEALPIPELINES_LOADED = 'dealPipelines/DEALPIPELINES_LOADED';
export const DEALPIPELINES_LOADING_FAILED = 'dealPipelines/DEALPIPELINES_LOADING_FAILED';

export const DEALPIPELINE_NEW = 'dealPipelines/DEALPIPELINE_NEW';
export const DEALPIPELINE_UPDATE_FIELD = 'dealPipelines/DEALPIPELINE_UPDATE_FIELD';
export const DEALPIPELINE_UPDATE_STAGE = 'dealPipelines/DEALPIPELINE_UPDATE_STAGE';
export const DEALPIPELINE_REORDER_STAGE = 'dealPipelines/DEALPIPELINE_REORDER_STAGE';
export const DEALPIPELINE_DELETE_STAGE = 'dealPipelines/DEALPIPELINE_DELETE_STAGE';
export const DEALPIPELINE_SAVING = 'dealPipelines/DEALPIPELINE_SAVING';
export const DEALPIPELINE_SAVED = 'dealPipelines/DEALPIPELINE_SAVED';
export const DEALPIPELINE_SAVING_FAILED = 'dealPipelines/DEALPIPELINE_SAVING_FAILED';
export const DEALPIPELINE_SELECT = 'dealPipelines/DEALPIPELINE_SELECT';
export const DEALPIPELINE_LOADING = 'dealPipelines/DEALPIPELINE_LOADING';
export const DEALPIPELINE_LOADED = 'dealPipelines/DEALPIPELINE_LOADED';
export const DEALPIPELINE_LOADING_FAILED = 'dealPipelines/DEALPIPELINE_LOADING_FAILED';
export const DEALPIPELINE_INVALIDATED = 'dealPipelines/DEALPIPELINE_INVALIDATED';
export const DEALPIPELINE_DELETING = 'dealPipelines/DEALPIPELINE_DELETING';
export const DEALPIPELINE_DELETED = 'dealPipelines/DEALPIPELINE_DELETED';