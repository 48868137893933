import {
    AppEvent,
    DataGridActionBar,
    EventBusInstance,
    filter as filterTypes,
    FlexibleFilterSISP,
    LogLevel,
    Modal,
    ModalType,
    SearchQuery,
    SearchQueryBuilder,
    showBanner,
    User,
} from '@sprint/sprint-react-components';
import React, { FunctionComponent, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import BoardLoading from '../../../../CommonComponents/Board/BoardLoading';
import CampusModal from '../../../../CommonComponents/Modal/CampusModal';
import Prompt from '../../../../CommonComponents/Prompt/Prompt';
import DealPipeline from '../../../../Entities/Deal/DealPipeline/DealPipeline';
import { switchMultiView } from '../../../Deals/Actions/Actions';
import {
    actionDealDataGridPipelineFilter,
    updateLoadedEntitiesDataGrid,
} from '../../../Deals/Actions/AdvancedSearchActions';
import DealBoard from '../../../Deals/Components/DealBoard';
import DataGridRepository from '../../Api/DataGridRepository';
import { DealPipelineRequest } from '../../Api/DealPipelineRequest';
import ExtraActionBar from '../../Components/ExtraActionsBar';
import { generateSimpleFilterRequestBody, SimpleFilterRequest } from '../../Components/Filters';
import { SavedInboundView } from '../../Components/SavedInboundView';
import SimpleCountsBar from '../../Components/SimpleCountsBar';
import SimpleSearchResultCounts from '../../Components/SimpleSearchResultCounts';
import ViewsBar from '../../Components/ViewsBar';
import { DealsBoardHelpers } from '../../HelperFunctions/DealsBoardHelpers';
import FilterLocalStorageManager from '../../HelperFunctions/FilterLocalStorageManager';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import { LimitsContext, RepositoryFactoryContext, UserContext } from '../../index';
import { Deal } from '../../Models/Deal';
import { UniqueKeyType } from '../../Models/Enums';
import {
    ActionBarMeta,
    AddSispMeta,
    DataGridMeta,
    EditSispMeta,
    PreviewSispMeta,
    PromptMeta,
    ViewsMeta,
} from '../CampusDataGrid';
declare function dictionaryValue(key: string): string;

interface Props {
    repository: DataGridRepository<any>;
    actionBarMeta: ActionBarMeta;
    addSispMeta: AddSispMeta;
    editSispMeta: EditSispMeta;
    previewSispMeta: PreviewSispMeta;
    dataGridMeta: DataGridMeta;
    viewsMeta: ViewsMeta;
    promptMeta: PromptMeta;
}

const DealsBoardWrapper: FunctionComponent<Props> = (props: Props) => {
    const dealPipelinesRepository = useContext(RepositoryFactoryContext).getApiRepository(new DealPipelineRequest());
    const dealPipelinesIdCollection = useRef<number[]>([]);

    const [reload, setReload] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [dealPipelineIdsLoaded, setDealPipelineIdsLoaded] = useState<boolean>(false);

    // Base Data
    const limits = useContext(LimitsContext); // Remove and refactor this to use the Limits API endpoint
    const user: User = useContext(UserContext);

    // State: General
    const [modal, setModal] = useState(null as ReactNode);
    const [showAddModal, setShowAddModal] = useState<boolean>(props.addSispMeta?.showSisp ?? false);

    const [listLimitReached, setListLimitReached] = useState<boolean>(false);
    const [currentUsage, setCurrentUsage] = useState<number | null>(null);
    const [currentLimit, setCurrentLimit] = useState<number | null>(null);

    // States: FilterSISP
    const [filterSispShown, setFilterSispShown] = useState(false);
    const [selectedFilterState, setSelectedFilterState] = useState<filterTypes.SelectedFilters[]>([]);

    // State: Views
    const [activeView, setActiveView] = useState<SavedInboundView | null>(null);
    const [addButtonLocked, setAddButtonLocked] = useState<boolean>(false);

    // State: Counts + Prompt
    const [disableToolbar, setDisableToolbar] = useState<boolean>(true);
    const [showPromptDialogue, setShowPromptDialogue] = useState<boolean | null>(null);

    // DataGridActionBar
    const [filter, setFilter] = useState<string>('');
    const [counts, setCounts] = useState<SimpleSearchResultCounts>({
        currentPage: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalItems: 0,
        totalPages: 0,
    });

    const supportEmail = dictionaryValue('company.email_support');
    const mailToSupport = `mailto:${supportEmail}`;
    const salesPhoneLine = dictionaryValue('company.telephone_support');

    const getDealPipelines = async () => {
        const query = new SearchQuery(1, 100);
        return dealPipelinesRepository
            .search(query)
            .then((results: any) => {
                if (results.wasCancelled) return results;
                dealPipelinesIdCollection.current = results.results.map((pipeline: DealPipeline) => pipeline.id);
                setDealPipelineIdsLoaded(true);
            })
            .catch(() => {
                return null;
            });
    };

    useEffect(() => {
        setDisableToolbar(loading || (showPromptDialogue ?? true));
        if (showPromptDialogue) {
            setFilterSispShown(false);
            setActiveView(null);
        }
    }, [showPromptDialogue]);

    useEffect(() => {
        setDisableToolbar(loading || (showPromptDialogue ?? true));
    }, [loading]);

    useEffect(() => {
        if (loading) return; // If we are still in a loading state we don't want to show the prompts
        if (showPromptDialogue) {
            setShowPromptDialogue(props.promptMeta != undefined && counts.totalItems == 0);
        }
        if (counts.totalItems == 0) {
            const hasBasicFilter: boolean = filter.length != 0 ?? false;
            const hasSelectedFilters: boolean = selectedFilterState ? selectedFilterState.length != 0 : false;
            setShowPromptDialogue(props.promptMeta != undefined && !(hasBasicFilter || hasSelectedFilters));
        } else {
            setDisableToolbar(false);
        }
    }, [counts]);

    // Loading filters from local storage if available
    const filterLocalStorageKey = FilterLocalStorageManager.makeKey(props.dataGridMeta.uniqueKey, user.id);

    useEffect(() => {
        if (activeView) FilterLocalStorageManager.clear(filterLocalStorageKey);
    }, [activeView]);

    useEffect(() => {
        if (
            (selectedFilterState && selectedFilterState.length == 0) ||
            (activeView && activeView?.id != 0) ||
            showPromptDialogue
        )
            return;
        FilterLocalStorageManager.set(filterLocalStorageKey, selectedFilterState);
    }, [selectedFilterState]);

    useEffect(() => {
        if (activeView || showPromptDialogue) return;
        setSelectedFilterState(FilterLocalStorageManager.parseFromLocalStorage(filterLocalStorageKey));
        getDealPipelines();
    }, []);
    // End filter persistence

    useEffect(() => {
        let pipelineIdCollection: number[] = [];
        if (dealPipelinesIdCollection.current.length > 0) {
            if (selectedFilterState) {
                pipelineIdCollection = DealsBoardHelpers.extractPipelinesFromFilter(
                    selectedFilterState,
                    dealPipelinesIdCollection.current,
                );
            } else {
                pipelineIdCollection = dealPipelinesIdCollection.current;
            }
        }
        dispatch(actionDealDataGridPipelineFilter(pipelineIdCollection));
    }, [selectedFilterState, dealPipelinesIdCollection.current]);

    const handleAddButtonClick = () => {
        if (addButtonLocked) {
            setModal(
                <Modal
                    title={`You Have Reached Your ${props.dataGridMeta.entityPlural} Limit`}
                    close={() => setModal(null)}
                    isOpen={true}
                    type={ModalType.INFO}
                >
                    <p>
                        You have up to{' '}
                        <strong>{limits[props.dataGridMeta.entityPlural.toLowerCase() + '_limit']}</strong>{' '}
                        {props.dataGridMeta.entityPlural} available and you're currently using{' '}
                        <strong>{counts.totalItems}</strong>.
                    </p>
                </Modal>,
            );
        } else {
            setShowAddModal(true);
        }
    };

    const listLimitNotifications = () => {
        if (props.viewsMeta) {
            props.viewsMeta
                .checkLimitDelegate()
                .then((res: any) => {
                    setListLimitReached(
                        res.inbound_list_limit ? res.inbound_list_usage >= res.inbound_list_limit : false,
                    );
                    setCurrentUsage(res.inbound_list_usage);
                    setCurrentLimit(res.inbound_list_limit);
                    if (res.show_alert) {
                        showBanner({
                            message: '<p class="alert-usage-warning">' + res.alert_message + '</p>',
                            level: LogLevel.ERROR,
                        });
                    }
                })
                .catch((err: any) => {
                    showBanner({
                        message: err.message,
                        level: LogLevel.ERROR,
                    });
                });
        }
    };

    // Legacy DealBoard Requirements
    const dispatch = useDispatch();
    useEffect(() => {
        if (reload) {
            setReload(false);
        }

        const query = new SearchQueryBuilder().withFilter(filter).build();
        const simpleFilterRequest: SimpleFilterRequest = {
            filterRequest: generateSimpleFilterRequestBody(selectedFilterState ?? []),
            ...query.toPOJO(),
            limit: 10000,
        };
        dispatch(updateLoadedEntitiesDataGrid(simpleFilterRequest, props.repository) as any);
    }, [filter, selectedFilterState, reload]);

    // On Did Mount
    useEffect(() => {
        setLoading(!dealPipelineIdsLoaded);
    }, [dealPipelineIdsLoaded]);

    useEffect(() => {
        dispatch(switchMultiView('Board'));
        // Handle creation lockout
        listLimitNotifications();
        if (props.dataGridMeta.createButtonLocked) {
            setAddButtonLocked(true);
        } else {
            setAddButtonLocked(false);
        }

        EventBusInstance.subscribe('update-datagrid-rows', (event: AppEvent<SimpleSearchResultCounts>) => {
            if (event.target !== 'deals-data-grid-board-counts') return;
            setCounts(event.message);
        });

        EventBusInstance.subscribe('update-datagrid-rows', (event: AppEvent<boolean>) => {
            if (event.target !== 'deals-data-grid-board-loading') return;
            setLoading(event.message);
        });

        EventBusInstance.subscribe('update-datagrid-rows', (event: AppEvent<boolean>) => {
            if (event.target !== 'deals-data-grid-board-delete') return;
            setReload(true);
        });

        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<number>) => {
            if (event.target != 'deals-data-grid-board-preview-show') return;
            const query = new SearchQueryBuilder().build();
            query.setExtendedParameters({
                dealId: event.message,
            });
            props.repository
                .search(query)
                .then((results: any) => {
                    return results.results.map((deal: Deal) => {
                        EventBusInstance.publish({
                            topic: 'show-hoverover-component',
                            message: deal,
                            target: props.previewSispMeta.key,
                        });
                    });
                })
                .catch((err: any) => {
                    return null;
                });
        });
    }, []);

    useEffect(() => {
        if (activeView) {
            // filter configuration
            if (JSON.stringify(selectedFilterState) !== JSON.stringify(activeView.selectedFilters)) {
                setSelectedFilterState(
                    (activeView.selectedFilters as filterTypes.SelectedFilters[]) ??
                        ([] as filterTypes.SelectedFilters[]),
                );
            }
        }
    }, [activeView]);
    return (
        <div id="DealBoard">
            <props.previewSispMeta.sisp uniqueKey={props.previewSispMeta.key} />
            <props.addSispMeta.sisp
                shown={showAddModal}
                onClose={() => setShowAddModal(false)}
                onSuccess={() => {
                    setShowPromptDialogue(false);
                    setReload(true);
                    return true;
                }}
                uniqueKey={props.addSispMeta.key}
            />
            <props.editSispMeta.sisp
                onClose={() => setShowAddModal(false)}
                onSuccess={() => {
                    setShowPromptDialogue(false);
                    setReload(true);
                    return true;
                }}
                uniqueKey={UniqueKeyBuilder.make(props.dataGridMeta.uniqueKey, UniqueKeyType.EDIT_SISP)}
            />
            {props.actionBarMeta?.extraActionBarMeta?.filterMeta && (
                <FlexibleFilterSISP
                    isOpen={filterSispShown}
                    onCancel={() => {
                        setFilterSispShown(false);
                    }}
                    onFilterUpdated={(selectedFilters) => {
                        if (
                            selectedFilters &&
                            JSON.stringify(selectedFilters) !== JSON.stringify(selectedFilterState)
                        ) {
                            setSelectedFilterState(selectedFilters);
                        }
                    }}
                    selectedFilters={selectedFilterState}
                    filterSections={props.actionBarMeta?.extraActionBarMeta?.filterMeta.fieldFilterSections}
                    defaultActiveKey={props.actionBarMeta?.extraActionBarMeta?.filterMeta.defaultActiveKey}
                />
            )}
            <div
                style={{
                    opacity: disableToolbar ? 0.5 : 1,
                    pointerEvents: disableToolbar ? 'none' : 'auto',
                }}
            >
                {props.viewsMeta && (
                    <ViewsBar
                        entityNamePlural={props.dataGridMeta.entityPlural}
                        targetDisplayStringSingular={'Filter'}
                        targetDisplayStringPlural={'Filters'}
                        count={counts?.totalItems ?? 0}
                        request={props.viewsMeta.request}
                        activeView={activeView || props.viewsMeta.defaultView}
                        onViewSelected={(view) => {
                            if (view === undefined)
                                return setActiveView(props.viewsMeta ? props.viewsMeta.defaultView : null);
                            setActiveView(view);
                        }}
                        onViewUpdated={() => listLimitNotifications()}
                        viewsTarget={props.viewsMeta.entityTarget}
                        selectedColumns={[]}
                        selectedFiltersState={selectedFilterState}
                        listLimitReached={listLimitReached}
                        limitReachedHandler={() =>
                            setModal(
                                <Modal
                                    title={'You Have Reached Your Lists Limit'}
                                    close={() => setModal(null)}
                                    isOpen={true}
                                    type={ModalType.INFO}
                                >
                                    <p>
                                        You are using <strong>{currentUsage}</strong> out of your plan's{' '}
                                        <strong>{currentLimit}</strong> {props.viewsMeta?.limitReachedListName} list
                                        limit.
                                    </p>
                                    <p>
                                        But, don't worry, it's easy to upgrade! Give sales a call on{' '}
                                        <strong>{salesPhoneLine}</strong> or drop us an email at{' '}
                                        <strong>
                                            <a href={mailToSupport}>{supportEmail}</a>
                                        </strong>
                                    </p>
                                </Modal>,
                            )
                        }
                        pinnedFirstTab={props.viewsMeta.pinnedFirstTab}
                        savedViewType={props.viewsMeta.savedViewType}
                    />
                )}
                {props.actionBarMeta && (
                    <DataGridActionBar
                        onFilterChange={setFilter}
                        filter={filter}
                        filterPlaceholder={props.actionBarMeta.searchPlaceHolder}
                        onAddButtonClick={() => handleAddButtonClick()}
                        addButtonLocked={addButtonLocked}
                        hideAddButton={false}
                        addButtonPlaceholder={['Create', props.dataGridMeta.entitySingular].join(' ')}
                        counts={
                            props.actionBarMeta.includeCounts ? (
                                <SimpleCountsBar
                                    counts={counts}
                                    singularText={props.dataGridMeta.entitySingular}
                                    pluralText={props.dataGridMeta.entityPlural}
                                />
                            ) : undefined
                        }
                    >
                        {props.actionBarMeta.extraActionBarMeta && (
                            <ExtraActionBar
                                filterMeta={
                                    props.actionBarMeta.extraActionBarMeta.filterMeta
                                        ? {
                                              filterSispShown: filterSispShown,
                                              setFilterSispShown: setFilterSispShown,
                                              onClearFilter: () => {
                                                  setActiveView(props.viewsMeta ? props.viewsMeta.defaultView : null);
                                                  setSelectedFilterState([]);
                                                  FilterLocalStorageManager.clear(filterLocalStorageKey);
                                              },
                                              currentFilters: selectedFilterState,
                                          }
                                        : undefined
                                }
                            />
                        )}
                    </DataGridActionBar>
                )}
            </div>
            {!loading && (
                <>
                    {!showPromptDialogue && (
                        <>
                            <CampusModal />
                            <DealBoard />
                        </>
                    )}
                    {showPromptDialogue && (
                        <>
                            <div style={{ marginTop: '1rem' }}>
                                <Prompt
                                    imgSrc={props.promptMeta.icon}
                                    imgHeight={180}
                                    header="No Deals Yet"
                                    subHeader={
                                        <>
                                            <p>
                                                Add a new deal{' '}
                                                <a
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setShowAddModal(true);
                                                    }}
                                                >
                                                    here
                                                </a>
                                                .
                                            </p>
                                            {props.promptMeta.helpCentreLink && (
                                                <p>
                                                    Learn more about {props.dataGridMeta.entityPlural.toLowerCase()} in
                                                    the{' '}
                                                    <a target="_blank" href={props.promptMeta?.helpCentreLink}>
                                                        help centre
                                                    </a>
                                                    .
                                                </p>
                                            )}
                                            <div className="prompt-btn-container">
                                                <Button
                                                    className="btn btn-primary"
                                                    onClick={() => {
                                                        setShowAddModal(true);
                                                    }}
                                                >
                                                    {['Create', props.dataGridMeta.entitySingular].join(' ')}
                                                </Button>
                                            </div>
                                        </>
                                    }
                                />
                            </div>
                        </>
                    )}
                </>
            )}
            {loading && <BoardLoading />}
        </div>
    );
};

export default DealsBoardWrapper;
