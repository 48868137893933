import { AppEvent, EventBusInstance, Modal, ModalType } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import LimitIssue from '../../Models/LimitIssue';
import './LimitReachedModal.scss';
declare function dictionaryValue(key: string): string;

interface Props {
    uniqueKey: string;
    limitNameSingular: string;
    limitNamePlural: string;
}

const SingleLimitReachedModal: FunctionComponent<Props> = (props: Props) => {
    // State: General
    const [shown, setShown] = useState<boolean>(false);
    const [usedCount, setUsedCount] = useState<number>();
    const [limit, setLimit] = useState<number>();

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<LimitIssue>) => {
            if (event.target !== props.uniqueKey) return;
            setUsedCount(event.message.used_count);
            setLimit(event.message.limit);
            setShown(true);
        });
    }, [shown]);

    const supportEmail = dictionaryValue('company.email_support');
    const mailToSupport = `mailto:${supportEmail}`;
    const salesPhoneLine = dictionaryValue('company.telephone_support');

    return (
        <Modal
            className="limit-reached-modal"
            close={() => setShown(false)}
            isOpen={shown}
            title={['You Have Reached Your', props.limitNamePlural, 'Limit'].join(' ')}
            type={ModalType.UPGRADE}
            footerOverride={
                <>
                    <Button
                        variant="primary"
                        onClick={(event) => {
                            event.preventDefault();
                            window.location.href = mailToSupport;
                        }}
                    >
                        Upgrade
                    </Button>
                    <Button variant="default" onClick={() => setShown(false)}>
                        Close
                    </Button>
                </>
            }
        >
            <>
                <p>
                    You are using <strong>{usedCount}</strong> out of your plan's <strong>{limit}</strong>{' '}
                    {props.limitNameSingular.toLowerCase()} limit.
                </p>
                <p>
                    But, don't worry, it's easy to upgrade!&nbsp;
                    <a href={mailToSupport} className="rdg-link">
                        Click here
                    </a>
                    &nbsp;to compare and upgrade your plan.
                </p>
                <p>
                    Alternatively, give sales a call on <strong>{salesPhoneLine}</strong> or drop us an email at&nbsp;
                    <a href={`mailto:${supportEmail}`} className="rdg-link">
                        {supportEmail}
                    </a>
                </p>
            </>
        </Modal>
    );
};

export default SingleLimitReachedModal;
