'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import ConnectedSavedSearchSelect from "../../../Entities/SavedSearch/Components/ConnectedSavedSearchSelect";

class LoadSavedSearchControl extends React.Component {

    render() {
        return <div className="react-adv--ss-field-control">
            <ConnectedSavedSearchSelect
                selectedValue={this.props.selectedValue}
                component={this.props.component}
                open={this.props.open}
                toggle={this.props.toggle}
                updateSelectedOption={this.props.updateSelectedOption}
                deleteCurrentlySelectedSearch={this.props.deleteCurrentlySelectedSearch}
                entityName={this.props.entityName}
            />
        </div>;
    }

}

LoadSavedSearchControl.propTypes = {
    selectedValue: PropTypes.number,
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    updateSelectedOption: PropTypes.func.isRequired,
    component: PropTypes.string,
    deleteCurrentlySelectedSearch: PropTypes.func,
    entityName: PropTypes.string.isRequired
};

export default LoadSavedSearchControl;