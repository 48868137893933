'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {SAVE_CURRENT_SEARCH_CONTROL} from "../AdvancedSearchBar";
import SaveCurrentSearchControl from "./SaveCurrentSearchControl";
import SavedSearch from "../../../Entities/SavedSearch/SavedSearch";

class PageHeader extends React.Component {

    clearSearch(e) {
        e.preventDefault();
        this.props.clearSearch();
    }

    savedSearchTitle() {
        if (!this.props.currentSavedSearch) return null;
        return <small style={{marginLeft: '12px'}}>{this.props.currentSavedSearch.forLabel()}</small>;
    }

    clearSearchLink() {
        if (!this.props.showClearSearch || !this.props.clearSearch) return null;
        return <small style={{marginLeft: '12px', fontSize: '55%'}}><a href="#" onClick={this.clearSearch.bind(this)}>Clear Filter</a></small>
    }

    render() {
        return <h1 className="page-header">
            {this.props.pageTitle}
            {this.props.viewControls}
            {this.savedSearchTitle()}
            {this.clearSearchLink()}
        </h1>;
    }

}

PageHeader.propTypes = {
    pageTitle: PropTypes.string,
    currentSavedSearch: PropTypes.instanceOf(SavedSearch),
    clearSearch: PropTypes.func,
    showClearSearch: PropTypes.bool,
    viewControls: PropTypes.element
};

PageHeader.defaultProps = {
    pageTitle: '',
    viewControls: null
};

export default PageHeader;