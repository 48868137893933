import React, { FunctionComponent } from 'react';

interface Props {
    message: string;
}

const Error: FunctionComponent<Props> = (props: Props) => {
    return <div className="alert alert-error">{props.message}</div>;
};

export default Error;
