import {
    Checkbox,
    DropDown,
    isValidString,
    LogLevel,
    OptionTypeBase,
    showBanner,
    Sisp,
} from '@sprint/sprint-react-components';
import { format } from 'date-fns';
import _ from 'lodash';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { EngagementMethodsRequest } from '../../Api/EngagementMethodsRequest';
import { RepositoryFactoryContext } from '../../index';
import EngagementMethodType from '../../Models/EngagementMethodType';
import { ProcessingGround } from '../../Models/Enums';
import './EngagementMethodsSisps.scss';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
}

const EngagementMethodsAddSisp: FunctionComponent<Props> = (props: Props) => {
    const engagementMethodsRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new EngagementMethodsRequest(),
    );

    const focusRef = useRef<HTMLInputElement>(null);

    const [newEngagementMethod, setNewEngagementMethod] = useState<string>('');
    const [newDefault, setNewDefault] = useState<boolean>(false);
    const [newProcessingGround, setNewProcessingGround] = useState<OptionTypeBase | null>(null);
    const [newEngagementMethodDate, setNewEngagementMethodDate] = useState<Date>();

    const validationStateDefault = {
        engagementMethod: true,
        processingGround: true,
    };
    const [validationState, setValidationState] = useState(validationStateDefault);

    useEffect(() => {
        if (props.shown) {
            focusRef.current?.focus();
        }
    }, [props.shown]);

    const reset = () => {
        setNewEngagementMethod('');
        setNewDefault(false);
        setNewProcessingGround(null);
        setNewEngagementMethodDate(undefined);
        setValidationState(validationStateDefault);
    };

    const validate = async (): Promise<boolean> => {
        const newValidationState = {
            engagementMethod: !!newEngagementMethod && isValidString(newEngagementMethod),
            processingGround: !!newProcessingGround,
        };

        setValidationState(newValidationState);
        return _.every(newValidationState);
    };

    const handleAddRow = async (): Promise<boolean> => {
        const EngagementMethod: EngagementMethodType = {
            engagement_method: newEngagementMethod,
            processing_ground: newProcessingGround!.value,
            default_date: newEngagementMethodDate ? format(newEngagementMethodDate, 'dd/MM/yyyy') : undefined,
            is_default: newDefault,
        };

        return engagementMethodsRepository
            .create(EngagementMethod)
            .then(props.onSuccess)
            .then(async (success) => {
                reset();
                return success;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to create Engagement Method - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        if ((await validate()) && (await handleAddRow())) props.onClose();
    };

    return (
        <Sisp
            className="engagement-methods-sisp-add"
            isOpen={props.shown}
            onSubmit={handleAddRow}
            onCancel={() => {
                reset();
                props.onClose();
            }}
            validate={validate}
        >
            <h4>Add an Engagement Method</h4>
            <Form onSubmit={onSubmitForm}>
                <Form.Group>
                    <Form.Label>
                        Engagement Method <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        ref={focusRef}
                        type="text"
                        isInvalid={!validationState.engagementMethod}
                        value={newEngagementMethod || ''}
                        onChange={(event) => {
                            setNewEngagementMethod(event.target.value);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {!validationState.engagementMethod && 'This field is required.'}
                    </Form.Control.Feedback>
                    <Form.Group style={{ marginTop: '15px' }}>
                        <Checkbox
                            label="Make this the default engagement method"
                            isChecked={newDefault}
                            onChange={(event) => setNewDefault(event.target.checked)}
                        />
                    </Form.Group>
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        Processing Ground <span className="required-field-marker">*</span>
                    </Form.Label>
                    <DropDown
                        value={newProcessingGround}
                        isInvalid={false}
                        onChange={(selected: OptionTypeBase) => {
                            setNewProcessingGround(selected);
                        }}
                        options={Object.values(ProcessingGround).map((value: string) => {
                            return {
                                value: value,
                                label: value,
                            };
                        })}
                        menuPosition="fixed"
                    />
                    <Form.Control.Feedback type="invalid">
                        {!validationState.processingGround && 'This field is required.'}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="engagement-method-date">
                    <Form.Label>Engagement Method Date</Form.Label>
                    <br />
                    <Form.Text muted>Does your Engagement Method relate to a distinct date?</Form.Text>
                    <DatePicker
                        className="engagement-method-date-picker"
                        selected={newEngagementMethodDate}
                        onChange={(date: Date) => setNewEngagementMethodDate(date)}
                        isClearable={true}
                        dateFormat="dd/MM/yyyy"
                    />
                </Form.Group>
            </Form>
        </Sisp>
    );
};

export default EngagementMethodsAddSisp;
