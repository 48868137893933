import { SmtpAccountProvider } from '../../Models/SmtpProvider';

const PLAIN_SMTP = { value: SmtpAccountProvider.PLAIN_SMTP, label: 'SMTP' };
const MICROSOFT = { value: SmtpAccountProvider.MICROSOFT, label: 'Microsoft' };
const GOOGLE = { value: SmtpAccountProvider.GOOGLE, label: 'Google' };

export const smtpOptions = [PLAIN_SMTP, MICROSOFT, GOOGLE];

export const toSmtpOption = (value: SmtpAccountProvider | string) => {
    switch (value) {
        case SmtpAccountProvider.MICROSOFT:
        case 'microsoft':
            return smtpOptions[1];
        case SmtpAccountProvider.GOOGLE:
        case 'google':
            return smtpOptions[2];
        case SmtpAccountProvider.PLAIN_SMTP:
        case 'smtp':
        default:
            return smtpOptions[0];
    }
};
