import { EventBusInstance, ExtendedColumn, showBanner } from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { FunctionComponent, useContext, useRef } from 'react';
import { Badge } from 'react-bootstrap';
import { RenderCellProps } from 'react-data-grid';
import { RepositoryFactoryContext } from '../..';
import { GlossaryRequest } from '../../Api/GlossaryRequest';
import GlossaryItem from '../../Models/GlossaryItem';
import CampusDataGrid, { ActionBarMeta, DataGridMeta, FilterBarMeta } from '../CampusDataGrid';
import GlossarySisp from './GlossarySisp';
import './GlossaryTable.scss';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

enum GlossaryColumnKey {
    ITEM,
    ABBREVIATION,
    TAGS,
}

const dataSetTags = ['UK', 'US', 'International', 'All'];

const GlossaryTable: FunctionComponent<Props> = (props: Props) => {
    const glossaryRepository = useContext(RepositoryFactoryContext).getApiRepository(new GlossaryRequest());
    const allTags = useRef<string[]>();

    const Columns: Record<GlossaryColumnKey, ExtendedColumn> = {
        [GlossaryColumnKey.ITEM]: {
            key: 'item',
            name: 'Item',
            sortable: true,
            renderCell: (props: RenderCellProps<unknown>) => {
                const item = (props.row as GlossaryItem).item;
                return (
                    <span
                        onClick={() => {
                            EventBusInstance.publish({
                                topic: 'update-datagrid-rows',
                                message: props.row as GlossaryItem,
                                target: 'handle-glossary-item-click',
                            });
                        }}
                        className="rdg-link"
                        style={{ cursor: 'pointer' }}
                    >
                        {item}
                    </span>
                );
            },
            width: '2fr',
        },
        [GlossaryColumnKey.ABBREVIATION]: {
            key: 'abbreviation',
            name: 'Abbreviation',
            sortable: true,
            width: '1fr',
            minWidth: 120,
        },
        [GlossaryColumnKey.TAGS]: {
            key: 'tags',
            name: 'Tags',
            sortable: false,
            renderCell: (props: RenderCellProps<unknown>) => {
                const tags = (props.row as GlossaryItem).tags;
                return (
                    <span>
                        {_.map(tags, (tag, i) => (
                            <Badge key={'tag-' + i} variant="secondary" style={{ marginRight: '5px' }}>
                                {tag}
                            </Badge>
                        ))}
                    </span>
                );
            },
            width: '3fr',
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[GlossaryColumnKey.ITEM],
        Columns[GlossaryColumnKey.ABBREVIATION],
        Columns[GlossaryColumnKey.TAGS],
    ];

    const dataGridMeta: DataGridMeta = {
        uniqueKey: props.dataGridUniqueKey,
        entitySingular: props.dataGridEntitySingular,
        entityPlural: props.dataGridEntityPlural,
        columnOptions: Columns,
        defaultColumns: DefaultColumns,
        draggableColumns: true,
        frozenColumns: [],
        hideSelect: true,
    };

    const filterBarMeta: FilterBarMeta = {
        filters: [
            {
                title: 'Tag',
                name: 'tag',
                isSearchable: true,
                loadOptions: (filter: string) => {
                    if (allTags.current) {
                        // We already have stored tags, lets use these
                        const filteredTags = filter
                            ? _.filter(allTags.current, (tag) => tag.toLowerCase().includes(filter.toLowerCase()))
                            : allTags.current;

                        return Promise.resolve({
                            options: _.map(filteredTags, (tag: string) => {
                                return { value: tag, label: tag };
                            }),
                        });
                    } else {
                        // Fetch tags and filter
                        return glossaryRepository
                            .post_action('fetch_tags')
                            .then((results: any) => {
                                // Sort result alphabetically
                                let tags: string[] = results.data.sort();
                                // Remove dataset tags
                                tags = _.filter(tags, (tag) => !dataSetTags.includes(tag));
                                // Store all tags so we don't need to request them again
                                allTags.current = tags;
                                if (filter) {
                                    tags = _.filter(tags, (tag) => tag.toLowerCase().includes(filter.toLowerCase()));
                                }
                                return {
                                    options: _.map(tags, (tag: string) => {
                                        return { value: tag, label: tag };
                                    }),
                                };
                            })
                            .catch((err: any) => {
                                showBanner({
                                    message: 'Failed to get tags - ' + (err?.message ?? err),
                                });
                            });
                    }
                },
            },
            {
                title: 'Dataset',
                name: 'dataset',
                options: _.map(dataSetTags, (tag: string) => {
                    return { value: tag, label: tag };
                }),
            },
        ],
    };

    const actionBarMeta: ActionBarMeta = {
        searchPlaceHolder: props.searchFilterPlaceholder,
        includeCounts: true,
        hideAddButton: true,
        filterBarMeta: filterBarMeta,
    };

    return (
        <>
            <GlossarySisp />
            <CampusDataGrid repository={glossaryRepository} actionBarMeta={actionBarMeta} dataGridMeta={dataGridMeta} />
        </>
    );
};
export default GlossaryTable;
