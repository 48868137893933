import { faLock, faSlidersH } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    CheckboxDropdown,
    ExportableField,
    OptionTypeBase,
    filter as filterTypes,
} from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Options } from 'react-select';
import DataGridRepository from '../Api/DataGridRepository';
import './ExtraActionsBar.scss';
import { SimpleFilterRequest } from './Filters';
import { SimpleExportProgressModal } from './SimpleExportProgressModal';

interface Props {
    filterMeta?: FilterMeta;
    optionalColumnsMeta?: OptionalColumnsMeta;
    exportMeta?: ExportMeta;
    customActions?: CustomAction[];
}

export interface FilterMeta {
    filterSispShown: boolean;
    setFilterSispShown: (shown: boolean) => void;
    onClearFilter: () => void;
    currentFilters?: filterTypes.SelectedFilters[];
}

export interface OptionalColumnsMeta {
    selectedColumnsForDropdown: Options<OptionTypeBase>;
    setEnabledOptionalColumns: (enabled: Options<OptionTypeBase>) => void;
    getEditColumnOptions: (selected: Options<OptionTypeBase>) => Options<OptionTypeBase>;
}

export interface ExportMeta {
    entity: string;
    repository: DataGridRepository<any>;
    filters: SimpleFilterRequest;
    totalRecords: number;
    exportableFields: ExportableField[];
    selectedExportFieldKeys: string[];
    externalParameters?: any;
    disclaimer?: React.ReactNode;
    disclaimerTitle?: string;
    allSelected?: boolean;
    noProgress?: boolean;
    /**
     * Allows you to display the export as disabled, useful when certain conditions need to be met in order to export.
     */
    exportDisabled?: boolean;
}

export interface CustomAction {
    name: string;
    action: () => void;
}

const ExtraActionBar = (props: Props) => {
    const [exportModalShown, setExportModalShown] = useState(false);

    const getCurrentFiltersCount = (): string => {
        const filters = props.filterMeta?.currentFilters;
        let total = 0;
        // Sections
        _.forEach(filters, (filterSection: filterTypes.SelectedFilters) => {
            // OR Blocks
            _.forEach(filterSection.filters, (andFilters: filterTypes.FieldFilter[]) => {
                // AND Blocks
                if (!_.isEmpty(andFilters)) {
                    _.forEach(andFilters, (filter: filterTypes.FieldFilter) => {
                        if (!_.isEmpty(filter)) total++;
                    });
                }
            });
        });
        return String(total > 9 ? '9+' : total);
    };

    return (
        <div className="people-extra-actions-bar">
            <div style={{ display: 'flex' }}>
                {props.filterMeta && (
                    <div className="filter-controls">
                        <ButtonGroup>
                            <Button
                                data-cy="open-filter-sisp-button"
                                variant="default"
                                onClick={() => {
                                    if (props.filterMeta) {
                                        props.filterMeta.setFilterSispShown(!props.filterMeta.filterSispShown);
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faSlidersH} className="icon" />
                                &nbsp; Filters{' '}
                                {props.filterMeta.currentFilters &&
                                props.filterMeta.currentFilters.length > 0 &&
                                getCurrentFiltersCount() != '0'
                                    ? '(' + getCurrentFiltersCount() + ')'
                                    : ''}
                            </Button>
                            <Button variant="default" onClick={props.filterMeta.onClearFilter}>
                                Clear All
                            </Button>
                        </ButtonGroup>
                    </div>
                )}
                {props.optionalColumnsMeta && (
                    <CheckboxDropdown
                        className="edit-columns-dropdown"
                        isInvalid={false}
                        selected={props.optionalColumnsMeta.selectedColumnsForDropdown}
                        onChange={props.optionalColumnsMeta.setEnabledOptionalColumns}
                        getOptions={props.optionalColumnsMeta.getEditColumnOptions}
                        placeholder="Edit Columns"
                    />
                )}
                {props.exportMeta && (
                    <>
                        {!props.exportMeta.exportDisabled && (
                            <SimpleExportProgressModal
                                entity={props.exportMeta.entity}
                                filter={props.exportMeta.filters}
                                repository={props.exportMeta.repository}
                                selectedFields={props.exportMeta.selectedExportFieldKeys ?? []}
                                exportableFields={props.exportMeta.exportableFields}
                                modalShown={exportModalShown}
                                closeModal={() => setExportModalShown(false)}
                                totalRecords={props.exportMeta.totalRecords}
                                setExportModalShow={(value: boolean) => setExportModalShown(value)}
                                externalParameters={props.exportMeta.externalParameters}
                                disclaimer={props.exportMeta.disclaimer}
                                disclaimerTitle={props.exportMeta.disclaimerTitle}
                                allSelected={props.exportMeta.allSelected}
                                noProgress={props.exportMeta.noProgress}
                            />
                        )}
                        <Button
                            variant="default"
                            disabled={props.exportMeta.exportDisabled}
                            onClick={(e) => {
                                e.currentTarget.blur();
                                setExportModalShown(true);
                            }}
                            style={{
                                marginLeft: '0.75rem',
                                marginRight: '0',
                            }}
                        >
                            Export
                            {props.exportMeta.exportDisabled && (
                                <FontAwesomeIcon icon={faLock} style={{ marginLeft: 6 }} />
                            )}
                        </Button>
                    </>
                )}
                {props.customActions && (
                    <ButtonGroup
                        style={{
                            marginLeft: '0.75rem',
                        }}
                    >
                        {props.customActions.map((action) => {
                            return (
                                <Button
                                    variant="default"
                                    onClick={action.action}
                                    style={{
                                        textTransform: 'capitalize',
                                    }}
                                    key={action.name}
                                >
                                    {action.name}
                                </Button>
                            );
                        })}
                    </ButtonGroup>
                )}
            </div>
        </div>
    );
};

export default ExtraActionBar;
