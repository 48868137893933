'use strict';

import React from 'react';
import PropTypes from "prop-types";
import DealPipelineStage from "../../../../Entities/Deal/DealPipeline/DealPipelineStage/DealPipelineStage";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import StageListItem from "./StageListItem";

import './stagelist.scss';

class StageList extends React.Component {

    onDragEnd(result) {
        const { destination, source } = result;

        if (!destination) return;
        if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        if (this.props.reorderAction) this.props.reorderAction(source.index, destination.index);
    }

    render() {
        return <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
            <Droppable droppableId="droppable-stage-list--open-stages">
                {provided => (
                    <div
                        className="react-stage-list stage-list--open-stages"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {this.props.stages && this.props.stages.length
                            ? this.props.stages.map((stage, index) =>
                                stage.isOpen()
                                ? <StageListItem
                                    stage={stage}
                                    index={index}
                                    editAction={this.props.editAction}
                                    deleteAction={this.props.deleteAction}
                                    key={stage.name + String(index)}
                                />
                                : null
                            )
                            : null}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            <Droppable droppableId="droppable-stage-list--closed-stages" isDropDisabled={true}>
                {provided => (
                    <div
                        className="react-stage-list stage-list--closed-stages"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {this.props.stages && this.props.stages.length
                            ? this.props.stages.map((stage, index) =>
                                stage.isClosed()
                                ? <StageListItem
                                    stage={stage}
                                    index={index}
                                    isDragDisabled={true}
                                    key={stage.name + String(index)}
                                />
                                : null
                            )
                            : <p>No stages added yet.</p>}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>;
    }
}

StageList.propTypes = {
    stages: PropTypes.arrayOf(PropTypes.instanceOf(DealPipelineStage)),
    editAction: PropTypes.func,
    deleteAction: PropTypes.func,
    reorderAction: PropTypes.func
};

StageList.defaultProps = {};

export default StageList;