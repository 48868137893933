import { SearchQueryBuilder } from '@sprint/sprint-react-components';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { RepositoryFactoryContext } from '../../Applications/DataGrids';
import BulkUpdateRepository, { UpdateType } from '../../Applications/DataGrids/Api/BulkEditRepository';
import { CustomPropertiesRequest } from '../../Applications/DataGrids/Api/CustomPropertiesRequest';
import CustomProperty from '../../Applications/DataGrids/Models/CustomProperty';
import { Field, toFieldType } from './BulkEditSingleFieldModal';

export const toFields = (
    type: UpdateType,
    customProperties: CustomProperty[],
    bulkUpdateRepository: BulkUpdateRepository,
    ids: number[],
) =>
    _.map(customProperties, (property) => {
        const field: Field = {
            key: property.name,
            name: property.name,
            type: toFieldType(property.data_type),
            save: async (data: Record<string, any>) => {
                const key = Object.keys(data)[0];
                let value: any;
                switch (field.type) {
                    case 'checkboxes':
                        value = _.map(data[key], (item) => item.value).join('\r\n');
                        break;
                    case 'enum':
                        value = data[key].value;
                        break;
                    default:
                        value = data[key];
                        break;
                }

                await bulkUpdateRepository.update({ customProperty: true, type, field: key, value, ids });
            },
        };

        if (['radio-buttons', 'checkboxes', 'enum'].includes(field.type)) {
            const values = property.options ? property.options.split(/\r?\n/) : [];
            const options = values.map((value: any) => {
                if (value.trim()) {
                    return {
                        value: value,
                        label: value,
                    };
                }
            });

            field.loadOptions = async (filter: string, _loadedOptions, { page }) => {
                return {
                    options: options,
                    hasMore: false,
                    additional: {
                        page: page + 1,
                    },
                };
            };
        }

        return field;
    });

export const useCustomProperties = (type: UpdateType): CustomProperty[] | undefined => {
    const [value, setValue] = useState<CustomProperty[] | undefined>(undefined);

    const customPropertiesRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new CustomPropertiesRequest(),
    );

    const toCustomPropertyType = (type: UpdateType) => {
        switch (type) {
            case 'contacts':
                return 'Contact';
            case 'organisations':
                return 'Organisation';
            case 'deals':
                return 'Deal';
            case 'tasks':
                return 'Task';
        }
    };

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            const searchQueryBuilder = new SearchQueryBuilder();
            const result = await customPropertiesRepository.search(searchQueryBuilder.build());
            const customProperties = _.filter(
                result.results as CustomProperty[],
                (property) => property.type === toCustomPropertyType(type),
            );

            if (isMounted) {
                setValue(customProperties);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, []);

    return value;
};
