import { APP_COLOURS } from '../Colours/AppColours';

const COLOUR_PRESETS: string[] = [
    APP_COLOURS.BLACK,
    APP_COLOURS.DARK_BLUE,
    APP_COLOURS.STANDARD_BLUE,
    APP_COLOURS.GREEN,
    APP_COLOURS.BRIGHT_GREEN,
    APP_COLOURS.ORANGE,
    APP_COLOURS.PINK,
];
export default COLOUR_PRESETS;
