import * as ActionTypes from "./ModalActionTypes";

const initialState = {
    settings: {
        open: false,
        header: null,
        content: null,
        footer: null
    },
    internal: {
        updateCanary: false
    }
};

export default function(state, action) {
    if (state === undefined) {
        state = initialState;
    }

    switch (action.type) {
        case ActionTypes.OPEN:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    open: true,
                    header: action.payload.header,
                    content: action.payload.content,
                    footer: action.payload.footer
                }
            };
        case ActionTypes.UPDATE:
            return {
                ...state,
                internal: {
                    ...state.internal,
                    // Toggling the canary will cause the modal to re-render
                    // when `updateCanary` is selected from the store by CampusModal.
                    // Using a toggle halves the number of renders caused by store changes.
                    updateCanary: !state.internal.updateCanary
                }
            };
        case ActionTypes.CLOSE:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    open: false
                }
            };
        default:
            return state;
    }
}