import {
    Modal,
    ModalType,
    showBanner,
    LogLevel,
} from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext } from 'react';
import { RepositoryFactoryContext } from '../../index';
import { CampaignsRequest } from '../../Api/CampaignsRequest';

interface Props {
    selectedRowsForDeletion: number[];
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
    modalShown: boolean;
    reloadDataGrid: () => void;
    closeModal: () => void;
}

const CampaignsDeleteModal: FunctionComponent<Props> = (props: Props) => {
    const campaignsRepository = useContext(RepositoryFactoryContext).getApiRepository(new CampaignsRequest());

    const deleteRows = () : Promise<boolean> => {
        return campaignsRepository
            .delete(props.selectedRowsForDeletion)
            .then((success: any) => {
                showBanner({
                    message: success.data.message,
                    level: LogLevel.SUCCESS,
                });
                props.reloadDataGrid();
                return true;
            })
            .catch((err: any) => {
                showBanner({
                    message: err?.message ?? err,
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    return (
        <Modal
            isOpen={props.modalShown}
            type={ModalType.DELETE}
            title={
                props.selectedRowsForDeletion.length > 1
                    ? `Delete These ${props.dataGridEntityPlural ?? 'Rows'}`
                    : `Delete This ${props.dataGridEntitySingular ?? 'Row'}`
            }
            close={props.closeModal}
            onSubmit={deleteRows}
        >
            <>
                Are you sure you want to delete
                {props.selectedRowsForDeletion.length > 1
                    ? ` these ${props.selectedRowsForDeletion.length} ${(
                          props.dataGridEntityPlural ?? 'rows'
                      ).toLowerCase()}?`
                    : ` this ${(props.dataGridEntitySingular ?? 'row').toLowerCase()}?`}
                <>
                    <br />
                    <br />
                    Deleted data cannot be recovered, ever.
                </>
            </>
        </Modal>
    );
}

export default CampaignsDeleteModal;
