'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import Prompt from "../../../../CommonComponents/Prompt/Prompt";
import DealsImg from '../../../../../public/assets/application/img/prompts/deals.svg';

class NoDealsFromFilter extends React.Component {

    render() {
        return <Prompt
            imgSrc={DealsImg}
            imgHeight={140} // Note: Normally 180 for module level prompt images
            header="No Deals Found"
            subHeader="Please adjust the specified filter."/>;
    }

}

NoDealsFromFilter.propTypes = {};

NoDealsFromFilter.defaultProps = {};

export default NoDealsFromFilter;