import { SearchQuery } from '@sprint/sprint-react-components';
import axios from 'axios';
import urlJoin from 'url-join';
import { v4 as uuidv4 } from 'uuid';
import HttpRequestService from '../../../CommonComponents/HttpRequestService/HttpRequestService';
interface SimpleType {
    id?: number;
}

export default class SimpleGetRepository<T extends SimpleType> {
    private _http: HttpRequestService;
    private _baseUrl: string;
    private _getRowsCancelToken: string;

    constructor(
        private _authToken: string,
        path: string,
    ) {
        this._http = new HttpRequestService(this._authToken);
        this._baseUrl = path;
        this._getRowsCancelToken = uuidv4();
    }

    private isSuccessCode(statusCode: number) {
        return statusCode >= 200 && statusCode <= 299;
    }

    private isClientErrorCode(statusCode: number) {
        return statusCode >= 400 && statusCode <= 499;
    }

    public async get(query: SearchQuery, id?: number): Promise<any> {
        const url = id ? urlJoin(this._baseUrl, id.toString()) : this._baseUrl;
        return this._http
            .get(urlJoin(url, `?${query.toQueryString()}`), this._getRowsCancelToken)
            .then((res: any | null) => {
                if (res && this.isSuccessCode(res.status)) {
                    return res.data;
                }
                throw new Error(res?.statusText);
            })
            .catch((err) => {
                if (axios.isCancel(err)) return err;
                throw err;
            });
    }
}
