import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';

interface Props {
    size?: SizeProp;
    inline?: boolean;
}

const Spinner: FunctionComponent<Props> = (props: Props) => {
    return (
        <span className="react-spinner" style={{ display: props.inline ? 'inline-block' : 'block' }}>
            <FontAwesomeIcon icon={faSpinnerThird} size={props.size} spin />
        </span>
    );
};

export default Spinner;
