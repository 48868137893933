export const TO_DO = 'To Do';
export const IN_PROGRESS = 'In Progress';
export const COMPLETE = 'Complete';
export const ON_HOLD = 'On Hold';

export function allOptions() {
    return [
        TO_DO, IN_PROGRESS, COMPLETE, ON_HOLD
    ];
}

export function defaultOption () {
    return TO_DO;
}

export function forSelect() {
    return allOptions().map(option => ({
        id: option,
        label: option
    }));
}