import React, { FunctionComponent } from 'react';

interface Props {
    content: string | JSX.Element | ((...args: any[]) => any);
}

const Body: FunctionComponent<Props> = (props: Props) => {
    // Note: `overflow: visible` is required for any inline select dropdown to function correctly
    return (
        <div className="modal-body" style={{ overflow: 'visible' }}>
            {typeof props.content === 'function' ? props.content() : props.content}
        </div>
    );
};

export default Body;
