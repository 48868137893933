'use strict';

export const FIELD_BLURRED = 'dealForm/FIELD_BLURRED';
export const FORM_SUBMIT_ATTEMPTED = 'dealForm/FORM_SUBMIT_ATTEMPTED';
export const FORM_RESET = 'dealForm/FORM_RESET';
export const ACTIVITIES_UPDATED = 'dealForm/ACTIVITIES_UPDATED';
export const ALERT_NOTIFICATIONS_UPDATED = 'dealForm/ALERT_NOTIFICATIONS_UPDATED';
export const PROMPT_PAGE_UPDATED = 'dealForm/PROMPT_PAGE_UPDATED';
export const VIEW_PAGE_UPDATED = 'dealForm/VIEW_PAGE_UPDATED';
export const VIEW_PAGE_EXITED = 'dealForm/VIEW_PAGE_EXITED';
export const DATA_TABLE_UPDATING = 'dealForm/DATA_TABLE_UPDATING';
export const DATA_TABLE_UPDATED = 'dealForm/DATA_TABLE_UPDATED';
export const OPEN_FORM_SISP = 'dealForm/OPEN_FORM_SISP';
export const CLOSE_FORM_SISP = 'dealForm/CLOSE_FORM_SISP';
export const OPEN_PREVIEW_SISP = 'dealPreview/OPEN_PREVIEW_SISP';
export const CLOSE_PREVIEW_SISP = 'dealPreview/CLOSE_PREVIEW_SISP';
export const OPEN_INTERACTION_SISP = 'dealView/OPEN_INTERACTION_SISP';
export const CLOSE_INTERACTION_SISP = 'dealView/CLOSE_INTERACTION_SISP';
export const OPEN_QUOTE_LINK_MODAL = 'dealForm/OPEN_QUOTE_LINK_MODAL';
export const QUOTE_LINK_MODAL_OPENED = 'dealForm/QUOTE_LINK_MODAL_OPENED';
export const OPEN_SALE_LINK_MODAL = 'dealForm/OPEN_SALE_LINK_MODAL';
export const SALE_LINK_MODAL_OPENED = 'dealForm/SALE_LINK_MODAL_OPENED';
export const SWITCH_MULTI_VIEW = 'dealBoard/SWITCH_MULTI_VIEW';
export const DATA_TABLE_VISIBILITY_CHANGED = 'dealBoard/DATA_TABLE_VISIBILITY_CHANGED';