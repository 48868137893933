import {
    AppEvent,
    DropDown,
    EventBusInstance,
    LogLevel,
    OptionTypeBase,
    PendingButton,
    SearchQuery,
    showBanner,
    Sisp,
    SortOrder
} from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { RepositoryFactoryContext } from '../..';
import { PaymentsRequest } from '../../Api/PaymentsRequest';
import { PaymentTypeRequest } from '../../Api/PaymentTypeRequest';
import { DateTimeParser } from '../../HelperFunctions/DateTimeParser';
import PaymentEditState from '../../Models/PaymentEditState';
import PaymentType from '../../Models/PaymentType';

interface Props {
    uniqueKey: string;
    onSuccess: (results: any) => Promise<boolean>;
}

const PaymentsEditSisp: FunctionComponent<Props> = (props: Props) => {
    const [shown, setShown] = useState<boolean>(false);

    const currencyRegexp = /[^0-9\.]+/g;
    const validEditPaymentRegexp = /^(([0-9]*\.?[0-9]{2}){1})$/g;
    const [paymentMethods, setPaymentMethods] = useState<OptionTypeBase[]>([]);

    const paymentsRepository = useContext(RepositoryFactoryContext).getApiRepository(new PaymentsRequest());
    const paymentTypesRepository = useContext(RepositoryFactoryContext).getApiRepository(new PaymentTypeRequest());

    const focusRef = useRef<HTMLInputElement>(null);

    const [rowId, setRowId] = useState<number | null>(null);
    const [invoiceId, setInvoiceId] = useState<number | null>(null);
    const [invoiceNumber, setInvoiceNumber] = useState<string>('');
    const [invoiceUrl, setInvoiceUrl] = useState<string>('');

    const [editPayment, setEditPayment] = useState<string>('');
    const [editPaymentDate, setEditPaymentDate] = useState<Date | null>(null);
    const [editPaymentMethod, setEditPaymentMethod] = useState<OptionTypeBase | null>(null);

    const [editPaymentValid, setEditPaymentValid] = useState<boolean>(true);
    const [editPaymentDateValid, setEditPaymentDateValid] = useState<boolean>(true);
    const [editPaymentMethodValid, setEditPaymentMethodValid] = useState<boolean>(true);

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (shown) {
            if (focusRef.current) {
                focusRef.current.focus();
            }
        }
    }, [shown]);

    const getPaymentMethods = async (filter: string, page?: number) => {
        // Build filterRequest
        const query: SearchQuery = new SearchQuery(page ?? 1, 100, 'id', SortOrder.DESC, filter);
        let res: OptionTypeBase[] = [];

        return paymentTypesRepository
            .search(query)
            .then((result: any) => {
                res = _.map(result.results, (paymentType: PaymentType) => {
                    return { label: paymentType.payment_type, value: paymentType.id! };
                });
                res.push({
                    value: result.counts.currentPage < result.counts.totalPages,
                    label: 'has_more',
                });
                return res;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to get Payment Methods - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return res;
            });
    };

    const populatePaymentMethodsDropdown = async () => {
        let res: OptionTypeBase[] = await getPaymentMethods('');
        res = _.filter(res, (obj) => obj.label !== 'has_more');
        setPaymentMethods(res);
        return res;
    };

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<PaymentEditState>) => {
            if (event.target !== props.uniqueKey) return;
            setIsLoading(true);
            populatePaymentMethodsDropdown().then((dropdownOptions: OptionTypeBase[]) => {
                setRowId(event.message.id);
                setInvoiceId(event.message.invoice_id);
                setInvoiceNumber(event.message.invoice_number);
                setEditPayment(event.message.amount_received.replace(currencyRegexp, ''));
                const parsedDate = DateTimeParser.parseDateString(event.message.payment_date);
                setEditPaymentDate(parsedDate);
                setEditPaymentMethod(
                    dropdownOptions.find((value) => value.label == event.message.payment_type) ?? null,
                );
                setInvoiceUrl(`view/${event.message.invoice_id}`);
                setIsLoading(false);
            });
            setShown(true);
        });
    }, [shown]);

    const validate = async (): Promise<boolean> => {
        const paymentValid = editPayment.length > 0 && validEditPaymentRegexp.test(editPayment);
        setEditPaymentValid(paymentValid);

        const dateValid = editPaymentDate != null;
        setEditPaymentDateValid(dateValid);

        const paymentMethodValid = editPaymentMethod != null;
        setEditPaymentMethodValid(paymentMethodValid);

        return paymentValid && dateValid && paymentMethodValid;
    };

    const reset = () => {
        setEditPayment('');
        setEditPaymentDate(null);
        setEditPaymentMethod(null);

        setEditPaymentValid(true);
        setEditPaymentDateValid(true);
        setEditPaymentMethodValid(true);
    };

    const handleEditRow = async (): Promise<boolean> => {
        const Payment: PaymentEditState = {
            id: rowId!,
            invoice_id: invoiceId!,
            invoice_number: invoiceNumber,
            amount_received: editPayment,
            payment_date: editPaymentDate!.toISOString().substring(0, 10),
            payment_type: editPaymentMethod!.value,
        };

        return paymentsRepository
            .update(Payment)
            .then((results: any) => {
                props.onSuccess(results.data);
                return Promise.resolve(true);
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to edit payment - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return Promise.resolve(false);
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        setIsSaving(true);
        e.preventDefault();
        if ((await validate()) && (await handleEditRow())) setShown(false);
        setIsSaving(false);
    };

    return (
        <Sisp
            isOpen={shown}
            onSubmit={handleEditRow}
            onCancel={() => {
                reset();
                setShown(false);
            }}
            validate={validate}
            footerOverride={
                <>
                    <Button
                        variant="default"
                        onClick={() => {
                            reset();
                            setShown(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <PendingButton variant="primary" onClick={onSubmitForm} pending={isSaving}>
                        Save
                    </PendingButton>
                </>
            }
        >
            <h4>Edit a Payment</h4>
            {isLoading ? (
                <div style={{ position: 'relative', alignItems: 'center' }}>
                    <Card
                        className="loading-spinner-container filter-loading-spinner"
                        style={{ background: '#f9f9f9' }}
                    >
                        <Spinner animation="border" role="status" />
                    </Card>
                </div>
            ) : (
                <Form onSubmit={onSubmitForm}>
                    <Form.Group>
                        <Form.Label>
                            Payment for Invoice <a href={invoiceUrl}>{invoiceNumber}</a>
                        </Form.Label>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{ justifyContent: 'flex-start', width: '100%' }}>
                            Amount Received <span className="required-field-marker">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            isInvalid={!editPaymentValid}
                            value={editPayment}
                            onChange={(event) => {
                                let entered_value = event.target.value.replace(currencyRegexp, '');
                                if (entered_value.includes('.')) {
                                    const tokens = entered_value.split('.');
                                    if (tokens[1].length > 2) {
                                        entered_value = `${tokens[0]}.${tokens[1].substring(0, 2)}`;
                                    }
                                }
                                setEditPayment(entered_value);
                                setEditPaymentValid(true);
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            {!editPaymentValid && editPayment != '' && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Payment Date <span className="required-field-marker">*</span>
                        </Form.Label>
                        {editPaymentDate && (
                            <DatePicker
                                selected={editPaymentDate}
                                onChange={setEditPaymentDate}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                                isClearable={false}
                            />
                        )}
                        <Form.Control.Feedback type="invalid">
                            {!editPaymentDateValid && !editPaymentDate && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Payment Method <span className="required-field-marker">*</span>
                        </Form.Label>
                        <DropDown
                            value={editPaymentMethod}
                            isInvalid={false}
                            onChange={(selected: OptionTypeBase) => {
                                setEditPaymentMethod(selected);
                            }}
                            options={paymentMethods}
                            menuPosition="fixed"
                        />
                        <Form.Control.Feedback type="invalid">
                            {!editPaymentMethodValid && !editPaymentMethod && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            )}
        </Sisp>
    );
};

export default PaymentsEditSisp;
