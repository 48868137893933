import { DataGridColumnPermission } from '@client/Applications/DataGrids/HelperFunctions/UserPermissionsHelper';
import { UserPermissionsContext } from '@client/Context/UserPermissions';
import { faBuilding } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    EntityTarget,
    ExtendedColumn,
    filter as filterTypes,
    HoveroverButton,
    OptionTypeBase,
    SearchQuery,
    SortOrder,
    ToolbarButton,
    ToolbarComponentProps,
    UserFormatter,
} from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { RenderCellProps } from 'react-data-grid';
import { Options } from 'react-select';
import HiddenEmailAddress from '../../../../CommonComponents/HiddenEmailAddress/HiddenEmailAddress';
import { PermissionKey } from '../../../../Context/UserPermissions';
import { ucwords } from '../../../../Helpers/StringHelper';
import { DEFAULT_COLUMN_WIDTH, X_LARGE_COLUMN_WIDTH } from '../../../EducationDataGrid/constant';
import { EdukOfstedColumnsFactory } from '../../../EducationDataGrid/establishments/EdukOfstedColumns.generated';
import { ContactSavedViewsRequest } from '../../Api/ContactSavedViewsRequest';
import { ContactsRequest } from '../../Api/ContactsRequest';
import LearnMoreModal, { LearnMoreModalType } from '../../Components/LearnMoreModal';
import { SavedInboundView } from '../../Components/SavedInboundView';
import { SavedViewType } from '../../Components/ViewsModal';
import { buildCustomPropertiesFiltersAndColumns } from '../../HelperFunctions/CustomPropertiesFiltersAndColumnsBuilder';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import { DictionaryContext, RepositoryFactoryContext } from '../../index';
import Contact from '../../Models/Contact';
import { CustomPropertyType } from '../../Models/CustomPropertyType';
import { NearLimitMethod, UniqueKeyType } from '../../Models/Enums';
import CampusDataGrid, {
    DeleteModalMeta,
    ExportableExtendedColumn,
    FilterExtendedColumn,
    PromptMeta,
} from '../CampusDataGrid';
import ClientTagsColumn, { getClientTagsColumn } from '../ClientTags/ClientTagsColumn';
import DataGridHelper from '../DataGridHelper';
import ContactsAddSisp from './ContactsAddSisp';
import ContactsBulkEdit from './ContactsBulkEdit';
import ContactsEditSisp from './ContactsEditSisp';
import ContactsPreviewSisp from './ContactsPreviewSisp';
import CRMDeleteModal from './CRMDeleteModal';
import ApiFilterOptionsService from './filters/ApiFilterOptionsService';
import { SyncedDataFilterableFieldsEDIN } from './filters/SyncedDataFilterableFieldsEDIN';
import { SyncedDataFilterableFieldsEDUK } from './filters/SyncedDataFilterableFieldsEDUK';
import { SyncedDataFilterableFieldsEDUS } from './filters/SyncedDataFilterableFieldsEDUS';

export interface ContactsExtendedColumn extends FilterExtendedColumn, ExportableExtendedColumn {
    isOrganisationColumn?: boolean;
}

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
    customProperties: any;
    creationLocked: boolean;
    showAddSisp: boolean;
    activeViewId?: number;
}

export enum ContactsColumnKey {
    JOB,
    TITLE,
    EMAIL,
    OWNER,
    UPDATED,
    TELEPHONE,
    UNSUBSCRIBED,
    SUPPRESSED,
    POINTS_SCORE,
    PROCESSING_GROUND,
    ENGAGEMENT_METHOD,
    ORGANISATION_FULL_ADDRESS,
    ORGANISATION_TOWN,
    ORGANISATION_COUNTY,
    ORGANISATION_REGION,
    ORGANISATION_POSTCODE,
    ORGANISATION_NAME,
    ORGANISATION_BUSINESS_TYPE,
    ORGANISATION_WEBSITE,
    ORGANISATION_EMAIL,
    ORGANISATION_TELEPHONE,
    ORGANISATION_COUNTRY,
    ORGANISATION_SECTOR,
    TAGS,
}

const ContactsTable: FunctionComponent<Props> = (props: Props) => {
    // Base Data
    const dictionary = useContext(DictionaryContext);
    const filterOptionsService = new ApiFilterOptionsService();

    const editSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);
    const previewSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.PREVIEW_SISP);

    // Repository Contexts
    const contactsRepository = useContext(RepositoryFactoryContext).getApiRepository<Contact>(new ContactsRequest());
    const permissions = useContext(UserPermissionsContext);
    const permissionFactory = (name: PermissionKey): DataGridColumnPermission => {
        let ofstedPermission: DataGridColumnPermission = 'invisible';
        if (permissions[name].isEnabled) {
            ofstedPermission = 'active';
        } else if (permissions[name].isVisible) {
            ofstedPermission = 'disabled';
        }
        return ofstedPermission;
    };
    const canBulkEdit = permissions.bulkEditContacts.isEnabled;

    // Learn More
    const learnMoreRef = useRef<LearnMoreModalType | null>(null);
    const showLearnMore = (e: Event, label: string, summary: string, description: string) => {
        learnMoreRef.current?.displayComponent(e, label, summary, description);
    };
    const learnMoreModal = <LearnMoreModal ref={learnMoreRef} sisp={true} />;

    // Columns
    const ColumnsOptions: Record<ContactsColumnKey, ContactsExtendedColumn> = {
        [ContactsColumnKey.JOB]: {
            key: 'job_role',
            name: 'Job',
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            filterFieldType: filterTypes.FieldType.STRING_ARRAY,
        },
        [ContactsColumnKey.TITLE]: {
            key: 'title',
            name: 'Title',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'title', SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: 'title',
                });
                return filterOptionsService.getFilterOptions(query);
            },
        },
        [ContactsColumnKey.EMAIL]: {
            key: 'email',
            name: 'Email',
            sortable: true,
            minWidth: DEFAULT_COLUMN_WIDTH,
            filterFieldType: filterTypes.FieldType.STRING,
            renderCell: (props: RenderCellProps<unknown>) => {
                // unclaimed teacher emails are obfuscated by the api, we want to display them correctly.
                const email = (props.row as Contact).email;
                const emailClaimed = (props.row as Contact).email_claimed;
                const edu_data_package = (props.row as Contact).education_data_package;
                if (edu_data_package && !emailClaimed) {
                    return <HiddenEmailAddress template={email} />;
                }
                return <>{email}</>;
            },
            exportFormatter: (entity: Contact) => {
                // Obfuscation unnecessary as the api protects the unclaimed emails
                let email = entity?.email;
                if (entity.education_data_package && !entity.email_claimed) email = '';
                return email || '';
            },
        },
        [ContactsColumnKey.TELEPHONE]: {
            key: 'telephone',
            name: 'Telephone',
            sortable: true,
            minWidth: DEFAULT_COLUMN_WIDTH,
            filterFieldType: filterTypes.FieldType.STRING,
        },

        [ContactsColumnKey.OWNER]: {
            key: 'owned_by',
            name: 'Owned By',
            sortable: true,
            renderCell: UserFormatter,
            exportFormatter: (entity: any) => (entity as Contact)?.owned_by?.name || '',
            minWidth: DEFAULT_COLUMN_WIDTH,
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'owned_by', SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: 'owned_by',
                });
                return filterOptionsService.getFilterOptions(query);
            },
        },
        [ContactsColumnKey.UPDATED]: {
            key: 'updated',
            sortKey: 'modified',
            name: 'Last Updated',
            cellClass: 'final-cell',
            headerCellClass: 'final-header-cell',
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            filterFieldType: filterTypes.FieldType.STRING,
        },
        [ContactsColumnKey.PROCESSING_GROUND]: {
            key: 'processing_ground',
            name: 'Processing Ground',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'processing_ground', SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: 'processing_ground',
                });
                return filterOptionsService.getFilterOptions(query);
            },
            renderCell: (props: RenderCellProps<unknown>) => {
                return <>{(props.row as Contact).engagement_method?.processing_ground}</>;
            },
        },
        [ContactsColumnKey.ENGAGEMENT_METHOD]: {
            key: 'engagement_method',
            name: 'Engagement Method',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'engagement_method', SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: 'engagement_method',
                });
                return filterOptionsService.getFilterOptions(query);
            },
            renderCell: (props: RenderCellProps<unknown>) => {
                return <>{(props.row as Contact).engagement_method?.name}</>;
            },
            exportFormatter: (entity: Contact) => entity.engagement_method?.name || '',
        },
        [ContactsColumnKey.UNSUBSCRIBED]: {
            key: 'unsubscribed',
            name: 'Unsubscribed',
            filterFieldType: filterTypes.FieldType.BOOLEAN,
        },
        [ContactsColumnKey.SUPPRESSED]: {
            key: 'suppressed',
            name: 'Suppressed',
            filterFieldType: filterTypes.FieldType.BOOLEAN,
        },
        [ContactsColumnKey.POINTS_SCORE]: {
            key: 'point_score',
            name: 'Points Score',
            filterFieldType: filterTypes.FieldType.NUMBER,
        },
        [ContactsColumnKey.ORGANISATION_NAME]: {
            isOrganisationColumn: true,
            key: 'organisation_name',
            name: ucwords(dictionary['organisation']) + ' Name',
            sortable: true,
            renderCell: (props: RenderCellProps<unknown>) => {
                const org = (props.row as Contact).organisation.name;
                const id = (props.row as Contact).organisation.id;
                return <a href={'/subscribers/organisations/view/' + id}>{org}</a>;
            },
            exportFormatter: (entity: any) => (entity as Contact)?.organisation.name || '',
            width: DEFAULT_COLUMN_WIDTH,
            filterFieldType: filterTypes.FieldType.STRING,
        },
        [ContactsColumnKey.ORGANISATION_FULL_ADDRESS]: {
            isOrganisationColumn: true,
            key: 'full_address',
            name: 'Full Address',
            sortable: false,
            renderCell: (props: RenderCellProps<unknown>) => {
                return <>{(props.row as Contact).organisation.full_address}</>;
            },
            exportFormatter: (entity: any) => (entity as Contact)?.organisation.full_address || '',
            minWidth: DEFAULT_COLUMN_WIDTH,
        },
        [ContactsColumnKey.ORGANISATION_TOWN]: {
            isOrganisationColumn: true,
            key: 'address4',
            name: 'Town',
            sortable: true,
            minWidth: DEFAULT_COLUMN_WIDTH,
            renderCell: (props: RenderCellProps<unknown>) => {
                return <>{(props.row as Contact).organisation.address4}</>;
            },
            exportFormatter: (entity: any) => (entity as Contact)?.organisation.address4 || '',
            filterFieldType: filterTypes.FieldType.STRING_ARRAY,
        },
        [ContactsColumnKey.ORGANISATION_COUNTY]: {
            isOrganisationColumn: true,
            key: 'county',
            name: 'County',
            sortable: true,
            minWidth: DEFAULT_COLUMN_WIDTH,
            renderCell: (props: RenderCellProps<unknown>) => {
                return <>{(props.row as Contact).organisation.county}</>;
            },
            exportFormatter: (entity: any) => (entity as Contact)?.organisation.county || '',
            filterFieldType: filterTypes.FieldType.STRING,
        },
        [ContactsColumnKey.ORGANISATION_REGION]: {
            isOrganisationColumn: true,
            key: 'region',
            name: 'Region',
            sortable: true,
            minWidth: DEFAULT_COLUMN_WIDTH,
            renderCell: (props: RenderCellProps<unknown>) => {
                return <>{(props.row as Contact).organisation.region}</>;
            },
            exportFormatter: (entity: any) => (entity as Contact)?.organisation.region || '',
            filterFieldType: filterTypes.FieldType.STRING,
        },
        [ContactsColumnKey.ORGANISATION_POSTCODE]: {
            isOrganisationColumn: true,
            key: 'postcode',
            name: ucwords(dictionary['postcode']),
            sortable: true,
            minWidth: DEFAULT_COLUMN_WIDTH,
            renderCell: (props: RenderCellProps<unknown>) => {
                return <>{(props.row as Contact).organisation.postcode}</>;
            },
            exportFormatter: (entity: any) => (entity as Contact)?.organisation.postcode || '',
            filterFieldType: filterTypes.FieldType.STRING,
        },
        [ContactsColumnKey.ORGANISATION_BUSINESS_TYPE]: {
            isOrganisationColumn: true,
            key: 'business_type',
            name: ucwords(dictionary['organisation']) + ' Business Type',
            renderCell: (props: RenderCellProps<unknown>) => {
                return <>{(props.row as Contact).organisation.business_type}</>;
            },
            exportFormatter: (entity: any) => (entity as Contact)?.organisation.business_type || '',
            filterFieldType: filterTypes.FieldType.STRING_ARRAY,
        },
        [ContactsColumnKey.ORGANISATION_WEBSITE]: {
            isOrganisationColumn: true,
            key: 'organisation_website',
            name: ucwords(dictionary['organisation']) + ' Website',
            renderCell: (props: RenderCellProps<unknown>) => {
                return <>{(props.row as Contact).organisation.website}</>;
            },
            exportFormatter: (entity: any) => (entity as Contact)?.organisation.website || '',
            filterFieldType: filterTypes.FieldType.STRING,
        },
        [ContactsColumnKey.ORGANISATION_EMAIL]: {
            isOrganisationColumn: true,
            key: 'organisation_email',
            name: ucwords(dictionary['organisation']) + ' Email',
            renderCell: (props: RenderCellProps<unknown>) => {
                return <>{(props.row as Contact).organisation.email}</>;
            },
            exportFormatter: (entity: any) => (entity as Contact)?.organisation.email || '',
            filterFieldType: filterTypes.FieldType.STRING,
        },
        [ContactsColumnKey.ORGANISATION_TELEPHONE]: {
            isOrganisationColumn: true,
            key: 'organisation_telephone',
            name: ucwords(dictionary['organisation']) + ' Telephone',
            renderCell: (props: RenderCellProps<unknown>) => {
                return <>{(props.row as Contact).organisation.telephone}</>;
            },
            exportFormatter: (entity: any) => (entity as Contact)?.organisation.telephone || '',
            filterFieldType: filterTypes.FieldType.STRING,
        },
        [ContactsColumnKey.ORGANISATION_COUNTRY]: {
            isOrganisationColumn: true,
            key: 'country',
            name: 'Country',
            renderCell: (props: RenderCellProps<unknown>) => {
                return <>{(props.row as Contact).organisation.country}</>;
            },
            exportFormatter: (entity: any) => (entity as Contact)?.organisation.country || '',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'country', SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: 'country',
                });
                return filterOptionsService.getFilterOptions(query);
            },
        },
        [ContactsColumnKey.ORGANISATION_SECTOR]: {
            isOrganisationColumn: true,
            key: 'organisation_type',
            name: 'Sector',
            renderCell: (props: RenderCellProps<unknown>) => {
                return <>{(props.row as Contact).organisation.sector}</>;
            },
            exportFormatter: (entity: any) => (entity as Contact)?.organisation.sector || '',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'organisation_type', SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: 'organisation_type',
                });
                return filterOptionsService.getFilterOptions(query);
            },
        },
        [ContactsColumnKey.TAGS]: {
            ...getClientTagsColumn(permissions.contactsCustomTags),
            filterFieldKey: 'contact_tags',
        },
    };

    const DefaultColumns: ContactsExtendedColumn[] = [
        ColumnsOptions[ContactsColumnKey.ORGANISATION_NAME],
        ColumnsOptions[ContactsColumnKey.JOB],
        ColumnsOptions[ContactsColumnKey.EMAIL],
        ColumnsOptions[ContactsColumnKey.OWNER],
        ColumnsOptions[ContactsColumnKey.UPDATED],
        ColumnsOptions[ContactsColumnKey.TAGS],
    ];

    const frozenColumns: ContactsExtendedColumn[] = [
        {
            key: 'fullname',
            name: 'Name',
            sortable: true,
            frozen: true,
            renderCell: (formatterProps: RenderCellProps<unknown>) => {
                const { full_name, id } = formatterProps.row as Contact;
                const editEventBusMessage: any = formatterProps.row as Contact;
                return (
                    <>
                        <a href={'/subscribers/contacts/view/' + id}>{full_name}</a>
                        <HoveroverButton
                            contents="Preview"
                            showHoverover={true}
                            eventBusMessageTarget={previewSispUniqueKey}
                            eventBusMessage={editEventBusMessage}
                        />
                        <HoveroverButton
                            contents="Edit"
                            showHoverover={true}
                            eventBusMessageTarget={editSispUniqueKey}
                            eventBusMessage={editEventBusMessage}
                        />
                    </>
                );
            },
            exportFormatter: (entity: any) => (entity as Contact)?.full_name || '',
            width: X_LARGE_COLUMN_WIDTH,
            filterFieldType: filterTypes.FieldType.STRING,
        },
    ];

    // Views
    const defaultView: SavedInboundView = {
        id: 0,
        name: 'All Contacts',
        dataGridColumns: DefaultColumns.map((column: ExtendedColumn) => {
            return {
                key: column.key,
                width: DEFAULT_COLUMN_WIDTH,
            };
        }),
    };

    const [contactsCustomPropertyColumns, setContactsCustomPropertyColumns] =
        useState<Record<any, FilterExtendedColumn>>();
    const [organisationCustomPropertyColumns, setOrganisationCustomPropertyColumns] =
        useState<Record<any, FilterExtendedColumn>>();

    // On Did Mount
    // Set up custom properties filters and columns
    useEffect(() => {
        const contacts = buildCustomPropertiesFiltersAndColumns(props.customProperties, CustomPropertyType.CONTACT);

        const organisations = buildCustomPropertiesFiltersAndColumns(
            props.customProperties,
            CustomPropertyType.ORGANISATION,
            'organisation',
        );

        setContactsCustomPropertyColumns(contacts.columns);
        setOrganisationCustomPropertyColumns(organisations.columns);
        setFilterableFieldsContacts([...filterableFieldsContacts, ...contacts.customProperties]);
        setFilterableFieldsOrganisations([...filterableFieldsOrganisations, ...organisations.customProperties]);
    }, []);

    const contactSalesFilters: ContactsExtendedColumn[] = permissions.moneyQuotesInListBuilder.isEnabled
        ? [
              {
                  key: 'people_quote_count',
                  name: 'Number of Quotes',
                  filterFieldType: filterTypes.FieldType.NUMBER,
              },
              {
                  key: 'people_quote_value',
                  name: 'Value of Quotes',
                  filterFieldType: filterTypes.FieldType.NUMBER,
              },
              {
                  key: 'person_sale_date',
                  name: 'Date of Sale',
                  filterFieldType: filterTypes.FieldType.DATE,
              },
              {
                  key: 'person_last_purchase_date',
                  name: 'Date of Last Sale',
                  filterFieldType: filterTypes.FieldType.DATE,
              },
              {
                  key: 'person_quote_date',
                  name: 'Date of Quote',
                  filterFieldType: filterTypes.FieldType.DATE,
              },
              {
                  key: 'person_last_quote_date',
                  name: 'Date of Last Quote',
                  filterFieldType: filterTypes.FieldType.DATE,
              },
              {
                  key: 'person_sales_count',
                  name: 'Number of Sales',
                  filterFieldType: filterTypes.FieldType.NUMBER,
              },
              {
                  key: 'person_sales_amount',
                  name: 'Value of Sales',
                  filterFieldType: filterTypes.FieldType.NUMBER,
              },
              {
                  key: 'person_product_sale',
                  name: 'Product Sales',
                  filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
                  filterFieldAsyncOptions: (filter: string, page?: number) => {
                      const PAGE_SIZE = 100;
                      const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'person_product_sale', SortOrder.ASC, filter);
                      query.setExtendedParameters({
                          fieldname: 'person_product_sale',
                      });
                      return filterOptionsService.getFilterOptions(query);
                  },
              },
              {
                  key: 'people_type',
                  name: 'Purchaser Type',
                  filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
                  filterFieldAsyncOptions: (filter: string, page?: number) => {
                      const PAGE_SIZE = 100;
                      const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'people_type', SortOrder.ASC, filter);
                      query.setExtendedParameters({
                          fieldname: 'people_type',
                      });
                      return filterOptionsService.getFilterOptions(query);
                  },
              },
          ]
        : [];

    const contactDealsFilters: ContactsExtendedColumn[] = permissions.dealsInListBuilder.isEnabled
        ? [
              {
                  key: 'people_deal_name',
                  name: 'Deal Name',
                  filterFieldType: filterTypes.FieldType.STRING,
              },
              {
                  key: 'deal_stage',
                  name: 'Deal Stage',
                  filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
                  filterFieldAsyncOptions: (filter: string, page?: number) => {
                      const PAGE_SIZE = 100;
                      const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'deal_stage', SortOrder.ASC, filter);
                      query.setExtendedParameters({
                          fieldname: 'deal_stage',
                      });
                      return filterOptionsService.getFilterOptions(query);
                  },
              },
              {
                  key: 'pipeline',
                  name: 'Deal Pipeline',
                  filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
                  filterFieldAsyncOptions: (filter: string, page?: number) => {
                      const PAGE_SIZE = 100;
                      const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'pipeline', SortOrder.ASC, filter);
                      query.setExtendedParameters({
                          fieldname: 'pipeline',
                      });
                      return filterOptionsService.getFilterOptions(query);
                  },
              },
              {
                  key: 'person_deals_value',
                  name: 'Value of Deals',
                  filterFieldType: filterTypes.FieldType.CURRENCY,
              },
              {
                  key: 'person_deal_owner',
                  name: 'Deal Owned By',
                  filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
                  filterFieldAsyncOptions: (filter: string, page?: number) => {
                      const PAGE_SIZE = 100;
                      const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'owned_by', SortOrder.ASC, filter);
                      query.setExtendedParameters({
                          fieldname: 'owned_by',
                      });
                      return filterOptionsService.getFilterOptions(query);
                  },
              },
              {
                  key: 'person_deal_type',
                  name: 'Deal Type',
                  filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
                  filterFieldAsyncOptions: (filter: string, page?: number) => {
                      const PAGE_SIZE = 100;
                      const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'deal_type', SortOrder.ASC, filter);
                      query.setExtendedParameters({
                          fieldname: 'deal_type',
                      });
                      return filterOptionsService.getFilterOptions(query);
                  },
              },
              {
                  key: 'person_deal_date_closed_by',
                  name: 'Deal Closed By Date',
                  filterFieldType: filterTypes.FieldType.DATE,
              },
              {
                  key: 'person_deal_date_follow_up',
                  name: 'Deal Follow Up Date',
                  filterFieldType: filterTypes.FieldType.DATE,
              },
              {
                  key: 'person_deal_first_created_date',
                  name: 'Deal First Created Date',
                  filterFieldType: filterTypes.FieldType.DATE,
              },
              {
                  key: 'person_deal_last_deal_closed_date',
                  name: 'Last Deal Closed Date',
                  filterFieldType: filterTypes.FieldType.DATE,
              },
              {
                  key: 'person_deal_last_deal_value',
                  name: 'Last Deal Value',
                  filterFieldType: filterTypes.FieldType.CURRENCY,
              },
              {
                  key: 'person_number_associated_deals',
                  name: 'Number of Associated Deals',
                  filterFieldType: filterTypes.FieldType.NUMBER,
              },
          ]
        : [];

    const organisationSalesFilters: ContactsExtendedColumn[] = permissions.moneyQuotesInListBuilder.isEnabled
        ? [
              {
                  key: 'organisation_sales_count',
                  name: 'Number of Sales',
                  filterFieldType: filterTypes.FieldType.NUMBER,
              },
              {
                  key: 'organisation_sales_amount',
                  name: 'Value of Sales',
                  filterFieldType: filterTypes.FieldType.NUMBER,
              },
              {
                  key: 'organisation_quote_count',
                  name: 'Number of Quotes',
                  filterFieldType: filterTypes.FieldType.NUMBER,
              },
              {
                  key: 'organisation_quote_value',
                  name: 'Value of Quotes',
                  filterFieldType: filterTypes.FieldType.NUMBER,
              },
              {
                  key: 'organisation_sale_date',
                  name: 'Date of Sale',
                  filterFieldType: filterTypes.FieldType.DATE,
              },
              {
                  key: 'organisation_last_purchase_date',
                  name: 'Date of Last Sale',
                  filterFieldType: filterTypes.FieldType.DATE,
              },

              {
                  key: 'organisation_quote_date',
                  name: 'Date of Quote',
                  filterFieldType: filterTypes.FieldType.DATE,
              },
              {
                  key: 'organisation_last_quote_date',
                  name: 'Date of Last Quote',
                  filterFieldType: filterTypes.FieldType.DATE,
              },
              {
                  key: 'organisation_product_sale',
                  name: 'Product Sales',
                  filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
                  filterFieldAsyncOptions: (filter: string, page?: number) => {
                      const PAGE_SIZE = 100;
                      const query = new SearchQuery(
                          page ?? 1,
                          PAGE_SIZE,
                          'organisation_product_sale',
                          SortOrder.ASC,
                          filter,
                      );
                      query.setExtendedParameters({
                          fieldname: 'organisation_product_sale',
                      });
                      return filterOptionsService.getFilterOptions(query);
                  },
              },
          ]
        : [];

    const organisationDealsFilters: ContactsExtendedColumn[] = permissions.dealsInListBuilder.isEnabled
        ? [
              {
                  key: 'organisation_deal_stage',
                  name: 'Deal Stage',
                  filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
                  filterFieldAsyncOptions: (filter: string, page?: number) => {
                      const PAGE_SIZE = 100;
                      const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'deal_stage', SortOrder.ASC, filter);
                      query.setExtendedParameters({
                          fieldname: 'deal_stage',
                      });
                      return filterOptionsService.getFilterOptions(query);
                  },
              },
              {
                  key: 'organisation_deal_name',
                  name: 'Deal Name',
                  filterFieldType: filterTypes.FieldType.STRING,
              },
              {
                  key: 'organisation_pipeline',
                  name: 'Deal Pipeline',
                  filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
                  filterFieldAsyncOptions: (filter: string, page?: number) => {
                      const PAGE_SIZE = 100;
                      const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'pipeline', SortOrder.ASC, filter);
                      query.setExtendedParameters({
                          fieldname: 'pipeline',
                      });
                      return filterOptionsService.getFilterOptions(query);
                  },
              },
              {
                  key: 'organisation_deals_value',
                  name: 'Value of Deals',
                  filterFieldType: filterTypes.FieldType.CURRENCY,
              },
              {
                  key: 'organisation_deal_owner',
                  name: 'Deal Owned By',
                  filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
                  filterFieldAsyncOptions: (filter: string, page?: number) => {
                      const PAGE_SIZE = 100;
                      const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'owned_by', SortOrder.ASC, filter);
                      query.setExtendedParameters({
                          fieldname: 'owned_by',
                      });
                      return filterOptionsService.getFilterOptions(query);
                  },
              },
              {
                  key: 'organisation_deal_type',
                  name: 'Deal Type',
                  filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
                  filterFieldAsyncOptions: (filter: string, page?: number) => {
                      const PAGE_SIZE = 100;
                      const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'deal_type', SortOrder.ASC, filter);
                      query.setExtendedParameters({
                          fieldname: 'deal_type',
                      });
                      return filterOptionsService.getFilterOptions(query);
                  },
              },
              {
                  key: 'organisation_deal_date_closed_by',
                  name: 'Deal Closed By Date',
                  filterFieldType: filterTypes.FieldType.DATE,
              },
              {
                  key: 'organisation_deal_date_follow_up',
                  name: 'Deal Follow Up Date',
                  filterFieldType: filterTypes.FieldType.DATE,
              },
              {
                  key: 'organisation_deal_first_created_date',
                  name: 'Deal First Created Date',
                  filterFieldType: filterTypes.FieldType.DATE,
              },
              {
                  key: 'organisation_deal_last_deal_closed_date',
                  name: 'Last Deal Closed Date',
                  filterFieldType: filterTypes.FieldType.DATE,
              },
              {
                  key: 'organisation_deal_last_deal_value',
                  name: 'Last Deal Value',
                  filterFieldType: filterTypes.FieldType.CURRENCY,
              },
              {
                  key: 'organisation_number_associated_deals',
                  name: 'Number of Associated Deals',
                  filterFieldType: filterTypes.FieldType.NUMBER,
              },
          ]
        : [];

    // Filters
    // this contains the fields that are used in the contacts filterSISP
    const [filterableFieldsContacts, setFilterableFieldsContacts] = useState<ContactsExtendedColumn[]>([
        {
            key: 'fullname',
            name: 'Full Name',
            filterFieldType: filterTypes.FieldType.STRING,
        },
        {
            key: 'firstname',
            name: 'First Name',
            filterFieldType: filterTypes.FieldType.STRING,
        },
        {
            key: 'surname',
            name: 'Last Name',
            filterFieldType: filterTypes.FieldType.STRING,
        },
        ColumnsOptions[ContactsColumnKey.EMAIL],
        ColumnsOptions[ContactsColumnKey.JOB],
        ColumnsOptions[ContactsColumnKey.TITLE],
        ColumnsOptions[ContactsColumnKey.TELEPHONE],
        ColumnsOptions[ContactsColumnKey.PROCESSING_GROUND],
        ColumnsOptions[ContactsColumnKey.ENGAGEMENT_METHOD],
        ColumnsOptions[ContactsColumnKey.OWNER],
        ColumnsOptions[ContactsColumnKey.UNSUBSCRIBED],
        ColumnsOptions[ContactsColumnKey.SUPPRESSED],
        ColumnsOptions[ContactsColumnKey.POINTS_SCORE],
        ColumnsOptions[ContactsColumnKey.TAGS],
        // Filterable fields with custom queries that can't be displayed directly in the DG.
        {
            key: 'opened_email',
            name: 'Opened Email',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'opened_email', SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: 'opened_email',
                });
                return filterOptionsService.getFilterOptions(query);
            },
        },
        {
            key: 'clicked_email',
            name: 'Clicked Email',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'clicked_email', SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: 'clicked_email',
                });
                return filterOptionsService.getFilterOptions(query);
            },
        },
        {
            key: 'bounced_email',
            name: 'Bounced Email',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'bounced_email', SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: 'bounced_email',
                });
                return filterOptionsService.getFilterOptions(query);
            },
        },
        {
            key: 'opened_outreach_email',
            name: 'Opened Outreach Email',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'opened_outreach_email', SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: 'opened_outreach_email',
                });
                return filterOptionsService.getFilterOptions(query);
            },
        },
        {
            key: 'clicked_outreach_email',
            name: 'Clicked Outreach Email',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'clicked_outreach_email', SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: 'clicked_outreach_email',
                });
                return filterOptionsService.getFilterOptions(query);
            },
        },
        {
            key: 'lifecycle_stage',
            name: 'Marketing Funnel Stage',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'lifecycle_stage', SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: 'lifecycle_stage',
                });
                return filterOptionsService.getFilterOptions(query);
            },
        },
        {
            key: 'people_converted_from_campaign',
            name: 'Email Campaign Converted From',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(
                    page ?? 1,
                    PAGE_SIZE,
                    'people_converted_from_campaign',
                    SortOrder.ASC,
                    filter,
                );
                query.setExtendedParameters({
                    fieldname: 'people_converted_from_campaign',
                });
                return filterOptionsService.getFilterOptions(query);
            },
        },
        {
            key: 'filled_out_form',
            name: 'Filled Out Form',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY_DATE,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'filled_out_form', SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: 'filled_out_form',
                });
                return filterOptionsService.getFilterOptions(query);
            },
        },
        {
            key: 'visited_page',
            name: 'Visited Page',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY_DATE,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'visited_page', SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: 'visited_page',
                });
                return filterOptionsService.getFilterOptions(query);
            },
        },
        {
            key: 'list_membership',
            name: 'List Membership',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'list_membership', SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: 'list_membership',
                });
                return filterOptionsService.getFilterOptions(query);
            },
        },
        {
            key: 'last_contact',
            name: 'Last Contact',
            filterFieldType: filterTypes.FieldType.DATE,
        },
        {
            key: 'linked_edu_data',
            name: 'Linked Education Data',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'linked_edu_data', SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: 'linked_edu_data',
                });
                return filterOptionsService.getFilterOptions(query);
            },
        },
        // Append extra filters
        ...contactDealsFilters,
        ...contactSalesFilters,
    ]);

    // this contains the fields that are used in the organisations filters
    const [filterableFieldsOrganisations, setFilterableFieldsOrganisations] = useState<ContactsExtendedColumn[]>([
        ColumnsOptions[ContactsColumnKey.ORGANISATION_NAME],
        ColumnsOptions[ContactsColumnKey.ORGANISATION_BUSINESS_TYPE],
        ColumnsOptions[ContactsColumnKey.ORGANISATION_WEBSITE],
        ColumnsOptions[ContactsColumnKey.ORGANISATION_EMAIL],
        ColumnsOptions[ContactsColumnKey.ORGANISATION_TELEPHONE],
        ColumnsOptions[ContactsColumnKey.ORGANISATION_TOWN],
        ColumnsOptions[ContactsColumnKey.ORGANISATION_COUNTY],
        ColumnsOptions[ContactsColumnKey.ORGANISATION_REGION],
        ColumnsOptions[ContactsColumnKey.ORGANISATION_POSTCODE],
        ColumnsOptions[ContactsColumnKey.ORGANISATION_COUNTRY],
        ColumnsOptions[ContactsColumnKey.ORGANISATION_SECTOR],
        {
            key: 'organisation_converted_from_campaign',
            name: 'Email Campaign Converted From',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(
                    page ?? 1,
                    PAGE_SIZE,
                    'organisation_converted_from_campaign',
                    SortOrder.ASC,
                    filter,
                );
                query.setExtendedParameters({
                    fieldname: 'organisation_converted_from_campaign',
                });
                return filterOptionsService.getFilterOptions(query);
            },
        },

        {
            key: 'org_linked_edu_data',
            name: 'Linked Education Data',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'org_linked_edu_data', SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: 'org_linked_edu_data',
                });
                return filterOptionsService.getFilterOptions(query);
            },
        },
        {
            ...ClientTagsColumn,
            name: 'Organisation Tags',
            filterFieldKey: 'organisation_tags',
            permission: getClientTagsColumn(permissions.organisationsCustomTags).permission,
        },
        // Append extra filters
        ...organisationDealsFilters,
        ...organisationSalesFilters,
    ]);

    const filterSections: filterTypes.FieldFilterSection[] = [
        {
            key: 'primaryFilters',
            name: 'Contact & ' + ucwords(dictionary['organisation']) + ' Filters',
            filterableFields: _.union(
                _.map(
                    _.filter(filterableFieldsContacts, (c) => !!c.filterFieldType),
                    (c) => {
                        return DataGridHelper.columnToFilter(c, { ['Contact']: [] });
                    },
                ),
                _.map(
                    _.filter(filterableFieldsOrganisations, (c) => !!c.filterFieldType),
                    (c) => {
                        return DataGridHelper.columnToFilter(c, { [ucwords(dictionary['organisation'])]: [] });
                    },
                ),
            ),
        },
    ];

    if (
        permissions.edukData.isEnabled &&
        (permissions.edukInboundListFilters.isEnabled || permissions.edukInboundListFilters.isVisible)
    ) {
        const filterableFields = _.map(
            _.filter(SyncedDataFilterableFieldsEDUK, (c) => !!c.filterFieldType),
            (c) => {
                return DataGridHelper.columnToFilter(c, undefined, 'eduk_');
            },
        );

        const ofstedColumns = EdukOfstedColumnsFactory(showLearnMore, permissionFactory);

        const filterableOfsted = _.map(Object.values(ofstedColumns), (c) => {
            return DataGridHelper.columnToFilter(c, undefined, 'eduk_');
        });
        filterableFields.push(...filterableOfsted);

        filterSections.push({
            key: 'edukEducationFilters',
            name: 'UK School Filters',
            filterableFields,
            disabled: !permissions.edukInboundListFilters.isEnabled && permissions.edukInboundListFilters.isVisible,
        });
    }

    if (
        permissions.edinData.isEnabled &&
        (permissions.edinInboundListFilters.isEnabled || permissions.edinInboundListFilters.isVisible)
    ) {
        filterSections.push({
            key: 'edinEducationFilters',
            name: 'International School Filters',
            filterableFields: _.map(
                _.filter(SyncedDataFilterableFieldsEDIN, (c) => !!c.filterFieldType),
                (c) => {
                    return DataGridHelper.columnToFilter(c, undefined, 'edin_');
                },
            ),
            disabled: !permissions.edinInboundListFilters.isEnabled && permissions.edinInboundListFilters.isVisible,
        });
    }

    if (
        permissions.edusData.isEnabled &&
        (permissions.edusInboundListFilters.isEnabled || permissions.edusInboundListFilters.isVisible)
    ) {
        filterSections.push({
            key: 'edusEducationFilters',
            name: 'US School Filters',
            filterableFields: _.map(
                _.filter(SyncedDataFilterableFieldsEDUS, (c) => !!c.filterFieldType),
                (c) => {
                    return DataGridHelper.columnToFilter(c, undefined, 'edus_');
                },
            ),
            disabled: !permissions.edusInboundListFilters.isEnabled && permissions.edusInboundListFilters.isVisible,
        });
    }

    // Edit Columns
    const getEditColumnOptions = (selected: Options<OptionTypeBase>): Options<OptionTypeBase> => {
        // Regular columns
        const availablePeopleOptions = [];
        const availableOrganisationOptions = [];
        const selectedKeys = selected.map((option) => option.value);
        const keys = Object.keys(ColumnsOptions);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i] as unknown as ContactsColumnKey;
            if (!selectedKeys.includes(key)) {
                if (ColumnsOptions[key].isOrganisationColumn) {
                    availableOrganisationOptions.push({
                        value: key,
                        label: (ColumnsOptions[key].name as string).replace(
                            'organisation',
                            ucwords(dictionary['organisation']),
                        ),
                        columnKey: ColumnsOptions[key].key,
                    });
                } else {
                    availablePeopleOptions.push({
                        value: key,
                        label: ColumnsOptions[key].name,
                        columnKey: ColumnsOptions[key].key,
                    });
                }
            }
        }

        // Custom properties
        if (organisationCustomPropertyColumns) {
            const customPropertyKeys = Object.keys(organisationCustomPropertyColumns);
            for (let i = 0; i < customPropertyKeys.length; i++) {
                const key = customPropertyKeys[i];
                if (!selectedKeys.includes(key)) {
                    availableOrganisationOptions.push({
                        value: key,
                        label: organisationCustomPropertyColumns[key].name as string,
                        columnKey: organisationCustomPropertyColumns[key].key,
                    });
                }
            }
        }

        if (contactsCustomPropertyColumns) {
            const customPropertyKeys = Object.keys(contactsCustomPropertyColumns);
            for (let i = 0; i < customPropertyKeys.length; i++) {
                const key = customPropertyKeys[i];
                if (!selectedKeys.includes(key)) {
                    availablePeopleOptions.push({
                        value: key,
                        label: contactsCustomPropertyColumns[key].name as string,
                        columnKey: contactsCustomPropertyColumns[key].key,
                    });
                }
            }
        }

        // Combine
        return [
            { label: 'Selected', options: selected },
            { label: 'Contacts', options: availablePeopleOptions },
            { label: ucwords(dictionary['organisations']), options: availableOrganisationOptions },
        ];
    };

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_contacts.png',
        iconHeight: 180,
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.ADD_CONTACTS),
    };

    const deleteModalMeta: DeleteModalMeta = {
        modal: CRMDeleteModal,
    };

    const [selected, setSelected] = useState<Contact[]>([]);
    const [showBulkEdit, setShowBulkEdit] = useState(false);
    const [reload, setReload] = useState(false);
    useEffect(() => {
        if (reload) {
            setReload(false);
        }
    }, [reload]);

    const SelectComponent: FunctionComponent<ToolbarComponentProps> = (props) => {
        return (
            <ToolbarButton
                buttonText="Edit Selected"
                onButtonPress={(
                    selectedRows: Set<React.Key>,
                    onComplete: () => void,
                    selectedRowData?: any[] | null,
                ) => {
                    setSelected(selectedRowData as Contact[]);
                    setShowBulkEdit(true);
                }}
                {...props}
            />
        );
    };

    return (
        <>
            {showBulkEdit && (
                <ContactsBulkEdit
                    close={() => {
                        setShowBulkEdit(false);
                        setReload(true);
                    }}
                    rows={selected}
                />
            )}
            <CampusDataGrid
                repository={contactsRepository}
                viewsMeta={{
                    request: new ContactSavedViewsRequest(),
                    entityTarget: EntityTarget.CONTACT,
                    displayStringSingular: 'List',
                    displayStringPlural: 'Lists',
                    savedViewType: SavedViewType.MARKETING_LIST,
                    defaultView: defaultView,
                    checkLimitDelegate: async () => {
                        const res = await fetch('/subscribers/lists/api/Contact/check_list_limits');
                        if (res.ok) {
                            const json = await res.json();
                            return json.data;
                        } else {
                            throw new Error('Error fetching Education List limits');
                        }
                    },
                    limitReachedListName: `Contact and ${ucwords(dictionary['organisation'])}`,
                    pinnedFirstTab: (
                        <OverlayTrigger
                            overlay={(overlayProps) => (
                                <Tooltip id="filter-tooltip" {...overlayProps}>
                                    {'All ' + ucwords(dictionary['organisations'])}
                                </Tooltip>
                            )}
                            placement="right"
                        >
                            <a href={'/subscribers/organisations'}>
                                <FontAwesomeIcon icon={faBuilding} />
                            </a>
                        </OverlayTrigger>
                    ),
                    forceLoadViewId: props.activeViewId,
                    updateViewOnSelect: true,
                }}
                actionBarMeta={{
                    searchPlaceHolder: props.searchFilterPlaceholder,
                    includeCounts: true,
                    extraActionBarMeta: {
                        getEditColumnOptionsDelegate: getEditColumnOptions,
                        filterMeta: {
                            defaultActiveKey: 'primaryFilters',
                            fieldFilterSections: filterSections,
                        },
                        exportMeta: {
                            allowExport: true,
                            entity: EntityTarget.CONTACT,
                        },
                    },
                }}
                addSispMeta={{
                    key: UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP),
                    sisp: ContactsAddSisp,
                    showSisp: props.showAddSisp,
                }}
                editSispMeta={{ sisp: ContactsEditSisp }}
                previewSispMeta={{ sisp: ContactsPreviewSisp, key: previewSispUniqueKey }}
                dataGridMeta={{
                    uniqueKey: props.dataGridUniqueKey,
                    entitySingular: props.dataGridEntitySingular,
                    entityPlural: props.dataGridEntityPlural,
                    createButtonLocked: props.creationLocked,
                    columnOptions: {
                        ...ColumnsOptions,
                        ...contactsCustomPropertyColumns,
                        ...organisationCustomPropertyColumns,
                    },
                    defaultColumns: DefaultColumns,
                    frozenColumns: frozenColumns,
                    draggableColumns: true,
                    defaultSortColumn: 'modified',
                    customSelectActionsComponent: canBulkEdit ? SelectComponent : undefined,
                    forceReload: reload,
                }}
                deleteModalMeta={deleteModalMeta}
                promptMeta={promptMeta}
                limitsModalMeta={{
                    limits: [
                        {
                            limitApiKey: 'contacts_limit',
                            nearLimitMethod: NearLimitMethod.PERCENTAGE,
                            percentLimit: 80,
                        },
                    ],
                }}
                learnMoreModal={learnMoreModal}
            />
        </>
    );
};

export default ContactsTable;
