import {
    AppEvent,
    EventBusInstance,
    Modal,
    ModalType,
    LogLevel,
    showBanner,
} from '@sprint/sprint-react-components';
import React, { FunctionComponent, useEffect, useState, useContext } from 'react';
import FormsType from '../../Models/FormsType';
import InnerHTML from '../../Components/DangerouslySetInnerHTML';

interface Props {
    uniqueKey: string;
}

const FormsPreviewModal: FunctionComponent<Props> = (props: Props) => {
    // State: General
    const [shown, setShown] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string>('');
    const [formContents, setFormContents] = useState<string>('');

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<FormsType>) => {
            if (event.target !== props.uniqueKey) return;
            setModalTitle(event.message.name);
            setFormContents(event.message.embed_code);
            setShown(true);
        });
    }, [shown]);

    return (
        <Modal
            className="forms-preview-modal"
            close={() => setShown(false)}
            isOpen={shown}
            title={modalTitle}
            type={ModalType.INFO}
            fullScreen={true}
        >
            <InnerHTML html={formContents} />
        </Modal>
    );
};

export default FormsPreviewModal;
