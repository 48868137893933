import { LogLevel, Modal, ModalType, showBanner } from '@sprint/sprint-react-components';
import React, { FunctionComponent } from 'react';
import DataGridRepository from '../Api/DataGridRepository';

interface Props {
    repository: DataGridRepository<any>;
    selectedRowsForDeletion: number[];
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
    modalShown: boolean;
    reloadDataGrid: () => void;
    closeModal: () => void;
}

const CampusDeleteModal: FunctionComponent<Props> = (props: Props) => {
    const title = () =>
        props.selectedRowsForDeletion.length > 1
            ? `Delete ${props.dataGridEntityPlural}`
            : `Delete ${props.dataGridEntitySingular}`;

    const warning = () =>
        `Are you sure you want to delete ${
            props.selectedRowsForDeletion.length > 1
                ? `these ${props.dataGridEntityPlural.toLowerCase()}`
                : `this ${props.dataGridEntitySingular.toLowerCase()}`
        }?`;

    const banner = () =>
        `${
            props.selectedRowsForDeletion.length > 1 ? props.dataGridEntityPlural : props.dataGridEntitySingular
        } deleted successfully`;

    const deleteRecords = (): Promise<boolean> => {
        return props.repository
            .delete(props.selectedRowsForDeletion)
            .then(() => {
                showBanner({
                    message: banner(),
                    level: LogLevel.SUCCESS,
                });
                props.reloadDataGrid();
                return true;
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Could not delete records - ' + (err?.message ?? err),
                });
                return false;
            });
    };

    return (
        <Modal
            isOpen={props.modalShown}
            type={ModalType.DELETE}
            title={title()}
            close={props.closeModal}
            onSubmit={deleteRecords}
        >
            <>{warning()}</>
        </Modal>
    );
};

export default CampusDeleteModal;
