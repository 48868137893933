import React, { FunctionComponent } from 'react';

interface Props {
    colour: string;
    onSelect: (colour: string) => void;
}

const ColourCircle: FunctionComponent<Props> = (props: Props) => {
    return (
        <span
            onClick={() => {
                return props.onSelect!(props.colour);
            }}
            style={{
                display: 'inline-block',
                backgroundColor: '#' + props.colour,
                width: '20px',
                height: '20px',
                margin: '2px',
                borderRadius: '100%',
                cursor: 'pointer',
            }}
        ></span>
    );
};

export default ColourCircle;
