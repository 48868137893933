import { filter } from '@sprint/sprint-react-components';
import { FilterExtendedColumn } from './CampusDataGrid';

class DataGridHelper {
    public static columnToFilter(
        column: FilterExtendedColumn,
        groups?: { [key: string]: string[] },
        keyPrefix?: string,
    ): filter.FilterableField {
        return {
            key: keyPrefix ? keyPrefix + (column.filterFieldKey ?? column.key) : column.filterFieldKey ?? column.key,
            name: column.filterFieldName ?? column.name,
            type: column.filterFieldType,
            description: column.description,
            options: column.filterFieldOptions ? column.filterFieldOptions() : undefined,
            loadOptions: column.filterFieldAsyncOptions,
            loadSubOptions: column.filterFieldAsyncSubOptions,
            permission: column.permission,
            groups: groups,
        } as filter.FilterableField;
    }
}

export default DataGridHelper;
