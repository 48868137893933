"use strict";

import Organisation from "../Organisation/Organisation";

export default class Contact {

    id = undefined;
    name = '';
    telephone = '';
    email = '';
    organisation = undefined;
    link = '';
    deleted = undefined;

    static blank() {
        return new Contact();
    }

    static fromApi(contact) {
        const entity = Contact.blank();

        // Construct from expected API output object
        // Note: `is_logged_in` will not be set for the below Users, access via Client object
        entity.id = contact.id;
        entity.name = contact.name;
        entity.telephone = contact.telephone;
        entity.email = contact.email;
        entity.organisation = contact.organisation ? Organisation.fromApi(contact.organisation) : undefined;
        entity.link = contact.link;
        entity.deleted = contact.deleted;

        return entity;
    }

    forSelect() {
        return {
            id: this.id,
            label: this.name + (this.organisation ? ' [' + this.organisation.name + ']' : '')
        }
    }

    forApi() {
        const formData = new FormData();

        // Note: FormData will transmit a string of 'undefined' if the field is empty, so check before appending.
        if (this.id) formData.append('id', this.id);
        if (this.name) formData.append('name', this.name);
        if (this.telephone) formData.append('telephone', this.telephone);
        if (this.email) formData.append('email', this.email);
        if (this.organisation) formData.append('organisation_id', this.organisation.id);

        return formData;
    }
}
