export class DateTimeParser {
    static parseDateString = (dateString: string): Date => {
        const validParseDateStringRegexp =
            /^(\d{1,2}(nd|rd|st|th), (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4})$/g;
        if (validParseDateStringRegexp.test(dateString)) {
            const dateTokens = dateString.split(' ');

            // Process Day
            const day = Number.parseInt(dateTokens[0].substring(0, dateTokens[0].length - 3));

            // Process Month (month is index 0)
            let month = 0;
            switch (dateTokens[1]) {
                case 'Jan':
                    month = 0;
                    break;
                case 'Feb':
                    month = 1;
                    break;
                case 'Mar':
                    month = 2;
                    break;
                case 'Apr':
                    month = 3;
                    break;
                case 'May':
                    month = 4;
                    break;
                case 'Jun':
                    month = 5;
                    break;
                case 'Jul':
                    month = 6;
                    break;
                case 'Aug':
                    month = 7;
                    break;
                case 'Sep':
                    month = 8;
                    break;
                case 'Oct':
                    month = 9;
                    break;
                case 'Nov':
                    month = 10;
                    break;
                case 'Dec':
                    month = 11;
                    break;
            }

            // Process Year
            const year = Number.parseInt(dateTokens[2]);

            return new Date(year, month, day);
        }
        throw new Error('Invalid dateString format');
    };
}
