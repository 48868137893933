'use strict';

import EntityState from "../../ValueObjects/EntityState/EntityState";

export default class CampusEntity {
    
    entityState = EntityState.initialState();
    id = undefined;

    static blank() {
        return new CampusEntity();
    }

    static deserialize(plainObject) {
        // Hydrate a new instance of this class
        return Object.assign(CampusEntity.blank(), plainObject, {
            // Call any lower-level deserializers here
        });
    }

    static select(entityArray, id) {
        return entityArray.find(entity => entity.matches(id));
    }

    matches(id) {
        return String(this.id) === String(id);
    }

    clone() {
        return Object.assign(this.constructor.blank(), this);
    }

    state() {
        return this.entityState ? this.entityState.clone() : EntityState.initialState();
    }

    setState(nextState) {
        this.entityState = nextState ? nextState.clone() : EntityState.initialState();
        return this;
    }

    transitionState(action, data) {
        this.entityState = EntityState.transition(this.state(), action, data);
        return this;
    }
}