import { AppEvent, Checkbox, EventBusInstance, isValidString, LogLevel, showBanner, Sisp } from '@sprint/sprint-react-components';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { RepositoryFactoryContext } from '../../index';
import { PaymentTypeRequest } from '../../Api/PaymentTypeRequest';
import PaymentType from '../../Models/PaymentType';
import PaymentTypeEditState from '../../Models/PaymentTypeEditState';

interface Props {
    uniqueKey: string;
    onSuccess: (results: any) => Promise<boolean>;
}

const PaymentTypesEditSisp: FunctionComponent<Props> = (props: Props) => {
    const [shown, setShown] = useState<boolean>(false);

    const paymentTypesRepository = useContext(RepositoryFactoryContext).getApiRepository(new PaymentTypeRequest());

    const focusRef = useRef<HTMLInputElement>(null);

    const [rowId, setRowId] = useState<number>(0);

    const [editPaymentType, setEditPaymentType] = useState<string>('');
    const [editPaymentTypeValid, setEditPaymentTypeValid] = useState<boolean>(true);

    const [editDefault, setEditDefault] = useState<boolean>(false);

    useEffect(() => {
        if (shown) {
            if (focusRef.current) {
                focusRef.current.focus();
                focusRef.current.selectionStart = focusRef.current.value.length;
                focusRef.current.selectionEnd = focusRef.current.value.length;
            }
            setEditPaymentTypeValid(true);        }
    }, [shown]);

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<PaymentTypeEditState>) => {
            if (event.target !== props.uniqueKey) return;
            setRowId(event.message.id);
            setEditPaymentType(event.message.payment_type);
            setEditDefault(event.message.is_default);
            setShown(true);
        });
    }, [shown]);

    const validate = async (): Promise<boolean> => {
        const typeValid = !!editPaymentType && isValidString(editPaymentType);
        setEditPaymentTypeValid(typeValid);
        return typeValid;    };

    const handleEditRow = async (): Promise<boolean> => {
        const PaymentType: PaymentType = {
            id: rowId,
            payment_type: editPaymentType,
            is_default: editDefault,
        };

        return paymentTypesRepository
            .update(PaymentType)
            .then((results: any) => {
                props.onSuccess(results.data);
                return Promise.resolve(true);
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to edit payment type - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return Promise.resolve(false);
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        if ((await validate()) && (await handleEditRow())) setShown(false);
    };

    return (
        <Sisp
            isOpen={shown}
            onSubmit={handleEditRow}
            onCancel={() => {
                setShown(false);
            }}
            validate={validate}
        >
            <h4>Edit a Payment Type</h4>
            <Form onSubmit={onSubmitForm}>
                <Form.Group>
                    <Form.Label>
                        Payment Type <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        ref={focusRef}
                        type="text"
                        isInvalid={!editPaymentTypeValid}
                        value={editPaymentType || ''}
                        onChange={(event) => {
                            setEditPaymentType(event.target.value);
                            setEditPaymentTypeValid(true);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(!editPaymentTypeValid && 'This field is required.')}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Checkbox
                        label="Make this the default payment type"
                        isChecked={editDefault}
                        onChange={(event) => setEditDefault(event.target.checked)}
                    />
                </Form.Group>
            </Form>
        </Sisp>
    );
};

export default PaymentTypesEditSisp;
