'use strict';

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Note: This component requires the bootstrap js and css to be loaded previously
 */
class ToolTip extends React.Component {

    componentDidMount() {
        this.toggleBootstrapTooltips();
    }

    componentDidUpdate() {
        this.toggleBootstrapTooltips();
    }

    toggleBootstrapTooltips() {
        $(this.refs.tooltip).tooltip();
    }

    render() {
        return <span ref="tooltip" title={this.props.tooltip} data-original-title={this.props.tooltip} data-placement={this.props.placement}>
            {this.props.children}
        </span>;
    }

}

ToolTip.propTypes = {
    tooltip: PropTypes.string.isRequired,
    placement: PropTypes.string // top|left|bottom|right
};

ToolTip.defaultProps = {
    placement: 'top'
};

export default ToolTip;