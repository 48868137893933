import { UserPermissionsContext } from '@client/Context/UserPermissions';
import {
    AppEvent,
    EventBusInstance,
    ExtendedColumn,
    filter as filterType,
    HoveroverButton,
    LogLevel,
    SearchQuery,
    showBanner,
    StyledPill,
    UserFormatter,
} from '@sprint/sprint-react-components';
import CSS from 'csstype';
import _ from 'lodash';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { ClientGroupsRequest } from '../../Api/ClientGroupsRequest';
import { EmailsRequest } from '../../Api/EmailsRequest';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import { RepositoryFactoryContext } from '../../index';
import ClientGroup from '../../Models/ClientGroup';
import EmailType from '../../Models/EmailType';
import { ClientGroupType, HTMLColors, UniqueKeyType } from '../../Models/Enums';
import CampusDataGrid, {
    ActionBarMeta,
    AddSispMeta,
    DataGridMeta,
    PreviewSispMeta,
    PromptMeta,
} from '../CampusDataGrid';
import EmailsAddRedirect from './EmailsAddRedirect';
import EmailsEditRedirect from './EmailsEditRedirect';
import EmailsPreviewModal from './EmailsPreviewModal';
import EmailsSendRedirect from './EmailsSendRedirect';
import './EmailsTable.scss';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

enum EmailsColumnKey {
    ID,
    NAME,
    SUBJECT,
    LAST_SENT,
    CAMPAIGN_TYPE,
    FOLDER,
    MODIFIED,
    MODIFIED_BY,
}

enum EmailsFiltersKey {
    FOLDER,
}

const EmailsTable: FunctionComponent<Props> = (props: Props) => {
    const copyEmailUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.COPY_ACTION);
    const editEmailUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);
    const previewEmailUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.PREVIEW_MODAL);
    const sendEmailUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.SEND_ACTION);

    const emailsRepository = useContext(RepositoryFactoryContext).getApiRepository(new EmailsRequest());
    const customPropertiesClientGroupsRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new ClientGroupsRequest(ClientGroupType.EMAILS),
    );
    const userPermissions = useContext(UserPermissionsContext);

    // State: DataGrid
    const [reload, setReload] = useState(false);

    const Columns: Record<EmailsColumnKey, ExtendedColumn> = {
        [EmailsColumnKey.ID]: {
            key: 'id',
            name: 'ID',
            sortable: false,
        },
        [EmailsColumnKey.NAME]: {
            key: 'name',
            name: 'Name',
            sortable: true,
            renderCell: (props) => {
                const id = (props.row as EmailType).id;
                const name = (props.row as EmailType).name;
                return (
                    <>
                        <a
                            href=""
                            className="rdg-link"
                            onClick={(event) => {
                                event.preventDefault();
                                EventBusInstance.publish({
                                    topic: 'show-hoverover-component',
                                    message: props.row as EmailType,
                                    target: previewEmailUniqueKey,
                                });
                            }}
                        >
                            {name}
                        </a>
                        <HoveroverButton
                            contents="Edit"
                            eventBusMessageTarget={editEmailUniqueKey}
                            eventBusMessage={props.row as EmailType}
                        />
                        <HoveroverButton
                            contents="Copy"
                            eventBusMessageTarget={copyEmailUniqueKey}
                            eventBusMessage={props.row as EmailType}
                            pending={activeCopyButtons.indexOf(id ?? 0) > -1}
                        />
                        <HoveroverButton
                            contents="Send"
                            eventBusMessageTarget={sendEmailUniqueKey}
                            eventBusMessage={props.row as EmailType}
                        />
                    </>
                );
            },
            width: '4fr',
        },
        [EmailsColumnKey.SUBJECT]: {
            key: 'subject',
            name: 'Subject',
            sortable: true,
            width: '3fr',
        },
        [EmailsColumnKey.LAST_SENT]: {
            key: 'last_sent',
            name: 'Last Sent',
            sortable: true,
            width: '1fr',
            renderCell: (props) => {
                const last_sent = (props.row as EmailType).last_sent;
                const pillStyle: CSS.Properties = {
                    backgroundColor: last_sent === 'Not Sent' ? HTMLColors.PRIMARY : HTMLColors.SUCCESS,
                };
                return <StyledPill cellContent={last_sent} style={pillStyle} />;
            },
        },
        [EmailsColumnKey.CAMPAIGN_TYPE]: {
            key: 'campaign_type',
            name: 'Campaign Type',
            sortable: true,
            width: '1fr',
        },
        [EmailsColumnKey.FOLDER]: {
            key: 'client_group',
            name: 'Folder',
            sortable: true,
            width: '1fr',
            renderCell: (fprops) => {
                const client_group_info = (fprops.row as EmailType).client_group_info;
                const client_group_name = client_group_info ? client_group_info.name : '';
                return <>{client_group_name}</>;
            },
        },
        [EmailsColumnKey.MODIFIED]: {
            key: 'modified',
            name: 'Modified',
            sortable: true,
            width: '1fr',
        },
        [EmailsColumnKey.MODIFIED_BY]: {
            key: 'modified_by',
            name: 'Modified By',
            sortable: true,
            renderCell: UserFormatter,
            width: '1fr',
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[EmailsColumnKey.NAME],
        Columns[EmailsColumnKey.SUBJECT],
        Columns[EmailsColumnKey.LAST_SENT],
        Columns[EmailsColumnKey.CAMPAIGN_TYPE],
        Columns[EmailsColumnKey.MODIFIED],
        Columns[EmailsColumnKey.MODIFIED_BY],
    ];

    if (userPermissions.clientGroupsEmails.isEnabled) {
        DefaultColumns.splice(4, 0, Columns[EmailsColumnKey.FOLDER]);
    }

    // Filter Bar
    const FilterBarFilters: Record<EmailsFiltersKey, filterType.BasicFilter> = {
        [EmailsFiltersKey.FOLDER]: {
            title: 'Folder',
            name: 'folders',
            loadOptions: async () => {
                const query = new SearchQuery(1, 1000);
                return customPropertiesClientGroupsRepository
                    .search(query)
                    .then((results: any) => {
                        return {
                            options: results.results.map((result: ClientGroup) => {
                                return { value: result.id, label: result.name };
                            }),
                        };
                    })
                    .catch((err: any) => {
                        return null;
                    });
            },
        },
    };

    useEffect(() => {
        listenForCopy();
    }, []);

    let activeCopyButtons: number[] = [];

    const addToActiveButtons = (id: number) => {
        activeCopyButtons.push(id);
    };

    const removeFromActiveButtons = (id: number) => {
        activeCopyButtons = _.filter(activeCopyButtons, (button) => {
            return button !== id;
        });
    };

    // listen for copy click
    const listenForCopy = () => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<EmailType>) => {
            if (event.target !== copyEmailUniqueKey) return;
            // Add to current active buttons
            event.message.id && addToActiveButtons(event.message.id);
            emailsRepository
                .post_action('copy', event.message.id ?? 0)
                .then(() => {
                    // Remove from current active buttons
                    event.message.id && removeFromActiveButtons(event.message.id);
                    showBanner({
                        message: 'Your email was successfully copied.',
                        level: LogLevel.SUCCESS,
                    });
                    setReload(true);
                })
                .catch((err) => {
                    // Remove from current active buttons
                    event.message.id && removeFromActiveButtons(event.message.id);
                    showBanner({
                        message: err.message,
                        level: LogLevel.ERROR,
                    });
                });
        });
    };

    const dataGridMeta: DataGridMeta = {
        uniqueKey: props.dataGridUniqueKey,
        entitySingular: props.dataGridEntitySingular,
        entityPlural: props.dataGridEntityPlural,
        columnOptions: Columns,
        defaultColumns: DefaultColumns,
        frozenColumns: [],
        forceReload: reload,
    };

    const actionBarMeta: ActionBarMeta = {
        searchPlaceHolder: props.searchFilterPlaceholder,
        includeCounts: true,
        filterBarMeta: userPermissions.clientGroupsEmails.isEnabled
            ? { filters: [FilterBarFilters[EmailsFiltersKey.FOLDER]] }
            : undefined,
    };

    const addSispMeta: AddSispMeta = {
        key: UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP),
        sisp: EmailsAddRedirect,
    };

    const previewSispMeta: PreviewSispMeta = {
        key: UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.PREVIEW_SISP),
        sisp: EmailsPreviewModal,
    };

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_emails.png',
        iconHeight: 180,
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.CREATE_EMAIL),
    };

    return (
        <>
            <EmailsSendRedirect uniqueKey={sendEmailUniqueKey} />
            <CampusDataGrid
                repository={emailsRepository}
                actionBarMeta={actionBarMeta}
                addSispMeta={addSispMeta}
                editSispMeta={{ sisp: EmailsEditRedirect }}
                dataGridMeta={dataGridMeta}
                previewSispMeta={previewSispMeta}
                promptMeta={promptMeta}
            />
        </>
    );
};

export default EmailsTable;
