import {
    ClickableLink,
    ExtendedColumn,
    filter as filterType,
    HoveroverButton,
    OptionTypeBase,
    StyledPill,
    User,
    UserFormatter,
} from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext } from 'react';
import { Options } from 'react-select';
import { ucwords } from '../../../../Helpers/StringHelper';
import { SalesRequest } from '../../Api/SalesRequest';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import UserPermissionsHelper from '../../HelperFunctions/UserPermissionsHelper';
import { DictionaryContext, RepositoryFactoryContext, UserContext } from '../../index';
import { DateFilters, HTMLColors, SalesStatus, UniqueKeyType } from '../../Models/Enums';
import SalesType, { Link } from '../../Models/SalesType';
import CampusDataGrid, { PromptMeta } from '../CampusDataGrid';
import SalesAddRedirect from './SalesAddRedirect';
import SalesDeleteModal from './SalesDeleteModal';
import SalesEditRedirect from './SalesEditRedirect';
import './SalesTable.scss';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

enum SalesColumnKey {
    INVOICE,
    DATE,
    DUE_DATE,
    ORGANISATION,
    CONTACT,
    STATUS,
    SOLD_BY,
    TOTAL,
}

enum SalesFilterBarFiltersKey {
    STATUS,
    DATE,
}

const SalesTable: FunctionComponent<Props> = (props: Props) => {
    const editSaleUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);

    const salesRepository = useContext(RepositoryFactoryContext).getApiRepository(new SalesRequest());
    const dictionary = useContext(DictionaryContext);
    const hasProducts = JSON.parse(String((document.getElementById('has_products') as HTMLInputElement).value));
    const user: User = useContext(UserContext);

    const Columns: Record<SalesColumnKey, ExtendedColumn> = {
        [SalesColumnKey.INVOICE]: {
            key: 'invoice',
            name: 'Invoice',
            sortable: true,
            renderCell: (fprops) => {
                const invoice: Link = (fprops.row as SalesType).invoice;
                return (
                    <>
                        <ClickableLink placeholder={invoice.name} url={invoice.url} />
                        <HoveroverButton
                            contents="Edit"
                            eventBusMessageTarget={editSaleUniqueKey}
                            eventBusMessage={fprops.row as SalesType}
                        />
                    </>
                );
            },
            width: '1fr',
        },
        [SalesColumnKey.DATE]: {
            key: 'date',
            name: 'Date',
            sortable: true,
            width: '1fr',
        },
        [SalesColumnKey.DUE_DATE]: {
            key: 'due_date',
            name: 'Due Date',
            sortable: true,
            width: '1fr',
        },
        [SalesColumnKey.ORGANISATION]: {
            key: 'organisation',
            name: ucwords(dictionary['organisation']),
            sortable: true,
            renderCell: (fprops) => {
                const organisation: Link = (fprops.row as SalesType).organisation;
                return <ClickableLink placeholder={organisation.name} url={organisation.url} />;
            },
            width: '1fr',
        },
        [SalesColumnKey.CONTACT]: {
            key: 'contact',
            name: 'Contact',
            sortable: true,
            renderCell: (fprops) => {
                const contact: Link = (fprops.row as SalesType).contact;
                return <ClickableLink placeholder={contact.name} url={contact.url} />;
            },
            width: '1fr',
        },
        [SalesColumnKey.STATUS]: {
            key: 'status',
            name: 'Status',
            sortable: true,
            renderCell: (fprops) => {
                const status = (fprops.row as SalesType).status;
                const findPillStyle = (status: string): string => {
                    switch (status) {
                        case SalesStatus.PAID:
                            return HTMLColors.SUCCESS;
                        case SalesStatus.OVERDUE:
                            return HTMLColors.DANGER;
                        case SalesStatus.UNPAID:
                        default:
                            return HTMLColors.WARNING;
                    }
                };
                return <StyledPill cellContent={status} style={{ backgroundColor: findPillStyle(status) }} />;
            },
            width: '1fr',
        },
        [SalesColumnKey.SOLD_BY]: {
            key: 'sold_by',
            name: 'Sold By',
            sortable: true,
            renderCell: UserFormatter,
            width: '1fr',
        },
        [SalesColumnKey.TOTAL]: {
            key: 'total',
            name: 'Total',
            sortable: true,
            width: '1fr',
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[SalesColumnKey.INVOICE],
        Columns[SalesColumnKey.DATE],
        Columns[SalesColumnKey.DUE_DATE],
        Columns[SalesColumnKey.ORGANISATION],
        Columns[SalesColumnKey.CONTACT],
        Columns[SalesColumnKey.STATUS],
        Columns[SalesColumnKey.SOLD_BY],
        Columns[SalesColumnKey.TOTAL],
    ];

    const getEditColumnOptions = (selected: readonly OptionTypeBase[]): Options<OptionTypeBase> => {
        const availableOptions = [];
        const selectedKeys = selected.map((option) => option.value.toString());
        const keys = Object.keys(Columns);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i] as unknown as SalesColumnKey;
            if (!selectedKeys.includes(key)) {
                availableOptions.push({
                    value: key,
                    label: Columns[key].name,
                    columnKey: Columns[key].key,
                });
            }
        }
        return [
            { label: 'Selected', options: selected },
            { label: props.dataGridEntityPlural, options: availableOptions },
        ];
    };

    // Filter Bar
    const FilterBarFilters: Record<SalesFilterBarFiltersKey, filterType.BasicFilter> = {
        [SalesFilterBarFiltersKey.STATUS]: {
            title: 'Status',
            name: 'status',
            options: [
                {
                    value: 'Unpaid',
                    label: 'Unpaid',
                },
                {
                    value: 'Overdue',
                    label: 'Overdue',
                },
                {
                    value: 'Paid',
                    label: 'Paid',
                },
                {
                    value: 'Part Paid',
                    label: 'Part Paid',
                },
            ],
        },
        [SalesFilterBarFiltersKey.DATE]: {
            title: 'Date',
            name: 'date',
            options: [
                {
                    value: DateFilters.TODAY,
                    label: 'Today',
                },
                {
                    value: DateFilters.YESTERDAY,
                    label: 'Yesterday',
                },
                {
                    value: DateFilters.THIS_WEEK,
                    label: 'This Week',
                },
                {
                    value: DateFilters.THIS_MONTH,
                    label: 'This Month',
                },
                {
                    value: DateFilters.BETWEEN,
                    label: 'Between',
                },
            ],
        },
    };

    const getFilterBarFilters = (): filterType.BasicFilter[] => [
        FilterBarFilters[SalesFilterBarFiltersKey.STATUS],
        FilterBarFilters[SalesFilterBarFiltersKey.DATE],
    ];

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_sales.png',
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.ADD_SALE),
    };

    const productsNeededAllowedPromptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_products.png',
        entityPlural: 'Products',
        iconHeight: 80,
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.MANAGE_PRODUCTS_SERVICES),
        header: "You Can't Create Sales Yet",
        addLineText: 'Before creating a Sale you need to add your products(s)',
        addButtonLabel: 'Add Product',
        addOnClickOverride: () => {
            window.location.href = '/settings/products';
        },
    };

    const productsNeededForbiddenPromptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_products.png',
        entityPlural: 'Products',
        iconHeight: 80,
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.MANAGE_PRODUCTS_SERVICES),
        header: "You Can't Create Sales Yet",
        additionalSubtext: [
            'Before creating a Sale you need to add your products(s).',
            "Unfortunately your user level doesn't allow you to do this. You'll need to ask an Admin or Manager user to add a product first.",
        ],
        hideAdd: true,
    };

    const returnCorrectProductsNeededPrompt = (): PromptMeta => {
        return UserPermissionsHelper.isUserAnAdminOrManager(user)
            ? productsNeededAllowedPromptMeta
            : productsNeededForbiddenPromptMeta;
    };

    return (
        <>
            <CampusDataGrid
                repository={salesRepository}
                actionBarMeta={{
                    searchPlaceHolder: props.searchFilterPlaceholder,
                    includeCounts: true,
                    extraActionBarMeta: {
                        getEditColumnOptionsDelegate: getEditColumnOptions,
                    },
                    filterBarMeta: {
                        filters: getFilterBarFilters(),
                    },
                }}
                dataGridMeta={{
                    uniqueKey: props.dataGridUniqueKey,
                    entitySingular: props.dataGridEntitySingular,
                    entityPlural: props.dataGridEntityPlural,
                    columnOptions: Columns,
                    defaultColumns: DefaultColumns,
                    draggableColumns: true,
                    defaultSortColumn: 'modified',
                }}
                addSispMeta={{
                    key: UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP),
                    sisp: SalesAddRedirect,
                }}
                editSispMeta={{
                    sisp: SalesEditRedirect,
                }}
                deleteModalMeta={{
                    modal: SalesDeleteModal,
                }}
                promptMeta={hasProducts ? promptMeta : returnCorrectProductsNeededPrompt()}
            />
        </>
    );
};

export default SalesTable;
