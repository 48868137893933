import { filter as filterTypes, SearchQuery, SortOrder } from '@sprint/sprint-react-components';
import _ from 'lodash';
import React from 'react';
import { format } from 'date-fns';
import { ContactsExtendedColumn } from '../Grids/CRM/ContactsTable';
import { OrganisationsExtendedColumn } from '../Grids/CRM/OrganisationsTable';
import { FilterExtendedColumn } from '../Grids/CampusDataGrid';
import CustomPropertyFilterType from '../Models/CustomPropertyFilterType';
import { CustomPropertyType } from '../Models/CustomPropertyType';
import ApiFilterOptionsService from '../Grids/CRM/filters/ApiFilterOptionsService';
import { Badge } from 'react-bootstrap';
import { RenderCellProps } from 'react-data-grid';

export const customPropertyDataTypeMap: Map<string, filterTypes.FieldType> = new Map([
    ['text', filterTypes.FieldType.STRING],
    ['textarea', filterTypes.FieldType.STRING],
    ['email', filterTypes.FieldType.STRING],
    ['number', filterTypes.FieldType.NUMBER],
    ['select', filterTypes.FieldType.ENUM_ARRAY],
    ['checkbox', filterTypes.FieldType.ENUM_ARRAY],
    ['radio', filterTypes.FieldType.ENUM_ARRAY],
    ['date', filterTypes.FieldType.DATE],
]);

type ColumnType = ContactsExtendedColumn | OrganisationsExtendedColumn | FilterExtendedColumn;

export const buildCustomPropertiesFiltersAndColumns = (
    allCustomProperties: CustomPropertyFilterType[],
    propertyType: CustomPropertyType,
    subType?: string,
): { customProperties: ColumnType[]; columns: Record<any, ColumnType> } => {
    const customProperties: ColumnType[] = [];
    const columns: Record<any, ColumnType> = {};
    const filterOptionsService = new ApiFilterOptionsService();

    // Add all custom properties to the base field list
    for (const i in allCustomProperties) {
        const property = allCustomProperties[i];
        if (property.type != propertyType || property.archived) continue;

        const propertyId = property.key.split('-')[1];

        const baseFilterableField = {
            key: property.key,
            name: property.name,
            filterFieldType: customPropertyDataTypeMap.get(property.data_type),
            renderCell: (props: RenderCellProps<unknown>) => {
                let rowCustomProperties = (props.row as any).custom_properties;
                if (subType) {
                    rowCustomProperties = (props.row as any)[subType].custom_properties;
                }
                if (customPropertyDataTypeMap.get(property.data_type) === filterTypes.FieldType.ENUM_ARRAY) {
                    if (rowCustomProperties && rowCustomProperties[propertyId]) {
                        return (
                            <p style={{ margin: 0 }} title={rowCustomProperties[propertyId]}>
                                {rowCustomProperties[propertyId].split(/\r?\n/).map((item: any) => {
                                    return (
                                        <Badge style={{ marginRight: '3px' }} key={_.uniqueId()}>
                                            {item}
                                        </Badge>
                                    );
                                })}
                            </p>
                        );
                    } else {
                        return <></>;
                    }
                } else if (customPropertyDataTypeMap.get(property.data_type) === filterTypes.FieldType.DATE) {
                    return (
                        <>
                            {rowCustomProperties?.[propertyId]
                                ? format(rowCustomProperties[propertyId], 'do, LLL yyyy')
                                : ''}
                        </>
                    );
                } else {
                    return <>{rowCustomProperties ? rowCustomProperties[propertyId] : ''}</>;
                }
            },
        };

        const filterableField: ColumnType =
            propertyType === CustomPropertyType.CONTACT || propertyType === CustomPropertyType.ORGANISATION
                ? {
                      ...baseFilterableField,
                      exportFormatter: (entity: any) => {
                          let rowCustomProperties = (entity as any).custom_properties;
                          if (subType) {
                              rowCustomProperties = entity[subType].custom_properties;
                          }
                          return rowCustomProperties ? rowCustomProperties[propertyId] : '';
                      },
                  }
                : baseFilterableField;

        if (filterableField.filterFieldType == filterTypes.FieldType.ENUM_ARRAY) {
            filterableField.filterFieldAsyncOptions = (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, property.key, SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: property.key,
                });
                return filterOptionsService.getFilterOptions(query);
            };
        }
        if (property.options.length > 0) {
            filterableField.filterFieldOptions = () => {
                return property.options;
            };
        }
        columns[filterableField.key] = filterableField;
        customProperties.push(filterableField);
    }

    return { customProperties: customProperties, columns: columns };
};
