import { AppEvent, EventBusInstance } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import EmailType from '../../Models/EmailType';

interface Props {
    uniqueKey: string;
}

const EmailsSendRedirect: FunctionComponent<Props> = (props: Props) => {
    const editUrlPrefix = '/emails/campaigns/add/';
    const [shown, setShown] = useState<boolean>(false);

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<EmailType>) => {
            if (event.target !== props.uniqueKey) return;
            const editUrl = event.message.id ? editUrlPrefix + event.message.id : editUrlPrefix;
            window.location.href = editUrl;
        });
    }, [shown]);

    return <></>;
};

export default EmailsSendRedirect;
