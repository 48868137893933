import {
    DefaultPillFormatter,
    ExtendedColumn,
    HoveroverButton,
    NonSelectableRow,
} from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext } from 'react';
import { TaskTypeRequest } from '../../Api/TaskTypeRequest';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import { UniqueKeyType } from '../../Models/Enums';
import TaskType from '../../Models/TaskType';
import TaskTypeEditState from '../../Models/TaskTypeEditState';
import { RepositoryFactoryContext } from '../../index';
import CampusDataGrid from '../CampusDataGrid';
import TaskTypesAddSisp from './TaskTypesAddSisp';
import TaskTypesEditSisp from './TaskTypesEditSisp';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

enum TaskTypesColumnKey {
    ID,
    TASK_TYPE,
}

const TaskTypesTable: FunctionComponent<Props> = (props: Props) => {
    const addSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP);
    const editSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);

    const taskTypesRepository = useContext(RepositoryFactoryContext).getApiRepository(new TaskTypeRequest());

    const Columns: Record<TaskTypesColumnKey, ExtendedColumn> = {
        [TaskTypesColumnKey.ID]: {
            key: 'id',
            name: 'ID',
            sortable: false,
        },
        [TaskTypesColumnKey.TASK_TYPE]: {
            key: 'type',
            name: 'Name',
            sortable: true,
            resizable: false,
            renderCell: (fprops) => {
                const name = (fprops.row as TaskType).type;
                const is_default = (fprops.row as TaskType).is_default;
                const built_in = (fprops.row as TaskType).built_in;
                const id = (fprops.row as TaskType).id!;
                const eventBusMessage: TaskTypeEditState = { id: id, type: name, is_default: is_default };
                return (
                    <>
                        <DefaultPillFormatter cellContent={name} showPill={is_default}>
                            <HoveroverButton
                                contents="Edit"
                                showHoverover={!built_in}
                                eventBusMessageTarget={editSispUniqueKey}
                                eventBusMessage={eventBusMessage}
                            />
                        </DefaultPillFormatter>
                    </>
                );
            },
        },
    };

    const DefaultColumns: ExtendedColumn[] = [Columns[TaskTypesColumnKey.TASK_TYPE]];

    const findNonSelectableRows = (rows: any): NonSelectableRow[] | null => {
        return rows
            ?.filter((row: any) => (row as TaskType).built_in)
            .map((row: any) => ({ id: row.id, reason: 'Built-in Type' }));
    };

    return (
        <>
            <CampusDataGrid
                repository={taskTypesRepository}
                dataGridMeta={{
                    uniqueKey: props.dataGridUniqueKey,
                    entitySingular: props.dataGridEntitySingular,
                    entityPlural: props.dataGridEntityPlural,
                    defaultColumns: DefaultColumns,
                    columnOptions: Columns,
                    nonSelectableRows: findNonSelectableRows,
                }}
                actionBarMeta={{ searchPlaceHolder: props.searchFilterPlaceholder, includeCounts: true }}
                addSispMeta={{
                    key: addSispUniqueKey,
                    sisp: TaskTypesAddSisp,
                }}
                editSispMeta={{
                    sisp: TaskTypesEditSisp,
                }}
                promptMeta={{
                    icon: '/assets/application/img/prompts/tasks.svg',
                    iconHeight: 90,
                }}
            />
        </>
    );
};

export default TaskTypesTable;
