import { ExtendedColumn, HoveroverButton, LogLevel, SearchQuery, showBanner } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext, useState } from 'react';
import { DictionaryContext, RepositoryFactoryContext } from '../..';
import { ucwords } from '../../../../Helpers/StringHelper';
import { PaymentsRequest, UnpaidInvoicesRequest } from '../../Api/PaymentsRequest';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import { UniqueKeyType } from '../../Models/Enums';
import Payment from '../../Models/Payment';
import PaymentEditState from '../../Models/PaymentEditState';
import UnpaidInvoice from '../../Models/UnpaidInvoice';
import CampusDataGrid, { ActionBarMeta, AddSispMeta, DataGridMeta, PromptMeta } from '../CampusDataGrid';
import PaymentsAddSisp from './PaymentsAddSisp';
import PaymentsEditSisp from './PaymentsEditSisp';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

enum PaymentsColumnKey {
    ID,
    SALE,
    ORGANISATION_NAME,
    TOTAL,
    DATE,
    PAYMENT_METHOD,
}

const PaymentsTable: FunctionComponent<Props> = (props: Props) => {
    const editSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);

    const paymentsRepository = useContext(RepositoryFactoryContext).getApiRepository(new PaymentsRequest());
    const unpaidInvoicesRepository = useContext(RepositoryFactoryContext).getApiRepository(new UnpaidInvoicesRequest());

    const dictionary = useContext(DictionaryContext);

    const [unpaidInvoices, setUnpaidInvoices] = useState<UnpaidInvoice[]>([]);
    const [showAddButton, setShowAddButton] = useState<boolean>(false);

    const Columns: Record<PaymentsColumnKey, ExtendedColumn> = {
        [PaymentsColumnKey.ID]: {
            key: 'id',
            name: 'ID',
        },
        [PaymentsColumnKey.SALE]: {
            key: 'invoice_number',
            name: 'Sale',
            sortable: true,
            renderCell: (fprops) => {
                const invoice_id = (fprops.row as Payment).invoice_id;
                const url = 'view/' + invoice_id;
                const invoice_number = (fprops.row as Payment).invoice_number;

                return <a href={url}>{invoice_number}</a>;
            },
            width: '1fr',
        },
        [PaymentsColumnKey.ORGANISATION_NAME]: {
            key: 'organisation_name',
            name: ucwords(dictionary['organisation']),
            sortable: true,
            renderCell: (fprops) => {
                const organisation_id = (fprops.row as Payment).organisation_id;
                const url = '../subscribers/organisations/view/' + organisation_id;
                const organisation_name = (fprops.row as Payment).organisation_name;

                return <a href={url}>{organisation_name}</a>;
            },
            width: '1fr',
        },
        [PaymentsColumnKey.TOTAL]: {
            key: 'amount_received',
            name: 'Total',
            sortable: true,
            width: '1fr',
        },
        [PaymentsColumnKey.DATE]: {
            key: 'payment_date',
            name: 'Date',
            sortable: true,
            renderCell: (fprops) => {
                const id = (fprops.row as Payment).id!;
                const invoice_id = (fprops.row as Payment).invoice_id;
                const invoice_number = (fprops.row as Payment).invoice_number;
                const amount_received = (fprops.row as Payment).amount_received;
                const payment_date = (fprops.row as Payment).payment_date;
                const payment_type = (fprops.row as Payment).payment_type;
                const eventBusMessage: PaymentEditState = {
                    id: id,
                    invoice_id: invoice_id,
                    invoice_number: invoice_number,
                    amount_received: amount_received,
                    payment_date: payment_date,
                    payment_type: payment_type,
                };
                return (
                    <>
                        {payment_date}
                        <HoveroverButton
                            contents="Edit"
                            showHoverover={true}
                            eventBusMessageTarget={editSispUniqueKey}
                            eventBusMessage={eventBusMessage}
                        />
                    </>
                );
            },
            width: '1fr',
        },
        [PaymentsColumnKey.PAYMENT_METHOD]: {
            key: 'payment_type',
            name: 'Payment Method',
            sortable: true,
            width: '1fr',
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[PaymentsColumnKey.DATE],
        Columns[PaymentsColumnKey.SALE],
        Columns[PaymentsColumnKey.ORGANISATION_NAME],
        Columns[PaymentsColumnKey.PAYMENT_METHOD],
        Columns[PaymentsColumnKey.TOTAL],
    ];

    const loadUnpaidInvoices = () => {
        const invoice_query = new SearchQuery(1, 100);
        unpaidInvoicesRepository
            .search(invoice_query)
            .then((results: any) => {
                if (results.results.length > 0) {
                    setShowAddButton(true);
                    setUnpaidInvoices(results.results);
                } else {
                    setShowAddButton(false);
                }
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get Unpaid Invoices - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                    dismissable: false,
                });
            });
    };

    const dataGridMeta: DataGridMeta = {
        uniqueKey: props.dataGridUniqueKey,
        entitySingular: props.dataGridEntitySingular,
        entityPlural: props.dataGridEntityPlural,
        columnOptions: Columns,
        defaultColumns: DefaultColumns,
        draggableColumns: true,
        frozenColumns: [],
        beforeOnGetRows: loadUnpaidInvoices,
    };

    const actionBarMeta: ActionBarMeta = {
        searchPlaceHolder: props.searchFilterPlaceholder,
        includeCounts: true,
        hideAddButton: !showAddButton,
    };

    const addSispMeta: AddSispMeta = {
        key: UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP),
        sisp: PaymentsAddSisp,
    };

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_payments.png',
        iconHeight: 180,
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.ADD_PAYMENT),
    };

    const noSalePromptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_sales.png',
        entitySingular: 'Sale',
        entityPlural: 'Sales',
        iconHeight: 180,
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.ADD_SALE),
        additionalSubtext: ['To add a payment you first need to create a Sale.'],
        addOnClickOverride: () => {
            window.location.href = '/sales/home/add';
        },
    };

    return (
        <>
            <CampusDataGrid
                repository={paymentsRepository}
                actionBarMeta={actionBarMeta}
                addSispMeta={addSispMeta}
                editSispMeta={{ sisp: PaymentsEditSisp }}
                dataGridMeta={dataGridMeta}
                promptMeta={showAddButton ? promptMeta : noSalePromptMeta}
            />
        </>
    );
};
export default PaymentsTable;
