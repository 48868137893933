import { AppEvent, EventBusInstance, Sisp } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import GlossaryItem from '../../Models/GlossaryItem';

const GlossarySisp: FunctionComponent = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [glossaryItem, setGlossaryItem] = useState<GlossaryItem>();

    // On Component did Mount
    useEffect(() => {
        // listen for glossary item click
        EventBusInstance.subscribe('update-datagrid-rows', async (event: AppEvent<GlossaryItem>) => {
            if (event.target !== 'handle-glossary-item-click') return;
            setGlossaryItem(event.message);
            setIsOpen(true);
        });
    }, []);

    return (
        <Sisp isOpen={isOpen} showSubmitButton={false} onCancel={() => setIsOpen(false)}>
            <h4>
                {glossaryItem?.item}
                {glossaryItem?.abbreviation && ' (' + glossaryItem?.abbreviation + ')'}
            </h4>
            {glossaryItem?.description && <span dangerouslySetInnerHTML={{ __html: glossaryItem?.description }} />}
        </Sisp>
    );
};
export default GlossarySisp;
