'use strict';

import React from 'react';

export default class User {

    avatar = '';
    email = '';
    fullName = '';
    id = undefined;
    isLoggedIn = false;
    level = '';
    name = '';
    type = '';
    firstName = '';

    static blank() {
        return new User();
    }

    static fromApi(user) {
        const entity = User.blank();

        // Construct from expected API output object
        entity.avatar = user.avatar;
        entity.email = user.email;
        entity.fullName = user.full_name;
        entity.id = user.id;
        entity.isLoggedIn = user.is_logged_in;
        entity.level = user.level;
        entity.name = user.name;
        entity.type = user.type;
        entity.firstName = user.first_name;

        return entity;
    }

    forSelect() {
        return {
            id: this.id,
            label: this.name,
            labelElement: React.createElement('span', null, [
                React.createElement('img', {
                    key: this.id,
                    src: this.avatar,
                    className: 'img-rounded',
                    alt: ''
                }),
                ` ${this.name}`
            ]),
        }
    }

    isPrivileged() {
        return this.isAdmin() || this.isManager();
    }

    isAdmin() {
        return (this.level === 'admin');
    }

    isManager() {
        return (this.level === 'manager');
    }

}
