import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { filter as filterTypes } from '@sprint/sprint-react-components';
import _ from 'lodash';
import React from 'react';
import { renderToString } from 'react-dom/server';
import * as FilterGroups from '../helpers/FilterGroups';
import EdApiCustomField, { CustomFieldType } from '../models/edapi/EdApiCustomField';
import { getIconFromName } from './FontAwesomeIconHelper';

export const convertCustomFieldTypeToFieldType = (t: CustomFieldType): filterTypes.FieldType => {
    switch (t) {
        case CustomFieldType.BOOLEAN:
            return filterTypes.FieldType.BOOLEAN;
        case CustomFieldType.NUMBER:
            return filterTypes.FieldType.NUMBER;
        case CustomFieldType.DATE:
            return filterTypes.FieldType.DATE;
        case CustomFieldType.LIST:
            return filterTypes.FieldType.ENUM;
        case CustomFieldType.STRING:
            return filterTypes.FieldType.STRING;
    }
};

export const getFilterableFieldKeyFromCustomField = (c: EdApiCustomField): string => {
    return 'customFields.' + c.name.toLocaleLowerCase().split(' ').join('_');
};

export const generateWarningText = (hasExpiredCustomField: boolean, hasUnauthorisedCustomField: boolean): string => {
    let text = 'This list contains filters ';
    if (hasExpiredCustomField && hasUnauthorisedCustomField) {
        text += 'on expired data and data you no longer have access to.';
    } else if (hasExpiredCustomField) {
        text += 'on expired data.';
    } else {
        text += 'on data you no longer have access to.';
    }
    return text;
};

export const buildCustomFieldFilters = (
    customFields: EdApiCustomField[],
    permissions: any,
    forPeopleFilters?: boolean,
): filterTypes.FilterableField[] => {
    // Build FilterableField array from Custom Fields
    const arr: filterTypes.FilterableField[] = [];
    _.each(customFields, (c) => {
        // Build enum options
        const options =
            c.type === CustomFieldType.LIST && c.listItems
                ? _.map(c.listItems.split(';'), (item) => {
                      return {
                          value: item,
                          name: item,
                      };
                  })
                : undefined;

        // Build Filter Groups
        let filterGroups: { [key: string]: string[] } = {};
        if (c.tags.length === 0) {
            // If there are no tags assigned to the Custom Field, put it under the General category
            filterGroups = { [FilterGroups.GENERAL]: [] };
        } else {
            _.each(c.tags, (tag) => {
                // For each tag on the Custom Field add as a new group
                const icon = <FontAwesomeIcon className="mr-2" icon={getIconFromName(tag.icon)} />;
                const stringVal = renderToString(icon) + ' ' + tag.tag;
                filterGroups[stringVal] = [];
            });
        }

        // Generate warning message if needed
        let allowed = true;
        let warning: string | null = null;
        if (!c.includeInCCFilterSisp) {
            // Check if Custom Field is allowed in filters
            allowed = false;
            warning =
                "This data shouldn't be used for filtering. Please remove this filter to continue using this list.";
        } else if (c.expireAt && new Date(c.expireAt) < new Date()) {
            // Check if Custom Field data has expired
            allowed = false;
            warning = 'This data has now expired. Please remove this filter to continue using this list.';
        } else if (!_.includes(Object.keys(permissions), c.permissionItemName)) {
            // Check user has permissions to see field
            allowed = false;
            warning =
                'You no longer have permissions to access this data. Please remove this filter to continue using this list.';
        }

        arr.push({
            key: (forPeopleFilters ? 'establishment.' : '') + getFilterableFieldKeyFromCustomField(c),
            editWarning: warning,
            editReadOnly: !allowed,
            name: c.name,
            description: c.description,
            type: convertCustomFieldTypeToFieldType(c.type),
            options,
            groups: filterGroups,
        } as filterTypes.FilterableField);
    });
    return arr;
};
