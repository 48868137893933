import { AppEvent, EventBusInstance } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import LimitIssue from '../../Models/LimitIssue';

declare function dictionaryValue(key: string): string;

interface Props {
    uniqueKey: string;
    limitName: string;
}

const SingleLimitBanner: FunctionComponent<Props> = (props: Props) => {
    // State: General
    const [shown, setShown] = useState<boolean>(false);
    const defaultBannerState = {
        nearLimit: false,
        atLimit: false,
        dismissable: true,
    };
    const [bannerState, setBannerState] = useState(defaultBannerState);
    const [usedCount, setUsedCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(0);

    useEffect(() => {
        EventBusInstance.subscribe('limit-banner', (event: AppEvent<LimitIssue | null>) => {
            if (event.target !== props.uniqueKey) return;
            if (event.message === null) {
                setShown(false);
            } else {
                setBannerState(
                    event.message.near_limit
                        ? { ...defaultBannerState, nearLimit: true }
                        : { ...defaultBannerState, atLimit: true, dismissable: false },
                );
                setUsedCount(event.message.used_count);
                setLimit(event.message.limit);
                setShown(true);
            }
        });
    }, []);

    const supportEmail = dictionaryValue('company.email_support');
    const mailToSupport = `mailto:${supportEmail}`;

    return (
        <div className="banner-container">
            <Alert show={shown} onClose={() => setShown(false)} variant="danger" dismissible={bannerState.dismissable}>
                {bannerState.nearLimit && (
                    <>
                        <b>
                            You've used {usedCount}/{limit} {props.limitName} and are nearing your limit.
                        </b>{' '}
                        Unlock space for more by&nbsp;
                        <a
                            href={mailToSupport}
                            className="rdg-link"
                            style={{ color: '#ffffff', textDecoration: 'underline' }}
                        >
                            upgrading your plan.
                        </a>
                    </>
                )}
                {bannerState.atLimit && (
                    <>
                        <b>
                            You've reached your limit of {limit} {props.limitName}.
                        </b>{' '}
                        Unlock space for more by&nbsp;
                        <a
                            href={mailToSupport}
                            className="rdg-link"
                            style={{ color: '#ffffff', textDecoration: 'underline' }}
                        >
                            upgrading your plan.
                        </a>
                    </>
                )}
            </Alert>
        </div>
    );
};

export default SingleLimitBanner;
