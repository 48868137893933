import React, { FunctionComponent, PropsWithChildren } from 'react';
import './prompt.scss';

interface Props {
    imgSrc?: string;
    imgHeight?: string | number;
    header?: string;
    subHeader?: string | JSX.Element;
    moduleLevel?: boolean;
}

const Prompt: FunctionComponent<Props> = (props: PropsWithChildren<Props>) => {
    const classWithBottomSpacing = (className: string, aboveSubHeader?: string | JSX.Element) =>
        className + (!aboveSubHeader && !props.children ? ' no-bottom' : '');

    return (
        <div className={'react-prompt-panel' + (props.moduleLevel ? ' module' : '')}>
            {props.imgSrc && <img className="prompt-panel--image" src={props.imgSrc} alt="" height={props.imgHeight} />}
            <h4 className={classWithBottomSpacing('prompt-panel--header', props.subHeader)}>{props.header}</h4>
            {props.subHeader ? (
                typeof props.subHeader === 'object' ? (
                    <div className={classWithBottomSpacing('prompt-panel--sub-header')}>{props.subHeader}</div>
                ) : (
                    <p className={classWithBottomSpacing('prompt-panel--sub-header')}>{props.subHeader}</p>
                )
            ) : null}
            {props.children}
        </div>
    );
};

Prompt.defaultProps = {
    imgSrc: '',
    imgHeight: '',
    header: 'No Items Found',
    subHeader: '',
};

export default Prompt;
