import { UserPermissionsContext } from '@client/Context/UserPermissions';
import {
    ClickableLink,
    EntityTarget,
    ExtendedColumn,
    filter as filterTypes,
    HoveroverButton,
    OptionTypeBase,
    SearchQuery,
    showBanner,
    SortOrder,
    StyledPill,
    ToolbarButton,
    ToolbarComponentProps,
    UserFormatter,
} from '@sprint/sprint-react-components';
import CSS from 'csstype';
import _ from 'lodash';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Options } from 'react-select';
import { DEFAULT_COLUMN_WIDTH } from '../../../EducationDataGrid/constant';
import { ContactsRequest } from '../../Api/ContactsRequest';
import { DealsRequest } from '../../Api/DealsRequest';
import { OrganisationsRequest } from '../../Api/OrganisationsRequest';
import { TaskSavedViewsRequest } from '../../Api/TaskSavedViewsRequest';
import { TaskPriorityRequest, TasksRequest, TaskStatusRequest } from '../../Api/TasksRequest';
import { TaskTypeRequest } from '../../Api/TaskTypeRequest';
import { UserTypeRequest } from '../../Api/UserTypeRequest';
import { SavedInboundView } from '../../Components/SavedInboundView';
import { SavedViewType } from '../../Components/ViewsModal';
import { buildCustomPropertiesFiltersAndColumns } from '../../HelperFunctions/CustomPropertiesFiltersAndColumnsBuilder';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import { PermissionsContext, RepositoryFactoryContext } from '../../index';
import Contact from '../../Models/Contact';
import { CustomPropertyType } from '../../Models/CustomPropertyType';
import { Deal } from '../../Models/Deal';
import { HTMLColors, TasksStatus, UniqueKeyType } from '../../Models/Enums';
import Organisation from '../../Models/Organisation';
import TasksType, { RelatesTo } from '../../Models/TasksType';
import TaskType from '../../Models/TaskType';
import UserType from '../../Models/UserType';
import CampusDataGrid, { DeleteModalMeta, FilterExtendedColumn, PromptMeta, ViewsMeta } from '../CampusDataGrid';
import DataGridHelper from '../DataGridHelper';
import TasksAddSisp from './TasksAddSisp';
import TasksBulkEdit from './TasksBulkEdit';
import TasksDeleteModal from './TasksDeleteModal';
import TasksEditSisp from './TasksEditSisp';
import TasksPreviewSisp from './TasksPreviewSisp';
import './TasksTable.scss';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
    customProperties: any;
}

interface parseCallbackInterface {
    (inputArray: []): filterTypes.EnumOption[];
}

enum TasksColumnKey {
    NAME,
    STATUS,
    PRIORITY,
    TYPE,
    ASSIGNED_TO,
    DUE_DATE,
    RELATES_TO,
}

const TasksTable: FunctionComponent<Props> = (props: Props) => {
    const addSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP);
    const editSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);
    const previewSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.PREVIEW_SISP);

    const tasksRepository = useContext(RepositoryFactoryContext).getApiRepository(new TasksRequest());
    const taskStatusRepository = useContext(RepositoryFactoryContext).getApiRepository(new TaskStatusRequest());
    const taskPriorityRepository = useContext(RepositoryFactoryContext).getApiRepository(new TaskPriorityRequest());
    const taskTypeRepository = useContext(RepositoryFactoryContext).getApiRepository(new TaskTypeRequest());
    const usersRepository = useContext(RepositoryFactoryContext).getApiRepository(new UserTypeRequest());
    const contactsRepository = useContext(RepositoryFactoryContext).getApiRepository(new ContactsRequest());
    const organisationsRepository = useContext(RepositoryFactoryContext).getApiRepository(new OrganisationsRequest());
    const dealsRepository = useContext(RepositoryFactoryContext).getApiRepository(new DealsRequest());

    const permissions = useContext(PermissionsContext);
    const userPermissions = useContext(UserPermissionsContext);

    const canBulkEdit = userPermissions.bulkEditTasks.isEnabled;

    // State: Columns
    const Columns: Record<TasksColumnKey, FilterExtendedColumn> = {
        [TasksColumnKey.NAME]: {
            key: 'name',
            name: 'Name',
            sortable: true,
            width: '3fr',
            renderCell: (props) => {
                const name = (props.row as TasksType).name;
                return (
                    <>
                        <ClickableLink placeholder={name.value} url={name.url} />
                        <HoveroverButton
                            contents="Preview"
                            eventBusMessageTarget={previewSispUniqueKey}
                            eventBusMessage={props.row as TasksType}
                        />
                        <HoveroverButton
                            contents="Edit"
                            showHoverover={name.can_edit}
                            eventBusMessageTarget={editSispUniqueKey}
                            eventBusMessage={props.row as TasksType}
                        />
                    </>
                );
            },
        },
        [TasksColumnKey.STATUS]: {
            key: 'status',
            name: 'Status',
            sortable: true,
            width: '1fr',
            renderCell: (props) => {
                const getStatusColor = (status: string) => {
                    switch (status) {
                        case TasksStatus.TO_DO:
                            return HTMLColors.WARNING;
                        case TasksStatus.IN_PROGRESS:
                            return HTMLColors.PRIMARY;
                        case TasksStatus.COMPLETE:
                            return HTMLColors.SUCCESS;
                        case TasksStatus.ON_HOLD:
                        default:
                            return HTMLColors.DANGER;
                    }
                };
                const status = (props.row as TasksType).status;
                const pillStyle: CSS.Properties = {
                    backgroundColor: getStatusColor(status),
                };
                return <StyledPill cellContent={status} style={pillStyle} />;
            },
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                return getFilterFieldOptions(
                    taskStatusRepository,
                    new SearchQuery(page ?? 1, 100, '', SortOrder.NONE, filter),
                    parseFilterFieldEnumArray,
                );
            },
        },
        [TasksColumnKey.PRIORITY]: {
            key: 'priority',
            name: 'Priority',
            sortable: true,
            width: '2fr',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                return getFilterFieldOptions(
                    taskPriorityRepository,
                    new SearchQuery(page ?? 1, 100, '', SortOrder.NONE, filter),
                    parseFilterFieldEnumArray,
                );
            },
        },
        [TasksColumnKey.TYPE]: {
            key: 'type',
            name: 'Type',
            sortable: true,
            width: '2fr',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const parseTaskTypeDataGridArray = (filterFieldDataGridArray: []) => {
                    return filterFieldDataGridArray.map((taskType: TaskType) => {
                        return { value: taskType.id!, name: taskType.type };
                    });
                };
                return getFilterFieldOptions(
                    taskTypeRepository,
                    new SearchQuery(page ?? 1, 100, 'id', SortOrder.ASC, filter),
                    parseTaskTypeDataGridArray,
                );
            },
        },
        [TasksColumnKey.ASSIGNED_TO]: {
            key: 'assigned_to',
            name: 'Assigned To',
            renderCell: UserFormatter,
            sortable: true,
            width: '2fr',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                return getFilterFieldOptions(
                    usersRepository,
                    new SearchQuery(page ?? 1, 100, 'id', SortOrder.ASC, filter),
                    parseUsersDataGridArray,
                );
            },
        },
        [TasksColumnKey.DUE_DATE]: {
            key: 'due_date',
            name: 'Due Date',
            sortable: true,
            width: '2fr',
            renderCell: (props) => {
                const due_date = (props.row as TasksType).due_date;
                return <p style={{ color: due_date.is_overdue ? HTMLColors.DANGER : 'inherit' }}> {due_date.date} </p>;
            },
            filterFieldType: filterTypes.FieldType.DATE,
        },
        [TasksColumnKey.RELATES_TO]: {
            key: 'relates_to',
            name: 'Relates To ',
            sortable: true,
            width: '2fr',
            renderCell: (props) => {
                const relates_to_deal = (props.row as TasksType).relates_to_deal;
                const relates_to_contact = (props.row as TasksType).relates_to_contact;
                const relates_to_organisation = (props.row as TasksType).relates_to_organisation;
                let relates_to: RelatesTo | null = null;

                if (relates_to_deal) {
                    relates_to = relates_to_deal;
                } else if (relates_to_contact) {
                    relates_to = relates_to_contact;
                } else if (relates_to_organisation) {
                    relates_to = relates_to_organisation;
                }

                return relates_to ? <ClickableLink placeholder={relates_to.name} url={relates_to.url} /> : null;
            },
        },
    };

    const DefaultColumns: FilterExtendedColumn[] = [
        Columns[TasksColumnKey.NAME],
        Columns[TasksColumnKey.STATUS],
        Columns[TasksColumnKey.PRIORITY],
        Columns[TasksColumnKey.TYPE],
        Columns[TasksColumnKey.ASSIGNED_TO],
        Columns[TasksColumnKey.DUE_DATE],
        Columns[TasksColumnKey.RELATES_TO],
    ];

    const [taskCustomPropertyColumns, setTaskCustomPropertyColumns] = useState<Record<any, FilterExtendedColumn>>();

    // On Did Mount
    // Set up custom property filters and columns
    useEffect(() => {
        if (userPermissions.customPropertiesTasks.isEnabled) {
            const { columns, customProperties } = buildCustomPropertiesFiltersAndColumns(
                props.customProperties,
                CustomPropertyType.TASK,
            );
            setTaskCustomPropertyColumns(columns);
            setFilterableFieldsTasks([...filterableFieldsTasks, ...customProperties]);
        }
    }, []);

    const parseFilterFieldEnumArray = (filterFieldStringArray: []) => {
        return filterFieldStringArray.map((filterField: string) => {
            return { value: filterField, name: filterField };
        });
    };

    const parseUsersDataGridArray = (filterFieldDataGridArray: []) => {
        return filterFieldDataGridArray.map((user: UserType) => {
            return {
                value: user.id,
                name: user.name,
            } as filterTypes.EnumOption;
        });
    };

    const getFilterFieldOptions = (repository: any, query: SearchQuery, callback: parseCallbackInterface) => {
        return repository
            .search(query)
            .then((result: any) => {
                const res: filterTypes.EnumOption[] = callback(result.results);
                res.push({
                    value: result.counts.currentPage < result.counts.totalPages,
                    name: 'has_more',
                });
                return res;
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get filter field options - ' + (err?.message ?? err),
                });
                return [];
            });
    };

    const dealFilters = permissions['deal_filters_module']
        ? [
              {
                  key: 'relates_to_deal',
                  name: 'Relates To Deal',
                  filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
                  filterFieldAsyncOptions: (filter: string, page?: number) => {
                      const parseDealsDataGridArray = (filterFieldDataGridArray: []) => {
                          return filterFieldDataGridArray.map((deal: Deal) => {
                              return { value: deal.id!, name: `${deal.name} [${deal.value.formatted}]` };
                          });
                      };
                      return getFilterFieldOptions(
                          dealsRepository,
                          new SearchQuery(page ?? 1, 100, 'd.name', SortOrder.ASC, filter),
                          parseDealsDataGridArray,
                      );
                  },
              },
          ]
        : [];

    // Filters
    const [filterableFieldsTasks, setFilterableFieldsTasks] = useState<FilterExtendedColumn[]>([
        Columns[TasksColumnKey.STATUS],
        Columns[TasksColumnKey.PRIORITY],
        Columns[TasksColumnKey.TYPE],
        Columns[TasksColumnKey.ASSIGNED_TO],
        {
            key: 'assigned_by',
            name: 'Assigned By',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                return getFilterFieldOptions(
                    usersRepository,
                    new SearchQuery(page ?? 1, 100, 'id', SortOrder.ASC, filter),
                    parseUsersDataGridArray,
                );
            },
        },
        Columns[TasksColumnKey.DUE_DATE],
        {
            key: 'completed_date',
            name: 'Completed Date',
            filterFieldType: filterTypes.FieldType.DATE,
        },
        {
            key: 'relates_to_contact',
            name: 'Relates To Contact',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const parseContactsDataGridArray = (filterFieldDataGridArray: []) => {
                    return filterFieldDataGridArray.map((contact: Contact) => {
                        return { value: contact.id!, name: `${contact.full_name} [${contact.organisation.name}]` };
                    });
                };
                return getFilterFieldOptions(
                    contactsRepository,
                    new SearchQuery(page ?? 1, 100, 'co.firstname', SortOrder.ASC, filter),
                    parseContactsDataGridArray,
                );
            },
        },
        {
            key: 'relates_to_organisation',
            name: 'Relates To Organisation',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const parseOrganisationsDataGridArray = (filterFieldDataGridArray: []) => {
                    return filterFieldDataGridArray.map((organisation: Organisation) => {
                        return { value: organisation.id, name: organisation.name };
                    });
                };
                return getFilterFieldOptions(
                    organisationsRepository,
                    new SearchQuery(page ?? 1, 100, 'o.organisation_name', SortOrder.ASC, filter),
                    parseOrganisationsDataGridArray,
                );
            },
        },
        {
            key: 'created_by',
            name: 'Created By',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                return getFilterFieldOptions(
                    usersRepository,
                    new SearchQuery(page ?? 1, 100, 'id', SortOrder.ASC, filter),
                    parseUsersDataGridArray,
                );
            },
        },
        {
            key: 'modified_by',
            name: 'Modified By',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                return getFilterFieldOptions(
                    usersRepository,
                    new SearchQuery(page ?? 1, 100, 'id', SortOrder.ASC, filter),
                    parseUsersDataGridArray,
                );
            },
        },
        // Additional filters
        ...dealFilters,
    ]);

    const filterSections: filterTypes.FieldFilterSection[] = [
        {
            key: 'primaryFilters',
            name: 'Task Filters',
            filterableFields: _.map(
                _.filter(filterableFieldsTasks, (c) => !!c.filterFieldType),
                (c) => {
                    return DataGridHelper.columnToFilter(c);
                },
            ),
        },
    ];

    const getEditColumnOptions = (selected: readonly OptionTypeBase[]): Options<OptionTypeBase> => {
        const availableOptions = [];
        const selectedKeys = selected.map((option) => option.value.toString());
        const keys = Object.keys(DefaultColumns);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i] as unknown as TasksColumnKey;
            if (!selectedKeys.includes(key)) {
                availableOptions.push({
                    value: key,
                    label: DefaultColumns[key].name,
                    columnKey: DefaultColumns[key].key,
                });
            }
        }
        // Custom properties
        if (taskCustomPropertyColumns) {
            const customPropertyKeys = Object.keys(taskCustomPropertyColumns);
            for (let i = 0; i < customPropertyKeys.length; i++) {
                const key = customPropertyKeys[i];
                if (!selectedKeys.includes(key)) {
                    availableOptions.push({
                        value: key,
                        label: taskCustomPropertyColumns[key].name as string,
                        columnKey: taskCustomPropertyColumns[key].key,
                    });
                }
            }
        }
        return [
            { label: 'Selected', options: selected },
            { label: props.dataGridEntityPlural, options: availableOptions },
        ];
    };

    // Views
    const defaultView: SavedInboundView = {
        id: 0,
        name: 'All ' + props.dataGridEntityPlural,
        dataGridColumns: DefaultColumns.map((column: ExtendedColumn) => {
            return {
                key: column.key,
                width: DEFAULT_COLUMN_WIDTH,
            };
        }),
    };

    const viewsMeta: ViewsMeta = {
        request: new TaskSavedViewsRequest(),
        savedViewType: SavedViewType.SAVED_SEARCH,
        entityTarget: EntityTarget.TASK,
        defaultView: defaultView,
        checkLimitDelegate: async () => {
            const res = await fetch('/subscribers/lists/api/task/check_list_limits');
            if (res.ok) {
                const json = await res.json();
                return json.data;
            } else {
                throw new Error('Error fetching Task List limits');
            }
        },
        limitReachedListName: props.dataGridEntitySingular,
    };

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/tasks.svg',
        iconHeight: 180,
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.ADD_TASK),
    };

    const deleteModalMeta: DeleteModalMeta = {
        modal: TasksDeleteModal,
    };

    const [selected, setSelected] = useState<TasksType[]>([]);
    const [showBulkEdit, setShowBulkEdit] = useState(false);
    const [reload, setReload] = useState(false);
    useEffect(() => {
        if (reload) {
            setReload(false);
        }
    }, [reload]);

    const SelectComponent: FunctionComponent<ToolbarComponentProps> = (props) => {
        return (
            <ToolbarButton
                buttonText="Edit Selected"
                onButtonPress={(
                    selectedRows: Set<React.Key>,
                    onComplete: () => void,
                    selectedRowData?: any[] | null,
                ) => {
                    setSelected(selectedRowData as TasksType[]);
                    setShowBulkEdit(true);
                }}
                {...props}
            />
        );
    };

    return (
        <>
            {showBulkEdit && (
                <TasksBulkEdit
                    close={() => {
                        setShowBulkEdit(false);
                        setReload(true);
                    }}
                    rows={selected}
                />
            )}
            <CampusDataGrid
                repository={tasksRepository}
                actionBarMeta={{
                    searchPlaceHolder: props.searchFilterPlaceholder,
                    includeCounts: true,
                    extraActionBarMeta: {
                        getEditColumnOptionsDelegate: getEditColumnOptions,
                        filterMeta: {
                            defaultActiveKey: 'primaryFilters',
                            fieldFilterSections: filterSections,
                        },
                    },
                }}
                addSispMeta={{
                    key: addSispUniqueKey,
                    sisp: TasksAddSisp,
                }}
                editSispMeta={{ sisp: TasksEditSisp }}
                previewSispMeta={{
                    key: previewSispUniqueKey,
                    sisp: TasksPreviewSisp,
                }}
                deleteModalMeta={deleteModalMeta}
                dataGridMeta={{
                    uniqueKey: props.dataGridUniqueKey,
                    entitySingular: props.dataGridEntitySingular,
                    entityPlural: props.dataGridEntityPlural,
                    columnOptions: { ...Columns, ...taskCustomPropertyColumns },
                    defaultColumns: DefaultColumns,
                    frozenColumns: [],
                    draggableColumns: true,
                    defaultSortColumn: 'id',
                    customSelectActionsComponent: canBulkEdit ? SelectComponent : undefined,
                    forceReload: reload,
                }}
                viewsMeta={viewsMeta}
                promptMeta={promptMeta}
            />
        </>
    );
};

export default TasksTable;
