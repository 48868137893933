import {
    AppEvent,
    EventBusInstance,
    ExtendedColumn,
    HoveroverButton,
    LogLevel,
    showBanner,
} from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { FormsRequest } from '../../Api/FormsRequest';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import { UniqueKeyType } from '../../Models/Enums';
import FormsType from '../../Models/FormsType';
import { RepositoryFactoryContext } from '../../index';
import CampusDataGrid, {
    ActionBarMeta,
    AddSispMeta,
    DataGridMeta,
    DeleteModalMeta,
    LimitsModalMeta,
    PreviewSispMeta,
    PromptMeta,
} from '../CampusDataGrid';
import FormsAddRedirect from './FormsAddRedirect';
import FormsDeleteModal from './FormsDeleteModal';
import FormsEditRedirect from './FormsEditRedirect';
import FormsEmbedModal from './FormsEmbedModal';
import FormsPreviewModal from './FormsPreviewModal';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

enum FormsColumnKey {
    ID,
    NAME,
    DESCRIPTION,
}

const FormsTable: FunctionComponent<Props> = (props: Props) => {
    const copyFormUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.COPY_ACTION);
    const editFormUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);
    const embedFormUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EMBED_MODAL);
    const previewFormUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.PREVIEW_MODAL);

    const privacyPolicyOk = JSON.parse(
        String((document.getElementById('privacy_policy_ok') as HTMLInputElement).value),
    );

    const formsRepository = useContext(RepositoryFactoryContext).getApiRepository(new FormsRequest());
    // State: General
    const [reload, setReload] = useState<boolean>(false);

    // State: Columns
    const Columns: Record<FormsColumnKey, ExtendedColumn> = {
        [FormsColumnKey.ID]: {
            key: 'id',
            name: 'ID',
            sortable: false,
        },
        [FormsColumnKey.NAME]: {
            key: 'name',
            name: 'Name',
            sortable: true,
            width: '1fr',
            renderCell: (props) => {
                const id = (props.row as FormsType).id;
                const name = (props.row as FormsType).name;
                return (
                    <>
                        <a
                            href=""
                            className="rdg-link"
                            onClick={(event) => {
                                event.preventDefault();
                                EventBusInstance.publish({
                                    topic: 'show-hoverover-component',
                                    message: props.row as FormsType,
                                    target: previewFormUniqueKey,
                                });
                            }}
                        >
                            {name}
                        </a>
                        <HoveroverButton
                            contents="Edit"
                            eventBusMessageTarget={editFormUniqueKey}
                            eventBusMessage={props.row as FormsType}
                        />
                        <HoveroverButton
                            contents="Copy"
                            eventBusMessageTarget={copyFormUniqueKey}
                            eventBusMessage={props.row as FormsType}
                            pending={activeCopyButtons.indexOf(id ?? 0) > -1}
                        />
                        <HoveroverButton
                            contents="Embed"
                            eventBusMessageTarget={embedFormUniqueKey}
                            eventBusMessage={props.row as FormsType}
                        />
                    </>
                );
            },
        },
        [FormsColumnKey.DESCRIPTION]: {
            key: 'description',
            name: 'Description',
            sortable: true,
            resizable: false,
            width: '1fr',
        },
    };

    const DefaultColumns: ExtendedColumn[] = [Columns[FormsColumnKey.NAME], Columns[FormsColumnKey.DESCRIPTION]];

    useEffect(() => {
        listenForCopy();
    }, []);

    useEffect(() => {
        // reset reload if true
        if (reload) setReload(false);
    }, [reload]);

    let activeCopyButtons: number[] = [];

    const addToActiveButtons = (id: number) => {
        activeCopyButtons.push(id);
    };

    const removeFromActiveButtons = (id: number) => {
        activeCopyButtons = _.filter(activeCopyButtons, (button) => {
            return button !== id;
        });
    };

    // listen for copy click
    const listenForCopy = () => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<FormsType>) => {
            if (event.target !== copyFormUniqueKey) return;
            // Add to current active buttons
            event.message.id && addToActiveButtons(event.message.id);
            formsRepository
                .post_action('copy', event.message.id ?? 0)
                .then(() => {
                    // Remove from current active buttons
                    event.message.id && removeFromActiveButtons(event.message.id);
                    showBanner({
                        message: 'Your Form was successfully copied.',
                        level: LogLevel.SUCCESS,
                    });
                    setReload(true);
                })
                .catch((err) => {
                    // Remove from current active buttons
                    event.message.id && removeFromActiveButtons(event.message.id);
                    showBanner({
                        message: err.message,
                        level: LogLevel.ERROR,
                    });
                });
        });
    };

    const dataGridMeta: DataGridMeta = {
        uniqueKey: props.dataGridUniqueKey,
        entitySingular: props.dataGridEntitySingular,
        entityPlural: props.dataGridEntityPlural,
        columnOptions: Columns,
        defaultColumns: DefaultColumns,
        frozenColumns: [],
        defaultSortColumn: 'id',
        forceReload: reload,
    };

    const actionBarMeta: ActionBarMeta = {
        searchPlaceHolder: props.searchFilterPlaceholder,
        includeCounts: true,
    };

    const addSispMeta: AddSispMeta = {
        key: UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP),
        sisp: FormsAddRedirect,
    };

    const previewSispMeta: PreviewSispMeta = {
        key: UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.PREVIEW_SISP),
        sisp: FormsPreviewModal,
    };

    const deleteModalMeta: DeleteModalMeta = {
        modal: FormsDeleteModal,
    };

    const limitModalMeta: LimitsModalMeta = {
        limits: [{ limitApiKey: 'forms_limit' }],
    };

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_forms.png',
        iconHeight: 180,
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.LEAD_GENERATION_FORM),
    };

    const privacyPolicyNeededMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_forms.png',
        iconHeight: 180,
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.CREATING_ENGAGEMENT_METHODS),
        addLineText: 'Before you can create a form you need to add a link to your Privacy Policy',
        addButtonLabel: 'Add Your Privacy Policy Link',
        addOnClickOverride: () => {
            window.location.href = '/settings/legal_processing';
        },
    };

    return (
        <>
            <FormsEmbedModal uniqueKey={embedFormUniqueKey} />
            <CampusDataGrid
                repository={formsRepository}
                actionBarMeta={actionBarMeta}
                addSispMeta={addSispMeta}
                editSispMeta={{ sisp: FormsEditRedirect }}
                previewSispMeta={previewSispMeta}
                dataGridMeta={dataGridMeta}
                deleteModalMeta={deleteModalMeta}
                limitsModalMeta={limitModalMeta}
                promptMeta={privacyPolicyOk ? promptMeta : privacyPolicyNeededMeta}
            />
        </>
    );
};

export default FormsTable;
