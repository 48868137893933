'use strict';

export const ALL = 'All';
export const PAST = 'Past';
export const FUTURE = 'Future';
export const TODAY = 'Today';
export const YESTERDAY = 'Yesterday';
export const TOMORROW = 'Tomorrow';
export const THIS_WEEK = 'This Week';
export const LAST_WEEK = 'Last Week';
export const NEXT_WEEK = 'Next Week';
export const THIS_MONTH = 'This Month';
export const LAST_MONTH = 'Last Month';
export const NEXT_MONTH = 'Next Month';
export const THIS_YEAR = 'This Year';
export const LAST_YEAR = 'Last Year';
export const NEXT_YEAR = 'Next Year';

export function allOptions() {
    return [
        ALL,
        PAST,
        FUTURE,
        TODAY,
        YESTERDAY,
        TOMORROW,
        THIS_WEEK,
        LAST_WEEK,
        NEXT_WEEK,
        THIS_MONTH,
        LAST_MONTH,
        NEXT_MONTH,
        THIS_YEAR,
        LAST_YEAR,
        NEXT_YEAR
    ];
}

export function defaultOption () {
    return ALL;
}

export function forSelect() {
    return allOptions().map(option => ({
        id: option,
        label: option
    }));
}