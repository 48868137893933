import { Checkbox, isValidString, LogLevel, showBanner, Sisp } from '@sprint/sprint-react-components';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { RepositoryFactoryContext } from '../../index';
import { VatRateRequest } from '../../Api/VatRateRequest';
import VatRate from '../../Models/VatRate';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
}

const VatRatesAddSisp: FunctionComponent<Props> = (props: Props) => {
    const vatRateRepository = useContext(RepositoryFactoryContext).getApiRepository(new VatRateRequest());

    const focusRef = useRef<HTMLInputElement>(null);

    const [newVatRate, setNewVatRate] = useState<string>('');
    const [newVatRateValid, setNewVatRateValid] = useState<boolean>(true);

    const [newDescription, setNewDescription] = useState<string>('');

    const [newDefault, setNewDefault] = useState<boolean>(false);

    useEffect(() => {
        if (props.shown) {
            focusRef.current?.focus();
        }
    }, [props.shown]);

    const validate = async (): Promise<boolean> => {
        const vatRateValid = !!newVatRate && isValidString(newVatRate);
        setNewVatRateValid(vatRateValid);
        return vatRateValid;     };

    const reset = () => {
        setNewVatRate('');
        setNewDescription('');
        setNewVatRateValid(true);
        setNewDefault(false);
    };

    const handleAddRow = (): Promise<boolean> => {
        const VatRate: VatRate = {
            vat_rate: newVatRate,
            description: newDescription,
            is_default: newDefault,
        };

        return vatRateRepository
            .create(VatRate)
            .then(props.onSuccess)
            .then(async (success) => {
                reset();
                return success;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to create Tax Rate - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        if ((await validate()) && (await handleAddRow())) props.onClose();
    };

    return (
        <Sisp
            isOpen={props.shown}
            onSubmit={handleAddRow}
            onCancel={() => {
                reset();
                props.onClose();
            }}
            validate={validate}
        >
            <h4>Add a Tax Rate</h4>
            <Form onSubmit={onSubmitForm}>
                <Form.Group>
                    <Form.Label>
                        Tax Rate <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        ref={focusRef}
                        type="text"
                        isInvalid={!newVatRateValid}
                        value={newVatRate || ''}
                        onChange={(event) => {
                            setNewVatRate(event.target.value);
                            setNewVatRateValid(true);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(!newVatRateValid && 'This field is required.')}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        type="text"
                        value={newDescription}
                        onChange={(event) => {
                            setNewDescription(event.target.value);
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Checkbox
                        label="Make this the default tax rate"
                        isChecked={newDefault}
                        onChange={(event) => setNewDefault(event.target.checked)}
                    />
                </Form.Group>
            </Form>
        </Sisp>
    );
};

export default VatRatesAddSisp;
