/* eslint-disable max-len */
import {
    AsyncDropDown,
    DropDown,
    LogLevel,
    OptionTypeBase,
    SearchQuery,
    Sisp,
    User,
    isValidEmail,
    showBanner,
} from '@sprint/sprint-react-components';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { SmtpAccountRequest, UsersWithoutSmtpRequest } from '../../Api/SmtpAccountRequest';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import SmtpAccount from '../../Models/SmtpAccount';
import { SmtpAccountProvider } from '../../Models/SmtpProvider';
import UserType from '../../Models/UserType';
import { RepositoryFactoryContext, UserContext } from '../../index';
import GoogleAgreementModal from './GoogleAgreementModal';
import MicrosoftAgreementModal from './MicrosoftAgreementModal';
import { smtpOptions, toSmtpOption } from './helpers';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
    oauthEnabled?: boolean;
}

const SmtpAccountAddSisp: FunctionComponent<Props> = (props: Props) => {
    const user: User = useContext(UserContext);
    const smtpAccountRepository = useContext(RepositoryFactoryContext).getApiRepository(new SmtpAccountRequest());
    const usersWithoutSmtpRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new UsersWithoutSmtpRequest(),
    );

    const focusRef = useRef<HTMLInputElement>(null);

    const [userDropdownOptions, setUserDropdownOptions] = useState<UserType[]>([]);

    const [selectedUser, setSelectedUser] = useState<OptionTypeBase | null>(null);
    const [userId, setUserId] = useState<number | null>(user.id);
    const [userIdValid, setUserIdValid] = useState<boolean>(true);

    const [newName, setNewName] = useState<string>('');
    const [newNameValid, setNewNameValid] = useState<boolean>(true);

    const [newEmail, setNewEmail] = useState<string>('');
    const [newEmailValid, setNewEmailValid] = useState<boolean>(true);

    const [newHost, setNewHost] = useState<string>('');
    const [newHostValid, setNewHostValid] = useState<boolean>(true);

    const [newUsername, setNewUsername] = useState<string>('');
    const [newUsernameValid, setNewUsernameValid] = useState<boolean>(true);

    const [newPassword, setNewPassword] = useState<string>('');
    const [newPasswordValid, setNewPasswordValid] = useState<boolean>(true);

    const [newPort, setNewPort] = useState<string>('25');
    const [newPortValid, setNewPortValid] = useState<boolean>(true);

    const [newSecurity, setNewSecurity] = useState<string>('None');
    const [newSecurityValid, setNewSecurityValid] = useState<boolean>(true);

    const [newProvider, setNewProvider] = useState<OptionTypeBase>(toSmtpOption(SmtpAccountProvider.PLAIN_SMTP));

    const [validatingSMTP, setValidatingSMTP] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

    const [abortController, setAbortController] = useState<AbortController | null>(null);

    const [showGoogleAgreement, setShowGoogleAgreement] = useState(false);
    const [showMicrosoftAgreement, setShowMicrosoftAgreement] = useState(false);

    useEffect(() => {
        if (props.shown) {
            focusRef.current?.focus();
        }
        setShowErrorMessage(false);
    }, [props.shown]);

    useEffect(() => {
        if (userDropdownOptions?.length == 1) {
            const user = userDropdownOptions[0];
            selectUser(user);
        } else if (userId != null) {
            const user = userDropdownOptions.find((user) => user.id == userId);
            if (user) {
                selectUser(user);
            }
        } else {
            reset();
        }
    }, [userId, userDropdownOptions]);

    const selectUser = (user: UserType) => {
        setSelectedUser(dropdownMapLambda(user));
        setUserId(user.id as number);
        setNewName(user.full_name ?? user.first_name);
        setNewEmail(user.email);
        setNewPort('25');
    };

    useEffect(() => {
        setShowErrorMessage(false);
    }, [userId, newName, newEmail, newHost, newUsername, newPassword, newPort, newSecurity, newProvider]);

    const onGetUsersWithoutSmtp = async (filter: string) => {
        const query = new SearchQuery(1, 100);
        return usersWithoutSmtpRepository
            .search(query)
            .then((results: any) => {
                if (results.wasCancelled) return results;
                setUserDropdownOptions(results.results);
                if (filter.length == 0) {
                    return results.results.map(dropdownMapLambda);
                } else {
                    return results.results
                        .filter((user: UserType) => user.full_name?.toLowerCase().includes(filter.toLowerCase()))
                        .map(dropdownMapLambda);
                }
            })
            .catch((err: any) => {
                showBanner({
                    message: `Failed to load SMTP details - ${err?.message ?? err}`,
                    level: LogLevel.ERROR,
                    dismissable: false,
                });
                reset();
                return null;
            });
    };

    const dropdownMapLambda = (element: UserType) => {
        return {
            value: element.id,
            label: (
                <span>
                    <img src={element.avatar} className="img-rounded" width="20" /> {element.full_name}
                </span>
            ),
        };
    };

    const validate = async (): Promise<boolean> => {
        const userValid = userId != null;
        setUserIdValid(userValid);

        const nameValid = !!newName;
        setNewNameValid(nameValid);

        const hostValid = !!newHost;
        setNewHostValid(hostValid);

        const usernameValid = !!newUsername;
        setNewUsernameValid(usernameValid);

        const passwordValid = !!newPassword;
        setNewPasswordValid(passwordValid);

        const emailValid = !!newEmail && isValidEmail(newEmail);
        setNewEmailValid(emailValid);

        const portValid = !!newPort && Number.isInteger(Number(newPort));
        setNewPortValid(portValid);

        switch (newProvider.value) {
            case SmtpAccountProvider.GOOGLE:
                return true;
            case SmtpAccountProvider.MICROSOFT:
                return true;
            case SmtpAccountProvider.PLAIN_SMTP:
                if (userValid && nameValid && hostValid && usernameValid && passwordValid && emailValid && portValid) {
                    setValidatingSMTP(true);
                    return true;
                }
        }

        return false;
    };

    const reset = () => {
        setUserId(null);
        setNewName('');
        setNewNameValid(true);
        setNewEmail('');
        setNewEmailValid(true);
        setNewHost('');
        setNewHostValid(true);
        setNewUsername('');
        setNewUsernameValid(true);
        setNewPassword('');
        setNewPasswordValid(true);
        setNewPort('');
        setNewPortValid(true);
        setAbortController(null);
        setNewProvider(toSmtpOption(SmtpAccountProvider.PLAIN_SMTP));
    };

    const makeSmtpAccount = (): SmtpAccount => {
        return {
            default_from_email: newEmail,
            default_from_name: newName,
            user: userId != null ? userId : undefined,
            provider: newProvider.value,
            smtp_host: newHost ?? '',
            smtp_port: Number(newPort) ?? 25,
            smtp_user: newUsername ?? '',
            smtp_pass: newPassword ?? '',
            smtp_security: newSecurity,
        };
    };

    const handleAddRow = async (): Promise<boolean> => {
        const SmtpAccount = makeSmtpAccount();
        const abortController = new AbortController();
        setAbortController(abortController);

        return smtpAccountRepository
            .create(SmtpAccount, abortController.signal)
            .then(props.onSuccess)
            .then(async (success) => {
                reset();
                setValidatingSMTP(false);
                return success;
            })
            .catch((err) => {
                setValidatingSMTP(false);
                if (err?.message == 'canceled') {
                    // axios cancel signal triggered
                    setErrorMessage('Request Cancelled');
                } else {
                    setErrorMessage(err?.message ?? err);
                }
                setShowErrorMessage(true);
                return false;
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        if (await validate()) {
            if (await handleAddRow()) {
                props.onClose();
            }
        }
    };

    const getGoogleInfo = (token: string) => {};

    const loginRequest = {
        prompt: 'consent',
        scopes: ['https://outlook.office.com/Mail.Send', 'offline_access'],
    };

    return (
        <Sisp
            isOpen={props.shown}
            onSubmit={handleAddRow}
            onCancel={() => {
                abortController?.abort();
                reset();
                if (!validatingSMTP) {
                    props.onClose();
                } else {
                    setValidatingSMTP(false);
                }
            }}
            validate={validate}
            cancelDuringSubmit
            showSubmitButton={newProvider.value === SmtpAccountProvider.PLAIN_SMTP}
        >
            {showErrorMessage && <p className="alert alert-danger">{errorMessage}</p>}
            <h4>Connect an Email</h4>
            {validatingSMTP && (
                <>
                    <p className="alert alert-success">Validating SMTP account details.</p>
                    <div style={{ position: 'relative', alignItems: 'center' }}>
                        <Card
                            className="loading-spinner-container filter-loading-spinner"
                            style={{ background: '#f9f9f9' }}
                        >
                            <Spinner animation="border" role="status" />
                        </Card>
                    </div>
                </>
            )}

            <>
                <p>
                    To send day to day email from Campus you need to add an SMTP account for you and each of your users.{' '}
                    <a target="_blank" href={KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.SEND_EMAILS)}>
                        Learn more
                    </a>
                    .
                </p>
                <Form
                    onSubmit={(e: FormEvent) => {
                        setShowErrorMessage(false);
                        onSubmitForm(e);
                    }}
                >
                    <Form.Group>
                        {/* User DropDown */}
                        <Form.Label>
                            Who Are You Setting This Up For? <span className="required-field-marker">*</span>
                        </Form.Label>
                        <AsyncDropDown
                            value={selectedUser}
                            isInvalid={false}
                            onChange={(option: OptionTypeBase) => {
                                setUserId(option?.value ?? null);
                                setUserIdValid(true);
                            }}
                            loadOptions={onGetUsersWithoutSmtp}
                            isClearable={true}
                            menuPortalTarget={document.body}
                        />
                        <Form.Control.Feedback type="invalid">
                            {!userIdValid && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {/* Provider */}
                    {props.oauthEnabled && (
                        <Form.Group>
                            <Form.Label>
                                Email Provider <span className="required-field-marker">*</span>
                            </Form.Label>
                            <DropDown
                                value={newProvider}
                                isInvalid={false}
                                isClearable={false}
                                menuPortalTarget={document.body}
                                menuPosition="fixed"
                                classNamePrefix={'provider-dropdown'}
                                onChange={(selected: OptionTypeBase) => {
                                    setNewProvider(selected);
                                }}
                                options={smtpOptions}
                            />
                            <Form.Control.Feedback type="invalid">
                                {!newSecurityValid && 'This field is required.'}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}

                    {newProvider.value === SmtpAccountProvider.PLAIN_SMTP && (
                        <>
                            {/* Email Address */}
                            <Form.Group>
                                <Form.Label>
                                    Email Address <span className="required-field-marker">*</span>
                                </Form.Label>
                                <Form.Control
                                    autoComplete="off"
                                    type="text"
                                    isInvalid={!newEmailValid}
                                    value={newEmail || ''}
                                    onChange={(event) => {
                                        setNewEmail(event.target.value);
                                        setNewEmailValid(true);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {!newEmailValid && 'This field is required.'}
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* From Name */}
                            <Form.Group>
                                <Form.Label>
                                    From Name <span className="required-field-marker">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    isInvalid={!newNameValid}
                                    value={newName || ''}
                                    onChange={(event) => {
                                        setNewName(event.target.value);
                                        setNewNameValid(true);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {!newNameValid && 'This field is required.'}
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* Host */}
                            <Form.Group>
                                <Form.Label>
                                    Host <span className="required-field-marker">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    isInvalid={!newHostValid}
                                    value={newHost || ''}
                                    onChange={(event) => {
                                        setNewHost(event.target.value);
                                        setNewHostValid(true);
                                    }}
                                    placeholder="e.g. mail.mycompany.com"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {!newHostValid && 'This field is required.'}
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* Username */}
                            <Form.Group>
                                <Form.Label>
                                    Username <span className="required-field-marker">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    isInvalid={!newUsernameValid}
                                    value={newUsername || ''}
                                    onChange={(event) => {
                                        setNewUsername(event.target.value);
                                        setNewUsernameValid(true);
                                    }}
                                    placeholder="e.g. bob@mycompany.com"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {!newUsernameValid && 'This field is required.'}
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* Password */}
                            <Form.Group>
                                <Form.Label>
                                    Password <span className="required-field-marker">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="password"
                                    isInvalid={!newPasswordValid}
                                    value={newPassword || ''}
                                    onChange={(event) => {
                                        setNewPassword(event.target.value);
                                        setNewPasswordValid(true);
                                    }}
                                    placeholder="**********"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {!newPasswordValid && 'This field is required.'}
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* Port */}
                            <Form.Group>
                                <Form.Label>
                                    Port <span className="required-field-marker">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    isInvalid={!newPortValid}
                                    value={newPort || ''}
                                    onChange={(event) => {
                                        setNewPort(event.target.value);
                                        setNewPortValid(true);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {!newPortValid && 'This field is required.'}
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* Security */}
                            <Form.Group>
                                <Form.Label>
                                    Security <span className="required-field-marker">*</span>
                                </Form.Label>
                                <DropDown
                                    value={{ value: newSecurity, label: newSecurity }}
                                    isInvalid={false}
                                    isClearable={false}
                                    menuPortalTarget={document.body}
                                    menuPosition="fixed"
                                    classNamePrefix={'provider-dropdown'}
                                    onChange={(selected: OptionTypeBase) => {
                                        setNewSecurity(selected.value);
                                        setNewSecurityValid(true);
                                    }}
                                    options={[
                                        { value: 'None', label: 'None' },
                                        { value: 'SSL', label: 'SSL' },
                                        { value: 'TLS', label: 'TLS' },
                                    ]}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {!newSecurityValid && 'This field is required.'}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </>
                    )}
                    {newProvider.value === SmtpAccountProvider.MICROSOFT && (
                        <>
                            <Button disabled={!userId} onClick={() => setShowMicrosoftAgreement(true)}>
                                Connect to Outlook 365
                            </Button>
                            {showMicrosoftAgreement && (
                                <MicrosoftAgreementModal
                                    href={`/settings/connected_emails/authenticate_microsoft?smtp_user_id=${userId}`}
                                    close={() => setShowMicrosoftAgreement(false)}
                                />
                            )}
                        </>
                    )}
                    {newProvider.value === SmtpAccountProvider.GOOGLE && (
                        <>
                            <Button disabled={!userId} onClick={() => setShowGoogleAgreement(true)}>
                                Connect to Gmail
                            </Button>
                            {showGoogleAgreement && (
                                <GoogleAgreementModal
                                    href={`/settings/connected_emails/authenticate_google?smtp_user_id=${userId}`}
                                    close={() => setShowGoogleAgreement(false)}
                                />
                            )}
                        </>
                    )}
                </Form>
            </>
        </Sisp>
    );
};

export default SmtpAccountAddSisp;
