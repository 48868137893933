import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import urlJoin from 'url-join';
import HttpRequestService from '../../../CommonComponents/HttpRequestService/HttpRequestService';
import SimpleSearchResultCounts from '../Components/SimpleSearchResultCounts';

interface SimpleType {
    id?: number;
}

export default class CrmPreviewRepository<T extends SimpleType> {
    private _cancelTokens: Record<string, CancelTokenSource> = {};

    private _http: HttpRequestService;
    private _baseUrl: string;

    constructor(
        private _authToken: string,
        path: string,
    ) {
        this._http = new HttpRequestService(this._authToken);
        this._baseUrl = path;
    }

    private isSuccessCode(statusCode: number) {
        return statusCode >= 200 && statusCode <= 299;
    }

    private isClientErrorCode(statusCode: number) {
        return statusCode >= 400 && statusCode <= 499;
    }

    public async preview(id: number): Promise<any> {
        const cancelTokenKey = this.constructor.name + '.preview';
        const url = id ? urlJoin(this._baseUrl, id.toString()) : this._baseUrl;

        return this._http
            .get(urlJoin(url, `/${id}`), cancelTokenKey)
            .then((res: AxiosResponse<{ items: T[]; meta: SimpleSearchResultCounts }> | null) => {
                if (res && this.isSuccessCode(res.status)) {
                    return res.data;
                }
                throw new Error(res?.statusText);
            })
            .catch((err) => {
                if (axios.isCancel(err)) return {};
                throw err;
            });
    }
}
