import React, { FunctionComponent } from 'react';
import './radio-button.scss';
import RadioButton from './RadioButton';

interface Option {
    value: string | number;
    label: string;
}

interface Props {
    id?: string;
    options: Option[];
    value: string | number;
    disabled?: boolean;
    onChange: (value: string | number) => void;
    onBlur?: () => void;
    alignVertical?: boolean;
}

const RadioButtonGroup: FunctionComponent<Props> = (props: Props) => {
    const onChange = (value: string | number, checked: boolean) => {
        if (checked) props.onChange(value);
    };

    const onBlur = () => {
        if (!props.onBlur) return;
        props.onBlur();
    };

    let className = 'react-radio-button-group';
    if (props.alignVertical) className += ' align-vertical';

    return (
        <div className={className}>
            {props.options.map((option, i) => (
                <RadioButton
                    key={i}
                    group={props.id}
                    value={option.value}
                    label={option.label}
                    checked={props.value === option.value}
                    disabled={props.disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                />
            ))}
        </div>
    );
};

export default RadioButtonGroup;
