import React, { FunctionComponent } from 'react';
import { Badge } from 'react-bootstrap';
import ClientTag from '../../Applications/DataGrids/Models/ClientTag';
import { APP_COLOURS } from '../Colours/AppColours';

interface Props {
    tag: ClientTag;
}

const ClientTagPill: FunctionComponent<Props> = (props: Props) => {
    const textColour =
        getColourLuminance(props.tag.hex_colour) > BRIGHTNESS_THRESHOLD ? APP_COLOURS.BLACK : APP_COLOURS.WHITE;
    return (
        <Badge style={{ borderRadius: '3px', backgroundColor: '#' + props.tag.hex_colour, color: '#' + textColour }}>
            {props.tag.name}
        </Badge>
    );
};

export default ClientTagPill;
