'use strict';

import * as ActionTypes from "./ActionTypes";
import DealTypeRepository from "./DealTypeRepository";

export function loadDealTypes() {
    return (dispatch) => {
        DealTypeRepository.all()
            .then(response => dispatch(dealTypesLoaded(response)))
            .catch(e => dispatch(dealTypesLoadingFailed(e.message ? e.message : e)));
    }
}

function dealTypesLoaded(dealTypes) {
    return {
        type: ActionTypes.DEALTYPES_LOADED,
        payload: {
            dealTypes: dealTypes
        }
    }
}

function dealTypesLoadingFailed(error) {
    return {
        type: ActionTypes.DEALTYPES_LOADING_FAILED,
        payload: {
            error: error
        }
    }
}

export function newDealType() {
    return {
        type: ActionTypes.DEALTYPE_NEW
    }
}

export function updateDealTypeField(id, fieldName, value) {
    return {
        type: ActionTypes.DEALTYPE_UPDATE_FIELD,
        payload: {
            id: id,
            fieldName: fieldName,
            value: value
        }
    }
}

export function saveDealType(entity) {
    return (dispatch) => {
        dispatch(savingDealType(entity));
        DealTypeRepository.save(entity).then((response) => {
            dispatch(dealTypeSaved(response));
        }).catch(error => {
            dispatch(savingDealTypeFailed(entity.id, error));
        });
    }
}

export function selectDealType(id) {
    return {
        type: ActionTypes.DEALTYPE_SELECT,
        payload: {
            id: id
        }
    }
}

export function loadDealType(id) {
    return (dispatch) => {
        dispatch(loadingDealType(id));
        DealTypeRepository.find(id).then((response) => {
            dispatch(dealTypeLoaded(response));
        }).catch(error => {
            dispatch(loadingDealTypeFailed(id, error));
        });
    }
}

export function invalidateDealType(id) {
    return {
        type: ActionTypes.DEALTYPE_INVALIDATED,
        payload: {
            id: id
        }
    }
}

export function deleteDealType(id) {
    return (dispatch) => {
        dispatch(deletingDealType(id));
        DealTypeRepository.delete(id).then(() => {
            dispatch(dealTypeDeleted(id));
        }).catch(error => {
            dispatch(savingDealTypeFailed(id, error));
        });
    }
}

function savingDealType(entity) {
    return {
        type: ActionTypes.DEALTYPE_SAVING,
        payload: {
            entity: entity
        }
    }
}

function dealTypeSaved(rawEntity) {
    return {
        type: ActionTypes.DEALTYPE_SAVED,
        payload: {
            data: rawEntity
        }
    }
}

function savingDealTypeFailed(id, error) {
    return {
        type: ActionTypes.DEALTYPE_SAVING_FAILED,
        payload: {
            id: id,
            error: error
        }
    }
}

function loadingDealType(id) {
    return {
        type: ActionTypes.DEALTYPE_LOADING,
        payload: {
            id: id
        }
    }
}

function dealTypeLoaded(rawEntity) {
    return {
        type: ActionTypes.DEALTYPE_LOADED,
        payload: {
            data: rawEntity
        }
    }
}

function loadingDealTypeFailed(id, error) {
    return {
        type: ActionTypes.DEALTYPE_LOADING_FAILED,
        payload: {
            id: id,
            error: error
        }
    }
}

function deletingDealType(id) {
    return {
        type: ActionTypes.DEALTYPE_DELETING,
        payload: {
            id: id
        }
    }
}

function dealTypeDeleted(id) {
    return {
        type: ActionTypes.DEALTYPE_DELETED,
        payload: {
            id: id
        }
    }
}