import {
    DefaultPillFormatter,
    ExtendedColumn,
    HoveroverButton,
    NonSelectableRow,
} from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext } from 'react';
import { DealTypeRequest } from '../../Api/DealTypeRequest';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import DealType from '../../Models/DealType';
import DealTypeEditState from '../../Models/DealTypeEditState';
import { UniqueKeyType } from '../../Models/Enums';
import { RepositoryFactoryContext } from '../../index';
import CampusDataGrid, { ActionBarMeta, AddSispMeta, DataGridMeta, PromptMeta } from '../CampusDataGrid';
import DealTypesAddSisp from './DealTypesAddSisp';
import DealTypesEditSisp from './DealTypesEditSisp';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

enum DealTypesColumnKey {
    ID,
    LAST_MODIFIED,
    DEAL_TYPE,
}

const DealTypesTable: FunctionComponent<Props> = (props: Props) => {
    const editSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);

    const dealTypesRepository = useContext(RepositoryFactoryContext).getApiRepository(new DealTypeRequest());

    const Columns: Record<DealTypesColumnKey, ExtendedColumn> = {
        [DealTypesColumnKey.ID]: {
            key: 'id',
            name: 'ID',
            sortable: false,
        },
        [DealTypesColumnKey.LAST_MODIFIED]: {
            key: 'modified',
            name: 'Last Modified',
            sortable: true,
        },
        [DealTypesColumnKey.DEAL_TYPE]: {
            key: 'type',
            name: 'Name',
            sortable: true,
            resizable: false,
            renderCell: (fprops) => {
                const name = (fprops.row as DealType).type;
                const is_default = (fprops.row as DealType).is_default;
                const built_in = (fprops.row as DealType).built_in;
                const id = (fprops.row as DealType).id!;
                const eventBusMessage: DealTypeEditState = { id: id, type: name, is_default: is_default };
                return (
                    <>
                        <DefaultPillFormatter cellContent={name} showPill={is_default}>
                            <HoveroverButton
                                contents="Edit"
                                showHoverover={!built_in}
                                eventBusMessageTarget={editSispUniqueKey}
                                eventBusMessage={eventBusMessage}
                            />
                        </DefaultPillFormatter>
                    </>
                );
            },
        },
    };

    const DefaultColumns: ExtendedColumn[] = [Columns[DealTypesColumnKey.DEAL_TYPE]];

    const findNonSelectableRows = (rows: any): NonSelectableRow[] | null => {
        return rows
            .filter((row: any) => (row as DealType).built_in)
            .map((row: any) => ({ id: row.id, reason: 'Built-in Type' }));
    };

    const dataGridMeta: DataGridMeta = {
        uniqueKey: props.dataGridUniqueKey,
        entitySingular: props.dataGridEntitySingular,
        entityPlural: props.dataGridEntityPlural,
        columnOptions: Columns,
        defaultColumns: DefaultColumns,
        frozenColumns: [],
        defaultSortColumn: 'modified',
        nonSelectableRows: findNonSelectableRows,
    };

    const actionBarMeta: ActionBarMeta = {
        searchPlaceHolder: props.searchFilterPlaceholder,
        includeCounts: true,
    };

    const addSispMeta: AddSispMeta = {
        key: UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP),
        sisp: DealTypesAddSisp,
    };

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/deal_types.svg',
        iconHeight: 90,
    };

    return (
        <>
            <CampusDataGrid
                repository={dealTypesRepository}
                actionBarMeta={actionBarMeta}
                addSispMeta={addSispMeta}
                editSispMeta={{ sisp: DealTypesEditSisp }}
                dataGridMeta={dataGridMeta}
                promptMeta={promptMeta}
            />
        </>
    );
};

export default DealTypesTable;
