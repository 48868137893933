import * as ActionTypes from "./ActionTypes";
import HttpRequestService from "../../Services/HttpRequestService/HttpRequestService";

const initialState = {
    currentFilter: '',
    searchResults: undefined,
    allKnownResults: [],
    pendingRequest: undefined,
    totalAvailableResults: undefined,
    moreLoading: false
};

function mergeResults(existingResults, newResults) {
    if (existingResults === undefined) return newResults;
    return existingResults.filter(existingResult => {
        return newResults.map(newResult => newResult.id).indexOf(existingResult.id) === -1;
    }).concat(newResults);
}

export default function (state, action) {
    if (state === undefined) {
        state = initialState;
    }

    switch (action.type) {
        case ActionTypes.DATA_LOADED: {
            const incomingResults = action.payload.contacts.items;
            return Object.assign({}, state, {
                searchResults: incomingResults,
                allKnownResults: mergeResults(state.allKnownResults, incomingResults),
                totalAvailableResults: action.payload.contacts.total ? action.payload.contacts.total : state.totalAvailableResults
            });
        }
        case ActionTypes.UPDATE_FILTER: {
            if (state.pendingRequest) HttpRequestService.cancel(state.pendingRequest);
            return Object.assign({}, state, {
                moreLoading: false,
                currentFilter: action.payload.filter,
                pendingRequest: action.payload.requestToken,
                searchResults: undefined,
                totalAvailableResults: undefined
            });
        }
        case ActionTypes.MORE_LOADING: {
            return Object.assign({}, state, {
                moreLoading: true
            });
        }
        case ActionTypes.MORE_LOADED: {
            const incomingResults = action.payload.additionalContacts.items;
            return Object.assign({}, state, {
                moreLoading: false,
                searchResults: mergeResults(state.searchResults, incomingResults),
                allKnownResults: mergeResults(state.allKnownResults, incomingResults)
            });
        }
        case ActionTypes.LOADING_CANCELLED: {
            return state;
        }
        case ActionTypes.IDS_LOADED: {
            const incomingResults = action.payload.additionalContacts.items;
            return Object.assign({}, state, {
                moreLoading: false,
                searchResults: mergeResults(state.searchResults, incomingResults),
                allKnownResults: mergeResults(state.allKnownResults, incomingResults)
            });
        }
        default: {
            return state;
        }
    }
}