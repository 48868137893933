import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, isValidString, LogLevel, PendingButton, showBanner, Sisp } from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as DealPipelineLegacy from '../../../../Entities/Deal/DealPipeline/DealPipeline';
import DealPipelineStage from '../../../../Entities/Deal/DealPipeline/DealPipelineStage/DealPipelineStage';
import StageList from '../../../DealSettings/Components/PipelineStageList/StageList';
import { DealPipelineRequest } from '../../Api/DealPipelineRequest';
import { RepositoryFactoryContext } from '../../index';
import { DealPipeline } from '../../Models/DealPipeline';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
    uniqueKey: string;
}

const DealPipelinesAddSisp: FunctionComponent<Props> = (props: Props) => {
    const dealPipelinesRepository = useContext(RepositoryFactoryContext).getApiRepository(new DealPipelineRequest());

    const focusRef = useRef<HTMLInputElement>(null);
    const newPipelineStageRef = useRef<HTMLInputElement>(null);

    const [newName, setNewName] = useState<string>('');
    const [newNameValid, setNewNameValid] = useState<boolean>(true);

    const [newDealLength, setNewDealLength] = useState<string>('0');
    const [newDealLengthValid, setNewDealLengthValid] = useState<boolean>(true);

    const [newStageName, setNewStageName] = useState<string>('');
    const [newStageNameValid, setNewStageNameValid] = useState<boolean>(true);

    const [newDefault, setNewDefault] = useState<boolean>(false);

    const [editStageIndex, setEditStageIndex] = useState<number>(0);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isAdd, setIsAdd] = useState<boolean>(false);

    const [pipelinePreEditStages, setPipelinePreEditStages] = useState<DealPipelineStage[]>([]);
    const [pipelinePostEditStages, setPipelinePostEditStages] = useState<DealPipelineStage[]>([]);
    const [pipelineStages, setPipelineStages] = useState<DealPipelineStage[]>(
        DealPipelineLegacy.default.defaultStages(),
    );
    const [showAddStage, setShowAddStage] = useState<boolean>(false);

    const [isSaving, setIsSaving] = useState<boolean>(false);

    useEffect(() => {
        if (props.shown && !showAddStage) {
            focusRef.current?.focus();
        }
    }, [props.shown, showAddStage]);

    useEffect(() => {
        if (showAddStage) {
            newPipelineStageRef.current?.focus();
        }
    }, [showAddStage]);

    const validate = async (): Promise<boolean> => {
        const nameValid = !!newName && isValidString(newName);
        const dealLengthValid = Number.isInteger(Number(newDealLength)) && newDealLength !== '';
        setNewNameValid(nameValid);
        setNewDealLengthValid(dealLengthValid);
        return nameValid && dealLengthValid;
    };

    const reset = () => {
        setNewName('');
        setNewDealLength('0');
        setNewDefault(false);
        setNewNameValid(true);
        setPipelineStages(DealPipelineLegacy.default.defaultStages());
    };

    const handleAddRow = async (): Promise<boolean> => {
        // Make sure default stages are always last
        const allStages = _.cloneDeep(pipelineStages);
        const defaultStages = DealPipelineLegacy.default.defaultStages();
        defaultStages.forEach((stage) => {
            const index = allStages.findIndex((o) => {
                return o.stageState === stage.stageState;
            });
            allStages.splice(index, 1);
        });
        allStages.push(...defaultStages);

        const DealPipeline: DealPipeline = {
            name: newName,
            stages: JSON.stringify(allStages.map((dealPipelineStage) => dealPipelineStage.forApi())),
            default_deal_length_days: newDealLength as unknown as number,
            is_default: newDefault,
            // Doesnt change can only be false
            built_in: false,
        };

        return dealPipelinesRepository
            .create(DealPipeline)
            .then(props.onSuccess)
            .then(async (success) => {
                reset();
                return success;
            })
            .catch((err) => {
                showBanner(
                    {
                        message: 'Failed to create Deal Name - ' + (err?.message ?? err),
                        level: LogLevel.ERROR,
                    },
                    props.uniqueKey,
                );
                return false;
            });
    };

    const handleAddStage = async (): Promise<boolean> => {
        const newStage: DealPipelineStage = DealPipelineStage.blankOpen();
        newStage.name = newStageName;
        const newPipelineStages = pipelineStages;
        if (isEdit) {
            newPipelineStages.splice(editStageIndex, 1, newStage);
        }

        if (isAdd) {
            newPipelineStages.push(newStage);
        }
        setIsEdit(false);
        setIsAdd(false);
        setPipelineStages(newPipelineStages);
        return true;
    };

    const onAddStageClick = async (): Promise<boolean> => {
        // Populate pre-edit stages
        const preEditStages = pipelineStages.filter((stage, index) => {
            return 1 < index;
        });
        setPipelinePreEditStages(preEditStages);
        // Populate post-edit stages
        const postEditStages = pipelineStages.filter((stage, index) => {
            return 1 >= index;
        });
        setPipelinePostEditStages(postEditStages);
        setIsAdd(true);
        setShowAddStage(true);
        return true;
    };

    const cancelAddEditStage = async (): Promise<void> => {
        setIsAdd(false);
        setIsEdit(false);
        setPipelinePreEditStages([]);
        setPipelinePostEditStages([]);
        setShowAddStage(false);
        setNewStageName('');
        setNewStageNameValid(true);
    };

    const handleReorderStage = async (oldStageIndex: number, newStageIndex: number): Promise<boolean> => {
        if (!isEdit) {
            const targetStage = pipelineStages[oldStageIndex];
            const newPipelineStages = pipelineStages;
            newPipelineStages.splice(oldStageIndex, 1);
            newPipelineStages.splice(newStageIndex, 0, targetStage);
            setPipelineStages(newPipelineStages);
            return true;
        }
        return false;
    };

    const handleDeleteStage = async (stageEntity: DealPipelineStage): Promise<boolean> => {
        if (isEdit) {
            // Handle edit index if delete called whilst in edit mode
            const deleteIndex = pipelineStages.findIndex((stage) => stage == stageEntity);
            if (deleteIndex < editStageIndex) {
                setEditStageIndex(editStageIndex - 1);
            }
            // Update PreEditStages
            const newPipelinePreEditStages = pipelinePreEditStages.filter((stage) => {
                return stage !== stageEntity;
            });
            setPipelinePreEditStages(newPipelinePreEditStages);
            // Update PostEditStages
            const newPipelinePostEditStages = pipelinePostEditStages.filter((stage) => {
                return stage !== stageEntity;
            });
            setPipelinePostEditStages(newPipelinePostEditStages);
        }
        if (isAdd) {
            // Update PreEditStages
            const newPipelinePreEditStages = pipelinePreEditStages.filter((stage) => {
                return stage !== stageEntity;
            });
            setPipelinePreEditStages(newPipelinePreEditStages);
        }
        const newPipelineStages = pipelineStages.filter((stage) => {
            return stage !== stageEntity;
        });
        setPipelineStages(newPipelineStages);
        return true;
    };

    const handleEditStage = async (stageEntity: DealPipelineStage): Promise<boolean> => {
        const editItemIndex = pipelineStages.findIndex((stage) => stage == stageEntity);
        setEditStageIndex(editItemIndex);
        // Populate pre-edit stages
        const preEditStages = pipelineStages.filter((stage, index) => {
            return 1 < index && index < editItemIndex;
        });
        setPipelinePreEditStages(preEditStages);
        // Populate post-edit stages
        const postEditStages = pipelineStages.filter((stage, index) => {
            return 1 >= index || index > editItemIndex;
        });
        setPipelinePostEditStages(postEditStages);
        setIsEdit(true);
        setNewStageName(stageEntity.name);
        setShowAddStage(true);

        return true;
    };

    const onSubmitAddStage = async (e: FormEvent) => {
        e.preventDefault();
        const stageNameValid = !!newStageName && isValidString(newStageName);
        setNewStageNameValid(stageNameValid);
        if (stageNameValid) {
            handleAddStage();
            setShowAddStage(false);
            setNewStageName('');
        }
    };

    const onSubmitForm = async (e: FormEvent) => {
        setIsSaving(true);
        e.preventDefault();
        if ((await validate()) && (await handleAddRow())) props.onClose();
        setIsSaving(false);
    };

    const onSubmitAndAddAnother = async (e: FormEvent) => {
        setIsSaving(true);
        e.preventDefault();
        if ((await validate()) && (await handleAddRow())) {
            reset();
            focusRef.current?.focus();
        }
        setIsSaving(false);
    };

    return (
        <Sisp
            isOpen={props.shown}
            onSubmit={handleAddRow}
            onCancel={() => {
                reset();
                props.onClose();
            }}
            validate={validate}
            footerOverride={
                <>
                    <Button
                        variant="default"
                        onClick={() => {
                            reset();
                            props.onClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <PendingButton variant="default" onClick={onSubmitAndAddAnother} pending={isSaving}>
                        Save & Add Another
                    </PendingButton>
                    <PendingButton variant="primary" onClick={onSubmitForm} pending={isSaving}>
                        Save
                    </PendingButton>
                </>
            }
        >
            <h4>Add a Deal Pipeline</h4>
            <Form onSubmit={onSubmitForm}>
                <Form.Group>
                    <Form.Label>
                        Pipeline Name <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        autoComplete="off"
                        ref={focusRef}
                        name="text"
                        isInvalid={!newNameValid}
                        value={newName || ''}
                        onChange={(event) => {
                            setNewName(event.target.value);
                            setNewNameValid(true);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {!newNameValid && 'This field is required.'}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Checkbox
                        label="Make this the default pipeline"
                        isChecked={newDefault}
                        onChange={(event) => setNewDefault(event.target.checked)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        Typical Deal Length (in days) <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        name="deal_length"
                        isInvalid={!newDealLengthValid}
                        value={newDealLength || ''}
                        onChange={(event) => {
                            setNewDealLength(event.target.value);
                            setNewDealLengthValid(true);
                        }}
                    />
                    <span className="help-block">This is used to auto-populate the close date on new deals.</span>
                    <Form.Control.Feedback type="invalid">
                        {!newDealLengthValid && 'This field is required.'}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <div>
                        {!showAddStage && (
                            <Button
                                variant="default"
                                size="sm"
                                className="pull-right"
                                style={{ margin: '10px 0' }}
                                onClick={() => onAddStageClick()}
                            >
                                Add a Stage
                            </Button>
                        )}
                        <Form.Label>Stages</Form.Label>
                    </div>
                </Form.Group>
                {(isAdd || isEdit) && pipelinePreEditStages.length != 0 && (
                    <Form.Group>
                        <StageList
                            stages={pipelinePreEditStages}
                            editAction={handleEditStage}
                            deleteAction={handleDeleteStage}
                            reorderAction={handleReorderStage}
                        />
                    </Form.Group>
                )}
                {showAddStage && (
                    <Form.Group style={{ position: 'relative' }}>
                        <Button className="pull-right" onClick={onSubmitAddStage}>
                            Save
                        </Button>
                        <Form.Control
                            ref={newPipelineStageRef}
                            name="stage_name"
                            isInvalid={!newStageNameValid}
                            value={newStageName || ''}
                            style={{ width: '84%' }}
                            onChange={(event) => {
                                setNewStageName(event.target.value);
                                setNewStageNameValid(true);
                            }}
                        />
                        <FontAwesomeIcon
                            icon={faTimes}
                            className="close"
                            style={{ position: 'absolute', right: '18%', bottom: '0', top: '0', margin: 'auto 0' }}
                            onClick={cancelAddEditStage}
                        />
                    </Form.Group>
                )}
                {!(isAdd || isEdit) && (
                    <Form.Group>
                        <StageList
                            stages={pipelineStages}
                            editAction={handleEditStage}
                            deleteAction={handleDeleteStage}
                            reorderAction={handleReorderStage}
                        />
                    </Form.Group>
                )}
                {(isAdd || isEdit) && (
                    <Form.Group>
                        <StageList
                            stages={pipelinePostEditStages}
                            editAction={handleEditStage}
                            deleteAction={handleDeleteStage}
                            reorderAction={handleReorderStage}
                        />
                    </Form.Group>
                )}
            </Form>
        </Sisp>
    );
};

export default DealPipelinesAddSisp;
