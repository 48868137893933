import React, { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/Overlay';

interface Props {
    tooltip: string;
    text: string;
    placement?: Placement;
}

const OverflowTooltip = (props: Props) => {
    const textElementRef = useRef<HTMLInputElement | null>(null);
    const [hoverStatus, setHover] = useState(false);

    const compareSize = () => {
        if (textElementRef.current) {
            const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
            setHover(compare);
        }
    };

    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
    }, []);

    useEffect(
        () => () => {
            window.removeEventListener('resize', compareSize);
        },
        [],
    );

    return (
        <OverlayTrigger
            show={hoverStatus ? undefined : false}
            overlay={(overlayProps) => (
                <Tooltip id="checkbox-tooltip" {...overlayProps} placement={props.placement ?? 'auto'}>
                    {props.tooltip}
                </Tooltip>
            )}
        >
            <div
                ref={textElementRef}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {props.text}
            </div>
        </OverlayTrigger>
    );
};

export default OverflowTooltip;
