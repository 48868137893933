import * as ActionTypes from '../Actions/ActionTypes';
import * as DealsActionType from '../../../Entities/Deal/ActionTypes';

const initialState = {
    updateActivities: false,
    interactionSispOpen: '',
    exitViewPage: false
};

export default function (state, action) {
    if (state === undefined) {
        state = initialState;
    }

    switch (action.type) {
        case ActionTypes.ACTIVITIES_UPDATED:
            return Object.assign({}, state, {
                updateActivities: false
            });
        case ActionTypes.VIEW_PAGE_EXITED:
            return Object.assign({}, state, {
                exitViewPage: false,
                interactionSispOpen: ''
            });
        case ActionTypes.OPEN_INTERACTION_SISP:
            return Object.assign({}, state, {
                interactionSispOpen: action.payload.interactionType
            });
        case ActionTypes.CLOSE_INTERACTION_SISP:
        case ActionTypes.OPEN_FORM_SISP:
        case ActionTypes.OPEN_PREVIEW_SISP:
            return Object.assign({}, state, {
                interactionSispOpen: ''
            });
        case DealsActionType.DEAL_SAVED:
            return Object.assign({}, state, {
                updateActivities: true,
            });
        case DealsActionType.DEALS_DELETED:
        case DealsActionType.DEAL_DELETED:
            return Object.assign({}, state, {
                exitViewPage: true
            });
        default:
            return state;
    }
}