type EducationDataPackage = 'EDUK' | 'EDIN' | 'EDUS';
export default EducationDataPackage;

export function displayDataPackage(dataPackage: EducationDataPackage) {
    switch (dataPackage) {
        case 'EDIN':
            return 'International';
        case 'EDUK':
            return 'United Kingdom';
        case 'EDUS':
            return 'United States';
    }
}

export function allDataPackages() {
    return [
        {
            value: 'EDIN',
            label: displayDataPackage('EDIN'),
        },
        {
            value: 'EDUK',
            label: displayDataPackage('EDUK'),
        },
        {
            value: 'EDUS',
            label: displayDataPackage('EDUS'),
        },
    ];
}
