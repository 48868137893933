'use strict';

import React from 'react';
import PropTypes from "prop-types";
import DealPipelineMultiSelect from "../../../../Entities/Deal/DealPipeline/Components/DealPipelineMultiSelect";
import DealStageStateMultiSelect from "../../../../ValueObjects/DealStageState/DealStageStateMultiSelect";
import DealTypeMultiSelect from "../../../../Entities/Deal/DealType/Components/DealTypeMultiSelect";
import DealLostReasonMultiSelect from "../../../../Entities/Deal/DealLostReason/Components/DealLostReasonMultiSelect";
import UserMultiSelect from "../../../../Entities/User/Components/UserMultiSelect";
import ConnectedContactMultiSelect from "../../../../Entities/Contact/Components/ConnectedContactMultiSelect";
import ConnectedOrganisationMultiSelect from "../../../../Entities/Organisation/Components/ConnectedOrganisationMultiSelect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/pro-regular-svg-icons";
import DateTimeRange from "../../../../CommonComponents/DateTime/DateTimeRange";
import NumberRange from "../../../../CommonComponents/NumberRange/NumberRange";

// these are all multi-selects
export const FIELD_DEAL_PIPELINE = 'Pipeline';
export const FIELD_DEAL_STAGE_STATE = 'Stage State';
export const FIELD_DEAL_TYPE = 'Type';
export const FIELD_OWNED_BY = 'Owned By';
export const FIELD_CREATED_BY = 'Created By';
export const FIELD_MODIFIED_BY = 'Modified By';
export const FIELD_RELATES_TO_CONTACT = 'Relates To Contact';
export const FIELD_RELATES_TO_ORGANISATION = 'Relates To Organisation';
export const FIELD_CREATED_DATE_BETWEEN = 'Created Date';
export const FIELD_FOLLOW_UP_DATE_BETWEEN = 'Follow Up Date';
export const FIELD_CLOSE_DATE_BETWEEN = 'Close By Date';
export const FIELD_DEAL_LOST_REASON = 'Lost Reason';
export const FIELD_VALUE_BETWEEN = 'Value';

export class FieldComponent extends React.Component {

    componentDidMount() {
        if (this.props.component === FIELD_DEAL_TYPE && this.props.dealTypeSearchResults === undefined) this.props.loadDealTypes();
        if (this.props.component === FIELD_DEAL_LOST_REASON && this.props.dealLostReasonSearchResults === undefined) this.props.loadDealLostReasons();
        if (this.props.component === FIELD_DEAL_PIPELINE && this.props.dealPipelineSearchResults === undefined) this.props.loadDealPipelines();
        if (
            (this.props.component === FIELD_OWNED_BY
            || this.props.component === FIELD_CREATED_BY
            || this.props.component === FIELD_MODIFIED_BY
            ) && this.props.userSearchResults === undefined
        ) this.props.loadUsers();
    }

    toggleComponent(componentName) {
        this.props.toggleComponent(componentName);
    }

    updateDealPipeline(newOptions) {
        const newSearch = Object.assign({}, this.props.currentSearch);
        newSearch.pipelines = newOptions;
        this.props.updateSearch(newSearch);
    }

    updateDealStageState(newOptions) {
        const newSearch = Object.assign({}, this.props.currentSearch);
        newSearch.stageStates = newOptions;
        this.props.updateSearch(newSearch);
    }

    updateDealType(newOptions) {
        const newSearch = Object.assign({}, this.props.currentSearch);
        newSearch.types = newOptions;
        this.props.updateSearch(newSearch);
    }

    updateOwnedBy(newOptions) {
        const newSearch = Object.assign({}, this.props.currentSearch);
        newSearch.ownedBy = newOptions;
        this.props.updateSearch(newSearch);
    }

    updateCreatedBy(newOptions) {
        const newSearch = Object.assign({}, this.props.currentSearch);
        newSearch.createdBy = newOptions;
        this.props.updateSearch(newSearch);
    }

    updateModifiedBy(newOptions) {
        const newSearch = Object.assign({}, this.props.currentSearch);
        newSearch.modifiedBy = newOptions;
        this.props.updateSearch(newSearch);
    }

    updateRelatesToContact(newOptions) {
        const newSearch = Object.assign({}, this.props.currentSearch);
        newSearch.relatesToContacts = newOptions;
        this.props.updateSearch(newSearch);
    }

    updateRelatesToOrganisation(newOptions) {
        const newSearch = Object.assign({}, this.props.currentSearch);
        newSearch.relatesToOrganisations = newOptions;
        this.props.updateSearch(newSearch);
    }

    updateCreatedDateBetween(from, to, preset) {
        const newSearch = Object.assign({}, this.props.currentSearch);
        newSearch.createdDateBetween = {
            from: from,
            to: to,
            preset: preset
        };
        this.props.updateSearch(newSearch);
    }

    updateFollowUpDateBetween(from, to, preset) {
        const newSearch = Object.assign({}, this.props.currentSearch);
        newSearch.followUpDateBetween = {
            from: from,
            to: to,
            preset: preset
        };
        this.props.updateSearch(newSearch);
    }

    updateCloseDateBetween(from, to, preset) {
        const newSearch = Object.assign({}, this.props.currentSearch);
        newSearch.closeDateBetween = {
            from: from,
            to: to,
            preset: preset
        };
        this.props.updateSearch(newSearch);
    }

    updateDealLostReason(newOptions) {
        const newSearch = Object.assign({}, this.props.currentSearch);
        newSearch.lostReasons = newOptions;
        this.props.updateSearch(newSearch);
    }

    updateValueBetween(from, to) {
        const newSearch = Object.assign({}, this.props.currentSearch);
        newSearch.valueBetween = {
            from: from,
            to: to
        };
        this.props.updateSearch(newSearch);
    }

    removeField(e, componentName) {
        e.preventDefault();
        this.props.removeComponent(componentName);
    }

    fieldDisplay() {
        switch (this.props.component) {
            case FIELD_DEAL_PIPELINE:
                return <DealPipelineMultiSelect
                    selectedValues={this.props.currentSearch.pipelines}
                    open={this.props.openComponent === FIELD_DEAL_PIPELINE}
                    toggle={() => this.toggleComponent(FIELD_DEAL_PIPELINE)}
                    updateSelectedOptions={this.updateDealPipeline.bind(this)}
                    availableValues={this.props.dealPipelineSearchResults}
                />;
            case FIELD_DEAL_STAGE_STATE:
                return <DealStageStateMultiSelect
                    selectedValues={this.props.currentSearch.stageStates}
                    open={this.props.openComponent === FIELD_DEAL_STAGE_STATE}
                    toggle={() => this.toggleComponent(FIELD_DEAL_STAGE_STATE)}
                    updateSelectedOptions={this.updateDealStageState.bind(this)}
                />;
            case FIELD_DEAL_TYPE:
                return <DealTypeMultiSelect
                    selectedValues={this.props.currentSearch.types}
                    open={this.props.openComponent === FIELD_DEAL_TYPE}
                    toggle={() => this.toggleComponent(FIELD_DEAL_TYPE)}
                    updateSelectedOptions={this.updateDealType.bind(this)}
                    availableValues={this.props.dealTypeSearchResults}
                />;
            case FIELD_OWNED_BY:
                return <UserMultiSelect
                    title={FIELD_OWNED_BY}
                    selectedValues={this.props.currentSearch.ownedBy}
                    open={this.props.openComponent === FIELD_OWNED_BY}
                    toggle={() => this.toggleComponent(FIELD_OWNED_BY)}
                    updateSelectedOptions={this.updateOwnedBy.bind(this)}
                    availableValues={this.props.userSearchResults}
                />;
            case FIELD_CREATED_BY:
                return <UserMultiSelect
                    title={FIELD_CREATED_BY}
                    selectedValues={this.props.currentSearch.createdBy}
                    open={this.props.openComponent === FIELD_CREATED_BY}
                    toggle={() => this.toggleComponent(FIELD_CREATED_BY)}
                    updateSelectedOptions={this.updateCreatedBy.bind(this)}
                    availableValues={this.props.userSearchResults}
                />;
            case FIELD_MODIFIED_BY:
                return <UserMultiSelect
                    title={FIELD_MODIFIED_BY}
                    selectedValues={this.props.currentSearch.modifiedBy}
                    open={this.props.openComponent === FIELD_MODIFIED_BY}
                    toggle={() => this.toggleComponent(FIELD_MODIFIED_BY)}
                    updateSelectedOptions={this.updateModifiedBy.bind(this)}
                    availableValues={this.props.userSearchResults}
                />;
            case FIELD_CREATED_DATE_BETWEEN:
                return <DateTimeRange
                    fromValue={this.props.currentSearch.createdDateBetween ? this.props.currentSearch.createdDateBetween.from : ''}
                    toValue={this.props.currentSearch.createdDateBetween ? this.props.currentSearch.createdDateBetween.to : ''}
                    presetUsed={this.props.currentSearch.createdDateBetween ? this.props.currentSearch.createdDateBetween.preset : undefined}
                    locale={this.props.locale ? this.props.locale.iso : 'en-GB'}
                    open={this.props.openComponent === FIELD_CREATED_DATE_BETWEEN}
                    toggle={() => this.toggleComponent(FIELD_CREATED_DATE_BETWEEN)}
                    update={this.updateCreatedDateBetween.bind(this)}
                    title={FIELD_CREATED_DATE_BETWEEN}
                    disableOutOfRangeDates={false}
                    disableFutureDates={true}
                    showFuturePresets={false}
                    timeEnabled={false}
                />;
            case FIELD_FOLLOW_UP_DATE_BETWEEN:
                return <DateTimeRange
                    fromValue={this.props.currentSearch.followUpDateBetween ? this.props.currentSearch.followUpDateBetween.from : ''}
                    toValue={this.props.currentSearch.followUpDateBetween ? this.props.currentSearch.followUpDateBetween.to : ''}
                    presetUsed={this.props.currentSearch.followUpDateBetween ? this.props.currentSearch.followUpDateBetween.preset : undefined}
                    locale={this.props.locale ? this.props.locale.iso : 'en-GB'}
                    open={this.props.openComponent === FIELD_FOLLOW_UP_DATE_BETWEEN}
                    toggle={() => this.toggleComponent(FIELD_FOLLOW_UP_DATE_BETWEEN)}
                    update={this.updateFollowUpDateBetween.bind(this)}
                    title={FIELD_FOLLOW_UP_DATE_BETWEEN}
                    disableOutOfRangeDates={false}
                    disableFutureDates={false}
                    showFuturePresets={true}
                    timeEnabled={false}
                />;
            case FIELD_CLOSE_DATE_BETWEEN:
                return <DateTimeRange
                    fromValue={this.props.currentSearch.closeDateBetween ? this.props.currentSearch.closeDateBetween.from : ''}
                    toValue={this.props.currentSearch.closeDateBetween ? this.props.currentSearch.closeDateBetween.to : ''}
                    presetUsed={this.props.currentSearch.closeDateBetween ? this.props.currentSearch.closeDateBetween.preset : undefined}
                    locale={this.props.locale ? this.props.locale.iso : 'en-GB'}
                    open={this.props.openComponent === FIELD_CLOSE_DATE_BETWEEN}
                    toggle={() => this.toggleComponent(FIELD_CLOSE_DATE_BETWEEN)}
                    update={this.updateCloseDateBetween.bind(this)}
                    title={FIELD_CLOSE_DATE_BETWEEN}
                    disableOutOfRangeDates={false}
                    disableFutureDates={false}
                    showFuturePresets={true}
                    timeEnabled={false}
                />;
            case FIELD_DEAL_LOST_REASON:
                return <DealLostReasonMultiSelect
                    selectedValues={this.props.currentSearch.lostReasons}
                    open={this.props.openComponent === FIELD_DEAL_LOST_REASON}
                    toggle={() => this.toggleComponent(FIELD_DEAL_LOST_REASON)}
                    updateSelectedOptions={this.updateDealLostReason.bind(this)}
                    availableValues={this.props.dealLostReasonSearchResults}
                />;
            case FIELD_VALUE_BETWEEN:
                return <NumberRange
                    fromValue={this.props.currentSearch.valueBetween ? this.props.currentSearch.valueBetween.from : ''}
                    toValue={this.props.currentSearch.valueBetween ? this.props.currentSearch.valueBetween.to : ''}
                    open={this.props.openComponent === FIELD_VALUE_BETWEEN}
                    toggle={() => this.toggleComponent(FIELD_VALUE_BETWEEN)}
                    update={this.updateValueBetween.bind(this)}
                    title={FIELD_VALUE_BETWEEN}
                />;
            case FIELD_RELATES_TO_CONTACT:
                return <ConnectedContactMultiSelect
                    selectedValues={this.props.currentSearch.relatesToContacts}
                    open={this.props.openComponent === FIELD_RELATES_TO_CONTACT}
                    toggle={() => this.toggleComponent(FIELD_RELATES_TO_CONTACT)}
                    updateSelectedOptions={this.updateRelatesToContact.bind(this)}
                />;
            case FIELD_RELATES_TO_ORGANISATION:
                return <ConnectedOrganisationMultiSelect
                    selectedValues={this.props.currentSearch.relatesToOrganisations}
                    open={this.props.openComponent === FIELD_RELATES_TO_ORGANISATION}
                    toggle={() => this.toggleComponent(FIELD_RELATES_TO_ORGANISATION)}
                    updateSelectedOptions={this.updateRelatesToOrganisation.bind(this)}
                />;
            default:
                return <code>{this.props.component} not available</code>;
        }
    }

    removeButton() {
        return <a className="react-adv--field-control-remove" href="#" onClick={e => this.removeField(e, this.props.component)}>
            <FontAwesomeIcon icon={faTimes}/>
        </a>
    }

    render() {
        return <div className="react-adv--field react-adv--field-has-remove">
            {this.removeButton()}
            {this.fieldDisplay()}
        </div>
    }

}

FieldComponent.propTypes = {
    component: PropTypes.string,
    currentSearch: PropTypes.object,
    toggleComponent: PropTypes.func,
    updateSearch: PropTypes.func,
    locale: PropTypes.object,

    userSearchResults: PropTypes.array,
    loadUsers: PropTypes.func,
    dealTypeSearchResults: PropTypes.array,
    loadDealTypes: PropTypes.func,
    dealLostReasonSearchResults: PropTypes.array,
    loadDealLostReasons: PropTypes.func,
    dealPipelineSearchResults: PropTypes.array,
    loadDealPipelines: PropTypes.func,
    removeComponent: PropTypes.func
};


export function allAvailableFields(modules) {
    if (modules === undefined) return undefined;
    const fields = [
        FIELD_DEAL_PIPELINE,
        FIELD_DEAL_STAGE_STATE,
        FIELD_DEAL_TYPE,
        FIELD_OWNED_BY,
        FIELD_CREATED_DATE_BETWEEN,
        FIELD_FOLLOW_UP_DATE_BETWEEN,
        FIELD_CLOSE_DATE_BETWEEN,
        FIELD_DEAL_LOST_REASON,
        FIELD_VALUE_BETWEEN,
        FIELD_RELATES_TO_CONTACT,
        FIELD_RELATES_TO_ORGANISATION,
        FIELD_CREATED_BY,
        FIELD_MODIFIED_BY
    ];

    return fields;
}
