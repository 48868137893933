'use strict';

import * as ActionTypes from "./ActionTypes";
import UserRepository from "./UserRepository";

export function loadUsers() {
    return (dispatch) => {
        UserRepository.all()
            .then(response => dispatch(usersLoaded(response)))
            .catch(e => dispatch(usersLoadingFailed(e.message ? e.message : e)));
    }
}

function usersLoaded(users) {
    return {
        type: ActionTypes.USERS_LOADED,
        payload: {
            users: users
        }
    }
}

function usersLoadingFailed(error) {
    return {
        type: ActionTypes.USERS_LOADING_FAILED,
        payload: {
            error: error
        }
    }
}