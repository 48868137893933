import { AppEvent, EventBusInstance } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import EmailType from '../../Models/EmailType';

interface Props {
    uniqueKey: string;
    onSuccess: (event: any) => Promise<boolean>;
}

const EmailsEditRedirect: FunctionComponent<Props> = (props: Props) => {
    const editUrlPrefix = '/emails/view/edit/';
    const [shown, setShown] = useState<boolean>(false);

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<EmailType>) => {
            if (event.target !== props.uniqueKey) return;
            const editUrl = editUrlPrefix + event.message.id;
            window.location.href = editUrl;
        });
    }, [shown]);

    return <></>;
};

export default EmailsEditRedirect;
