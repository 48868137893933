import { LogLevel, Modal, ModalType, showBanner } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext } from 'react';
import { TasksRequest } from '../../Api/TasksRequest';
import { RepositoryFactoryContext } from '../../index';

interface Props {
    selectedRowsForDeletion: number[];
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
    modalShown: boolean;
    reloadDataGrid: () => void;
    closeModal: () => void;
}

const TasksDeleteModal: FunctionComponent<Props> = (props: Props) => {
    const tasksRepository = useContext(RepositoryFactoryContext).getApiRepository(new TasksRequest());

    const deleteRows = (): Promise<boolean> => {
        return tasksRepository
            .delete(props.selectedRowsForDeletion)
            .then((success: any) => {
                showBanner({
                    message: success.data.message,
                    level: LogLevel.SUCCESS,
                });
                props.reloadDataGrid();
                return true;
            })
            .catch((err: any) => {
                showBanner({
                    message: err?.message ?? err,
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    return (
        <Modal
            isOpen={props.modalShown}
            type={ModalType.DELETE}
            title={
                props.selectedRowsForDeletion.length > 1
                    ? `Delete ${props.dataGridEntityPlural ?? 'Rows'}`
                    : `Delete ${props.dataGridEntitySingular ?? 'Row'}`
            }
            close={props.closeModal}
            onSubmit={deleteRows}
        >
            <>
                Are you sure you want to delete
                {props.selectedRowsForDeletion.length > 1
                    ? ` these ${props.selectedRowsForDeletion.length} ${(
                          props.dataGridEntityPlural ?? 'rows'
                      ).toLowerCase()}?`
                    : ` this ${(props.dataGridEntitySingular ?? 'row').toLowerCase()}?`}
                <>
                    <br />
                    <br />
                    Deleted data cannot be recovered.
                </>
            </>
        </Modal>
    );
};

export default TasksDeleteModal;
