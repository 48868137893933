'use strict';

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons'

/**
 * interface FilterProps {
 *    currentFilter: string
 *    updateFilter(newFilter: string): void
 * }
 */
export default class Filter extends React.Component {

    filterChange(e) {
        this.props.updateFilter(e.target.value);
    }

    clearFilter() {
        this.props.updateFilter('');
    }

    render() {
        const clearButton = this.props.currentFilter
            ? <button className="btn btn-default" onClick={this.clearFilter.bind(this)}><FontAwesomeIcon icon={faTimes} /></button>
            : null;

        return <div className="react-select--filter">
            <input type="text" onChange={this.filterChange.bind(this)} value={this.props.currentFilter} autoFocus={true} />
            <span className="react-select--filter--icon">
                { clearButton }
                <FontAwesomeIcon icon={faSearch} />
            </span>
        </div>;
    }

}