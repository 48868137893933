import * as ActionTypes from "./ModalActionTypes";

export function openModal(content, header, footer) {
    return {
        type: ActionTypes.OPEN,
        payload: {
            content: content,
            header: header,
            footer: footer
        }
    }
}

export function updateModal() {
    return {
        type: ActionTypes.UPDATE
    }
}

export function closeModal() {
    return {
        type: ActionTypes.CLOSE
    }
}