'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import './select.scss';
import Value from "./Pieces/Value";
import DropDown from "./Pieces/DropDown";
import DropDownStateless from "./Pieces/DropDownStateless";
import {shortenPlaceholder} from "./functions";

class MultiSelect extends React.Component {

    constructor(props) {
        super(props);

        // Allow removeEventListener on bound functions
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    getPositionOfDropDown() {
        if (!this.refs.multiSelectContainer) return null;
        const rects = this.refs.multiSelectContainer.getClientRects();
        if (rects.length === 0) return null;
        const element = rects[0];
        let offset = element.height - 1;

        // Should the drop down be placed above the text box?
        const windowHeight = window.innerHeight;
        let placeDropDownAbove = windowHeight - element.y < 350;

        // Do not place drop down above if the height of the dropdown won't fit above
        if (element.y < 350) placeDropDownAbove = false;

        if (placeDropDownAbove) offset = -Math.abs(offset);

        return offset;
    }

    handleClick(e) {
        if (!this.refs.multiSelectContainer || this.refs.multiSelectContainer.contains(e.target)) return;
        if (!this.props.open) return;
        this.onBlur();
        this.props.toggle();
    }

    showOptions(e) {
        e.preventDefault();
        if (this.props.open) this.onBlur();
        this.props.toggle();
    }

    onBlur() {
        if (!this.props.onBlur) return;
        this.props.onBlur();
    }

    updateSelectedOptions(val) {
        this.props.updateSelectedOptions(val);
    }

    clearOption(e) {
        e.preventDefault();
        e.stopPropagation(); // Prevent dropdown opening on clear
        this.props.updateSelectedOptions([]);
    }

    getDropDown() {
        if (!this.props.open) return null;

        return this.props.updateFilter === undefined
            ? <DropDown
                selectedOptions={this.props.selectedOptions}
                availableOptions={this.props.availableOptions}
                updateSelectedOptions={this.updateSelectedOptions.bind(this)}
                multi={true}
                position={this.getPositionOfDropDown()}
            />
            : <DropDownStateless
                selectedOptions={this.props.selectedOptions}
                availableOptions={this.props.availableOptions}
                updateSelectedOptions={this.updateSelectedOptions.bind(this)}
                currentFilter={this.props.currentFilter}
                updateFilter={this.props.updateFilter}
                totalAvailable={this.props.totalAvailable}
                loadMore={this.props.loadMore}
                moreLoading={this.props.moreLoading}
                multi={true}
                position={this.getPositionOfDropDown()}
            />;
    }

    render() {
        let className = "react-select react-select--multi";
        if (this.props.open) className += ' react-select--open';

        // Only show optional clear button when something is selected
        let allowClear = (typeof this.props.selectedOptions !== 'undefined' && this.props.selectedOptions.length)
            ? this.props.allowClear
            : false;

        return <div className={className} ref="multiSelectContainer">
            <Value
                open={this.props.open}
                showOptions={this.showOptions.bind(this)}
                clearOptions={this.clearOption.bind(this)}
                allowClear={allowClear}
                placeholder={this.props.placeholder}
                shortenPlaceHolder={this.props.shortenPlaceholder}
                usePills={this.props.usePills}
                availableOptions={this.props.availableOptions}
                selectedOptions={this.props.selectedOptions}
                disabled={this.props.disabled}
            />
            {this.getDropDown()}
        </div>;
    }

}

MultiSelect.propTypes = {
    placeholder: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    shortenPlaceholder: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    selectedOptions: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])),
    availableOptions: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]).isRequired,
            label: PropTypes.string.isRequired,
            labelElement: PropTypes.element,
        })
    ])),
    updateSelectedOptions: PropTypes.func.isRequired,
    allowClear: PropTypes.bool,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,

    // for ajax filtering:
    currentFilter: PropTypes.string,
    updateFilter: PropTypes.func,

    // for pagination:
    totalAvailable: PropTypes.number,
    loadMore: PropTypes.func,
    moreLoading: PropTypes.bool,

    // for gussying
    usePills: PropTypes.bool
};

MultiSelect.defaultProps = {
    placeholder: 'Select',
    shortenPlaceholder: true,
    usePills: false
};

export default MultiSelect;