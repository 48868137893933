import { formatStat, Stat } from '@client/CommonComponents/StatCards/Stat';
import _ from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import NumbersHelper from '../../Applications/CampaignReporting/Common/Helpers/NumbersHelper';
import HttpRequestService from '../HttpRequestService/HttpRequestService';
import PercentDiff from '../PercentDiff/PercentDiff';
import Spinner from '../Spinner/Spinner';
import HelpTooltip from '../Tooltips/HelpTooltip';
import './MultiStatCard.scss';
import { StatFormat } from './StatCard';

interface Props {
    title: string;
    url: string;
    stats: Stat[];
    mainStat: Stat;
    body: any;
    icon?: JSX.Element;
    comparison?: {
        label: string;
        value: number;
    };
    footerLink?: {
        text: string;
        href: string;
    };
    help?: {
        title: string;
        text: string;
    };
}

interface StatResponse {
    value: number;
}

const MultiStatCard: FunctionComponent<Props> = (props: Props) => {
    const http = new HttpRequestService('');
    const [stats, setStats] = useState<{ [key: string]: StatResponse }>();
    const [loading, setLoading] = useState<boolean>(true);

    const mainStatValue = stats ? Number(stats[props.mainStat.key].value) : 0;

    async function getStats() {
        const body = {
            ...props.body,
            fields: _.flattenDeep(props.stats.map((s) => [s.key, s.rateKey])),
        };
        const data = await http.post(props.url, body, `stat-card-${props.url}`);
        setStats(data.data);
        setLoading(false);
    }

    useEffect(() => {
        if (props.body === undefined) return;
        setLoading(true);
        getStats();
    }, [props.body]);

    return (
        <div className="multi-stat-card-wrapper metric-card-wrapper rounded-card">
            <Card>
                <Card.Header>
                    <div className="col-sm-6 text-left metric-title">
                        {props.title}{' '}
                        {props.help && <HelpTooltip title={props.help.title} helptext={props.help.text} />}
                    </div>
                    <div className="col-sm-6 text-right metric-percent">
                        {loading && <Spinner />}
                        {!loading && <>{formatStat(mainStatValue, props.mainStat.format)}</>}
                        {props.comparison && (
                            <div className="metric-diff">
                                {loading && '-'}
                                {!loading && (
                                    <PercentDiff
                                        diff={NumbersHelper.percentageDifference(mainStatValue, props.comparison.value)}
                                    />
                                )}{' '}
                                {props.comparison.label}
                            </div>
                        )}
                    </div>
                </Card.Header>
                <Card.Body>
                    {props.stats.map((stat) => {
                        const statValue = stats ? stats[stat.key].value : 0;
                        const statRateValue = stats && stat.rateKey ? stats[stat.rateKey].value : 0;
                        return (
                            <div className="metric-row">
                                <span className="metric-text-label">
                                    {stat.title}
                                    {stat.help && <HelpTooltip title={stat.help.title} helptext={stat.help.text} />}
                                </span>
                                <span className="metric-value">
                                    {loading && <Spinner />}
                                    {!loading && (
                                        <span className="metric-percentage">
                                            ({stat.rateKey && formatStat(Number(statRateValue), StatFormat.PERCENT)})
                                        </span>
                                    )}
                                    {!loading && formatStat(Number(statValue), stat.format)}
                                </span>
                            </div>
                        );
                    })}
                    {props.footerLink && (
                        <a href={props.footerLink.href} target="_blank" className="faq-link no-print">
                            {props.footerLink.text}
                        </a>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
};

export default MultiStatCard;
