import React, { FunctionComponent, PropsWithChildren } from 'react';
import './viewpanel.scss';

interface Props {
    label: any;
}

const ViewPanelItem: FunctionComponent<Props> = (props: PropsWithChildren<Props>) => {
    return (
        <div className="react-view-panel-item">
            <p className="content-header content-small">{props.label}</p>
            <div className="content">{props.children}</div>
        </div>
    );
};

export default ViewPanelItem;
