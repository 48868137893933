import { Modal, ModalType } from '@sprint/sprint-react-components';
import React from 'react';
import { Button } from 'react-bootstrap';

interface Props {
    href: string;
    close: () => void;
}

const GoogleAgreementModal = (props: Props) => {
    return (
        <Modal
            title="Connect to Your Google Account"
            isOpen={true}
            type={ModalType.INFO}
            footerOverride={
                <Button href={props.href} onClick={props.close}>
                    Continue
                </Button>
            }
            close={props.close}
        >
            <p>
                When you integrate your Google account with Campus it will be able to access data and information from
                your account and send Gmail messages.
            </p>
            <p>
                You can revoke Campus's access from within your Google account by following the instructions listed{' '}
                <a target="_blank" href="https://support.google.com/accounts/answer/3466521">
                    here
                </a>
                .
            </p>
            <p>
                For more information on the Google integration and the types of data we can access, please see our{' '}
                <a target="_blank" href="https://www.sprint-education.co.uk/legal/privacy-policy">
                    Privacy Policy
                </a>
                .
            </p>
        </Modal>
    );
};

export default GoogleAgreementModal;
