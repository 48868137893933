'use strict';

import React from 'react';
import DateTimeInput from './DateTimeInput';
import DateHelper from '../../Helpers/DateHelper';
import * as RangePreset from '../../ValueObjects/DateTimeRangePreset/DateTimeRangePreset';
import Moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/en-ie';
import 'moment/locale/en-ca';
import 'moment/locale/en-au';
import 'moment/locale/en-nz';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDay} from "@fortawesome/pro-light-svg-icons";
import './datetime.scss';
import PropTypes from 'prop-types';

class DateTimeRange extends React.Component {

    value() {
        let iconClassName = "react-date-time-range--icon";
        if (this.props.open) iconClassName += " open";

        const dateHelper = new DateHelper(this.props.outputFormat);

        let title = (this.props.title ? this.props.title.replace(' Between', '') : 'Selected Dates') + ': ';
        let value = 'Any Date';
        let rangeString = dateHelper.rangeString(this.props.fromValue, this.props.toValue, this.props.presetUsed, this.props.timeEnabled, this.props.locale ? this.props.locale : 'en-GB');

        if (((this.props.fromValue && this.props.toValue) || this.props.presetUsed) && rangeString) {
            value = rangeString;
        }

        return <a href="#" className="react-date-time-range--value" onClick={this.props.toggle}>
            {title + value}
            <span className={iconClassName}>
                <i/>
            </span>
        </a>;
    }

    changeFrom(from) {
        if (from) {
            const dateHelper = new DateHelper(this.props.outputFormat);
            const fromMoment = Moment(from);
            const to = this.props.toValue ? this.props.toValue : dateHelper.thisDayEnd(Moment(from));
            const toMoment = Moment(to);

            if (this.props.timeEnabled) {
                this.props.update(
                    Moment.min(fromMoment, toMoment).startOf('minute').format(this.props.outputFormat),
                    Moment.max(fromMoment, toMoment).endOf('minute').format(this.props.outputFormat),
                    undefined
                );
            } else {
                this.props.update(
                    dateHelper.thisDayStart(Moment.min(fromMoment, toMoment)),
                    dateHelper.thisDayEnd(Moment.max(fromMoment, toMoment)),
                    undefined
                );
            }
        }
    }

    changeTo(to) {
        if (to) {
            const dateHelper = new DateHelper(this.props.outputFormat);
            const from = this.props.fromValue ? this.props.fromValue : dateHelper.thisDayStart(Moment(to));
            const fromMoment = Moment(from);
            to = this.props.fromValue ? to : dateHelper.thisDayEnd(Moment(to));
            const toMoment = Moment(to);

            if (this.props.timeEnabled) {
                this.props.update(
                    Moment.min(fromMoment, toMoment).startOf('minute').format(this.props.outputFormat),
                    Moment.max(fromMoment, toMoment).endOf('minute').format(this.props.outputFormat),
                    undefined
                );
            } else {
                this.props.update(
                    dateHelper.thisDayStart(Moment.min(fromMoment, toMoment)),
                    dateHelper.thisDayEnd(Moment.max(fromMoment, toMoment)),
                    undefined
                );
            }
        }
    }

    setPreset(e, option) {
        if (e) e.preventDefault();

        const dateHelper = new DateHelper(this.props.outputFormat);
        this.props.update(undefined, undefined, option);
    }

    renderDay(props, currentDate, selectedDate) {
        const from = this.props.fromValue;
        const to = this.props.toValue;

        // Style all dates in range
        let classes = currentDate.isBetween(from, to, 'day')
            ? `${props.className} in-selecting-range` : props.className;

        // Add rdtActive to selected from and end dates in pickers
        if (currentDate.isSame(to, 'day') && currentDate.isSame(from, 'day')) {
            classes += ' rdtActive';
        } else if (currentDate.isSame(to, 'day') || currentDate.isSame(from, 'day')) {
            classes += ' rdtActive';
            if (currentDate.isSame(selectedDate, 'day')) classes += ' this-end-of-range';
            else classes += ' opposite-end-of-range';
        }

        return (
            <td {...props}
                className={classes}>
                {currentDate.date()}
            </td>
        );
    }

    isValidStartDate(currentDate, selectedDate) {
        let isValid = true;

        if (this.props.disableOutOfRangeDates) {
            const to = this.props.toValue;

            isValid = (!to) ? true : currentDate.isSameOrBefore(to);
        }
        if (this.props.disableFutureDates && isValid) isValid = (currentDate.isSameOrBefore(Moment()));

        return isValid;
    }

    isValidEndDate(currentDate, selectedDate) {
        let isValid = true;

        if (this.props.disableOutOfRangeDates) {
            const from = this.props.fromValue;

            isValid = (!from) ? true : currentDate.isSameOrAfter(Moment(from).startOf('day'));
        }
        if (this.props.disableFutureDates && isValid) isValid = (currentDate.isSameOrBefore(Moment()));

        return isValid;
    }

    customRangeInputs() {
        return <div className="react-date-time-range--dates">
            <div>
                <label>Between</label>
                <DateTimeInput
                    value={this.props.fromValue}
                    locale={this.props.locale}
                    onChange={this.changeFrom.bind(this)}
                    renderDay={this.renderDay.bind(this)}
                    isValidDate={this.isValidStartDate.bind(this)}
                    outputFormat={this.props.outputFormat}
                    timeFormat={this.props.timeEnabled}
                    closeOnSelect={true}
                    disableOnClickOutside={false}
                    showClear={false}
                    popupAbove={!!this.props.showRangePresets}
                    dimInput={!!this.props.presetUsed}
                />
            </div>
            <div>
                <label>and</label>
                <DateTimeInput
                    value={this.props.toValue}
                    locale={this.props.locale}
                    onChange={this.changeTo.bind(this)}
                    renderDay={this.renderDay.bind(this)}
                    isValidDate={this.isValidEndDate.bind(this)}
                    outputFormat={this.props.outputFormat}
                    timeFormat={this.props.timeEnabled}
                    closeOnSelect={true}
                    disableOnClickOutside={false}
                    showClear={false}
                    popupAbove={!!this.props.showRangePresets}
                    dimInput={!!this.props.presetUsed}
                />
            </div>
        </div>;
    }

    rangePresets() {
        let presetElements = [];
        // Day
        presetElements.push(this.presetElement(RangePreset.TODAY));
        presetElements.push(this.presetElement(this.props.showFuturePresets ? RangePreset.TOMORROW : RangePreset.YESTERDAY));
        // Week
        presetElements.push(this.presetElement(RangePreset.THIS_WEEK));
        presetElements.push(this.presetElement(this.props.showFuturePresets ? RangePreset.NEXT_WEEK : RangePreset.LAST_WEEK));
        // Month
        presetElements.push(this.presetElement(RangePreset.THIS_MONTH));
        presetElements.push(this.presetElement(this.props.showFuturePresets ? RangePreset.NEXT_MONTH : RangePreset.LAST_MONTH));
        // Year and beyond
        presetElements.push(this.presetElement(RangePreset.THIS_YEAR));
        if (this.props.showFuturePresets) presetElements.push(this.presetElement(this.props.showCustomRange ? RangePreset.FUTURE : RangePreset.NEXT_YEAR));
        else presetElements.push(this.presetElement(RangePreset.LAST_YEAR));
        // Past and specific ranges
        presetElements.push(this.presetElement(RangePreset.PAST));
        if (this.props.showCustomRange) presetElements.push(this.specificRangeElement());
        else if (this.props.showFuturePresets) presetElements.push(this.presetElement(RangePreset.FUTURE));

        return <div className="react-date-time-range--presets">
            {presetElements}
        </div>;
    }

    presetElement(preset) {
        return <a href="#" key={preset} onClick={(e) => this.setPreset(e, preset)} className={this.rangePresetClass(preset)}>{preset}</a>;
    }

    specificRangeElement() {
        return <a href="#" key={"Specific Range"} onClick={(e) => this.setPreset(e, undefined)} className={this.props.presetUsed ? "" : "active"}><FontAwesomeIcon icon={faCalendarDay}/> Specific Range</a>;
    }

    rangePresetClass(presetToTest) {
        const dateHelper = new DateHelper(this.props.outputFormat);
        return (this.props.presetUsed === presetToTest) ? 'active' : '';
    }

    dropDown() {
        if (!this.props.open) return null;

        let customRangeInputs = ((this.props.showCustomRange && !this.props.presetUsed) || !this.props.showRangePresets)
            ? this.customRangeInputs() : '';

        let rangePresets = (this.props.showRangePresets)
            ? this.rangePresets() : '';

        return <div className="react-date-time-range--dropdown">
            {rangePresets}
            {customRangeInputs}
        </div>;
    }

    render() {
        let className = "react-date-time-range";
        if (this.props.open) className += ' react-date-time-range--open';
        return <div className={className}>
            {this.value()}
            {this.dropDown()}
        </div>
    }

}

DateTimeRange.propTypes = {
    open: PropTypes.bool.isRequired,
    fromValue: PropTypes.string,
    toValue: PropTypes.string,
    presetUsed: PropTypes.string,
    toggle: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    outputFormat: PropTypes.string,
    disableOutOfRangeDates: PropTypes.bool,
    disableFutureDates: PropTypes.bool,
    timeEnabled: PropTypes.bool,
    showRangePresets: PropTypes.bool,
    showFuturePresets: PropTypes.bool,
    showCustomRange: PropTypes.bool,
    locale: PropTypes.string
};

DateTimeRange.defaultProps = {
    outputFormat: "YYYY-MM-DD HH:mm:ss",
    presetUsed: undefined,
    disableOutOfRangeDates: false,
    disableFutureDates: false,
    timeEnabled: false,
    showRangePresets: true,
    showFuturePresets: false,
    showCustomRange: true,
    locale: 'en-GB'
};

export default DateTimeRange;