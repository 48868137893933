import { AppEvent, Checkbox, EventBusInstance, isValidString, LogLevel, showBanner, Sisp } from '@sprint/sprint-react-components';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { RepositoryFactoryContext } from '../../index';
import { VatRateRequest } from '../../Api/VatRateRequest';
import VatRate from '../../Models/VatRate';
import VatRateEditState from '../../Models/VatRateEditState';

interface Props {
    uniqueKey: string;
    onSuccess: (results: any) => Promise<boolean>;
}

const VatRatesEditSisp: FunctionComponent<Props> = (props: Props) => {
    const [shown, setShown] = useState<boolean>(false);

    const vatRateRepository = useContext(RepositoryFactoryContext).getApiRepository(new VatRateRequest());

    const focusRef = useRef<HTMLInputElement>(null);

    const [rowId, setRowId] = useState<number>(0);

    const [editVatRate, setEditVatRate] = useState<string>('');
    const [editVatRateValid, setEditVatRateValid] = useState<boolean>(true);

    const [editDescription, setEditDescription] = useState<string>('');

    const [editDefault, setEditDefault] = useState<boolean>(false);

    useEffect(() => {
        if (shown) {
            if (focusRef.current) {
                focusRef.current.focus();
                focusRef.current.selectionStart = focusRef.current.value.length;
                focusRef.current.selectionEnd = focusRef.current.value.length;
            }
            setEditVatRateValid(true);        
        }
    }, [shown]);

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<VatRateEditState>) => {
            if (event.target !== props.uniqueKey) return;
            setRowId(event.message.id);
            setEditVatRate(event.message.vat_rate);
            setEditDescription(event.message.description);
            setEditDefault(event.message.is_default);
            setShown(true);
        });
    }, [shown]);

    const validate = async (): Promise<boolean> => {
        const vatRateValid = !!editVatRate && isValidString(editVatRate);
        setEditVatRateValid(vatRateValid);
        return vatRateValid;
    };

    const handleEditRow = async (): Promise<boolean> => {
        const VatRate: VatRate = {
            id: rowId,
            vat_rate: editVatRate,
            description: editDescription,
            is_default: editDefault,
        };

        return vatRateRepository
            .update(VatRate)
            .then((results: any) => {
                props.onSuccess(results.data);
                return Promise.resolve(true);
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to edit tax rate - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return Promise.resolve(false);
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        if ((await validate()) && (await handleEditRow())) setShown(false);
    };

    return (
        <Sisp
            isOpen={shown}
            onSubmit={handleEditRow}
            onCancel={() => {
                setShown(false);
            }}
            validate={validate}
        >
            <h4>Edit a Tax Rate</h4>
            <Form onSubmit={onSubmitForm}>
                <Form.Group>
                    <Form.Label>
                        Tax Rate <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        ref={focusRef}
                        type="text"
                        isInvalid={!editVatRateValid}
                        value={editVatRate || ''}
                        onChange={(event) => {
                            setEditVatRate(event.target.value);
                            setEditVatRateValid(true);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(!editVatRateValid && 'This field is required.')}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        ref={focusRef}
                        type="text"
                        value={editDescription}
                        onChange={(event) => {
                            setEditDescription(event.target.value);
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Checkbox
                        label="Make this the default tax rate"
                        isChecked={editDefault}
                        onChange={(event) => setEditDefault(event.target.checked)}
                    />
                </Form.Group>
            </Form>
        </Sisp>
    );
};

export default VatRatesEditSisp;
