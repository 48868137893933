'use strict';

import React from 'react';
import Filter from "./Filter";
import DropDownList from "./DropDownList";
import PropTypes from 'prop-types';

class DropDown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            filter: ''
        }
    }

    updateFilter(newFilter) {
        this.setState({
            filter: newFilter
        });
    }

    filter() {
        if (this.props.showFilter === false) return null;
        return <Filter
            currentFilter={this.state.filter}
            updateFilter={this.updateFilter.bind(this)}
        />;
    }

    render() {
        let options = undefined;
        if (this.props.availableOptions !== undefined) {
            options = this.props.availableOptions.slice();
            if (this.state.filter) options = options.filter((o) => {
                if (o.id === undefined) return o.toLowerCase().includes(this.state.filter.toLowerCase());
                return o.label.toLowerCase().includes(this.state.filter.toLowerCase());
            });
        }

        let style = {};
        if (this.props.position !== null) {
            style = {
                top: this.props.position >= 0 ? this.props.position + 'px' : 'inherit',
                bottom: this.props.position < 0 ? Math.abs(this.props.position) + 'px' : 'inherit'
            };
            if (this.props.position < 0) style.boxShadow = '0 0 12px rgba(0,0,0,.175)';
        }

        return <div className="react-select--dropdown" style={style}>
            {this.filter()}
            <DropDownList
                selectedOptions={this.props.selectedOptions}
                availableOptions={options}
                updateSelectedOptions={this.props.updateSelectedOptions}
                multi={this.props.multi}
                deleteItem={this.props.deleteItem}
                deletingItem={this.props.deletingItem}
                noResultsLabel={this.props.noResultsLabel}
            />
        </div>;
    }
}

DropDown.defaultProps = {
    multi: false,
    showFilter: true,
    position: null
};

DropDown.propTypes = {
    selectedOptions: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])),
    availableOptions: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]).isRequired,
            label: PropTypes.string.isRequired,
            labelElement: PropTypes.element,
        })
    ])),
    multi: PropTypes.bool,
    updateSelectedOptions: PropTypes.func.isRequired,
    deleteItem: PropTypes.func,
    deletingItem: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    showFilter: PropTypes.bool,
    position: PropTypes.number
};

export default DropDown;