import { LogLevel, showBanner } from '@sprint/sprint-react-components';
import React, { BaseSyntheticEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { FieldValues, useForm } from 'react-hook-form';
import { RepositoryFactoryContext } from '../..';
import CampusColourPicker from '../../../../CommonComponents/CampusColourPicker/CampusColourPicker';
import COLOUR_PRESETS from '../../../../CommonComponents/ClientTags/ColourPresets';
import PopoverEmojiPicker from '../../../../CommonComponents/EmojiPicker/PopoverEmojiPicker';
import { ClientTagsRequest } from '../../Api/ClientTagsRequest';
import ClientTag from '../../Models/ClientTag';

interface Props {
    data?: ClientTag;
    onSubmit: (data: any) => void;
}

type FormData = {
    id?: number;
    name: string;
    description?: string;
    hex_colour: string;
};

const ClientTagsForm: FunctionComponent<Props> = (props: Props) => {
    const repository = useContext(RepositoryFactoryContext).getApiRepository(new ClientTagsRequest());

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        clearErrors,
        getValues,
        formState: { errors, isSubmitting },
    } = useForm<FormData>({ mode: 'all' });

    const [colour, setColour] = useState<string>(COLOUR_PRESETS[0]);
    const hexColour = watch('hex_colour');

    useEffect(() => {
        clearErrors('hex_colour');
        setValue('hex_colour', sanitiseColour(colour));
    }, [colour]);

    useEffect(() => {
        if (!props.data) return;
        setValue('id', props.data.id);
        setValue('name', props.data.name);
        setValue('description', props.data.description);
        setColour(props.data.hex_colour);
    }, [props.data]);

    const sanitiseColour = (colour: string) => {
        return colour.replaceAll('#', '');
    };

    const resetForm = () => {
        reset();
    };

    const handleRepoSubmit = async (data: FieldValues): Promise<boolean> => {
        return repository
            .create(data)
            .then(async (data) => {
                resetForm();
                props.onSubmit(data);
                return true;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to create Tag - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    const onSubmitForm = async (data: FieldValues, event?: BaseSyntheticEvent<unknown, any, any>) => {
        if (await handleRepoSubmit(data)) {
            resetForm();
        }
    };

    const colourPickerPopover = (
        <Popover id="popover-colour-picker">
            <CampusColourPicker
                colour={colour}
                onChange={(colour) => {
                    setColour(colour);
                }}
                showPresets={true}
            />
        </Popover>
    );

    return (
        <Form id="tag_add_edit_form" onSubmit={handleSubmit(onSubmitForm)} autoComplete="off">
            <Form.Group>
                <Form.Label>
                    Tag Name <span className="required-field-marker">*</span>
                </Form.Label>
                <div style={{ position: 'relative' }}>
                    <Form.Control
                        {...register('name', { required: true })}
                        type="text"
                        autoComplete="off"
                        maxLength={50}
                    />
                    <div style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
                        <PopoverEmojiPicker
                            onEmojiClick={(emoji, event) => {
                                const currentTag = getValues()['name'];
                                setValue('name', currentTag + emoji.emoji);
                            }}
                        />
                    </div>
                </div>
                {errors.name && errors.name.type === 'required' && <span>This field is required</span>}
            </Form.Group>
            <Form.Group>
                <Form.Label>Colour</Form.Label>

                <OverlayTrigger overlay={colourPickerPopover} trigger="click" placement="left" rootClose>
                    <Form.Control
                        {...register('hex_colour', {
                            pattern: /[0-9A-Fa-f]{6}|[0-9A-Fa-f]{3}/g,
                        })}
                        type="text"
                        style={{ borderLeft: '35px solid #' + hexColour, textTransform: 'uppercase' }}
                        onChange={(ev) => {
                            setColour(ev.target.value);
                        }}
                    />
                </OverlayTrigger>
                {errors.hex_colour && errors.hex_colour.type === 'pattern' && <span>Invalid colour hex</span>}
            </Form.Group>
            <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                    {...register('description')}
                    type="textarea"
                    as={'textarea'}
                    style={{ minHeight: '150px', resize: 'vertical' }}
                />
            </Form.Group>
        </Form>
    );
};

export default ClientTagsForm;
