'use strict';

import CampusEntity from "../../CampusEntity/CampusEntity";
import User from "../../User/User";

export default class DealLostReason extends CampusEntity {

    id = undefined;
    reason = '';
    builtIn = false;
    isDefault = false;
    created = '';
    createdBy = undefined;
    modified = '';
    modifiedBy = undefined;

    static blank() {
        return new DealLostReason();
    }

    static fromApi(lostReason) {
        const entity = DealLostReason.blank();

        // Construct from expected API output object
        // Note: `is_logged_in` will not be set for the below Users, access via Client object
        entity.id = lostReason.id;
        entity.reason = lostReason.reason;
        entity.builtIn = lostReason.built_in;
        entity.isDefault = lostReason.is_default;
        entity.created = lostReason.created;
        entity.createdBy = lostReason.created_by ? User.fromApi(lostReason.created_by) : undefined;
        entity.modified = lostReason.modified;
        entity.modifiedBy = lostReason.modified_by ? User.fromApi(lostReason.modified_by) : undefined;

        return entity;
    }

    static select(entityArray, id) {
        return entityArray.find(entity => entity.matches(id));
    }

    matches(id) {
        return String(this.id) === String(id);
    }

    clone() {
        return Object.assign(DealLostReason.blank(), this);
    }

    forSelect() {
        return {
            id: this.id,
            label: this.reason
        }
    }

    forApi() {
        const formData = new FormData();

        // Note: FormData will transmit a string of 'undefined' if the field is empty, so check before appending.
        if (this.id) formData.append('id', this.id);
        if (this.reason) formData.append('reason', this.reason);
        if (this.isDefault) formData.append('is_default', this.isDefault ? '1' : '0');

        return formData;
    }

}
