import { LogLevel, Modal, ModalType, SearchQueryBuilder, showBanner } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { RepositoryFactoryContext } from '../..';
import { SalesPaymentRequest, SalesRequest } from '../../Api/SalesRequest';
import './SalesDeleteModal.scss';
import SalesPaymentsTable from './SalesPaymentsTable';

interface Props {
    selectedRowsForDeletion: number[];
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
    modalShown: boolean;
    reloadDataGrid: () => void;
    closeModal: () => void;
}

const SalesDeleteModal: FunctionComponent<Props> = (props: Props) => {
    const salesRepository = useContext(RepositoryFactoryContext).getApiRepository(new SalesRequest());
    const salesPaymentRepository = useContext(RepositoryFactoryContext).getApiRepository(new SalesPaymentRequest());

    // State: General
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showDataGrid, setShowDataGrid] = useState<boolean>(false);
    const [payments, setPayments] = useState();

    useEffect(() => {
        if (props.modalShown) {
            if (props.selectedRowsForDeletion.length > 1) {
                setIsLoading(false);
            } else {
                setIsLoading(true);
                getSalePayments(props.selectedRowsForDeletion[0]);
            }
        }
    }, [props.modalShown]);

    const getSalePayments = (id: number): Promise<boolean> => {
        return salesPaymentRepository
            .search(new SearchQueryBuilder().build(), id)
            .then((results: any) => {
                if (results.counts.totalItems < 1) {
                    setShowDataGrid(false);
                } else {
                    setPayments(results);
                    setShowDataGrid(true);
                }
                setIsLoading(false);
                return true;
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get Sales Payments - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                    dismissable: false,
                });
                return false;
            });
    };

    const deleteRows = (): Promise<boolean> => {
        return salesRepository
            .delete(props.selectedRowsForDeletion)
            .then((success: any) => {
                showBanner({
                    message: success.data.message,
                    level: LogLevel.SUCCESS,
                });
                props.reloadDataGrid();
                return true;
            })
            .catch((err: any) => {
                showBanner({
                    message: err?.message ?? err,
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    return (
        <Modal
            className="sales-delete-modal"
            isOpen={props.modalShown}
            type={ModalType.DELETE}
            title={
                props.selectedRowsForDeletion.length > 1
                    ? `Delete These ${props.dataGridEntityPlural ?? 'Rows'}`
                    : `Delete This ${props.dataGridEntitySingular ?? 'Row'}`
            }
            close={props.closeModal}
            onSubmit={deleteRows}
        >
            {isLoading ? (
                <div style={{ position: 'relative', alignItems: 'center' }}>
                    <Card
                        className="loading-spinner-container filter-loading-spinner"
                        style={{ background: '#f9f9f9' }}
                    >
                        <Spinner animation="border" role="status" />
                    </Card>
                </div>
            ) : props.selectedRowsForDeletion.length > 1 ? (
                <>
                    Are you sure you want to delete
                    {` these ${props.selectedRowsForDeletion.length} ${(
                        props.dataGridEntityPlural ?? 'rows'
                    ).toLowerCase()}?`}
                    <>
                        <br />
                        <br />
                        If you do, it means deleting all the attached payments too.
                    </>
                </>
            ) : showDataGrid ? (
                <>
                    Are you sure that you want to delete this sale?
                    <br />
                    <br />
                    If you do, it means deleting all the attached payments too:
                    <br />
                    <br />
                    <SalesPaymentsTable dataGridUniqueKey="SalesPayments" dataGridData={payments} />
                </>
            ) : (
                <>Are you sure that you want to delete this sale?</>
            )}
        </Modal>
    );
};

export default SalesDeleteModal;
