'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {filterSales, loadIdList, loadMore} from "../Actions";
import ConnectedCampusFormSelect from "../../../CommonComponents/Form/ConnectedCampusFormSelect";

class ConnectedSaleFormSelect extends React.Component {

    render() {
        return <ConnectedCampusFormSelect
            {...this.props}
            reducerName="sale"
            filterEntity={filterSales}
            loadIdList={loadIdList}
            loadMore={loadMore}
        />
    }

}

ConnectedSaleFormSelect.propTypes = {
    label: PropTypes.string.isRequired,
    validationErrorMessage: PropTypes.string,
    placeHolder: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    clearable: PropTypes.bool,
    noResultsLabel: PropTypes.string,
    advancedSearch: PropTypes.object
};

ConnectedSaleFormSelect.defaultProps = {
    label: 'Sale',
    placeHolder: 'Please select',
    noResultsLabel: 'No Sales Found'
};

export default ConnectedSaleFormSelect;