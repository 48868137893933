import { AppEvent, EventBusInstance, LogLevel, Sisp, showBanner } from '@sprint/sprint-react-components';
import React, { FormEvent, FunctionComponent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import DataGridRepository from '../../Applications/DataGrids/Api/DataGridRepository';
import ClientTag from '../../Applications/DataGrids/Models/ClientTag';
import ClientTagPill from './ClientTagPill';
import TagsEditDropdown from './TagsEditDropdown';

type MinimalRequiredCampaignType = {
    id: number;
    tags?: ClientTag[];
};

interface Props {
    onSuccess: (event: any) => Promise<boolean>;
    repository: DataGridRepository<any>;
}

export const EDIT_CAMPAIGN_TAGS_EVENT_KEY = 'edit-campaign-tags';

const CampaignsEditTagsSisp: FunctionComponent<Props> = (props: Props) => {
    // State: General
    const [shown, setShown] = useState<boolean>(false);
    const [rowId, setRowId] = useState<number>(0);
    const [tags, setTags] = useState<{ value: number; label: any }[]>([]);

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<MinimalRequiredCampaignType>) => {
            if (event.target !== EDIT_CAMPAIGN_TAGS_EVENT_KEY) return;
            setRowId(event.message.id!);
            setTags(
                event.message.tags?.map((t) => {
                    return {
                        value: t.id ?? 0,
                        label: <ClientTagPill tag={t} />,
                    };
                }) ?? [],
            );
            setShown(true);
        });
    }, [shown]);

    const handleUpdate = async (): Promise<boolean> => {
        return props.repository
            .post_action(
                'update_tags',
                rowId,
                tags.map((v) => v.value),
            )
            .then((results: any) => {
                props.onSuccess(results.data);
                showBanner({
                    message: 'Campaign tags saved successfully.',
                    level: LogLevel.SUCCESS,
                });
                return Promise.resolve(true);
            })
            .catch((err: any) => {
                showBanner({
                    message: err?.message ?? err,
                    level: LogLevel.ERROR,
                });
                return Promise.resolve(false);
            });
    };

    const validate = async (): Promise<boolean> => {
        return true;
    };

    const onSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        if ((await validate()) && (await handleUpdate())) setShown(false);
    };

    return (
        <Sisp
            isOpen={shown}
            onSubmit={handleUpdate}
            onCancel={() => {
                setShown(false);
            }}
            validate={validate}
        >
            <h4>Edit Campaign Tags</h4>
            <Form onSubmit={onSubmitForm}>
                <TagsEditDropdown
                    onChange={(tags) => {
                        setTags(tags);
                    }}
                    existingTags={tags}
                />
            </Form>
        </Sisp>
    );
};

export default CampaignsEditTagsSisp;
