'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import '../dropdown.scss';
import MenuOption from "./MenuOption";

class Menu extends React.Component {

    render() {
        if (!this.props.open) return null;

        let renderedOptions;

        if (this.props.options) {
            renderedOptions = this.props.options.map((option) => {
                const value = option.id !== undefined ? option.id : option;
                let label = option.id !== undefined ? option.label : option;
                if (option.labelElement) label = option.labelElement;

                return <MenuOption value={value} key={value} action={this.props.optionClicked}>
                    {label}
                </MenuOption>;
            });
        }

        return <div className="react-drop-down-menu-container">
            <ul className="react-drop-down-menu" style={{marginLeft: this.props.offsetHorizontal, marginTop: this.props.offsetVertical}}>
                {renderedOptions && renderedOptions.length
                    ? renderedOptions
                    : <MenuOption>{this.props.noOptions}</MenuOption>}
            </ul>
        </div>;
    }

}

Menu.propTypes = {
    open: PropTypes.bool,
    noOptions: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    options: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]).isRequired,
            label: PropTypes.string.isRequired,
            labelElement: PropTypes.element
        })
    ])),
    optionClicked: PropTypes.func,
    offsetHorizontal: PropTypes.number,
    offsetVertical: PropTypes.number
};

Menu.defaultProps = {
    noOptions: 'No Items',
    offsetHorizontal: 0,
    offsetVertical: 0
};

export default Menu;