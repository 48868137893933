'use strict';

import HttpRequestService from "../../../Services/HttpRequestService/HttpRequestService";

export default class DealPipelineRepository {

    static all() {
        return new Promise(function(resolve, reject) {
            new HttpRequestService()
                .get('deals/ajax/get_deal_pipelines')
                .then(response => {
                    if (response.status !== 200) return reject('Unable to load deal pipelines, endpoint not responding. ERR-REACT-DP-07');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.message);
                })
        });
    }
    
    static save(dealPipelineEntity) {
        return new Promise(function(resolve, reject) {
            if (typeof dealPipelineEntity.forApi !== 'function') return reject('Unable to save deal pipeline, invalid deal pipeline entity provided. ERR-REACT-DP-06');
            new HttpRequestService()
                .post('settings/deal_pipelines/save', dealPipelineEntity.forApi())
                .then(response => {
                    if (!response) return reject('Invalid response returned. ERR-REACT-DP-01');
                    if (response.status !== 200) return reject('Unable to save deal pipeline, endpoint not responding. ERR-REACT-DP-02');
                    if (!response.data.data) return reject('Invalid response returned. ERR-REACT-DP-05');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve(response.data.data);
                })
                .catch(error => reject(error.message));
        });
    }

    static find(dealPipelineId) {
        return new Promise(function(resolve, reject) {
            new HttpRequestService()
                .get('settings/deal_pipelines/get/' + dealPipelineId)
                .then(response => {
                    if (!response) return reject('Invalid response returned. ERR-REACT-DP-03');
                    if (response.status !== 200) return reject('Unable to load deal pipeline, endpoint not responding. ERR-REACT-DP-04');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve(response.data.data);
                })
                .catch(error => reject(error.message));
        })
    }

    static delete(dealPipelineId) {
        return new Promise(function(resolve, reject) {
            if (dealPipelineId < 1) return reject('Unable to delete deal pipeline, invalid ID provided. ERR-REACT-DP-11');

            const formData = new FormData();
            formData.append('id', dealPipelineId);

            new HttpRequestService()
                .post('settings/deal_pipelines/delete/', formData)
                .then(response => {
                    if (!response) return reject('Invalid response returned. ERR-REACT-DP-09');
                    if (response.status !== 200) return reject('Unable to delete deal pipeline, endpoint not responding. ERR-REACT-DP-10');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve();
                })
                .catch(error => reject(error.message))
        });
    }



}