import { ExtendedColumn, HoveroverButton, SearchQuery, UserFormatter } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { SmtpAccountRequest, UsersWithoutSmtpRequest } from '../../Api/SmtpAccountRequest';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import { UniqueKeyType } from '../../Models/Enums';
import SmtpAccount from '../../Models/SmtpAccount';
import SmtpAccountEditState from '../../Models/SmtpAccountEditState';
import UserType from '../../Models/UserType';
import { RepositoryFactoryContext } from '../../index';
import CampusDataGrid from '../CampusDataGrid';
import SmtpAccountAddSisp from './SmtpAccountAddSisp';
import SmtpAccountEditSisp from './SmtpAccountEditSisp';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
    enableOauth?: boolean;
}

enum SmtpAccountColumnKey {
    ID,
    EMAIL_ADDRESS,
    PROVIDER,
    USER,
}

const SmtpAccountTable: FunctionComponent<Props> = (props: Props) => {
    const addSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP);
    const editSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);

    const smtpAccountsRepository = useContext(RepositoryFactoryContext).getApiRepository(new SmtpAccountRequest());
    const usersWithoutSmtpRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new UsersWithoutSmtpRequest(),
    );

    const [showAddButton, setShowAddButton] = useState(false);

    useEffect(() => {
        onGetUsersWithoutSmtp('');
    }, []);

    // State: Columns

    const Columns: Record<SmtpAccountColumnKey, ExtendedColumn> = {
        [SmtpAccountColumnKey.ID]: {
            key: 'id',
            name: 'ID',
        },
        [SmtpAccountColumnKey.EMAIL_ADDRESS]: {
            key: 'default_from_email',
            name: 'Email',
            sortable: true,
            renderCell: (props) => {
                const id = (props.row as SmtpAccount).id!;
                const smtp_host = (props.row as SmtpAccount).smtp_host;
                const smtp_port = (props.row as SmtpAccount).smtp_port;
                const smtp_user = (props.row as SmtpAccount).smtp_user;
                const smtp_pass = '**********';
                const default_from_name = (props.row as SmtpAccount).default_from_name;
                const default_from_email = (props.row as SmtpAccount).default_from_email;
                const smtp_security = (props.row as SmtpAccount).smtp_security;
                const user = ((props.row as SmtpAccount).user as UserType).id!;
                const provider = (props.row as SmtpAccount).provider;
                const eventBusMessage: SmtpAccountEditState = {
                    id: id,
                    smtp_host: smtp_host,
                    smtp_port: smtp_port,
                    smtp_user: smtp_user,
                    smtp_pass: smtp_pass,
                    default_from_name: default_from_name,
                    default_from_email: default_from_email,
                    smtp_security: smtp_security,
                    user: user,
                    provider: provider,
                };
                return (
                    <>
                        {default_from_email}
                        <HoveroverButton
                            contents="Edit"
                            eventBusMessageTarget={editSispUniqueKey}
                            eventBusMessage={eventBusMessage}
                        />
                    </>
                );
            },
            width: '1fr',
        },
        [SmtpAccountColumnKey.PROVIDER]: {
            key: 'provider',
            name: 'Email Provider',
            sortable: true,
            width: '1fr',
            renderCell: (props) => {
                switch (props.row.provider) {
                    case 'google':
                        return 'Gmail';
                    case 'microsoft':
                        return 'Outlook 365';
                    default:
                        return 'SMTP';
                }
            },
        },
        [SmtpAccountColumnKey.USER]: {
            key: 'user',
            name: 'User',
            sortable: true,
            renderCell: UserFormatter,
            width: '1fr',
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[SmtpAccountColumnKey.EMAIL_ADDRESS],
        Columns[SmtpAccountColumnKey.PROVIDER],
        Columns[SmtpAccountColumnKey.USER],
    ];

    const onGetUsersWithoutSmtp = async (filter: string) => {
        const query = new SearchQuery(1, 100);
        return usersWithoutSmtpRepository
            .search(query)
            .then((results: any) => {
                if (results.results.length > 0) {
                    return setShowAddButton(true);
                } else {
                    return setShowAddButton(false);
                }
            })
            .catch((err: any) => {
                return setShowAddButton(false);
            });
    };

    return (
        <CampusDataGrid
            repository={smtpAccountsRepository}
            dataGridMeta={{
                uniqueKey: props.dataGridUniqueKey,
                entitySingular: props.dataGridEntitySingular,
                entityPlural: props.dataGridEntityPlural,
                defaultColumns: DefaultColumns,
                columnOptions: Columns,
            }}
            actionBarMeta={{
                searchPlaceHolder: props.searchFilterPlaceholder,
                includeCounts: true,
                hideAddButton: !showAddButton,
            }}
            addSispMeta={{
                key: addSispUniqueKey,
                sisp: SmtpAccountAddSisp,
                additionalProps: { oauthEnabled: props.enableOauth },
            }}
            editSispMeta={{
                sisp: SmtpAccountEditSisp,
            }}
            promptMeta={{
                icon: '/assets/application/img/prompts/no_smtp_settings.png',
                entitySingular: 'Connected Email',
                entityPlural: 'Connected Emails',
                addButtonLabel: 'Connect Email',
            }}
        />
    );
};

export default SmtpAccountTable;
