import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppEvent, EventBusInstance, Modal, ModalType } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import FormsType from '../../Models/FormsType';
import './FormsEmbedModal.scss';

interface Props {
    uniqueKey: string;
}

const FormsEmbedModal: FunctionComponent<Props> = (props: Props) => {
    // State: General
    const [shown, setShown] = useState<boolean>(false);
    const [embedCopied, setEmbedCopied] = useState<boolean>(false);

    const [formCode, setFormCode] = useState<string>('');

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<FormsType>) => {
            if (event.target !== props.uniqueKey) return;
            setFormCode(event.message.embed_code);
            setEmbedCopied(false);
            setShown(true);
        });
    }, [shown]);

    const helpPath: string = KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.EMBEDDING_A_FORM);

    return (
        <Modal
            className="forms-embed-modal"
            close={() => setShown(false)}
            isOpen={shown}
            title="Embed Your Form Code"
            type={ModalType.INFO}
        >
            <Form>
                <Form.Group>
                    <div>
                        Copy and paste this into the webpage where you want the form to show. Or get&nbsp;
                        <a href={helpPath} className="rdg-link">
                            a little more help.
                        </a>
                    </div>
                </Form.Group>
                <Form.Group>
                    <CopyToClipboard onCopy={() => setEmbedCopied(true)} text={formCode}>
                        <Button className="copy-code" variant="primary">
                            Copy Code
                        </Button>
                    </CopyToClipboard>
                    {embedCopied && (
                        <span className="copy-success">
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;Successfully Copied to Clipboard
                        </span>
                    )}
                </Form.Group>
                <Form.Group>
                    <div className="embed-code">{formCode}</div>
                </Form.Group>
            </Form>
        </Modal>
    );
};

export default FormsEmbedModal;
