import React, { FunctionComponent } from 'react';

interface Props {
    content: string | JSX.Element | ((...args: any[]) => any);
}

const Footer: FunctionComponent<Props> = (props: Props) => {
    return <div className="modal-footer">{typeof props.content === 'function' ? props.content() : props.content}</div>;
};

export default Footer;
