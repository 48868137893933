'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import Filter from "./Filter";
import DropDownList from "./DropDownList";
import Spinner from "../../Spinner/Spinner";

class DropDownStateless extends React.Component {

    constructor(props) {
        super(props);
    }

    loadMore() {
        if (!this.props.totalAvailable || !this.props.availableOptions || this.props.availableOptions.length >= this.props.totalAvailable) return null;
        const more = <a href="#" onClick={this.props.loadMore}>View more...</a>;
        const moreLoading = <a><Spinner/></a>;
        return <li className="react-select--load-more">
            { this.props.moreLoading ? moreLoading : more }
        </li>
    }

    filter() {
        if (this.props.showFilter === false) return null;
        return <Filter
            currentFilter={this.props.currentFilter}
            updateFilter={this.props.updateFilter}
        />;
    }

    render() {
        let style = {};
        if (this.props.position !== null) {
            style = {
                top: this.props.position >= 0 ? this.props.position + 'px' : 'inherit',
                bottom: this.props.position < 0 ? Math.abs(this.props.position) + 'px' : 'inherit'
            };
            if (this.props.position < 0) style.boxShadow = '0 0 12px rgba(0,0,0,.175)';
        }

        return <div className="react-select--dropdown" style={style}>
            {this.filter()}
            <DropDownList
                selectedOptions={this.props.selectedOptions}
                availableOptions={this.props.availableOptions}
                updateSelectedOptions={this.props.updateSelectedOptions}
                multi={this.props.multi}
                deleteItem={this.props.deleteItem}
                deletingItem={this.props.deletingItem}
                noResultsLabel={this.props.noResultsLabel}
            >{ this.loadMore() }</DropDownList>
        </div>;
    }

}

DropDownStateless.propTypes = {
    selectedOptions: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])),
    availableOptions: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]).isRequired,
            label: PropTypes.string.isRequired,
            labelElement: PropTypes.element,
        })
    ])),
    updateSelectedOptions: PropTypes.func.isRequired,
    currentFilter: PropTypes.string.isRequired,
    updateFilter: PropTypes.func.isRequired,
    totalAvailable: PropTypes.number,
    loadMore: PropTypes.func.isRequired,
    moreLoading: PropTypes.bool.isRequired,
    multi: PropTypes.bool,
    deleteItem: PropTypes.func,
    deletingItem: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    showFilter: PropTypes.bool,
    position: PropTypes.number
};

DropDownStateless.defaultProps = {
    multi: false,
    showFilter: true,
    position: null
};

export default DropDownStateless;