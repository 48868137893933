'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import Open from "./SaveCurrentSearchControl/Open";
import Closed from "./SaveCurrentSearchControl/Closed";
import Spinner from "../../../CommonComponents/Spinner/Spinner";
import SavedSearch from "../../../Entities/SavedSearch/SavedSearch";

class SaveCurrentSearchControl extends React.Component {

    openOrClosed() {
        if (this.props.saving) return <Spinner inline={true}/>;

        if (this.props.open) return <Open
            toggle={this.props.toggle}
            save={this.props.save}
        />;

        return <Closed
            toggle={this.props.toggle}
            currentSavedSearch={this.props.currentSavedSearch}
            save={this.props.save}
        />
    }

    render() {
        return <div className="react-adv--ss-field-control">
            { this.openOrClosed() }
        </div>;
    }

}

SaveCurrentSearchControl.propTypes = {
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    currentSavedSearch: PropTypes.instanceOf(SavedSearch)
};

export default SaveCurrentSearchControl;