import { Checkbox, isValidString, LogLevel, showBanner, Sisp } from '@sprint/sprint-react-components';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { RepositoryFactoryContext } from '../../index';
import { PaymentTypeRequest } from '../../Api/PaymentTypeRequest';
import PaymentType from '../../Models/PaymentType';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
}

const PaymentTypesAddSisp: FunctionComponent<Props> = (props: Props) => {
    const paymentTypesRepository = useContext(RepositoryFactoryContext).getApiRepository(new PaymentTypeRequest());

    const focusRef = useRef<HTMLInputElement>(null);

    const [newPaymentType, setNewPaymentType] = useState<string>('');
    const [newPaymentTypeValid, setNewPaymentTypeValid] = useState<boolean>(true);

    const [newDefault, setNewDefault] = useState<boolean>(false);

    useEffect(() => {
        if (props.shown) {
            focusRef.current?.focus();
        }
    }, [props.shown]);

    const validate = async (): Promise<boolean> => {
        const typeValid = !!newPaymentType && isValidString(newPaymentType);
        setNewPaymentTypeValid(typeValid);
        return typeValid;    };

    const reset = () => {
        setNewPaymentType('');
        setNewPaymentTypeValid(true);
        setNewDefault(false);
    };

    const handleAddRow = (): Promise<boolean> => {
        const PaymentType: PaymentType = {
            payment_type: newPaymentType,
            is_default: newDefault,
        };

        return paymentTypesRepository
            .create(PaymentType)
            .then(props.onSuccess)
            .then(async (success) => {
                reset();
                return success;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to create Payment Type - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        if ((await validate()) && (await handleAddRow())) props.onClose();
    };

    return (
        <Sisp
            isOpen={props.shown}
            onSubmit={handleAddRow}
            onCancel={() => {
                reset();
                props.onClose();
            }}
            validate={validate}
        >
            <h4>Add a PaymentType</h4>
            <Form onSubmit={onSubmitForm}>
                <Form.Group>
                    <Form.Label>
                        Payment Type <span className="required-field-marker">*</span>
                    </Form.Label>                   
                    <Form.Control
                        ref={focusRef}
                        type="text"
                        isInvalid={!newPaymentTypeValid}
                        value={newPaymentType || ''}
                        onChange={(event) => {
                            setNewPaymentType(event.target.value);
                            setNewPaymentTypeValid(true);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(!newPaymentTypeValid && 'This field is required.')}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Checkbox
                        label="Make this the default payment type"
                        isChecked={newDefault}
                        onChange={(event) => setNewDefault(event.target.checked)}
                    />
                </Form.Group>
            </Form>
        </Sisp>
    );
};

export default PaymentTypesAddSisp;
